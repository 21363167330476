import { Upload } from '@mui/icons-material';
import { Button, FormControl } from '@mui/material';
import {
  NotificationTypeEnum,
  notificationQueue,
  useDialog,
  useNotification,
} from '@prismamedia/one-components';
import { GetSubject_subject } from '__generated__/queries-topic';
import {
  useCreatePhoto,
  useUpdatePhotoCache,
} from 'apollo/mutations/photos.photo.graphql';
import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';
import { FC, useEffect, useRef, useState } from 'react';
import { Metadata } from 'utils/getMetadata';
import { PhotoListDialog } from '../PhotoListDialog';
import { UnderSubjectSelect } from '../UnderSubjectSelect';
import { useStyles } from './styles';

interface ImportPhotosDialogProps {
  subject: GetSubject_subject;
}

export const ImportPhotosDialog: FC<ImportPhotosDialogProps> = ({
  subject,
}) => {
  const classes = useStyles();
  const { closeDialog } = useDialog();
  const [photos, setPhotos] = useState<
    Pick<
      SearchPhotosPhoto,
      'metadataByName' | 'updatedAt' | 'previews' | 'downloadUrl'
    >[]
  >([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const [underSubject, setUnderSubject] = useState<string>('');
  const { pushNotification, removeNotification } = useNotification();
  const createPhoto = useCreatePhoto();
  const updatePhotoCache = useUpdatePhotoCache();

  useEffect(() => {
    inputRef.current?.click();
  }, []);

  useEffect(() => {
    files &&
      Array.from(files).forEach((file, i) => {
        const reader = new FileReader();
        // eslint-disable-next-line immutable/no-mutation
        reader.onload = (e) => {
          setPhotos((prev) =>
            Object.assign([], prev, {
              [i]: {
                previews: [
                  {
                    __typename: 'Preview',
                    size: 400,
                    href: e.target && (e.target.result as string),
                  },
                ],
                updatedAt: new Date().toISOString(),
                photoMetadatas: [],
                downloadUrl: null,
              },
            }),
          );
        };
        reader.readAsDataURL(file);
      });
  }, [files]);

  const uploadPhotos = async () => {
    if (!files) return;

    closeDialog();

    const queue = Array.from(files).map((file) => {
      const result = createPhoto({
        file,
        archiveConnect: { archiveId: subject.unit.photoArchiveId },
        metadatas: [
          {
            metadata: Metadata.Undersubject,
            value: underSubject || undefined,
          },
          { metadata: Metadata.ID_subject, value: subject.id },
        ],
      });

      return () => result;
    });

    try {
      await notificationQueue(
        queue,
        pushNotification,
        removeNotification,
        'Importation',
      );
      await updatePhotoCache();
    } catch (e: any) {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
    }
  };

  return (
    <PhotoListDialog
      photos={photos}
      title="Importer des images"
      submitText="Importer"
      disabled={!photos.length}
      onSubmit={uploadPhotos}
    >
      <input
        type="file"
        ref={inputRef}
        multiple
        className={classes.inputElement}
        onChange={(e) => e.target.files && setFiles(Array.from(e.target.files))}
      />
      {!photos.length ? (
        <Button
          variant="contained"
          color="primary"
          startIcon={<Upload />}
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.click();
            }
          }}
        >
          Charger des images
        </Button>
      ) : (
        <FormControl className={classes.formControl} variant="standard">
          <UnderSubjectSelect
            subject={subject}
            value={underSubject}
            onChange={setUnderSubject}
          />
        </FormControl>
      )}
    </PhotoListDialog>
  );
};
