import { useApolloClient } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormControlLabel, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import {
  LoadingButton,
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import arrayMutators from 'final-form-arrays';
import { insert, prepend, update } from 'ramda';
import { FC, useCallback, useState } from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import uuid from 'uuid/v4';
import {
  LocationPage_GetPrintHeadingByPrintIssue,
  LocationPage_GetPrintHeadingByPrintIssueVariables,
  LocationPage_GetPrintIssuesByPrintPublication,
  LocationPage_GetPrintPublicationKey,
  LocationPage_GetSubjectsFromPrintHeading,
  LocationPage_GetSubjectsFromPrintHeadingVariables,
  PrintIssueCreationInput,
  PrintIssueUpdateInput,
} from '../../../../__generated__/queries-topic';
import { Select } from '../../../../components/FinalFormMaterial/Select';
import { TextField } from '../../../../components/FinalFormMaterial/TextField';
import { SortablePrintIssue } from '../../../../utils/sortPrintIssues';
import { GET_SUBJECTS_FROM_PRINT_HEADING } from '../../PrintHeading/AutoSubjectDialogForm/getSubjectsFromPrintHeading.topic.graphql';
import { createAutoSubject } from '../../PrintHeading/AutoSubjectDialogForm/utils';
import { GET_PRINT_HEADING_BY_PRINT_ISSUE } from '../../PrintHeading/getPrintHeadingsByPrintIssue.topic.graphql';
import { generateSubjectTitle } from '../../utils';
import { GET_PRINT_ISSUES_BY_PRINT_PUBLICATION } from '../getPrintIssuesByPrintPublication.topic.graphql';
import { GET_PRINT_PUBLICATION_KEY } from './getPrintPublicationKey.topic.graphql';
import { useStyles } from './styles';
import { usePrintIssueUpsert } from './upsertPrintIssue.topic.graphql';

export interface PrintIssueDialogFormProps {
  open: boolean;
  printIssue?: SortablePrintIssue;
  printIssues: SortablePrintIssue[];
  handleClose: () => void;
  printPublicationId: string;
  unitId: string;
  fromPrintIssue?: SortablePrintIssue;
}

interface PrintIssueFormModel {
  titles: string[];
  duplicatedId: string;
  keepAutoSubject: boolean;
}

export const PrintIssueDialogForm: FC<PrintIssueDialogFormProps> = ({
  open,
  printPublicationId,
  unitId,
  printIssue,
  printIssues,
  handleClose,
  fromPrintIssue,
}) => {
  const classes = useStyles();
  const { pushNotification } = useNotification();
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  const [upsertPrintIssue] = usePrintIssueUpsert({
    update: (cache, { data }) => {
      if (!data || !data.upsertPrintIssue) {
        return;
      }
      const result = cache.readQuery<LocationPage_GetPrintIssuesByPrintPublication>(
        {
          query: GET_PRINT_ISSUES_BY_PRINT_PUBLICATION,
          variables: { printPublicationId },
        },
      );
      if (result?.printPublication && result.printIssues) {
        let newPrintIssues = result.printIssues;
        if (printIssue) {
          const printIssueIndex = result.printIssues.findIndex(
            (pi) => pi.id === printIssue.id,
          );
          newPrintIssues = update(
            printIssueIndex,
            data.upsertPrintIssue,
            newPrintIssues,
          );
        } else {
          newPrintIssues = prepend(data.upsertPrintIssue, newPrintIssues);
        }
        cache.writeQuery({
          query: GET_PRINT_ISSUES_BY_PRINT_PUBLICATION,
          variables: { printPublicationId },
          data: {
            ...result,
            printIssues: newPrintIssues,
          },
        });
      }
    },
    onCompleted: (data) => {
      handleClose();
      if (!data.upsertPrintIssue) {
        pushNotification({
          message: "Erreur lors de l'enregistrement de la parution",
          type: NotificationTypeEnum.error,
        });
        return;
      }
      const issue =
        data && data.upsertPrintIssue && data.upsertPrintIssue.title;
      pushNotification({
        message: `La parution ${issue} a bien été enregistrée.`,
        type: NotificationTypeEnum.success,
      });
    },
    onError: (error) => {
      console.error(error);
      pushNotification({
        message: "Erreur lors de l'enregistrement de la parution.",
        type: NotificationTypeEnum.error,
      });
    },
  });

  const handleSubmitPrintIssue = useCallback(
    async (values: PrintIssueFormModel) => {
      setIsLoading(true);
      try {
        // eslint-disable-next-line fp/no-loops
        for (let i = 0; i < values.titles.length; i++) {
          const title = values.titles[i];
          if (!title) continue;
          const id = (printIssue && printIssue.id) || uuid();

          const updateData: PrintIssueUpdateInput = {
            title,
            printPublication: {
              connect: { id: printPublicationId },
            },
          };
          let subjects = null;
          const printHeadingsWithAutoSubject: Record<string, string> = {};
          if (values.duplicatedId) {
            const duplicatedIssue = printIssues.find(
              (pi) => pi.id === values.duplicatedId,
            );
            if (duplicatedIssue) {
              const printHeadings = await client.query<
                LocationPage_GetPrintHeadingByPrintIssue,
                LocationPage_GetPrintHeadingByPrintIssueVariables
              >({
                query: GET_PRINT_HEADING_BY_PRINT_ISSUE,
                variables: {
                  printIssueId: values.duplicatedId,
                },
              });

              // eslint-disable-next-line immutable/no-mutation
              updateData.printHeadings = {
                create: printHeadings.data.printHeadings.map((printHeading) => {
                  const printHeadingId = uuid();
                  if (printHeading.hasAutomaticSubject) {
                    // eslint-disable-next-line immutable/no-mutation
                    printHeadingsWithAutoSubject[
                      printHeading.id
                    ] = printHeadingId;
                  }
                  return {
                    id: printHeadingId,
                    title: printHeading.title,
                    order: printHeading.order,
                    hasAutomaticSubject: values.keepAutoSubject
                      ? printHeading.hasAutomaticSubject
                      : false,
                    autoPrototype: printHeading.autoPrototype,
                    printTemplates: {
                      create: printHeading.printTemplates.map((template) => ({
                        id: uuid(),
                        active: template.active,
                        printTemplate: {
                          connect: {
                            id: template.printTemplate.id,
                          },
                        },
                      })),
                    },
                  };
                }),
              };
            }

            // retreive the subjects that were "auto created"
            subjects = await client.query<
              LocationPage_GetSubjectsFromPrintHeading,
              LocationPage_GetSubjectsFromPrintHeadingVariables
            >({
              query: GET_SUBJECTS_FROM_PRINT_HEADING,
              variables: {
                where: {
                  flatAssignments_some: {
                    printHeading: {
                      id_in: Object.keys(printHeadingsWithAutoSubject),
                    },
                  },
                  isAuto: true,
                },
              },
            });
          }
          const createData: PrintIssueCreationInput = {
            id,
            ...(updateData as PrintIssueCreationInput),
          };
          // create new printIssues and printHeadings
          await upsertPrintIssue({
            variables: {
              create: createData,
              update: updateData,
              where: { id },
            },
          });
          // If we retreived subjects then we need to duplicate them with their new title
          // and the new printHeading previously created
          if (
            subjects &&
            subjects.data &&
            subjects.data.subjects &&
            values.keepAutoSubject
          ) {
            const promisesOfSubjects = subjects.data.subjects.map(
              async (subject) => {
                const printHeadingRef = subject.flatAssignments[0].printHeading
                  ? subject.flatAssignments[0].printHeading
                  : { id: '', title: '' };
                const {
                  data,
                } = await client.query<LocationPage_GetPrintPublicationKey>({
                  query: GET_PRINT_PUBLICATION_KEY,
                  variables: { id: printPublicationId },
                });
                return createAutoSubject(
                  client,
                  generateSubjectTitle(
                    data.printPublication?.key,
                    title,
                    printHeadingRef.title,
                  ),
                  null,
                  unitId,
                  printHeadingsWithAutoSubject[printHeadingRef.id],
                  subject.subjectCategory?.parent
                    ? subject.subjectCategory.parent.id
                    : '',
                  subject.subjectCategory?.id,
                );
              },
            );
            await Promise.all(promisesOfSubjects);
          }
        }
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [printIssue, printIssues, printPublicationId, unitId, upsertPrintIssue],
  );

  return (
    <Dialog
      open={open}
      onClose={() => handleClose}
      aria-labelledby="form-dialog-title"
      classes={{
        paper: classes.paper,
      }}
    >
      <Form
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators,
        }}
        initialValues={{
          titles: printIssue ? [printIssue.title] : [''],
          duplicatedId: fromPrintIssue ? fromPrintIssue.id : '',
          keepAutoSubject: false,
        }}
        validate={
          ((values: PrintIssueFormModel) => {
            const errors: { 'titles.0'?: string } = {};
            if (!values.titles[0]?.length) {
              // eslint-disable-next-line immutable/no-mutation
              errors['titles.0'] = 'Titre obligatoire';
            }
            return errors;
          }) as any
        }
        onSubmit={handleSubmitPrintIssue as any}
        render={({ form }) => {
          return (
            <>
              <DialogTitle
                id="form-dialog-title"
                classes={{ root: classes.title }}
              >
                {printIssue
                  ? 'Éditer une parution'
                  : 'Créer une nouvelle parution'}
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                {!printIssue && (
                  <Field
                    data-cy="create-issue-duplicated-id-select"
                    component={Select as FC<FieldRenderProps>}
                    name="duplicatedId"
                    label="Se baser sur la parution:"
                    fullWidth
                  >
                    {insert(
                      0,
                      <MenuItem key="0" value="" />,
                      printIssues.map((pi) => (
                        <MenuItem
                          data-cy="create-issue-duplicated-id-select-choice"
                          key={pi.id}
                          value={pi.id}
                        >
                          {pi.title}
                        </MenuItem>
                      )),
                    )}
                  </Field>
                )}
                <FieldArray name="titles">
                  {({ fields }) => (
                    <>
                      {fields.map((fieldName: string, idx: number) => (
                        <div key={fieldName} style={{ display: 'flex' }}>
                          <Field
                            component={TextField}
                            name={fieldName}
                            label="Titre"
                            fullWidth
                          />
                          {!printIssue && idx > 0 && (
                            <IconButton
                              onClick={() => fields.remove(idx)}
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </div>
                      ))}
                      {!printIssue && (
                        // eslint-disable-next-line fp/no-mutating-methods
                        <Button onClick={() => fields.push('')}>
                          Ajouter une autre parution.
                        </Button>
                      )}
                    </>
                  )}
                </FieldArray>
              </DialogContent>
              <DialogActions>
                {!printIssue && (
                  <Field
                    name="keepAutoSubject"
                    render={({ input }) => (
                      <FormControlLabel
                        value={input.value}
                        control={
                          <Switch
                            color="primary"
                            onChange={input.onChange}
                            name={input.name}
                          />
                        }
                        label="Conserver sujet auto"
                        labelPlacement="start"
                      />
                    )}
                  />
                )}
                <Button onClick={handleClose}>Annuler</Button>
                <LoadingButton
                  data-cy="create-unit-save-button"
                  onClick={form.submit}
                  loading={isLoading}
                  color="primary"
                  variant="contained"
                >
                  Sauvegarder
                </LoadingButton>
              </DialogActions>
            </>
          );
        }}
      />
    </Dialog>
  );
};
