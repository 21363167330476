/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCategory
// ====================================================

export interface CreateCategory_createCategory_parent {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface CreateCategory_createCategory {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  label: string | null;
  parent: CreateCategory_createCategory_parent | null;
}

export interface CreateCategory {
  /**
   * Create a single "Category" node.
   */
  createCategory: CreateCategory_createCategory;
}

export interface CreateCategoryVariables {
  data: CategoryCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePhoto
// ====================================================

export interface CreatePhoto_createPhoto_archive {
  __typename: "Archive";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * get the access rights of the archive
   */
  accesses: (string | null)[] | null;
}

export interface CreatePhoto_createPhoto_previews {
  __typename: "Preview";
  href: string | null;
  size: number | null;
}

export interface CreatePhoto_createPhoto {
  __typename: "Photo";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  archive: CreatePhoto_createPhoto_archive;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  height: number | null;
  width: number | null;
  filesize: number | null;
  originalName: string | null;
  /**
   * asset content type
   */
  contentType: string | null;
  /**
   * get a S3 download URL
   */
  downloadUrl: string | null;
  /**
   * get all supported resized previews
   */
  previews: CreatePhoto_createPhoto_previews[] | null;
  /**
   * optimized photo metadata listing by name for convenience
   */
  metadataByName: GraphQL$JSONObject | null;
  /**
   * get presigned url from S3
   */
  uploadUrl: string | null;
}

export interface CreatePhoto {
  /**
   * Create a single "Photo" node.
   */
  createPhoto: CreatePhoto_createPhoto;
}

export interface CreatePhotoVariables {
  data: PhotoCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePhoto
// ====================================================

export interface UpdatePhoto_updatePhoto_archive {
  __typename: "Archive";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * get the access rights of the archive
   */
  accesses: (string | null)[] | null;
}

export interface UpdatePhoto_updatePhoto_previews {
  __typename: "Preview";
  href: string | null;
  size: number | null;
}

export interface UpdatePhoto_updatePhoto {
  __typename: "Photo";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  archive: UpdatePhoto_updatePhoto_archive;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  height: number | null;
  width: number | null;
  filesize: number | null;
  originalName: string | null;
  /**
   * asset content type
   */
  contentType: string | null;
  /**
   * get a S3 download URL
   */
  downloadUrl: string | null;
  /**
   * get all supported resized previews
   */
  previews: UpdatePhoto_updatePhoto_previews[] | null;
  /**
   * optimized photo metadata listing by name for convenience
   */
  metadataByName: GraphQL$JSONObject | null;
}

export interface UpdatePhoto {
  /**
   * Update a single "Photo" node.
   */
  updatePhoto: UpdatePhoto_updatePhoto | null;
}

export interface UpdatePhotoVariables {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePhoto
// ====================================================

export interface DeletePhoto_deletePhoto {
  __typename: "Photo";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface DeletePhoto {
  /**
   * Delete a single "Photo" node.
   */
  deletePhoto: DeletePhoto_deletePhoto | null;
}

export interface DeletePhotoVariables {
  where: PhotoWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePhotoFile
// ====================================================

export interface UpdatePhotoFile_updatePhotoFile_archive {
  __typename: "Archive";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * get the access rights of the archive
   */
  accesses: (string | null)[] | null;
}

export interface UpdatePhotoFile_updatePhotoFile_previews {
  __typename: "Preview";
  href: string | null;
  size: number | null;
}

export interface UpdatePhotoFile_updatePhotoFile {
  __typename: "Photo";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  archive: UpdatePhotoFile_updatePhotoFile_archive;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  height: number | null;
  width: number | null;
  filesize: number | null;
  originalName: string | null;
  /**
   * asset content type
   */
  contentType: string | null;
  /**
   * get a S3 download URL
   */
  downloadUrl: string | null;
  /**
   * get all supported resized previews
   */
  previews: UpdatePhotoFile_updatePhotoFile_previews[] | null;
  /**
   * optimized photo metadata listing by name for convenience
   */
  metadataByName: GraphQL$JSONObject | null;
}

export interface UpdatePhotoFile {
  /**
   * Update files metadatas
   */
  updatePhotoFile: UpdatePhotoFile_updatePhotoFile | null;
}

export interface UpdatePhotoFileVariables {
  photoId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClonePhoto
// ====================================================

export interface ClonePhoto_clonePhoto_archive {
  __typename: "Archive";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * get the access rights of the archive
   */
  accesses: (string | null)[] | null;
}

export interface ClonePhoto_clonePhoto_previews {
  __typename: "Preview";
  href: string | null;
  size: number | null;
}

export interface ClonePhoto_clonePhoto {
  __typename: "Photo";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  archive: ClonePhoto_clonePhoto_archive;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  height: number | null;
  width: number | null;
  filesize: number | null;
  originalName: string | null;
  /**
   * asset content type
   */
  contentType: string | null;
  /**
   * get a S3 download URL
   */
  downloadUrl: string | null;
  /**
   * get all supported resized previews
   */
  previews: ClonePhoto_clonePhoto_previews[] | null;
  /**
   * optimized photo metadata listing by name for convenience
   */
  metadataByName: GraphQL$JSONObject | null;
}

export interface ClonePhoto {
  /**
   * clone a photo
   */
  clonePhoto: ClonePhoto_clonePhoto;
}

export interface ClonePhotoVariables {
  sourceId: string;
  create: PhotoCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetArchives
// ====================================================

export interface GetArchives_archives {
  __typename: "Archive";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * The internal ID
   */
  archiveId: number | null;
  /**
   * The archive type
   */
  type: ArchiveType | null;
  /**
   * get the title of the archive
   */
  title: string | null;
  /**
   * get the title of the archive
   */
  key: ArchiveKeyType | null;
  /**
   * get the access rights of the archive
   */
  accesses: (string | null)[] | null;
  hasCategory: boolean | null;
}

export interface GetArchives {
  /**
   * Retrieve a list of "Archive" nodes.
   */
  archives: GetArchives_archives[];
}

export interface GetArchivesVariables {
  where?: ArchiveWhereInput | null;
  first: number;
  orderBy?: ArchiveOrderByInput[] | null;
  skip?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCategories
// ====================================================

export interface GetCategories_categories_parent {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface GetCategories_categories {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  label: string | null;
  parent: GetCategories_categories_parent | null;
}

export interface GetCategories {
  /**
   * Retrieve a list of "Category" nodes.
   */
  categories: GetCategories_categories[];
}

export interface GetCategoriesVariables {
  where?: CategoryWhereInput | null;
  first: number;
  orderBy?: CategoryOrderByInput[] | null;
  skip?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUnderSubjects
// ====================================================

export interface GetUnderSubjects_metadataUndersubjectList {
  __typename: "MetadataUndersubject";
  /**
   * The ID
   */
  id: number;
  value: string | null;
}

export interface GetUnderSubjects {
  /**
   * Retrieve a list of "MetadataUndersubject" nodes.
   */
  metadataUndersubjectList: GetUnderSubjects_metadataUndersubjectList[];
}

export interface GetUnderSubjectsVariables {
  where: MetadataUndersubjectWhereInput;
  first: number;
  orderBy?: MetadataUndersubjectOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchPhotos
// ====================================================

export interface SearchPhotos_searchPhotos_archive {
  __typename: "Archive";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * get the access rights of the archive
   */
  accesses: (string | null)[] | null;
}

export interface SearchPhotos_searchPhotos_previews {
  __typename: "Preview";
  href: string | null;
  size: number | null;
}

export interface SearchPhotos_searchPhotos {
  __typename: "Photo";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  archive: SearchPhotos_searchPhotos_archive;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  height: number | null;
  width: number | null;
  filesize: number | null;
  originalName: string | null;
  /**
   * asset content type
   */
  contentType: string | null;
  /**
   * get a S3 download URL
   */
  downloadUrl: string | null;
  /**
   * get all supported resized previews
   */
  previews: SearchPhotos_searchPhotos_previews[] | null;
  /**
   * optimized photo metadata listing by name for convenience
   */
  metadataByName: GraphQL$JSONObject | null;
}

export interface SearchPhotos {
  /**
   * optimized for speed search photos query
   */
  searchPhotos: SearchPhotos_searchPhotos[];
}

export interface SearchPhotosVariables {
  where?: PhotoWhereInput | null;
  first: number;
  orderBy?: PhotoOrderByInput[] | null;
  skip?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPhotosCount
// ====================================================

export interface GetPhotosCount {
  /**
   * optimized for speed photos count query
   */
  photoCount: number;
}

export interface GetPhotosCountVariables {
  where?: PhotoWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Archive
// ====================================================

export interface Archive {
  __typename: "Archive";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * The internal ID
   */
  archiveId: number | null;
  /**
   * The archive type
   */
  type: ArchiveType | null;
  /**
   * get the title of the archive
   */
  title: string | null;
  /**
   * get the title of the archive
   */
  key: ArchiveKeyType | null;
  /**
   * get the access rights of the archive
   */
  accesses: (string | null)[] | null;
  hasCategory: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Category
// ====================================================

export interface Category_parent {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface Category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  label: string | null;
  parent: Category_parent | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Photo
// ====================================================

export interface Photo_archive {
  __typename: "Archive";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * get the access rights of the archive
   */
  accesses: (string | null)[] | null;
}

export interface Photo_previews {
  __typename: "Preview";
  href: string | null;
  size: number | null;
}

export interface Photo {
  __typename: "Photo";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  archive: Photo_archive;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  height: number | null;
  width: number | null;
  filesize: number | null;
  originalName: string | null;
  /**
   * asset content type
   */
  contentType: string | null;
  /**
   * get a S3 download URL
   */
  downloadUrl: string | null;
  /**
   * get all supported resized previews
   */
  previews: Photo_previews[] | null;
  /**
   * optimized photo metadata listing by name for convenience
   */
  metadataByName: GraphQL$JSONObject | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ArchiveKeyType {
  CAM_LIVRAISON = "CAM_LIVRAISON",
  CAM_SUJETS = "CAM_SUJETS",
  CBG_SUJETS = "CBG_SUJETS",
  CLE_SUJETS = "CLE_SUJETS",
  CTM_SUJETS = "CTM_SUJETS",
  FAP_BEAUTE_ARCHIVES = "FAP_BEAUTE_ARCHIVES",
  FAP_CUISINE_ARCHIVES = "FAP_CUISINE_ARCHIVES",
  FAP_DECO_ARCHIVES = "FAP_DECO_ARCHIVES",
  FATV_SUJETS = "FATV_SUJETS",
  GALA_LIVRAISON = "GALA_LIVRAISON",
  GAL_PEOPLE = "GAL_PEOPLE",
  GEO_ARCHIVES = "GEO_ARCHIVES",
  HBZ_ARCHIVE = "HBZ_ARCHIVE",
  HBZ_LIVRAISON = "HBZ_LIVRAISON",
  HBZ_SUJETS = "HBZ_SUJETS",
  MAD_SUJETS = "MAD_SUJETS",
  NEON_SUJETS = "NEON_SUJETS",
  NGE_ARCHIVE = "NGE_ARCHIVE",
  NGE_SUJETS = "NGE_SUJETS",
  NR_PERSONNALITES_ARCHIVES = "NR_PERSONNALITES_ARCHIVES",
  PECO_LIVRAISON = "PECO_LIVRAISON",
  PECO_SUJETS = "PECO_SUJETS",
  PECO_WEB = "PECO_WEB",
  PFEM_LIVRAISON = "PFEM_LIVRAISON",
  PFEM_SUJETS = "PFEM_SUJETS",
  PGAL_SUJETS = "PGAL_SUJETS",
  PGEO_SUJETS = "PGEO_SUJETS",
  PTVS_ARCHIVES_TV = "PTVS_ARCHIVES_TV",
  PTVS_LIVRAISON = "PTVS_LIVRAISON",
  PTVS_SUJETS = "PTVS_SUJETS",
  TEST_UNIT = "TEST_UNIT",
  TRV_CUI = "TRV_CUI",
  TRV_PHOTOS = "TRV_PHOTOS",
  TRV_WEB_GRAPHIX = "TRV_WEB_GRAPHIX",
  VOICI_LIVRAISON = "VOICI_LIVRAISON",
  VOICI_SUJETS = "VOICI_SUJETS",
  VOI_ARCHIVES = "VOI_ARCHIVES",
  VOI_BASE_PEOPLE = "VOI_BASE_PEOPLE",
  VOI_BEAUTE = "VOI_BEAUTE",
  VOI_MODE = "VOI_MODE",
  VOI_SCOOP = "VOI_SCOOP",
}

export enum ArchiveOrderByInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  archiveId_ASC = "archiveId_ASC",
  archiveId_DESC = "archiveId_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  hasCategories_ASC = "hasCategories_ASC",
  hasCategories_DESC = "hasCategories_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  label_ASC = "label_ASC",
  label_DESC = "label_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  type_ASC = "type_ASC",
  type_DESC = "type_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum ArchiveType {
  ARCHIVE = "ARCHIVE",
  DELIVERY = "DELIVERY",
  NEWS = "NEWS",
  SUJET = "SUJET",
}

export enum CategoryOrderByInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  label_ASC = "label_ASC",
  label_DESC = "label_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum ImportQueueStatusType {
  DELETED = "DELETED",
  FAILED = "FAILED",
  FINISHED = "FINISHED",
  PENDING = "PENDING",
}

export enum MetadataNameEnumType {
  AccessRights = "AccessRights",
  AddlModelInfo = "AddlModelInfo",
  Archive_child = "Archive_child",
  Archive_parent = "Archive_parent",
  AssetState = "AssetState",
  Audience = "Audience",
  AuthorsPosition = "AuthorsPosition",
  BackupName = "BackupName",
  BitmapGrayscalePicture = "BitmapGrayscalePicture",
  Brand = "Brand",
  BrandPrisma = "BrandPrisma",
  BrandShortName = "BrandShortName",
  CaptionWriter = "CaptionWriter",
  Category = "Category",
  CategoryPrisma = "CategoryPrisma",
  Certificate = "Certificate",
  CiAdrCity = "CiAdrCity",
  CiAdrCtry = "CiAdrCtry",
  CiAdrExtadr = "CiAdrExtadr",
  CiAdrPcode = "CiAdrPcode",
  CiAdrRegion = "CiAdrRegion",
  CiEmailWork = "CiEmailWork",
  CiTelWork = "CiTelWork",
  CiUrlWork = "CiUrlWork",
  City = "City",
  Classify = "Classify",
  Company = "Company",
  CompanyShortName = "CompanyShortName",
  Confidentiality = "Confidentiality",
  ContainedIn = "ContainedIn",
  ContentMediaKind = "ContentMediaKind",
  ContentValue = "ContentValue",
  CopyrightLayer = "CopyrightLayer",
  Country = "Country",
  CountryCode = "CountryCode",
  CreatedTime = "CreatedTime",
  CreatorTool = "CreatorTool",
  Credit = "Credit",
  CustomField14 = "CustomField14",
  CustomField15 = "CustomField15",
  CustomField16 = "CustomField16",
  CustomField17 = "CustomField17",
  CustomField20 = "CustomField20",
  CustomField5 = "CustomField5",
  CustomField6 = "CustomField6",
  CustomField7 = "CustomField7",
  CustomField9 = "CustomField9",
  DateCreated = "DateCreated",
  DateTimeDigitized = "DateTimeDigitized",
  DateTimeOriginal = "DateTimeOriginal",
  DeliveryAccount = "DeliveryAccount",
  DeliveryCompany = "DeliveryCompany",
  DeliveryCopyright = "DeliveryCopyright",
  DeliveryDateFolder = "DeliveryDateFolder",
  DeliveryDateTime = "DeliveryDateTime",
  DeliveryDcCreator = "DeliveryDcCreator",
  DeliveryDcRights = "DeliveryDcRights",
  DeliveryFileType = "DeliveryFileType",
  DeliveryFolder = "DeliveryFolder",
  DeliveryKind = "DeliveryKind",
  DeliveryPath = "DeliveryPath",
  DeliveryPersonShownintheImage = "DeliveryPersonShownintheImage",
  DeliveryPhotoshopCredit = "DeliveryPhotoshopCredit",
  DeliveryPhotoshopSource = "DeliveryPhotoshopSource",
  DeliveryService = "DeliveryService",
  DeliverySubject = "DeliverySubject",
  DeliveryUnderSubject = "DeliveryUnderSubject",
  Department = "Department",
  DigitalAssetURL = "DigitalAssetURL",
  Division = "Division",
  DocumentText = "DocumentText",
  EditStatus = "EditStatus",
  Edition = "Edition",
  EditorialVersion = "EditorialVersion",
  EnvironnementPhoto = "EnvironnementPhoto",
  EquipmentInstitution = "EquipmentInstitution",
  EquipmentManufacturer = "EquipmentManufacturer",
  Event = "Event",
  Exif = "Exif",
  FabStorage = "FabStorage",
  FileCheckFor = "FileCheckFor",
  FileInfoBitsPerPixel = "FileInfoBitsPerPixel",
  FileInfoFrames = "FileInfoFrames",
  FileInfoPhotometricInterpretation = "FileInfoPhotometricInterpretation",
  FileInfoTransferSyntax = "FileInfoTransferSyntax",
  FileStage = "FileStage",
  FileStatus = "FileStatus",
  FileType = "FileType",
  FixtureIdentifier = "FixtureIdentifier",
  Format = "Format",
  GPSLatitude = "GPSLatitude",
  GPSLongitude = "GPSLongitude",
  GPSProcessingMethod = "GPSProcessingMethod",
  Header = "Header",
  HeaderPrisma = "HeaderPrisma",
  Headline = "Headline",
  History = "History",
  ICCProfile = "ICCProfile",
  ID_subject = "ID_subject",
  ISOSpeedRatings = "ISOSpeedRatings",
  IdAssignment = "IdAssignment",
  IdMediaContent = "IdMediaContent",
  IdPhotoPrisma = "IdPhotoPrisma",
  ImageNotes = "ImageNotes",
  ImageUniqueID = "ImageUniqueID",
  Instructions = "Instructions",
  IntellectualGenre = "IntellectualGenre",
  Issue = "Issue",
  JobId = "JobId",
  JobProcessing = "JobProcessing",
  LayoutPictureEditing = "LayoutPictureEditing",
  LayoutStorage = "LayoutStorage",
  Links = "Links",
  LocalCaption = "LocalCaption",
  Location = "Location",
  Make = "Make",
  Manifest = "Manifest",
  Marked = "Marked",
  MasterDocumentId = "MasterDocumentId",
  MaxAvailHeight = "MaxAvailHeight",
  MaxAvailWidth = "MaxAvailWidth",
  MinorModelAgeDisclosure = "MinorModelAgeDisclosure",
  Model = "Model",
  ModelAge = "ModelAge",
  ModelReleaseID = "ModelReleaseID",
  ModelReleaseStatus = "ModelReleaseStatus",
  ObjectCycle = "ObjectCycle",
  OrganisationInImageName = "OrganisationInImageName",
  OriginalColor = "OriginalColor",
  OriginalICCProfile = "OriginalICCProfile",
  OriginalName = "OriginalName",
  OriginalWeigth = "OriginalWeigth",
  Owner = "Owner",
  OwnerId = "OwnerId",
  Page = "Page",
  PagePrisma = "PagePrisma",
  PaintBasedCorrections = "PaintBasedCorrections",
  PatientDOB = "PatientDOB",
  PatientID = "PatientID",
  PatientName = "PatientName",
  PatientSex = "PatientSex",
  PersonInImage = "PersonInImage",
  PhotoStorage = "PhotoStorage",
  PrepressPictureEditing = "PrepressPictureEditing",
  PriceLevel = "PriceLevel",
  PrintingProfile = "PrintingProfile",
  PrismaProduction = "PrismaProduction",
  ProcessHistory = "ProcessHistory",
  ProcessParameter = "ProcessParameter",
  ProcessStatus = "ProcessStatus",
  Product = "Product",
  ProductShortName = "ProductShortName",
  Products = "Products",
  Produits = "Produits",
  ProgramVersion = "ProgramVersion",
  PropertyReleaseID = "PropertyReleaseID",
  PropertyReleaseStatus = "PropertyReleaseStatus",
  Publisher = "Publisher",
  QualifiedUsePrismaBy = "QualifiedUsePrismaBy",
  QualifiedUsePrismaDate = "QualifiedUsePrismaDate",
  QualifiedUsePrismaDuration = "QualifiedUsePrismaDuration",
  QualifiedUsePrismaSupport = "QualifiedUsePrismaSupport",
  Rating = "Rating",
  ReferenceDate = "ReferenceDate",
  ReferenceNumber = "ReferenceNumber",
  ReferenceService = "ReferenceService",
  ReleaseDate = "ReleaseDate",
  ReleaseTime = "ReleaseTime",
  RequiredPermission = "RequiredPermission",
  ResolutionKind = "ResolutionKind",
  Royaltyfree = "Royaltyfree",
  Scene = "Scene",
  Section = "Section",
  SentTo = "SentTo",
  SerialNumber = "SerialNumber",
  SeriesDateTime = "SeriesDateTime",
  SeriesDescription = "SeriesDescription",
  SeriesModality = "SeriesModality",
  SeriesNumber = "SeriesNumber",
  ShortUniqueId = "ShortUniqueId",
  Source = "Source",
  State = "State",
  StudyDateTime = "StudyDateTime",
  StudyDescription = "StudyDescription",
  StudyID = "StudyID",
  StudyPhysician = "StudyPhysician",
  SubjectCode = "SubjectCode",
  SubjectPrisma = "SubjectPrisma",
  SupplementalCategories = "SupplementalCategories",
  TargetVersion = "TargetVersion",
  ToneCurve = "ToneCurve",
  TransferredBy = "TransferredBy",
  TransferredByEmail = "TransferredByEmail",
  TransferredByFullName = "TransferredByFullName",
  TransmissionReference = "TransmissionReference",
  Type = "Type",
  UnderUnderSubject = "UnderUnderSubject",
  Undersubject = "Undersubject",
  UniqueId = "UniqueId",
  Unit = "Unit",
  UnitShortName = "UnitShortName",
  UploadTime = "UploadTime",
  UploadedBy = "UploadedBy",
  UploadedByFullName = "UploadedByFullName",
  Urgency = "Urgency",
  UsageTerms = "UsageTerms",
  UserComment = "UserComment",
  UserDefined195 = "UserDefined195",
  UserDefined237 = "UserDefined237",
  UserDefined238 = "UserDefined238",
  UserDefined239 = "UserDefined239",
  UserDefined242 = "UserDefined242",
  UserDefined62 = "UserDefined62",
  Valid = "Valid",
  Version = "Version",
  WebStatement = "WebStatement",
  WorkflowKind = "WorkflowKind",
  XMPFileStamps = "XMPFileStamps",
  XMPHistory = "XMPHistory",
  attributionName = "attributionName",
  attributionURL = "attributionURL",
  creator = "creator",
  description = "description",
  license = "license",
  morePermissions = "morePermissions",
  publishingIssue = "publishingIssue",
  publishingSubject = "publishingSubject",
  rights = "rights",
  subject = "subject",
  title = "title",
}

export enum MetadataUndersubjectOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  value_ASC = "value_ASC",
  value_DESC = "value_DESC",
}

export enum MetadataValueTypeEnum {
  Date = "Date",
  List = "List",
  Text = "Text",
}

export enum PhotoOrderByInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  contentType_ASC = "contentType_ASC",
  contentType_DESC = "contentType_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  filesize_ASC = "filesize_ASC",
  filesize_DESC = "filesize_DESC",
  height_ASC = "height_ASC",
  height_DESC = "height_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  isLegacy_ASC = "isLegacy_ASC",
  isLegacy_DESC = "isLegacy_DESC",
  isUploaded_ASC = "isUploaded_ASC",
  isUploaded_DESC = "isUploaded_DESC",
  originalName_ASC = "originalName_ASC",
  originalName_DESC = "originalName_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  width_ASC = "width_ASC",
  width_DESC = "width_DESC",
}

export interface AgencyCreateInput {
  name: string;
  label: string;
  archiveAgencies?: AgencyNestedArchiveAgenciesCreateInput | null;
  photos?: AgencyNestedPhotosCreateInput | null;
}

export interface AgencyNestedArchiveAgenciesCreateInput {
  connect?: ArchiveAgencyWhereUniqueInput[] | null;
  create?: ArchiveAgencyWithForcedAgencyCreateInput[] | null;
}

export interface AgencyNestedArchiveAgenciesUpdateInput {
  connect?: ArchiveAgencyWhereUniqueInput[] | null;
  update?: AgencyNestedUpdateArchiveAgenciesUpdateInput[] | null;
  create?: ArchiveAgencyWithForcedAgencyCreateInput[] | null;
  upsert?: AgencyNestedUpsertArchiveAgenciesUpdateInput[] | null;
  delete?: ArchiveAgencyWithForcedAgencyWhereUniqueInput[] | null;
}

export interface AgencyNestedPhotosCreateInput {
  connect?: PhotoWhereUniqueInput[] | null;
  create?: PhotoWithForcedAgencyCreateInput[] | null;
}

export interface AgencyNestedPhotosUpdateInput {
  connect?: PhotoWhereUniqueInput[] | null;
  disconnect?: PhotoWhereUniqueInput[] | null;
  update?: AgencyNestedUpdatePhotosUpdateInput[] | null;
  create?: PhotoWithForcedAgencyCreateInput[] | null;
  upsert?: AgencyNestedUpsertPhotosUpdateInput[] | null;
  delete?: PhotoWhereUniqueInput[] | null;
}

export interface AgencyNestedUpdateArchiveAgenciesUpdateInput {
  where: ArchiveAgencyWithOptionalAgencyWhereUniqueInput;
  data: ArchiveAgencyWithForcedAgencyUpdateInput;
}

export interface AgencyNestedUpdatePhotosUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoWithForcedAgencyUpdateInput;
}

export interface AgencyNestedUpsertArchiveAgenciesUpdateInput {
  where: ArchiveAgencyWithOptionalAgencyWhereUniqueInput;
  update: ArchiveAgencyWithForcedAgencyUpdateInput;
  create: ArchiveAgencyWithForcedAgencyCreateInput;
}

export interface AgencyNestedUpsertPhotosUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoWithForcedAgencyUpdateInput;
  create: PhotoWithForcedAgencyCreateInput;
}

export interface AgencyUpdateInput {
  name?: string | null;
  label?: string | null;
  archiveAgencies?: AgencyNestedArchiveAgenciesUpdateInput | null;
  photos?: AgencyNestedPhotosUpdateInput | null;
}

/**
 * Filters the "Agency" nodes by specifying some conditions
 */
export interface AgencyWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  label?: string | null;
  label_not?: string | null;
  label_in?: string[] | null;
  label_not_in?: string[] | null;
  label_contains?: string | null;
  label_not_contains?: string | null;
  label_starts_with?: string | null;
  label_not_starts_with?: string | null;
  label_ends_with?: string | null;
  label_not_ends_with?: string | null;
  label_lt?: string | null;
  label_lte?: string | null;
  label_gt?: string | null;
  label_gte?: string | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  archiveAgencies_some?: ArchiveAgencyWhereInput | null;
  photos_some?: PhotoWhereInput | null;
  AND?: AgencyWhereInput[] | null;
  OR?: AgencyWhereInput[] | null;
  NOT?: AgencyWhereInput | null;
}

/**
 * Identifies exactly one "Agency" node with one of these unique values:
 * - _id
 * - name
 * - label
 */
export interface AgencyWhereUniqueInput {
  _id?: number | null;
  name?: string | null;
  label?: string | null;
}

export interface ArchiveAgencyNestedAgencyCreateInput {
  connect?: AgencyWhereUniqueInput | null;
  update?: ArchiveAgencyNestedUpdateAgencyCreateInput | null;
  create?: AgencyCreateInput | null;
  upsert?: ArchiveAgencyNestedUpsertAgencyCreateInput | null;
}

export interface ArchiveAgencyNestedAgencyUpdateInput {
  connect?: AgencyWhereUniqueInput | null;
  update?: ArchiveAgencyNestedUpdateAgencyUpdateInput | null;
  create?: AgencyCreateInput | null;
  upsert?: ArchiveAgencyNestedUpsertAgencyUpdateInput | null;
}

export interface ArchiveAgencyNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: ArchiveAgencyNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: ArchiveAgencyNestedUpsertArchiveCreateInput | null;
}

export interface ArchiveAgencyNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: ArchiveAgencyNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: ArchiveAgencyNestedUpsertArchiveUpdateInput | null;
}

export interface ArchiveAgencyNestedUpdateAgencyCreateInput {
  where: AgencyWhereUniqueInput;
  data: AgencyUpdateInput;
}

export interface ArchiveAgencyNestedUpdateAgencyUpdateInput {
  where: AgencyWhereUniqueInput;
  data: AgencyUpdateInput;
}

export interface ArchiveAgencyNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface ArchiveAgencyNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface ArchiveAgencyNestedUpsertAgencyCreateInput {
  where: AgencyWhereUniqueInput;
  update: AgencyUpdateInput;
  create: AgencyCreateInput;
}

export interface ArchiveAgencyNestedUpsertAgencyUpdateInput {
  where: AgencyWhereUniqueInput;
  update: AgencyUpdateInput;
  create: AgencyCreateInput;
}

export interface ArchiveAgencyNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface ArchiveAgencyNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

/**
 * Filters the "ArchiveAgency" nodes by specifying some conditions
 */
export interface ArchiveAgencyWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  agency?: AgencyWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: ArchiveAgencyWhereInput[] | null;
  OR?: ArchiveAgencyWhereInput[] | null;
  NOT?: ArchiveAgencyWhereInput | null;
}

/**
 * Identifies exactly one "ArchiveAgency" node with one of these unique combinations of values:
 * - _id
 * - agency, archive
 */
export interface ArchiveAgencyWhereUniqueInput {
  _id?: number | null;
  agency?: AgencyWhereUniqueInput | null;
  archive?: ArchiveWhereUniqueInput | null;
}

export interface ArchiveAgencyWithForcedAgencyCreateInput {
  archive: ArchiveAgencyNestedArchiveCreateInput;
}

export interface ArchiveAgencyWithForcedAgencyUpdateInput {
  archive?: ArchiveAgencyNestedArchiveUpdateInput | null;
}

/**
 * Given a forced "agency", identifies exactly one "ArchiveAgency" node.
 */
export interface ArchiveAgencyWithForcedAgencyWhereUniqueInput {
  archive: ArchiveWhereUniqueInput;
}

export interface ArchiveAgencyWithForcedArchiveCreateInput {
  agency: ArchiveAgencyNestedAgencyCreateInput;
}

export interface ArchiveAgencyWithForcedArchiveUpdateInput {
  agency?: ArchiveAgencyNestedAgencyUpdateInput | null;
}

/**
 * Given a forced "archive", identifies exactly one "ArchiveAgency" node.
 */
export interface ArchiveAgencyWithForcedArchiveWhereUniqueInput {
  agency: AgencyWhereUniqueInput;
}

/**
 * Given a known "agency", identifies exactly one "ArchiveAgency" node with one of these unique combinations of values:
 * - _id
 * - agency (optional), archive
 */
export interface ArchiveAgencyWithOptionalAgencyWhereUniqueInput {
  _id?: number | null;
  agency?: AgencyWhereUniqueInput | null;
  archive?: ArchiveWhereUniqueInput | null;
}

/**
 * Given a known "archive", identifies exactly one "ArchiveAgency" node with one of these unique combinations of values:
 * - _id
 * - agency, archive (optional)
 */
export interface ArchiveAgencyWithOptionalArchiveWhereUniqueInput {
  _id?: number | null;
  agency?: AgencyWhereUniqueInput | null;
  archive?: ArchiveWhereUniqueInput | null;
}

export interface ArchiveCreateInput {
  id?: string | null;
  archiveId?: number | null;
  name?: ArchiveKeyType | null;
  label?: string | null;
  type?: ArchiveType | null;
  hasCategories?: boolean | null;
  archiveAgencies?: ArchiveNestedArchiveAgenciesCreateInput | null;
  categories?: ArchiveNestedCategoriesCreateInput | null;
  deliverySubjects?: ArchiveNestedDeliverySubjectsCreateInput | null;
  gcImportSetting?: ArchiveNestedGcImportSettingCreateInput | null;
  importQueues?: ArchiveNestedImportQueuesCreateInput | null;
  photos?: ArchiveNestedPhotosCreateInput | null;
  photoMetadatas?: ArchiveNestedPhotoMetadatasCreateInput | null;
  metadataSerialNumberList?: ArchiveNestedMetadataSerialNumberListCreateInput | null;
  metadataAttributionNameList?: ArchiveNestedMetadataAttributionNameListCreateInput | null;
  metadataAttributionUrlList?: ArchiveNestedMetadataAttributionUrlListCreateInput | null;
  metadataLicenseList?: ArchiveNestedMetadataLicenseListCreateInput | null;
  metadataMorePermissionsList?: ArchiveNestedMetadataMorePermissionsListCreateInput | null;
  metadataPaintBasedCorrectionsList?: ArchiveNestedMetadataPaintBasedCorrectionsListCreateInput | null;
  metadataToneCurveList?: ArchiveNestedMetadataToneCurveListCreateInput | null;
  metadataCreatorList?: ArchiveNestedMetadataCreatorListCreateInput | null;
  metadataDescriptionList?: ArchiveNestedMetadataDescriptionListCreateInput | null;
  metadataFormatList?: ArchiveNestedMetadataFormatListCreateInput | null;
  metadataPageList?: ArchiveNestedMetadataPageListCreateInput | null;
  metadataPublisherList?: ArchiveNestedMetadataPublisherListCreateInput | null;
  metadataRightsList?: ArchiveNestedMetadataRightsListCreateInput | null;
  metadataSubjectList?: ArchiveNestedMetadataSubjectListCreateInput | null;
  metadataTitleList?: ArchiveNestedMetadataTitleListCreateInput | null;
  metadataTypeList?: ArchiveNestedMetadataTypeListCreateInput | null;
  metadataAccessRightsList?: ArchiveNestedMetadataAccessRightsListCreateInput | null;
  metadataAudienceList?: ArchiveNestedMetadataAudienceListCreateInput | null;
  metadataValidList?: ArchiveNestedMetadataValidListCreateInput | null;
  metadataEquipmentInstitutionList?: ArchiveNestedMetadataEquipmentInstitutionListCreateInput | null;
  metadataEquipmentManufacturerList?: ArchiveNestedMetadataEquipmentManufacturerListCreateInput | null;
  metadataFileInfoBitsPerPixelList?: ArchiveNestedMetadataFileInfoBitsPerPixelListCreateInput | null;
  metadataFileInfoFramesList?: ArchiveNestedMetadataFileInfoFramesListCreateInput | null;
  metadataFileInfoPhotometricInterpretationList?: ArchiveNestedMetadataFileInfoPhotometricInterpretationListCreateInput | null;
  metadataFileInfoTransferSyntaxList?: ArchiveNestedMetadataFileInfoTransferSyntaxListCreateInput | null;
  metadataPatientDobList?: ArchiveNestedMetadataPatientDobListCreateInput | null;
  metadataPatientIdList?: ArchiveNestedMetadataPatientIdListCreateInput | null;
  metadataPatientNameList?: ArchiveNestedMetadataPatientNameListCreateInput | null;
  metadataPatientSexList?: ArchiveNestedMetadataPatientSexListCreateInput | null;
  metadataSeriesDateTimeList?: ArchiveNestedMetadataSeriesDateTimeListCreateInput | null;
  metadataSeriesDescriptionList?: ArchiveNestedMetadataSeriesDescriptionListCreateInput | null;
  metadataSeriesModalityList?: ArchiveNestedMetadataSeriesModalityListCreateInput | null;
  metadataSeriesNumberList?: ArchiveNestedMetadataSeriesNumberListCreateInput | null;
  metadataStudyDateTimeList?: ArchiveNestedMetadataStudyDateTimeListCreateInput | null;
  metadataStudyDescriptionList?: ArchiveNestedMetadataStudyDescriptionListCreateInput | null;
  metadataStudyIdList?: ArchiveNestedMetadataStudyIdListCreateInput | null;
  metadataStudyPhysicianList?: ArchiveNestedMetadataStudyPhysicianListCreateInput | null;
  metadataDateTimeDigitizedList?: ArchiveNestedMetadataDateTimeDigitizedListCreateInput | null;
  metadataDateTimeOriginalList?: ArchiveNestedMetadataDateTimeOriginalListCreateInput | null;
  metadataGpsLatitudeList?: ArchiveNestedMetadataGpsLatitudeListCreateInput | null;
  metadataGpsLongitudeList?: ArchiveNestedMetadataGpsLongitudeListCreateInput | null;
  metadataGpsProcessingMethodList?: ArchiveNestedMetadataGpsProcessingMethodListCreateInput | null;
  metadataImageUniqueIdList?: ArchiveNestedMetadataImageUniqueIdListCreateInput | null;
  metadataIsoSpeedRatingsList?: ArchiveNestedMetadataIsoSpeedRatingsListCreateInput | null;
  metadataUserCommentList?: ArchiveNestedMetadataUserCommentListCreateInput | null;
  metadataCustomField14List?: ArchiveNestedMetadataCustomField14ListCreateInput | null;
  metadataCustomField15List?: ArchiveNestedMetadataCustomField15ListCreateInput | null;
  metadataCustomField16List?: ArchiveNestedMetadataCustomField16ListCreateInput | null;
  metadataCustomField17List?: ArchiveNestedMetadataCustomField17ListCreateInput | null;
  metadataCustomField20List?: ArchiveNestedMetadataCustomField20ListCreateInput | null;
  metadataCustomField5List?: ArchiveNestedMetadataCustomField5ListCreateInput | null;
  metadataCustomField6List?: ArchiveNestedMetadataCustomField6ListCreateInput | null;
  metadataCustomField7List?: ArchiveNestedMetadataCustomField7ListCreateInput | null;
  metadataCustomField9List?: ArchiveNestedMetadataCustomField9ListCreateInput | null;
  metadataCreatedTimeList?: ArchiveNestedMetadataCreatedTimeListCreateInput | null;
  metadataEditStatusList?: ArchiveNestedMetadataEditStatusListCreateInput | null;
  metadataFixtureIdentifierList?: ArchiveNestedMetadataFixtureIdentifierListCreateInput | null;
  metadataLocalCaptionList?: ArchiveNestedMetadataLocalCaptionListCreateInput | null;
  metadataProgramVersionList?: ArchiveNestedMetadataProgramVersionListCreateInput | null;
  metadataReferenceDateList?: ArchiveNestedMetadataReferenceDateListCreateInput | null;
  metadataReferenceNumberList?: ArchiveNestedMetadataReferenceNumberListCreateInput | null;
  metadataReferenceServiceList?: ArchiveNestedMetadataReferenceServiceListCreateInput | null;
  metadataReleaseDateList?: ArchiveNestedMetadataReleaseDateListCreateInput | null;
  metadataReleaseTimeList?: ArchiveNestedMetadataReleaseTimeListCreateInput | null;
  metadataAssetStateList?: ArchiveNestedMetadataAssetStateListCreateInput | null;
  metadataClassifyList?: ArchiveNestedMetadataClassifyListCreateInput | null;
  metadataContainedInList?: ArchiveNestedMetadataContainedInListCreateInput | null;
  metadataContentValueList?: ArchiveNestedMetadataContentValueListCreateInput | null;
  metadataDocumentTextList?: ArchiveNestedMetadataDocumentTextListCreateInput | null;
  metadataExifList?: ArchiveNestedMetadataExifListCreateInput | null;
  metadataHistoryList?: ArchiveNestedMetadataHistoryListCreateInput | null;
  metadataImageNotesList?: ArchiveNestedMetadataImageNotesListCreateInput | null;
  metadataJobIdList?: ArchiveNestedMetadataJobIdListCreateInput | null;
  metadataLinksList?: ArchiveNestedMetadataLinksListCreateInput | null;
  metadataMasterDocumentIdList?: ArchiveNestedMetadataMasterDocumentIdListCreateInput | null;
  metadataObjectCycleList?: ArchiveNestedMetadataObjectCycleListCreateInput | null;
  metadataOwnerIdList?: ArchiveNestedMetadataOwnerIdListCreateInput | null;
  metadataShortUniqueIdList?: ArchiveNestedMetadataShortUniqueIdListCreateInput | null;
  metadataTransferredByList?: ArchiveNestedMetadataTransferredByListCreateInput | null;
  metadataTransferredByEmailList?: ArchiveNestedMetadataTransferredByEmailListCreateInput | null;
  metadataTransferredByFullNameList?: ArchiveNestedMetadataTransferredByFullNameListCreateInput | null;
  metadataUniqueIdList?: ArchiveNestedMetadataUniqueIdListCreateInput | null;
  metadataUploadedByList?: ArchiveNestedMetadataUploadedByListCreateInput | null;
  metadataUploadedByFullNameList?: ArchiveNestedMetadataUploadedByFullNameListCreateInput | null;
  metadataUploadTimeList?: ArchiveNestedMetadataUploadTimeListCreateInput | null;
  metadataUserDefined195List?: ArchiveNestedMetadataUserDefined195ListCreateInput | null;
  metadataUserDefined237List?: ArchiveNestedMetadataUserDefined237ListCreateInput | null;
  metadataUserDefined238List?: ArchiveNestedMetadataUserDefined238ListCreateInput | null;
  metadataUserDefined239List?: ArchiveNestedMetadataUserDefined239ListCreateInput | null;
  metadataUserDefined242List?: ArchiveNestedMetadataUserDefined242ListCreateInput | null;
  metadataUserDefined62List?: ArchiveNestedMetadataUserDefined62ListCreateInput | null;
  metadataCiAdrCityList?: ArchiveNestedMetadataCiAdrCityListCreateInput | null;
  metadataCiAdrCtryList?: ArchiveNestedMetadataCiAdrCtryListCreateInput | null;
  metadataCiAdrExtadrList?: ArchiveNestedMetadataCiAdrExtadrListCreateInput | null;
  metadataCiAdrPcodeList?: ArchiveNestedMetadataCiAdrPcodeListCreateInput | null;
  metadataCiAdrRegionList?: ArchiveNestedMetadataCiAdrRegionListCreateInput | null;
  metadataCiEmailWorkList?: ArchiveNestedMetadataCiEmailWorkListCreateInput | null;
  metadataCiTelWorkList?: ArchiveNestedMetadataCiTelWorkListCreateInput | null;
  metadataCiUrlWorkList?: ArchiveNestedMetadataCiUrlWorkListCreateInput | null;
  metadataCountryCodeList?: ArchiveNestedMetadataCountryCodeListCreateInput | null;
  metadataIntellectualGenreList?: ArchiveNestedMetadataIntellectualGenreListCreateInput | null;
  metadataLocationList?: ArchiveNestedMetadataLocationListCreateInput | null;
  metadataSceneList?: ArchiveNestedMetadataSceneListCreateInput | null;
  metadataSubjectCodeList?: ArchiveNestedMetadataSubjectCodeListCreateInput | null;
  metadataAddlModelInfoList?: ArchiveNestedMetadataAddlModelInfoListCreateInput | null;
  metadataEventList?: ArchiveNestedMetadataEventListCreateInput | null;
  metadataMaxAvailHeightList?: ArchiveNestedMetadataMaxAvailHeightListCreateInput | null;
  metadataMaxAvailWidthList?: ArchiveNestedMetadataMaxAvailWidthListCreateInput | null;
  metadataModelAgeList?: ArchiveNestedMetadataModelAgeListCreateInput | null;
  metadataOrganisationInImageNameList?: ArchiveNestedMetadataOrganisationInImageNameListCreateInput | null;
  metadataPersonInImageList?: ArchiveNestedMetadataPersonInImageListCreateInput | null;
  metadataAuthorsPositionList?: ArchiveNestedMetadataAuthorsPositionListCreateInput | null;
  metadataCaptionWriterList?: ArchiveNestedMetadataCaptionWriterListCreateInput | null;
  metadataCategoryList?: ArchiveNestedMetadataCategoryListCreateInput | null;
  metadataCityList?: ArchiveNestedMetadataCityListCreateInput | null;
  metadataCountryList?: ArchiveNestedMetadataCountryListCreateInput | null;
  metadataCreditList?: ArchiveNestedMetadataCreditListCreateInput | null;
  metadataDateCreatedList?: ArchiveNestedMetadataDateCreatedListCreateInput | null;
  metadataHeadlineList?: ArchiveNestedMetadataHeadlineListCreateInput | null;
  metadataIccProfileList?: ArchiveNestedMetadataIccProfileListCreateInput | null;
  metadataInstructionsList?: ArchiveNestedMetadataInstructionsListCreateInput | null;
  metadataSourceList?: ArchiveNestedMetadataSourceListCreateInput | null;
  metadataStateList?: ArchiveNestedMetadataStateListCreateInput | null;
  metadataSupplementalCategoriesList?: ArchiveNestedMetadataSupplementalCategoriesListCreateInput | null;
  metadataTransmissionReferenceList?: ArchiveNestedMetadataTransmissionReferenceListCreateInput | null;
  metadataUrgencyList?: ArchiveNestedMetadataUrgencyListCreateInput | null;
  metadataMinorModelAgeDisclosureList?: ArchiveNestedMetadataMinorModelAgeDisclosureListCreateInput | null;
  metadataModelReleaseIdList?: ArchiveNestedMetadataModelReleaseIdListCreateInput | null;
  metadataModelReleaseStatusList?: ArchiveNestedMetadataModelReleaseStatusListCreateInput | null;
  metadataPropertyReleaseIdList?: ArchiveNestedMetadataPropertyReleaseIdListCreateInput | null;
  metadataPropertyReleaseStatusList?: ArchiveNestedMetadataPropertyReleaseStatusListCreateInput | null;
  metadataVersionList?: ArchiveNestedMetadataVersionListCreateInput | null;
  metadataBrandList?: ArchiveNestedMetadataBrandListCreateInput | null;
  metadataDivisionList?: ArchiveNestedMetadataDivisionListCreateInput | null;
  metadataHeaderList?: ArchiveNestedMetadataHeaderListCreateInput | null;
  metadataProductsList?: ArchiveNestedMetadataProductsListCreateInput | null;
  metadataProduitsList?: ArchiveNestedMetadataProduitsListCreateInput | null;
  metadataPublishingIssueList?: ArchiveNestedMetadataPublishingIssueListCreateInput | null;
  metadataPublishingSubjectList?: ArchiveNestedMetadataPublishingSubjectListCreateInput | null;
  metadataCompanyList?: ArchiveNestedMetadataCompanyListCreateInput | null;
  metadataCompanyShortNameList?: ArchiveNestedMetadataCompanyShortNameListCreateInput | null;
  metadataConfidentialityList?: ArchiveNestedMetadataConfidentialityListCreateInput | null;
  metadataEditorialVersionList?: ArchiveNestedMetadataEditorialVersionListCreateInput | null;
  metadataFileCheckForList?: ArchiveNestedMetadataFileCheckForListCreateInput | null;
  metadataFileStageList?: ArchiveNestedMetadataFileStageListCreateInput | null;
  metadataFileStatusList?: ArchiveNestedMetadataFileStatusListCreateInput | null;
  metadataSentToList?: ArchiveNestedMetadataSentToListCreateInput | null;
  metadataTargetVersionList?: ArchiveNestedMetadataTargetVersionListCreateInput | null;
  metadataUnitList?: ArchiveNestedMetadataUnitListCreateInput | null;
  metadataArchiveChildList?: ArchiveNestedMetadataArchiveChildListCreateInput | null;
  metadataArchiveParentList?: ArchiveNestedMetadataArchiveParentListCreateInput | null;
  metadataBackupNameList?: ArchiveNestedMetadataBackupNameListCreateInput | null;
  metadataBitmapGrayscalePictureList?: ArchiveNestedMetadataBitmapGrayscalePictureListCreateInput | null;
  metadataBrandPrismaList?: ArchiveNestedMetadataBrandPrismaListCreateInput | null;
  metadataBrandShortNameList?: ArchiveNestedMetadataBrandShortNameListCreateInput | null;
  metadataCategoryPrismaList?: ArchiveNestedMetadataCategoryPrismaListCreateInput | null;
  metadataContentMediaKindList?: ArchiveNestedMetadataContentMediaKindListCreateInput | null;
  metadataCopyrightLayerList?: ArchiveNestedMetadataCopyrightLayerListCreateInput | null;
  metadataDeliveryAccountList?: ArchiveNestedMetadataDeliveryAccountListCreateInput | null;
  metadataDeliveryCompanyList?: ArchiveNestedMetadataDeliveryCompanyListCreateInput | null;
  metadataDeliveryCopyrightList?: ArchiveNestedMetadataDeliveryCopyrightListCreateInput | null;
  metadataDeliveryDateFolderList?: ArchiveNestedMetadataDeliveryDateFolderListCreateInput | null;
  metadataDeliveryDateTimeList?: ArchiveNestedMetadataDeliveryDateTimeListCreateInput | null;
  metadataDeliveryDcCreatorList?: ArchiveNestedMetadataDeliveryDcCreatorListCreateInput | null;
  metadataDeliveryDcRightsList?: ArchiveNestedMetadataDeliveryDcRightsListCreateInput | null;
  metadataDeliveryFileTypeList?: ArchiveNestedMetadataDeliveryFileTypeListCreateInput | null;
  metadataDeliveryFolderList?: ArchiveNestedMetadataDeliveryFolderListCreateInput | null;
  metadataDeliveryKindList?: ArchiveNestedMetadataDeliveryKindListCreateInput | null;
  metadataDeliveryPathList?: ArchiveNestedMetadataDeliveryPathListCreateInput | null;
  metadataDeliveryPersonShownintheImageList?: ArchiveNestedMetadataDeliveryPersonShownintheImageListCreateInput | null;
  metadataDeliveryPhotoshopCreditList?: ArchiveNestedMetadataDeliveryPhotoshopCreditListCreateInput | null;
  metadataDeliveryPhotoshopSourceList?: ArchiveNestedMetadataDeliveryPhotoshopSourceListCreateInput | null;
  metadataDeliveryServiceList?: ArchiveNestedMetadataDeliveryServiceListCreateInput | null;
  metadataDeliverySubjectList?: ArchiveNestedMetadataDeliverySubjectListCreateInput | null;
  metadataDeliveryUnderSubjectList?: ArchiveNestedMetadataDeliveryUnderSubjectListCreateInput | null;
  metadataDepartmentList?: ArchiveNestedMetadataDepartmentListCreateInput | null;
  metadataDigitalAssetUrlList?: ArchiveNestedMetadataDigitalAssetUrlListCreateInput | null;
  metadataEditionList?: ArchiveNestedMetadataEditionListCreateInput | null;
  metadataEnvironnementPhotoList?: ArchiveNestedMetadataEnvironnementPhotoListCreateInput | null;
  metadataFabStorageList?: ArchiveNestedMetadataFabStorageListCreateInput | null;
  metadataFileTypeList?: ArchiveNestedMetadataFileTypeListCreateInput | null;
  metadataHeaderPrismaList?: ArchiveNestedMetadataHeaderPrismaListCreateInput | null;
  metadataIdSubjectList?: ArchiveNestedMetadataIdSubjectListCreateInput | null;
  metadataIdAssignmentList?: ArchiveNestedMetadataIdAssignmentListCreateInput | null;
  metadataIdMediaContentList?: ArchiveNestedMetadataIdMediaContentListCreateInput | null;
  metadataIdPhotoPrismaList?: ArchiveNestedMetadataIdPhotoPrismaListCreateInput | null;
  metadataIssueList?: ArchiveNestedMetadataIssueListCreateInput | null;
  metadataJobProcessingList?: ArchiveNestedMetadataJobProcessingListCreateInput | null;
  metadataLayoutPictureEditingList?: ArchiveNestedMetadataLayoutPictureEditingListCreateInput | null;
  metadataLayoutStorageList?: ArchiveNestedMetadataLayoutStorageListCreateInput | null;
  metadataOriginalColorList?: ArchiveNestedMetadataOriginalColorListCreateInput | null;
  metadataOriginalIccProfileList?: ArchiveNestedMetadataOriginalIccProfileListCreateInput | null;
  metadataOriginalNameList?: ArchiveNestedMetadataOriginalNameListCreateInput | null;
  metadataOriginalWeigthList?: ArchiveNestedMetadataOriginalWeigthListCreateInput | null;
  metadataPagePrismaList?: ArchiveNestedMetadataPagePrismaListCreateInput | null;
  metadataPhotoStorageList?: ArchiveNestedMetadataPhotoStorageListCreateInput | null;
  metadataPrepressPictureEditingList?: ArchiveNestedMetadataPrepressPictureEditingListCreateInput | null;
  metadataPriceLevelList?: ArchiveNestedMetadataPriceLevelListCreateInput | null;
  metadataPrintingProfileList?: ArchiveNestedMetadataPrintingProfileListCreateInput | null;
  metadataPrismaProductionList?: ArchiveNestedMetadataPrismaProductionListCreateInput | null;
  metadataProcessHistoryList?: ArchiveNestedMetadataProcessHistoryListCreateInput | null;
  metadataProcessParameterList?: ArchiveNestedMetadataProcessParameterListCreateInput | null;
  metadataProcessStatusList?: ArchiveNestedMetadataProcessStatusListCreateInput | null;
  metadataProductList?: ArchiveNestedMetadataProductListCreateInput | null;
  metadataProductShortNameList?: ArchiveNestedMetadataProductShortNameListCreateInput | null;
  metadataQualifiedUsePrismaByList?: ArchiveNestedMetadataQualifiedUsePrismaByListCreateInput | null;
  metadataQualifiedUsePrismaDateList?: ArchiveNestedMetadataQualifiedUsePrismaDateListCreateInput | null;
  metadataQualifiedUsePrismaDurationList?: ArchiveNestedMetadataQualifiedUsePrismaDurationListCreateInput | null;
  metadataQualifiedUsePrismaSupportList?: ArchiveNestedMetadataQualifiedUsePrismaSupportListCreateInput | null;
  metadataRequiredPermissionList?: ArchiveNestedMetadataRequiredPermissionListCreateInput | null;
  metadataResolutionKindList?: ArchiveNestedMetadataResolutionKindListCreateInput | null;
  metadataRoyaltyfreeList?: ArchiveNestedMetadataRoyaltyfreeListCreateInput | null;
  metadataSectionList?: ArchiveNestedMetadataSectionListCreateInput | null;
  metadataSubjectPrismaList?: ArchiveNestedMetadataSubjectPrismaListCreateInput | null;
  metadataUndersubjectList?: ArchiveNestedMetadataUndersubjectListCreateInput | null;
  metadataUnderUnderSubjectList?: ArchiveNestedMetadataUnderUnderSubjectListCreateInput | null;
  metadataUnitShortNameList?: ArchiveNestedMetadataUnitShortNameListCreateInput | null;
  metadataWorkflowKindList?: ArchiveNestedMetadataWorkflowKindListCreateInput | null;
  metadataMakeList?: ArchiveNestedMetadataMakeListCreateInput | null;
  metadataModelList?: ArchiveNestedMetadataModelListCreateInput | null;
  metadataCreatorToolList?: ArchiveNestedMetadataCreatorToolListCreateInput | null;
  metadataRatingList?: ArchiveNestedMetadataRatingListCreateInput | null;
  metadataXmpFileStampsList?: ArchiveNestedMetadataXmpFileStampsListCreateInput | null;
  metadataManifestList?: ArchiveNestedMetadataManifestListCreateInput | null;
  metadataXmpHistoryList?: ArchiveNestedMetadataXmpHistoryListCreateInput | null;
  metadataCertificateList?: ArchiveNestedMetadataCertificateListCreateInput | null;
  metadataMarkedList?: ArchiveNestedMetadataMarkedListCreateInput | null;
  metadataOwnerList?: ArchiveNestedMetadataOwnerListCreateInput | null;
  metadataUsageTermsList?: ArchiveNestedMetadataUsageTermsListCreateInput | null;
  metadataWebStatementList?: ArchiveNestedMetadataWebStatementListCreateInput | null;
}

export interface ArchiveNestedArchiveAgenciesCreateInput {
  connect?: ArchiveAgencyWhereUniqueInput[] | null;
  create?: ArchiveAgencyWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedArchiveAgenciesUpdateInput {
  connect?: ArchiveAgencyWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateArchiveAgenciesUpdateInput[] | null;
  create?: ArchiveAgencyWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertArchiveAgenciesUpdateInput[] | null;
  delete?: ArchiveAgencyWithForcedArchiveWhereUniqueInput[] | null;
}

export interface ArchiveNestedCategoriesCreateInput {
  connect?: CategoryWhereUniqueInput[] | null;
  create?: CategoryWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedCategoriesUpdateInput {
  connect?: CategoryWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateCategoriesUpdateInput[] | null;
  create?: CategoryWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertCategoriesUpdateInput[] | null;
  delete?: CategoryWithForcedArchiveWhereUniqueInput[] | null;
}

export interface ArchiveNestedDeliverySubjectsCreateInput {
  connect?: DeliverySubjectWhereUniqueInput[] | null;
  create?: DeliverySubjectWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedDeliverySubjectsUpdateInput {
  connect?: DeliverySubjectWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateDeliverySubjectsUpdateInput[] | null;
  create?: DeliverySubjectWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertDeliverySubjectsUpdateInput[] | null;
  delete?: DeliverySubjectWithForcedArchiveWhereUniqueInput[] | null;
}

export interface ArchiveNestedGcImportSettingCreateInput {
  connect?: GcImportSettingWhereUniqueInput | null;
  create?: GcImportSettingWithForcedArchiveCreateInput | null;
}

export interface ArchiveNestedGcImportSettingUpdateInput {
  connect?: GcImportSettingWhereUniqueInput | null;
  update?: ArchiveNestedUpdateGcImportSettingUpdateInput | null;
  create?: GcImportSettingWithForcedArchiveCreateInput | null;
  upsert?: ArchiveNestedUpsertGcImportSettingUpdateInput | null;
  delete?: boolean | null;
}

export interface ArchiveNestedImportQueuesCreateInput {
  connect?: ImportQueueWhereUniqueInput[] | null;
  create?: ImportQueueWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedImportQueuesUpdateInput {
  connect?: ImportQueueWhereUniqueInput[] | null;
  disconnect?: ImportQueueWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateImportQueuesUpdateInput[] | null;
  create?: ImportQueueWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertImportQueuesUpdateInput[] | null;
  delete?: ImportQueueWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataAccessRightsListCreateInput {
  connect?: MetadataAccessRightsWhereUniqueInput[] | null;
  create?: MetadataAccessRightsWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataAccessRightsListUpdateInput {
  connect?: MetadataAccessRightsWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataAccessRightsListUpdateInput[] | null;
  create?: MetadataAccessRightsWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataAccessRightsListUpdateInput[] | null;
  delete?: MetadataAccessRightsWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataAddlModelInfoListCreateInput {
  connect?: MetadataAddlModelInfoWhereUniqueInput[] | null;
  create?: MetadataAddlModelInfoWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataAddlModelInfoListUpdateInput {
  connect?: MetadataAddlModelInfoWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataAddlModelInfoListUpdateInput[] | null;
  create?: MetadataAddlModelInfoWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataAddlModelInfoListUpdateInput[] | null;
  delete?: MetadataAddlModelInfoWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataArchiveChildListCreateInput {
  connect?: MetadataArchiveChildWhereUniqueInput[] | null;
  create?: MetadataArchiveChildWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataArchiveChildListUpdateInput {
  connect?: MetadataArchiveChildWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataArchiveChildListUpdateInput[] | null;
  create?: MetadataArchiveChildWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataArchiveChildListUpdateInput[] | null;
  delete?: MetadataArchiveChildWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataArchiveParentListCreateInput {
  connect?: MetadataArchiveParentWhereUniqueInput[] | null;
  create?: MetadataArchiveParentWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataArchiveParentListUpdateInput {
  connect?: MetadataArchiveParentWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataArchiveParentListUpdateInput[] | null;
  create?: MetadataArchiveParentWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataArchiveParentListUpdateInput[] | null;
  delete?: MetadataArchiveParentWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataAssetStateListCreateInput {
  connect?: MetadataAssetStateWhereUniqueInput[] | null;
  create?: MetadataAssetStateWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataAssetStateListUpdateInput {
  connect?: MetadataAssetStateWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataAssetStateListUpdateInput[] | null;
  create?: MetadataAssetStateWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataAssetStateListUpdateInput[] | null;
  delete?: MetadataAssetStateWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataAttributionNameListCreateInput {
  connect?: MetadataAttributionNameWhereUniqueInput[] | null;
  create?: MetadataAttributionNameWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataAttributionNameListUpdateInput {
  connect?: MetadataAttributionNameWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataAttributionNameListUpdateInput[] | null;
  create?: MetadataAttributionNameWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataAttributionNameListUpdateInput[] | null;
  delete?: MetadataAttributionNameWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataAttributionUrlListCreateInput {
  connect?: MetadataAttributionUrlWhereUniqueInput[] | null;
  create?: MetadataAttributionUrlWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataAttributionUrlListUpdateInput {
  connect?: MetadataAttributionUrlWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataAttributionUrlListUpdateInput[] | null;
  create?: MetadataAttributionUrlWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataAttributionUrlListUpdateInput[] | null;
  delete?: MetadataAttributionUrlWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataAudienceListCreateInput {
  connect?: MetadataAudienceWhereUniqueInput[] | null;
  create?: MetadataAudienceWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataAudienceListUpdateInput {
  connect?: MetadataAudienceWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataAudienceListUpdateInput[] | null;
  create?: MetadataAudienceWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataAudienceListUpdateInput[] | null;
  delete?: MetadataAudienceWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataAuthorsPositionListCreateInput {
  connect?: MetadataAuthorsPositionWhereUniqueInput[] | null;
  create?: MetadataAuthorsPositionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataAuthorsPositionListUpdateInput {
  connect?: MetadataAuthorsPositionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataAuthorsPositionListUpdateInput[] | null;
  create?: MetadataAuthorsPositionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataAuthorsPositionListUpdateInput[] | null;
  delete?: MetadataAuthorsPositionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataBackupNameListCreateInput {
  connect?: MetadataBackupNameWhereUniqueInput[] | null;
  create?: MetadataBackupNameWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataBackupNameListUpdateInput {
  connect?: MetadataBackupNameWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataBackupNameListUpdateInput[] | null;
  create?: MetadataBackupNameWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataBackupNameListUpdateInput[] | null;
  delete?: MetadataBackupNameWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataBitmapGrayscalePictureListCreateInput {
  connect?: MetadataBitmapGrayscalePictureWhereUniqueInput[] | null;
  create?: MetadataBitmapGrayscalePictureWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataBitmapGrayscalePictureListUpdateInput {
  connect?: MetadataBitmapGrayscalePictureWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataBitmapGrayscalePictureListUpdateInput[] | null;
  create?: MetadataBitmapGrayscalePictureWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataBitmapGrayscalePictureListUpdateInput[] | null;
  delete?: MetadataBitmapGrayscalePictureWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataBrandListCreateInput {
  connect?: MetadataBrandWhereUniqueInput[] | null;
  create?: MetadataBrandWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataBrandListUpdateInput {
  connect?: MetadataBrandWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataBrandListUpdateInput[] | null;
  create?: MetadataBrandWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataBrandListUpdateInput[] | null;
  delete?: MetadataBrandWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataBrandPrismaListCreateInput {
  connect?: MetadataBrandPrismaWhereUniqueInput[] | null;
  create?: MetadataBrandPrismaWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataBrandPrismaListUpdateInput {
  connect?: MetadataBrandPrismaWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataBrandPrismaListUpdateInput[] | null;
  create?: MetadataBrandPrismaWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataBrandPrismaListUpdateInput[] | null;
  delete?: MetadataBrandPrismaWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataBrandShortNameListCreateInput {
  connect?: MetadataBrandShortNameWhereUniqueInput[] | null;
  create?: MetadataBrandShortNameWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataBrandShortNameListUpdateInput {
  connect?: MetadataBrandShortNameWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataBrandShortNameListUpdateInput[] | null;
  create?: MetadataBrandShortNameWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataBrandShortNameListUpdateInput[] | null;
  delete?: MetadataBrandShortNameWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCaptionWriterListCreateInput {
  connect?: MetadataCaptionWriterWhereUniqueInput[] | null;
  create?: MetadataCaptionWriterWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCaptionWriterListUpdateInput {
  connect?: MetadataCaptionWriterWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCaptionWriterListUpdateInput[] | null;
  create?: MetadataCaptionWriterWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCaptionWriterListUpdateInput[] | null;
  delete?: MetadataCaptionWriterWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCategoryListCreateInput {
  connect?: MetadataCategoryWhereUniqueInput[] | null;
  create?: MetadataCategoryWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCategoryListUpdateInput {
  connect?: MetadataCategoryWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCategoryListUpdateInput[] | null;
  create?: MetadataCategoryWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCategoryListUpdateInput[] | null;
  delete?: MetadataCategoryWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCategoryPrismaListCreateInput {
  connect?: MetadataCategoryPrismaWhereUniqueInput[] | null;
  create?: MetadataCategoryPrismaWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCategoryPrismaListUpdateInput {
  connect?: MetadataCategoryPrismaWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCategoryPrismaListUpdateInput[] | null;
  create?: MetadataCategoryPrismaWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCategoryPrismaListUpdateInput[] | null;
  delete?: MetadataCategoryPrismaWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCertificateListCreateInput {
  connect?: MetadataCertificateWhereUniqueInput[] | null;
  create?: MetadataCertificateWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCertificateListUpdateInput {
  connect?: MetadataCertificateWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCertificateListUpdateInput[] | null;
  create?: MetadataCertificateWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCertificateListUpdateInput[] | null;
  delete?: MetadataCertificateWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCiAdrCityListCreateInput {
  connect?: MetadataCiAdrCityWhereUniqueInput[] | null;
  create?: MetadataCiAdrCityWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCiAdrCityListUpdateInput {
  connect?: MetadataCiAdrCityWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCiAdrCityListUpdateInput[] | null;
  create?: MetadataCiAdrCityWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCiAdrCityListUpdateInput[] | null;
  delete?: MetadataCiAdrCityWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCiAdrCtryListCreateInput {
  connect?: MetadataCiAdrCtryWhereUniqueInput[] | null;
  create?: MetadataCiAdrCtryWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCiAdrCtryListUpdateInput {
  connect?: MetadataCiAdrCtryWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCiAdrCtryListUpdateInput[] | null;
  create?: MetadataCiAdrCtryWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCiAdrCtryListUpdateInput[] | null;
  delete?: MetadataCiAdrCtryWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCiAdrExtadrListCreateInput {
  connect?: MetadataCiAdrExtadrWhereUniqueInput[] | null;
  create?: MetadataCiAdrExtadrWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCiAdrExtadrListUpdateInput {
  connect?: MetadataCiAdrExtadrWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCiAdrExtadrListUpdateInput[] | null;
  create?: MetadataCiAdrExtadrWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCiAdrExtadrListUpdateInput[] | null;
  delete?: MetadataCiAdrExtadrWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCiAdrPcodeListCreateInput {
  connect?: MetadataCiAdrPcodeWhereUniqueInput[] | null;
  create?: MetadataCiAdrPcodeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCiAdrPcodeListUpdateInput {
  connect?: MetadataCiAdrPcodeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCiAdrPcodeListUpdateInput[] | null;
  create?: MetadataCiAdrPcodeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCiAdrPcodeListUpdateInput[] | null;
  delete?: MetadataCiAdrPcodeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCiAdrRegionListCreateInput {
  connect?: MetadataCiAdrRegionWhereUniqueInput[] | null;
  create?: MetadataCiAdrRegionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCiAdrRegionListUpdateInput {
  connect?: MetadataCiAdrRegionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCiAdrRegionListUpdateInput[] | null;
  create?: MetadataCiAdrRegionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCiAdrRegionListUpdateInput[] | null;
  delete?: MetadataCiAdrRegionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCiEmailWorkListCreateInput {
  connect?: MetadataCiEmailWorkWhereUniqueInput[] | null;
  create?: MetadataCiEmailWorkWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCiEmailWorkListUpdateInput {
  connect?: MetadataCiEmailWorkWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCiEmailWorkListUpdateInput[] | null;
  create?: MetadataCiEmailWorkWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCiEmailWorkListUpdateInput[] | null;
  delete?: MetadataCiEmailWorkWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCiTelWorkListCreateInput {
  connect?: MetadataCiTelWorkWhereUniqueInput[] | null;
  create?: MetadataCiTelWorkWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCiTelWorkListUpdateInput {
  connect?: MetadataCiTelWorkWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCiTelWorkListUpdateInput[] | null;
  create?: MetadataCiTelWorkWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCiTelWorkListUpdateInput[] | null;
  delete?: MetadataCiTelWorkWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCiUrlWorkListCreateInput {
  connect?: MetadataCiUrlWorkWhereUniqueInput[] | null;
  create?: MetadataCiUrlWorkWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCiUrlWorkListUpdateInput {
  connect?: MetadataCiUrlWorkWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCiUrlWorkListUpdateInput[] | null;
  create?: MetadataCiUrlWorkWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCiUrlWorkListUpdateInput[] | null;
  delete?: MetadataCiUrlWorkWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCityListCreateInput {
  connect?: MetadataCityWhereUniqueInput[] | null;
  create?: MetadataCityWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCityListUpdateInput {
  connect?: MetadataCityWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCityListUpdateInput[] | null;
  create?: MetadataCityWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCityListUpdateInput[] | null;
  delete?: MetadataCityWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataClassifyListCreateInput {
  connect?: MetadataClassifyWhereUniqueInput[] | null;
  create?: MetadataClassifyWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataClassifyListUpdateInput {
  connect?: MetadataClassifyWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataClassifyListUpdateInput[] | null;
  create?: MetadataClassifyWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataClassifyListUpdateInput[] | null;
  delete?: MetadataClassifyWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCompanyListCreateInput {
  connect?: MetadataCompanyWhereUniqueInput[] | null;
  create?: MetadataCompanyWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCompanyListUpdateInput {
  connect?: MetadataCompanyWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCompanyListUpdateInput[] | null;
  create?: MetadataCompanyWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCompanyListUpdateInput[] | null;
  delete?: MetadataCompanyWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCompanyShortNameListCreateInput {
  connect?: MetadataCompanyShortNameWhereUniqueInput[] | null;
  create?: MetadataCompanyShortNameWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCompanyShortNameListUpdateInput {
  connect?: MetadataCompanyShortNameWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCompanyShortNameListUpdateInput[] | null;
  create?: MetadataCompanyShortNameWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCompanyShortNameListUpdateInput[] | null;
  delete?: MetadataCompanyShortNameWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataConfidentialityListCreateInput {
  connect?: MetadataConfidentialityWhereUniqueInput[] | null;
  create?: MetadataConfidentialityWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataConfidentialityListUpdateInput {
  connect?: MetadataConfidentialityWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataConfidentialityListUpdateInput[] | null;
  create?: MetadataConfidentialityWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataConfidentialityListUpdateInput[] | null;
  delete?: MetadataConfidentialityWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataContainedInListCreateInput {
  connect?: MetadataContainedInWhereUniqueInput[] | null;
  create?: MetadataContainedInWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataContainedInListUpdateInput {
  connect?: MetadataContainedInWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataContainedInListUpdateInput[] | null;
  create?: MetadataContainedInWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataContainedInListUpdateInput[] | null;
  delete?: MetadataContainedInWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataContentMediaKindListCreateInput {
  connect?: MetadataContentMediaKindWhereUniqueInput[] | null;
  create?: MetadataContentMediaKindWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataContentMediaKindListUpdateInput {
  connect?: MetadataContentMediaKindWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataContentMediaKindListUpdateInput[] | null;
  create?: MetadataContentMediaKindWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataContentMediaKindListUpdateInput[] | null;
  delete?: MetadataContentMediaKindWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataContentValueListCreateInput {
  connect?: MetadataContentValueWhereUniqueInput[] | null;
  create?: MetadataContentValueWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataContentValueListUpdateInput {
  connect?: MetadataContentValueWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataContentValueListUpdateInput[] | null;
  create?: MetadataContentValueWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataContentValueListUpdateInput[] | null;
  delete?: MetadataContentValueWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCopyrightLayerListCreateInput {
  connect?: MetadataCopyrightLayerWhereUniqueInput[] | null;
  create?: MetadataCopyrightLayerWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCopyrightLayerListUpdateInput {
  connect?: MetadataCopyrightLayerWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCopyrightLayerListUpdateInput[] | null;
  create?: MetadataCopyrightLayerWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCopyrightLayerListUpdateInput[] | null;
  delete?: MetadataCopyrightLayerWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCountryCodeListCreateInput {
  connect?: MetadataCountryCodeWhereUniqueInput[] | null;
  create?: MetadataCountryCodeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCountryCodeListUpdateInput {
  connect?: MetadataCountryCodeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCountryCodeListUpdateInput[] | null;
  create?: MetadataCountryCodeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCountryCodeListUpdateInput[] | null;
  delete?: MetadataCountryCodeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCountryListCreateInput {
  connect?: MetadataCountryWhereUniqueInput[] | null;
  create?: MetadataCountryWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCountryListUpdateInput {
  connect?: MetadataCountryWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCountryListUpdateInput[] | null;
  create?: MetadataCountryWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCountryListUpdateInput[] | null;
  delete?: MetadataCountryWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCreatedTimeListCreateInput {
  connect?: MetadataCreatedTimeWhereUniqueInput[] | null;
  create?: MetadataCreatedTimeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCreatedTimeListUpdateInput {
  connect?: MetadataCreatedTimeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCreatedTimeListUpdateInput[] | null;
  create?: MetadataCreatedTimeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCreatedTimeListUpdateInput[] | null;
  delete?: MetadataCreatedTimeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCreatorListCreateInput {
  connect?: MetadataCreatorWhereUniqueInput[] | null;
  create?: MetadataCreatorWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCreatorListUpdateInput {
  connect?: MetadataCreatorWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCreatorListUpdateInput[] | null;
  create?: MetadataCreatorWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCreatorListUpdateInput[] | null;
  delete?: MetadataCreatorWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCreatorToolListCreateInput {
  connect?: MetadataCreatorToolWhereUniqueInput[] | null;
  create?: MetadataCreatorToolWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCreatorToolListUpdateInput {
  connect?: MetadataCreatorToolWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCreatorToolListUpdateInput[] | null;
  create?: MetadataCreatorToolWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCreatorToolListUpdateInput[] | null;
  delete?: MetadataCreatorToolWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCreditListCreateInput {
  connect?: MetadataCreditWhereUniqueInput[] | null;
  create?: MetadataCreditWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCreditListUpdateInput {
  connect?: MetadataCreditWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCreditListUpdateInput[] | null;
  create?: MetadataCreditWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCreditListUpdateInput[] | null;
  delete?: MetadataCreditWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCustomField14ListCreateInput {
  connect?: MetadataCustomField14WhereUniqueInput[] | null;
  create?: MetadataCustomField14WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCustomField14ListUpdateInput {
  connect?: MetadataCustomField14WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCustomField14ListUpdateInput[] | null;
  create?: MetadataCustomField14WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCustomField14ListUpdateInput[] | null;
  delete?: MetadataCustomField14WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCustomField15ListCreateInput {
  connect?: MetadataCustomField15WhereUniqueInput[] | null;
  create?: MetadataCustomField15WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCustomField15ListUpdateInput {
  connect?: MetadataCustomField15WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCustomField15ListUpdateInput[] | null;
  create?: MetadataCustomField15WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCustomField15ListUpdateInput[] | null;
  delete?: MetadataCustomField15WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCustomField16ListCreateInput {
  connect?: MetadataCustomField16WhereUniqueInput[] | null;
  create?: MetadataCustomField16WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCustomField16ListUpdateInput {
  connect?: MetadataCustomField16WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCustomField16ListUpdateInput[] | null;
  create?: MetadataCustomField16WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCustomField16ListUpdateInput[] | null;
  delete?: MetadataCustomField16WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCustomField17ListCreateInput {
  connect?: MetadataCustomField17WhereUniqueInput[] | null;
  create?: MetadataCustomField17WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCustomField17ListUpdateInput {
  connect?: MetadataCustomField17WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCustomField17ListUpdateInput[] | null;
  create?: MetadataCustomField17WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCustomField17ListUpdateInput[] | null;
  delete?: MetadataCustomField17WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCustomField20ListCreateInput {
  connect?: MetadataCustomField20WhereUniqueInput[] | null;
  create?: MetadataCustomField20WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCustomField20ListUpdateInput {
  connect?: MetadataCustomField20WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCustomField20ListUpdateInput[] | null;
  create?: MetadataCustomField20WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCustomField20ListUpdateInput[] | null;
  delete?: MetadataCustomField20WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCustomField5ListCreateInput {
  connect?: MetadataCustomField5WhereUniqueInput[] | null;
  create?: MetadataCustomField5WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCustomField5ListUpdateInput {
  connect?: MetadataCustomField5WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCustomField5ListUpdateInput[] | null;
  create?: MetadataCustomField5WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCustomField5ListUpdateInput[] | null;
  delete?: MetadataCustomField5WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCustomField6ListCreateInput {
  connect?: MetadataCustomField6WhereUniqueInput[] | null;
  create?: MetadataCustomField6WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCustomField6ListUpdateInput {
  connect?: MetadataCustomField6WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCustomField6ListUpdateInput[] | null;
  create?: MetadataCustomField6WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCustomField6ListUpdateInput[] | null;
  delete?: MetadataCustomField6WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCustomField7ListCreateInput {
  connect?: MetadataCustomField7WhereUniqueInput[] | null;
  create?: MetadataCustomField7WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCustomField7ListUpdateInput {
  connect?: MetadataCustomField7WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCustomField7ListUpdateInput[] | null;
  create?: MetadataCustomField7WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCustomField7ListUpdateInput[] | null;
  delete?: MetadataCustomField7WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataCustomField9ListCreateInput {
  connect?: MetadataCustomField9WhereUniqueInput[] | null;
  create?: MetadataCustomField9WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataCustomField9ListUpdateInput {
  connect?: MetadataCustomField9WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataCustomField9ListUpdateInput[] | null;
  create?: MetadataCustomField9WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataCustomField9ListUpdateInput[] | null;
  delete?: MetadataCustomField9WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDateCreatedListCreateInput {
  connect?: MetadataDateCreatedWhereUniqueInput[] | null;
  create?: MetadataDateCreatedWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDateCreatedListUpdateInput {
  connect?: MetadataDateCreatedWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDateCreatedListUpdateInput[] | null;
  create?: MetadataDateCreatedWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDateCreatedListUpdateInput[] | null;
  delete?: MetadataDateCreatedWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDateTimeDigitizedListCreateInput {
  connect?: MetadataDateTimeDigitizedWhereUniqueInput[] | null;
  create?: MetadataDateTimeDigitizedWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDateTimeDigitizedListUpdateInput {
  connect?: MetadataDateTimeDigitizedWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDateTimeDigitizedListUpdateInput[] | null;
  create?: MetadataDateTimeDigitizedWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDateTimeDigitizedListUpdateInput[] | null;
  delete?: MetadataDateTimeDigitizedWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDateTimeOriginalListCreateInput {
  connect?: MetadataDateTimeOriginalWhereUniqueInput[] | null;
  create?: MetadataDateTimeOriginalWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDateTimeOriginalListUpdateInput {
  connect?: MetadataDateTimeOriginalWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDateTimeOriginalListUpdateInput[] | null;
  create?: MetadataDateTimeOriginalWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDateTimeOriginalListUpdateInput[] | null;
  delete?: MetadataDateTimeOriginalWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryAccountListCreateInput {
  connect?: MetadataDeliveryAccountWhereUniqueInput[] | null;
  create?: MetadataDeliveryAccountWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryAccountListUpdateInput {
  connect?: MetadataDeliveryAccountWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryAccountListUpdateInput[] | null;
  create?: MetadataDeliveryAccountWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryAccountListUpdateInput[] | null;
  delete?: MetadataDeliveryAccountWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryCompanyListCreateInput {
  connect?: MetadataDeliveryCompanyWhereUniqueInput[] | null;
  create?: MetadataDeliveryCompanyWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryCompanyListUpdateInput {
  connect?: MetadataDeliveryCompanyWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryCompanyListUpdateInput[] | null;
  create?: MetadataDeliveryCompanyWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryCompanyListUpdateInput[] | null;
  delete?: MetadataDeliveryCompanyWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryCopyrightListCreateInput {
  connect?: MetadataDeliveryCopyrightWhereUniqueInput[] | null;
  create?: MetadataDeliveryCopyrightWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryCopyrightListUpdateInput {
  connect?: MetadataDeliveryCopyrightWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryCopyrightListUpdateInput[] | null;
  create?: MetadataDeliveryCopyrightWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryCopyrightListUpdateInput[] | null;
  delete?: MetadataDeliveryCopyrightWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryDateFolderListCreateInput {
  connect?: MetadataDeliveryDateFolderWhereUniqueInput[] | null;
  create?: MetadataDeliveryDateFolderWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryDateFolderListUpdateInput {
  connect?: MetadataDeliveryDateFolderWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryDateFolderListUpdateInput[] | null;
  create?: MetadataDeliveryDateFolderWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryDateFolderListUpdateInput[] | null;
  delete?: MetadataDeliveryDateFolderWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryDateTimeListCreateInput {
  connect?: MetadataDeliveryDateTimeWhereUniqueInput[] | null;
  create?: MetadataDeliveryDateTimeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryDateTimeListUpdateInput {
  connect?: MetadataDeliveryDateTimeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryDateTimeListUpdateInput[] | null;
  create?: MetadataDeliveryDateTimeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryDateTimeListUpdateInput[] | null;
  delete?: MetadataDeliveryDateTimeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryDcCreatorListCreateInput {
  connect?: MetadataDeliveryDcCreatorWhereUniqueInput[] | null;
  create?: MetadataDeliveryDcCreatorWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryDcCreatorListUpdateInput {
  connect?: MetadataDeliveryDcCreatorWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryDcCreatorListUpdateInput[] | null;
  create?: MetadataDeliveryDcCreatorWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryDcCreatorListUpdateInput[] | null;
  delete?: MetadataDeliveryDcCreatorWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryDcRightsListCreateInput {
  connect?: MetadataDeliveryDcRightsWhereUniqueInput[] | null;
  create?: MetadataDeliveryDcRightsWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryDcRightsListUpdateInput {
  connect?: MetadataDeliveryDcRightsWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryDcRightsListUpdateInput[] | null;
  create?: MetadataDeliveryDcRightsWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryDcRightsListUpdateInput[] | null;
  delete?: MetadataDeliveryDcRightsWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryFileTypeListCreateInput {
  connect?: MetadataDeliveryFileTypeWhereUniqueInput[] | null;
  create?: MetadataDeliveryFileTypeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryFileTypeListUpdateInput {
  connect?: MetadataDeliveryFileTypeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryFileTypeListUpdateInput[] | null;
  create?: MetadataDeliveryFileTypeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryFileTypeListUpdateInput[] | null;
  delete?: MetadataDeliveryFileTypeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryFolderListCreateInput {
  connect?: MetadataDeliveryFolderWhereUniqueInput[] | null;
  create?: MetadataDeliveryFolderWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryFolderListUpdateInput {
  connect?: MetadataDeliveryFolderWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryFolderListUpdateInput[] | null;
  create?: MetadataDeliveryFolderWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryFolderListUpdateInput[] | null;
  delete?: MetadataDeliveryFolderWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryKindListCreateInput {
  connect?: MetadataDeliveryKindWhereUniqueInput[] | null;
  create?: MetadataDeliveryKindWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryKindListUpdateInput {
  connect?: MetadataDeliveryKindWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryKindListUpdateInput[] | null;
  create?: MetadataDeliveryKindWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryKindListUpdateInput[] | null;
  delete?: MetadataDeliveryKindWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryPathListCreateInput {
  connect?: MetadataDeliveryPathWhereUniqueInput[] | null;
  create?: MetadataDeliveryPathWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryPathListUpdateInput {
  connect?: MetadataDeliveryPathWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryPathListUpdateInput[] | null;
  create?: MetadataDeliveryPathWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryPathListUpdateInput[] | null;
  delete?: MetadataDeliveryPathWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryPersonShownintheImageListCreateInput {
  connect?: MetadataDeliveryPersonShownintheImageWhereUniqueInput[] | null;
  create?: MetadataDeliveryPersonShownintheImageWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryPersonShownintheImageListUpdateInput {
  connect?: MetadataDeliveryPersonShownintheImageWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryPersonShownintheImageListUpdateInput[] | null;
  create?: MetadataDeliveryPersonShownintheImageWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryPersonShownintheImageListUpdateInput[] | null;
  delete?: MetadataDeliveryPersonShownintheImageWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryPhotoshopCreditListCreateInput {
  connect?: MetadataDeliveryPhotoshopCreditWhereUniqueInput[] | null;
  create?: MetadataDeliveryPhotoshopCreditWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryPhotoshopCreditListUpdateInput {
  connect?: MetadataDeliveryPhotoshopCreditWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryPhotoshopCreditListUpdateInput[] | null;
  create?: MetadataDeliveryPhotoshopCreditWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryPhotoshopCreditListUpdateInput[] | null;
  delete?: MetadataDeliveryPhotoshopCreditWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryPhotoshopSourceListCreateInput {
  connect?: MetadataDeliveryPhotoshopSourceWhereUniqueInput[] | null;
  create?: MetadataDeliveryPhotoshopSourceWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryPhotoshopSourceListUpdateInput {
  connect?: MetadataDeliveryPhotoshopSourceWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryPhotoshopSourceListUpdateInput[] | null;
  create?: MetadataDeliveryPhotoshopSourceWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryPhotoshopSourceListUpdateInput[] | null;
  delete?: MetadataDeliveryPhotoshopSourceWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryServiceListCreateInput {
  connect?: MetadataDeliveryServiceWhereUniqueInput[] | null;
  create?: MetadataDeliveryServiceWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryServiceListUpdateInput {
  connect?: MetadataDeliveryServiceWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryServiceListUpdateInput[] | null;
  create?: MetadataDeliveryServiceWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryServiceListUpdateInput[] | null;
  delete?: MetadataDeliveryServiceWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliverySubjectListCreateInput {
  connect?: MetadataDeliverySubjectWhereUniqueInput[] | null;
  create?: MetadataDeliverySubjectWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliverySubjectListUpdateInput {
  connect?: MetadataDeliverySubjectWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliverySubjectListUpdateInput[] | null;
  create?: MetadataDeliverySubjectWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliverySubjectListUpdateInput[] | null;
  delete?: MetadataDeliverySubjectWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryUnderSubjectListCreateInput {
  connect?: MetadataDeliveryUnderSubjectWhereUniqueInput[] | null;
  create?: MetadataDeliveryUnderSubjectWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDeliveryUnderSubjectListUpdateInput {
  connect?: MetadataDeliveryUnderSubjectWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDeliveryUnderSubjectListUpdateInput[] | null;
  create?: MetadataDeliveryUnderSubjectWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDeliveryUnderSubjectListUpdateInput[] | null;
  delete?: MetadataDeliveryUnderSubjectWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDepartmentListCreateInput {
  connect?: MetadataDepartmentWhereUniqueInput[] | null;
  create?: MetadataDepartmentWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDepartmentListUpdateInput {
  connect?: MetadataDepartmentWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDepartmentListUpdateInput[] | null;
  create?: MetadataDepartmentWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDepartmentListUpdateInput[] | null;
  delete?: MetadataDepartmentWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDescriptionListCreateInput {
  connect?: MetadataDescriptionWhereUniqueInput[] | null;
  create?: MetadataDescriptionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDescriptionListUpdateInput {
  connect?: MetadataDescriptionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDescriptionListUpdateInput[] | null;
  create?: MetadataDescriptionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDescriptionListUpdateInput[] | null;
  delete?: MetadataDescriptionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDigitalAssetUrlListCreateInput {
  connect?: MetadataDigitalAssetUrlWhereUniqueInput[] | null;
  create?: MetadataDigitalAssetUrlWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDigitalAssetUrlListUpdateInput {
  connect?: MetadataDigitalAssetUrlWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDigitalAssetUrlListUpdateInput[] | null;
  create?: MetadataDigitalAssetUrlWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDigitalAssetUrlListUpdateInput[] | null;
  delete?: MetadataDigitalAssetUrlWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDivisionListCreateInput {
  connect?: MetadataDivisionWhereUniqueInput[] | null;
  create?: MetadataDivisionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDivisionListUpdateInput {
  connect?: MetadataDivisionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDivisionListUpdateInput[] | null;
  create?: MetadataDivisionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDivisionListUpdateInput[] | null;
  delete?: MetadataDivisionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataDocumentTextListCreateInput {
  connect?: MetadataDocumentTextWhereUniqueInput[] | null;
  create?: MetadataDocumentTextWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataDocumentTextListUpdateInput {
  connect?: MetadataDocumentTextWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataDocumentTextListUpdateInput[] | null;
  create?: MetadataDocumentTextWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataDocumentTextListUpdateInput[] | null;
  delete?: MetadataDocumentTextWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataEditStatusListCreateInput {
  connect?: MetadataEditStatusWhereUniqueInput[] | null;
  create?: MetadataEditStatusWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataEditStatusListUpdateInput {
  connect?: MetadataEditStatusWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataEditStatusListUpdateInput[] | null;
  create?: MetadataEditStatusWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataEditStatusListUpdateInput[] | null;
  delete?: MetadataEditStatusWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataEditionListCreateInput {
  connect?: MetadataEditionWhereUniqueInput[] | null;
  create?: MetadataEditionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataEditionListUpdateInput {
  connect?: MetadataEditionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataEditionListUpdateInput[] | null;
  create?: MetadataEditionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataEditionListUpdateInput[] | null;
  delete?: MetadataEditionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataEditorialVersionListCreateInput {
  connect?: MetadataEditorialVersionWhereUniqueInput[] | null;
  create?: MetadataEditorialVersionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataEditorialVersionListUpdateInput {
  connect?: MetadataEditorialVersionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataEditorialVersionListUpdateInput[] | null;
  create?: MetadataEditorialVersionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataEditorialVersionListUpdateInput[] | null;
  delete?: MetadataEditorialVersionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataEnvironnementPhotoListCreateInput {
  connect?: MetadataEnvironnementPhotoWhereUniqueInput[] | null;
  create?: MetadataEnvironnementPhotoWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataEnvironnementPhotoListUpdateInput {
  connect?: MetadataEnvironnementPhotoWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataEnvironnementPhotoListUpdateInput[] | null;
  create?: MetadataEnvironnementPhotoWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataEnvironnementPhotoListUpdateInput[] | null;
  delete?: MetadataEnvironnementPhotoWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataEquipmentInstitutionListCreateInput {
  connect?: MetadataEquipmentInstitutionWhereUniqueInput[] | null;
  create?: MetadataEquipmentInstitutionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataEquipmentInstitutionListUpdateInput {
  connect?: MetadataEquipmentInstitutionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataEquipmentInstitutionListUpdateInput[] | null;
  create?: MetadataEquipmentInstitutionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataEquipmentInstitutionListUpdateInput[] | null;
  delete?: MetadataEquipmentInstitutionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataEquipmentManufacturerListCreateInput {
  connect?: MetadataEquipmentManufacturerWhereUniqueInput[] | null;
  create?: MetadataEquipmentManufacturerWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataEquipmentManufacturerListUpdateInput {
  connect?: MetadataEquipmentManufacturerWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataEquipmentManufacturerListUpdateInput[] | null;
  create?: MetadataEquipmentManufacturerWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataEquipmentManufacturerListUpdateInput[] | null;
  delete?: MetadataEquipmentManufacturerWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataEventListCreateInput {
  connect?: MetadataEventWhereUniqueInput[] | null;
  create?: MetadataEventWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataEventListUpdateInput {
  connect?: MetadataEventWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataEventListUpdateInput[] | null;
  create?: MetadataEventWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataEventListUpdateInput[] | null;
  delete?: MetadataEventWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataExifListCreateInput {
  connect?: MetadataExifWhereUniqueInput[] | null;
  create?: MetadataExifWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataExifListUpdateInput {
  connect?: MetadataExifWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataExifListUpdateInput[] | null;
  create?: MetadataExifWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataExifListUpdateInput[] | null;
  delete?: MetadataExifWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataFabStorageListCreateInput {
  connect?: MetadataFabStorageWhereUniqueInput[] | null;
  create?: MetadataFabStorageWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataFabStorageListUpdateInput {
  connect?: MetadataFabStorageWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataFabStorageListUpdateInput[] | null;
  create?: MetadataFabStorageWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataFabStorageListUpdateInput[] | null;
  delete?: MetadataFabStorageWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataFileCheckForListCreateInput {
  connect?: MetadataFileCheckForWhereUniqueInput[] | null;
  create?: MetadataFileCheckForWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataFileCheckForListUpdateInput {
  connect?: MetadataFileCheckForWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataFileCheckForListUpdateInput[] | null;
  create?: MetadataFileCheckForWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataFileCheckForListUpdateInput[] | null;
  delete?: MetadataFileCheckForWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataFileInfoBitsPerPixelListCreateInput {
  connect?: MetadataFileInfoBitsPerPixelWhereUniqueInput[] | null;
  create?: MetadataFileInfoBitsPerPixelWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataFileInfoBitsPerPixelListUpdateInput {
  connect?: MetadataFileInfoBitsPerPixelWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataFileInfoBitsPerPixelListUpdateInput[] | null;
  create?: MetadataFileInfoBitsPerPixelWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataFileInfoBitsPerPixelListUpdateInput[] | null;
  delete?: MetadataFileInfoBitsPerPixelWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataFileInfoFramesListCreateInput {
  connect?: MetadataFileInfoFramesWhereUniqueInput[] | null;
  create?: MetadataFileInfoFramesWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataFileInfoFramesListUpdateInput {
  connect?: MetadataFileInfoFramesWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataFileInfoFramesListUpdateInput[] | null;
  create?: MetadataFileInfoFramesWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataFileInfoFramesListUpdateInput[] | null;
  delete?: MetadataFileInfoFramesWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataFileInfoPhotometricInterpretationListCreateInput {
  connect?: MetadataFileInfoPhotometricInterpretationWhereUniqueInput[] | null;
  create?: MetadataFileInfoPhotometricInterpretationWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataFileInfoPhotometricInterpretationListUpdateInput {
  connect?: MetadataFileInfoPhotometricInterpretationWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataFileInfoPhotometricInterpretationListUpdateInput[] | null;
  create?: MetadataFileInfoPhotometricInterpretationWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataFileInfoPhotometricInterpretationListUpdateInput[] | null;
  delete?: MetadataFileInfoPhotometricInterpretationWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataFileInfoTransferSyntaxListCreateInput {
  connect?: MetadataFileInfoTransferSyntaxWhereUniqueInput[] | null;
  create?: MetadataFileInfoTransferSyntaxWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataFileInfoTransferSyntaxListUpdateInput {
  connect?: MetadataFileInfoTransferSyntaxWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataFileInfoTransferSyntaxListUpdateInput[] | null;
  create?: MetadataFileInfoTransferSyntaxWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataFileInfoTransferSyntaxListUpdateInput[] | null;
  delete?: MetadataFileInfoTransferSyntaxWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataFileStageListCreateInput {
  connect?: MetadataFileStageWhereUniqueInput[] | null;
  create?: MetadataFileStageWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataFileStageListUpdateInput {
  connect?: MetadataFileStageWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataFileStageListUpdateInput[] | null;
  create?: MetadataFileStageWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataFileStageListUpdateInput[] | null;
  delete?: MetadataFileStageWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataFileStatusListCreateInput {
  connect?: MetadataFileStatusWhereUniqueInput[] | null;
  create?: MetadataFileStatusWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataFileStatusListUpdateInput {
  connect?: MetadataFileStatusWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataFileStatusListUpdateInput[] | null;
  create?: MetadataFileStatusWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataFileStatusListUpdateInput[] | null;
  delete?: MetadataFileStatusWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataFileTypeListCreateInput {
  connect?: MetadataFileTypeWhereUniqueInput[] | null;
  create?: MetadataFileTypeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataFileTypeListUpdateInput {
  connect?: MetadataFileTypeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataFileTypeListUpdateInput[] | null;
  create?: MetadataFileTypeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataFileTypeListUpdateInput[] | null;
  delete?: MetadataFileTypeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataFixtureIdentifierListCreateInput {
  connect?: MetadataFixtureIdentifierWhereUniqueInput[] | null;
  create?: MetadataFixtureIdentifierWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataFixtureIdentifierListUpdateInput {
  connect?: MetadataFixtureIdentifierWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataFixtureIdentifierListUpdateInput[] | null;
  create?: MetadataFixtureIdentifierWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataFixtureIdentifierListUpdateInput[] | null;
  delete?: MetadataFixtureIdentifierWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataFormatListCreateInput {
  connect?: MetadataFormatWhereUniqueInput[] | null;
  create?: MetadataFormatWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataFormatListUpdateInput {
  connect?: MetadataFormatWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataFormatListUpdateInput[] | null;
  create?: MetadataFormatWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataFormatListUpdateInput[] | null;
  delete?: MetadataFormatWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataGpsLatitudeListCreateInput {
  connect?: MetadataGpsLatitudeWhereUniqueInput[] | null;
  create?: MetadataGpsLatitudeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataGpsLatitudeListUpdateInput {
  connect?: MetadataGpsLatitudeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataGpsLatitudeListUpdateInput[] | null;
  create?: MetadataGpsLatitudeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataGpsLatitudeListUpdateInput[] | null;
  delete?: MetadataGpsLatitudeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataGpsLongitudeListCreateInput {
  connect?: MetadataGpsLongitudeWhereUniqueInput[] | null;
  create?: MetadataGpsLongitudeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataGpsLongitudeListUpdateInput {
  connect?: MetadataGpsLongitudeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataGpsLongitudeListUpdateInput[] | null;
  create?: MetadataGpsLongitudeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataGpsLongitudeListUpdateInput[] | null;
  delete?: MetadataGpsLongitudeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataGpsProcessingMethodListCreateInput {
  connect?: MetadataGpsProcessingMethodWhereUniqueInput[] | null;
  create?: MetadataGpsProcessingMethodWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataGpsProcessingMethodListUpdateInput {
  connect?: MetadataGpsProcessingMethodWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataGpsProcessingMethodListUpdateInput[] | null;
  create?: MetadataGpsProcessingMethodWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataGpsProcessingMethodListUpdateInput[] | null;
  delete?: MetadataGpsProcessingMethodWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataHeaderListCreateInput {
  connect?: MetadataHeaderWhereUniqueInput[] | null;
  create?: MetadataHeaderWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataHeaderListUpdateInput {
  connect?: MetadataHeaderWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataHeaderListUpdateInput[] | null;
  create?: MetadataHeaderWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataHeaderListUpdateInput[] | null;
  delete?: MetadataHeaderWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataHeaderPrismaListCreateInput {
  connect?: MetadataHeaderPrismaWhereUniqueInput[] | null;
  create?: MetadataHeaderPrismaWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataHeaderPrismaListUpdateInput {
  connect?: MetadataHeaderPrismaWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataHeaderPrismaListUpdateInput[] | null;
  create?: MetadataHeaderPrismaWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataHeaderPrismaListUpdateInput[] | null;
  delete?: MetadataHeaderPrismaWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataHeadlineListCreateInput {
  connect?: MetadataHeadlineWhereUniqueInput[] | null;
  create?: MetadataHeadlineWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataHeadlineListUpdateInput {
  connect?: MetadataHeadlineWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataHeadlineListUpdateInput[] | null;
  create?: MetadataHeadlineWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataHeadlineListUpdateInput[] | null;
  delete?: MetadataHeadlineWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataHistoryListCreateInput {
  connect?: MetadataHistoryWhereUniqueInput[] | null;
  create?: MetadataHistoryWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataHistoryListUpdateInput {
  connect?: MetadataHistoryWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataHistoryListUpdateInput[] | null;
  create?: MetadataHistoryWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataHistoryListUpdateInput[] | null;
  delete?: MetadataHistoryWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataIccProfileListCreateInput {
  connect?: MetadataIccProfileWhereUniqueInput[] | null;
  create?: MetadataIccProfileWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataIccProfileListUpdateInput {
  connect?: MetadataIccProfileWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataIccProfileListUpdateInput[] | null;
  create?: MetadataIccProfileWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataIccProfileListUpdateInput[] | null;
  delete?: MetadataIccProfileWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataIdAssignmentListCreateInput {
  connect?: MetadataIdAssignmentWhereUniqueInput[] | null;
  create?: MetadataIdAssignmentWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataIdAssignmentListUpdateInput {
  connect?: MetadataIdAssignmentWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataIdAssignmentListUpdateInput[] | null;
  create?: MetadataIdAssignmentWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataIdAssignmentListUpdateInput[] | null;
  delete?: MetadataIdAssignmentWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataIdMediaContentListCreateInput {
  connect?: MetadataIdMediaContentWhereUniqueInput[] | null;
  create?: MetadataIdMediaContentWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataIdMediaContentListUpdateInput {
  connect?: MetadataIdMediaContentWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataIdMediaContentListUpdateInput[] | null;
  create?: MetadataIdMediaContentWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataIdMediaContentListUpdateInput[] | null;
  delete?: MetadataIdMediaContentWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataIdPhotoPrismaListCreateInput {
  connect?: MetadataIdPhotoPrismaWhereUniqueInput[] | null;
  create?: MetadataIdPhotoPrismaWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataIdPhotoPrismaListUpdateInput {
  connect?: MetadataIdPhotoPrismaWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataIdPhotoPrismaListUpdateInput[] | null;
  create?: MetadataIdPhotoPrismaWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataIdPhotoPrismaListUpdateInput[] | null;
  delete?: MetadataIdPhotoPrismaWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataIdSubjectListCreateInput {
  connect?: MetadataIdSubjectWhereUniqueInput[] | null;
  create?: MetadataIdSubjectWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataIdSubjectListUpdateInput {
  connect?: MetadataIdSubjectWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataIdSubjectListUpdateInput[] | null;
  create?: MetadataIdSubjectWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataIdSubjectListUpdateInput[] | null;
  delete?: MetadataIdSubjectWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataImageNotesListCreateInput {
  connect?: MetadataImageNotesWhereUniqueInput[] | null;
  create?: MetadataImageNotesWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataImageNotesListUpdateInput {
  connect?: MetadataImageNotesWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataImageNotesListUpdateInput[] | null;
  create?: MetadataImageNotesWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataImageNotesListUpdateInput[] | null;
  delete?: MetadataImageNotesWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataImageUniqueIdListCreateInput {
  connect?: MetadataImageUniqueIdWhereUniqueInput[] | null;
  create?: MetadataImageUniqueIdWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataImageUniqueIdListUpdateInput {
  connect?: MetadataImageUniqueIdWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataImageUniqueIdListUpdateInput[] | null;
  create?: MetadataImageUniqueIdWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataImageUniqueIdListUpdateInput[] | null;
  delete?: MetadataImageUniqueIdWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataInstructionsListCreateInput {
  connect?: MetadataInstructionsWhereUniqueInput[] | null;
  create?: MetadataInstructionsWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataInstructionsListUpdateInput {
  connect?: MetadataInstructionsWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataInstructionsListUpdateInput[] | null;
  create?: MetadataInstructionsWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataInstructionsListUpdateInput[] | null;
  delete?: MetadataInstructionsWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataIntellectualGenreListCreateInput {
  connect?: MetadataIntellectualGenreWhereUniqueInput[] | null;
  create?: MetadataIntellectualGenreWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataIntellectualGenreListUpdateInput {
  connect?: MetadataIntellectualGenreWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataIntellectualGenreListUpdateInput[] | null;
  create?: MetadataIntellectualGenreWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataIntellectualGenreListUpdateInput[] | null;
  delete?: MetadataIntellectualGenreWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataIsoSpeedRatingsListCreateInput {
  connect?: MetadataIsoSpeedRatingsWhereUniqueInput[] | null;
  create?: MetadataIsoSpeedRatingsWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataIsoSpeedRatingsListUpdateInput {
  connect?: MetadataIsoSpeedRatingsWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataIsoSpeedRatingsListUpdateInput[] | null;
  create?: MetadataIsoSpeedRatingsWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataIsoSpeedRatingsListUpdateInput[] | null;
  delete?: MetadataIsoSpeedRatingsWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataIssueListCreateInput {
  connect?: MetadataIssueWhereUniqueInput[] | null;
  create?: MetadataIssueWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataIssueListUpdateInput {
  connect?: MetadataIssueWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataIssueListUpdateInput[] | null;
  create?: MetadataIssueWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataIssueListUpdateInput[] | null;
  delete?: MetadataIssueWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataJobIdListCreateInput {
  connect?: MetadataJobIdWhereUniqueInput[] | null;
  create?: MetadataJobIdWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataJobIdListUpdateInput {
  connect?: MetadataJobIdWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataJobIdListUpdateInput[] | null;
  create?: MetadataJobIdWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataJobIdListUpdateInput[] | null;
  delete?: MetadataJobIdWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataJobProcessingListCreateInput {
  connect?: MetadataJobProcessingWhereUniqueInput[] | null;
  create?: MetadataJobProcessingWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataJobProcessingListUpdateInput {
  connect?: MetadataJobProcessingWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataJobProcessingListUpdateInput[] | null;
  create?: MetadataJobProcessingWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataJobProcessingListUpdateInput[] | null;
  delete?: MetadataJobProcessingWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataLayoutPictureEditingListCreateInput {
  connect?: MetadataLayoutPictureEditingWhereUniqueInput[] | null;
  create?: MetadataLayoutPictureEditingWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataLayoutPictureEditingListUpdateInput {
  connect?: MetadataLayoutPictureEditingWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataLayoutPictureEditingListUpdateInput[] | null;
  create?: MetadataLayoutPictureEditingWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataLayoutPictureEditingListUpdateInput[] | null;
  delete?: MetadataLayoutPictureEditingWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataLayoutStorageListCreateInput {
  connect?: MetadataLayoutStorageWhereUniqueInput[] | null;
  create?: MetadataLayoutStorageWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataLayoutStorageListUpdateInput {
  connect?: MetadataLayoutStorageWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataLayoutStorageListUpdateInput[] | null;
  create?: MetadataLayoutStorageWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataLayoutStorageListUpdateInput[] | null;
  delete?: MetadataLayoutStorageWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataLicenseListCreateInput {
  connect?: MetadataLicenseWhereUniqueInput[] | null;
  create?: MetadataLicenseWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataLicenseListUpdateInput {
  connect?: MetadataLicenseWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataLicenseListUpdateInput[] | null;
  create?: MetadataLicenseWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataLicenseListUpdateInput[] | null;
  delete?: MetadataLicenseWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataLinksListCreateInput {
  connect?: MetadataLinksWhereUniqueInput[] | null;
  create?: MetadataLinksWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataLinksListUpdateInput {
  connect?: MetadataLinksWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataLinksListUpdateInput[] | null;
  create?: MetadataLinksWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataLinksListUpdateInput[] | null;
  delete?: MetadataLinksWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataLocalCaptionListCreateInput {
  connect?: MetadataLocalCaptionWhereUniqueInput[] | null;
  create?: MetadataLocalCaptionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataLocalCaptionListUpdateInput {
  connect?: MetadataLocalCaptionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataLocalCaptionListUpdateInput[] | null;
  create?: MetadataLocalCaptionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataLocalCaptionListUpdateInput[] | null;
  delete?: MetadataLocalCaptionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataLocationListCreateInput {
  connect?: MetadataLocationWhereUniqueInput[] | null;
  create?: MetadataLocationWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataLocationListUpdateInput {
  connect?: MetadataLocationWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataLocationListUpdateInput[] | null;
  create?: MetadataLocationWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataLocationListUpdateInput[] | null;
  delete?: MetadataLocationWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataMakeListCreateInput {
  connect?: MetadataMakeWhereUniqueInput[] | null;
  create?: MetadataMakeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataMakeListUpdateInput {
  connect?: MetadataMakeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataMakeListUpdateInput[] | null;
  create?: MetadataMakeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataMakeListUpdateInput[] | null;
  delete?: MetadataMakeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataManifestListCreateInput {
  connect?: MetadataManifestWhereUniqueInput[] | null;
  create?: MetadataManifestWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataManifestListUpdateInput {
  connect?: MetadataManifestWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataManifestListUpdateInput[] | null;
  create?: MetadataManifestWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataManifestListUpdateInput[] | null;
  delete?: MetadataManifestWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataMarkedListCreateInput {
  connect?: MetadataMarkedWhereUniqueInput[] | null;
  create?: MetadataMarkedWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataMarkedListUpdateInput {
  connect?: MetadataMarkedWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataMarkedListUpdateInput[] | null;
  create?: MetadataMarkedWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataMarkedListUpdateInput[] | null;
  delete?: MetadataMarkedWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataMasterDocumentIdListCreateInput {
  connect?: MetadataMasterDocumentIdWhereUniqueInput[] | null;
  create?: MetadataMasterDocumentIdWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataMasterDocumentIdListUpdateInput {
  connect?: MetadataMasterDocumentIdWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataMasterDocumentIdListUpdateInput[] | null;
  create?: MetadataMasterDocumentIdWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataMasterDocumentIdListUpdateInput[] | null;
  delete?: MetadataMasterDocumentIdWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataMaxAvailHeightListCreateInput {
  connect?: MetadataMaxAvailHeightWhereUniqueInput[] | null;
  create?: MetadataMaxAvailHeightWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataMaxAvailHeightListUpdateInput {
  connect?: MetadataMaxAvailHeightWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataMaxAvailHeightListUpdateInput[] | null;
  create?: MetadataMaxAvailHeightWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataMaxAvailHeightListUpdateInput[] | null;
  delete?: MetadataMaxAvailHeightWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataMaxAvailWidthListCreateInput {
  connect?: MetadataMaxAvailWidthWhereUniqueInput[] | null;
  create?: MetadataMaxAvailWidthWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataMaxAvailWidthListUpdateInput {
  connect?: MetadataMaxAvailWidthWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataMaxAvailWidthListUpdateInput[] | null;
  create?: MetadataMaxAvailWidthWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataMaxAvailWidthListUpdateInput[] | null;
  delete?: MetadataMaxAvailWidthWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataMinorModelAgeDisclosureListCreateInput {
  connect?: MetadataMinorModelAgeDisclosureWhereUniqueInput[] | null;
  create?: MetadataMinorModelAgeDisclosureWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataMinorModelAgeDisclosureListUpdateInput {
  connect?: MetadataMinorModelAgeDisclosureWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataMinorModelAgeDisclosureListUpdateInput[] | null;
  create?: MetadataMinorModelAgeDisclosureWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataMinorModelAgeDisclosureListUpdateInput[] | null;
  delete?: MetadataMinorModelAgeDisclosureWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataModelAgeListCreateInput {
  connect?: MetadataModelAgeWhereUniqueInput[] | null;
  create?: MetadataModelAgeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataModelAgeListUpdateInput {
  connect?: MetadataModelAgeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataModelAgeListUpdateInput[] | null;
  create?: MetadataModelAgeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataModelAgeListUpdateInput[] | null;
  delete?: MetadataModelAgeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataModelListCreateInput {
  connect?: MetadataModelWhereUniqueInput[] | null;
  create?: MetadataModelWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataModelListUpdateInput {
  connect?: MetadataModelWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataModelListUpdateInput[] | null;
  create?: MetadataModelWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataModelListUpdateInput[] | null;
  delete?: MetadataModelWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataModelReleaseIdListCreateInput {
  connect?: MetadataModelReleaseIdWhereUniqueInput[] | null;
  create?: MetadataModelReleaseIdWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataModelReleaseIdListUpdateInput {
  connect?: MetadataModelReleaseIdWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataModelReleaseIdListUpdateInput[] | null;
  create?: MetadataModelReleaseIdWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataModelReleaseIdListUpdateInput[] | null;
  delete?: MetadataModelReleaseIdWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataModelReleaseStatusListCreateInput {
  connect?: MetadataModelReleaseStatusWhereUniqueInput[] | null;
  create?: MetadataModelReleaseStatusWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataModelReleaseStatusListUpdateInput {
  connect?: MetadataModelReleaseStatusWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataModelReleaseStatusListUpdateInput[] | null;
  create?: MetadataModelReleaseStatusWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataModelReleaseStatusListUpdateInput[] | null;
  delete?: MetadataModelReleaseStatusWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataMorePermissionsListCreateInput {
  connect?: MetadataMorePermissionsWhereUniqueInput[] | null;
  create?: MetadataMorePermissionsWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataMorePermissionsListUpdateInput {
  connect?: MetadataMorePermissionsWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataMorePermissionsListUpdateInput[] | null;
  create?: MetadataMorePermissionsWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataMorePermissionsListUpdateInput[] | null;
  delete?: MetadataMorePermissionsWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataObjectCycleListCreateInput {
  connect?: MetadataObjectCycleWhereUniqueInput[] | null;
  create?: MetadataObjectCycleWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataObjectCycleListUpdateInput {
  connect?: MetadataObjectCycleWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataObjectCycleListUpdateInput[] | null;
  create?: MetadataObjectCycleWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataObjectCycleListUpdateInput[] | null;
  delete?: MetadataObjectCycleWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataOrganisationInImageNameListCreateInput {
  connect?: MetadataOrganisationInImageNameWhereUniqueInput[] | null;
  create?: MetadataOrganisationInImageNameWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataOrganisationInImageNameListUpdateInput {
  connect?: MetadataOrganisationInImageNameWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataOrganisationInImageNameListUpdateInput[] | null;
  create?: MetadataOrganisationInImageNameWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataOrganisationInImageNameListUpdateInput[] | null;
  delete?: MetadataOrganisationInImageNameWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataOriginalColorListCreateInput {
  connect?: MetadataOriginalColorWhereUniqueInput[] | null;
  create?: MetadataOriginalColorWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataOriginalColorListUpdateInput {
  connect?: MetadataOriginalColorWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataOriginalColorListUpdateInput[] | null;
  create?: MetadataOriginalColorWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataOriginalColorListUpdateInput[] | null;
  delete?: MetadataOriginalColorWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataOriginalIccProfileListCreateInput {
  connect?: MetadataOriginalIccProfileWhereUniqueInput[] | null;
  create?: MetadataOriginalIccProfileWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataOriginalIccProfileListUpdateInput {
  connect?: MetadataOriginalIccProfileWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataOriginalIccProfileListUpdateInput[] | null;
  create?: MetadataOriginalIccProfileWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataOriginalIccProfileListUpdateInput[] | null;
  delete?: MetadataOriginalIccProfileWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataOriginalNameListCreateInput {
  connect?: MetadataOriginalNameWhereUniqueInput[] | null;
  create?: MetadataOriginalNameWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataOriginalNameListUpdateInput {
  connect?: MetadataOriginalNameWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataOriginalNameListUpdateInput[] | null;
  create?: MetadataOriginalNameWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataOriginalNameListUpdateInput[] | null;
  delete?: MetadataOriginalNameWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataOriginalWeigthListCreateInput {
  connect?: MetadataOriginalWeigthWhereUniqueInput[] | null;
  create?: MetadataOriginalWeigthWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataOriginalWeigthListUpdateInput {
  connect?: MetadataOriginalWeigthWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataOriginalWeigthListUpdateInput[] | null;
  create?: MetadataOriginalWeigthWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataOriginalWeigthListUpdateInput[] | null;
  delete?: MetadataOriginalWeigthWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataOwnerIdListCreateInput {
  connect?: MetadataOwnerIdWhereUniqueInput[] | null;
  create?: MetadataOwnerIdWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataOwnerIdListUpdateInput {
  connect?: MetadataOwnerIdWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataOwnerIdListUpdateInput[] | null;
  create?: MetadataOwnerIdWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataOwnerIdListUpdateInput[] | null;
  delete?: MetadataOwnerIdWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataOwnerListCreateInput {
  connect?: MetadataOwnerWhereUniqueInput[] | null;
  create?: MetadataOwnerWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataOwnerListUpdateInput {
  connect?: MetadataOwnerWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataOwnerListUpdateInput[] | null;
  create?: MetadataOwnerWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataOwnerListUpdateInput[] | null;
  delete?: MetadataOwnerWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPageListCreateInput {
  connect?: MetadataPageWhereUniqueInput[] | null;
  create?: MetadataPageWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPageListUpdateInput {
  connect?: MetadataPageWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPageListUpdateInput[] | null;
  create?: MetadataPageWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPageListUpdateInput[] | null;
  delete?: MetadataPageWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPagePrismaListCreateInput {
  connect?: MetadataPagePrismaWhereUniqueInput[] | null;
  create?: MetadataPagePrismaWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPagePrismaListUpdateInput {
  connect?: MetadataPagePrismaWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPagePrismaListUpdateInput[] | null;
  create?: MetadataPagePrismaWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPagePrismaListUpdateInput[] | null;
  delete?: MetadataPagePrismaWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPaintBasedCorrectionsListCreateInput {
  connect?: MetadataPaintBasedCorrectionsWhereUniqueInput[] | null;
  create?: MetadataPaintBasedCorrectionsWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPaintBasedCorrectionsListUpdateInput {
  connect?: MetadataPaintBasedCorrectionsWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPaintBasedCorrectionsListUpdateInput[] | null;
  create?: MetadataPaintBasedCorrectionsWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPaintBasedCorrectionsListUpdateInput[] | null;
  delete?: MetadataPaintBasedCorrectionsWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPatientDobListCreateInput {
  connect?: MetadataPatientDobWhereUniqueInput[] | null;
  create?: MetadataPatientDobWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPatientDobListUpdateInput {
  connect?: MetadataPatientDobWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPatientDobListUpdateInput[] | null;
  create?: MetadataPatientDobWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPatientDobListUpdateInput[] | null;
  delete?: MetadataPatientDobWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPatientIdListCreateInput {
  connect?: MetadataPatientIdWhereUniqueInput[] | null;
  create?: MetadataPatientIdWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPatientIdListUpdateInput {
  connect?: MetadataPatientIdWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPatientIdListUpdateInput[] | null;
  create?: MetadataPatientIdWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPatientIdListUpdateInput[] | null;
  delete?: MetadataPatientIdWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPatientNameListCreateInput {
  connect?: MetadataPatientNameWhereUniqueInput[] | null;
  create?: MetadataPatientNameWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPatientNameListUpdateInput {
  connect?: MetadataPatientNameWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPatientNameListUpdateInput[] | null;
  create?: MetadataPatientNameWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPatientNameListUpdateInput[] | null;
  delete?: MetadataPatientNameWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPatientSexListCreateInput {
  connect?: MetadataPatientSexWhereUniqueInput[] | null;
  create?: MetadataPatientSexWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPatientSexListUpdateInput {
  connect?: MetadataPatientSexWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPatientSexListUpdateInput[] | null;
  create?: MetadataPatientSexWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPatientSexListUpdateInput[] | null;
  delete?: MetadataPatientSexWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPersonInImageListCreateInput {
  connect?: MetadataPersonInImageWhereUniqueInput[] | null;
  create?: MetadataPersonInImageWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPersonInImageListUpdateInput {
  connect?: MetadataPersonInImageWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPersonInImageListUpdateInput[] | null;
  create?: MetadataPersonInImageWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPersonInImageListUpdateInput[] | null;
  delete?: MetadataPersonInImageWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPhotoStorageListCreateInput {
  connect?: MetadataPhotoStorageWhereUniqueInput[] | null;
  create?: MetadataPhotoStorageWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPhotoStorageListUpdateInput {
  connect?: MetadataPhotoStorageWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPhotoStorageListUpdateInput[] | null;
  create?: MetadataPhotoStorageWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPhotoStorageListUpdateInput[] | null;
  delete?: MetadataPhotoStorageWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPrepressPictureEditingListCreateInput {
  connect?: MetadataPrepressPictureEditingWhereUniqueInput[] | null;
  create?: MetadataPrepressPictureEditingWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPrepressPictureEditingListUpdateInput {
  connect?: MetadataPrepressPictureEditingWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPrepressPictureEditingListUpdateInput[] | null;
  create?: MetadataPrepressPictureEditingWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPrepressPictureEditingListUpdateInput[] | null;
  delete?: MetadataPrepressPictureEditingWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPriceLevelListCreateInput {
  connect?: MetadataPriceLevelWhereUniqueInput[] | null;
  create?: MetadataPriceLevelWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPriceLevelListUpdateInput {
  connect?: MetadataPriceLevelWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPriceLevelListUpdateInput[] | null;
  create?: MetadataPriceLevelWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPriceLevelListUpdateInput[] | null;
  delete?: MetadataPriceLevelWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPrintingProfileListCreateInput {
  connect?: MetadataPrintingProfileWhereUniqueInput[] | null;
  create?: MetadataPrintingProfileWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPrintingProfileListUpdateInput {
  connect?: MetadataPrintingProfileWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPrintingProfileListUpdateInput[] | null;
  create?: MetadataPrintingProfileWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPrintingProfileListUpdateInput[] | null;
  delete?: MetadataPrintingProfileWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPrismaProductionListCreateInput {
  connect?: MetadataPrismaProductionWhereUniqueInput[] | null;
  create?: MetadataPrismaProductionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPrismaProductionListUpdateInput {
  connect?: MetadataPrismaProductionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPrismaProductionListUpdateInput[] | null;
  create?: MetadataPrismaProductionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPrismaProductionListUpdateInput[] | null;
  delete?: MetadataPrismaProductionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataProcessHistoryListCreateInput {
  connect?: MetadataProcessHistoryWhereUniqueInput[] | null;
  create?: MetadataProcessHistoryWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataProcessHistoryListUpdateInput {
  connect?: MetadataProcessHistoryWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataProcessHistoryListUpdateInput[] | null;
  create?: MetadataProcessHistoryWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataProcessHistoryListUpdateInput[] | null;
  delete?: MetadataProcessHistoryWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataProcessParameterListCreateInput {
  connect?: MetadataProcessParameterWhereUniqueInput[] | null;
  create?: MetadataProcessParameterWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataProcessParameterListUpdateInput {
  connect?: MetadataProcessParameterWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataProcessParameterListUpdateInput[] | null;
  create?: MetadataProcessParameterWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataProcessParameterListUpdateInput[] | null;
  delete?: MetadataProcessParameterWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataProcessStatusListCreateInput {
  connect?: MetadataProcessStatusWhereUniqueInput[] | null;
  create?: MetadataProcessStatusWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataProcessStatusListUpdateInput {
  connect?: MetadataProcessStatusWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataProcessStatusListUpdateInput[] | null;
  create?: MetadataProcessStatusWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataProcessStatusListUpdateInput[] | null;
  delete?: MetadataProcessStatusWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataProductListCreateInput {
  connect?: MetadataProductWhereUniqueInput[] | null;
  create?: MetadataProductWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataProductListUpdateInput {
  connect?: MetadataProductWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataProductListUpdateInput[] | null;
  create?: MetadataProductWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataProductListUpdateInput[] | null;
  delete?: MetadataProductWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataProductShortNameListCreateInput {
  connect?: MetadataProductShortNameWhereUniqueInput[] | null;
  create?: MetadataProductShortNameWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataProductShortNameListUpdateInput {
  connect?: MetadataProductShortNameWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataProductShortNameListUpdateInput[] | null;
  create?: MetadataProductShortNameWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataProductShortNameListUpdateInput[] | null;
  delete?: MetadataProductShortNameWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataProductsListCreateInput {
  connect?: MetadataProductsWhereUniqueInput[] | null;
  create?: MetadataProductsWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataProductsListUpdateInput {
  connect?: MetadataProductsWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataProductsListUpdateInput[] | null;
  create?: MetadataProductsWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataProductsListUpdateInput[] | null;
  delete?: MetadataProductsWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataProduitsListCreateInput {
  connect?: MetadataProduitsWhereUniqueInput[] | null;
  create?: MetadataProduitsWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataProduitsListUpdateInput {
  connect?: MetadataProduitsWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataProduitsListUpdateInput[] | null;
  create?: MetadataProduitsWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataProduitsListUpdateInput[] | null;
  delete?: MetadataProduitsWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataProgramVersionListCreateInput {
  connect?: MetadataProgramVersionWhereUniqueInput[] | null;
  create?: MetadataProgramVersionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataProgramVersionListUpdateInput {
  connect?: MetadataProgramVersionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataProgramVersionListUpdateInput[] | null;
  create?: MetadataProgramVersionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataProgramVersionListUpdateInput[] | null;
  delete?: MetadataProgramVersionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPropertyReleaseIdListCreateInput {
  connect?: MetadataPropertyReleaseIdWhereUniqueInput[] | null;
  create?: MetadataPropertyReleaseIdWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPropertyReleaseIdListUpdateInput {
  connect?: MetadataPropertyReleaseIdWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPropertyReleaseIdListUpdateInput[] | null;
  create?: MetadataPropertyReleaseIdWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPropertyReleaseIdListUpdateInput[] | null;
  delete?: MetadataPropertyReleaseIdWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPropertyReleaseStatusListCreateInput {
  connect?: MetadataPropertyReleaseStatusWhereUniqueInput[] | null;
  create?: MetadataPropertyReleaseStatusWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPropertyReleaseStatusListUpdateInput {
  connect?: MetadataPropertyReleaseStatusWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPropertyReleaseStatusListUpdateInput[] | null;
  create?: MetadataPropertyReleaseStatusWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPropertyReleaseStatusListUpdateInput[] | null;
  delete?: MetadataPropertyReleaseStatusWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPublisherListCreateInput {
  connect?: MetadataPublisherWhereUniqueInput[] | null;
  create?: MetadataPublisherWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPublisherListUpdateInput {
  connect?: MetadataPublisherWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPublisherListUpdateInput[] | null;
  create?: MetadataPublisherWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPublisherListUpdateInput[] | null;
  delete?: MetadataPublisherWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPublishingIssueListCreateInput {
  connect?: MetadataPublishingIssueWhereUniqueInput[] | null;
  create?: MetadataPublishingIssueWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPublishingIssueListUpdateInput {
  connect?: MetadataPublishingIssueWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPublishingIssueListUpdateInput[] | null;
  create?: MetadataPublishingIssueWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPublishingIssueListUpdateInput[] | null;
  delete?: MetadataPublishingIssueWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataPublishingSubjectListCreateInput {
  connect?: MetadataPublishingSubjectWhereUniqueInput[] | null;
  create?: MetadataPublishingSubjectWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataPublishingSubjectListUpdateInput {
  connect?: MetadataPublishingSubjectWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataPublishingSubjectListUpdateInput[] | null;
  create?: MetadataPublishingSubjectWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataPublishingSubjectListUpdateInput[] | null;
  delete?: MetadataPublishingSubjectWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataQualifiedUsePrismaByListCreateInput {
  connect?: MetadataQualifiedUsePrismaByWhereUniqueInput[] | null;
  create?: MetadataQualifiedUsePrismaByWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataQualifiedUsePrismaByListUpdateInput {
  connect?: MetadataQualifiedUsePrismaByWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataQualifiedUsePrismaByListUpdateInput[] | null;
  create?: MetadataQualifiedUsePrismaByWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataQualifiedUsePrismaByListUpdateInput[] | null;
  delete?: MetadataQualifiedUsePrismaByWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataQualifiedUsePrismaDateListCreateInput {
  connect?: MetadataQualifiedUsePrismaDateWhereUniqueInput[] | null;
  create?: MetadataQualifiedUsePrismaDateWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataQualifiedUsePrismaDateListUpdateInput {
  connect?: MetadataQualifiedUsePrismaDateWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataQualifiedUsePrismaDateListUpdateInput[] | null;
  create?: MetadataQualifiedUsePrismaDateWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataQualifiedUsePrismaDateListUpdateInput[] | null;
  delete?: MetadataQualifiedUsePrismaDateWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataQualifiedUsePrismaDurationListCreateInput {
  connect?: MetadataQualifiedUsePrismaDurationWhereUniqueInput[] | null;
  create?: MetadataQualifiedUsePrismaDurationWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataQualifiedUsePrismaDurationListUpdateInput {
  connect?: MetadataQualifiedUsePrismaDurationWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataQualifiedUsePrismaDurationListUpdateInput[] | null;
  create?: MetadataQualifiedUsePrismaDurationWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataQualifiedUsePrismaDurationListUpdateInput[] | null;
  delete?: MetadataQualifiedUsePrismaDurationWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataQualifiedUsePrismaSupportListCreateInput {
  connect?: MetadataQualifiedUsePrismaSupportWhereUniqueInput[] | null;
  create?: MetadataQualifiedUsePrismaSupportWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataQualifiedUsePrismaSupportListUpdateInput {
  connect?: MetadataQualifiedUsePrismaSupportWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataQualifiedUsePrismaSupportListUpdateInput[] | null;
  create?: MetadataQualifiedUsePrismaSupportWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataQualifiedUsePrismaSupportListUpdateInput[] | null;
  delete?: MetadataQualifiedUsePrismaSupportWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataRatingListCreateInput {
  connect?: MetadataRatingWhereUniqueInput[] | null;
  create?: MetadataRatingWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataRatingListUpdateInput {
  connect?: MetadataRatingWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataRatingListUpdateInput[] | null;
  create?: MetadataRatingWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataRatingListUpdateInput[] | null;
  delete?: MetadataRatingWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataReferenceDateListCreateInput {
  connect?: MetadataReferenceDateWhereUniqueInput[] | null;
  create?: MetadataReferenceDateWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataReferenceDateListUpdateInput {
  connect?: MetadataReferenceDateWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataReferenceDateListUpdateInput[] | null;
  create?: MetadataReferenceDateWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataReferenceDateListUpdateInput[] | null;
  delete?: MetadataReferenceDateWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataReferenceNumberListCreateInput {
  connect?: MetadataReferenceNumberWhereUniqueInput[] | null;
  create?: MetadataReferenceNumberWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataReferenceNumberListUpdateInput {
  connect?: MetadataReferenceNumberWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataReferenceNumberListUpdateInput[] | null;
  create?: MetadataReferenceNumberWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataReferenceNumberListUpdateInput[] | null;
  delete?: MetadataReferenceNumberWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataReferenceServiceListCreateInput {
  connect?: MetadataReferenceServiceWhereUniqueInput[] | null;
  create?: MetadataReferenceServiceWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataReferenceServiceListUpdateInput {
  connect?: MetadataReferenceServiceWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataReferenceServiceListUpdateInput[] | null;
  create?: MetadataReferenceServiceWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataReferenceServiceListUpdateInput[] | null;
  delete?: MetadataReferenceServiceWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataReleaseDateListCreateInput {
  connect?: MetadataReleaseDateWhereUniqueInput[] | null;
  create?: MetadataReleaseDateWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataReleaseDateListUpdateInput {
  connect?: MetadataReleaseDateWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataReleaseDateListUpdateInput[] | null;
  create?: MetadataReleaseDateWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataReleaseDateListUpdateInput[] | null;
  delete?: MetadataReleaseDateWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataReleaseTimeListCreateInput {
  connect?: MetadataReleaseTimeWhereUniqueInput[] | null;
  create?: MetadataReleaseTimeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataReleaseTimeListUpdateInput {
  connect?: MetadataReleaseTimeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataReleaseTimeListUpdateInput[] | null;
  create?: MetadataReleaseTimeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataReleaseTimeListUpdateInput[] | null;
  delete?: MetadataReleaseTimeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataRequiredPermissionListCreateInput {
  connect?: MetadataRequiredPermissionWhereUniqueInput[] | null;
  create?: MetadataRequiredPermissionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataRequiredPermissionListUpdateInput {
  connect?: MetadataRequiredPermissionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataRequiredPermissionListUpdateInput[] | null;
  create?: MetadataRequiredPermissionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataRequiredPermissionListUpdateInput[] | null;
  delete?: MetadataRequiredPermissionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataResolutionKindListCreateInput {
  connect?: MetadataResolutionKindWhereUniqueInput[] | null;
  create?: MetadataResolutionKindWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataResolutionKindListUpdateInput {
  connect?: MetadataResolutionKindWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataResolutionKindListUpdateInput[] | null;
  create?: MetadataResolutionKindWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataResolutionKindListUpdateInput[] | null;
  delete?: MetadataResolutionKindWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataRightsListCreateInput {
  connect?: MetadataRightsWhereUniqueInput[] | null;
  create?: MetadataRightsWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataRightsListUpdateInput {
  connect?: MetadataRightsWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataRightsListUpdateInput[] | null;
  create?: MetadataRightsWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataRightsListUpdateInput[] | null;
  delete?: MetadataRightsWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataRoyaltyfreeListCreateInput {
  connect?: MetadataRoyaltyfreeWhereUniqueInput[] | null;
  create?: MetadataRoyaltyfreeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataRoyaltyfreeListUpdateInput {
  connect?: MetadataRoyaltyfreeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataRoyaltyfreeListUpdateInput[] | null;
  create?: MetadataRoyaltyfreeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataRoyaltyfreeListUpdateInput[] | null;
  delete?: MetadataRoyaltyfreeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataSceneListCreateInput {
  connect?: MetadataSceneWhereUniqueInput[] | null;
  create?: MetadataSceneWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataSceneListUpdateInput {
  connect?: MetadataSceneWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataSceneListUpdateInput[] | null;
  create?: MetadataSceneWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataSceneListUpdateInput[] | null;
  delete?: MetadataSceneWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataSectionListCreateInput {
  connect?: MetadataSectionWhereUniqueInput[] | null;
  create?: MetadataSectionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataSectionListUpdateInput {
  connect?: MetadataSectionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataSectionListUpdateInput[] | null;
  create?: MetadataSectionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataSectionListUpdateInput[] | null;
  delete?: MetadataSectionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataSentToListCreateInput {
  connect?: MetadataSentToWhereUniqueInput[] | null;
  create?: MetadataSentToWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataSentToListUpdateInput {
  connect?: MetadataSentToWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataSentToListUpdateInput[] | null;
  create?: MetadataSentToWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataSentToListUpdateInput[] | null;
  delete?: MetadataSentToWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataSerialNumberListCreateInput {
  connect?: MetadataSerialNumberWhereUniqueInput[] | null;
  create?: MetadataSerialNumberWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataSerialNumberListUpdateInput {
  connect?: MetadataSerialNumberWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataSerialNumberListUpdateInput[] | null;
  create?: MetadataSerialNumberWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataSerialNumberListUpdateInput[] | null;
  delete?: MetadataSerialNumberWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataSeriesDateTimeListCreateInput {
  connect?: MetadataSeriesDateTimeWhereUniqueInput[] | null;
  create?: MetadataSeriesDateTimeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataSeriesDateTimeListUpdateInput {
  connect?: MetadataSeriesDateTimeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataSeriesDateTimeListUpdateInput[] | null;
  create?: MetadataSeriesDateTimeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataSeriesDateTimeListUpdateInput[] | null;
  delete?: MetadataSeriesDateTimeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataSeriesDescriptionListCreateInput {
  connect?: MetadataSeriesDescriptionWhereUniqueInput[] | null;
  create?: MetadataSeriesDescriptionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataSeriesDescriptionListUpdateInput {
  connect?: MetadataSeriesDescriptionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataSeriesDescriptionListUpdateInput[] | null;
  create?: MetadataSeriesDescriptionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataSeriesDescriptionListUpdateInput[] | null;
  delete?: MetadataSeriesDescriptionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataSeriesModalityListCreateInput {
  connect?: MetadataSeriesModalityWhereUniqueInput[] | null;
  create?: MetadataSeriesModalityWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataSeriesModalityListUpdateInput {
  connect?: MetadataSeriesModalityWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataSeriesModalityListUpdateInput[] | null;
  create?: MetadataSeriesModalityWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataSeriesModalityListUpdateInput[] | null;
  delete?: MetadataSeriesModalityWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataSeriesNumberListCreateInput {
  connect?: MetadataSeriesNumberWhereUniqueInput[] | null;
  create?: MetadataSeriesNumberWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataSeriesNumberListUpdateInput {
  connect?: MetadataSeriesNumberWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataSeriesNumberListUpdateInput[] | null;
  create?: MetadataSeriesNumberWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataSeriesNumberListUpdateInput[] | null;
  delete?: MetadataSeriesNumberWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataShortUniqueIdListCreateInput {
  connect?: MetadataShortUniqueIdWhereUniqueInput[] | null;
  create?: MetadataShortUniqueIdWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataShortUniqueIdListUpdateInput {
  connect?: MetadataShortUniqueIdWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataShortUniqueIdListUpdateInput[] | null;
  create?: MetadataShortUniqueIdWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataShortUniqueIdListUpdateInput[] | null;
  delete?: MetadataShortUniqueIdWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataSourceListCreateInput {
  connect?: MetadataSourceWhereUniqueInput[] | null;
  create?: MetadataSourceWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataSourceListUpdateInput {
  connect?: MetadataSourceWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataSourceListUpdateInput[] | null;
  create?: MetadataSourceWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataSourceListUpdateInput[] | null;
  delete?: MetadataSourceWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataStateListCreateInput {
  connect?: MetadataStateWhereUniqueInput[] | null;
  create?: MetadataStateWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataStateListUpdateInput {
  connect?: MetadataStateWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataStateListUpdateInput[] | null;
  create?: MetadataStateWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataStateListUpdateInput[] | null;
  delete?: MetadataStateWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataStudyDateTimeListCreateInput {
  connect?: MetadataStudyDateTimeWhereUniqueInput[] | null;
  create?: MetadataStudyDateTimeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataStudyDateTimeListUpdateInput {
  connect?: MetadataStudyDateTimeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataStudyDateTimeListUpdateInput[] | null;
  create?: MetadataStudyDateTimeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataStudyDateTimeListUpdateInput[] | null;
  delete?: MetadataStudyDateTimeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataStudyDescriptionListCreateInput {
  connect?: MetadataStudyDescriptionWhereUniqueInput[] | null;
  create?: MetadataStudyDescriptionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataStudyDescriptionListUpdateInput {
  connect?: MetadataStudyDescriptionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataStudyDescriptionListUpdateInput[] | null;
  create?: MetadataStudyDescriptionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataStudyDescriptionListUpdateInput[] | null;
  delete?: MetadataStudyDescriptionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataStudyIdListCreateInput {
  connect?: MetadataStudyIdWhereUniqueInput[] | null;
  create?: MetadataStudyIdWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataStudyIdListUpdateInput {
  connect?: MetadataStudyIdWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataStudyIdListUpdateInput[] | null;
  create?: MetadataStudyIdWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataStudyIdListUpdateInput[] | null;
  delete?: MetadataStudyIdWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataStudyPhysicianListCreateInput {
  connect?: MetadataStudyPhysicianWhereUniqueInput[] | null;
  create?: MetadataStudyPhysicianWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataStudyPhysicianListUpdateInput {
  connect?: MetadataStudyPhysicianWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataStudyPhysicianListUpdateInput[] | null;
  create?: MetadataStudyPhysicianWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataStudyPhysicianListUpdateInput[] | null;
  delete?: MetadataStudyPhysicianWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataSubjectCodeListCreateInput {
  connect?: MetadataSubjectCodeWhereUniqueInput[] | null;
  create?: MetadataSubjectCodeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataSubjectCodeListUpdateInput {
  connect?: MetadataSubjectCodeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataSubjectCodeListUpdateInput[] | null;
  create?: MetadataSubjectCodeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataSubjectCodeListUpdateInput[] | null;
  delete?: MetadataSubjectCodeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataSubjectListCreateInput {
  connect?: MetadataSubjectWhereUniqueInput[] | null;
  create?: MetadataSubjectWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataSubjectListUpdateInput {
  connect?: MetadataSubjectWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataSubjectListUpdateInput[] | null;
  create?: MetadataSubjectWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataSubjectListUpdateInput[] | null;
  delete?: MetadataSubjectWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataSubjectPrismaListCreateInput {
  connect?: MetadataSubjectPrismaWhereUniqueInput[] | null;
  create?: MetadataSubjectPrismaWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataSubjectPrismaListUpdateInput {
  connect?: MetadataSubjectPrismaWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataSubjectPrismaListUpdateInput[] | null;
  create?: MetadataSubjectPrismaWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataSubjectPrismaListUpdateInput[] | null;
  delete?: MetadataSubjectPrismaWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataSupplementalCategoriesListCreateInput {
  connect?: MetadataSupplementalCategoriesWhereUniqueInput[] | null;
  create?: MetadataSupplementalCategoriesWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataSupplementalCategoriesListUpdateInput {
  connect?: MetadataSupplementalCategoriesWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataSupplementalCategoriesListUpdateInput[] | null;
  create?: MetadataSupplementalCategoriesWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataSupplementalCategoriesListUpdateInput[] | null;
  delete?: MetadataSupplementalCategoriesWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataTargetVersionListCreateInput {
  connect?: MetadataTargetVersionWhereUniqueInput[] | null;
  create?: MetadataTargetVersionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataTargetVersionListUpdateInput {
  connect?: MetadataTargetVersionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataTargetVersionListUpdateInput[] | null;
  create?: MetadataTargetVersionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataTargetVersionListUpdateInput[] | null;
  delete?: MetadataTargetVersionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataTitleListCreateInput {
  connect?: MetadataTitleWhereUniqueInput[] | null;
  create?: MetadataTitleWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataTitleListUpdateInput {
  connect?: MetadataTitleWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataTitleListUpdateInput[] | null;
  create?: MetadataTitleWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataTitleListUpdateInput[] | null;
  delete?: MetadataTitleWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataToneCurveListCreateInput {
  connect?: MetadataToneCurveWhereUniqueInput[] | null;
  create?: MetadataToneCurveWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataToneCurveListUpdateInput {
  connect?: MetadataToneCurveWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataToneCurveListUpdateInput[] | null;
  create?: MetadataToneCurveWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataToneCurveListUpdateInput[] | null;
  delete?: MetadataToneCurveWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataTransferredByEmailListCreateInput {
  connect?: MetadataTransferredByEmailWhereUniqueInput[] | null;
  create?: MetadataTransferredByEmailWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataTransferredByEmailListUpdateInput {
  connect?: MetadataTransferredByEmailWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataTransferredByEmailListUpdateInput[] | null;
  create?: MetadataTransferredByEmailWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataTransferredByEmailListUpdateInput[] | null;
  delete?: MetadataTransferredByEmailWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataTransferredByFullNameListCreateInput {
  connect?: MetadataTransferredByFullNameWhereUniqueInput[] | null;
  create?: MetadataTransferredByFullNameWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataTransferredByFullNameListUpdateInput {
  connect?: MetadataTransferredByFullNameWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataTransferredByFullNameListUpdateInput[] | null;
  create?: MetadataTransferredByFullNameWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataTransferredByFullNameListUpdateInput[] | null;
  delete?: MetadataTransferredByFullNameWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataTransferredByListCreateInput {
  connect?: MetadataTransferredByWhereUniqueInput[] | null;
  create?: MetadataTransferredByWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataTransferredByListUpdateInput {
  connect?: MetadataTransferredByWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataTransferredByListUpdateInput[] | null;
  create?: MetadataTransferredByWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataTransferredByListUpdateInput[] | null;
  delete?: MetadataTransferredByWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataTransmissionReferenceListCreateInput {
  connect?: MetadataTransmissionReferenceWhereUniqueInput[] | null;
  create?: MetadataTransmissionReferenceWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataTransmissionReferenceListUpdateInput {
  connect?: MetadataTransmissionReferenceWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataTransmissionReferenceListUpdateInput[] | null;
  create?: MetadataTransmissionReferenceWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataTransmissionReferenceListUpdateInput[] | null;
  delete?: MetadataTransmissionReferenceWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataTypeListCreateInput {
  connect?: MetadataTypeWhereUniqueInput[] | null;
  create?: MetadataTypeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataTypeListUpdateInput {
  connect?: MetadataTypeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataTypeListUpdateInput[] | null;
  create?: MetadataTypeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataTypeListUpdateInput[] | null;
  delete?: MetadataTypeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUnderUnderSubjectListCreateInput {
  connect?: MetadataUnderUnderSubjectWhereUniqueInput[] | null;
  create?: MetadataUnderUnderSubjectWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUnderUnderSubjectListUpdateInput {
  connect?: MetadataUnderUnderSubjectWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUnderUnderSubjectListUpdateInput[] | null;
  create?: MetadataUnderUnderSubjectWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUnderUnderSubjectListUpdateInput[] | null;
  delete?: MetadataUnderUnderSubjectWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUndersubjectListCreateInput {
  connect?: MetadataUndersubjectWhereUniqueInput[] | null;
  create?: MetadataUndersubjectWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUndersubjectListUpdateInput {
  connect?: MetadataUndersubjectWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUndersubjectListUpdateInput[] | null;
  create?: MetadataUndersubjectWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUndersubjectListUpdateInput[] | null;
  delete?: MetadataUndersubjectWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUniqueIdListCreateInput {
  connect?: MetadataUniqueIdWhereUniqueInput[] | null;
  create?: MetadataUniqueIdWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUniqueIdListUpdateInput {
  connect?: MetadataUniqueIdWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUniqueIdListUpdateInput[] | null;
  create?: MetadataUniqueIdWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUniqueIdListUpdateInput[] | null;
  delete?: MetadataUniqueIdWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUnitListCreateInput {
  connect?: MetadataUnitWhereUniqueInput[] | null;
  create?: MetadataUnitWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUnitListUpdateInput {
  connect?: MetadataUnitWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUnitListUpdateInput[] | null;
  create?: MetadataUnitWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUnitListUpdateInput[] | null;
  delete?: MetadataUnitWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUnitShortNameListCreateInput {
  connect?: MetadataUnitShortNameWhereUniqueInput[] | null;
  create?: MetadataUnitShortNameWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUnitShortNameListUpdateInput {
  connect?: MetadataUnitShortNameWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUnitShortNameListUpdateInput[] | null;
  create?: MetadataUnitShortNameWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUnitShortNameListUpdateInput[] | null;
  delete?: MetadataUnitShortNameWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUploadTimeListCreateInput {
  connect?: MetadataUploadTimeWhereUniqueInput[] | null;
  create?: MetadataUploadTimeWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUploadTimeListUpdateInput {
  connect?: MetadataUploadTimeWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUploadTimeListUpdateInput[] | null;
  create?: MetadataUploadTimeWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUploadTimeListUpdateInput[] | null;
  delete?: MetadataUploadTimeWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUploadedByFullNameListCreateInput {
  connect?: MetadataUploadedByFullNameWhereUniqueInput[] | null;
  create?: MetadataUploadedByFullNameWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUploadedByFullNameListUpdateInput {
  connect?: MetadataUploadedByFullNameWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUploadedByFullNameListUpdateInput[] | null;
  create?: MetadataUploadedByFullNameWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUploadedByFullNameListUpdateInput[] | null;
  delete?: MetadataUploadedByFullNameWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUploadedByListCreateInput {
  connect?: MetadataUploadedByWhereUniqueInput[] | null;
  create?: MetadataUploadedByWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUploadedByListUpdateInput {
  connect?: MetadataUploadedByWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUploadedByListUpdateInput[] | null;
  create?: MetadataUploadedByWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUploadedByListUpdateInput[] | null;
  delete?: MetadataUploadedByWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUrgencyListCreateInput {
  connect?: MetadataUrgencyWhereUniqueInput[] | null;
  create?: MetadataUrgencyWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUrgencyListUpdateInput {
  connect?: MetadataUrgencyWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUrgencyListUpdateInput[] | null;
  create?: MetadataUrgencyWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUrgencyListUpdateInput[] | null;
  delete?: MetadataUrgencyWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUsageTermsListCreateInput {
  connect?: MetadataUsageTermsWhereUniqueInput[] | null;
  create?: MetadataUsageTermsWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUsageTermsListUpdateInput {
  connect?: MetadataUsageTermsWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUsageTermsListUpdateInput[] | null;
  create?: MetadataUsageTermsWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUsageTermsListUpdateInput[] | null;
  delete?: MetadataUsageTermsWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUserCommentListCreateInput {
  connect?: MetadataUserCommentWhereUniqueInput[] | null;
  create?: MetadataUserCommentWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUserCommentListUpdateInput {
  connect?: MetadataUserCommentWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUserCommentListUpdateInput[] | null;
  create?: MetadataUserCommentWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUserCommentListUpdateInput[] | null;
  delete?: MetadataUserCommentWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUserDefined195ListCreateInput {
  connect?: MetadataUserDefined195WhereUniqueInput[] | null;
  create?: MetadataUserDefined195WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUserDefined195ListUpdateInput {
  connect?: MetadataUserDefined195WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUserDefined195ListUpdateInput[] | null;
  create?: MetadataUserDefined195WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUserDefined195ListUpdateInput[] | null;
  delete?: MetadataUserDefined195WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUserDefined237ListCreateInput {
  connect?: MetadataUserDefined237WhereUniqueInput[] | null;
  create?: MetadataUserDefined237WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUserDefined237ListUpdateInput {
  connect?: MetadataUserDefined237WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUserDefined237ListUpdateInput[] | null;
  create?: MetadataUserDefined237WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUserDefined237ListUpdateInput[] | null;
  delete?: MetadataUserDefined237WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUserDefined238ListCreateInput {
  connect?: MetadataUserDefined238WhereUniqueInput[] | null;
  create?: MetadataUserDefined238WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUserDefined238ListUpdateInput {
  connect?: MetadataUserDefined238WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUserDefined238ListUpdateInput[] | null;
  create?: MetadataUserDefined238WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUserDefined238ListUpdateInput[] | null;
  delete?: MetadataUserDefined238WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUserDefined239ListCreateInput {
  connect?: MetadataUserDefined239WhereUniqueInput[] | null;
  create?: MetadataUserDefined239WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUserDefined239ListUpdateInput {
  connect?: MetadataUserDefined239WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUserDefined239ListUpdateInput[] | null;
  create?: MetadataUserDefined239WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUserDefined239ListUpdateInput[] | null;
  delete?: MetadataUserDefined239WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUserDefined242ListCreateInput {
  connect?: MetadataUserDefined242WhereUniqueInput[] | null;
  create?: MetadataUserDefined242WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUserDefined242ListUpdateInput {
  connect?: MetadataUserDefined242WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUserDefined242ListUpdateInput[] | null;
  create?: MetadataUserDefined242WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUserDefined242ListUpdateInput[] | null;
  delete?: MetadataUserDefined242WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataUserDefined62ListCreateInput {
  connect?: MetadataUserDefined62WhereUniqueInput[] | null;
  create?: MetadataUserDefined62WithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataUserDefined62ListUpdateInput {
  connect?: MetadataUserDefined62WhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataUserDefined62ListUpdateInput[] | null;
  create?: MetadataUserDefined62WithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataUserDefined62ListUpdateInput[] | null;
  delete?: MetadataUserDefined62WhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataValidListCreateInput {
  connect?: MetadataValidWhereUniqueInput[] | null;
  create?: MetadataValidWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataValidListUpdateInput {
  connect?: MetadataValidWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataValidListUpdateInput[] | null;
  create?: MetadataValidWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataValidListUpdateInput[] | null;
  delete?: MetadataValidWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataVersionListCreateInput {
  connect?: MetadataVersionWhereUniqueInput[] | null;
  create?: MetadataVersionWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataVersionListUpdateInput {
  connect?: MetadataVersionWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataVersionListUpdateInput[] | null;
  create?: MetadataVersionWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataVersionListUpdateInput[] | null;
  delete?: MetadataVersionWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataWebStatementListCreateInput {
  connect?: MetadataWebStatementWhereUniqueInput[] | null;
  create?: MetadataWebStatementWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataWebStatementListUpdateInput {
  connect?: MetadataWebStatementWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataWebStatementListUpdateInput[] | null;
  create?: MetadataWebStatementWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataWebStatementListUpdateInput[] | null;
  delete?: MetadataWebStatementWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataWorkflowKindListCreateInput {
  connect?: MetadataWorkflowKindWhereUniqueInput[] | null;
  create?: MetadataWorkflowKindWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataWorkflowKindListUpdateInput {
  connect?: MetadataWorkflowKindWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataWorkflowKindListUpdateInput[] | null;
  create?: MetadataWorkflowKindWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataWorkflowKindListUpdateInput[] | null;
  delete?: MetadataWorkflowKindWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataXmpFileStampsListCreateInput {
  connect?: MetadataXmpFileStampsWhereUniqueInput[] | null;
  create?: MetadataXmpFileStampsWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataXmpFileStampsListUpdateInput {
  connect?: MetadataXmpFileStampsWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataXmpFileStampsListUpdateInput[] | null;
  create?: MetadataXmpFileStampsWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataXmpFileStampsListUpdateInput[] | null;
  delete?: MetadataXmpFileStampsWhereUniqueInput[] | null;
}

export interface ArchiveNestedMetadataXmpHistoryListCreateInput {
  connect?: MetadataXmpHistoryWhereUniqueInput[] | null;
  create?: MetadataXmpHistoryWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedMetadataXmpHistoryListUpdateInput {
  connect?: MetadataXmpHistoryWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdateMetadataXmpHistoryListUpdateInput[] | null;
  create?: MetadataXmpHistoryWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertMetadataXmpHistoryListUpdateInput[] | null;
  delete?: MetadataXmpHistoryWhereUniqueInput[] | null;
}

export interface ArchiveNestedPhotoMetadatasCreateInput {
  connect?: PhotoMetadataWhereUniqueInput[] | null;
  create?: PhotoMetadataWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedPhotoMetadatasUpdateInput {
  connect?: PhotoMetadataWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdatePhotoMetadatasUpdateInput[] | null;
  create?: PhotoMetadataWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertPhotoMetadatasUpdateInput[] | null;
  delete?: PhotoMetadataWhereUniqueInput[] | null;
}

export interface ArchiveNestedPhotosCreateInput {
  connect?: PhotoWhereUniqueInput[] | null;
  create?: PhotoWithForcedArchiveCreateInput[] | null;
}

export interface ArchiveNestedPhotosUpdateInput {
  connect?: PhotoWhereUniqueInput[] | null;
  update?: ArchiveNestedUpdatePhotosUpdateInput[] | null;
  create?: PhotoWithForcedArchiveCreateInput[] | null;
  upsert?: ArchiveNestedUpsertPhotosUpdateInput[] | null;
  delete?: PhotoWhereUniqueInput[] | null;
}

export interface ArchiveNestedUpdateArchiveAgenciesUpdateInput {
  where: ArchiveAgencyWithOptionalArchiveWhereUniqueInput;
  data: ArchiveAgencyWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateCategoriesUpdateInput {
  where: CategoryWithOptionalArchiveWhereUniqueInput;
  data: CategoryWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateDeliverySubjectsUpdateInput {
  where: DeliverySubjectWithOptionalArchiveWhereUniqueInput;
  data: DeliverySubjectWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateGcImportSettingUpdateInput {
  where: GcImportSettingWithOptionalArchiveWhereUniqueInput;
  data: GcImportSettingWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateImportQueuesUpdateInput {
  where: ImportQueueWhereUniqueInput;
  data: ImportQueueWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataAccessRightsListUpdateInput {
  where: MetadataAccessRightsWhereUniqueInput;
  data: MetadataAccessRightsWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataAddlModelInfoListUpdateInput {
  where: MetadataAddlModelInfoWhereUniqueInput;
  data: MetadataAddlModelInfoWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataArchiveChildListUpdateInput {
  where: MetadataArchiveChildWhereUniqueInput;
  data: MetadataArchiveChildWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataArchiveParentListUpdateInput {
  where: MetadataArchiveParentWhereUniqueInput;
  data: MetadataArchiveParentWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataAssetStateListUpdateInput {
  where: MetadataAssetStateWhereUniqueInput;
  data: MetadataAssetStateWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataAttributionNameListUpdateInput {
  where: MetadataAttributionNameWhereUniqueInput;
  data: MetadataAttributionNameWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataAttributionUrlListUpdateInput {
  where: MetadataAttributionUrlWhereUniqueInput;
  data: MetadataAttributionUrlWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataAudienceListUpdateInput {
  where: MetadataAudienceWhereUniqueInput;
  data: MetadataAudienceWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataAuthorsPositionListUpdateInput {
  where: MetadataAuthorsPositionWhereUniqueInput;
  data: MetadataAuthorsPositionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataBackupNameListUpdateInput {
  where: MetadataBackupNameWhereUniqueInput;
  data: MetadataBackupNameWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataBitmapGrayscalePictureListUpdateInput {
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
  data: MetadataBitmapGrayscalePictureWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataBrandListUpdateInput {
  where: MetadataBrandWhereUniqueInput;
  data: MetadataBrandWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataBrandPrismaListUpdateInput {
  where: MetadataBrandPrismaWhereUniqueInput;
  data: MetadataBrandPrismaWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataBrandShortNameListUpdateInput {
  where: MetadataBrandShortNameWhereUniqueInput;
  data: MetadataBrandShortNameWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCaptionWriterListUpdateInput {
  where: MetadataCaptionWriterWhereUniqueInput;
  data: MetadataCaptionWriterWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCategoryListUpdateInput {
  where: MetadataCategoryWhereUniqueInput;
  data: MetadataCategoryWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCategoryPrismaListUpdateInput {
  where: MetadataCategoryPrismaWhereUniqueInput;
  data: MetadataCategoryPrismaWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCertificateListUpdateInput {
  where: MetadataCertificateWhereUniqueInput;
  data: MetadataCertificateWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCiAdrCityListUpdateInput {
  where: MetadataCiAdrCityWhereUniqueInput;
  data: MetadataCiAdrCityWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCiAdrCtryListUpdateInput {
  where: MetadataCiAdrCtryWhereUniqueInput;
  data: MetadataCiAdrCtryWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCiAdrExtadrListUpdateInput {
  where: MetadataCiAdrExtadrWhereUniqueInput;
  data: MetadataCiAdrExtadrWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCiAdrPcodeListUpdateInput {
  where: MetadataCiAdrPcodeWhereUniqueInput;
  data: MetadataCiAdrPcodeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCiAdrRegionListUpdateInput {
  where: MetadataCiAdrRegionWhereUniqueInput;
  data: MetadataCiAdrRegionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCiEmailWorkListUpdateInput {
  where: MetadataCiEmailWorkWhereUniqueInput;
  data: MetadataCiEmailWorkWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCiTelWorkListUpdateInput {
  where: MetadataCiTelWorkWhereUniqueInput;
  data: MetadataCiTelWorkWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCiUrlWorkListUpdateInput {
  where: MetadataCiUrlWorkWhereUniqueInput;
  data: MetadataCiUrlWorkWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCityListUpdateInput {
  where: MetadataCityWhereUniqueInput;
  data: MetadataCityWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataClassifyListUpdateInput {
  where: MetadataClassifyWhereUniqueInput;
  data: MetadataClassifyWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCompanyListUpdateInput {
  where: MetadataCompanyWhereUniqueInput;
  data: MetadataCompanyWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCompanyShortNameListUpdateInput {
  where: MetadataCompanyShortNameWhereUniqueInput;
  data: MetadataCompanyShortNameWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataConfidentialityListUpdateInput {
  where: MetadataConfidentialityWhereUniqueInput;
  data: MetadataConfidentialityWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataContainedInListUpdateInput {
  where: MetadataContainedInWhereUniqueInput;
  data: MetadataContainedInWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataContentMediaKindListUpdateInput {
  where: MetadataContentMediaKindWhereUniqueInput;
  data: MetadataContentMediaKindWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataContentValueListUpdateInput {
  where: MetadataContentValueWhereUniqueInput;
  data: MetadataContentValueWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCopyrightLayerListUpdateInput {
  where: MetadataCopyrightLayerWhereUniqueInput;
  data: MetadataCopyrightLayerWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCountryCodeListUpdateInput {
  where: MetadataCountryCodeWhereUniqueInput;
  data: MetadataCountryCodeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCountryListUpdateInput {
  where: MetadataCountryWhereUniqueInput;
  data: MetadataCountryWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCreatedTimeListUpdateInput {
  where: MetadataCreatedTimeWhereUniqueInput;
  data: MetadataCreatedTimeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCreatorListUpdateInput {
  where: MetadataCreatorWhereUniqueInput;
  data: MetadataCreatorWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCreatorToolListUpdateInput {
  where: MetadataCreatorToolWhereUniqueInput;
  data: MetadataCreatorToolWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCreditListUpdateInput {
  where: MetadataCreditWhereUniqueInput;
  data: MetadataCreditWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCustomField14ListUpdateInput {
  where: MetadataCustomField14WhereUniqueInput;
  data: MetadataCustomField14WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCustomField15ListUpdateInput {
  where: MetadataCustomField15WhereUniqueInput;
  data: MetadataCustomField15WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCustomField16ListUpdateInput {
  where: MetadataCustomField16WhereUniqueInput;
  data: MetadataCustomField16WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCustomField17ListUpdateInput {
  where: MetadataCustomField17WhereUniqueInput;
  data: MetadataCustomField17WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCustomField20ListUpdateInput {
  where: MetadataCustomField20WhereUniqueInput;
  data: MetadataCustomField20WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCustomField5ListUpdateInput {
  where: MetadataCustomField5WhereUniqueInput;
  data: MetadataCustomField5WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCustomField6ListUpdateInput {
  where: MetadataCustomField6WhereUniqueInput;
  data: MetadataCustomField6WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCustomField7ListUpdateInput {
  where: MetadataCustomField7WhereUniqueInput;
  data: MetadataCustomField7WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataCustomField9ListUpdateInput {
  where: MetadataCustomField9WhereUniqueInput;
  data: MetadataCustomField9WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDateCreatedListUpdateInput {
  where: MetadataDateCreatedWhereUniqueInput;
  data: MetadataDateCreatedWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDateTimeDigitizedListUpdateInput {
  where: MetadataDateTimeDigitizedWhereUniqueInput;
  data: MetadataDateTimeDigitizedWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDateTimeOriginalListUpdateInput {
  where: MetadataDateTimeOriginalWhereUniqueInput;
  data: MetadataDateTimeOriginalWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryAccountListUpdateInput {
  where: MetadataDeliveryAccountWhereUniqueInput;
  data: MetadataDeliveryAccountWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryCompanyListUpdateInput {
  where: MetadataDeliveryCompanyWhereUniqueInput;
  data: MetadataDeliveryCompanyWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryCopyrightListUpdateInput {
  where: MetadataDeliveryCopyrightWhereUniqueInput;
  data: MetadataDeliveryCopyrightWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryDateFolderListUpdateInput {
  where: MetadataDeliveryDateFolderWhereUniqueInput;
  data: MetadataDeliveryDateFolderWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryDateTimeListUpdateInput {
  where: MetadataDeliveryDateTimeWhereUniqueInput;
  data: MetadataDeliveryDateTimeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryDcCreatorListUpdateInput {
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
  data: MetadataDeliveryDcCreatorWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryDcRightsListUpdateInput {
  where: MetadataDeliveryDcRightsWhereUniqueInput;
  data: MetadataDeliveryDcRightsWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryFileTypeListUpdateInput {
  where: MetadataDeliveryFileTypeWhereUniqueInput;
  data: MetadataDeliveryFileTypeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryFolderListUpdateInput {
  where: MetadataDeliveryFolderWhereUniqueInput;
  data: MetadataDeliveryFolderWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryKindListUpdateInput {
  where: MetadataDeliveryKindWhereUniqueInput;
  data: MetadataDeliveryKindWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryPathListUpdateInput {
  where: MetadataDeliveryPathWhereUniqueInput;
  data: MetadataDeliveryPathWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryPersonShownintheImageListUpdateInput {
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
  data: MetadataDeliveryPersonShownintheImageWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryPhotoshopCreditListUpdateInput {
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
  data: MetadataDeliveryPhotoshopCreditWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryPhotoshopSourceListUpdateInput {
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
  data: MetadataDeliveryPhotoshopSourceWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryServiceListUpdateInput {
  where: MetadataDeliveryServiceWhereUniqueInput;
  data: MetadataDeliveryServiceWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliverySubjectListUpdateInput {
  where: MetadataDeliverySubjectWhereUniqueInput;
  data: MetadataDeliverySubjectWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDeliveryUnderSubjectListUpdateInput {
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
  data: MetadataDeliveryUnderSubjectWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDepartmentListUpdateInput {
  where: MetadataDepartmentWhereUniqueInput;
  data: MetadataDepartmentWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDescriptionListUpdateInput {
  where: MetadataDescriptionWhereUniqueInput;
  data: MetadataDescriptionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDigitalAssetUrlListUpdateInput {
  where: MetadataDigitalAssetUrlWhereUniqueInput;
  data: MetadataDigitalAssetUrlWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDivisionListUpdateInput {
  where: MetadataDivisionWhereUniqueInput;
  data: MetadataDivisionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataDocumentTextListUpdateInput {
  where: MetadataDocumentTextWhereUniqueInput;
  data: MetadataDocumentTextWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataEditStatusListUpdateInput {
  where: MetadataEditStatusWhereUniqueInput;
  data: MetadataEditStatusWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataEditionListUpdateInput {
  where: MetadataEditionWhereUniqueInput;
  data: MetadataEditionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataEditorialVersionListUpdateInput {
  where: MetadataEditorialVersionWhereUniqueInput;
  data: MetadataEditorialVersionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataEnvironnementPhotoListUpdateInput {
  where: MetadataEnvironnementPhotoWhereUniqueInput;
  data: MetadataEnvironnementPhotoWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataEquipmentInstitutionListUpdateInput {
  where: MetadataEquipmentInstitutionWhereUniqueInput;
  data: MetadataEquipmentInstitutionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataEquipmentManufacturerListUpdateInput {
  where: MetadataEquipmentManufacturerWhereUniqueInput;
  data: MetadataEquipmentManufacturerWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataEventListUpdateInput {
  where: MetadataEventWhereUniqueInput;
  data: MetadataEventWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataExifListUpdateInput {
  where: MetadataExifWhereUniqueInput;
  data: MetadataExifWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataFabStorageListUpdateInput {
  where: MetadataFabStorageWhereUniqueInput;
  data: MetadataFabStorageWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataFileCheckForListUpdateInput {
  where: MetadataFileCheckForWhereUniqueInput;
  data: MetadataFileCheckForWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataFileInfoBitsPerPixelListUpdateInput {
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
  data: MetadataFileInfoBitsPerPixelWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataFileInfoFramesListUpdateInput {
  where: MetadataFileInfoFramesWhereUniqueInput;
  data: MetadataFileInfoFramesWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataFileInfoPhotometricInterpretationListUpdateInput {
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
  data: MetadataFileInfoPhotometricInterpretationWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataFileInfoTransferSyntaxListUpdateInput {
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
  data: MetadataFileInfoTransferSyntaxWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataFileStageListUpdateInput {
  where: MetadataFileStageWhereUniqueInput;
  data: MetadataFileStageWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataFileStatusListUpdateInput {
  where: MetadataFileStatusWhereUniqueInput;
  data: MetadataFileStatusWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataFileTypeListUpdateInput {
  where: MetadataFileTypeWhereUniqueInput;
  data: MetadataFileTypeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataFixtureIdentifierListUpdateInput {
  where: MetadataFixtureIdentifierWhereUniqueInput;
  data: MetadataFixtureIdentifierWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataFormatListUpdateInput {
  where: MetadataFormatWhereUniqueInput;
  data: MetadataFormatWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataGpsLatitudeListUpdateInput {
  where: MetadataGpsLatitudeWhereUniqueInput;
  data: MetadataGpsLatitudeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataGpsLongitudeListUpdateInput {
  where: MetadataGpsLongitudeWhereUniqueInput;
  data: MetadataGpsLongitudeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataGpsProcessingMethodListUpdateInput {
  where: MetadataGpsProcessingMethodWhereUniqueInput;
  data: MetadataGpsProcessingMethodWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataHeaderListUpdateInput {
  where: MetadataHeaderWhereUniqueInput;
  data: MetadataHeaderWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataHeaderPrismaListUpdateInput {
  where: MetadataHeaderPrismaWhereUniqueInput;
  data: MetadataHeaderPrismaWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataHeadlineListUpdateInput {
  where: MetadataHeadlineWhereUniqueInput;
  data: MetadataHeadlineWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataHistoryListUpdateInput {
  where: MetadataHistoryWhereUniqueInput;
  data: MetadataHistoryWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataIccProfileListUpdateInput {
  where: MetadataIccProfileWhereUniqueInput;
  data: MetadataIccProfileWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataIdAssignmentListUpdateInput {
  where: MetadataIdAssignmentWhereUniqueInput;
  data: MetadataIdAssignmentWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataIdMediaContentListUpdateInput {
  where: MetadataIdMediaContentWhereUniqueInput;
  data: MetadataIdMediaContentWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataIdPhotoPrismaListUpdateInput {
  where: MetadataIdPhotoPrismaWhereUniqueInput;
  data: MetadataIdPhotoPrismaWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataIdSubjectListUpdateInput {
  where: MetadataIdSubjectWhereUniqueInput;
  data: MetadataIdSubjectWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataImageNotesListUpdateInput {
  where: MetadataImageNotesWhereUniqueInput;
  data: MetadataImageNotesWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataImageUniqueIdListUpdateInput {
  where: MetadataImageUniqueIdWhereUniqueInput;
  data: MetadataImageUniqueIdWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataInstructionsListUpdateInput {
  where: MetadataInstructionsWhereUniqueInput;
  data: MetadataInstructionsWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataIntellectualGenreListUpdateInput {
  where: MetadataIntellectualGenreWhereUniqueInput;
  data: MetadataIntellectualGenreWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataIsoSpeedRatingsListUpdateInput {
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
  data: MetadataIsoSpeedRatingsWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataIssueListUpdateInput {
  where: MetadataIssueWhereUniqueInput;
  data: MetadataIssueWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataJobIdListUpdateInput {
  where: MetadataJobIdWhereUniqueInput;
  data: MetadataJobIdWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataJobProcessingListUpdateInput {
  where: MetadataJobProcessingWhereUniqueInput;
  data: MetadataJobProcessingWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataLayoutPictureEditingListUpdateInput {
  where: MetadataLayoutPictureEditingWhereUniqueInput;
  data: MetadataLayoutPictureEditingWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataLayoutStorageListUpdateInput {
  where: MetadataLayoutStorageWhereUniqueInput;
  data: MetadataLayoutStorageWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataLicenseListUpdateInput {
  where: MetadataLicenseWhereUniqueInput;
  data: MetadataLicenseWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataLinksListUpdateInput {
  where: MetadataLinksWhereUniqueInput;
  data: MetadataLinksWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataLocalCaptionListUpdateInput {
  where: MetadataLocalCaptionWhereUniqueInput;
  data: MetadataLocalCaptionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataLocationListUpdateInput {
  where: MetadataLocationWhereUniqueInput;
  data: MetadataLocationWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataMakeListUpdateInput {
  where: MetadataMakeWhereUniqueInput;
  data: MetadataMakeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataManifestListUpdateInput {
  where: MetadataManifestWhereUniqueInput;
  data: MetadataManifestWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataMarkedListUpdateInput {
  where: MetadataMarkedWhereUniqueInput;
  data: MetadataMarkedWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataMasterDocumentIdListUpdateInput {
  where: MetadataMasterDocumentIdWhereUniqueInput;
  data: MetadataMasterDocumentIdWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataMaxAvailHeightListUpdateInput {
  where: MetadataMaxAvailHeightWhereUniqueInput;
  data: MetadataMaxAvailHeightWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataMaxAvailWidthListUpdateInput {
  where: MetadataMaxAvailWidthWhereUniqueInput;
  data: MetadataMaxAvailWidthWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataMinorModelAgeDisclosureListUpdateInput {
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
  data: MetadataMinorModelAgeDisclosureWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataModelAgeListUpdateInput {
  where: MetadataModelAgeWhereUniqueInput;
  data: MetadataModelAgeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataModelListUpdateInput {
  where: MetadataModelWhereUniqueInput;
  data: MetadataModelWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataModelReleaseIdListUpdateInput {
  where: MetadataModelReleaseIdWhereUniqueInput;
  data: MetadataModelReleaseIdWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataModelReleaseStatusListUpdateInput {
  where: MetadataModelReleaseStatusWhereUniqueInput;
  data: MetadataModelReleaseStatusWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataMorePermissionsListUpdateInput {
  where: MetadataMorePermissionsWhereUniqueInput;
  data: MetadataMorePermissionsWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataObjectCycleListUpdateInput {
  where: MetadataObjectCycleWhereUniqueInput;
  data: MetadataObjectCycleWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataOrganisationInImageNameListUpdateInput {
  where: MetadataOrganisationInImageNameWhereUniqueInput;
  data: MetadataOrganisationInImageNameWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataOriginalColorListUpdateInput {
  where: MetadataOriginalColorWhereUniqueInput;
  data: MetadataOriginalColorWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataOriginalIccProfileListUpdateInput {
  where: MetadataOriginalIccProfileWhereUniqueInput;
  data: MetadataOriginalIccProfileWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataOriginalNameListUpdateInput {
  where: MetadataOriginalNameWhereUniqueInput;
  data: MetadataOriginalNameWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataOriginalWeigthListUpdateInput {
  where: MetadataOriginalWeigthWhereUniqueInput;
  data: MetadataOriginalWeigthWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataOwnerIdListUpdateInput {
  where: MetadataOwnerIdWhereUniqueInput;
  data: MetadataOwnerIdWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataOwnerListUpdateInput {
  where: MetadataOwnerWhereUniqueInput;
  data: MetadataOwnerWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPageListUpdateInput {
  where: MetadataPageWhereUniqueInput;
  data: MetadataPageWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPagePrismaListUpdateInput {
  where: MetadataPagePrismaWhereUniqueInput;
  data: MetadataPagePrismaWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPaintBasedCorrectionsListUpdateInput {
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
  data: MetadataPaintBasedCorrectionsWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPatientDobListUpdateInput {
  where: MetadataPatientDobWhereUniqueInput;
  data: MetadataPatientDobWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPatientIdListUpdateInput {
  where: MetadataPatientIdWhereUniqueInput;
  data: MetadataPatientIdWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPatientNameListUpdateInput {
  where: MetadataPatientNameWhereUniqueInput;
  data: MetadataPatientNameWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPatientSexListUpdateInput {
  where: MetadataPatientSexWhereUniqueInput;
  data: MetadataPatientSexWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPersonInImageListUpdateInput {
  where: MetadataPersonInImageWhereUniqueInput;
  data: MetadataPersonInImageWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPhotoStorageListUpdateInput {
  where: MetadataPhotoStorageWhereUniqueInput;
  data: MetadataPhotoStorageWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPrepressPictureEditingListUpdateInput {
  where: MetadataPrepressPictureEditingWhereUniqueInput;
  data: MetadataPrepressPictureEditingWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPriceLevelListUpdateInput {
  where: MetadataPriceLevelWhereUniqueInput;
  data: MetadataPriceLevelWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPrintingProfileListUpdateInput {
  where: MetadataPrintingProfileWhereUniqueInput;
  data: MetadataPrintingProfileWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPrismaProductionListUpdateInput {
  where: MetadataPrismaProductionWhereUniqueInput;
  data: MetadataPrismaProductionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataProcessHistoryListUpdateInput {
  where: MetadataProcessHistoryWhereUniqueInput;
  data: MetadataProcessHistoryWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataProcessParameterListUpdateInput {
  where: MetadataProcessParameterWhereUniqueInput;
  data: MetadataProcessParameterWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataProcessStatusListUpdateInput {
  where: MetadataProcessStatusWhereUniqueInput;
  data: MetadataProcessStatusWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataProductListUpdateInput {
  where: MetadataProductWhereUniqueInput;
  data: MetadataProductWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataProductShortNameListUpdateInput {
  where: MetadataProductShortNameWhereUniqueInput;
  data: MetadataProductShortNameWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataProductsListUpdateInput {
  where: MetadataProductsWhereUniqueInput;
  data: MetadataProductsWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataProduitsListUpdateInput {
  where: MetadataProduitsWhereUniqueInput;
  data: MetadataProduitsWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataProgramVersionListUpdateInput {
  where: MetadataProgramVersionWhereUniqueInput;
  data: MetadataProgramVersionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPropertyReleaseIdListUpdateInput {
  where: MetadataPropertyReleaseIdWhereUniqueInput;
  data: MetadataPropertyReleaseIdWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPropertyReleaseStatusListUpdateInput {
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
  data: MetadataPropertyReleaseStatusWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPublisherListUpdateInput {
  where: MetadataPublisherWhereUniqueInput;
  data: MetadataPublisherWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPublishingIssueListUpdateInput {
  where: MetadataPublishingIssueWhereUniqueInput;
  data: MetadataPublishingIssueWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataPublishingSubjectListUpdateInput {
  where: MetadataPublishingSubjectWhereUniqueInput;
  data: MetadataPublishingSubjectWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataQualifiedUsePrismaByListUpdateInput {
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
  data: MetadataQualifiedUsePrismaByWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataQualifiedUsePrismaDateListUpdateInput {
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
  data: MetadataQualifiedUsePrismaDateWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataQualifiedUsePrismaDurationListUpdateInput {
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
  data: MetadataQualifiedUsePrismaDurationWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataQualifiedUsePrismaSupportListUpdateInput {
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
  data: MetadataQualifiedUsePrismaSupportWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataRatingListUpdateInput {
  where: MetadataRatingWhereUniqueInput;
  data: MetadataRatingWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataReferenceDateListUpdateInput {
  where: MetadataReferenceDateWhereUniqueInput;
  data: MetadataReferenceDateWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataReferenceNumberListUpdateInput {
  where: MetadataReferenceNumberWhereUniqueInput;
  data: MetadataReferenceNumberWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataReferenceServiceListUpdateInput {
  where: MetadataReferenceServiceWhereUniqueInput;
  data: MetadataReferenceServiceWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataReleaseDateListUpdateInput {
  where: MetadataReleaseDateWhereUniqueInput;
  data: MetadataReleaseDateWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataReleaseTimeListUpdateInput {
  where: MetadataReleaseTimeWhereUniqueInput;
  data: MetadataReleaseTimeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataRequiredPermissionListUpdateInput {
  where: MetadataRequiredPermissionWhereUniqueInput;
  data: MetadataRequiredPermissionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataResolutionKindListUpdateInput {
  where: MetadataResolutionKindWhereUniqueInput;
  data: MetadataResolutionKindWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataRightsListUpdateInput {
  where: MetadataRightsWhereUniqueInput;
  data: MetadataRightsWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataRoyaltyfreeListUpdateInput {
  where: MetadataRoyaltyfreeWhereUniqueInput;
  data: MetadataRoyaltyfreeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataSceneListUpdateInput {
  where: MetadataSceneWhereUniqueInput;
  data: MetadataSceneWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataSectionListUpdateInput {
  where: MetadataSectionWhereUniqueInput;
  data: MetadataSectionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataSentToListUpdateInput {
  where: MetadataSentToWhereUniqueInput;
  data: MetadataSentToWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataSerialNumberListUpdateInput {
  where: MetadataSerialNumberWhereUniqueInput;
  data: MetadataSerialNumberWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataSeriesDateTimeListUpdateInput {
  where: MetadataSeriesDateTimeWhereUniqueInput;
  data: MetadataSeriesDateTimeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataSeriesDescriptionListUpdateInput {
  where: MetadataSeriesDescriptionWhereUniqueInput;
  data: MetadataSeriesDescriptionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataSeriesModalityListUpdateInput {
  where: MetadataSeriesModalityWhereUniqueInput;
  data: MetadataSeriesModalityWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataSeriesNumberListUpdateInput {
  where: MetadataSeriesNumberWhereUniqueInput;
  data: MetadataSeriesNumberWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataShortUniqueIdListUpdateInput {
  where: MetadataShortUniqueIdWhereUniqueInput;
  data: MetadataShortUniqueIdWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataSourceListUpdateInput {
  where: MetadataSourceWhereUniqueInput;
  data: MetadataSourceWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataStateListUpdateInput {
  where: MetadataStateWhereUniqueInput;
  data: MetadataStateWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataStudyDateTimeListUpdateInput {
  where: MetadataStudyDateTimeWhereUniqueInput;
  data: MetadataStudyDateTimeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataStudyDescriptionListUpdateInput {
  where: MetadataStudyDescriptionWhereUniqueInput;
  data: MetadataStudyDescriptionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataStudyIdListUpdateInput {
  where: MetadataStudyIdWhereUniqueInput;
  data: MetadataStudyIdWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataStudyPhysicianListUpdateInput {
  where: MetadataStudyPhysicianWhereUniqueInput;
  data: MetadataStudyPhysicianWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataSubjectCodeListUpdateInput {
  where: MetadataSubjectCodeWhereUniqueInput;
  data: MetadataSubjectCodeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataSubjectListUpdateInput {
  where: MetadataSubjectWhereUniqueInput;
  data: MetadataSubjectWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataSubjectPrismaListUpdateInput {
  where: MetadataSubjectPrismaWhereUniqueInput;
  data: MetadataSubjectPrismaWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataSupplementalCategoriesListUpdateInput {
  where: MetadataSupplementalCategoriesWhereUniqueInput;
  data: MetadataSupplementalCategoriesWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataTargetVersionListUpdateInput {
  where: MetadataTargetVersionWhereUniqueInput;
  data: MetadataTargetVersionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataTitleListUpdateInput {
  where: MetadataTitleWhereUniqueInput;
  data: MetadataTitleWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataToneCurveListUpdateInput {
  where: MetadataToneCurveWhereUniqueInput;
  data: MetadataToneCurveWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataTransferredByEmailListUpdateInput {
  where: MetadataTransferredByEmailWhereUniqueInput;
  data: MetadataTransferredByEmailWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataTransferredByFullNameListUpdateInput {
  where: MetadataTransferredByFullNameWhereUniqueInput;
  data: MetadataTransferredByFullNameWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataTransferredByListUpdateInput {
  where: MetadataTransferredByWhereUniqueInput;
  data: MetadataTransferredByWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataTransmissionReferenceListUpdateInput {
  where: MetadataTransmissionReferenceWhereUniqueInput;
  data: MetadataTransmissionReferenceWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataTypeListUpdateInput {
  where: MetadataTypeWhereUniqueInput;
  data: MetadataTypeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUnderUnderSubjectListUpdateInput {
  where: MetadataUnderUnderSubjectWhereUniqueInput;
  data: MetadataUnderUnderSubjectWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUndersubjectListUpdateInput {
  where: MetadataUndersubjectWhereUniqueInput;
  data: MetadataUndersubjectWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUniqueIdListUpdateInput {
  where: MetadataUniqueIdWhereUniqueInput;
  data: MetadataUniqueIdWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUnitListUpdateInput {
  where: MetadataUnitWhereUniqueInput;
  data: MetadataUnitWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUnitShortNameListUpdateInput {
  where: MetadataUnitShortNameWhereUniqueInput;
  data: MetadataUnitShortNameWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUploadTimeListUpdateInput {
  where: MetadataUploadTimeWhereUniqueInput;
  data: MetadataUploadTimeWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUploadedByFullNameListUpdateInput {
  where: MetadataUploadedByFullNameWhereUniqueInput;
  data: MetadataUploadedByFullNameWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUploadedByListUpdateInput {
  where: MetadataUploadedByWhereUniqueInput;
  data: MetadataUploadedByWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUrgencyListUpdateInput {
  where: MetadataUrgencyWhereUniqueInput;
  data: MetadataUrgencyWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUsageTermsListUpdateInput {
  where: MetadataUsageTermsWhereUniqueInput;
  data: MetadataUsageTermsWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUserCommentListUpdateInput {
  where: MetadataUserCommentWhereUniqueInput;
  data: MetadataUserCommentWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUserDefined195ListUpdateInput {
  where: MetadataUserDefined195WhereUniqueInput;
  data: MetadataUserDefined195WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUserDefined237ListUpdateInput {
  where: MetadataUserDefined237WhereUniqueInput;
  data: MetadataUserDefined237WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUserDefined238ListUpdateInput {
  where: MetadataUserDefined238WhereUniqueInput;
  data: MetadataUserDefined238WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUserDefined239ListUpdateInput {
  where: MetadataUserDefined239WhereUniqueInput;
  data: MetadataUserDefined239WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUserDefined242ListUpdateInput {
  where: MetadataUserDefined242WhereUniqueInput;
  data: MetadataUserDefined242WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataUserDefined62ListUpdateInput {
  where: MetadataUserDefined62WhereUniqueInput;
  data: MetadataUserDefined62WithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataValidListUpdateInput {
  where: MetadataValidWhereUniqueInput;
  data: MetadataValidWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataVersionListUpdateInput {
  where: MetadataVersionWhereUniqueInput;
  data: MetadataVersionWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataWebStatementListUpdateInput {
  where: MetadataWebStatementWhereUniqueInput;
  data: MetadataWebStatementWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataWorkflowKindListUpdateInput {
  where: MetadataWorkflowKindWhereUniqueInput;
  data: MetadataWorkflowKindWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataXmpFileStampsListUpdateInput {
  where: MetadataXmpFileStampsWhereUniqueInput;
  data: MetadataXmpFileStampsWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdateMetadataXmpHistoryListUpdateInput {
  where: MetadataXmpHistoryWhereUniqueInput;
  data: MetadataXmpHistoryWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdatePhotoMetadatasUpdateInput {
  where: PhotoMetadataWhereUniqueInput;
  data: PhotoMetadataWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpdatePhotosUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoWithForcedArchiveUpdateInput;
}

export interface ArchiveNestedUpsertArchiveAgenciesUpdateInput {
  where: ArchiveAgencyWithOptionalArchiveWhereUniqueInput;
  update: ArchiveAgencyWithForcedArchiveUpdateInput;
  create: ArchiveAgencyWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertCategoriesUpdateInput {
  where: CategoryWithOptionalArchiveWhereUniqueInput;
  update: CategoryWithForcedArchiveUpdateInput;
  create: CategoryWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertDeliverySubjectsUpdateInput {
  where: DeliverySubjectWithOptionalArchiveWhereUniqueInput;
  update: DeliverySubjectWithForcedArchiveUpdateInput;
  create: DeliverySubjectWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertGcImportSettingUpdateInput {
  where: GcImportSettingWithOptionalArchiveWhereUniqueInput;
  update: GcImportSettingWithForcedArchiveUpdateInput;
  create: GcImportSettingWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertImportQueuesUpdateInput {
  where: ImportQueueWhereUniqueInput;
  update: ImportQueueWithForcedArchiveUpdateInput;
  create: ImportQueueWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataAccessRightsListUpdateInput {
  where: MetadataAccessRightsWhereUniqueInput;
  update: MetadataAccessRightsWithForcedArchiveUpdateInput;
  create: MetadataAccessRightsWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataAddlModelInfoListUpdateInput {
  where: MetadataAddlModelInfoWhereUniqueInput;
  update: MetadataAddlModelInfoWithForcedArchiveUpdateInput;
  create: MetadataAddlModelInfoWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataArchiveChildListUpdateInput {
  where: MetadataArchiveChildWhereUniqueInput;
  update: MetadataArchiveChildWithForcedArchiveUpdateInput;
  create: MetadataArchiveChildWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataArchiveParentListUpdateInput {
  where: MetadataArchiveParentWhereUniqueInput;
  update: MetadataArchiveParentWithForcedArchiveUpdateInput;
  create: MetadataArchiveParentWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataAssetStateListUpdateInput {
  where: MetadataAssetStateWhereUniqueInput;
  update: MetadataAssetStateWithForcedArchiveUpdateInput;
  create: MetadataAssetStateWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataAttributionNameListUpdateInput {
  where: MetadataAttributionNameWhereUniqueInput;
  update: MetadataAttributionNameWithForcedArchiveUpdateInput;
  create: MetadataAttributionNameWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataAttributionUrlListUpdateInput {
  where: MetadataAttributionUrlWhereUniqueInput;
  update: MetadataAttributionUrlWithForcedArchiveUpdateInput;
  create: MetadataAttributionUrlWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataAudienceListUpdateInput {
  where: MetadataAudienceWhereUniqueInput;
  update: MetadataAudienceWithForcedArchiveUpdateInput;
  create: MetadataAudienceWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataAuthorsPositionListUpdateInput {
  where: MetadataAuthorsPositionWhereUniqueInput;
  update: MetadataAuthorsPositionWithForcedArchiveUpdateInput;
  create: MetadataAuthorsPositionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataBackupNameListUpdateInput {
  where: MetadataBackupNameWhereUniqueInput;
  update: MetadataBackupNameWithForcedArchiveUpdateInput;
  create: MetadataBackupNameWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataBitmapGrayscalePictureListUpdateInput {
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
  update: MetadataBitmapGrayscalePictureWithForcedArchiveUpdateInput;
  create: MetadataBitmapGrayscalePictureWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataBrandListUpdateInput {
  where: MetadataBrandWhereUniqueInput;
  update: MetadataBrandWithForcedArchiveUpdateInput;
  create: MetadataBrandWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataBrandPrismaListUpdateInput {
  where: MetadataBrandPrismaWhereUniqueInput;
  update: MetadataBrandPrismaWithForcedArchiveUpdateInput;
  create: MetadataBrandPrismaWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataBrandShortNameListUpdateInput {
  where: MetadataBrandShortNameWhereUniqueInput;
  update: MetadataBrandShortNameWithForcedArchiveUpdateInput;
  create: MetadataBrandShortNameWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCaptionWriterListUpdateInput {
  where: MetadataCaptionWriterWhereUniqueInput;
  update: MetadataCaptionWriterWithForcedArchiveUpdateInput;
  create: MetadataCaptionWriterWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCategoryListUpdateInput {
  where: MetadataCategoryWhereUniqueInput;
  update: MetadataCategoryWithForcedArchiveUpdateInput;
  create: MetadataCategoryWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCategoryPrismaListUpdateInput {
  where: MetadataCategoryPrismaWhereUniqueInput;
  update: MetadataCategoryPrismaWithForcedArchiveUpdateInput;
  create: MetadataCategoryPrismaWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCertificateListUpdateInput {
  where: MetadataCertificateWhereUniqueInput;
  update: MetadataCertificateWithForcedArchiveUpdateInput;
  create: MetadataCertificateWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCiAdrCityListUpdateInput {
  where: MetadataCiAdrCityWhereUniqueInput;
  update: MetadataCiAdrCityWithForcedArchiveUpdateInput;
  create: MetadataCiAdrCityWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCiAdrCtryListUpdateInput {
  where: MetadataCiAdrCtryWhereUniqueInput;
  update: MetadataCiAdrCtryWithForcedArchiveUpdateInput;
  create: MetadataCiAdrCtryWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCiAdrExtadrListUpdateInput {
  where: MetadataCiAdrExtadrWhereUniqueInput;
  update: MetadataCiAdrExtadrWithForcedArchiveUpdateInput;
  create: MetadataCiAdrExtadrWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCiAdrPcodeListUpdateInput {
  where: MetadataCiAdrPcodeWhereUniqueInput;
  update: MetadataCiAdrPcodeWithForcedArchiveUpdateInput;
  create: MetadataCiAdrPcodeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCiAdrRegionListUpdateInput {
  where: MetadataCiAdrRegionWhereUniqueInput;
  update: MetadataCiAdrRegionWithForcedArchiveUpdateInput;
  create: MetadataCiAdrRegionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCiEmailWorkListUpdateInput {
  where: MetadataCiEmailWorkWhereUniqueInput;
  update: MetadataCiEmailWorkWithForcedArchiveUpdateInput;
  create: MetadataCiEmailWorkWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCiTelWorkListUpdateInput {
  where: MetadataCiTelWorkWhereUniqueInput;
  update: MetadataCiTelWorkWithForcedArchiveUpdateInput;
  create: MetadataCiTelWorkWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCiUrlWorkListUpdateInput {
  where: MetadataCiUrlWorkWhereUniqueInput;
  update: MetadataCiUrlWorkWithForcedArchiveUpdateInput;
  create: MetadataCiUrlWorkWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCityListUpdateInput {
  where: MetadataCityWhereUniqueInput;
  update: MetadataCityWithForcedArchiveUpdateInput;
  create: MetadataCityWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataClassifyListUpdateInput {
  where: MetadataClassifyWhereUniqueInput;
  update: MetadataClassifyWithForcedArchiveUpdateInput;
  create: MetadataClassifyWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCompanyListUpdateInput {
  where: MetadataCompanyWhereUniqueInput;
  update: MetadataCompanyWithForcedArchiveUpdateInput;
  create: MetadataCompanyWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCompanyShortNameListUpdateInput {
  where: MetadataCompanyShortNameWhereUniqueInput;
  update: MetadataCompanyShortNameWithForcedArchiveUpdateInput;
  create: MetadataCompanyShortNameWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataConfidentialityListUpdateInput {
  where: MetadataConfidentialityWhereUniqueInput;
  update: MetadataConfidentialityWithForcedArchiveUpdateInput;
  create: MetadataConfidentialityWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataContainedInListUpdateInput {
  where: MetadataContainedInWhereUniqueInput;
  update: MetadataContainedInWithForcedArchiveUpdateInput;
  create: MetadataContainedInWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataContentMediaKindListUpdateInput {
  where: MetadataContentMediaKindWhereUniqueInput;
  update: MetadataContentMediaKindWithForcedArchiveUpdateInput;
  create: MetadataContentMediaKindWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataContentValueListUpdateInput {
  where: MetadataContentValueWhereUniqueInput;
  update: MetadataContentValueWithForcedArchiveUpdateInput;
  create: MetadataContentValueWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCopyrightLayerListUpdateInput {
  where: MetadataCopyrightLayerWhereUniqueInput;
  update: MetadataCopyrightLayerWithForcedArchiveUpdateInput;
  create: MetadataCopyrightLayerWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCountryCodeListUpdateInput {
  where: MetadataCountryCodeWhereUniqueInput;
  update: MetadataCountryCodeWithForcedArchiveUpdateInput;
  create: MetadataCountryCodeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCountryListUpdateInput {
  where: MetadataCountryWhereUniqueInput;
  update: MetadataCountryWithForcedArchiveUpdateInput;
  create: MetadataCountryWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCreatedTimeListUpdateInput {
  where: MetadataCreatedTimeWhereUniqueInput;
  update: MetadataCreatedTimeWithForcedArchiveUpdateInput;
  create: MetadataCreatedTimeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCreatorListUpdateInput {
  where: MetadataCreatorWhereUniqueInput;
  update: MetadataCreatorWithForcedArchiveUpdateInput;
  create: MetadataCreatorWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCreatorToolListUpdateInput {
  where: MetadataCreatorToolWhereUniqueInput;
  update: MetadataCreatorToolWithForcedArchiveUpdateInput;
  create: MetadataCreatorToolWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCreditListUpdateInput {
  where: MetadataCreditWhereUniqueInput;
  update: MetadataCreditWithForcedArchiveUpdateInput;
  create: MetadataCreditWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCustomField14ListUpdateInput {
  where: MetadataCustomField14WhereUniqueInput;
  update: MetadataCustomField14WithForcedArchiveUpdateInput;
  create: MetadataCustomField14WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCustomField15ListUpdateInput {
  where: MetadataCustomField15WhereUniqueInput;
  update: MetadataCustomField15WithForcedArchiveUpdateInput;
  create: MetadataCustomField15WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCustomField16ListUpdateInput {
  where: MetadataCustomField16WhereUniqueInput;
  update: MetadataCustomField16WithForcedArchiveUpdateInput;
  create: MetadataCustomField16WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCustomField17ListUpdateInput {
  where: MetadataCustomField17WhereUniqueInput;
  update: MetadataCustomField17WithForcedArchiveUpdateInput;
  create: MetadataCustomField17WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCustomField20ListUpdateInput {
  where: MetadataCustomField20WhereUniqueInput;
  update: MetadataCustomField20WithForcedArchiveUpdateInput;
  create: MetadataCustomField20WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCustomField5ListUpdateInput {
  where: MetadataCustomField5WhereUniqueInput;
  update: MetadataCustomField5WithForcedArchiveUpdateInput;
  create: MetadataCustomField5WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCustomField6ListUpdateInput {
  where: MetadataCustomField6WhereUniqueInput;
  update: MetadataCustomField6WithForcedArchiveUpdateInput;
  create: MetadataCustomField6WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCustomField7ListUpdateInput {
  where: MetadataCustomField7WhereUniqueInput;
  update: MetadataCustomField7WithForcedArchiveUpdateInput;
  create: MetadataCustomField7WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataCustomField9ListUpdateInput {
  where: MetadataCustomField9WhereUniqueInput;
  update: MetadataCustomField9WithForcedArchiveUpdateInput;
  create: MetadataCustomField9WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDateCreatedListUpdateInput {
  where: MetadataDateCreatedWhereUniqueInput;
  update: MetadataDateCreatedWithForcedArchiveUpdateInput;
  create: MetadataDateCreatedWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDateTimeDigitizedListUpdateInput {
  where: MetadataDateTimeDigitizedWhereUniqueInput;
  update: MetadataDateTimeDigitizedWithForcedArchiveUpdateInput;
  create: MetadataDateTimeDigitizedWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDateTimeOriginalListUpdateInput {
  where: MetadataDateTimeOriginalWhereUniqueInput;
  update: MetadataDateTimeOriginalWithForcedArchiveUpdateInput;
  create: MetadataDateTimeOriginalWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryAccountListUpdateInput {
  where: MetadataDeliveryAccountWhereUniqueInput;
  update: MetadataDeliveryAccountWithForcedArchiveUpdateInput;
  create: MetadataDeliveryAccountWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryCompanyListUpdateInput {
  where: MetadataDeliveryCompanyWhereUniqueInput;
  update: MetadataDeliveryCompanyWithForcedArchiveUpdateInput;
  create: MetadataDeliveryCompanyWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryCopyrightListUpdateInput {
  where: MetadataDeliveryCopyrightWhereUniqueInput;
  update: MetadataDeliveryCopyrightWithForcedArchiveUpdateInput;
  create: MetadataDeliveryCopyrightWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryDateFolderListUpdateInput {
  where: MetadataDeliveryDateFolderWhereUniqueInput;
  update: MetadataDeliveryDateFolderWithForcedArchiveUpdateInput;
  create: MetadataDeliveryDateFolderWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryDateTimeListUpdateInput {
  where: MetadataDeliveryDateTimeWhereUniqueInput;
  update: MetadataDeliveryDateTimeWithForcedArchiveUpdateInput;
  create: MetadataDeliveryDateTimeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryDcCreatorListUpdateInput {
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
  update: MetadataDeliveryDcCreatorWithForcedArchiveUpdateInput;
  create: MetadataDeliveryDcCreatorWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryDcRightsListUpdateInput {
  where: MetadataDeliveryDcRightsWhereUniqueInput;
  update: MetadataDeliveryDcRightsWithForcedArchiveUpdateInput;
  create: MetadataDeliveryDcRightsWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryFileTypeListUpdateInput {
  where: MetadataDeliveryFileTypeWhereUniqueInput;
  update: MetadataDeliveryFileTypeWithForcedArchiveUpdateInput;
  create: MetadataDeliveryFileTypeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryFolderListUpdateInput {
  where: MetadataDeliveryFolderWhereUniqueInput;
  update: MetadataDeliveryFolderWithForcedArchiveUpdateInput;
  create: MetadataDeliveryFolderWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryKindListUpdateInput {
  where: MetadataDeliveryKindWhereUniqueInput;
  update: MetadataDeliveryKindWithForcedArchiveUpdateInput;
  create: MetadataDeliveryKindWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryPathListUpdateInput {
  where: MetadataDeliveryPathWhereUniqueInput;
  update: MetadataDeliveryPathWithForcedArchiveUpdateInput;
  create: MetadataDeliveryPathWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryPersonShownintheImageListUpdateInput {
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
  update: MetadataDeliveryPersonShownintheImageWithForcedArchiveUpdateInput;
  create: MetadataDeliveryPersonShownintheImageWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryPhotoshopCreditListUpdateInput {
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
  update: MetadataDeliveryPhotoshopCreditWithForcedArchiveUpdateInput;
  create: MetadataDeliveryPhotoshopCreditWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryPhotoshopSourceListUpdateInput {
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
  update: MetadataDeliveryPhotoshopSourceWithForcedArchiveUpdateInput;
  create: MetadataDeliveryPhotoshopSourceWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryServiceListUpdateInput {
  where: MetadataDeliveryServiceWhereUniqueInput;
  update: MetadataDeliveryServiceWithForcedArchiveUpdateInput;
  create: MetadataDeliveryServiceWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliverySubjectListUpdateInput {
  where: MetadataDeliverySubjectWhereUniqueInput;
  update: MetadataDeliverySubjectWithForcedArchiveUpdateInput;
  create: MetadataDeliverySubjectWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDeliveryUnderSubjectListUpdateInput {
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
  update: MetadataDeliveryUnderSubjectWithForcedArchiveUpdateInput;
  create: MetadataDeliveryUnderSubjectWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDepartmentListUpdateInput {
  where: MetadataDepartmentWhereUniqueInput;
  update: MetadataDepartmentWithForcedArchiveUpdateInput;
  create: MetadataDepartmentWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDescriptionListUpdateInput {
  where: MetadataDescriptionWhereUniqueInput;
  update: MetadataDescriptionWithForcedArchiveUpdateInput;
  create: MetadataDescriptionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDigitalAssetUrlListUpdateInput {
  where: MetadataDigitalAssetUrlWhereUniqueInput;
  update: MetadataDigitalAssetUrlWithForcedArchiveUpdateInput;
  create: MetadataDigitalAssetUrlWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDivisionListUpdateInput {
  where: MetadataDivisionWhereUniqueInput;
  update: MetadataDivisionWithForcedArchiveUpdateInput;
  create: MetadataDivisionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataDocumentTextListUpdateInput {
  where: MetadataDocumentTextWhereUniqueInput;
  update: MetadataDocumentTextWithForcedArchiveUpdateInput;
  create: MetadataDocumentTextWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataEditStatusListUpdateInput {
  where: MetadataEditStatusWhereUniqueInput;
  update: MetadataEditStatusWithForcedArchiveUpdateInput;
  create: MetadataEditStatusWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataEditionListUpdateInput {
  where: MetadataEditionWhereUniqueInput;
  update: MetadataEditionWithForcedArchiveUpdateInput;
  create: MetadataEditionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataEditorialVersionListUpdateInput {
  where: MetadataEditorialVersionWhereUniqueInput;
  update: MetadataEditorialVersionWithForcedArchiveUpdateInput;
  create: MetadataEditorialVersionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataEnvironnementPhotoListUpdateInput {
  where: MetadataEnvironnementPhotoWhereUniqueInput;
  update: MetadataEnvironnementPhotoWithForcedArchiveUpdateInput;
  create: MetadataEnvironnementPhotoWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataEquipmentInstitutionListUpdateInput {
  where: MetadataEquipmentInstitutionWhereUniqueInput;
  update: MetadataEquipmentInstitutionWithForcedArchiveUpdateInput;
  create: MetadataEquipmentInstitutionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataEquipmentManufacturerListUpdateInput {
  where: MetadataEquipmentManufacturerWhereUniqueInput;
  update: MetadataEquipmentManufacturerWithForcedArchiveUpdateInput;
  create: MetadataEquipmentManufacturerWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataEventListUpdateInput {
  where: MetadataEventWhereUniqueInput;
  update: MetadataEventWithForcedArchiveUpdateInput;
  create: MetadataEventWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataExifListUpdateInput {
  where: MetadataExifWhereUniqueInput;
  update: MetadataExifWithForcedArchiveUpdateInput;
  create: MetadataExifWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataFabStorageListUpdateInput {
  where: MetadataFabStorageWhereUniqueInput;
  update: MetadataFabStorageWithForcedArchiveUpdateInput;
  create: MetadataFabStorageWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataFileCheckForListUpdateInput {
  where: MetadataFileCheckForWhereUniqueInput;
  update: MetadataFileCheckForWithForcedArchiveUpdateInput;
  create: MetadataFileCheckForWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataFileInfoBitsPerPixelListUpdateInput {
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
  update: MetadataFileInfoBitsPerPixelWithForcedArchiveUpdateInput;
  create: MetadataFileInfoBitsPerPixelWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataFileInfoFramesListUpdateInput {
  where: MetadataFileInfoFramesWhereUniqueInput;
  update: MetadataFileInfoFramesWithForcedArchiveUpdateInput;
  create: MetadataFileInfoFramesWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataFileInfoPhotometricInterpretationListUpdateInput {
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
  update: MetadataFileInfoPhotometricInterpretationWithForcedArchiveUpdateInput;
  create: MetadataFileInfoPhotometricInterpretationWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataFileInfoTransferSyntaxListUpdateInput {
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
  update: MetadataFileInfoTransferSyntaxWithForcedArchiveUpdateInput;
  create: MetadataFileInfoTransferSyntaxWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataFileStageListUpdateInput {
  where: MetadataFileStageWhereUniqueInput;
  update: MetadataFileStageWithForcedArchiveUpdateInput;
  create: MetadataFileStageWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataFileStatusListUpdateInput {
  where: MetadataFileStatusWhereUniqueInput;
  update: MetadataFileStatusWithForcedArchiveUpdateInput;
  create: MetadataFileStatusWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataFileTypeListUpdateInput {
  where: MetadataFileTypeWhereUniqueInput;
  update: MetadataFileTypeWithForcedArchiveUpdateInput;
  create: MetadataFileTypeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataFixtureIdentifierListUpdateInput {
  where: MetadataFixtureIdentifierWhereUniqueInput;
  update: MetadataFixtureIdentifierWithForcedArchiveUpdateInput;
  create: MetadataFixtureIdentifierWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataFormatListUpdateInput {
  where: MetadataFormatWhereUniqueInput;
  update: MetadataFormatWithForcedArchiveUpdateInput;
  create: MetadataFormatWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataGpsLatitudeListUpdateInput {
  where: MetadataGpsLatitudeWhereUniqueInput;
  update: MetadataGpsLatitudeWithForcedArchiveUpdateInput;
  create: MetadataGpsLatitudeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataGpsLongitudeListUpdateInput {
  where: MetadataGpsLongitudeWhereUniqueInput;
  update: MetadataGpsLongitudeWithForcedArchiveUpdateInput;
  create: MetadataGpsLongitudeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataGpsProcessingMethodListUpdateInput {
  where: MetadataGpsProcessingMethodWhereUniqueInput;
  update: MetadataGpsProcessingMethodWithForcedArchiveUpdateInput;
  create: MetadataGpsProcessingMethodWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataHeaderListUpdateInput {
  where: MetadataHeaderWhereUniqueInput;
  update: MetadataHeaderWithForcedArchiveUpdateInput;
  create: MetadataHeaderWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataHeaderPrismaListUpdateInput {
  where: MetadataHeaderPrismaWhereUniqueInput;
  update: MetadataHeaderPrismaWithForcedArchiveUpdateInput;
  create: MetadataHeaderPrismaWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataHeadlineListUpdateInput {
  where: MetadataHeadlineWhereUniqueInput;
  update: MetadataHeadlineWithForcedArchiveUpdateInput;
  create: MetadataHeadlineWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataHistoryListUpdateInput {
  where: MetadataHistoryWhereUniqueInput;
  update: MetadataHistoryWithForcedArchiveUpdateInput;
  create: MetadataHistoryWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataIccProfileListUpdateInput {
  where: MetadataIccProfileWhereUniqueInput;
  update: MetadataIccProfileWithForcedArchiveUpdateInput;
  create: MetadataIccProfileWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataIdAssignmentListUpdateInput {
  where: MetadataIdAssignmentWhereUniqueInput;
  update: MetadataIdAssignmentWithForcedArchiveUpdateInput;
  create: MetadataIdAssignmentWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataIdMediaContentListUpdateInput {
  where: MetadataIdMediaContentWhereUniqueInput;
  update: MetadataIdMediaContentWithForcedArchiveUpdateInput;
  create: MetadataIdMediaContentWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataIdPhotoPrismaListUpdateInput {
  where: MetadataIdPhotoPrismaWhereUniqueInput;
  update: MetadataIdPhotoPrismaWithForcedArchiveUpdateInput;
  create: MetadataIdPhotoPrismaWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataIdSubjectListUpdateInput {
  where: MetadataIdSubjectWhereUniqueInput;
  update: MetadataIdSubjectWithForcedArchiveUpdateInput;
  create: MetadataIdSubjectWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataImageNotesListUpdateInput {
  where: MetadataImageNotesWhereUniqueInput;
  update: MetadataImageNotesWithForcedArchiveUpdateInput;
  create: MetadataImageNotesWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataImageUniqueIdListUpdateInput {
  where: MetadataImageUniqueIdWhereUniqueInput;
  update: MetadataImageUniqueIdWithForcedArchiveUpdateInput;
  create: MetadataImageUniqueIdWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataInstructionsListUpdateInput {
  where: MetadataInstructionsWhereUniqueInput;
  update: MetadataInstructionsWithForcedArchiveUpdateInput;
  create: MetadataInstructionsWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataIntellectualGenreListUpdateInput {
  where: MetadataIntellectualGenreWhereUniqueInput;
  update: MetadataIntellectualGenreWithForcedArchiveUpdateInput;
  create: MetadataIntellectualGenreWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataIsoSpeedRatingsListUpdateInput {
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
  update: MetadataIsoSpeedRatingsWithForcedArchiveUpdateInput;
  create: MetadataIsoSpeedRatingsWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataIssueListUpdateInput {
  where: MetadataIssueWhereUniqueInput;
  update: MetadataIssueWithForcedArchiveUpdateInput;
  create: MetadataIssueWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataJobIdListUpdateInput {
  where: MetadataJobIdWhereUniqueInput;
  update: MetadataJobIdWithForcedArchiveUpdateInput;
  create: MetadataJobIdWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataJobProcessingListUpdateInput {
  where: MetadataJobProcessingWhereUniqueInput;
  update: MetadataJobProcessingWithForcedArchiveUpdateInput;
  create: MetadataJobProcessingWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataLayoutPictureEditingListUpdateInput {
  where: MetadataLayoutPictureEditingWhereUniqueInput;
  update: MetadataLayoutPictureEditingWithForcedArchiveUpdateInput;
  create: MetadataLayoutPictureEditingWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataLayoutStorageListUpdateInput {
  where: MetadataLayoutStorageWhereUniqueInput;
  update: MetadataLayoutStorageWithForcedArchiveUpdateInput;
  create: MetadataLayoutStorageWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataLicenseListUpdateInput {
  where: MetadataLicenseWhereUniqueInput;
  update: MetadataLicenseWithForcedArchiveUpdateInput;
  create: MetadataLicenseWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataLinksListUpdateInput {
  where: MetadataLinksWhereUniqueInput;
  update: MetadataLinksWithForcedArchiveUpdateInput;
  create: MetadataLinksWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataLocalCaptionListUpdateInput {
  where: MetadataLocalCaptionWhereUniqueInput;
  update: MetadataLocalCaptionWithForcedArchiveUpdateInput;
  create: MetadataLocalCaptionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataLocationListUpdateInput {
  where: MetadataLocationWhereUniqueInput;
  update: MetadataLocationWithForcedArchiveUpdateInput;
  create: MetadataLocationWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataMakeListUpdateInput {
  where: MetadataMakeWhereUniqueInput;
  update: MetadataMakeWithForcedArchiveUpdateInput;
  create: MetadataMakeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataManifestListUpdateInput {
  where: MetadataManifestWhereUniqueInput;
  update: MetadataManifestWithForcedArchiveUpdateInput;
  create: MetadataManifestWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataMarkedListUpdateInput {
  where: MetadataMarkedWhereUniqueInput;
  update: MetadataMarkedWithForcedArchiveUpdateInput;
  create: MetadataMarkedWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataMasterDocumentIdListUpdateInput {
  where: MetadataMasterDocumentIdWhereUniqueInput;
  update: MetadataMasterDocumentIdWithForcedArchiveUpdateInput;
  create: MetadataMasterDocumentIdWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataMaxAvailHeightListUpdateInput {
  where: MetadataMaxAvailHeightWhereUniqueInput;
  update: MetadataMaxAvailHeightWithForcedArchiveUpdateInput;
  create: MetadataMaxAvailHeightWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataMaxAvailWidthListUpdateInput {
  where: MetadataMaxAvailWidthWhereUniqueInput;
  update: MetadataMaxAvailWidthWithForcedArchiveUpdateInput;
  create: MetadataMaxAvailWidthWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataMinorModelAgeDisclosureListUpdateInput {
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
  update: MetadataMinorModelAgeDisclosureWithForcedArchiveUpdateInput;
  create: MetadataMinorModelAgeDisclosureWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataModelAgeListUpdateInput {
  where: MetadataModelAgeWhereUniqueInput;
  update: MetadataModelAgeWithForcedArchiveUpdateInput;
  create: MetadataModelAgeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataModelListUpdateInput {
  where: MetadataModelWhereUniqueInput;
  update: MetadataModelWithForcedArchiveUpdateInput;
  create: MetadataModelWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataModelReleaseIdListUpdateInput {
  where: MetadataModelReleaseIdWhereUniqueInput;
  update: MetadataModelReleaseIdWithForcedArchiveUpdateInput;
  create: MetadataModelReleaseIdWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataModelReleaseStatusListUpdateInput {
  where: MetadataModelReleaseStatusWhereUniqueInput;
  update: MetadataModelReleaseStatusWithForcedArchiveUpdateInput;
  create: MetadataModelReleaseStatusWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataMorePermissionsListUpdateInput {
  where: MetadataMorePermissionsWhereUniqueInput;
  update: MetadataMorePermissionsWithForcedArchiveUpdateInput;
  create: MetadataMorePermissionsWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataObjectCycleListUpdateInput {
  where: MetadataObjectCycleWhereUniqueInput;
  update: MetadataObjectCycleWithForcedArchiveUpdateInput;
  create: MetadataObjectCycleWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataOrganisationInImageNameListUpdateInput {
  where: MetadataOrganisationInImageNameWhereUniqueInput;
  update: MetadataOrganisationInImageNameWithForcedArchiveUpdateInput;
  create: MetadataOrganisationInImageNameWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataOriginalColorListUpdateInput {
  where: MetadataOriginalColorWhereUniqueInput;
  update: MetadataOriginalColorWithForcedArchiveUpdateInput;
  create: MetadataOriginalColorWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataOriginalIccProfileListUpdateInput {
  where: MetadataOriginalIccProfileWhereUniqueInput;
  update: MetadataOriginalIccProfileWithForcedArchiveUpdateInput;
  create: MetadataOriginalIccProfileWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataOriginalNameListUpdateInput {
  where: MetadataOriginalNameWhereUniqueInput;
  update: MetadataOriginalNameWithForcedArchiveUpdateInput;
  create: MetadataOriginalNameWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataOriginalWeigthListUpdateInput {
  where: MetadataOriginalWeigthWhereUniqueInput;
  update: MetadataOriginalWeigthWithForcedArchiveUpdateInput;
  create: MetadataOriginalWeigthWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataOwnerIdListUpdateInput {
  where: MetadataOwnerIdWhereUniqueInput;
  update: MetadataOwnerIdWithForcedArchiveUpdateInput;
  create: MetadataOwnerIdWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataOwnerListUpdateInput {
  where: MetadataOwnerWhereUniqueInput;
  update: MetadataOwnerWithForcedArchiveUpdateInput;
  create: MetadataOwnerWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPageListUpdateInput {
  where: MetadataPageWhereUniqueInput;
  update: MetadataPageWithForcedArchiveUpdateInput;
  create: MetadataPageWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPagePrismaListUpdateInput {
  where: MetadataPagePrismaWhereUniqueInput;
  update: MetadataPagePrismaWithForcedArchiveUpdateInput;
  create: MetadataPagePrismaWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPaintBasedCorrectionsListUpdateInput {
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
  update: MetadataPaintBasedCorrectionsWithForcedArchiveUpdateInput;
  create: MetadataPaintBasedCorrectionsWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPatientDobListUpdateInput {
  where: MetadataPatientDobWhereUniqueInput;
  update: MetadataPatientDobWithForcedArchiveUpdateInput;
  create: MetadataPatientDobWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPatientIdListUpdateInput {
  where: MetadataPatientIdWhereUniqueInput;
  update: MetadataPatientIdWithForcedArchiveUpdateInput;
  create: MetadataPatientIdWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPatientNameListUpdateInput {
  where: MetadataPatientNameWhereUniqueInput;
  update: MetadataPatientNameWithForcedArchiveUpdateInput;
  create: MetadataPatientNameWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPatientSexListUpdateInput {
  where: MetadataPatientSexWhereUniqueInput;
  update: MetadataPatientSexWithForcedArchiveUpdateInput;
  create: MetadataPatientSexWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPersonInImageListUpdateInput {
  where: MetadataPersonInImageWhereUniqueInput;
  update: MetadataPersonInImageWithForcedArchiveUpdateInput;
  create: MetadataPersonInImageWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPhotoStorageListUpdateInput {
  where: MetadataPhotoStorageWhereUniqueInput;
  update: MetadataPhotoStorageWithForcedArchiveUpdateInput;
  create: MetadataPhotoStorageWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPrepressPictureEditingListUpdateInput {
  where: MetadataPrepressPictureEditingWhereUniqueInput;
  update: MetadataPrepressPictureEditingWithForcedArchiveUpdateInput;
  create: MetadataPrepressPictureEditingWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPriceLevelListUpdateInput {
  where: MetadataPriceLevelWhereUniqueInput;
  update: MetadataPriceLevelWithForcedArchiveUpdateInput;
  create: MetadataPriceLevelWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPrintingProfileListUpdateInput {
  where: MetadataPrintingProfileWhereUniqueInput;
  update: MetadataPrintingProfileWithForcedArchiveUpdateInput;
  create: MetadataPrintingProfileWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPrismaProductionListUpdateInput {
  where: MetadataPrismaProductionWhereUniqueInput;
  update: MetadataPrismaProductionWithForcedArchiveUpdateInput;
  create: MetadataPrismaProductionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataProcessHistoryListUpdateInput {
  where: MetadataProcessHistoryWhereUniqueInput;
  update: MetadataProcessHistoryWithForcedArchiveUpdateInput;
  create: MetadataProcessHistoryWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataProcessParameterListUpdateInput {
  where: MetadataProcessParameterWhereUniqueInput;
  update: MetadataProcessParameterWithForcedArchiveUpdateInput;
  create: MetadataProcessParameterWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataProcessStatusListUpdateInput {
  where: MetadataProcessStatusWhereUniqueInput;
  update: MetadataProcessStatusWithForcedArchiveUpdateInput;
  create: MetadataProcessStatusWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataProductListUpdateInput {
  where: MetadataProductWhereUniqueInput;
  update: MetadataProductWithForcedArchiveUpdateInput;
  create: MetadataProductWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataProductShortNameListUpdateInput {
  where: MetadataProductShortNameWhereUniqueInput;
  update: MetadataProductShortNameWithForcedArchiveUpdateInput;
  create: MetadataProductShortNameWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataProductsListUpdateInput {
  where: MetadataProductsWhereUniqueInput;
  update: MetadataProductsWithForcedArchiveUpdateInput;
  create: MetadataProductsWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataProduitsListUpdateInput {
  where: MetadataProduitsWhereUniqueInput;
  update: MetadataProduitsWithForcedArchiveUpdateInput;
  create: MetadataProduitsWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataProgramVersionListUpdateInput {
  where: MetadataProgramVersionWhereUniqueInput;
  update: MetadataProgramVersionWithForcedArchiveUpdateInput;
  create: MetadataProgramVersionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPropertyReleaseIdListUpdateInput {
  where: MetadataPropertyReleaseIdWhereUniqueInput;
  update: MetadataPropertyReleaseIdWithForcedArchiveUpdateInput;
  create: MetadataPropertyReleaseIdWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPropertyReleaseStatusListUpdateInput {
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
  update: MetadataPropertyReleaseStatusWithForcedArchiveUpdateInput;
  create: MetadataPropertyReleaseStatusWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPublisherListUpdateInput {
  where: MetadataPublisherWhereUniqueInput;
  update: MetadataPublisherWithForcedArchiveUpdateInput;
  create: MetadataPublisherWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPublishingIssueListUpdateInput {
  where: MetadataPublishingIssueWhereUniqueInput;
  update: MetadataPublishingIssueWithForcedArchiveUpdateInput;
  create: MetadataPublishingIssueWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataPublishingSubjectListUpdateInput {
  where: MetadataPublishingSubjectWhereUniqueInput;
  update: MetadataPublishingSubjectWithForcedArchiveUpdateInput;
  create: MetadataPublishingSubjectWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataQualifiedUsePrismaByListUpdateInput {
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
  update: MetadataQualifiedUsePrismaByWithForcedArchiveUpdateInput;
  create: MetadataQualifiedUsePrismaByWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataQualifiedUsePrismaDateListUpdateInput {
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
  update: MetadataQualifiedUsePrismaDateWithForcedArchiveUpdateInput;
  create: MetadataQualifiedUsePrismaDateWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataQualifiedUsePrismaDurationListUpdateInput {
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
  update: MetadataQualifiedUsePrismaDurationWithForcedArchiveUpdateInput;
  create: MetadataQualifiedUsePrismaDurationWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataQualifiedUsePrismaSupportListUpdateInput {
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
  update: MetadataQualifiedUsePrismaSupportWithForcedArchiveUpdateInput;
  create: MetadataQualifiedUsePrismaSupportWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataRatingListUpdateInput {
  where: MetadataRatingWhereUniqueInput;
  update: MetadataRatingWithForcedArchiveUpdateInput;
  create: MetadataRatingWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataReferenceDateListUpdateInput {
  where: MetadataReferenceDateWhereUniqueInput;
  update: MetadataReferenceDateWithForcedArchiveUpdateInput;
  create: MetadataReferenceDateWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataReferenceNumberListUpdateInput {
  where: MetadataReferenceNumberWhereUniqueInput;
  update: MetadataReferenceNumberWithForcedArchiveUpdateInput;
  create: MetadataReferenceNumberWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataReferenceServiceListUpdateInput {
  where: MetadataReferenceServiceWhereUniqueInput;
  update: MetadataReferenceServiceWithForcedArchiveUpdateInput;
  create: MetadataReferenceServiceWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataReleaseDateListUpdateInput {
  where: MetadataReleaseDateWhereUniqueInput;
  update: MetadataReleaseDateWithForcedArchiveUpdateInput;
  create: MetadataReleaseDateWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataReleaseTimeListUpdateInput {
  where: MetadataReleaseTimeWhereUniqueInput;
  update: MetadataReleaseTimeWithForcedArchiveUpdateInput;
  create: MetadataReleaseTimeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataRequiredPermissionListUpdateInput {
  where: MetadataRequiredPermissionWhereUniqueInput;
  update: MetadataRequiredPermissionWithForcedArchiveUpdateInput;
  create: MetadataRequiredPermissionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataResolutionKindListUpdateInput {
  where: MetadataResolutionKindWhereUniqueInput;
  update: MetadataResolutionKindWithForcedArchiveUpdateInput;
  create: MetadataResolutionKindWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataRightsListUpdateInput {
  where: MetadataRightsWhereUniqueInput;
  update: MetadataRightsWithForcedArchiveUpdateInput;
  create: MetadataRightsWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataRoyaltyfreeListUpdateInput {
  where: MetadataRoyaltyfreeWhereUniqueInput;
  update: MetadataRoyaltyfreeWithForcedArchiveUpdateInput;
  create: MetadataRoyaltyfreeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataSceneListUpdateInput {
  where: MetadataSceneWhereUniqueInput;
  update: MetadataSceneWithForcedArchiveUpdateInput;
  create: MetadataSceneWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataSectionListUpdateInput {
  where: MetadataSectionWhereUniqueInput;
  update: MetadataSectionWithForcedArchiveUpdateInput;
  create: MetadataSectionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataSentToListUpdateInput {
  where: MetadataSentToWhereUniqueInput;
  update: MetadataSentToWithForcedArchiveUpdateInput;
  create: MetadataSentToWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataSerialNumberListUpdateInput {
  where: MetadataSerialNumberWhereUniqueInput;
  update: MetadataSerialNumberWithForcedArchiveUpdateInput;
  create: MetadataSerialNumberWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataSeriesDateTimeListUpdateInput {
  where: MetadataSeriesDateTimeWhereUniqueInput;
  update: MetadataSeriesDateTimeWithForcedArchiveUpdateInput;
  create: MetadataSeriesDateTimeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataSeriesDescriptionListUpdateInput {
  where: MetadataSeriesDescriptionWhereUniqueInput;
  update: MetadataSeriesDescriptionWithForcedArchiveUpdateInput;
  create: MetadataSeriesDescriptionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataSeriesModalityListUpdateInput {
  where: MetadataSeriesModalityWhereUniqueInput;
  update: MetadataSeriesModalityWithForcedArchiveUpdateInput;
  create: MetadataSeriesModalityWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataSeriesNumberListUpdateInput {
  where: MetadataSeriesNumberWhereUniqueInput;
  update: MetadataSeriesNumberWithForcedArchiveUpdateInput;
  create: MetadataSeriesNumberWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataShortUniqueIdListUpdateInput {
  where: MetadataShortUniqueIdWhereUniqueInput;
  update: MetadataShortUniqueIdWithForcedArchiveUpdateInput;
  create: MetadataShortUniqueIdWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataSourceListUpdateInput {
  where: MetadataSourceWhereUniqueInput;
  update: MetadataSourceWithForcedArchiveUpdateInput;
  create: MetadataSourceWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataStateListUpdateInput {
  where: MetadataStateWhereUniqueInput;
  update: MetadataStateWithForcedArchiveUpdateInput;
  create: MetadataStateWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataStudyDateTimeListUpdateInput {
  where: MetadataStudyDateTimeWhereUniqueInput;
  update: MetadataStudyDateTimeWithForcedArchiveUpdateInput;
  create: MetadataStudyDateTimeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataStudyDescriptionListUpdateInput {
  where: MetadataStudyDescriptionWhereUniqueInput;
  update: MetadataStudyDescriptionWithForcedArchiveUpdateInput;
  create: MetadataStudyDescriptionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataStudyIdListUpdateInput {
  where: MetadataStudyIdWhereUniqueInput;
  update: MetadataStudyIdWithForcedArchiveUpdateInput;
  create: MetadataStudyIdWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataStudyPhysicianListUpdateInput {
  where: MetadataStudyPhysicianWhereUniqueInput;
  update: MetadataStudyPhysicianWithForcedArchiveUpdateInput;
  create: MetadataStudyPhysicianWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataSubjectCodeListUpdateInput {
  where: MetadataSubjectCodeWhereUniqueInput;
  update: MetadataSubjectCodeWithForcedArchiveUpdateInput;
  create: MetadataSubjectCodeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataSubjectListUpdateInput {
  where: MetadataSubjectWhereUniqueInput;
  update: MetadataSubjectWithForcedArchiveUpdateInput;
  create: MetadataSubjectWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataSubjectPrismaListUpdateInput {
  where: MetadataSubjectPrismaWhereUniqueInput;
  update: MetadataSubjectPrismaWithForcedArchiveUpdateInput;
  create: MetadataSubjectPrismaWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataSupplementalCategoriesListUpdateInput {
  where: MetadataSupplementalCategoriesWhereUniqueInput;
  update: MetadataSupplementalCategoriesWithForcedArchiveUpdateInput;
  create: MetadataSupplementalCategoriesWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataTargetVersionListUpdateInput {
  where: MetadataTargetVersionWhereUniqueInput;
  update: MetadataTargetVersionWithForcedArchiveUpdateInput;
  create: MetadataTargetVersionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataTitleListUpdateInput {
  where: MetadataTitleWhereUniqueInput;
  update: MetadataTitleWithForcedArchiveUpdateInput;
  create: MetadataTitleWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataToneCurveListUpdateInput {
  where: MetadataToneCurveWhereUniqueInput;
  update: MetadataToneCurveWithForcedArchiveUpdateInput;
  create: MetadataToneCurveWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataTransferredByEmailListUpdateInput {
  where: MetadataTransferredByEmailWhereUniqueInput;
  update: MetadataTransferredByEmailWithForcedArchiveUpdateInput;
  create: MetadataTransferredByEmailWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataTransferredByFullNameListUpdateInput {
  where: MetadataTransferredByFullNameWhereUniqueInput;
  update: MetadataTransferredByFullNameWithForcedArchiveUpdateInput;
  create: MetadataTransferredByFullNameWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataTransferredByListUpdateInput {
  where: MetadataTransferredByWhereUniqueInput;
  update: MetadataTransferredByWithForcedArchiveUpdateInput;
  create: MetadataTransferredByWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataTransmissionReferenceListUpdateInput {
  where: MetadataTransmissionReferenceWhereUniqueInput;
  update: MetadataTransmissionReferenceWithForcedArchiveUpdateInput;
  create: MetadataTransmissionReferenceWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataTypeListUpdateInput {
  where: MetadataTypeWhereUniqueInput;
  update: MetadataTypeWithForcedArchiveUpdateInput;
  create: MetadataTypeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUnderUnderSubjectListUpdateInput {
  where: MetadataUnderUnderSubjectWhereUniqueInput;
  update: MetadataUnderUnderSubjectWithForcedArchiveUpdateInput;
  create: MetadataUnderUnderSubjectWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUndersubjectListUpdateInput {
  where: MetadataUndersubjectWhereUniqueInput;
  update: MetadataUndersubjectWithForcedArchiveUpdateInput;
  create: MetadataUndersubjectWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUniqueIdListUpdateInput {
  where: MetadataUniqueIdWhereUniqueInput;
  update: MetadataUniqueIdWithForcedArchiveUpdateInput;
  create: MetadataUniqueIdWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUnitListUpdateInput {
  where: MetadataUnitWhereUniqueInput;
  update: MetadataUnitWithForcedArchiveUpdateInput;
  create: MetadataUnitWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUnitShortNameListUpdateInput {
  where: MetadataUnitShortNameWhereUniqueInput;
  update: MetadataUnitShortNameWithForcedArchiveUpdateInput;
  create: MetadataUnitShortNameWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUploadTimeListUpdateInput {
  where: MetadataUploadTimeWhereUniqueInput;
  update: MetadataUploadTimeWithForcedArchiveUpdateInput;
  create: MetadataUploadTimeWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUploadedByFullNameListUpdateInput {
  where: MetadataUploadedByFullNameWhereUniqueInput;
  update: MetadataUploadedByFullNameWithForcedArchiveUpdateInput;
  create: MetadataUploadedByFullNameWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUploadedByListUpdateInput {
  where: MetadataUploadedByWhereUniqueInput;
  update: MetadataUploadedByWithForcedArchiveUpdateInput;
  create: MetadataUploadedByWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUrgencyListUpdateInput {
  where: MetadataUrgencyWhereUniqueInput;
  update: MetadataUrgencyWithForcedArchiveUpdateInput;
  create: MetadataUrgencyWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUsageTermsListUpdateInput {
  where: MetadataUsageTermsWhereUniqueInput;
  update: MetadataUsageTermsWithForcedArchiveUpdateInput;
  create: MetadataUsageTermsWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUserCommentListUpdateInput {
  where: MetadataUserCommentWhereUniqueInput;
  update: MetadataUserCommentWithForcedArchiveUpdateInput;
  create: MetadataUserCommentWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUserDefined195ListUpdateInput {
  where: MetadataUserDefined195WhereUniqueInput;
  update: MetadataUserDefined195WithForcedArchiveUpdateInput;
  create: MetadataUserDefined195WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUserDefined237ListUpdateInput {
  where: MetadataUserDefined237WhereUniqueInput;
  update: MetadataUserDefined237WithForcedArchiveUpdateInput;
  create: MetadataUserDefined237WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUserDefined238ListUpdateInput {
  where: MetadataUserDefined238WhereUniqueInput;
  update: MetadataUserDefined238WithForcedArchiveUpdateInput;
  create: MetadataUserDefined238WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUserDefined239ListUpdateInput {
  where: MetadataUserDefined239WhereUniqueInput;
  update: MetadataUserDefined239WithForcedArchiveUpdateInput;
  create: MetadataUserDefined239WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUserDefined242ListUpdateInput {
  where: MetadataUserDefined242WhereUniqueInput;
  update: MetadataUserDefined242WithForcedArchiveUpdateInput;
  create: MetadataUserDefined242WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataUserDefined62ListUpdateInput {
  where: MetadataUserDefined62WhereUniqueInput;
  update: MetadataUserDefined62WithForcedArchiveUpdateInput;
  create: MetadataUserDefined62WithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataValidListUpdateInput {
  where: MetadataValidWhereUniqueInput;
  update: MetadataValidWithForcedArchiveUpdateInput;
  create: MetadataValidWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataVersionListUpdateInput {
  where: MetadataVersionWhereUniqueInput;
  update: MetadataVersionWithForcedArchiveUpdateInput;
  create: MetadataVersionWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataWebStatementListUpdateInput {
  where: MetadataWebStatementWhereUniqueInput;
  update: MetadataWebStatementWithForcedArchiveUpdateInput;
  create: MetadataWebStatementWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataWorkflowKindListUpdateInput {
  where: MetadataWorkflowKindWhereUniqueInput;
  update: MetadataWorkflowKindWithForcedArchiveUpdateInput;
  create: MetadataWorkflowKindWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataXmpFileStampsListUpdateInput {
  where: MetadataXmpFileStampsWhereUniqueInput;
  update: MetadataXmpFileStampsWithForcedArchiveUpdateInput;
  create: MetadataXmpFileStampsWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertMetadataXmpHistoryListUpdateInput {
  where: MetadataXmpHistoryWhereUniqueInput;
  update: MetadataXmpHistoryWithForcedArchiveUpdateInput;
  create: MetadataXmpHistoryWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertPhotoMetadatasUpdateInput {
  where: PhotoMetadataWhereUniqueInput;
  update: PhotoMetadataWithForcedArchiveUpdateInput;
  create: PhotoMetadataWithForcedArchiveCreateInput;
}

export interface ArchiveNestedUpsertPhotosUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoWithForcedArchiveUpdateInput;
  create: PhotoWithForcedArchiveCreateInput;
}

export interface ArchiveUpdateInput {
  name?: ArchiveKeyType | null;
  label?: string | null;
  type?: ArchiveType | null;
  hasCategories?: boolean | null;
  archiveAgencies?: ArchiveNestedArchiveAgenciesUpdateInput | null;
  categories?: ArchiveNestedCategoriesUpdateInput | null;
  deliverySubjects?: ArchiveNestedDeliverySubjectsUpdateInput | null;
  gcImportSetting?: ArchiveNestedGcImportSettingUpdateInput | null;
  importQueues?: ArchiveNestedImportQueuesUpdateInput | null;
  photos?: ArchiveNestedPhotosUpdateInput | null;
  photoMetadatas?: ArchiveNestedPhotoMetadatasUpdateInput | null;
  metadataSerialNumberList?: ArchiveNestedMetadataSerialNumberListUpdateInput | null;
  metadataAttributionNameList?: ArchiveNestedMetadataAttributionNameListUpdateInput | null;
  metadataAttributionUrlList?: ArchiveNestedMetadataAttributionUrlListUpdateInput | null;
  metadataLicenseList?: ArchiveNestedMetadataLicenseListUpdateInput | null;
  metadataMorePermissionsList?: ArchiveNestedMetadataMorePermissionsListUpdateInput | null;
  metadataPaintBasedCorrectionsList?: ArchiveNestedMetadataPaintBasedCorrectionsListUpdateInput | null;
  metadataToneCurveList?: ArchiveNestedMetadataToneCurveListUpdateInput | null;
  metadataCreatorList?: ArchiveNestedMetadataCreatorListUpdateInput | null;
  metadataDescriptionList?: ArchiveNestedMetadataDescriptionListUpdateInput | null;
  metadataFormatList?: ArchiveNestedMetadataFormatListUpdateInput | null;
  metadataPageList?: ArchiveNestedMetadataPageListUpdateInput | null;
  metadataPublisherList?: ArchiveNestedMetadataPublisherListUpdateInput | null;
  metadataRightsList?: ArchiveNestedMetadataRightsListUpdateInput | null;
  metadataSubjectList?: ArchiveNestedMetadataSubjectListUpdateInput | null;
  metadataTitleList?: ArchiveNestedMetadataTitleListUpdateInput | null;
  metadataTypeList?: ArchiveNestedMetadataTypeListUpdateInput | null;
  metadataAccessRightsList?: ArchiveNestedMetadataAccessRightsListUpdateInput | null;
  metadataAudienceList?: ArchiveNestedMetadataAudienceListUpdateInput | null;
  metadataValidList?: ArchiveNestedMetadataValidListUpdateInput | null;
  metadataEquipmentInstitutionList?: ArchiveNestedMetadataEquipmentInstitutionListUpdateInput | null;
  metadataEquipmentManufacturerList?: ArchiveNestedMetadataEquipmentManufacturerListUpdateInput | null;
  metadataFileInfoBitsPerPixelList?: ArchiveNestedMetadataFileInfoBitsPerPixelListUpdateInput | null;
  metadataFileInfoFramesList?: ArchiveNestedMetadataFileInfoFramesListUpdateInput | null;
  metadataFileInfoPhotometricInterpretationList?: ArchiveNestedMetadataFileInfoPhotometricInterpretationListUpdateInput | null;
  metadataFileInfoTransferSyntaxList?: ArchiveNestedMetadataFileInfoTransferSyntaxListUpdateInput | null;
  metadataPatientDobList?: ArchiveNestedMetadataPatientDobListUpdateInput | null;
  metadataPatientIdList?: ArchiveNestedMetadataPatientIdListUpdateInput | null;
  metadataPatientNameList?: ArchiveNestedMetadataPatientNameListUpdateInput | null;
  metadataPatientSexList?: ArchiveNestedMetadataPatientSexListUpdateInput | null;
  metadataSeriesDateTimeList?: ArchiveNestedMetadataSeriesDateTimeListUpdateInput | null;
  metadataSeriesDescriptionList?: ArchiveNestedMetadataSeriesDescriptionListUpdateInput | null;
  metadataSeriesModalityList?: ArchiveNestedMetadataSeriesModalityListUpdateInput | null;
  metadataSeriesNumberList?: ArchiveNestedMetadataSeriesNumberListUpdateInput | null;
  metadataStudyDateTimeList?: ArchiveNestedMetadataStudyDateTimeListUpdateInput | null;
  metadataStudyDescriptionList?: ArchiveNestedMetadataStudyDescriptionListUpdateInput | null;
  metadataStudyIdList?: ArchiveNestedMetadataStudyIdListUpdateInput | null;
  metadataStudyPhysicianList?: ArchiveNestedMetadataStudyPhysicianListUpdateInput | null;
  metadataDateTimeDigitizedList?: ArchiveNestedMetadataDateTimeDigitizedListUpdateInput | null;
  metadataDateTimeOriginalList?: ArchiveNestedMetadataDateTimeOriginalListUpdateInput | null;
  metadataGpsLatitudeList?: ArchiveNestedMetadataGpsLatitudeListUpdateInput | null;
  metadataGpsLongitudeList?: ArchiveNestedMetadataGpsLongitudeListUpdateInput | null;
  metadataGpsProcessingMethodList?: ArchiveNestedMetadataGpsProcessingMethodListUpdateInput | null;
  metadataImageUniqueIdList?: ArchiveNestedMetadataImageUniqueIdListUpdateInput | null;
  metadataIsoSpeedRatingsList?: ArchiveNestedMetadataIsoSpeedRatingsListUpdateInput | null;
  metadataUserCommentList?: ArchiveNestedMetadataUserCommentListUpdateInput | null;
  metadataCustomField14List?: ArchiveNestedMetadataCustomField14ListUpdateInput | null;
  metadataCustomField15List?: ArchiveNestedMetadataCustomField15ListUpdateInput | null;
  metadataCustomField16List?: ArchiveNestedMetadataCustomField16ListUpdateInput | null;
  metadataCustomField17List?: ArchiveNestedMetadataCustomField17ListUpdateInput | null;
  metadataCustomField20List?: ArchiveNestedMetadataCustomField20ListUpdateInput | null;
  metadataCustomField5List?: ArchiveNestedMetadataCustomField5ListUpdateInput | null;
  metadataCustomField6List?: ArchiveNestedMetadataCustomField6ListUpdateInput | null;
  metadataCustomField7List?: ArchiveNestedMetadataCustomField7ListUpdateInput | null;
  metadataCustomField9List?: ArchiveNestedMetadataCustomField9ListUpdateInput | null;
  metadataCreatedTimeList?: ArchiveNestedMetadataCreatedTimeListUpdateInput | null;
  metadataEditStatusList?: ArchiveNestedMetadataEditStatusListUpdateInput | null;
  metadataFixtureIdentifierList?: ArchiveNestedMetadataFixtureIdentifierListUpdateInput | null;
  metadataLocalCaptionList?: ArchiveNestedMetadataLocalCaptionListUpdateInput | null;
  metadataProgramVersionList?: ArchiveNestedMetadataProgramVersionListUpdateInput | null;
  metadataReferenceDateList?: ArchiveNestedMetadataReferenceDateListUpdateInput | null;
  metadataReferenceNumberList?: ArchiveNestedMetadataReferenceNumberListUpdateInput | null;
  metadataReferenceServiceList?: ArchiveNestedMetadataReferenceServiceListUpdateInput | null;
  metadataReleaseDateList?: ArchiveNestedMetadataReleaseDateListUpdateInput | null;
  metadataReleaseTimeList?: ArchiveNestedMetadataReleaseTimeListUpdateInput | null;
  metadataAssetStateList?: ArchiveNestedMetadataAssetStateListUpdateInput | null;
  metadataClassifyList?: ArchiveNestedMetadataClassifyListUpdateInput | null;
  metadataContainedInList?: ArchiveNestedMetadataContainedInListUpdateInput | null;
  metadataContentValueList?: ArchiveNestedMetadataContentValueListUpdateInput | null;
  metadataDocumentTextList?: ArchiveNestedMetadataDocumentTextListUpdateInput | null;
  metadataExifList?: ArchiveNestedMetadataExifListUpdateInput | null;
  metadataHistoryList?: ArchiveNestedMetadataHistoryListUpdateInput | null;
  metadataImageNotesList?: ArchiveNestedMetadataImageNotesListUpdateInput | null;
  metadataJobIdList?: ArchiveNestedMetadataJobIdListUpdateInput | null;
  metadataLinksList?: ArchiveNestedMetadataLinksListUpdateInput | null;
  metadataMasterDocumentIdList?: ArchiveNestedMetadataMasterDocumentIdListUpdateInput | null;
  metadataObjectCycleList?: ArchiveNestedMetadataObjectCycleListUpdateInput | null;
  metadataOwnerIdList?: ArchiveNestedMetadataOwnerIdListUpdateInput | null;
  metadataShortUniqueIdList?: ArchiveNestedMetadataShortUniqueIdListUpdateInput | null;
  metadataTransferredByList?: ArchiveNestedMetadataTransferredByListUpdateInput | null;
  metadataTransferredByEmailList?: ArchiveNestedMetadataTransferredByEmailListUpdateInput | null;
  metadataTransferredByFullNameList?: ArchiveNestedMetadataTransferredByFullNameListUpdateInput | null;
  metadataUniqueIdList?: ArchiveNestedMetadataUniqueIdListUpdateInput | null;
  metadataUploadedByList?: ArchiveNestedMetadataUploadedByListUpdateInput | null;
  metadataUploadedByFullNameList?: ArchiveNestedMetadataUploadedByFullNameListUpdateInput | null;
  metadataUploadTimeList?: ArchiveNestedMetadataUploadTimeListUpdateInput | null;
  metadataUserDefined195List?: ArchiveNestedMetadataUserDefined195ListUpdateInput | null;
  metadataUserDefined237List?: ArchiveNestedMetadataUserDefined237ListUpdateInput | null;
  metadataUserDefined238List?: ArchiveNestedMetadataUserDefined238ListUpdateInput | null;
  metadataUserDefined239List?: ArchiveNestedMetadataUserDefined239ListUpdateInput | null;
  metadataUserDefined242List?: ArchiveNestedMetadataUserDefined242ListUpdateInput | null;
  metadataUserDefined62List?: ArchiveNestedMetadataUserDefined62ListUpdateInput | null;
  metadataCiAdrCityList?: ArchiveNestedMetadataCiAdrCityListUpdateInput | null;
  metadataCiAdrCtryList?: ArchiveNestedMetadataCiAdrCtryListUpdateInput | null;
  metadataCiAdrExtadrList?: ArchiveNestedMetadataCiAdrExtadrListUpdateInput | null;
  metadataCiAdrPcodeList?: ArchiveNestedMetadataCiAdrPcodeListUpdateInput | null;
  metadataCiAdrRegionList?: ArchiveNestedMetadataCiAdrRegionListUpdateInput | null;
  metadataCiEmailWorkList?: ArchiveNestedMetadataCiEmailWorkListUpdateInput | null;
  metadataCiTelWorkList?: ArchiveNestedMetadataCiTelWorkListUpdateInput | null;
  metadataCiUrlWorkList?: ArchiveNestedMetadataCiUrlWorkListUpdateInput | null;
  metadataCountryCodeList?: ArchiveNestedMetadataCountryCodeListUpdateInput | null;
  metadataIntellectualGenreList?: ArchiveNestedMetadataIntellectualGenreListUpdateInput | null;
  metadataLocationList?: ArchiveNestedMetadataLocationListUpdateInput | null;
  metadataSceneList?: ArchiveNestedMetadataSceneListUpdateInput | null;
  metadataSubjectCodeList?: ArchiveNestedMetadataSubjectCodeListUpdateInput | null;
  metadataAddlModelInfoList?: ArchiveNestedMetadataAddlModelInfoListUpdateInput | null;
  metadataEventList?: ArchiveNestedMetadataEventListUpdateInput | null;
  metadataMaxAvailHeightList?: ArchiveNestedMetadataMaxAvailHeightListUpdateInput | null;
  metadataMaxAvailWidthList?: ArchiveNestedMetadataMaxAvailWidthListUpdateInput | null;
  metadataModelAgeList?: ArchiveNestedMetadataModelAgeListUpdateInput | null;
  metadataOrganisationInImageNameList?: ArchiveNestedMetadataOrganisationInImageNameListUpdateInput | null;
  metadataPersonInImageList?: ArchiveNestedMetadataPersonInImageListUpdateInput | null;
  metadataAuthorsPositionList?: ArchiveNestedMetadataAuthorsPositionListUpdateInput | null;
  metadataCaptionWriterList?: ArchiveNestedMetadataCaptionWriterListUpdateInput | null;
  metadataCategoryList?: ArchiveNestedMetadataCategoryListUpdateInput | null;
  metadataCityList?: ArchiveNestedMetadataCityListUpdateInput | null;
  metadataCountryList?: ArchiveNestedMetadataCountryListUpdateInput | null;
  metadataCreditList?: ArchiveNestedMetadataCreditListUpdateInput | null;
  metadataDateCreatedList?: ArchiveNestedMetadataDateCreatedListUpdateInput | null;
  metadataHeadlineList?: ArchiveNestedMetadataHeadlineListUpdateInput | null;
  metadataIccProfileList?: ArchiveNestedMetadataIccProfileListUpdateInput | null;
  metadataInstructionsList?: ArchiveNestedMetadataInstructionsListUpdateInput | null;
  metadataSourceList?: ArchiveNestedMetadataSourceListUpdateInput | null;
  metadataStateList?: ArchiveNestedMetadataStateListUpdateInput | null;
  metadataSupplementalCategoriesList?: ArchiveNestedMetadataSupplementalCategoriesListUpdateInput | null;
  metadataTransmissionReferenceList?: ArchiveNestedMetadataTransmissionReferenceListUpdateInput | null;
  metadataUrgencyList?: ArchiveNestedMetadataUrgencyListUpdateInput | null;
  metadataMinorModelAgeDisclosureList?: ArchiveNestedMetadataMinorModelAgeDisclosureListUpdateInput | null;
  metadataModelReleaseIdList?: ArchiveNestedMetadataModelReleaseIdListUpdateInput | null;
  metadataModelReleaseStatusList?: ArchiveNestedMetadataModelReleaseStatusListUpdateInput | null;
  metadataPropertyReleaseIdList?: ArchiveNestedMetadataPropertyReleaseIdListUpdateInput | null;
  metadataPropertyReleaseStatusList?: ArchiveNestedMetadataPropertyReleaseStatusListUpdateInput | null;
  metadataVersionList?: ArchiveNestedMetadataVersionListUpdateInput | null;
  metadataBrandList?: ArchiveNestedMetadataBrandListUpdateInput | null;
  metadataDivisionList?: ArchiveNestedMetadataDivisionListUpdateInput | null;
  metadataHeaderList?: ArchiveNestedMetadataHeaderListUpdateInput | null;
  metadataProductsList?: ArchiveNestedMetadataProductsListUpdateInput | null;
  metadataProduitsList?: ArchiveNestedMetadataProduitsListUpdateInput | null;
  metadataPublishingIssueList?: ArchiveNestedMetadataPublishingIssueListUpdateInput | null;
  metadataPublishingSubjectList?: ArchiveNestedMetadataPublishingSubjectListUpdateInput | null;
  metadataCompanyList?: ArchiveNestedMetadataCompanyListUpdateInput | null;
  metadataCompanyShortNameList?: ArchiveNestedMetadataCompanyShortNameListUpdateInput | null;
  metadataConfidentialityList?: ArchiveNestedMetadataConfidentialityListUpdateInput | null;
  metadataEditorialVersionList?: ArchiveNestedMetadataEditorialVersionListUpdateInput | null;
  metadataFileCheckForList?: ArchiveNestedMetadataFileCheckForListUpdateInput | null;
  metadataFileStageList?: ArchiveNestedMetadataFileStageListUpdateInput | null;
  metadataFileStatusList?: ArchiveNestedMetadataFileStatusListUpdateInput | null;
  metadataSentToList?: ArchiveNestedMetadataSentToListUpdateInput | null;
  metadataTargetVersionList?: ArchiveNestedMetadataTargetVersionListUpdateInput | null;
  metadataUnitList?: ArchiveNestedMetadataUnitListUpdateInput | null;
  metadataArchiveChildList?: ArchiveNestedMetadataArchiveChildListUpdateInput | null;
  metadataArchiveParentList?: ArchiveNestedMetadataArchiveParentListUpdateInput | null;
  metadataBackupNameList?: ArchiveNestedMetadataBackupNameListUpdateInput | null;
  metadataBitmapGrayscalePictureList?: ArchiveNestedMetadataBitmapGrayscalePictureListUpdateInput | null;
  metadataBrandPrismaList?: ArchiveNestedMetadataBrandPrismaListUpdateInput | null;
  metadataBrandShortNameList?: ArchiveNestedMetadataBrandShortNameListUpdateInput | null;
  metadataCategoryPrismaList?: ArchiveNestedMetadataCategoryPrismaListUpdateInput | null;
  metadataContentMediaKindList?: ArchiveNestedMetadataContentMediaKindListUpdateInput | null;
  metadataCopyrightLayerList?: ArchiveNestedMetadataCopyrightLayerListUpdateInput | null;
  metadataDeliveryAccountList?: ArchiveNestedMetadataDeliveryAccountListUpdateInput | null;
  metadataDeliveryCompanyList?: ArchiveNestedMetadataDeliveryCompanyListUpdateInput | null;
  metadataDeliveryCopyrightList?: ArchiveNestedMetadataDeliveryCopyrightListUpdateInput | null;
  metadataDeliveryDateFolderList?: ArchiveNestedMetadataDeliveryDateFolderListUpdateInput | null;
  metadataDeliveryDateTimeList?: ArchiveNestedMetadataDeliveryDateTimeListUpdateInput | null;
  metadataDeliveryDcCreatorList?: ArchiveNestedMetadataDeliveryDcCreatorListUpdateInput | null;
  metadataDeliveryDcRightsList?: ArchiveNestedMetadataDeliveryDcRightsListUpdateInput | null;
  metadataDeliveryFileTypeList?: ArchiveNestedMetadataDeliveryFileTypeListUpdateInput | null;
  metadataDeliveryFolderList?: ArchiveNestedMetadataDeliveryFolderListUpdateInput | null;
  metadataDeliveryKindList?: ArchiveNestedMetadataDeliveryKindListUpdateInput | null;
  metadataDeliveryPathList?: ArchiveNestedMetadataDeliveryPathListUpdateInput | null;
  metadataDeliveryPersonShownintheImageList?: ArchiveNestedMetadataDeliveryPersonShownintheImageListUpdateInput | null;
  metadataDeliveryPhotoshopCreditList?: ArchiveNestedMetadataDeliveryPhotoshopCreditListUpdateInput | null;
  metadataDeliveryPhotoshopSourceList?: ArchiveNestedMetadataDeliveryPhotoshopSourceListUpdateInput | null;
  metadataDeliveryServiceList?: ArchiveNestedMetadataDeliveryServiceListUpdateInput | null;
  metadataDeliverySubjectList?: ArchiveNestedMetadataDeliverySubjectListUpdateInput | null;
  metadataDeliveryUnderSubjectList?: ArchiveNestedMetadataDeliveryUnderSubjectListUpdateInput | null;
  metadataDepartmentList?: ArchiveNestedMetadataDepartmentListUpdateInput | null;
  metadataDigitalAssetUrlList?: ArchiveNestedMetadataDigitalAssetUrlListUpdateInput | null;
  metadataEditionList?: ArchiveNestedMetadataEditionListUpdateInput | null;
  metadataEnvironnementPhotoList?: ArchiveNestedMetadataEnvironnementPhotoListUpdateInput | null;
  metadataFabStorageList?: ArchiveNestedMetadataFabStorageListUpdateInput | null;
  metadataFileTypeList?: ArchiveNestedMetadataFileTypeListUpdateInput | null;
  metadataHeaderPrismaList?: ArchiveNestedMetadataHeaderPrismaListUpdateInput | null;
  metadataIdSubjectList?: ArchiveNestedMetadataIdSubjectListUpdateInput | null;
  metadataIdAssignmentList?: ArchiveNestedMetadataIdAssignmentListUpdateInput | null;
  metadataIdMediaContentList?: ArchiveNestedMetadataIdMediaContentListUpdateInput | null;
  metadataIdPhotoPrismaList?: ArchiveNestedMetadataIdPhotoPrismaListUpdateInput | null;
  metadataIssueList?: ArchiveNestedMetadataIssueListUpdateInput | null;
  metadataJobProcessingList?: ArchiveNestedMetadataJobProcessingListUpdateInput | null;
  metadataLayoutPictureEditingList?: ArchiveNestedMetadataLayoutPictureEditingListUpdateInput | null;
  metadataLayoutStorageList?: ArchiveNestedMetadataLayoutStorageListUpdateInput | null;
  metadataOriginalColorList?: ArchiveNestedMetadataOriginalColorListUpdateInput | null;
  metadataOriginalIccProfileList?: ArchiveNestedMetadataOriginalIccProfileListUpdateInput | null;
  metadataOriginalNameList?: ArchiveNestedMetadataOriginalNameListUpdateInput | null;
  metadataOriginalWeigthList?: ArchiveNestedMetadataOriginalWeigthListUpdateInput | null;
  metadataPagePrismaList?: ArchiveNestedMetadataPagePrismaListUpdateInput | null;
  metadataPhotoStorageList?: ArchiveNestedMetadataPhotoStorageListUpdateInput | null;
  metadataPrepressPictureEditingList?: ArchiveNestedMetadataPrepressPictureEditingListUpdateInput | null;
  metadataPriceLevelList?: ArchiveNestedMetadataPriceLevelListUpdateInput | null;
  metadataPrintingProfileList?: ArchiveNestedMetadataPrintingProfileListUpdateInput | null;
  metadataPrismaProductionList?: ArchiveNestedMetadataPrismaProductionListUpdateInput | null;
  metadataProcessHistoryList?: ArchiveNestedMetadataProcessHistoryListUpdateInput | null;
  metadataProcessParameterList?: ArchiveNestedMetadataProcessParameterListUpdateInput | null;
  metadataProcessStatusList?: ArchiveNestedMetadataProcessStatusListUpdateInput | null;
  metadataProductList?: ArchiveNestedMetadataProductListUpdateInput | null;
  metadataProductShortNameList?: ArchiveNestedMetadataProductShortNameListUpdateInput | null;
  metadataQualifiedUsePrismaByList?: ArchiveNestedMetadataQualifiedUsePrismaByListUpdateInput | null;
  metadataQualifiedUsePrismaDateList?: ArchiveNestedMetadataQualifiedUsePrismaDateListUpdateInput | null;
  metadataQualifiedUsePrismaDurationList?: ArchiveNestedMetadataQualifiedUsePrismaDurationListUpdateInput | null;
  metadataQualifiedUsePrismaSupportList?: ArchiveNestedMetadataQualifiedUsePrismaSupportListUpdateInput | null;
  metadataRequiredPermissionList?: ArchiveNestedMetadataRequiredPermissionListUpdateInput | null;
  metadataResolutionKindList?: ArchiveNestedMetadataResolutionKindListUpdateInput | null;
  metadataRoyaltyfreeList?: ArchiveNestedMetadataRoyaltyfreeListUpdateInput | null;
  metadataSectionList?: ArchiveNestedMetadataSectionListUpdateInput | null;
  metadataSubjectPrismaList?: ArchiveNestedMetadataSubjectPrismaListUpdateInput | null;
  metadataUndersubjectList?: ArchiveNestedMetadataUndersubjectListUpdateInput | null;
  metadataUnderUnderSubjectList?: ArchiveNestedMetadataUnderUnderSubjectListUpdateInput | null;
  metadataUnitShortNameList?: ArchiveNestedMetadataUnitShortNameListUpdateInput | null;
  metadataWorkflowKindList?: ArchiveNestedMetadataWorkflowKindListUpdateInput | null;
  metadataMakeList?: ArchiveNestedMetadataMakeListUpdateInput | null;
  metadataModelList?: ArchiveNestedMetadataModelListUpdateInput | null;
  metadataCreatorToolList?: ArchiveNestedMetadataCreatorToolListUpdateInput | null;
  metadataRatingList?: ArchiveNestedMetadataRatingListUpdateInput | null;
  metadataXmpFileStampsList?: ArchiveNestedMetadataXmpFileStampsListUpdateInput | null;
  metadataManifestList?: ArchiveNestedMetadataManifestListUpdateInput | null;
  metadataXmpHistoryList?: ArchiveNestedMetadataXmpHistoryListUpdateInput | null;
  metadataCertificateList?: ArchiveNestedMetadataCertificateListUpdateInput | null;
  metadataMarkedList?: ArchiveNestedMetadataMarkedListUpdateInput | null;
  metadataOwnerList?: ArchiveNestedMetadataOwnerListUpdateInput | null;
  metadataUsageTermsList?: ArchiveNestedMetadataUsageTermsListUpdateInput | null;
  metadataWebStatementList?: ArchiveNestedMetadataWebStatementListUpdateInput | null;
}

/**
 * Filters the "Archive" nodes by specifying some conditions
 */
export interface ArchiveWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  archiveId?: number | null;
  archiveId_not?: number | null;
  archiveId_is_null?: boolean | null;
  archiveId_in?: (number | null)[] | null;
  archiveId_not_in?: (number | null)[] | null;
  archiveId_lt?: number | null;
  archiveId_lte?: number | null;
  archiveId_gt?: number | null;
  archiveId_gte?: number | null;
  name?: ArchiveKeyType | null;
  name_not?: ArchiveKeyType | null;
  name_is_null?: boolean | null;
  name_in?: (ArchiveKeyType | null)[] | null;
  name_not_in?: (ArchiveKeyType | null)[] | null;
  label?: string | null;
  label_not?: string | null;
  label_is_null?: boolean | null;
  label_in?: (string | null)[] | null;
  label_not_in?: (string | null)[] | null;
  label_contains?: string | null;
  label_not_contains?: string | null;
  label_starts_with?: string | null;
  label_not_starts_with?: string | null;
  label_ends_with?: string | null;
  label_not_ends_with?: string | null;
  label_lt?: string | null;
  label_lte?: string | null;
  label_gt?: string | null;
  label_gte?: string | null;
  type?: ArchiveType | null;
  type_not?: ArchiveType | null;
  type_is_null?: boolean | null;
  type_in?: (ArchiveType | null)[] | null;
  type_not_in?: (ArchiveType | null)[] | null;
  hasCategories?: boolean | null;
  hasCategories_not?: boolean | null;
  hasCategories_is_null?: boolean | null;
  hasCategories_in?: (boolean | null)[] | null;
  hasCategories_not_in?: (boolean | null)[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  archiveAgencies_some?: ArchiveAgencyWhereInput | null;
  categories_some?: CategoryWhereInput | null;
  deliverySubjects_some?: DeliverySubjectWhereInput | null;
  gcImportSetting?: GcImportSettingWhereInput | null;
  gcImportSetting_is_null?: boolean | null;
  importQueues_some?: ImportQueueWhereInput | null;
  photos_some?: PhotoWhereInput | null;
  photoMetadatas_some?: PhotoMetadataWhereInput | null;
  metadataSerialNumberList_some?: MetadataSerialNumberWhereInput | null;
  metadataAttributionNameList_some?: MetadataAttributionNameWhereInput | null;
  metadataAttributionUrlList_some?: MetadataAttributionUrlWhereInput | null;
  metadataLicenseList_some?: MetadataLicenseWhereInput | null;
  metadataMorePermissionsList_some?: MetadataMorePermissionsWhereInput | null;
  metadataPaintBasedCorrectionsList_some?: MetadataPaintBasedCorrectionsWhereInput | null;
  metadataToneCurveList_some?: MetadataToneCurveWhereInput | null;
  metadataCreatorList_some?: MetadataCreatorWhereInput | null;
  metadataDescriptionList_some?: MetadataDescriptionWhereInput | null;
  metadataFormatList_some?: MetadataFormatWhereInput | null;
  metadataPageList_some?: MetadataPageWhereInput | null;
  metadataPublisherList_some?: MetadataPublisherWhereInput | null;
  metadataRightsList_some?: MetadataRightsWhereInput | null;
  metadataSubjectList_some?: MetadataSubjectWhereInput | null;
  metadataTitleList_some?: MetadataTitleWhereInput | null;
  metadataTypeList_some?: MetadataTypeWhereInput | null;
  metadataAccessRightsList_some?: MetadataAccessRightsWhereInput | null;
  metadataAudienceList_some?: MetadataAudienceWhereInput | null;
  metadataValidList_some?: MetadataValidWhereInput | null;
  metadataEquipmentInstitutionList_some?: MetadataEquipmentInstitutionWhereInput | null;
  metadataEquipmentManufacturerList_some?: MetadataEquipmentManufacturerWhereInput | null;
  metadataFileInfoBitsPerPixelList_some?: MetadataFileInfoBitsPerPixelWhereInput | null;
  metadataFileInfoFramesList_some?: MetadataFileInfoFramesWhereInput | null;
  metadataFileInfoPhotometricInterpretationList_some?: MetadataFileInfoPhotometricInterpretationWhereInput | null;
  metadataFileInfoTransferSyntaxList_some?: MetadataFileInfoTransferSyntaxWhereInput | null;
  metadataPatientDobList_some?: MetadataPatientDobWhereInput | null;
  metadataPatientIdList_some?: MetadataPatientIdWhereInput | null;
  metadataPatientNameList_some?: MetadataPatientNameWhereInput | null;
  metadataPatientSexList_some?: MetadataPatientSexWhereInput | null;
  metadataSeriesDateTimeList_some?: MetadataSeriesDateTimeWhereInput | null;
  metadataSeriesDescriptionList_some?: MetadataSeriesDescriptionWhereInput | null;
  metadataSeriesModalityList_some?: MetadataSeriesModalityWhereInput | null;
  metadataSeriesNumberList_some?: MetadataSeriesNumberWhereInput | null;
  metadataStudyDateTimeList_some?: MetadataStudyDateTimeWhereInput | null;
  metadataStudyDescriptionList_some?: MetadataStudyDescriptionWhereInput | null;
  metadataStudyIdList_some?: MetadataStudyIdWhereInput | null;
  metadataStudyPhysicianList_some?: MetadataStudyPhysicianWhereInput | null;
  metadataDateTimeDigitizedList_some?: MetadataDateTimeDigitizedWhereInput | null;
  metadataDateTimeOriginalList_some?: MetadataDateTimeOriginalWhereInput | null;
  metadataGpsLatitudeList_some?: MetadataGpsLatitudeWhereInput | null;
  metadataGpsLongitudeList_some?: MetadataGpsLongitudeWhereInput | null;
  metadataGpsProcessingMethodList_some?: MetadataGpsProcessingMethodWhereInput | null;
  metadataImageUniqueIdList_some?: MetadataImageUniqueIdWhereInput | null;
  metadataIsoSpeedRatingsList_some?: MetadataIsoSpeedRatingsWhereInput | null;
  metadataUserCommentList_some?: MetadataUserCommentWhereInput | null;
  metadataCustomField14List_some?: MetadataCustomField14WhereInput | null;
  metadataCustomField15List_some?: MetadataCustomField15WhereInput | null;
  metadataCustomField16List_some?: MetadataCustomField16WhereInput | null;
  metadataCustomField17List_some?: MetadataCustomField17WhereInput | null;
  metadataCustomField20List_some?: MetadataCustomField20WhereInput | null;
  metadataCustomField5List_some?: MetadataCustomField5WhereInput | null;
  metadataCustomField6List_some?: MetadataCustomField6WhereInput | null;
  metadataCustomField7List_some?: MetadataCustomField7WhereInput | null;
  metadataCustomField9List_some?: MetadataCustomField9WhereInput | null;
  metadataCreatedTimeList_some?: MetadataCreatedTimeWhereInput | null;
  metadataEditStatusList_some?: MetadataEditStatusWhereInput | null;
  metadataFixtureIdentifierList_some?: MetadataFixtureIdentifierWhereInput | null;
  metadataLocalCaptionList_some?: MetadataLocalCaptionWhereInput | null;
  metadataProgramVersionList_some?: MetadataProgramVersionWhereInput | null;
  metadataReferenceDateList_some?: MetadataReferenceDateWhereInput | null;
  metadataReferenceNumberList_some?: MetadataReferenceNumberWhereInput | null;
  metadataReferenceServiceList_some?: MetadataReferenceServiceWhereInput | null;
  metadataReleaseDateList_some?: MetadataReleaseDateWhereInput | null;
  metadataReleaseTimeList_some?: MetadataReleaseTimeWhereInput | null;
  metadataAssetStateList_some?: MetadataAssetStateWhereInput | null;
  metadataClassifyList_some?: MetadataClassifyWhereInput | null;
  metadataContainedInList_some?: MetadataContainedInWhereInput | null;
  metadataContentValueList_some?: MetadataContentValueWhereInput | null;
  metadataDocumentTextList_some?: MetadataDocumentTextWhereInput | null;
  metadataExifList_some?: MetadataExifWhereInput | null;
  metadataHistoryList_some?: MetadataHistoryWhereInput | null;
  metadataImageNotesList_some?: MetadataImageNotesWhereInput | null;
  metadataJobIdList_some?: MetadataJobIdWhereInput | null;
  metadataLinksList_some?: MetadataLinksWhereInput | null;
  metadataMasterDocumentIdList_some?: MetadataMasterDocumentIdWhereInput | null;
  metadataObjectCycleList_some?: MetadataObjectCycleWhereInput | null;
  metadataOwnerIdList_some?: MetadataOwnerIdWhereInput | null;
  metadataShortUniqueIdList_some?: MetadataShortUniqueIdWhereInput | null;
  metadataTransferredByList_some?: MetadataTransferredByWhereInput | null;
  metadataTransferredByEmailList_some?: MetadataTransferredByEmailWhereInput | null;
  metadataTransferredByFullNameList_some?: MetadataTransferredByFullNameWhereInput | null;
  metadataUniqueIdList_some?: MetadataUniqueIdWhereInput | null;
  metadataUploadedByList_some?: MetadataUploadedByWhereInput | null;
  metadataUploadedByFullNameList_some?: MetadataUploadedByFullNameWhereInput | null;
  metadataUploadTimeList_some?: MetadataUploadTimeWhereInput | null;
  metadataUserDefined195List_some?: MetadataUserDefined195WhereInput | null;
  metadataUserDefined237List_some?: MetadataUserDefined237WhereInput | null;
  metadataUserDefined238List_some?: MetadataUserDefined238WhereInput | null;
  metadataUserDefined239List_some?: MetadataUserDefined239WhereInput | null;
  metadataUserDefined242List_some?: MetadataUserDefined242WhereInput | null;
  metadataUserDefined62List_some?: MetadataUserDefined62WhereInput | null;
  metadataCiAdrCityList_some?: MetadataCiAdrCityWhereInput | null;
  metadataCiAdrCtryList_some?: MetadataCiAdrCtryWhereInput | null;
  metadataCiAdrExtadrList_some?: MetadataCiAdrExtadrWhereInput | null;
  metadataCiAdrPcodeList_some?: MetadataCiAdrPcodeWhereInput | null;
  metadataCiAdrRegionList_some?: MetadataCiAdrRegionWhereInput | null;
  metadataCiEmailWorkList_some?: MetadataCiEmailWorkWhereInput | null;
  metadataCiTelWorkList_some?: MetadataCiTelWorkWhereInput | null;
  metadataCiUrlWorkList_some?: MetadataCiUrlWorkWhereInput | null;
  metadataCountryCodeList_some?: MetadataCountryCodeWhereInput | null;
  metadataIntellectualGenreList_some?: MetadataIntellectualGenreWhereInput | null;
  metadataLocationList_some?: MetadataLocationWhereInput | null;
  metadataSceneList_some?: MetadataSceneWhereInput | null;
  metadataSubjectCodeList_some?: MetadataSubjectCodeWhereInput | null;
  metadataAddlModelInfoList_some?: MetadataAddlModelInfoWhereInput | null;
  metadataEventList_some?: MetadataEventWhereInput | null;
  metadataMaxAvailHeightList_some?: MetadataMaxAvailHeightWhereInput | null;
  metadataMaxAvailWidthList_some?: MetadataMaxAvailWidthWhereInput | null;
  metadataModelAgeList_some?: MetadataModelAgeWhereInput | null;
  metadataOrganisationInImageNameList_some?: MetadataOrganisationInImageNameWhereInput | null;
  metadataPersonInImageList_some?: MetadataPersonInImageWhereInput | null;
  metadataAuthorsPositionList_some?: MetadataAuthorsPositionWhereInput | null;
  metadataCaptionWriterList_some?: MetadataCaptionWriterWhereInput | null;
  metadataCategoryList_some?: MetadataCategoryWhereInput | null;
  metadataCityList_some?: MetadataCityWhereInput | null;
  metadataCountryList_some?: MetadataCountryWhereInput | null;
  metadataCreditList_some?: MetadataCreditWhereInput | null;
  metadataDateCreatedList_some?: MetadataDateCreatedWhereInput | null;
  metadataHeadlineList_some?: MetadataHeadlineWhereInput | null;
  metadataIccProfileList_some?: MetadataIccProfileWhereInput | null;
  metadataInstructionsList_some?: MetadataInstructionsWhereInput | null;
  metadataSourceList_some?: MetadataSourceWhereInput | null;
  metadataStateList_some?: MetadataStateWhereInput | null;
  metadataSupplementalCategoriesList_some?: MetadataSupplementalCategoriesWhereInput | null;
  metadataTransmissionReferenceList_some?: MetadataTransmissionReferenceWhereInput | null;
  metadataUrgencyList_some?: MetadataUrgencyWhereInput | null;
  metadataMinorModelAgeDisclosureList_some?: MetadataMinorModelAgeDisclosureWhereInput | null;
  metadataModelReleaseIdList_some?: MetadataModelReleaseIdWhereInput | null;
  metadataModelReleaseStatusList_some?: MetadataModelReleaseStatusWhereInput | null;
  metadataPropertyReleaseIdList_some?: MetadataPropertyReleaseIdWhereInput | null;
  metadataPropertyReleaseStatusList_some?: MetadataPropertyReleaseStatusWhereInput | null;
  metadataVersionList_some?: MetadataVersionWhereInput | null;
  metadataBrandList_some?: MetadataBrandWhereInput | null;
  metadataDivisionList_some?: MetadataDivisionWhereInput | null;
  metadataHeaderList_some?: MetadataHeaderWhereInput | null;
  metadataProductsList_some?: MetadataProductsWhereInput | null;
  metadataProduitsList_some?: MetadataProduitsWhereInput | null;
  metadataPublishingIssueList_some?: MetadataPublishingIssueWhereInput | null;
  metadataPublishingSubjectList_some?: MetadataPublishingSubjectWhereInput | null;
  metadataCompanyList_some?: MetadataCompanyWhereInput | null;
  metadataCompanyShortNameList_some?: MetadataCompanyShortNameWhereInput | null;
  metadataConfidentialityList_some?: MetadataConfidentialityWhereInput | null;
  metadataEditorialVersionList_some?: MetadataEditorialVersionWhereInput | null;
  metadataFileCheckForList_some?: MetadataFileCheckForWhereInput | null;
  metadataFileStageList_some?: MetadataFileStageWhereInput | null;
  metadataFileStatusList_some?: MetadataFileStatusWhereInput | null;
  metadataSentToList_some?: MetadataSentToWhereInput | null;
  metadataTargetVersionList_some?: MetadataTargetVersionWhereInput | null;
  metadataUnitList_some?: MetadataUnitWhereInput | null;
  metadataArchiveChildList_some?: MetadataArchiveChildWhereInput | null;
  metadataArchiveParentList_some?: MetadataArchiveParentWhereInput | null;
  metadataBackupNameList_some?: MetadataBackupNameWhereInput | null;
  metadataBitmapGrayscalePictureList_some?: MetadataBitmapGrayscalePictureWhereInput | null;
  metadataBrandPrismaList_some?: MetadataBrandPrismaWhereInput | null;
  metadataBrandShortNameList_some?: MetadataBrandShortNameWhereInput | null;
  metadataCategoryPrismaList_some?: MetadataCategoryPrismaWhereInput | null;
  metadataContentMediaKindList_some?: MetadataContentMediaKindWhereInput | null;
  metadataCopyrightLayerList_some?: MetadataCopyrightLayerWhereInput | null;
  metadataDeliveryAccountList_some?: MetadataDeliveryAccountWhereInput | null;
  metadataDeliveryCompanyList_some?: MetadataDeliveryCompanyWhereInput | null;
  metadataDeliveryCopyrightList_some?: MetadataDeliveryCopyrightWhereInput | null;
  metadataDeliveryDateFolderList_some?: MetadataDeliveryDateFolderWhereInput | null;
  metadataDeliveryDateTimeList_some?: MetadataDeliveryDateTimeWhereInput | null;
  metadataDeliveryDcCreatorList_some?: MetadataDeliveryDcCreatorWhereInput | null;
  metadataDeliveryDcRightsList_some?: MetadataDeliveryDcRightsWhereInput | null;
  metadataDeliveryFileTypeList_some?: MetadataDeliveryFileTypeWhereInput | null;
  metadataDeliveryFolderList_some?: MetadataDeliveryFolderWhereInput | null;
  metadataDeliveryKindList_some?: MetadataDeliveryKindWhereInput | null;
  metadataDeliveryPathList_some?: MetadataDeliveryPathWhereInput | null;
  metadataDeliveryPersonShownintheImageList_some?: MetadataDeliveryPersonShownintheImageWhereInput | null;
  metadataDeliveryPhotoshopCreditList_some?: MetadataDeliveryPhotoshopCreditWhereInput | null;
  metadataDeliveryPhotoshopSourceList_some?: MetadataDeliveryPhotoshopSourceWhereInput | null;
  metadataDeliveryServiceList_some?: MetadataDeliveryServiceWhereInput | null;
  metadataDeliverySubjectList_some?: MetadataDeliverySubjectWhereInput | null;
  metadataDeliveryUnderSubjectList_some?: MetadataDeliveryUnderSubjectWhereInput | null;
  metadataDepartmentList_some?: MetadataDepartmentWhereInput | null;
  metadataDigitalAssetUrlList_some?: MetadataDigitalAssetUrlWhereInput | null;
  metadataEditionList_some?: MetadataEditionWhereInput | null;
  metadataEnvironnementPhotoList_some?: MetadataEnvironnementPhotoWhereInput | null;
  metadataFabStorageList_some?: MetadataFabStorageWhereInput | null;
  metadataFileTypeList_some?: MetadataFileTypeWhereInput | null;
  metadataHeaderPrismaList_some?: MetadataHeaderPrismaWhereInput | null;
  metadataIdSubjectList_some?: MetadataIdSubjectWhereInput | null;
  metadataIdAssignmentList_some?: MetadataIdAssignmentWhereInput | null;
  metadataIdMediaContentList_some?: MetadataIdMediaContentWhereInput | null;
  metadataIdPhotoPrismaList_some?: MetadataIdPhotoPrismaWhereInput | null;
  metadataIssueList_some?: MetadataIssueWhereInput | null;
  metadataJobProcessingList_some?: MetadataJobProcessingWhereInput | null;
  metadataLayoutPictureEditingList_some?: MetadataLayoutPictureEditingWhereInput | null;
  metadataLayoutStorageList_some?: MetadataLayoutStorageWhereInput | null;
  metadataOriginalColorList_some?: MetadataOriginalColorWhereInput | null;
  metadataOriginalIccProfileList_some?: MetadataOriginalIccProfileWhereInput | null;
  metadataOriginalNameList_some?: MetadataOriginalNameWhereInput | null;
  metadataOriginalWeigthList_some?: MetadataOriginalWeigthWhereInput | null;
  metadataPagePrismaList_some?: MetadataPagePrismaWhereInput | null;
  metadataPhotoStorageList_some?: MetadataPhotoStorageWhereInput | null;
  metadataPrepressPictureEditingList_some?: MetadataPrepressPictureEditingWhereInput | null;
  metadataPriceLevelList_some?: MetadataPriceLevelWhereInput | null;
  metadataPrintingProfileList_some?: MetadataPrintingProfileWhereInput | null;
  metadataPrismaProductionList_some?: MetadataPrismaProductionWhereInput | null;
  metadataProcessHistoryList_some?: MetadataProcessHistoryWhereInput | null;
  metadataProcessParameterList_some?: MetadataProcessParameterWhereInput | null;
  metadataProcessStatusList_some?: MetadataProcessStatusWhereInput | null;
  metadataProductList_some?: MetadataProductWhereInput | null;
  metadataProductShortNameList_some?: MetadataProductShortNameWhereInput | null;
  metadataQualifiedUsePrismaByList_some?: MetadataQualifiedUsePrismaByWhereInput | null;
  metadataQualifiedUsePrismaDateList_some?: MetadataQualifiedUsePrismaDateWhereInput | null;
  metadataQualifiedUsePrismaDurationList_some?: MetadataQualifiedUsePrismaDurationWhereInput | null;
  metadataQualifiedUsePrismaSupportList_some?: MetadataQualifiedUsePrismaSupportWhereInput | null;
  metadataRequiredPermissionList_some?: MetadataRequiredPermissionWhereInput | null;
  metadataResolutionKindList_some?: MetadataResolutionKindWhereInput | null;
  metadataRoyaltyfreeList_some?: MetadataRoyaltyfreeWhereInput | null;
  metadataSectionList_some?: MetadataSectionWhereInput | null;
  metadataSubjectPrismaList_some?: MetadataSubjectPrismaWhereInput | null;
  metadataUndersubjectList_some?: MetadataUndersubjectWhereInput | null;
  metadataUnderUnderSubjectList_some?: MetadataUnderUnderSubjectWhereInput | null;
  metadataUnitShortNameList_some?: MetadataUnitShortNameWhereInput | null;
  metadataWorkflowKindList_some?: MetadataWorkflowKindWhereInput | null;
  metadataMakeList_some?: MetadataMakeWhereInput | null;
  metadataModelList_some?: MetadataModelWhereInput | null;
  metadataCreatorToolList_some?: MetadataCreatorToolWhereInput | null;
  metadataRatingList_some?: MetadataRatingWhereInput | null;
  metadataXmpFileStampsList_some?: MetadataXmpFileStampsWhereInput | null;
  metadataManifestList_some?: MetadataManifestWhereInput | null;
  metadataXmpHistoryList_some?: MetadataXmpHistoryWhereInput | null;
  metadataCertificateList_some?: MetadataCertificateWhereInput | null;
  metadataMarkedList_some?: MetadataMarkedWhereInput | null;
  metadataOwnerList_some?: MetadataOwnerWhereInput | null;
  metadataUsageTermsList_some?: MetadataUsageTermsWhereInput | null;
  metadataWebStatementList_some?: MetadataWebStatementWhereInput | null;
  AND?: ArchiveWhereInput[] | null;
  OR?: ArchiveWhereInput[] | null;
  NOT?: ArchiveWhereInput | null;
}

/**
 * Identifies exactly one "Archive" node with one of these unique values:
 * - _id
 * - id
 * - archiveId
 */
export interface ArchiveWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  archiveId?: number | null;
}

export interface CategoryCreateInput {
  id?: string | null;
  label?: string | null;
  archive: CategoryNestedArchiveCreateInput;
  parent?: CategoryNestedParentCreateInput | null;
  children?: CategoryNestedChildrenCreateInput | null;
  photos?: CategoryNestedPhotosCreateInput | null;
}

export interface CategoryNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: CategoryNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: CategoryNestedUpsertArchiveCreateInput | null;
}

export interface CategoryNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: CategoryNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: CategoryNestedUpsertArchiveUpdateInput | null;
}

export interface CategoryNestedChildrenCreateInput {
  connect?: CategoryWhereUniqueInput[] | null;
  create?: CategoryWithForcedParentCreateInput[] | null;
}

export interface CategoryNestedChildrenUpdateInput {
  connect?: CategoryWhereUniqueInput[] | null;
  disconnect?: CategoryWithForcedParentWhereUniqueInput[] | null;
  update?: CategoryNestedUpdateChildrenUpdateInput[] | null;
  create?: CategoryWithForcedParentCreateInput[] | null;
  upsert?: CategoryNestedUpsertChildrenUpdateInput[] | null;
  delete?: CategoryWithForcedParentWhereUniqueInput[] | null;
}

export interface CategoryNestedParentCreateInput {
  connect?: CategoryWhereUniqueInput | null;
  update?: CategoryNestedUpdateParentCreateInput | null;
  create?: CategoryCreateInput | null;
  upsert?: CategoryNestedUpsertParentCreateInput | null;
}

export interface CategoryNestedParentUpdateInput {
  connect?: CategoryWhereUniqueInput | null;
  update?: CategoryNestedUpdateParentUpdateInput | null;
  create?: CategoryCreateInput | null;
  upsert?: CategoryNestedUpsertParentUpdateInput | null;
  disconnect?: boolean | null;
}

export interface CategoryNestedPhotosCreateInput {
  connect?: PhotoWhereUniqueInput[] | null;
  create?: PhotoWithForcedCategoryCreateInput[] | null;
}

export interface CategoryNestedPhotosUpdateInput {
  connect?: PhotoWhereUniqueInput[] | null;
  disconnect?: PhotoWhereUniqueInput[] | null;
  update?: CategoryNestedUpdatePhotosUpdateInput[] | null;
  create?: PhotoWithForcedCategoryCreateInput[] | null;
  upsert?: CategoryNestedUpsertPhotosUpdateInput[] | null;
  delete?: PhotoWhereUniqueInput[] | null;
}

export interface CategoryNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface CategoryNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface CategoryNestedUpdateChildrenUpdateInput {
  where: CategoryWithOptionalParentWhereUniqueInput;
  data: CategoryWithForcedParentUpdateInput;
}

export interface CategoryNestedUpdateParentCreateInput {
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateInput;
}

export interface CategoryNestedUpdateParentUpdateInput {
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateInput;
}

export interface CategoryNestedUpdatePhotosUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoWithForcedCategoryUpdateInput;
}

export interface CategoryNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface CategoryNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface CategoryNestedUpsertChildrenUpdateInput {
  where: CategoryWithOptionalParentWhereUniqueInput;
  update: CategoryWithForcedParentUpdateInput;
  create: CategoryWithForcedParentCreateInput;
}

export interface CategoryNestedUpsertParentCreateInput {
  where: CategoryWhereUniqueInput;
  update: CategoryUpdateInput;
  create: CategoryCreateInput;
}

export interface CategoryNestedUpsertParentUpdateInput {
  where: CategoryWhereUniqueInput;
  update: CategoryUpdateInput;
  create: CategoryCreateInput;
}

export interface CategoryNestedUpsertPhotosUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoWithForcedCategoryUpdateInput;
  create: PhotoWithForcedCategoryCreateInput;
}

export interface CategoryUpdateInput {
  label?: string | null;
  archive?: CategoryNestedArchiveUpdateInput | null;
  parent?: CategoryNestedParentUpdateInput | null;
  children?: CategoryNestedChildrenUpdateInput | null;
  photos?: CategoryNestedPhotosUpdateInput | null;
}

/**
 * Filters the "Category" nodes by specifying some conditions
 */
export interface CategoryWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  label?: string | null;
  label_not?: string | null;
  label_is_null?: boolean | null;
  label_in?: (string | null)[] | null;
  label_not_in?: (string | null)[] | null;
  label_contains?: string | null;
  label_not_contains?: string | null;
  label_starts_with?: string | null;
  label_not_starts_with?: string | null;
  label_ends_with?: string | null;
  label_not_ends_with?: string | null;
  label_lt?: string | null;
  label_lte?: string | null;
  label_gt?: string | null;
  label_gte?: string | null;
  archive?: ArchiveWhereInput | null;
  parent?: CategoryWhereInput | null;
  parent_is_null?: boolean | null;
  children_some?: CategoryWhereInput | null;
  photos_some?: PhotoWhereInput | null;
  AND?: CategoryWhereInput[] | null;
  OR?: CategoryWhereInput[] | null;
  NOT?: CategoryWhereInput | null;
}

/**
 * Identifies exactly one "Category" node with one of these unique combinations of values:
 * - _id
 * - id
 * - archive, label, parent
 */
export interface CategoryWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  archive?: ArchiveWhereUniqueInput | null;
  label?: string | null;
  parent?: CategoryWhereUniqueInput | null;
}

export interface CategoryWithForcedArchiveCreateInput {
  id?: string | null;
  label?: string | null;
  parent?: CategoryNestedParentCreateInput | null;
  children?: CategoryNestedChildrenCreateInput | null;
  photos?: CategoryNestedPhotosCreateInput | null;
}

export interface CategoryWithForcedArchiveUpdateInput {
  label?: string | null;
  parent?: CategoryNestedParentUpdateInput | null;
  children?: CategoryNestedChildrenUpdateInput | null;
  photos?: CategoryNestedPhotosUpdateInput | null;
}

/**
 * Given a forced "archive", identifies exactly one "Category" node.
 */
export interface CategoryWithForcedArchiveWhereUniqueInput {
  label: string;
  parent: CategoryWhereUniqueInput;
}

export interface CategoryWithForcedParentCreateInput {
  id?: string | null;
  label?: string | null;
  archive: CategoryNestedArchiveCreateInput;
  children?: CategoryNestedChildrenCreateInput | null;
  photos?: CategoryNestedPhotosCreateInput | null;
}

export interface CategoryWithForcedParentUpdateInput {
  label?: string | null;
  archive?: CategoryNestedArchiveUpdateInput | null;
  children?: CategoryNestedChildrenUpdateInput | null;
  photos?: CategoryNestedPhotosUpdateInput | null;
}

/**
 * Given a forced "parent", identifies exactly one "Category" node.
 */
export interface CategoryWithForcedParentWhereUniqueInput {
  archive: ArchiveWhereUniqueInput;
  label: string;
}

/**
 * Given a known "archive", identifies exactly one "Category" node with one of these unique combinations of values:
 * - _id
 * - id
 * - archive (optional), label, parent
 */
export interface CategoryWithOptionalArchiveWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  archive?: ArchiveWhereUniqueInput | null;
  label?: string | null;
  parent?: CategoryWhereUniqueInput | null;
}

/**
 * Given a known "parent", identifies exactly one "Category" node with one of these unique combinations of values:
 * - _id
 * - id
 * - archive, label, parent (optional)
 */
export interface CategoryWithOptionalParentWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  archive?: ArchiveWhereUniqueInput | null;
  label?: string | null;
  parent?: CategoryWhereUniqueInput | null;
}

export interface DeliverySubjectCreateInput {
  name: string;
  agency: string;
  archive: DeliverySubjectNestedArchiveCreateInput;
  photos?: DeliverySubjectNestedPhotosCreateInput | null;
}

export interface DeliverySubjectNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: DeliverySubjectNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: DeliverySubjectNestedUpsertArchiveCreateInput | null;
}

export interface DeliverySubjectNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: DeliverySubjectNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: DeliverySubjectNestedUpsertArchiveUpdateInput | null;
}

export interface DeliverySubjectNestedPhotosCreateInput {
  connect?: PhotoWhereUniqueInput[] | null;
  create?: PhotoWithForcedDeliverySubjectCreateInput[] | null;
}

export interface DeliverySubjectNestedPhotosUpdateInput {
  connect?: PhotoWhereUniqueInput[] | null;
  disconnect?: PhotoWhereUniqueInput[] | null;
  update?: DeliverySubjectNestedUpdatePhotosUpdateInput[] | null;
  create?: PhotoWithForcedDeliverySubjectCreateInput[] | null;
  upsert?: DeliverySubjectNestedUpsertPhotosUpdateInput[] | null;
  delete?: PhotoWhereUniqueInput[] | null;
}

export interface DeliverySubjectNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface DeliverySubjectNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface DeliverySubjectNestedUpdatePhotosUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoWithForcedDeliverySubjectUpdateInput;
}

export interface DeliverySubjectNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface DeliverySubjectNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface DeliverySubjectNestedUpsertPhotosUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoWithForcedDeliverySubjectUpdateInput;
  create: PhotoWithForcedDeliverySubjectCreateInput;
}

export interface DeliverySubjectUpdateInput {
  name?: string | null;
  agency?: string | null;
  archive?: DeliverySubjectNestedArchiveUpdateInput | null;
  photos?: DeliverySubjectNestedPhotosUpdateInput | null;
}

/**
 * Filters the "DeliverySubject" nodes by specifying some conditions
 */
export interface DeliverySubjectWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  agency?: string | null;
  agency_not?: string | null;
  agency_in?: string[] | null;
  agency_not_in?: string[] | null;
  agency_contains?: string | null;
  agency_not_contains?: string | null;
  agency_starts_with?: string | null;
  agency_not_starts_with?: string | null;
  agency_ends_with?: string | null;
  agency_not_ends_with?: string | null;
  agency_lt?: string | null;
  agency_lte?: string | null;
  agency_gt?: string | null;
  agency_gte?: string | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  archive?: ArchiveWhereInput | null;
  photos_some?: PhotoWhereInput | null;
  AND?: DeliverySubjectWhereInput[] | null;
  OR?: DeliverySubjectWhereInput[] | null;
  NOT?: DeliverySubjectWhereInput | null;
}

/**
 * Identifies exactly one "DeliverySubject" node with one of these unique combinations of values:
 * - _id
 * - agency, archive, name
 */
export interface DeliverySubjectWhereUniqueInput {
  _id?: number | null;
  agency?: string | null;
  archive?: ArchiveWhereUniqueInput | null;
  name?: string | null;
}

export interface DeliverySubjectWithForcedArchiveCreateInput {
  name: string;
  agency: string;
  photos?: DeliverySubjectNestedPhotosCreateInput | null;
}

export interface DeliverySubjectWithForcedArchiveUpdateInput {
  name?: string | null;
  agency?: string | null;
  photos?: DeliverySubjectNestedPhotosUpdateInput | null;
}

/**
 * Given a forced "archive", identifies exactly one "DeliverySubject" node.
 */
export interface DeliverySubjectWithForcedArchiveWhereUniqueInput {
  agency: string;
  name: string;
}

/**
 * Given a known "archive", identifies exactly one "DeliverySubject" node with one of these unique combinations of values:
 * - _id
 * - agency, archive (optional), name
 */
export interface DeliverySubjectWithOptionalArchiveWhereUniqueInput {
  _id?: number | null;
  agency?: string | null;
  archive?: ArchiveWhereUniqueInput | null;
  name?: string | null;
}

/**
 * Filters the "GcImportSetting" nodes by specifying some conditions
 */
export interface GcImportSettingWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  period?: number | null;
  period_not?: number | null;
  period_is_null?: boolean | null;
  period_in?: (number | null)[] | null;
  period_not_in?: (number | null)[] | null;
  period_lt?: number | null;
  period_lte?: number | null;
  period_gt?: number | null;
  period_gte?: number | null;
  enabled?: boolean | null;
  enabled_not?: boolean | null;
  enabled_is_null?: boolean | null;
  enabled_in?: (boolean | null)[] | null;
  enabled_not_in?: (boolean | null)[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  archive?: ArchiveWhereInput | null;
  AND?: GcImportSettingWhereInput[] | null;
  OR?: GcImportSettingWhereInput[] | null;
  NOT?: GcImportSettingWhereInput | null;
}

/**
 * Identifies exactly one "GcImportSetting" node with one of these unique values:
 * - id
 * - archive
 */
export interface GcImportSettingWhereUniqueInput {
  id?: number | null;
  archive?: ArchiveWhereUniqueInput | null;
}

export interface GcImportSettingWithForcedArchiveCreateInput {
  period?: number | null;
  enabled?: boolean | null;
}

export interface GcImportSettingWithForcedArchiveUpdateInput {
  period?: number | null;
  enabled?: boolean | null;
}

/**
 * Given a known "archive", identifies exactly one "GcImportSetting" node with one of these unique values:
 * - id
 * - archive (optional)
 */
export interface GcImportSettingWithOptionalArchiveWhereUniqueInput {
  id?: number | null;
  archive?: ArchiveWhereUniqueInput | null;
}

export interface ImportQueueNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: ImportQueueNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: ImportQueueNestedUpsertArchiveCreateInput | null;
}

export interface ImportQueueNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: ImportQueueNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: ImportQueueNestedUpsertArchiveUpdateInput | null;
  disconnect?: boolean | null;
}

export interface ImportQueueNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: ImportQueueNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: ImportQueueNestedUpsertPhotoCreateInput | null;
}

export interface ImportQueueNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: ImportQueueNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: ImportQueueNestedUpsertPhotoUpdateInput | null;
  disconnect?: boolean | null;
}

export interface ImportQueueNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface ImportQueueNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface ImportQueueNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface ImportQueueNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface ImportQueueNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface ImportQueueNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface ImportQueueNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface ImportQueueNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "ImportQueue" nodes by specifying some conditions
 */
export interface ImportQueueWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  message?: string | null;
  message_not?: string | null;
  message_is_null?: boolean | null;
  message_in?: (string | null)[] | null;
  message_not_in?: (string | null)[] | null;
  message_contains?: string | null;
  message_not_contains?: string | null;
  message_starts_with?: string | null;
  message_not_starts_with?: string | null;
  message_ends_with?: string | null;
  message_not_ends_with?: string | null;
  message_lt?: string | null;
  message_lte?: string | null;
  message_gt?: string | null;
  message_gte?: string | null;
  s3key?: string | null;
  s3key_not?: string | null;
  s3key_in?: string[] | null;
  s3key_not_in?: string[] | null;
  s3key_contains?: string | null;
  s3key_not_contains?: string | null;
  s3key_starts_with?: string | null;
  s3key_not_starts_with?: string | null;
  s3key_ends_with?: string | null;
  s3key_not_ends_with?: string | null;
  s3key_lt?: string | null;
  s3key_lte?: string | null;
  s3key_gt?: string | null;
  s3key_gte?: string | null;
  filename?: string | null;
  filename_not?: string | null;
  filename_in?: string[] | null;
  filename_not_in?: string[] | null;
  filename_contains?: string | null;
  filename_not_contains?: string | null;
  filename_starts_with?: string | null;
  filename_not_starts_with?: string | null;
  filename_ends_with?: string | null;
  filename_not_ends_with?: string | null;
  filename_lt?: string | null;
  filename_lte?: string | null;
  filename_gt?: string | null;
  filename_gte?: string | null;
  agency?: string | null;
  agency_not?: string | null;
  agency_is_null?: boolean | null;
  agency_in?: (string | null)[] | null;
  agency_not_in?: (string | null)[] | null;
  agency_contains?: string | null;
  agency_not_contains?: string | null;
  agency_starts_with?: string | null;
  agency_not_starts_with?: string | null;
  agency_ends_with?: string | null;
  agency_not_ends_with?: string | null;
  agency_lt?: string | null;
  agency_lte?: string | null;
  agency_gt?: string | null;
  agency_gte?: string | null;
  subject?: string | null;
  subject_not?: string | null;
  subject_is_null?: boolean | null;
  subject_in?: (string | null)[] | null;
  subject_not_in?: (string | null)[] | null;
  subject_contains?: string | null;
  subject_not_contains?: string | null;
  subject_starts_with?: string | null;
  subject_not_starts_with?: string | null;
  subject_ends_with?: string | null;
  subject_not_ends_with?: string | null;
  subject_lt?: string | null;
  subject_lte?: string | null;
  subject_gt?: string | null;
  subject_gte?: string | null;
  type?: string | null;
  type_not?: string | null;
  type_is_null?: boolean | null;
  type_in?: (string | null)[] | null;
  type_not_in?: (string | null)[] | null;
  type_contains?: string | null;
  type_not_contains?: string | null;
  type_starts_with?: string | null;
  type_not_starts_with?: string | null;
  type_ends_with?: string | null;
  type_not_ends_with?: string | null;
  type_lt?: string | null;
  type_lte?: string | null;
  type_gt?: string | null;
  type_gte?: string | null;
  size?: number | null;
  size_not?: number | null;
  size_is_null?: boolean | null;
  size_in?: (number | null)[] | null;
  size_not_in?: (number | null)[] | null;
  size_lt?: number | null;
  size_lte?: number | null;
  size_gt?: number | null;
  size_gte?: number | null;
  retry?: number | null;
  retry_not?: number | null;
  retry_is_null?: boolean | null;
  retry_in?: (number | null)[] | null;
  retry_not_in?: (number | null)[] | null;
  retry_lt?: number | null;
  retry_lte?: number | null;
  retry_gt?: number | null;
  retry_gte?: number | null;
  duration?: number | null;
  duration_not?: number | null;
  duration_is_null?: boolean | null;
  duration_in?: (number | null)[] | null;
  duration_not_in?: (number | null)[] | null;
  duration_lt?: number | null;
  duration_lte?: number | null;
  duration_gt?: number | null;
  duration_gte?: number | null;
  status?: ImportQueueStatusType | null;
  status_not?: ImportQueueStatusType | null;
  status_in?: ImportQueueStatusType[] | null;
  status_not_in?: ImportQueueStatusType[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  importedAt?: GraphQL$DateTime | null;
  importedAt_not?: GraphQL$DateTime | null;
  importedAt_is_null?: boolean | null;
  importedAt_in?: (GraphQL$DateTime | null)[] | null;
  importedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  importedAt_lt?: GraphQL$DateTime | null;
  importedAt_lte?: GraphQL$DateTime | null;
  importedAt_gt?: GraphQL$DateTime | null;
  importedAt_gte?: GraphQL$DateTime | null;
  archive?: ArchiveWhereInput | null;
  archive_is_null?: boolean | null;
  photo?: PhotoWhereInput | null;
  photo_is_null?: boolean | null;
  AND?: ImportQueueWhereInput[] | null;
  OR?: ImportQueueWhereInput[] | null;
  NOT?: ImportQueueWhereInput | null;
}

/**
 * Identifies exactly one "ImportQueue" node with one of these unique values:
 * - _id
 * - s3key
 * - photo
 */
export interface ImportQueueWhereUniqueInput {
  _id?: number | null;
  s3key?: string | null;
  photo?: PhotoWhereUniqueInput | null;
}

export interface ImportQueueWithForcedArchiveCreateInput {
  message?: string | null;
  s3key: string;
  filename: string;
  agency?: string | null;
  subject?: string | null;
  type?: string | null;
  size?: number | null;
  retry?: number | null;
  duration?: number | null;
  status: ImportQueueStatusType;
  photo?: ImportQueueNestedPhotoCreateInput | null;
}

export interface ImportQueueWithForcedArchiveUpdateInput {
  message?: string | null;
  s3key?: string | null;
  filename?: string | null;
  agency?: string | null;
  subject?: string | null;
  type?: string | null;
  size?: number | null;
  retry?: number | null;
  duration?: number | null;
  status?: ImportQueueStatusType | null;
  photo?: ImportQueueNestedPhotoUpdateInput | null;
}

export interface ImportQueueWithForcedPhotoCreateInput {
  message?: string | null;
  s3key: string;
  filename: string;
  agency?: string | null;
  subject?: string | null;
  type?: string | null;
  size?: number | null;
  retry?: number | null;
  duration?: number | null;
  status: ImportQueueStatusType;
  archive?: ImportQueueNestedArchiveCreateInput | null;
}

export interface ImportQueueWithForcedPhotoUpdateInput {
  message?: string | null;
  s3key?: string | null;
  filename?: string | null;
  agency?: string | null;
  subject?: string | null;
  type?: string | null;
  size?: number | null;
  retry?: number | null;
  duration?: number | null;
  status?: ImportQueueStatusType | null;
  archive?: ImportQueueNestedArchiveUpdateInput | null;
}

/**
 * Given a known "photo", identifies exactly one "ImportQueue" node with one of these unique values:
 * - _id
 * - s3key
 * - photo (optional)
 */
export interface ImportQueueWithOptionalPhotoWhereUniqueInput {
  _id?: number | null;
  s3key?: string | null;
  photo?: PhotoWhereUniqueInput | null;
}

export interface MetadataAccessRightsNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataAccessRightsNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataAccessRightsNestedUpsertArchiveCreateInput | null;
}

export interface MetadataAccessRightsNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataAccessRightsNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataAccessRightsNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataAccessRightsNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataAccessRightsNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataAccessRightsNestedUpsertMetadataCreateInput | null;
}

export interface MetadataAccessRightsNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataAccessRightsNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataAccessRightsNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataAccessRightsNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataAccessRightsNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataAccessRightsNestedUpsertPhotoCreateInput | null;
}

export interface MetadataAccessRightsNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataAccessRightsNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataAccessRightsNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataAccessRightsNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataAccessRightsNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataAccessRightsNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataAccessRightsNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataAccessRightsNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataAccessRightsNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataAccessRightsNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataAccessRightsNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataAccessRightsNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataAccessRightsNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataAccessRightsNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataAccessRightsNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataAccessRights" nodes by specifying some conditions
 */
export interface MetadataAccessRightsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataAccessRightsWhereInput[] | null;
  OR?: MetadataAccessRightsWhereInput[] | null;
  NOT?: MetadataAccessRightsWhereInput | null;
}

/**
 * Identifies exactly one "MetadataAccessRights" node.
 */
export interface MetadataAccessRightsWhereUniqueInput {
  id: number;
}

export interface MetadataAccessRightsWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataAccessRightsNestedPhotoCreateInput;
  metadata: MetadataAccessRightsNestedMetadataCreateInput;
}

export interface MetadataAccessRightsWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataAccessRightsNestedPhotoUpdateInput | null;
  metadata?: MetadataAccessRightsNestedMetadataUpdateInput | null;
}

export interface MetadataAccessRightsWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataAccessRightsNestedPhotoCreateInput;
  archive: MetadataAccessRightsNestedArchiveCreateInput;
}

export interface MetadataAccessRightsWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataAccessRightsNestedPhotoUpdateInput | null;
  archive?: MetadataAccessRightsNestedArchiveUpdateInput | null;
}

export interface MetadataAccessRightsWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataAccessRightsNestedMetadataCreateInput;
  archive: MetadataAccessRightsNestedArchiveCreateInput;
}

export interface MetadataAccessRightsWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataAccessRightsNestedMetadataUpdateInput | null;
  archive?: MetadataAccessRightsNestedArchiveUpdateInput | null;
}

export interface MetadataAddlModelInfoNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataAddlModelInfoNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataAddlModelInfoNestedUpsertArchiveCreateInput | null;
}

export interface MetadataAddlModelInfoNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataAddlModelInfoNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataAddlModelInfoNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataAddlModelInfoNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataAddlModelInfoNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataAddlModelInfoNestedUpsertMetadataCreateInput | null;
}

export interface MetadataAddlModelInfoNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataAddlModelInfoNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataAddlModelInfoNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataAddlModelInfoNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataAddlModelInfoNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataAddlModelInfoNestedUpsertPhotoCreateInput | null;
}

export interface MetadataAddlModelInfoNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataAddlModelInfoNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataAddlModelInfoNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataAddlModelInfoNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataAddlModelInfoNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataAddlModelInfoNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataAddlModelInfoNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataAddlModelInfoNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataAddlModelInfoNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataAddlModelInfoNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataAddlModelInfoNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataAddlModelInfoNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataAddlModelInfoNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataAddlModelInfoNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataAddlModelInfoNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataAddlModelInfo" nodes by specifying some conditions
 */
export interface MetadataAddlModelInfoWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataAddlModelInfoWhereInput[] | null;
  OR?: MetadataAddlModelInfoWhereInput[] | null;
  NOT?: MetadataAddlModelInfoWhereInput | null;
}

/**
 * Identifies exactly one "MetadataAddlModelInfo" node.
 */
export interface MetadataAddlModelInfoWhereUniqueInput {
  id: number;
}

export interface MetadataAddlModelInfoWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataAddlModelInfoNestedPhotoCreateInput;
  metadata: MetadataAddlModelInfoNestedMetadataCreateInput;
}

export interface MetadataAddlModelInfoWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataAddlModelInfoNestedPhotoUpdateInput | null;
  metadata?: MetadataAddlModelInfoNestedMetadataUpdateInput | null;
}

export interface MetadataAddlModelInfoWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataAddlModelInfoNestedPhotoCreateInput;
  archive: MetadataAddlModelInfoNestedArchiveCreateInput;
}

export interface MetadataAddlModelInfoWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataAddlModelInfoNestedPhotoUpdateInput | null;
  archive?: MetadataAddlModelInfoNestedArchiveUpdateInput | null;
}

export interface MetadataAddlModelInfoWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataAddlModelInfoNestedMetadataCreateInput;
  archive: MetadataAddlModelInfoNestedArchiveCreateInput;
}

export interface MetadataAddlModelInfoWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataAddlModelInfoNestedMetadataUpdateInput | null;
  archive?: MetadataAddlModelInfoNestedArchiveUpdateInput | null;
}

export interface MetadataArchiveChildNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataArchiveChildNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataArchiveChildNestedUpsertArchiveCreateInput | null;
}

export interface MetadataArchiveChildNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataArchiveChildNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataArchiveChildNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataArchiveChildNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataArchiveChildNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataArchiveChildNestedUpsertMetadataCreateInput | null;
}

export interface MetadataArchiveChildNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataArchiveChildNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataArchiveChildNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataArchiveChildNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataArchiveChildNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataArchiveChildNestedUpsertPhotoCreateInput | null;
}

export interface MetadataArchiveChildNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataArchiveChildNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataArchiveChildNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataArchiveChildNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataArchiveChildNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataArchiveChildNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataArchiveChildNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataArchiveChildNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataArchiveChildNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataArchiveChildNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataArchiveChildNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataArchiveChildNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataArchiveChildNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataArchiveChildNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataArchiveChildNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataArchiveChild" nodes by specifying some conditions
 */
export interface MetadataArchiveChildWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataArchiveChildWhereInput[] | null;
  OR?: MetadataArchiveChildWhereInput[] | null;
  NOT?: MetadataArchiveChildWhereInput | null;
}

/**
 * Identifies exactly one "MetadataArchiveChild" node.
 */
export interface MetadataArchiveChildWhereUniqueInput {
  id: number;
}

export interface MetadataArchiveChildWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataArchiveChildNestedPhotoCreateInput;
  metadata: MetadataArchiveChildNestedMetadataCreateInput;
}

export interface MetadataArchiveChildWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataArchiveChildNestedPhotoUpdateInput | null;
  metadata?: MetadataArchiveChildNestedMetadataUpdateInput | null;
}

export interface MetadataArchiveChildWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataArchiveChildNestedPhotoCreateInput;
  archive: MetadataArchiveChildNestedArchiveCreateInput;
}

export interface MetadataArchiveChildWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataArchiveChildNestedPhotoUpdateInput | null;
  archive?: MetadataArchiveChildNestedArchiveUpdateInput | null;
}

export interface MetadataArchiveChildWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataArchiveChildNestedMetadataCreateInput;
  archive: MetadataArchiveChildNestedArchiveCreateInput;
}

export interface MetadataArchiveChildWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataArchiveChildNestedMetadataUpdateInput | null;
  archive?: MetadataArchiveChildNestedArchiveUpdateInput | null;
}

export interface MetadataArchiveParentNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataArchiveParentNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataArchiveParentNestedUpsertArchiveCreateInput | null;
}

export interface MetadataArchiveParentNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataArchiveParentNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataArchiveParentNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataArchiveParentNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataArchiveParentNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataArchiveParentNestedUpsertMetadataCreateInput | null;
}

export interface MetadataArchiveParentNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataArchiveParentNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataArchiveParentNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataArchiveParentNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataArchiveParentNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataArchiveParentNestedUpsertPhotoCreateInput | null;
}

export interface MetadataArchiveParentNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataArchiveParentNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataArchiveParentNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataArchiveParentNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataArchiveParentNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataArchiveParentNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataArchiveParentNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataArchiveParentNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataArchiveParentNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataArchiveParentNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataArchiveParentNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataArchiveParentNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataArchiveParentNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataArchiveParentNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataArchiveParentNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataArchiveParent" nodes by specifying some conditions
 */
export interface MetadataArchiveParentWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataArchiveParentWhereInput[] | null;
  OR?: MetadataArchiveParentWhereInput[] | null;
  NOT?: MetadataArchiveParentWhereInput | null;
}

/**
 * Identifies exactly one "MetadataArchiveParent" node.
 */
export interface MetadataArchiveParentWhereUniqueInput {
  id: number;
}

export interface MetadataArchiveParentWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataArchiveParentNestedPhotoCreateInput;
  metadata: MetadataArchiveParentNestedMetadataCreateInput;
}

export interface MetadataArchiveParentWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataArchiveParentNestedPhotoUpdateInput | null;
  metadata?: MetadataArchiveParentNestedMetadataUpdateInput | null;
}

export interface MetadataArchiveParentWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataArchiveParentNestedPhotoCreateInput;
  archive: MetadataArchiveParentNestedArchiveCreateInput;
}

export interface MetadataArchiveParentWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataArchiveParentNestedPhotoUpdateInput | null;
  archive?: MetadataArchiveParentNestedArchiveUpdateInput | null;
}

export interface MetadataArchiveParentWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataArchiveParentNestedMetadataCreateInput;
  archive: MetadataArchiveParentNestedArchiveCreateInput;
}

export interface MetadataArchiveParentWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataArchiveParentNestedMetadataUpdateInput | null;
  archive?: MetadataArchiveParentNestedArchiveUpdateInput | null;
}

export interface MetadataAssetStateNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataAssetStateNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataAssetStateNestedUpsertArchiveCreateInput | null;
}

export interface MetadataAssetStateNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataAssetStateNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataAssetStateNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataAssetStateNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataAssetStateNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataAssetStateNestedUpsertMetadataCreateInput | null;
}

export interface MetadataAssetStateNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataAssetStateNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataAssetStateNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataAssetStateNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataAssetStateNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataAssetStateNestedUpsertPhotoCreateInput | null;
}

export interface MetadataAssetStateNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataAssetStateNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataAssetStateNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataAssetStateNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataAssetStateNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataAssetStateNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataAssetStateNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataAssetStateNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataAssetStateNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataAssetStateNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataAssetStateNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataAssetStateNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataAssetStateNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataAssetStateNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataAssetStateNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataAssetState" nodes by specifying some conditions
 */
export interface MetadataAssetStateWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataAssetStateWhereInput[] | null;
  OR?: MetadataAssetStateWhereInput[] | null;
  NOT?: MetadataAssetStateWhereInput | null;
}

/**
 * Identifies exactly one "MetadataAssetState" node.
 */
export interface MetadataAssetStateWhereUniqueInput {
  id: number;
}

export interface MetadataAssetStateWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataAssetStateNestedPhotoCreateInput;
  metadata: MetadataAssetStateNestedMetadataCreateInput;
}

export interface MetadataAssetStateWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataAssetStateNestedPhotoUpdateInput | null;
  metadata?: MetadataAssetStateNestedMetadataUpdateInput | null;
}

export interface MetadataAssetStateWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataAssetStateNestedPhotoCreateInput;
  archive: MetadataAssetStateNestedArchiveCreateInput;
}

export interface MetadataAssetStateWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataAssetStateNestedPhotoUpdateInput | null;
  archive?: MetadataAssetStateNestedArchiveUpdateInput | null;
}

export interface MetadataAssetStateWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataAssetStateNestedMetadataCreateInput;
  archive: MetadataAssetStateNestedArchiveCreateInput;
}

export interface MetadataAssetStateWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataAssetStateNestedMetadataUpdateInput | null;
  archive?: MetadataAssetStateNestedArchiveUpdateInput | null;
}

export interface MetadataAttributionNameNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataAttributionNameNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataAttributionNameNestedUpsertArchiveCreateInput | null;
}

export interface MetadataAttributionNameNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataAttributionNameNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataAttributionNameNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataAttributionNameNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataAttributionNameNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataAttributionNameNestedUpsertMetadataCreateInput | null;
}

export interface MetadataAttributionNameNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataAttributionNameNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataAttributionNameNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataAttributionNameNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataAttributionNameNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataAttributionNameNestedUpsertPhotoCreateInput | null;
}

export interface MetadataAttributionNameNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataAttributionNameNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataAttributionNameNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataAttributionNameNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataAttributionNameNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataAttributionNameNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataAttributionNameNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataAttributionNameNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataAttributionNameNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataAttributionNameNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataAttributionNameNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataAttributionNameNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataAttributionNameNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataAttributionNameNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataAttributionNameNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataAttributionName" nodes by specifying some conditions
 */
export interface MetadataAttributionNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataAttributionNameWhereInput[] | null;
  OR?: MetadataAttributionNameWhereInput[] | null;
  NOT?: MetadataAttributionNameWhereInput | null;
}

/**
 * Identifies exactly one "MetadataAttributionName" node.
 */
export interface MetadataAttributionNameWhereUniqueInput {
  id: number;
}

export interface MetadataAttributionNameWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataAttributionNameNestedPhotoCreateInput;
  metadata: MetadataAttributionNameNestedMetadataCreateInput;
}

export interface MetadataAttributionNameWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataAttributionNameNestedPhotoUpdateInput | null;
  metadata?: MetadataAttributionNameNestedMetadataUpdateInput | null;
}

export interface MetadataAttributionNameWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataAttributionNameNestedPhotoCreateInput;
  archive: MetadataAttributionNameNestedArchiveCreateInput;
}

export interface MetadataAttributionNameWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataAttributionNameNestedPhotoUpdateInput | null;
  archive?: MetadataAttributionNameNestedArchiveUpdateInput | null;
}

export interface MetadataAttributionNameWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataAttributionNameNestedMetadataCreateInput;
  archive: MetadataAttributionNameNestedArchiveCreateInput;
}

export interface MetadataAttributionNameWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataAttributionNameNestedMetadataUpdateInput | null;
  archive?: MetadataAttributionNameNestedArchiveUpdateInput | null;
}

export interface MetadataAttributionUrlNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataAttributionUrlNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataAttributionUrlNestedUpsertArchiveCreateInput | null;
}

export interface MetadataAttributionUrlNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataAttributionUrlNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataAttributionUrlNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataAttributionUrlNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataAttributionUrlNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataAttributionUrlNestedUpsertMetadataCreateInput | null;
}

export interface MetadataAttributionUrlNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataAttributionUrlNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataAttributionUrlNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataAttributionUrlNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataAttributionUrlNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataAttributionUrlNestedUpsertPhotoCreateInput | null;
}

export interface MetadataAttributionUrlNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataAttributionUrlNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataAttributionUrlNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataAttributionUrlNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataAttributionUrlNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataAttributionUrlNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataAttributionUrlNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataAttributionUrlNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataAttributionUrlNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataAttributionUrlNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataAttributionUrlNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataAttributionUrlNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataAttributionUrlNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataAttributionUrlNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataAttributionUrlNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataAttributionUrl" nodes by specifying some conditions
 */
export interface MetadataAttributionUrlWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataAttributionUrlWhereInput[] | null;
  OR?: MetadataAttributionUrlWhereInput[] | null;
  NOT?: MetadataAttributionUrlWhereInput | null;
}

/**
 * Identifies exactly one "MetadataAttributionUrl" node.
 */
export interface MetadataAttributionUrlWhereUniqueInput {
  id: number;
}

export interface MetadataAttributionUrlWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataAttributionUrlNestedPhotoCreateInput;
  metadata: MetadataAttributionUrlNestedMetadataCreateInput;
}

export interface MetadataAttributionUrlWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataAttributionUrlNestedPhotoUpdateInput | null;
  metadata?: MetadataAttributionUrlNestedMetadataUpdateInput | null;
}

export interface MetadataAttributionUrlWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataAttributionUrlNestedPhotoCreateInput;
  archive: MetadataAttributionUrlNestedArchiveCreateInput;
}

export interface MetadataAttributionUrlWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataAttributionUrlNestedPhotoUpdateInput | null;
  archive?: MetadataAttributionUrlNestedArchiveUpdateInput | null;
}

export interface MetadataAttributionUrlWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataAttributionUrlNestedMetadataCreateInput;
  archive: MetadataAttributionUrlNestedArchiveCreateInput;
}

export interface MetadataAttributionUrlWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataAttributionUrlNestedMetadataUpdateInput | null;
  archive?: MetadataAttributionUrlNestedArchiveUpdateInput | null;
}

export interface MetadataAudienceNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataAudienceNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataAudienceNestedUpsertArchiveCreateInput | null;
}

export interface MetadataAudienceNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataAudienceNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataAudienceNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataAudienceNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataAudienceNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataAudienceNestedUpsertMetadataCreateInput | null;
}

export interface MetadataAudienceNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataAudienceNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataAudienceNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataAudienceNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataAudienceNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataAudienceNestedUpsertPhotoCreateInput | null;
}

export interface MetadataAudienceNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataAudienceNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataAudienceNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataAudienceNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataAudienceNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataAudienceNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataAudienceNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataAudienceNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataAudienceNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataAudienceNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataAudienceNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataAudienceNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataAudienceNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataAudienceNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataAudienceNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataAudience" nodes by specifying some conditions
 */
export interface MetadataAudienceWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataAudienceWhereInput[] | null;
  OR?: MetadataAudienceWhereInput[] | null;
  NOT?: MetadataAudienceWhereInput | null;
}

/**
 * Identifies exactly one "MetadataAudience" node.
 */
export interface MetadataAudienceWhereUniqueInput {
  id: number;
}

export interface MetadataAudienceWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataAudienceNestedPhotoCreateInput;
  metadata: MetadataAudienceNestedMetadataCreateInput;
}

export interface MetadataAudienceWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataAudienceNestedPhotoUpdateInput | null;
  metadata?: MetadataAudienceNestedMetadataUpdateInput | null;
}

export interface MetadataAudienceWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataAudienceNestedPhotoCreateInput;
  archive: MetadataAudienceNestedArchiveCreateInput;
}

export interface MetadataAudienceWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataAudienceNestedPhotoUpdateInput | null;
  archive?: MetadataAudienceNestedArchiveUpdateInput | null;
}

export interface MetadataAudienceWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataAudienceNestedMetadataCreateInput;
  archive: MetadataAudienceNestedArchiveCreateInput;
}

export interface MetadataAudienceWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataAudienceNestedMetadataUpdateInput | null;
  archive?: MetadataAudienceNestedArchiveUpdateInput | null;
}

export interface MetadataAuthorsPositionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataAuthorsPositionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataAuthorsPositionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataAuthorsPositionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataAuthorsPositionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataAuthorsPositionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataAuthorsPositionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataAuthorsPositionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataAuthorsPositionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataAuthorsPositionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataAuthorsPositionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataAuthorsPositionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataAuthorsPositionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataAuthorsPositionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataAuthorsPositionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataAuthorsPositionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataAuthorsPositionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataAuthorsPositionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataAuthorsPositionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataAuthorsPositionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataAuthorsPositionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataAuthorsPositionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataAuthorsPositionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataAuthorsPositionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataAuthorsPositionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataAuthorsPositionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataAuthorsPositionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataAuthorsPositionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataAuthorsPositionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataAuthorsPositionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataAuthorsPosition" nodes by specifying some conditions
 */
export interface MetadataAuthorsPositionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataAuthorsPositionWhereInput[] | null;
  OR?: MetadataAuthorsPositionWhereInput[] | null;
  NOT?: MetadataAuthorsPositionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataAuthorsPosition" node.
 */
export interface MetadataAuthorsPositionWhereUniqueInput {
  id: number;
}

export interface MetadataAuthorsPositionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataAuthorsPositionNestedPhotoCreateInput;
  metadata: MetadataAuthorsPositionNestedMetadataCreateInput;
}

export interface MetadataAuthorsPositionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataAuthorsPositionNestedPhotoUpdateInput | null;
  metadata?: MetadataAuthorsPositionNestedMetadataUpdateInput | null;
}

export interface MetadataAuthorsPositionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataAuthorsPositionNestedPhotoCreateInput;
  archive: MetadataAuthorsPositionNestedArchiveCreateInput;
}

export interface MetadataAuthorsPositionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataAuthorsPositionNestedPhotoUpdateInput | null;
  archive?: MetadataAuthorsPositionNestedArchiveUpdateInput | null;
}

export interface MetadataAuthorsPositionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataAuthorsPositionNestedMetadataCreateInput;
  archive: MetadataAuthorsPositionNestedArchiveCreateInput;
}

export interface MetadataAuthorsPositionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataAuthorsPositionNestedMetadataUpdateInput | null;
  archive?: MetadataAuthorsPositionNestedArchiveUpdateInput | null;
}

export interface MetadataBackupNameNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataBackupNameNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataBackupNameNestedUpsertArchiveCreateInput | null;
}

export interface MetadataBackupNameNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataBackupNameNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataBackupNameNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataBackupNameNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataBackupNameNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataBackupNameNestedUpsertMetadataCreateInput | null;
}

export interface MetadataBackupNameNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataBackupNameNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataBackupNameNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataBackupNameNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataBackupNameNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataBackupNameNestedUpsertPhotoCreateInput | null;
}

export interface MetadataBackupNameNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataBackupNameNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataBackupNameNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataBackupNameNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataBackupNameNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataBackupNameNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataBackupNameNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataBackupNameNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataBackupNameNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataBackupNameNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataBackupNameNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataBackupNameNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataBackupNameNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataBackupNameNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataBackupNameNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataBackupName" nodes by specifying some conditions
 */
export interface MetadataBackupNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataBackupNameWhereInput[] | null;
  OR?: MetadataBackupNameWhereInput[] | null;
  NOT?: MetadataBackupNameWhereInput | null;
}

/**
 * Identifies exactly one "MetadataBackupName" node.
 */
export interface MetadataBackupNameWhereUniqueInput {
  id: number;
}

export interface MetadataBackupNameWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataBackupNameNestedPhotoCreateInput;
  metadata: MetadataBackupNameNestedMetadataCreateInput;
}

export interface MetadataBackupNameWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataBackupNameNestedPhotoUpdateInput | null;
  metadata?: MetadataBackupNameNestedMetadataUpdateInput | null;
}

export interface MetadataBackupNameWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataBackupNameNestedPhotoCreateInput;
  archive: MetadataBackupNameNestedArchiveCreateInput;
}

export interface MetadataBackupNameWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataBackupNameNestedPhotoUpdateInput | null;
  archive?: MetadataBackupNameNestedArchiveUpdateInput | null;
}

export interface MetadataBackupNameWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataBackupNameNestedMetadataCreateInput;
  archive: MetadataBackupNameNestedArchiveCreateInput;
}

export interface MetadataBackupNameWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataBackupNameNestedMetadataUpdateInput | null;
  archive?: MetadataBackupNameNestedArchiveUpdateInput | null;
}

export interface MetadataBitmapGrayscalePictureNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataBitmapGrayscalePictureNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataBitmapGrayscalePictureNestedUpsertArchiveCreateInput | null;
}

export interface MetadataBitmapGrayscalePictureNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataBitmapGrayscalePictureNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataBitmapGrayscalePictureNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataBitmapGrayscalePictureNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataBitmapGrayscalePictureNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataBitmapGrayscalePictureNestedUpsertMetadataCreateInput | null;
}

export interface MetadataBitmapGrayscalePictureNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataBitmapGrayscalePictureNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataBitmapGrayscalePictureNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataBitmapGrayscalePictureNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataBitmapGrayscalePictureNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataBitmapGrayscalePictureNestedUpsertPhotoCreateInput | null;
}

export interface MetadataBitmapGrayscalePictureNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataBitmapGrayscalePictureNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataBitmapGrayscalePictureNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataBitmapGrayscalePictureNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataBitmapGrayscalePictureNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataBitmapGrayscalePictureNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataBitmapGrayscalePictureNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataBitmapGrayscalePictureNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataBitmapGrayscalePictureNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataBitmapGrayscalePictureNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataBitmapGrayscalePictureNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataBitmapGrayscalePictureNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataBitmapGrayscalePictureNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataBitmapGrayscalePictureNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataBitmapGrayscalePictureNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataBitmapGrayscalePicture" nodes by specifying some conditions
 */
export interface MetadataBitmapGrayscalePictureWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataBitmapGrayscalePictureWhereInput[] | null;
  OR?: MetadataBitmapGrayscalePictureWhereInput[] | null;
  NOT?: MetadataBitmapGrayscalePictureWhereInput | null;
}

/**
 * Identifies exactly one "MetadataBitmapGrayscalePicture" node.
 */
export interface MetadataBitmapGrayscalePictureWhereUniqueInput {
  id: number;
}

export interface MetadataBitmapGrayscalePictureWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataBitmapGrayscalePictureNestedPhotoCreateInput;
  metadata: MetadataBitmapGrayscalePictureNestedMetadataCreateInput;
}

export interface MetadataBitmapGrayscalePictureWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataBitmapGrayscalePictureNestedPhotoUpdateInput | null;
  metadata?: MetadataBitmapGrayscalePictureNestedMetadataUpdateInput | null;
}

export interface MetadataBitmapGrayscalePictureWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataBitmapGrayscalePictureNestedPhotoCreateInput;
  archive: MetadataBitmapGrayscalePictureNestedArchiveCreateInput;
}

export interface MetadataBitmapGrayscalePictureWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataBitmapGrayscalePictureNestedPhotoUpdateInput | null;
  archive?: MetadataBitmapGrayscalePictureNestedArchiveUpdateInput | null;
}

export interface MetadataBitmapGrayscalePictureWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataBitmapGrayscalePictureNestedMetadataCreateInput;
  archive: MetadataBitmapGrayscalePictureNestedArchiveCreateInput;
}

export interface MetadataBitmapGrayscalePictureWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataBitmapGrayscalePictureNestedMetadataUpdateInput | null;
  archive?: MetadataBitmapGrayscalePictureNestedArchiveUpdateInput | null;
}

export interface MetadataBrandNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataBrandNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataBrandNestedUpsertArchiveCreateInput | null;
}

export interface MetadataBrandNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataBrandNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataBrandNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataBrandNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataBrandNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataBrandNestedUpsertMetadataCreateInput | null;
}

export interface MetadataBrandNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataBrandNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataBrandNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataBrandNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataBrandNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataBrandNestedUpsertPhotoCreateInput | null;
}

export interface MetadataBrandNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataBrandNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataBrandNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataBrandNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataBrandNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataBrandNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataBrandNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataBrandNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataBrandNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataBrandNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataBrandNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataBrandNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataBrandNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataBrandNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataBrandNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataBrandPrismaNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataBrandPrismaNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataBrandPrismaNestedUpsertArchiveCreateInput | null;
}

export interface MetadataBrandPrismaNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataBrandPrismaNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataBrandPrismaNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataBrandPrismaNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataBrandPrismaNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataBrandPrismaNestedUpsertMetadataCreateInput | null;
}

export interface MetadataBrandPrismaNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataBrandPrismaNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataBrandPrismaNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataBrandPrismaNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataBrandPrismaNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataBrandPrismaNestedUpsertPhotoCreateInput | null;
}

export interface MetadataBrandPrismaNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataBrandPrismaNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataBrandPrismaNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataBrandPrismaNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataBrandPrismaNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataBrandPrismaNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataBrandPrismaNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataBrandPrismaNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataBrandPrismaNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataBrandPrismaNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataBrandPrismaNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataBrandPrismaNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataBrandPrismaNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataBrandPrismaNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataBrandPrismaNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataBrandPrisma" nodes by specifying some conditions
 */
export interface MetadataBrandPrismaWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataBrandPrismaWhereInput[] | null;
  OR?: MetadataBrandPrismaWhereInput[] | null;
  NOT?: MetadataBrandPrismaWhereInput | null;
}

/**
 * Identifies exactly one "MetadataBrandPrisma" node.
 */
export interface MetadataBrandPrismaWhereUniqueInput {
  id: number;
}

export interface MetadataBrandPrismaWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataBrandPrismaNestedPhotoCreateInput;
  metadata: MetadataBrandPrismaNestedMetadataCreateInput;
}

export interface MetadataBrandPrismaWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataBrandPrismaNestedPhotoUpdateInput | null;
  metadata?: MetadataBrandPrismaNestedMetadataUpdateInput | null;
}

export interface MetadataBrandPrismaWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataBrandPrismaNestedPhotoCreateInput;
  archive: MetadataBrandPrismaNestedArchiveCreateInput;
}

export interface MetadataBrandPrismaWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataBrandPrismaNestedPhotoUpdateInput | null;
  archive?: MetadataBrandPrismaNestedArchiveUpdateInput | null;
}

export interface MetadataBrandPrismaWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataBrandPrismaNestedMetadataCreateInput;
  archive: MetadataBrandPrismaNestedArchiveCreateInput;
}

export interface MetadataBrandPrismaWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataBrandPrismaNestedMetadataUpdateInput | null;
  archive?: MetadataBrandPrismaNestedArchiveUpdateInput | null;
}

export interface MetadataBrandShortNameNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataBrandShortNameNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataBrandShortNameNestedUpsertArchiveCreateInput | null;
}

export interface MetadataBrandShortNameNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataBrandShortNameNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataBrandShortNameNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataBrandShortNameNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataBrandShortNameNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataBrandShortNameNestedUpsertMetadataCreateInput | null;
}

export interface MetadataBrandShortNameNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataBrandShortNameNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataBrandShortNameNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataBrandShortNameNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataBrandShortNameNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataBrandShortNameNestedUpsertPhotoCreateInput | null;
}

export interface MetadataBrandShortNameNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataBrandShortNameNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataBrandShortNameNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataBrandShortNameNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataBrandShortNameNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataBrandShortNameNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataBrandShortNameNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataBrandShortNameNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataBrandShortNameNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataBrandShortNameNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataBrandShortNameNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataBrandShortNameNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataBrandShortNameNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataBrandShortNameNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataBrandShortNameNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataBrandShortName" nodes by specifying some conditions
 */
export interface MetadataBrandShortNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataBrandShortNameWhereInput[] | null;
  OR?: MetadataBrandShortNameWhereInput[] | null;
  NOT?: MetadataBrandShortNameWhereInput | null;
}

/**
 * Identifies exactly one "MetadataBrandShortName" node.
 */
export interface MetadataBrandShortNameWhereUniqueInput {
  id: number;
}

export interface MetadataBrandShortNameWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataBrandShortNameNestedPhotoCreateInput;
  metadata: MetadataBrandShortNameNestedMetadataCreateInput;
}

export interface MetadataBrandShortNameWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataBrandShortNameNestedPhotoUpdateInput | null;
  metadata?: MetadataBrandShortNameNestedMetadataUpdateInput | null;
}

export interface MetadataBrandShortNameWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataBrandShortNameNestedPhotoCreateInput;
  archive: MetadataBrandShortNameNestedArchiveCreateInput;
}

export interface MetadataBrandShortNameWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataBrandShortNameNestedPhotoUpdateInput | null;
  archive?: MetadataBrandShortNameNestedArchiveUpdateInput | null;
}

export interface MetadataBrandShortNameWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataBrandShortNameNestedMetadataCreateInput;
  archive: MetadataBrandShortNameNestedArchiveCreateInput;
}

export interface MetadataBrandShortNameWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataBrandShortNameNestedMetadataUpdateInput | null;
  archive?: MetadataBrandShortNameNestedArchiveUpdateInput | null;
}

/**
 * Filters the "MetadataBrand" nodes by specifying some conditions
 */
export interface MetadataBrandWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataBrandWhereInput[] | null;
  OR?: MetadataBrandWhereInput[] | null;
  NOT?: MetadataBrandWhereInput | null;
}

/**
 * Identifies exactly one "MetadataBrand" node.
 */
export interface MetadataBrandWhereUniqueInput {
  id: number;
}

export interface MetadataBrandWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataBrandNestedPhotoCreateInput;
  metadata: MetadataBrandNestedMetadataCreateInput;
}

export interface MetadataBrandWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataBrandNestedPhotoUpdateInput | null;
  metadata?: MetadataBrandNestedMetadataUpdateInput | null;
}

export interface MetadataBrandWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataBrandNestedPhotoCreateInput;
  archive: MetadataBrandNestedArchiveCreateInput;
}

export interface MetadataBrandWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataBrandNestedPhotoUpdateInput | null;
  archive?: MetadataBrandNestedArchiveUpdateInput | null;
}

export interface MetadataBrandWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataBrandNestedMetadataCreateInput;
  archive: MetadataBrandNestedArchiveCreateInput;
}

export interface MetadataBrandWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataBrandNestedMetadataUpdateInput | null;
  archive?: MetadataBrandNestedArchiveUpdateInput | null;
}

export interface MetadataCaptionWriterNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCaptionWriterNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCaptionWriterNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCaptionWriterNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCaptionWriterNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCaptionWriterNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCaptionWriterNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCaptionWriterNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCaptionWriterNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCaptionWriterNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCaptionWriterNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCaptionWriterNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCaptionWriterNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCaptionWriterNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCaptionWriterNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCaptionWriterNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCaptionWriterNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCaptionWriterNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCaptionWriterNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCaptionWriterNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCaptionWriterNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCaptionWriterNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCaptionWriterNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCaptionWriterNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCaptionWriterNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCaptionWriterNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCaptionWriterNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCaptionWriterNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCaptionWriterNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCaptionWriterNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCaptionWriter" nodes by specifying some conditions
 */
export interface MetadataCaptionWriterWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCaptionWriterWhereInput[] | null;
  OR?: MetadataCaptionWriterWhereInput[] | null;
  NOT?: MetadataCaptionWriterWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCaptionWriter" node.
 */
export interface MetadataCaptionWriterWhereUniqueInput {
  id: number;
}

export interface MetadataCaptionWriterWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCaptionWriterNestedPhotoCreateInput;
  metadata: MetadataCaptionWriterNestedMetadataCreateInput;
}

export interface MetadataCaptionWriterWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCaptionWriterNestedPhotoUpdateInput | null;
  metadata?: MetadataCaptionWriterNestedMetadataUpdateInput | null;
}

export interface MetadataCaptionWriterWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCaptionWriterNestedPhotoCreateInput;
  archive: MetadataCaptionWriterNestedArchiveCreateInput;
}

export interface MetadataCaptionWriterWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCaptionWriterNestedPhotoUpdateInput | null;
  archive?: MetadataCaptionWriterNestedArchiveUpdateInput | null;
}

export interface MetadataCaptionWriterWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCaptionWriterNestedMetadataCreateInput;
  archive: MetadataCaptionWriterNestedArchiveCreateInput;
}

export interface MetadataCaptionWriterWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCaptionWriterNestedMetadataUpdateInput | null;
  archive?: MetadataCaptionWriterNestedArchiveUpdateInput | null;
}

export interface MetadataCategoryNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCategoryNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCategoryNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCategoryNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCategoryNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCategoryNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCategoryNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCategoryNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCategoryNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCategoryNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCategoryNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCategoryNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCategoryNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCategoryNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCategoryNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCategoryNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCategoryNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCategoryNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCategoryNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCategoryNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCategoryNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCategoryNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCategoryNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCategoryNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCategoryNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCategoryNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCategoryNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCategoryNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCategoryNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCategoryNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCategoryPrismaNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCategoryPrismaNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCategoryPrismaNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCategoryPrismaNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCategoryPrismaNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCategoryPrismaNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCategoryPrismaNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCategoryPrismaNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCategoryPrismaNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCategoryPrismaNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCategoryPrismaNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCategoryPrismaNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCategoryPrismaNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCategoryPrismaNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCategoryPrismaNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCategoryPrismaNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCategoryPrismaNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCategoryPrismaNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCategoryPrismaNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCategoryPrismaNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCategoryPrismaNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCategoryPrismaNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCategoryPrismaNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCategoryPrismaNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCategoryPrismaNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCategoryPrismaNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCategoryPrismaNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCategoryPrismaNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCategoryPrismaNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCategoryPrismaNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCategoryPrisma" nodes by specifying some conditions
 */
export interface MetadataCategoryPrismaWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCategoryPrismaWhereInput[] | null;
  OR?: MetadataCategoryPrismaWhereInput[] | null;
  NOT?: MetadataCategoryPrismaWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCategoryPrisma" node.
 */
export interface MetadataCategoryPrismaWhereUniqueInput {
  id: number;
}

export interface MetadataCategoryPrismaWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCategoryPrismaNestedPhotoCreateInput;
  metadata: MetadataCategoryPrismaNestedMetadataCreateInput;
}

export interface MetadataCategoryPrismaWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCategoryPrismaNestedPhotoUpdateInput | null;
  metadata?: MetadataCategoryPrismaNestedMetadataUpdateInput | null;
}

export interface MetadataCategoryPrismaWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCategoryPrismaNestedPhotoCreateInput;
  archive: MetadataCategoryPrismaNestedArchiveCreateInput;
}

export interface MetadataCategoryPrismaWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCategoryPrismaNestedPhotoUpdateInput | null;
  archive?: MetadataCategoryPrismaNestedArchiveUpdateInput | null;
}

export interface MetadataCategoryPrismaWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCategoryPrismaNestedMetadataCreateInput;
  archive: MetadataCategoryPrismaNestedArchiveCreateInput;
}

export interface MetadataCategoryPrismaWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCategoryPrismaNestedMetadataUpdateInput | null;
  archive?: MetadataCategoryPrismaNestedArchiveUpdateInput | null;
}

/**
 * Filters the "MetadataCategory" nodes by specifying some conditions
 */
export interface MetadataCategoryWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCategoryWhereInput[] | null;
  OR?: MetadataCategoryWhereInput[] | null;
  NOT?: MetadataCategoryWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCategory" node.
 */
export interface MetadataCategoryWhereUniqueInput {
  id: number;
}

export interface MetadataCategoryWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCategoryNestedPhotoCreateInput;
  metadata: MetadataCategoryNestedMetadataCreateInput;
}

export interface MetadataCategoryWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCategoryNestedPhotoUpdateInput | null;
  metadata?: MetadataCategoryNestedMetadataUpdateInput | null;
}

export interface MetadataCategoryWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCategoryNestedPhotoCreateInput;
  archive: MetadataCategoryNestedArchiveCreateInput;
}

export interface MetadataCategoryWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCategoryNestedPhotoUpdateInput | null;
  archive?: MetadataCategoryNestedArchiveUpdateInput | null;
}

export interface MetadataCategoryWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCategoryNestedMetadataCreateInput;
  archive: MetadataCategoryNestedArchiveCreateInput;
}

export interface MetadataCategoryWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCategoryNestedMetadataUpdateInput | null;
  archive?: MetadataCategoryNestedArchiveUpdateInput | null;
}

export interface MetadataCertificateNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCertificateNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCertificateNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCertificateNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCertificateNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCertificateNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCertificateNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCertificateNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCertificateNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCertificateNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCertificateNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCertificateNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCertificateNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCertificateNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCertificateNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCertificateNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCertificateNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCertificateNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCertificateNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCertificateNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCertificateNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCertificateNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCertificateNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCertificateNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCertificateNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCertificateNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCertificateNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCertificateNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCertificateNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCertificateNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCertificate" nodes by specifying some conditions
 */
export interface MetadataCertificateWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCertificateWhereInput[] | null;
  OR?: MetadataCertificateWhereInput[] | null;
  NOT?: MetadataCertificateWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCertificate" node.
 */
export interface MetadataCertificateWhereUniqueInput {
  id: number;
}

export interface MetadataCertificateWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCertificateNestedPhotoCreateInput;
  metadata: MetadataCertificateNestedMetadataCreateInput;
}

export interface MetadataCertificateWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCertificateNestedPhotoUpdateInput | null;
  metadata?: MetadataCertificateNestedMetadataUpdateInput | null;
}

export interface MetadataCertificateWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCertificateNestedPhotoCreateInput;
  archive: MetadataCertificateNestedArchiveCreateInput;
}

export interface MetadataCertificateWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCertificateNestedPhotoUpdateInput | null;
  archive?: MetadataCertificateNestedArchiveUpdateInput | null;
}

export interface MetadataCertificateWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCertificateNestedMetadataCreateInput;
  archive: MetadataCertificateNestedArchiveCreateInput;
}

export interface MetadataCertificateWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCertificateNestedMetadataUpdateInput | null;
  archive?: MetadataCertificateNestedArchiveUpdateInput | null;
}

export interface MetadataCiAdrCityNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiAdrCityNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiAdrCityNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCiAdrCityNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiAdrCityNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiAdrCityNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCiAdrCityNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiAdrCityNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiAdrCityNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCiAdrCityNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiAdrCityNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiAdrCityNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCiAdrCityNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiAdrCityNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiAdrCityNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCiAdrCityNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiAdrCityNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiAdrCityNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCiAdrCityNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiAdrCityNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiAdrCityNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiAdrCityNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiAdrCityNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiAdrCityNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiAdrCityNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiAdrCityNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiAdrCityNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiAdrCityNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiAdrCityNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCiAdrCityNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCiAdrCity" nodes by specifying some conditions
 */
export interface MetadataCiAdrCityWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiAdrCityWhereInput[] | null;
  OR?: MetadataCiAdrCityWhereInput[] | null;
  NOT?: MetadataCiAdrCityWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCiAdrCity" node.
 */
export interface MetadataCiAdrCityWhereUniqueInput {
  id: number;
}

export interface MetadataCiAdrCityWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCiAdrCityNestedPhotoCreateInput;
  metadata: MetadataCiAdrCityNestedMetadataCreateInput;
}

export interface MetadataCiAdrCityWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCiAdrCityNestedPhotoUpdateInput | null;
  metadata?: MetadataCiAdrCityNestedMetadataUpdateInput | null;
}

export interface MetadataCiAdrCityWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCiAdrCityNestedPhotoCreateInput;
  archive: MetadataCiAdrCityNestedArchiveCreateInput;
}

export interface MetadataCiAdrCityWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCiAdrCityNestedPhotoUpdateInput | null;
  archive?: MetadataCiAdrCityNestedArchiveUpdateInput | null;
}

export interface MetadataCiAdrCityWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCiAdrCityNestedMetadataCreateInput;
  archive: MetadataCiAdrCityNestedArchiveCreateInput;
}

export interface MetadataCiAdrCityWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCiAdrCityNestedMetadataUpdateInput | null;
  archive?: MetadataCiAdrCityNestedArchiveUpdateInput | null;
}

export interface MetadataCiAdrCtryNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiAdrCtryNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiAdrCtryNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCiAdrCtryNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiAdrCtryNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiAdrCtryNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCiAdrCtryNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiAdrCtryNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiAdrCtryNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCiAdrCtryNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiAdrCtryNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiAdrCtryNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCiAdrCtryNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiAdrCtryNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiAdrCtryNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCiAdrCtryNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiAdrCtryNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiAdrCtryNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCiAdrCtryNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiAdrCtryNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiAdrCtryNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiAdrCtryNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiAdrCtryNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiAdrCtryNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiAdrCtryNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiAdrCtryNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiAdrCtryNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiAdrCtryNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiAdrCtryNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCiAdrCtryNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCiAdrCtry" nodes by specifying some conditions
 */
export interface MetadataCiAdrCtryWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiAdrCtryWhereInput[] | null;
  OR?: MetadataCiAdrCtryWhereInput[] | null;
  NOT?: MetadataCiAdrCtryWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCiAdrCtry" node.
 */
export interface MetadataCiAdrCtryWhereUniqueInput {
  id: number;
}

export interface MetadataCiAdrCtryWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCiAdrCtryNestedPhotoCreateInput;
  metadata: MetadataCiAdrCtryNestedMetadataCreateInput;
}

export interface MetadataCiAdrCtryWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCiAdrCtryNestedPhotoUpdateInput | null;
  metadata?: MetadataCiAdrCtryNestedMetadataUpdateInput | null;
}

export interface MetadataCiAdrCtryWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCiAdrCtryNestedPhotoCreateInput;
  archive: MetadataCiAdrCtryNestedArchiveCreateInput;
}

export interface MetadataCiAdrCtryWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCiAdrCtryNestedPhotoUpdateInput | null;
  archive?: MetadataCiAdrCtryNestedArchiveUpdateInput | null;
}

export interface MetadataCiAdrCtryWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCiAdrCtryNestedMetadataCreateInput;
  archive: MetadataCiAdrCtryNestedArchiveCreateInput;
}

export interface MetadataCiAdrCtryWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCiAdrCtryNestedMetadataUpdateInput | null;
  archive?: MetadataCiAdrCtryNestedArchiveUpdateInput | null;
}

export interface MetadataCiAdrExtadrNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiAdrExtadrNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiAdrExtadrNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCiAdrExtadrNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiAdrExtadrNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiAdrExtadrNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCiAdrExtadrNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiAdrExtadrNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiAdrExtadrNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCiAdrExtadrNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiAdrExtadrNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiAdrExtadrNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCiAdrExtadrNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiAdrExtadrNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiAdrExtadrNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCiAdrExtadrNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiAdrExtadrNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiAdrExtadrNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCiAdrExtadrNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiAdrExtadrNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiAdrExtadrNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiAdrExtadrNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiAdrExtadrNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiAdrExtadrNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiAdrExtadrNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiAdrExtadrNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiAdrExtadrNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiAdrExtadrNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiAdrExtadrNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCiAdrExtadrNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCiAdrExtadr" nodes by specifying some conditions
 */
export interface MetadataCiAdrExtadrWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiAdrExtadrWhereInput[] | null;
  OR?: MetadataCiAdrExtadrWhereInput[] | null;
  NOT?: MetadataCiAdrExtadrWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCiAdrExtadr" node.
 */
export interface MetadataCiAdrExtadrWhereUniqueInput {
  id: number;
}

export interface MetadataCiAdrExtadrWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCiAdrExtadrNestedPhotoCreateInput;
  metadata: MetadataCiAdrExtadrNestedMetadataCreateInput;
}

export interface MetadataCiAdrExtadrWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCiAdrExtadrNestedPhotoUpdateInput | null;
  metadata?: MetadataCiAdrExtadrNestedMetadataUpdateInput | null;
}

export interface MetadataCiAdrExtadrWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCiAdrExtadrNestedPhotoCreateInput;
  archive: MetadataCiAdrExtadrNestedArchiveCreateInput;
}

export interface MetadataCiAdrExtadrWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCiAdrExtadrNestedPhotoUpdateInput | null;
  archive?: MetadataCiAdrExtadrNestedArchiveUpdateInput | null;
}

export interface MetadataCiAdrExtadrWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCiAdrExtadrNestedMetadataCreateInput;
  archive: MetadataCiAdrExtadrNestedArchiveCreateInput;
}

export interface MetadataCiAdrExtadrWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCiAdrExtadrNestedMetadataUpdateInput | null;
  archive?: MetadataCiAdrExtadrNestedArchiveUpdateInput | null;
}

export interface MetadataCiAdrPcodeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiAdrPcodeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiAdrPcodeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCiAdrPcodeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiAdrPcodeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiAdrPcodeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCiAdrPcodeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiAdrPcodeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiAdrPcodeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCiAdrPcodeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiAdrPcodeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiAdrPcodeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCiAdrPcodeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiAdrPcodeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiAdrPcodeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCiAdrPcodeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiAdrPcodeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiAdrPcodeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCiAdrPcodeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiAdrPcodeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiAdrPcodeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiAdrPcodeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiAdrPcodeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiAdrPcodeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiAdrPcodeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiAdrPcodeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiAdrPcodeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiAdrPcodeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiAdrPcodeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCiAdrPcodeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCiAdrPcode" nodes by specifying some conditions
 */
export interface MetadataCiAdrPcodeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiAdrPcodeWhereInput[] | null;
  OR?: MetadataCiAdrPcodeWhereInput[] | null;
  NOT?: MetadataCiAdrPcodeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCiAdrPcode" node.
 */
export interface MetadataCiAdrPcodeWhereUniqueInput {
  id: number;
}

export interface MetadataCiAdrPcodeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCiAdrPcodeNestedPhotoCreateInput;
  metadata: MetadataCiAdrPcodeNestedMetadataCreateInput;
}

export interface MetadataCiAdrPcodeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCiAdrPcodeNestedPhotoUpdateInput | null;
  metadata?: MetadataCiAdrPcodeNestedMetadataUpdateInput | null;
}

export interface MetadataCiAdrPcodeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCiAdrPcodeNestedPhotoCreateInput;
  archive: MetadataCiAdrPcodeNestedArchiveCreateInput;
}

export interface MetadataCiAdrPcodeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCiAdrPcodeNestedPhotoUpdateInput | null;
  archive?: MetadataCiAdrPcodeNestedArchiveUpdateInput | null;
}

export interface MetadataCiAdrPcodeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCiAdrPcodeNestedMetadataCreateInput;
  archive: MetadataCiAdrPcodeNestedArchiveCreateInput;
}

export interface MetadataCiAdrPcodeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCiAdrPcodeNestedMetadataUpdateInput | null;
  archive?: MetadataCiAdrPcodeNestedArchiveUpdateInput | null;
}

export interface MetadataCiAdrRegionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiAdrRegionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiAdrRegionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCiAdrRegionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiAdrRegionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiAdrRegionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCiAdrRegionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiAdrRegionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiAdrRegionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCiAdrRegionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiAdrRegionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiAdrRegionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCiAdrRegionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiAdrRegionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiAdrRegionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCiAdrRegionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiAdrRegionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiAdrRegionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCiAdrRegionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiAdrRegionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiAdrRegionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiAdrRegionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiAdrRegionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiAdrRegionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiAdrRegionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiAdrRegionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiAdrRegionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiAdrRegionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiAdrRegionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCiAdrRegionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCiAdrRegion" nodes by specifying some conditions
 */
export interface MetadataCiAdrRegionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiAdrRegionWhereInput[] | null;
  OR?: MetadataCiAdrRegionWhereInput[] | null;
  NOT?: MetadataCiAdrRegionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCiAdrRegion" node.
 */
export interface MetadataCiAdrRegionWhereUniqueInput {
  id: number;
}

export interface MetadataCiAdrRegionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCiAdrRegionNestedPhotoCreateInput;
  metadata: MetadataCiAdrRegionNestedMetadataCreateInput;
}

export interface MetadataCiAdrRegionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCiAdrRegionNestedPhotoUpdateInput | null;
  metadata?: MetadataCiAdrRegionNestedMetadataUpdateInput | null;
}

export interface MetadataCiAdrRegionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCiAdrRegionNestedPhotoCreateInput;
  archive: MetadataCiAdrRegionNestedArchiveCreateInput;
}

export interface MetadataCiAdrRegionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCiAdrRegionNestedPhotoUpdateInput | null;
  archive?: MetadataCiAdrRegionNestedArchiveUpdateInput | null;
}

export interface MetadataCiAdrRegionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCiAdrRegionNestedMetadataCreateInput;
  archive: MetadataCiAdrRegionNestedArchiveCreateInput;
}

export interface MetadataCiAdrRegionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCiAdrRegionNestedMetadataUpdateInput | null;
  archive?: MetadataCiAdrRegionNestedArchiveUpdateInput | null;
}

export interface MetadataCiEmailWorkNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiEmailWorkNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiEmailWorkNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCiEmailWorkNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiEmailWorkNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiEmailWorkNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCiEmailWorkNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiEmailWorkNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiEmailWorkNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCiEmailWorkNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiEmailWorkNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiEmailWorkNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCiEmailWorkNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiEmailWorkNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiEmailWorkNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCiEmailWorkNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiEmailWorkNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiEmailWorkNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCiEmailWorkNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiEmailWorkNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiEmailWorkNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiEmailWorkNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiEmailWorkNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiEmailWorkNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiEmailWorkNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiEmailWorkNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiEmailWorkNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiEmailWorkNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiEmailWorkNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCiEmailWorkNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCiEmailWork" nodes by specifying some conditions
 */
export interface MetadataCiEmailWorkWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiEmailWorkWhereInput[] | null;
  OR?: MetadataCiEmailWorkWhereInput[] | null;
  NOT?: MetadataCiEmailWorkWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCiEmailWork" node.
 */
export interface MetadataCiEmailWorkWhereUniqueInput {
  id: number;
}

export interface MetadataCiEmailWorkWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCiEmailWorkNestedPhotoCreateInput;
  metadata: MetadataCiEmailWorkNestedMetadataCreateInput;
}

export interface MetadataCiEmailWorkWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCiEmailWorkNestedPhotoUpdateInput | null;
  metadata?: MetadataCiEmailWorkNestedMetadataUpdateInput | null;
}

export interface MetadataCiEmailWorkWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCiEmailWorkNestedPhotoCreateInput;
  archive: MetadataCiEmailWorkNestedArchiveCreateInput;
}

export interface MetadataCiEmailWorkWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCiEmailWorkNestedPhotoUpdateInput | null;
  archive?: MetadataCiEmailWorkNestedArchiveUpdateInput | null;
}

export interface MetadataCiEmailWorkWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCiEmailWorkNestedMetadataCreateInput;
  archive: MetadataCiEmailWorkNestedArchiveCreateInput;
}

export interface MetadataCiEmailWorkWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCiEmailWorkNestedMetadataUpdateInput | null;
  archive?: MetadataCiEmailWorkNestedArchiveUpdateInput | null;
}

export interface MetadataCiTelWorkNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiTelWorkNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiTelWorkNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCiTelWorkNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiTelWorkNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiTelWorkNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCiTelWorkNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiTelWorkNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiTelWorkNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCiTelWorkNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiTelWorkNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiTelWorkNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCiTelWorkNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiTelWorkNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiTelWorkNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCiTelWorkNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiTelWorkNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiTelWorkNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCiTelWorkNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiTelWorkNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiTelWorkNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiTelWorkNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiTelWorkNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiTelWorkNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiTelWorkNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiTelWorkNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiTelWorkNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiTelWorkNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiTelWorkNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCiTelWorkNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCiTelWork" nodes by specifying some conditions
 */
export interface MetadataCiTelWorkWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiTelWorkWhereInput[] | null;
  OR?: MetadataCiTelWorkWhereInput[] | null;
  NOT?: MetadataCiTelWorkWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCiTelWork" node.
 */
export interface MetadataCiTelWorkWhereUniqueInput {
  id: number;
}

export interface MetadataCiTelWorkWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCiTelWorkNestedPhotoCreateInput;
  metadata: MetadataCiTelWorkNestedMetadataCreateInput;
}

export interface MetadataCiTelWorkWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCiTelWorkNestedPhotoUpdateInput | null;
  metadata?: MetadataCiTelWorkNestedMetadataUpdateInput | null;
}

export interface MetadataCiTelWorkWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCiTelWorkNestedPhotoCreateInput;
  archive: MetadataCiTelWorkNestedArchiveCreateInput;
}

export interface MetadataCiTelWorkWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCiTelWorkNestedPhotoUpdateInput | null;
  archive?: MetadataCiTelWorkNestedArchiveUpdateInput | null;
}

export interface MetadataCiTelWorkWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCiTelWorkNestedMetadataCreateInput;
  archive: MetadataCiTelWorkNestedArchiveCreateInput;
}

export interface MetadataCiTelWorkWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCiTelWorkNestedMetadataUpdateInput | null;
  archive?: MetadataCiTelWorkNestedArchiveUpdateInput | null;
}

export interface MetadataCiUrlWorkNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiUrlWorkNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiUrlWorkNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCiUrlWorkNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCiUrlWorkNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCiUrlWorkNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCiUrlWorkNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiUrlWorkNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiUrlWorkNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCiUrlWorkNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCiUrlWorkNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCiUrlWorkNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCiUrlWorkNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiUrlWorkNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiUrlWorkNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCiUrlWorkNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCiUrlWorkNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCiUrlWorkNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCiUrlWorkNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiUrlWorkNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCiUrlWorkNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiUrlWorkNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCiUrlWorkNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiUrlWorkNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCiUrlWorkNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiUrlWorkNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCiUrlWorkNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiUrlWorkNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCiUrlWorkNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCiUrlWorkNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCiUrlWork" nodes by specifying some conditions
 */
export interface MetadataCiUrlWorkWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCiUrlWorkWhereInput[] | null;
  OR?: MetadataCiUrlWorkWhereInput[] | null;
  NOT?: MetadataCiUrlWorkWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCiUrlWork" node.
 */
export interface MetadataCiUrlWorkWhereUniqueInput {
  id: number;
}

export interface MetadataCiUrlWorkWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCiUrlWorkNestedPhotoCreateInput;
  metadata: MetadataCiUrlWorkNestedMetadataCreateInput;
}

export interface MetadataCiUrlWorkWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCiUrlWorkNestedPhotoUpdateInput | null;
  metadata?: MetadataCiUrlWorkNestedMetadataUpdateInput | null;
}

export interface MetadataCiUrlWorkWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCiUrlWorkNestedPhotoCreateInput;
  archive: MetadataCiUrlWorkNestedArchiveCreateInput;
}

export interface MetadataCiUrlWorkWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCiUrlWorkNestedPhotoUpdateInput | null;
  archive?: MetadataCiUrlWorkNestedArchiveUpdateInput | null;
}

export interface MetadataCiUrlWorkWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCiUrlWorkNestedMetadataCreateInput;
  archive: MetadataCiUrlWorkNestedArchiveCreateInput;
}

export interface MetadataCiUrlWorkWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCiUrlWorkNestedMetadataUpdateInput | null;
  archive?: MetadataCiUrlWorkNestedArchiveUpdateInput | null;
}

export interface MetadataCityNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCityNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCityNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCityNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCityNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCityNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCityNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCityNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCityNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCityNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCityNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCityNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCityNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCityNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCityNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCityNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCityNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCityNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCityNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCityNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCityNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCityNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCityNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCityNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCityNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCityNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCityNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCityNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCityNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCityNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCity" nodes by specifying some conditions
 */
export interface MetadataCityWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCityWhereInput[] | null;
  OR?: MetadataCityWhereInput[] | null;
  NOT?: MetadataCityWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCity" node.
 */
export interface MetadataCityWhereUniqueInput {
  id: number;
}

export interface MetadataCityWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCityNestedPhotoCreateInput;
  metadata: MetadataCityNestedMetadataCreateInput;
}

export interface MetadataCityWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCityNestedPhotoUpdateInput | null;
  metadata?: MetadataCityNestedMetadataUpdateInput | null;
}

export interface MetadataCityWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCityNestedPhotoCreateInput;
  archive: MetadataCityNestedArchiveCreateInput;
}

export interface MetadataCityWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCityNestedPhotoUpdateInput | null;
  archive?: MetadataCityNestedArchiveUpdateInput | null;
}

export interface MetadataCityWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCityNestedMetadataCreateInput;
  archive: MetadataCityNestedArchiveCreateInput;
}

export interface MetadataCityWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCityNestedMetadataUpdateInput | null;
  archive?: MetadataCityNestedArchiveUpdateInput | null;
}

export interface MetadataClassifyNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataClassifyNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataClassifyNestedUpsertArchiveCreateInput | null;
}

export interface MetadataClassifyNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataClassifyNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataClassifyNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataClassifyNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataClassifyNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataClassifyNestedUpsertMetadataCreateInput | null;
}

export interface MetadataClassifyNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataClassifyNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataClassifyNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataClassifyNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataClassifyNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataClassifyNestedUpsertPhotoCreateInput | null;
}

export interface MetadataClassifyNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataClassifyNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataClassifyNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataClassifyNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataClassifyNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataClassifyNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataClassifyNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataClassifyNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataClassifyNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataClassifyNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataClassifyNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataClassifyNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataClassifyNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataClassifyNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataClassifyNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataClassify" nodes by specifying some conditions
 */
export interface MetadataClassifyWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataClassifyWhereInput[] | null;
  OR?: MetadataClassifyWhereInput[] | null;
  NOT?: MetadataClassifyWhereInput | null;
}

/**
 * Identifies exactly one "MetadataClassify" node.
 */
export interface MetadataClassifyWhereUniqueInput {
  id: number;
}

export interface MetadataClassifyWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataClassifyNestedPhotoCreateInput;
  metadata: MetadataClassifyNestedMetadataCreateInput;
}

export interface MetadataClassifyWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataClassifyNestedPhotoUpdateInput | null;
  metadata?: MetadataClassifyNestedMetadataUpdateInput | null;
}

export interface MetadataClassifyWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataClassifyNestedPhotoCreateInput;
  archive: MetadataClassifyNestedArchiveCreateInput;
}

export interface MetadataClassifyWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataClassifyNestedPhotoUpdateInput | null;
  archive?: MetadataClassifyNestedArchiveUpdateInput | null;
}

export interface MetadataClassifyWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataClassifyNestedMetadataCreateInput;
  archive: MetadataClassifyNestedArchiveCreateInput;
}

export interface MetadataClassifyWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataClassifyNestedMetadataUpdateInput | null;
  archive?: MetadataClassifyNestedArchiveUpdateInput | null;
}

export interface MetadataCompanyNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCompanyNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCompanyNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCompanyNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCompanyNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCompanyNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCompanyNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCompanyNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCompanyNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCompanyNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCompanyNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCompanyNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCompanyNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCompanyNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCompanyNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCompanyNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCompanyNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCompanyNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCompanyNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCompanyNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCompanyNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCompanyNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCompanyNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCompanyNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCompanyNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCompanyNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCompanyNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCompanyNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCompanyNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCompanyNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCompanyShortNameNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCompanyShortNameNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCompanyShortNameNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCompanyShortNameNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCompanyShortNameNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCompanyShortNameNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCompanyShortNameNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCompanyShortNameNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCompanyShortNameNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCompanyShortNameNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCompanyShortNameNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCompanyShortNameNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCompanyShortNameNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCompanyShortNameNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCompanyShortNameNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCompanyShortNameNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCompanyShortNameNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCompanyShortNameNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCompanyShortNameNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCompanyShortNameNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCompanyShortNameNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCompanyShortNameNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCompanyShortNameNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCompanyShortNameNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCompanyShortNameNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCompanyShortNameNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCompanyShortNameNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCompanyShortNameNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCompanyShortNameNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCompanyShortNameNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCompanyShortName" nodes by specifying some conditions
 */
export interface MetadataCompanyShortNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCompanyShortNameWhereInput[] | null;
  OR?: MetadataCompanyShortNameWhereInput[] | null;
  NOT?: MetadataCompanyShortNameWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCompanyShortName" node.
 */
export interface MetadataCompanyShortNameWhereUniqueInput {
  id: number;
}

export interface MetadataCompanyShortNameWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCompanyShortNameNestedPhotoCreateInput;
  metadata: MetadataCompanyShortNameNestedMetadataCreateInput;
}

export interface MetadataCompanyShortNameWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCompanyShortNameNestedPhotoUpdateInput | null;
  metadata?: MetadataCompanyShortNameNestedMetadataUpdateInput | null;
}

export interface MetadataCompanyShortNameWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCompanyShortNameNestedPhotoCreateInput;
  archive: MetadataCompanyShortNameNestedArchiveCreateInput;
}

export interface MetadataCompanyShortNameWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCompanyShortNameNestedPhotoUpdateInput | null;
  archive?: MetadataCompanyShortNameNestedArchiveUpdateInput | null;
}

export interface MetadataCompanyShortNameWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCompanyShortNameNestedMetadataCreateInput;
  archive: MetadataCompanyShortNameNestedArchiveCreateInput;
}

export interface MetadataCompanyShortNameWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCompanyShortNameNestedMetadataUpdateInput | null;
  archive?: MetadataCompanyShortNameNestedArchiveUpdateInput | null;
}

/**
 * Filters the "MetadataCompany" nodes by specifying some conditions
 */
export interface MetadataCompanyWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCompanyWhereInput[] | null;
  OR?: MetadataCompanyWhereInput[] | null;
  NOT?: MetadataCompanyWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCompany" node.
 */
export interface MetadataCompanyWhereUniqueInput {
  id: number;
}

export interface MetadataCompanyWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCompanyNestedPhotoCreateInput;
  metadata: MetadataCompanyNestedMetadataCreateInput;
}

export interface MetadataCompanyWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCompanyNestedPhotoUpdateInput | null;
  metadata?: MetadataCompanyNestedMetadataUpdateInput | null;
}

export interface MetadataCompanyWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCompanyNestedPhotoCreateInput;
  archive: MetadataCompanyNestedArchiveCreateInput;
}

export interface MetadataCompanyWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCompanyNestedPhotoUpdateInput | null;
  archive?: MetadataCompanyNestedArchiveUpdateInput | null;
}

export interface MetadataCompanyWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCompanyNestedMetadataCreateInput;
  archive: MetadataCompanyNestedArchiveCreateInput;
}

export interface MetadataCompanyWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCompanyNestedMetadataUpdateInput | null;
  archive?: MetadataCompanyNestedArchiveUpdateInput | null;
}

export interface MetadataConfidentialityNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataConfidentialityNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataConfidentialityNestedUpsertArchiveCreateInput | null;
}

export interface MetadataConfidentialityNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataConfidentialityNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataConfidentialityNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataConfidentialityNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataConfidentialityNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataConfidentialityNestedUpsertMetadataCreateInput | null;
}

export interface MetadataConfidentialityNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataConfidentialityNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataConfidentialityNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataConfidentialityNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataConfidentialityNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataConfidentialityNestedUpsertPhotoCreateInput | null;
}

export interface MetadataConfidentialityNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataConfidentialityNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataConfidentialityNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataConfidentialityNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataConfidentialityNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataConfidentialityNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataConfidentialityNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataConfidentialityNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataConfidentialityNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataConfidentialityNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataConfidentialityNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataConfidentialityNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataConfidentialityNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataConfidentialityNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataConfidentialityNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataConfidentiality" nodes by specifying some conditions
 */
export interface MetadataConfidentialityWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataConfidentialityWhereInput[] | null;
  OR?: MetadataConfidentialityWhereInput[] | null;
  NOT?: MetadataConfidentialityWhereInput | null;
}

/**
 * Identifies exactly one "MetadataConfidentiality" node.
 */
export interface MetadataConfidentialityWhereUniqueInput {
  id: number;
}

export interface MetadataConfidentialityWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataConfidentialityNestedPhotoCreateInput;
  metadata: MetadataConfidentialityNestedMetadataCreateInput;
}

export interface MetadataConfidentialityWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataConfidentialityNestedPhotoUpdateInput | null;
  metadata?: MetadataConfidentialityNestedMetadataUpdateInput | null;
}

export interface MetadataConfidentialityWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataConfidentialityNestedPhotoCreateInput;
  archive: MetadataConfidentialityNestedArchiveCreateInput;
}

export interface MetadataConfidentialityWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataConfidentialityNestedPhotoUpdateInput | null;
  archive?: MetadataConfidentialityNestedArchiveUpdateInput | null;
}

export interface MetadataConfidentialityWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataConfidentialityNestedMetadataCreateInput;
  archive: MetadataConfidentialityNestedArchiveCreateInput;
}

export interface MetadataConfidentialityWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataConfidentialityNestedMetadataUpdateInput | null;
  archive?: MetadataConfidentialityNestedArchiveUpdateInput | null;
}

export interface MetadataContainedInNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataContainedInNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataContainedInNestedUpsertArchiveCreateInput | null;
}

export interface MetadataContainedInNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataContainedInNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataContainedInNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataContainedInNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataContainedInNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataContainedInNestedUpsertMetadataCreateInput | null;
}

export interface MetadataContainedInNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataContainedInNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataContainedInNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataContainedInNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataContainedInNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataContainedInNestedUpsertPhotoCreateInput | null;
}

export interface MetadataContainedInNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataContainedInNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataContainedInNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataContainedInNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataContainedInNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataContainedInNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataContainedInNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataContainedInNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataContainedInNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataContainedInNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataContainedInNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataContainedInNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataContainedInNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataContainedInNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataContainedInNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataContainedIn" nodes by specifying some conditions
 */
export interface MetadataContainedInWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataContainedInWhereInput[] | null;
  OR?: MetadataContainedInWhereInput[] | null;
  NOT?: MetadataContainedInWhereInput | null;
}

/**
 * Identifies exactly one "MetadataContainedIn" node.
 */
export interface MetadataContainedInWhereUniqueInput {
  id: number;
}

export interface MetadataContainedInWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataContainedInNestedPhotoCreateInput;
  metadata: MetadataContainedInNestedMetadataCreateInput;
}

export interface MetadataContainedInWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataContainedInNestedPhotoUpdateInput | null;
  metadata?: MetadataContainedInNestedMetadataUpdateInput | null;
}

export interface MetadataContainedInWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataContainedInNestedPhotoCreateInput;
  archive: MetadataContainedInNestedArchiveCreateInput;
}

export interface MetadataContainedInWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataContainedInNestedPhotoUpdateInput | null;
  archive?: MetadataContainedInNestedArchiveUpdateInput | null;
}

export interface MetadataContainedInWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataContainedInNestedMetadataCreateInput;
  archive: MetadataContainedInNestedArchiveCreateInput;
}

export interface MetadataContainedInWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataContainedInNestedMetadataUpdateInput | null;
  archive?: MetadataContainedInNestedArchiveUpdateInput | null;
}

export interface MetadataContentMediaKindNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataContentMediaKindNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataContentMediaKindNestedUpsertArchiveCreateInput | null;
}

export interface MetadataContentMediaKindNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataContentMediaKindNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataContentMediaKindNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataContentMediaKindNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataContentMediaKindNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataContentMediaKindNestedUpsertMetadataCreateInput | null;
}

export interface MetadataContentMediaKindNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataContentMediaKindNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataContentMediaKindNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataContentMediaKindNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataContentMediaKindNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataContentMediaKindNestedUpsertPhotoCreateInput | null;
}

export interface MetadataContentMediaKindNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataContentMediaKindNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataContentMediaKindNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataContentMediaKindNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataContentMediaKindNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataContentMediaKindNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataContentMediaKindNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataContentMediaKindNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataContentMediaKindNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataContentMediaKindNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataContentMediaKindNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataContentMediaKindNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataContentMediaKindNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataContentMediaKindNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataContentMediaKindNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataContentMediaKind" nodes by specifying some conditions
 */
export interface MetadataContentMediaKindWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataContentMediaKindWhereInput[] | null;
  OR?: MetadataContentMediaKindWhereInput[] | null;
  NOT?: MetadataContentMediaKindWhereInput | null;
}

/**
 * Identifies exactly one "MetadataContentMediaKind" node.
 */
export interface MetadataContentMediaKindWhereUniqueInput {
  id: number;
}

export interface MetadataContentMediaKindWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataContentMediaKindNestedPhotoCreateInput;
  metadata: MetadataContentMediaKindNestedMetadataCreateInput;
}

export interface MetadataContentMediaKindWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataContentMediaKindNestedPhotoUpdateInput | null;
  metadata?: MetadataContentMediaKindNestedMetadataUpdateInput | null;
}

export interface MetadataContentMediaKindWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataContentMediaKindNestedPhotoCreateInput;
  archive: MetadataContentMediaKindNestedArchiveCreateInput;
}

export interface MetadataContentMediaKindWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataContentMediaKindNestedPhotoUpdateInput | null;
  archive?: MetadataContentMediaKindNestedArchiveUpdateInput | null;
}

export interface MetadataContentMediaKindWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataContentMediaKindNestedMetadataCreateInput;
  archive: MetadataContentMediaKindNestedArchiveCreateInput;
}

export interface MetadataContentMediaKindWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataContentMediaKindNestedMetadataUpdateInput | null;
  archive?: MetadataContentMediaKindNestedArchiveUpdateInput | null;
}

export interface MetadataContentValueNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataContentValueNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataContentValueNestedUpsertArchiveCreateInput | null;
}

export interface MetadataContentValueNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataContentValueNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataContentValueNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataContentValueNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataContentValueNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataContentValueNestedUpsertMetadataCreateInput | null;
}

export interface MetadataContentValueNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataContentValueNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataContentValueNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataContentValueNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataContentValueNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataContentValueNestedUpsertPhotoCreateInput | null;
}

export interface MetadataContentValueNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataContentValueNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataContentValueNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataContentValueNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataContentValueNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataContentValueNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataContentValueNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataContentValueNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataContentValueNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataContentValueNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataContentValueNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataContentValueNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataContentValueNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataContentValueNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataContentValueNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataContentValue" nodes by specifying some conditions
 */
export interface MetadataContentValueWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataContentValueWhereInput[] | null;
  OR?: MetadataContentValueWhereInput[] | null;
  NOT?: MetadataContentValueWhereInput | null;
}

/**
 * Identifies exactly one "MetadataContentValue" node.
 */
export interface MetadataContentValueWhereUniqueInput {
  id: number;
}

export interface MetadataContentValueWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataContentValueNestedPhotoCreateInput;
  metadata: MetadataContentValueNestedMetadataCreateInput;
}

export interface MetadataContentValueWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataContentValueNestedPhotoUpdateInput | null;
  metadata?: MetadataContentValueNestedMetadataUpdateInput | null;
}

export interface MetadataContentValueWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataContentValueNestedPhotoCreateInput;
  archive: MetadataContentValueNestedArchiveCreateInput;
}

export interface MetadataContentValueWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataContentValueNestedPhotoUpdateInput | null;
  archive?: MetadataContentValueNestedArchiveUpdateInput | null;
}

export interface MetadataContentValueWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataContentValueNestedMetadataCreateInput;
  archive: MetadataContentValueNestedArchiveCreateInput;
}

export interface MetadataContentValueWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataContentValueNestedMetadataUpdateInput | null;
  archive?: MetadataContentValueNestedArchiveUpdateInput | null;
}

export interface MetadataCopyrightLayerNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCopyrightLayerNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCopyrightLayerNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCopyrightLayerNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCopyrightLayerNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCopyrightLayerNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCopyrightLayerNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCopyrightLayerNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCopyrightLayerNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCopyrightLayerNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCopyrightLayerNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCopyrightLayerNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCopyrightLayerNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCopyrightLayerNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCopyrightLayerNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCopyrightLayerNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCopyrightLayerNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCopyrightLayerNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCopyrightLayerNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCopyrightLayerNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCopyrightLayerNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCopyrightLayerNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCopyrightLayerNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCopyrightLayerNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCopyrightLayerNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCopyrightLayerNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCopyrightLayerNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCopyrightLayerNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCopyrightLayerNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCopyrightLayerNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCopyrightLayer" nodes by specifying some conditions
 */
export interface MetadataCopyrightLayerWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCopyrightLayerWhereInput[] | null;
  OR?: MetadataCopyrightLayerWhereInput[] | null;
  NOT?: MetadataCopyrightLayerWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCopyrightLayer" node.
 */
export interface MetadataCopyrightLayerWhereUniqueInput {
  id: number;
}

export interface MetadataCopyrightLayerWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCopyrightLayerNestedPhotoCreateInput;
  metadata: MetadataCopyrightLayerNestedMetadataCreateInput;
}

export interface MetadataCopyrightLayerWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCopyrightLayerNestedPhotoUpdateInput | null;
  metadata?: MetadataCopyrightLayerNestedMetadataUpdateInput | null;
}

export interface MetadataCopyrightLayerWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCopyrightLayerNestedPhotoCreateInput;
  archive: MetadataCopyrightLayerNestedArchiveCreateInput;
}

export interface MetadataCopyrightLayerWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCopyrightLayerNestedPhotoUpdateInput | null;
  archive?: MetadataCopyrightLayerNestedArchiveUpdateInput | null;
}

export interface MetadataCopyrightLayerWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCopyrightLayerNestedMetadataCreateInput;
  archive: MetadataCopyrightLayerNestedArchiveCreateInput;
}

export interface MetadataCopyrightLayerWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCopyrightLayerNestedMetadataUpdateInput | null;
  archive?: MetadataCopyrightLayerNestedArchiveUpdateInput | null;
}

export interface MetadataCountryCodeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCountryCodeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCountryCodeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCountryCodeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCountryCodeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCountryCodeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCountryCodeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCountryCodeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCountryCodeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCountryCodeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCountryCodeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCountryCodeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCountryCodeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCountryCodeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCountryCodeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCountryCodeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCountryCodeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCountryCodeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCountryCodeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCountryCodeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCountryCodeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCountryCodeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCountryCodeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCountryCodeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCountryCodeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCountryCodeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCountryCodeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCountryCodeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCountryCodeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCountryCodeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCountryCode" nodes by specifying some conditions
 */
export interface MetadataCountryCodeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCountryCodeWhereInput[] | null;
  OR?: MetadataCountryCodeWhereInput[] | null;
  NOT?: MetadataCountryCodeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCountryCode" node.
 */
export interface MetadataCountryCodeWhereUniqueInput {
  id: number;
}

export interface MetadataCountryCodeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCountryCodeNestedPhotoCreateInput;
  metadata: MetadataCountryCodeNestedMetadataCreateInput;
}

export interface MetadataCountryCodeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCountryCodeNestedPhotoUpdateInput | null;
  metadata?: MetadataCountryCodeNestedMetadataUpdateInput | null;
}

export interface MetadataCountryCodeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCountryCodeNestedPhotoCreateInput;
  archive: MetadataCountryCodeNestedArchiveCreateInput;
}

export interface MetadataCountryCodeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCountryCodeNestedPhotoUpdateInput | null;
  archive?: MetadataCountryCodeNestedArchiveUpdateInput | null;
}

export interface MetadataCountryCodeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCountryCodeNestedMetadataCreateInput;
  archive: MetadataCountryCodeNestedArchiveCreateInput;
}

export interface MetadataCountryCodeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCountryCodeNestedMetadataUpdateInput | null;
  archive?: MetadataCountryCodeNestedArchiveUpdateInput | null;
}

export interface MetadataCountryNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCountryNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCountryNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCountryNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCountryNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCountryNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCountryNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCountryNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCountryNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCountryNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCountryNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCountryNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCountryNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCountryNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCountryNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCountryNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCountryNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCountryNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCountryNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCountryNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCountryNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCountryNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCountryNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCountryNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCountryNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCountryNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCountryNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCountryNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCountryNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCountryNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCountry" nodes by specifying some conditions
 */
export interface MetadataCountryWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCountryWhereInput[] | null;
  OR?: MetadataCountryWhereInput[] | null;
  NOT?: MetadataCountryWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCountry" node.
 */
export interface MetadataCountryWhereUniqueInput {
  id: number;
}

export interface MetadataCountryWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCountryNestedPhotoCreateInput;
  metadata: MetadataCountryNestedMetadataCreateInput;
}

export interface MetadataCountryWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCountryNestedPhotoUpdateInput | null;
  metadata?: MetadataCountryNestedMetadataUpdateInput | null;
}

export interface MetadataCountryWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCountryNestedPhotoCreateInput;
  archive: MetadataCountryNestedArchiveCreateInput;
}

export interface MetadataCountryWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCountryNestedPhotoUpdateInput | null;
  archive?: MetadataCountryNestedArchiveUpdateInput | null;
}

export interface MetadataCountryWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCountryNestedMetadataCreateInput;
  archive: MetadataCountryNestedArchiveCreateInput;
}

export interface MetadataCountryWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCountryNestedMetadataUpdateInput | null;
  archive?: MetadataCountryNestedArchiveUpdateInput | null;
}

export interface MetadataCreateInput {
  id?: string | null;
  name: MetadataNameEnumType;
  legacyName?: string | null;
  label: string;
  namespace: string;
  structName?: string | null;
  type: MetadataValueTypeEnum;
  photoMetadatas?: MetadataNestedPhotoMetadatasCreateInput | null;
  metadataSerialNumberList?: MetadataNestedMetadataSerialNumberListCreateInput | null;
  metadataAttributionNameList?: MetadataNestedMetadataAttributionNameListCreateInput | null;
  metadataAttributionUrlList?: MetadataNestedMetadataAttributionUrlListCreateInput | null;
  metadataLicenseList?: MetadataNestedMetadataLicenseListCreateInput | null;
  metadataMorePermissionsList?: MetadataNestedMetadataMorePermissionsListCreateInput | null;
  metadataPaintBasedCorrectionsList?: MetadataNestedMetadataPaintBasedCorrectionsListCreateInput | null;
  metadataToneCurveList?: MetadataNestedMetadataToneCurveListCreateInput | null;
  metadataCreatorList?: MetadataNestedMetadataCreatorListCreateInput | null;
  metadataDescriptionList?: MetadataNestedMetadataDescriptionListCreateInput | null;
  metadataFormatList?: MetadataNestedMetadataFormatListCreateInput | null;
  metadataPageList?: MetadataNestedMetadataPageListCreateInput | null;
  metadataPublisherList?: MetadataNestedMetadataPublisherListCreateInput | null;
  metadataRightsList?: MetadataNestedMetadataRightsListCreateInput | null;
  metadataSubjectList?: MetadataNestedMetadataSubjectListCreateInput | null;
  metadataTitleList?: MetadataNestedMetadataTitleListCreateInput | null;
  metadataTypeList?: MetadataNestedMetadataTypeListCreateInput | null;
  metadataAccessRightsList?: MetadataNestedMetadataAccessRightsListCreateInput | null;
  metadataAudienceList?: MetadataNestedMetadataAudienceListCreateInput | null;
  metadataValidList?: MetadataNestedMetadataValidListCreateInput | null;
  metadataEquipmentInstitutionList?: MetadataNestedMetadataEquipmentInstitutionListCreateInput | null;
  metadataEquipmentManufacturerList?: MetadataNestedMetadataEquipmentManufacturerListCreateInput | null;
  metadataFileInfoBitsPerPixelList?: MetadataNestedMetadataFileInfoBitsPerPixelListCreateInput | null;
  metadataFileInfoFramesList?: MetadataNestedMetadataFileInfoFramesListCreateInput | null;
  metadataFileInfoPhotometricInterpretationList?: MetadataNestedMetadataFileInfoPhotometricInterpretationListCreateInput | null;
  metadataFileInfoTransferSyntaxList?: MetadataNestedMetadataFileInfoTransferSyntaxListCreateInput | null;
  metadataPatientDobList?: MetadataNestedMetadataPatientDobListCreateInput | null;
  metadataPatientIdList?: MetadataNestedMetadataPatientIdListCreateInput | null;
  metadataPatientNameList?: MetadataNestedMetadataPatientNameListCreateInput | null;
  metadataPatientSexList?: MetadataNestedMetadataPatientSexListCreateInput | null;
  metadataSeriesDateTimeList?: MetadataNestedMetadataSeriesDateTimeListCreateInput | null;
  metadataSeriesDescriptionList?: MetadataNestedMetadataSeriesDescriptionListCreateInput | null;
  metadataSeriesModalityList?: MetadataNestedMetadataSeriesModalityListCreateInput | null;
  metadataSeriesNumberList?: MetadataNestedMetadataSeriesNumberListCreateInput | null;
  metadataStudyDateTimeList?: MetadataNestedMetadataStudyDateTimeListCreateInput | null;
  metadataStudyDescriptionList?: MetadataNestedMetadataStudyDescriptionListCreateInput | null;
  metadataStudyIdList?: MetadataNestedMetadataStudyIdListCreateInput | null;
  metadataStudyPhysicianList?: MetadataNestedMetadataStudyPhysicianListCreateInput | null;
  metadataDateTimeDigitizedList?: MetadataNestedMetadataDateTimeDigitizedListCreateInput | null;
  metadataDateTimeOriginalList?: MetadataNestedMetadataDateTimeOriginalListCreateInput | null;
  metadataGpsLatitudeList?: MetadataNestedMetadataGpsLatitudeListCreateInput | null;
  metadataGpsLongitudeList?: MetadataNestedMetadataGpsLongitudeListCreateInput | null;
  metadataGpsProcessingMethodList?: MetadataNestedMetadataGpsProcessingMethodListCreateInput | null;
  metadataImageUniqueIdList?: MetadataNestedMetadataImageUniqueIdListCreateInput | null;
  metadataIsoSpeedRatingsList?: MetadataNestedMetadataIsoSpeedRatingsListCreateInput | null;
  metadataUserCommentList?: MetadataNestedMetadataUserCommentListCreateInput | null;
  metadataCustomField14List?: MetadataNestedMetadataCustomField14ListCreateInput | null;
  metadataCustomField15List?: MetadataNestedMetadataCustomField15ListCreateInput | null;
  metadataCustomField16List?: MetadataNestedMetadataCustomField16ListCreateInput | null;
  metadataCustomField17List?: MetadataNestedMetadataCustomField17ListCreateInput | null;
  metadataCustomField20List?: MetadataNestedMetadataCustomField20ListCreateInput | null;
  metadataCustomField5List?: MetadataNestedMetadataCustomField5ListCreateInput | null;
  metadataCustomField6List?: MetadataNestedMetadataCustomField6ListCreateInput | null;
  metadataCustomField7List?: MetadataNestedMetadataCustomField7ListCreateInput | null;
  metadataCustomField9List?: MetadataNestedMetadataCustomField9ListCreateInput | null;
  metadataCreatedTimeList?: MetadataNestedMetadataCreatedTimeListCreateInput | null;
  metadataEditStatusList?: MetadataNestedMetadataEditStatusListCreateInput | null;
  metadataFixtureIdentifierList?: MetadataNestedMetadataFixtureIdentifierListCreateInput | null;
  metadataLocalCaptionList?: MetadataNestedMetadataLocalCaptionListCreateInput | null;
  metadataProgramVersionList?: MetadataNestedMetadataProgramVersionListCreateInput | null;
  metadataReferenceDateList?: MetadataNestedMetadataReferenceDateListCreateInput | null;
  metadataReferenceNumberList?: MetadataNestedMetadataReferenceNumberListCreateInput | null;
  metadataReferenceServiceList?: MetadataNestedMetadataReferenceServiceListCreateInput | null;
  metadataReleaseDateList?: MetadataNestedMetadataReleaseDateListCreateInput | null;
  metadataReleaseTimeList?: MetadataNestedMetadataReleaseTimeListCreateInput | null;
  metadataAssetStateList?: MetadataNestedMetadataAssetStateListCreateInput | null;
  metadataClassifyList?: MetadataNestedMetadataClassifyListCreateInput | null;
  metadataContainedInList?: MetadataNestedMetadataContainedInListCreateInput | null;
  metadataContentValueList?: MetadataNestedMetadataContentValueListCreateInput | null;
  metadataDocumentTextList?: MetadataNestedMetadataDocumentTextListCreateInput | null;
  metadataExifList?: MetadataNestedMetadataExifListCreateInput | null;
  metadataHistoryList?: MetadataNestedMetadataHistoryListCreateInput | null;
  metadataImageNotesList?: MetadataNestedMetadataImageNotesListCreateInput | null;
  metadataJobIdList?: MetadataNestedMetadataJobIdListCreateInput | null;
  metadataLinksList?: MetadataNestedMetadataLinksListCreateInput | null;
  metadataMasterDocumentIdList?: MetadataNestedMetadataMasterDocumentIdListCreateInput | null;
  metadataObjectCycleList?: MetadataNestedMetadataObjectCycleListCreateInput | null;
  metadataOwnerIdList?: MetadataNestedMetadataOwnerIdListCreateInput | null;
  metadataShortUniqueIdList?: MetadataNestedMetadataShortUniqueIdListCreateInput | null;
  metadataTransferredByList?: MetadataNestedMetadataTransferredByListCreateInput | null;
  metadataTransferredByEmailList?: MetadataNestedMetadataTransferredByEmailListCreateInput | null;
  metadataTransferredByFullNameList?: MetadataNestedMetadataTransferredByFullNameListCreateInput | null;
  metadataUniqueIdList?: MetadataNestedMetadataUniqueIdListCreateInput | null;
  metadataUploadedByList?: MetadataNestedMetadataUploadedByListCreateInput | null;
  metadataUploadedByFullNameList?: MetadataNestedMetadataUploadedByFullNameListCreateInput | null;
  metadataUploadTimeList?: MetadataNestedMetadataUploadTimeListCreateInput | null;
  metadataUserDefined195List?: MetadataNestedMetadataUserDefined195ListCreateInput | null;
  metadataUserDefined237List?: MetadataNestedMetadataUserDefined237ListCreateInput | null;
  metadataUserDefined238List?: MetadataNestedMetadataUserDefined238ListCreateInput | null;
  metadataUserDefined239List?: MetadataNestedMetadataUserDefined239ListCreateInput | null;
  metadataUserDefined242List?: MetadataNestedMetadataUserDefined242ListCreateInput | null;
  metadataUserDefined62List?: MetadataNestedMetadataUserDefined62ListCreateInput | null;
  metadataCiAdrCityList?: MetadataNestedMetadataCiAdrCityListCreateInput | null;
  metadataCiAdrCtryList?: MetadataNestedMetadataCiAdrCtryListCreateInput | null;
  metadataCiAdrExtadrList?: MetadataNestedMetadataCiAdrExtadrListCreateInput | null;
  metadataCiAdrPcodeList?: MetadataNestedMetadataCiAdrPcodeListCreateInput | null;
  metadataCiAdrRegionList?: MetadataNestedMetadataCiAdrRegionListCreateInput | null;
  metadataCiEmailWorkList?: MetadataNestedMetadataCiEmailWorkListCreateInput | null;
  metadataCiTelWorkList?: MetadataNestedMetadataCiTelWorkListCreateInput | null;
  metadataCiUrlWorkList?: MetadataNestedMetadataCiUrlWorkListCreateInput | null;
  metadataCountryCodeList?: MetadataNestedMetadataCountryCodeListCreateInput | null;
  metadataIntellectualGenreList?: MetadataNestedMetadataIntellectualGenreListCreateInput | null;
  metadataLocationList?: MetadataNestedMetadataLocationListCreateInput | null;
  metadataSceneList?: MetadataNestedMetadataSceneListCreateInput | null;
  metadataSubjectCodeList?: MetadataNestedMetadataSubjectCodeListCreateInput | null;
  metadataAddlModelInfoList?: MetadataNestedMetadataAddlModelInfoListCreateInput | null;
  metadataEventList?: MetadataNestedMetadataEventListCreateInput | null;
  metadataMaxAvailHeightList?: MetadataNestedMetadataMaxAvailHeightListCreateInput | null;
  metadataMaxAvailWidthList?: MetadataNestedMetadataMaxAvailWidthListCreateInput | null;
  metadataModelAgeList?: MetadataNestedMetadataModelAgeListCreateInput | null;
  metadataOrganisationInImageNameList?: MetadataNestedMetadataOrganisationInImageNameListCreateInput | null;
  metadataPersonInImageList?: MetadataNestedMetadataPersonInImageListCreateInput | null;
  metadataAuthorsPositionList?: MetadataNestedMetadataAuthorsPositionListCreateInput | null;
  metadataCaptionWriterList?: MetadataNestedMetadataCaptionWriterListCreateInput | null;
  metadataCategoryList?: MetadataNestedMetadataCategoryListCreateInput | null;
  metadataCityList?: MetadataNestedMetadataCityListCreateInput | null;
  metadataCountryList?: MetadataNestedMetadataCountryListCreateInput | null;
  metadataCreditList?: MetadataNestedMetadataCreditListCreateInput | null;
  metadataDateCreatedList?: MetadataNestedMetadataDateCreatedListCreateInput | null;
  metadataHeadlineList?: MetadataNestedMetadataHeadlineListCreateInput | null;
  metadataIccProfileList?: MetadataNestedMetadataIccProfileListCreateInput | null;
  metadataInstructionsList?: MetadataNestedMetadataInstructionsListCreateInput | null;
  metadataSourceList?: MetadataNestedMetadataSourceListCreateInput | null;
  metadataStateList?: MetadataNestedMetadataStateListCreateInput | null;
  metadataSupplementalCategoriesList?: MetadataNestedMetadataSupplementalCategoriesListCreateInput | null;
  metadataTransmissionReferenceList?: MetadataNestedMetadataTransmissionReferenceListCreateInput | null;
  metadataUrgencyList?: MetadataNestedMetadataUrgencyListCreateInput | null;
  metadataMinorModelAgeDisclosureList?: MetadataNestedMetadataMinorModelAgeDisclosureListCreateInput | null;
  metadataModelReleaseIdList?: MetadataNestedMetadataModelReleaseIdListCreateInput | null;
  metadataModelReleaseStatusList?: MetadataNestedMetadataModelReleaseStatusListCreateInput | null;
  metadataPropertyReleaseIdList?: MetadataNestedMetadataPropertyReleaseIdListCreateInput | null;
  metadataPropertyReleaseStatusList?: MetadataNestedMetadataPropertyReleaseStatusListCreateInput | null;
  metadataVersionList?: MetadataNestedMetadataVersionListCreateInput | null;
  metadataBrandList?: MetadataNestedMetadataBrandListCreateInput | null;
  metadataDivisionList?: MetadataNestedMetadataDivisionListCreateInput | null;
  metadataHeaderList?: MetadataNestedMetadataHeaderListCreateInput | null;
  metadataProductsList?: MetadataNestedMetadataProductsListCreateInput | null;
  metadataProduitsList?: MetadataNestedMetadataProduitsListCreateInput | null;
  metadataPublishingIssueList?: MetadataNestedMetadataPublishingIssueListCreateInput | null;
  metadataPublishingSubjectList?: MetadataNestedMetadataPublishingSubjectListCreateInput | null;
  metadataCompanyList?: MetadataNestedMetadataCompanyListCreateInput | null;
  metadataCompanyShortNameList?: MetadataNestedMetadataCompanyShortNameListCreateInput | null;
  metadataConfidentialityList?: MetadataNestedMetadataConfidentialityListCreateInput | null;
  metadataEditorialVersionList?: MetadataNestedMetadataEditorialVersionListCreateInput | null;
  metadataFileCheckForList?: MetadataNestedMetadataFileCheckForListCreateInput | null;
  metadataFileStageList?: MetadataNestedMetadataFileStageListCreateInput | null;
  metadataFileStatusList?: MetadataNestedMetadataFileStatusListCreateInput | null;
  metadataSentToList?: MetadataNestedMetadataSentToListCreateInput | null;
  metadataTargetVersionList?: MetadataNestedMetadataTargetVersionListCreateInput | null;
  metadataUnitList?: MetadataNestedMetadataUnitListCreateInput | null;
  metadataArchiveChildList?: MetadataNestedMetadataArchiveChildListCreateInput | null;
  metadataArchiveParentList?: MetadataNestedMetadataArchiveParentListCreateInput | null;
  metadataBackupNameList?: MetadataNestedMetadataBackupNameListCreateInput | null;
  metadataBitmapGrayscalePictureList?: MetadataNestedMetadataBitmapGrayscalePictureListCreateInput | null;
  metadataBrandPrismaList?: MetadataNestedMetadataBrandPrismaListCreateInput | null;
  metadataBrandShortNameList?: MetadataNestedMetadataBrandShortNameListCreateInput | null;
  metadataCategoryPrismaList?: MetadataNestedMetadataCategoryPrismaListCreateInput | null;
  metadataContentMediaKindList?: MetadataNestedMetadataContentMediaKindListCreateInput | null;
  metadataCopyrightLayerList?: MetadataNestedMetadataCopyrightLayerListCreateInput | null;
  metadataDeliveryAccountList?: MetadataNestedMetadataDeliveryAccountListCreateInput | null;
  metadataDeliveryCompanyList?: MetadataNestedMetadataDeliveryCompanyListCreateInput | null;
  metadataDeliveryCopyrightList?: MetadataNestedMetadataDeliveryCopyrightListCreateInput | null;
  metadataDeliveryDateFolderList?: MetadataNestedMetadataDeliveryDateFolderListCreateInput | null;
  metadataDeliveryDateTimeList?: MetadataNestedMetadataDeliveryDateTimeListCreateInput | null;
  metadataDeliveryDcCreatorList?: MetadataNestedMetadataDeliveryDcCreatorListCreateInput | null;
  metadataDeliveryDcRightsList?: MetadataNestedMetadataDeliveryDcRightsListCreateInput | null;
  metadataDeliveryFileTypeList?: MetadataNestedMetadataDeliveryFileTypeListCreateInput | null;
  metadataDeliveryFolderList?: MetadataNestedMetadataDeliveryFolderListCreateInput | null;
  metadataDeliveryKindList?: MetadataNestedMetadataDeliveryKindListCreateInput | null;
  metadataDeliveryPathList?: MetadataNestedMetadataDeliveryPathListCreateInput | null;
  metadataDeliveryPersonShownintheImageList?: MetadataNestedMetadataDeliveryPersonShownintheImageListCreateInput | null;
  metadataDeliveryPhotoshopCreditList?: MetadataNestedMetadataDeliveryPhotoshopCreditListCreateInput | null;
  metadataDeliveryPhotoshopSourceList?: MetadataNestedMetadataDeliveryPhotoshopSourceListCreateInput | null;
  metadataDeliveryServiceList?: MetadataNestedMetadataDeliveryServiceListCreateInput | null;
  metadataDeliverySubjectList?: MetadataNestedMetadataDeliverySubjectListCreateInput | null;
  metadataDeliveryUnderSubjectList?: MetadataNestedMetadataDeliveryUnderSubjectListCreateInput | null;
  metadataDepartmentList?: MetadataNestedMetadataDepartmentListCreateInput | null;
  metadataDigitalAssetUrlList?: MetadataNestedMetadataDigitalAssetUrlListCreateInput | null;
  metadataEditionList?: MetadataNestedMetadataEditionListCreateInput | null;
  metadataEnvironnementPhotoList?: MetadataNestedMetadataEnvironnementPhotoListCreateInput | null;
  metadataFabStorageList?: MetadataNestedMetadataFabStorageListCreateInput | null;
  metadataFileTypeList?: MetadataNestedMetadataFileTypeListCreateInput | null;
  metadataHeaderPrismaList?: MetadataNestedMetadataHeaderPrismaListCreateInput | null;
  metadataIdSubjectList?: MetadataNestedMetadataIdSubjectListCreateInput | null;
  metadataIdAssignmentList?: MetadataNestedMetadataIdAssignmentListCreateInput | null;
  metadataIdMediaContentList?: MetadataNestedMetadataIdMediaContentListCreateInput | null;
  metadataIdPhotoPrismaList?: MetadataNestedMetadataIdPhotoPrismaListCreateInput | null;
  metadataIssueList?: MetadataNestedMetadataIssueListCreateInput | null;
  metadataJobProcessingList?: MetadataNestedMetadataJobProcessingListCreateInput | null;
  metadataLayoutPictureEditingList?: MetadataNestedMetadataLayoutPictureEditingListCreateInput | null;
  metadataLayoutStorageList?: MetadataNestedMetadataLayoutStorageListCreateInput | null;
  metadataOriginalColorList?: MetadataNestedMetadataOriginalColorListCreateInput | null;
  metadataOriginalIccProfileList?: MetadataNestedMetadataOriginalIccProfileListCreateInput | null;
  metadataOriginalNameList?: MetadataNestedMetadataOriginalNameListCreateInput | null;
  metadataOriginalWeigthList?: MetadataNestedMetadataOriginalWeigthListCreateInput | null;
  metadataPagePrismaList?: MetadataNestedMetadataPagePrismaListCreateInput | null;
  metadataPhotoStorageList?: MetadataNestedMetadataPhotoStorageListCreateInput | null;
  metadataPrepressPictureEditingList?: MetadataNestedMetadataPrepressPictureEditingListCreateInput | null;
  metadataPriceLevelList?: MetadataNestedMetadataPriceLevelListCreateInput | null;
  metadataPrintingProfileList?: MetadataNestedMetadataPrintingProfileListCreateInput | null;
  metadataPrismaProductionList?: MetadataNestedMetadataPrismaProductionListCreateInput | null;
  metadataProcessHistoryList?: MetadataNestedMetadataProcessHistoryListCreateInput | null;
  metadataProcessParameterList?: MetadataNestedMetadataProcessParameterListCreateInput | null;
  metadataProcessStatusList?: MetadataNestedMetadataProcessStatusListCreateInput | null;
  metadataProductList?: MetadataNestedMetadataProductListCreateInput | null;
  metadataProductShortNameList?: MetadataNestedMetadataProductShortNameListCreateInput | null;
  metadataQualifiedUsePrismaByList?: MetadataNestedMetadataQualifiedUsePrismaByListCreateInput | null;
  metadataQualifiedUsePrismaDateList?: MetadataNestedMetadataQualifiedUsePrismaDateListCreateInput | null;
  metadataQualifiedUsePrismaDurationList?: MetadataNestedMetadataQualifiedUsePrismaDurationListCreateInput | null;
  metadataQualifiedUsePrismaSupportList?: MetadataNestedMetadataQualifiedUsePrismaSupportListCreateInput | null;
  metadataRequiredPermissionList?: MetadataNestedMetadataRequiredPermissionListCreateInput | null;
  metadataResolutionKindList?: MetadataNestedMetadataResolutionKindListCreateInput | null;
  metadataRoyaltyfreeList?: MetadataNestedMetadataRoyaltyfreeListCreateInput | null;
  metadataSectionList?: MetadataNestedMetadataSectionListCreateInput | null;
  metadataSubjectPrismaList?: MetadataNestedMetadataSubjectPrismaListCreateInput | null;
  metadataUndersubjectList?: MetadataNestedMetadataUndersubjectListCreateInput | null;
  metadataUnderUnderSubjectList?: MetadataNestedMetadataUnderUnderSubjectListCreateInput | null;
  metadataUnitShortNameList?: MetadataNestedMetadataUnitShortNameListCreateInput | null;
  metadataWorkflowKindList?: MetadataNestedMetadataWorkflowKindListCreateInput | null;
  metadataMakeList?: MetadataNestedMetadataMakeListCreateInput | null;
  metadataModelList?: MetadataNestedMetadataModelListCreateInput | null;
  metadataCreatorToolList?: MetadataNestedMetadataCreatorToolListCreateInput | null;
  metadataRatingList?: MetadataNestedMetadataRatingListCreateInput | null;
  metadataXmpFileStampsList?: MetadataNestedMetadataXmpFileStampsListCreateInput | null;
  metadataManifestList?: MetadataNestedMetadataManifestListCreateInput | null;
  metadataXmpHistoryList?: MetadataNestedMetadataXmpHistoryListCreateInput | null;
  metadataCertificateList?: MetadataNestedMetadataCertificateListCreateInput | null;
  metadataMarkedList?: MetadataNestedMetadataMarkedListCreateInput | null;
  metadataOwnerList?: MetadataNestedMetadataOwnerListCreateInput | null;
  metadataUsageTermsList?: MetadataNestedMetadataUsageTermsListCreateInput | null;
  metadataWebStatementList?: MetadataNestedMetadataWebStatementListCreateInput | null;
}

export interface MetadataCreatedTimeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCreatedTimeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCreatedTimeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCreatedTimeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCreatedTimeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCreatedTimeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCreatedTimeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCreatedTimeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCreatedTimeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCreatedTimeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCreatedTimeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCreatedTimeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCreatedTimeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCreatedTimeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCreatedTimeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCreatedTimeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCreatedTimeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCreatedTimeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCreatedTimeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCreatedTimeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCreatedTimeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCreatedTimeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCreatedTimeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCreatedTimeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCreatedTimeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCreatedTimeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCreatedTimeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCreatedTimeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCreatedTimeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCreatedTimeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCreatedTime" nodes by specifying some conditions
 */
export interface MetadataCreatedTimeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCreatedTimeWhereInput[] | null;
  OR?: MetadataCreatedTimeWhereInput[] | null;
  NOT?: MetadataCreatedTimeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCreatedTime" node.
 */
export interface MetadataCreatedTimeWhereUniqueInput {
  id: number;
}

export interface MetadataCreatedTimeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCreatedTimeNestedPhotoCreateInput;
  metadata: MetadataCreatedTimeNestedMetadataCreateInput;
}

export interface MetadataCreatedTimeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCreatedTimeNestedPhotoUpdateInput | null;
  metadata?: MetadataCreatedTimeNestedMetadataUpdateInput | null;
}

export interface MetadataCreatedTimeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCreatedTimeNestedPhotoCreateInput;
  archive: MetadataCreatedTimeNestedArchiveCreateInput;
}

export interface MetadataCreatedTimeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCreatedTimeNestedPhotoUpdateInput | null;
  archive?: MetadataCreatedTimeNestedArchiveUpdateInput | null;
}

export interface MetadataCreatedTimeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCreatedTimeNestedMetadataCreateInput;
  archive: MetadataCreatedTimeNestedArchiveCreateInput;
}

export interface MetadataCreatedTimeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCreatedTimeNestedMetadataUpdateInput | null;
  archive?: MetadataCreatedTimeNestedArchiveUpdateInput | null;
}

export interface MetadataCreatorNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCreatorNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCreatorNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCreatorNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCreatorNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCreatorNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCreatorNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCreatorNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCreatorNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCreatorNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCreatorNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCreatorNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCreatorNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCreatorNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCreatorNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCreatorNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCreatorNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCreatorNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCreatorNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCreatorNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCreatorNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCreatorNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCreatorNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCreatorNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCreatorNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCreatorNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCreatorNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCreatorNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCreatorNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCreatorNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCreatorToolNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCreatorToolNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCreatorToolNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCreatorToolNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCreatorToolNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCreatorToolNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCreatorToolNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCreatorToolNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCreatorToolNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCreatorToolNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCreatorToolNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCreatorToolNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCreatorToolNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCreatorToolNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCreatorToolNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCreatorToolNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCreatorToolNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCreatorToolNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCreatorToolNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCreatorToolNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCreatorToolNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCreatorToolNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCreatorToolNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCreatorToolNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCreatorToolNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCreatorToolNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCreatorToolNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCreatorToolNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCreatorToolNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCreatorToolNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCreatorTool" nodes by specifying some conditions
 */
export interface MetadataCreatorToolWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCreatorToolWhereInput[] | null;
  OR?: MetadataCreatorToolWhereInput[] | null;
  NOT?: MetadataCreatorToolWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCreatorTool" node.
 */
export interface MetadataCreatorToolWhereUniqueInput {
  id: number;
}

export interface MetadataCreatorToolWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCreatorToolNestedPhotoCreateInput;
  metadata: MetadataCreatorToolNestedMetadataCreateInput;
}

export interface MetadataCreatorToolWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCreatorToolNestedPhotoUpdateInput | null;
  metadata?: MetadataCreatorToolNestedMetadataUpdateInput | null;
}

export interface MetadataCreatorToolWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCreatorToolNestedPhotoCreateInput;
  archive: MetadataCreatorToolNestedArchiveCreateInput;
}

export interface MetadataCreatorToolWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCreatorToolNestedPhotoUpdateInput | null;
  archive?: MetadataCreatorToolNestedArchiveUpdateInput | null;
}

export interface MetadataCreatorToolWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCreatorToolNestedMetadataCreateInput;
  archive: MetadataCreatorToolNestedArchiveCreateInput;
}

export interface MetadataCreatorToolWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCreatorToolNestedMetadataUpdateInput | null;
  archive?: MetadataCreatorToolNestedArchiveUpdateInput | null;
}

/**
 * Filters the "MetadataCreator" nodes by specifying some conditions
 */
export interface MetadataCreatorWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCreatorWhereInput[] | null;
  OR?: MetadataCreatorWhereInput[] | null;
  NOT?: MetadataCreatorWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCreator" node.
 */
export interface MetadataCreatorWhereUniqueInput {
  id: number;
}

export interface MetadataCreatorWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCreatorNestedPhotoCreateInput;
  metadata: MetadataCreatorNestedMetadataCreateInput;
}

export interface MetadataCreatorWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCreatorNestedPhotoUpdateInput | null;
  metadata?: MetadataCreatorNestedMetadataUpdateInput | null;
}

export interface MetadataCreatorWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCreatorNestedPhotoCreateInput;
  archive: MetadataCreatorNestedArchiveCreateInput;
}

export interface MetadataCreatorWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCreatorNestedPhotoUpdateInput | null;
  archive?: MetadataCreatorNestedArchiveUpdateInput | null;
}

export interface MetadataCreatorWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCreatorNestedMetadataCreateInput;
  archive: MetadataCreatorNestedArchiveCreateInput;
}

export interface MetadataCreatorWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCreatorNestedMetadataUpdateInput | null;
  archive?: MetadataCreatorNestedArchiveUpdateInput | null;
}

export interface MetadataCreditNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCreditNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCreditNestedUpsertArchiveCreateInput | null;
}

export interface MetadataCreditNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCreditNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCreditNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCreditNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCreditNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCreditNestedUpsertMetadataCreateInput | null;
}

export interface MetadataCreditNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCreditNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCreditNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCreditNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCreditNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCreditNestedUpsertPhotoCreateInput | null;
}

export interface MetadataCreditNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCreditNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCreditNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCreditNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCreditNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCreditNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCreditNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCreditNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCreditNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCreditNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCreditNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCreditNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCreditNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCreditNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCreditNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCredit" nodes by specifying some conditions
 */
export interface MetadataCreditWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCreditWhereInput[] | null;
  OR?: MetadataCreditWhereInput[] | null;
  NOT?: MetadataCreditWhereInput | null;
}

/**
 * Identifies exactly one "MetadataCredit" node.
 */
export interface MetadataCreditWhereUniqueInput {
  id: number;
}

export interface MetadataCreditWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCreditNestedPhotoCreateInput;
  metadata: MetadataCreditNestedMetadataCreateInput;
}

export interface MetadataCreditWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCreditNestedPhotoUpdateInput | null;
  metadata?: MetadataCreditNestedMetadataUpdateInput | null;
}

export interface MetadataCreditWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCreditNestedPhotoCreateInput;
  archive: MetadataCreditNestedArchiveCreateInput;
}

export interface MetadataCreditWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCreditNestedPhotoUpdateInput | null;
  archive?: MetadataCreditNestedArchiveUpdateInput | null;
}

export interface MetadataCreditWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCreditNestedMetadataCreateInput;
  archive: MetadataCreditNestedArchiveCreateInput;
}

export interface MetadataCreditWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCreditNestedMetadataUpdateInput | null;
  archive?: MetadataCreditNestedArchiveUpdateInput | null;
}

export interface MetadataCustomField14NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField14NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField14NestedUpsertArchiveCreateInput | null;
}

export interface MetadataCustomField14NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField14NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField14NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCustomField14NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField14NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField14NestedUpsertMetadataCreateInput | null;
}

export interface MetadataCustomField14NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField14NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField14NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCustomField14NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField14NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField14NestedUpsertPhotoCreateInput | null;
}

export interface MetadataCustomField14NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField14NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField14NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCustomField14NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField14NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField14NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField14NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField14NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField14NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField14NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField14NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField14NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField14NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField14NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCustomField14NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCustomField14" nodes by specifying some conditions
 */
export interface MetadataCustomField14WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField14WhereInput[] | null;
  OR?: MetadataCustomField14WhereInput[] | null;
  NOT?: MetadataCustomField14WhereInput | null;
}

/**
 * Identifies exactly one "MetadataCustomField14" node.
 */
export interface MetadataCustomField14WhereUniqueInput {
  id: number;
}

export interface MetadataCustomField14WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCustomField14NestedPhotoCreateInput;
  metadata: MetadataCustomField14NestedMetadataCreateInput;
}

export interface MetadataCustomField14WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField14NestedPhotoUpdateInput | null;
  metadata?: MetadataCustomField14NestedMetadataUpdateInput | null;
}

export interface MetadataCustomField14WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCustomField14NestedPhotoCreateInput;
  archive: MetadataCustomField14NestedArchiveCreateInput;
}

export interface MetadataCustomField14WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField14NestedPhotoUpdateInput | null;
  archive?: MetadataCustomField14NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField14WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCustomField14NestedMetadataCreateInput;
  archive: MetadataCustomField14NestedArchiveCreateInput;
}

export interface MetadataCustomField14WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCustomField14NestedMetadataUpdateInput | null;
  archive?: MetadataCustomField14NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField15NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField15NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField15NestedUpsertArchiveCreateInput | null;
}

export interface MetadataCustomField15NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField15NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField15NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCustomField15NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField15NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField15NestedUpsertMetadataCreateInput | null;
}

export interface MetadataCustomField15NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField15NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField15NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCustomField15NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField15NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField15NestedUpsertPhotoCreateInput | null;
}

export interface MetadataCustomField15NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField15NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField15NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCustomField15NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField15NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField15NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField15NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField15NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField15NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField15NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField15NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField15NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField15NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField15NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCustomField15NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCustomField15" nodes by specifying some conditions
 */
export interface MetadataCustomField15WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField15WhereInput[] | null;
  OR?: MetadataCustomField15WhereInput[] | null;
  NOT?: MetadataCustomField15WhereInput | null;
}

/**
 * Identifies exactly one "MetadataCustomField15" node.
 */
export interface MetadataCustomField15WhereUniqueInput {
  id: number;
}

export interface MetadataCustomField15WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCustomField15NestedPhotoCreateInput;
  metadata: MetadataCustomField15NestedMetadataCreateInput;
}

export interface MetadataCustomField15WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField15NestedPhotoUpdateInput | null;
  metadata?: MetadataCustomField15NestedMetadataUpdateInput | null;
}

export interface MetadataCustomField15WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCustomField15NestedPhotoCreateInput;
  archive: MetadataCustomField15NestedArchiveCreateInput;
}

export interface MetadataCustomField15WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField15NestedPhotoUpdateInput | null;
  archive?: MetadataCustomField15NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField15WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCustomField15NestedMetadataCreateInput;
  archive: MetadataCustomField15NestedArchiveCreateInput;
}

export interface MetadataCustomField15WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCustomField15NestedMetadataUpdateInput | null;
  archive?: MetadataCustomField15NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField16NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField16NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField16NestedUpsertArchiveCreateInput | null;
}

export interface MetadataCustomField16NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField16NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField16NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCustomField16NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField16NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField16NestedUpsertMetadataCreateInput | null;
}

export interface MetadataCustomField16NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField16NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField16NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCustomField16NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField16NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField16NestedUpsertPhotoCreateInput | null;
}

export interface MetadataCustomField16NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField16NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField16NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCustomField16NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField16NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField16NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField16NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField16NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField16NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField16NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField16NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField16NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField16NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField16NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCustomField16NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCustomField16" nodes by specifying some conditions
 */
export interface MetadataCustomField16WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField16WhereInput[] | null;
  OR?: MetadataCustomField16WhereInput[] | null;
  NOT?: MetadataCustomField16WhereInput | null;
}

/**
 * Identifies exactly one "MetadataCustomField16" node.
 */
export interface MetadataCustomField16WhereUniqueInput {
  id: number;
}

export interface MetadataCustomField16WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCustomField16NestedPhotoCreateInput;
  metadata: MetadataCustomField16NestedMetadataCreateInput;
}

export interface MetadataCustomField16WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField16NestedPhotoUpdateInput | null;
  metadata?: MetadataCustomField16NestedMetadataUpdateInput | null;
}

export interface MetadataCustomField16WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCustomField16NestedPhotoCreateInput;
  archive: MetadataCustomField16NestedArchiveCreateInput;
}

export interface MetadataCustomField16WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField16NestedPhotoUpdateInput | null;
  archive?: MetadataCustomField16NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField16WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCustomField16NestedMetadataCreateInput;
  archive: MetadataCustomField16NestedArchiveCreateInput;
}

export interface MetadataCustomField16WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCustomField16NestedMetadataUpdateInput | null;
  archive?: MetadataCustomField16NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField17NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField17NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField17NestedUpsertArchiveCreateInput | null;
}

export interface MetadataCustomField17NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField17NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField17NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCustomField17NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField17NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField17NestedUpsertMetadataCreateInput | null;
}

export interface MetadataCustomField17NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField17NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField17NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCustomField17NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField17NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField17NestedUpsertPhotoCreateInput | null;
}

export interface MetadataCustomField17NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField17NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField17NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCustomField17NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField17NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField17NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField17NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField17NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField17NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField17NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField17NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField17NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField17NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField17NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCustomField17NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCustomField17" nodes by specifying some conditions
 */
export interface MetadataCustomField17WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField17WhereInput[] | null;
  OR?: MetadataCustomField17WhereInput[] | null;
  NOT?: MetadataCustomField17WhereInput | null;
}

/**
 * Identifies exactly one "MetadataCustomField17" node.
 */
export interface MetadataCustomField17WhereUniqueInput {
  id: number;
}

export interface MetadataCustomField17WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCustomField17NestedPhotoCreateInput;
  metadata: MetadataCustomField17NestedMetadataCreateInput;
}

export interface MetadataCustomField17WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField17NestedPhotoUpdateInput | null;
  metadata?: MetadataCustomField17NestedMetadataUpdateInput | null;
}

export interface MetadataCustomField17WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCustomField17NestedPhotoCreateInput;
  archive: MetadataCustomField17NestedArchiveCreateInput;
}

export interface MetadataCustomField17WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField17NestedPhotoUpdateInput | null;
  archive?: MetadataCustomField17NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField17WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCustomField17NestedMetadataCreateInput;
  archive: MetadataCustomField17NestedArchiveCreateInput;
}

export interface MetadataCustomField17WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCustomField17NestedMetadataUpdateInput | null;
  archive?: MetadataCustomField17NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField20NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField20NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField20NestedUpsertArchiveCreateInput | null;
}

export interface MetadataCustomField20NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField20NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField20NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCustomField20NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField20NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField20NestedUpsertMetadataCreateInput | null;
}

export interface MetadataCustomField20NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField20NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField20NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCustomField20NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField20NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField20NestedUpsertPhotoCreateInput | null;
}

export interface MetadataCustomField20NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField20NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField20NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCustomField20NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField20NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField20NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField20NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField20NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField20NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField20NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField20NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField20NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField20NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField20NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCustomField20NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCustomField20" nodes by specifying some conditions
 */
export interface MetadataCustomField20WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField20WhereInput[] | null;
  OR?: MetadataCustomField20WhereInput[] | null;
  NOT?: MetadataCustomField20WhereInput | null;
}

/**
 * Identifies exactly one "MetadataCustomField20" node.
 */
export interface MetadataCustomField20WhereUniqueInput {
  id: number;
}

export interface MetadataCustomField20WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCustomField20NestedPhotoCreateInput;
  metadata: MetadataCustomField20NestedMetadataCreateInput;
}

export interface MetadataCustomField20WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField20NestedPhotoUpdateInput | null;
  metadata?: MetadataCustomField20NestedMetadataUpdateInput | null;
}

export interface MetadataCustomField20WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCustomField20NestedPhotoCreateInput;
  archive: MetadataCustomField20NestedArchiveCreateInput;
}

export interface MetadataCustomField20WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField20NestedPhotoUpdateInput | null;
  archive?: MetadataCustomField20NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField20WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCustomField20NestedMetadataCreateInput;
  archive: MetadataCustomField20NestedArchiveCreateInput;
}

export interface MetadataCustomField20WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCustomField20NestedMetadataUpdateInput | null;
  archive?: MetadataCustomField20NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField5NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField5NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField5NestedUpsertArchiveCreateInput | null;
}

export interface MetadataCustomField5NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField5NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField5NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCustomField5NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField5NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField5NestedUpsertMetadataCreateInput | null;
}

export interface MetadataCustomField5NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField5NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField5NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCustomField5NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField5NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField5NestedUpsertPhotoCreateInput | null;
}

export interface MetadataCustomField5NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField5NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField5NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCustomField5NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField5NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField5NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField5NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField5NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField5NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField5NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField5NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField5NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField5NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField5NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCustomField5NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCustomField5" nodes by specifying some conditions
 */
export interface MetadataCustomField5WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField5WhereInput[] | null;
  OR?: MetadataCustomField5WhereInput[] | null;
  NOT?: MetadataCustomField5WhereInput | null;
}

/**
 * Identifies exactly one "MetadataCustomField5" node.
 */
export interface MetadataCustomField5WhereUniqueInput {
  id: number;
}

export interface MetadataCustomField5WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCustomField5NestedPhotoCreateInput;
  metadata: MetadataCustomField5NestedMetadataCreateInput;
}

export interface MetadataCustomField5WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField5NestedPhotoUpdateInput | null;
  metadata?: MetadataCustomField5NestedMetadataUpdateInput | null;
}

export interface MetadataCustomField5WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCustomField5NestedPhotoCreateInput;
  archive: MetadataCustomField5NestedArchiveCreateInput;
}

export interface MetadataCustomField5WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField5NestedPhotoUpdateInput | null;
  archive?: MetadataCustomField5NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField5WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCustomField5NestedMetadataCreateInput;
  archive: MetadataCustomField5NestedArchiveCreateInput;
}

export interface MetadataCustomField5WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCustomField5NestedMetadataUpdateInput | null;
  archive?: MetadataCustomField5NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField6NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField6NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField6NestedUpsertArchiveCreateInput | null;
}

export interface MetadataCustomField6NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField6NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField6NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCustomField6NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField6NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField6NestedUpsertMetadataCreateInput | null;
}

export interface MetadataCustomField6NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField6NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField6NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCustomField6NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField6NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField6NestedUpsertPhotoCreateInput | null;
}

export interface MetadataCustomField6NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField6NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField6NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCustomField6NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField6NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField6NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField6NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField6NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField6NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField6NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField6NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField6NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField6NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField6NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCustomField6NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCustomField6" nodes by specifying some conditions
 */
export interface MetadataCustomField6WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField6WhereInput[] | null;
  OR?: MetadataCustomField6WhereInput[] | null;
  NOT?: MetadataCustomField6WhereInput | null;
}

/**
 * Identifies exactly one "MetadataCustomField6" node.
 */
export interface MetadataCustomField6WhereUniqueInput {
  id: number;
}

export interface MetadataCustomField6WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCustomField6NestedPhotoCreateInput;
  metadata: MetadataCustomField6NestedMetadataCreateInput;
}

export interface MetadataCustomField6WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField6NestedPhotoUpdateInput | null;
  metadata?: MetadataCustomField6NestedMetadataUpdateInput | null;
}

export interface MetadataCustomField6WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCustomField6NestedPhotoCreateInput;
  archive: MetadataCustomField6NestedArchiveCreateInput;
}

export interface MetadataCustomField6WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField6NestedPhotoUpdateInput | null;
  archive?: MetadataCustomField6NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField6WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCustomField6NestedMetadataCreateInput;
  archive: MetadataCustomField6NestedArchiveCreateInput;
}

export interface MetadataCustomField6WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCustomField6NestedMetadataUpdateInput | null;
  archive?: MetadataCustomField6NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField7NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField7NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField7NestedUpsertArchiveCreateInput | null;
}

export interface MetadataCustomField7NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField7NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField7NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCustomField7NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField7NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField7NestedUpsertMetadataCreateInput | null;
}

export interface MetadataCustomField7NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField7NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField7NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCustomField7NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField7NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField7NestedUpsertPhotoCreateInput | null;
}

export interface MetadataCustomField7NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField7NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField7NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCustomField7NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField7NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField7NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField7NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField7NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField7NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField7NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField7NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField7NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField7NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField7NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCustomField7NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCustomField7" nodes by specifying some conditions
 */
export interface MetadataCustomField7WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField7WhereInput[] | null;
  OR?: MetadataCustomField7WhereInput[] | null;
  NOT?: MetadataCustomField7WhereInput | null;
}

/**
 * Identifies exactly one "MetadataCustomField7" node.
 */
export interface MetadataCustomField7WhereUniqueInput {
  id: number;
}

export interface MetadataCustomField7WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCustomField7NestedPhotoCreateInput;
  metadata: MetadataCustomField7NestedMetadataCreateInput;
}

export interface MetadataCustomField7WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField7NestedPhotoUpdateInput | null;
  metadata?: MetadataCustomField7NestedMetadataUpdateInput | null;
}

export interface MetadataCustomField7WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCustomField7NestedPhotoCreateInput;
  archive: MetadataCustomField7NestedArchiveCreateInput;
}

export interface MetadataCustomField7WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField7NestedPhotoUpdateInput | null;
  archive?: MetadataCustomField7NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField7WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCustomField7NestedMetadataCreateInput;
  archive: MetadataCustomField7NestedArchiveCreateInput;
}

export interface MetadataCustomField7WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCustomField7NestedMetadataUpdateInput | null;
  archive?: MetadataCustomField7NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField9NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField9NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField9NestedUpsertArchiveCreateInput | null;
}

export interface MetadataCustomField9NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataCustomField9NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataCustomField9NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataCustomField9NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField9NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField9NestedUpsertMetadataCreateInput | null;
}

export interface MetadataCustomField9NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataCustomField9NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataCustomField9NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataCustomField9NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField9NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField9NestedUpsertPhotoCreateInput | null;
}

export interface MetadataCustomField9NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataCustomField9NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataCustomField9NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataCustomField9NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField9NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataCustomField9NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField9NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataCustomField9NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField9NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataCustomField9NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField9NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataCustomField9NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField9NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataCustomField9NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataCustomField9NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataCustomField9" nodes by specifying some conditions
 */
export interface MetadataCustomField9WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataCustomField9WhereInput[] | null;
  OR?: MetadataCustomField9WhereInput[] | null;
  NOT?: MetadataCustomField9WhereInput | null;
}

/**
 * Identifies exactly one "MetadataCustomField9" node.
 */
export interface MetadataCustomField9WhereUniqueInput {
  id: number;
}

export interface MetadataCustomField9WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataCustomField9NestedPhotoCreateInput;
  metadata: MetadataCustomField9NestedMetadataCreateInput;
}

export interface MetadataCustomField9WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField9NestedPhotoUpdateInput | null;
  metadata?: MetadataCustomField9NestedMetadataUpdateInput | null;
}

export interface MetadataCustomField9WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataCustomField9NestedPhotoCreateInput;
  archive: MetadataCustomField9NestedArchiveCreateInput;
}

export interface MetadataCustomField9WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataCustomField9NestedPhotoUpdateInput | null;
  archive?: MetadataCustomField9NestedArchiveUpdateInput | null;
}

export interface MetadataCustomField9WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataCustomField9NestedMetadataCreateInput;
  archive: MetadataCustomField9NestedArchiveCreateInput;
}

export interface MetadataCustomField9WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataCustomField9NestedMetadataUpdateInput | null;
  archive?: MetadataCustomField9NestedArchiveUpdateInput | null;
}

export interface MetadataDateCreatedNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDateCreatedNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDateCreatedNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDateCreatedNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDateCreatedNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDateCreatedNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDateCreatedNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDateCreatedNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDateCreatedNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDateCreatedNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDateCreatedNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDateCreatedNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDateCreatedNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDateCreatedNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDateCreatedNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDateCreatedNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDateCreatedNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDateCreatedNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDateCreatedNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDateCreatedNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDateCreatedNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDateCreatedNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDateCreatedNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDateCreatedNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDateCreatedNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDateCreatedNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDateCreatedNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDateCreatedNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDateCreatedNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDateCreatedNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDateCreated" nodes by specifying some conditions
 */
export interface MetadataDateCreatedWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDateCreatedWhereInput[] | null;
  OR?: MetadataDateCreatedWhereInput[] | null;
  NOT?: MetadataDateCreatedWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDateCreated" node.
 */
export interface MetadataDateCreatedWhereUniqueInput {
  id: number;
}

export interface MetadataDateCreatedWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDateCreatedNestedPhotoCreateInput;
  metadata: MetadataDateCreatedNestedMetadataCreateInput;
}

export interface MetadataDateCreatedWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDateCreatedNestedPhotoUpdateInput | null;
  metadata?: MetadataDateCreatedNestedMetadataUpdateInput | null;
}

export interface MetadataDateCreatedWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDateCreatedNestedPhotoCreateInput;
  archive: MetadataDateCreatedNestedArchiveCreateInput;
}

export interface MetadataDateCreatedWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDateCreatedNestedPhotoUpdateInput | null;
  archive?: MetadataDateCreatedNestedArchiveUpdateInput | null;
}

export interface MetadataDateCreatedWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDateCreatedNestedMetadataCreateInput;
  archive: MetadataDateCreatedNestedArchiveCreateInput;
}

export interface MetadataDateCreatedWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDateCreatedNestedMetadataUpdateInput | null;
  archive?: MetadataDateCreatedNestedArchiveUpdateInput | null;
}

export interface MetadataDateTimeDigitizedNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDateTimeDigitizedNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDateTimeDigitizedNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDateTimeDigitizedNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDateTimeDigitizedNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDateTimeDigitizedNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDateTimeDigitizedNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDateTimeDigitizedNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDateTimeDigitizedNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDateTimeDigitizedNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDateTimeDigitizedNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDateTimeDigitizedNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDateTimeDigitizedNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDateTimeDigitizedNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDateTimeDigitizedNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDateTimeDigitizedNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDateTimeDigitizedNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDateTimeDigitizedNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDateTimeDigitizedNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDateTimeDigitizedNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDateTimeDigitizedNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDateTimeDigitizedNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDateTimeDigitizedNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDateTimeDigitizedNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDateTimeDigitizedNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDateTimeDigitizedNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDateTimeDigitizedNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDateTimeDigitizedNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDateTimeDigitizedNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDateTimeDigitizedNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDateTimeDigitized" nodes by specifying some conditions
 */
export interface MetadataDateTimeDigitizedWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDateTimeDigitizedWhereInput[] | null;
  OR?: MetadataDateTimeDigitizedWhereInput[] | null;
  NOT?: MetadataDateTimeDigitizedWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDateTimeDigitized" node.
 */
export interface MetadataDateTimeDigitizedWhereUniqueInput {
  id: number;
}

export interface MetadataDateTimeDigitizedWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDateTimeDigitizedNestedPhotoCreateInput;
  metadata: MetadataDateTimeDigitizedNestedMetadataCreateInput;
}

export interface MetadataDateTimeDigitizedWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDateTimeDigitizedNestedPhotoUpdateInput | null;
  metadata?: MetadataDateTimeDigitizedNestedMetadataUpdateInput | null;
}

export interface MetadataDateTimeDigitizedWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDateTimeDigitizedNestedPhotoCreateInput;
  archive: MetadataDateTimeDigitizedNestedArchiveCreateInput;
}

export interface MetadataDateTimeDigitizedWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDateTimeDigitizedNestedPhotoUpdateInput | null;
  archive?: MetadataDateTimeDigitizedNestedArchiveUpdateInput | null;
}

export interface MetadataDateTimeDigitizedWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDateTimeDigitizedNestedMetadataCreateInput;
  archive: MetadataDateTimeDigitizedNestedArchiveCreateInput;
}

export interface MetadataDateTimeDigitizedWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDateTimeDigitizedNestedMetadataUpdateInput | null;
  archive?: MetadataDateTimeDigitizedNestedArchiveUpdateInput | null;
}

export interface MetadataDateTimeOriginalNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDateTimeOriginalNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDateTimeOriginalNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDateTimeOriginalNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDateTimeOriginalNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDateTimeOriginalNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDateTimeOriginalNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDateTimeOriginalNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDateTimeOriginalNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDateTimeOriginalNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDateTimeOriginalNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDateTimeOriginalNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDateTimeOriginalNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDateTimeOriginalNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDateTimeOriginalNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDateTimeOriginalNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDateTimeOriginalNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDateTimeOriginalNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDateTimeOriginalNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDateTimeOriginalNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDateTimeOriginalNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDateTimeOriginalNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDateTimeOriginalNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDateTimeOriginalNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDateTimeOriginalNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDateTimeOriginalNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDateTimeOriginalNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDateTimeOriginalNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDateTimeOriginalNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDateTimeOriginalNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDateTimeOriginal" nodes by specifying some conditions
 */
export interface MetadataDateTimeOriginalWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDateTimeOriginalWhereInput[] | null;
  OR?: MetadataDateTimeOriginalWhereInput[] | null;
  NOT?: MetadataDateTimeOriginalWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDateTimeOriginal" node.
 */
export interface MetadataDateTimeOriginalWhereUniqueInput {
  id: number;
}

export interface MetadataDateTimeOriginalWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDateTimeOriginalNestedPhotoCreateInput;
  metadata: MetadataDateTimeOriginalNestedMetadataCreateInput;
}

export interface MetadataDateTimeOriginalWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDateTimeOriginalNestedPhotoUpdateInput | null;
  metadata?: MetadataDateTimeOriginalNestedMetadataUpdateInput | null;
}

export interface MetadataDateTimeOriginalWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDateTimeOriginalNestedPhotoCreateInput;
  archive: MetadataDateTimeOriginalNestedArchiveCreateInput;
}

export interface MetadataDateTimeOriginalWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDateTimeOriginalNestedPhotoUpdateInput | null;
  archive?: MetadataDateTimeOriginalNestedArchiveUpdateInput | null;
}

export interface MetadataDateTimeOriginalWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDateTimeOriginalNestedMetadataCreateInput;
  archive: MetadataDateTimeOriginalNestedArchiveCreateInput;
}

export interface MetadataDateTimeOriginalWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDateTimeOriginalNestedMetadataUpdateInput | null;
  archive?: MetadataDateTimeOriginalNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryAccountNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryAccountNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryAccountNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryAccountNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryAccountNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryAccountNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryAccountNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryAccountNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryAccountNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryAccountNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryAccountNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryAccountNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryAccountNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryAccountNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryAccountNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryAccountNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryAccountNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryAccountNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryAccountNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryAccountNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryAccountNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryAccountNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryAccountNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryAccountNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryAccountNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryAccountNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryAccountNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryAccountNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryAccountNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryAccountNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryAccount" nodes by specifying some conditions
 */
export interface MetadataDeliveryAccountWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryAccountWhereInput[] | null;
  OR?: MetadataDeliveryAccountWhereInput[] | null;
  NOT?: MetadataDeliveryAccountWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryAccount" node.
 */
export interface MetadataDeliveryAccountWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryAccountWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryAccountNestedPhotoCreateInput;
  metadata: MetadataDeliveryAccountNestedMetadataCreateInput;
}

export interface MetadataDeliveryAccountWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryAccountNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryAccountNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryAccountWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryAccountNestedPhotoCreateInput;
  archive: MetadataDeliveryAccountNestedArchiveCreateInput;
}

export interface MetadataDeliveryAccountWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryAccountNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryAccountNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryAccountWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryAccountNestedMetadataCreateInput;
  archive: MetadataDeliveryAccountNestedArchiveCreateInput;
}

export interface MetadataDeliveryAccountWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryAccountNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryAccountNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryCompanyNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryCompanyNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryCompanyNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryCompanyNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryCompanyNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryCompanyNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryCompanyNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryCompanyNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryCompanyNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryCompanyNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryCompanyNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryCompanyNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryCompanyNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryCompanyNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryCompanyNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryCompanyNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryCompanyNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryCompanyNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryCompanyNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryCompanyNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryCompanyNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryCompanyNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryCompanyNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryCompanyNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryCompanyNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryCompanyNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryCompanyNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryCompanyNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryCompanyNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryCompanyNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryCompany" nodes by specifying some conditions
 */
export interface MetadataDeliveryCompanyWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryCompanyWhereInput[] | null;
  OR?: MetadataDeliveryCompanyWhereInput[] | null;
  NOT?: MetadataDeliveryCompanyWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryCompany" node.
 */
export interface MetadataDeliveryCompanyWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryCompanyWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryCompanyNestedPhotoCreateInput;
  metadata: MetadataDeliveryCompanyNestedMetadataCreateInput;
}

export interface MetadataDeliveryCompanyWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryCompanyNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryCompanyNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryCompanyWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryCompanyNestedPhotoCreateInput;
  archive: MetadataDeliveryCompanyNestedArchiveCreateInput;
}

export interface MetadataDeliveryCompanyWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryCompanyNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryCompanyNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryCompanyWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryCompanyNestedMetadataCreateInput;
  archive: MetadataDeliveryCompanyNestedArchiveCreateInput;
}

export interface MetadataDeliveryCompanyWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryCompanyNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryCompanyNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryCopyrightNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryCopyrightNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryCopyrightNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryCopyrightNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryCopyrightNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryCopyrightNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryCopyrightNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryCopyrightNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryCopyrightNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryCopyrightNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryCopyrightNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryCopyrightNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryCopyrightNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryCopyrightNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryCopyrightNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryCopyrightNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryCopyrightNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryCopyrightNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryCopyrightNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryCopyrightNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryCopyrightNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryCopyrightNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryCopyrightNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryCopyrightNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryCopyrightNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryCopyrightNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryCopyrightNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryCopyrightNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryCopyrightNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryCopyrightNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryCopyright" nodes by specifying some conditions
 */
export interface MetadataDeliveryCopyrightWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryCopyrightWhereInput[] | null;
  OR?: MetadataDeliveryCopyrightWhereInput[] | null;
  NOT?: MetadataDeliveryCopyrightWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryCopyright" node.
 */
export interface MetadataDeliveryCopyrightWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryCopyrightWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryCopyrightNestedPhotoCreateInput;
  metadata: MetadataDeliveryCopyrightNestedMetadataCreateInput;
}

export interface MetadataDeliveryCopyrightWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryCopyrightNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryCopyrightNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryCopyrightWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryCopyrightNestedPhotoCreateInput;
  archive: MetadataDeliveryCopyrightNestedArchiveCreateInput;
}

export interface MetadataDeliveryCopyrightWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryCopyrightNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryCopyrightNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryCopyrightWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryCopyrightNestedMetadataCreateInput;
  archive: MetadataDeliveryCopyrightNestedArchiveCreateInput;
}

export interface MetadataDeliveryCopyrightWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryCopyrightNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryCopyrightNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryDateFolderNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryDateFolderNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryDateFolderNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryDateFolderNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryDateFolderNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryDateFolderNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryDateFolderNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryDateFolderNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryDateFolderNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryDateFolderNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryDateFolderNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryDateFolderNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryDateFolderNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryDateFolderNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryDateFolderNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryDateFolderNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryDateFolderNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryDateFolderNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryDateFolderNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryDateFolderNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryDateFolderNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryDateFolderNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryDateFolderNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryDateFolderNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryDateFolderNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryDateFolderNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryDateFolderNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryDateFolderNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryDateFolderNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryDateFolderNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryDateFolder" nodes by specifying some conditions
 */
export interface MetadataDeliveryDateFolderWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryDateFolderWhereInput[] | null;
  OR?: MetadataDeliveryDateFolderWhereInput[] | null;
  NOT?: MetadataDeliveryDateFolderWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryDateFolder" node.
 */
export interface MetadataDeliveryDateFolderWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryDateFolderWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryDateFolderNestedPhotoCreateInput;
  metadata: MetadataDeliveryDateFolderNestedMetadataCreateInput;
}

export interface MetadataDeliveryDateFolderWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryDateFolderNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryDateFolderNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryDateFolderWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryDateFolderNestedPhotoCreateInput;
  archive: MetadataDeliveryDateFolderNestedArchiveCreateInput;
}

export interface MetadataDeliveryDateFolderWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryDateFolderNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryDateFolderNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryDateFolderWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryDateFolderNestedMetadataCreateInput;
  archive: MetadataDeliveryDateFolderNestedArchiveCreateInput;
}

export interface MetadataDeliveryDateFolderWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryDateFolderNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryDateFolderNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryDateTimeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryDateTimeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryDateTimeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryDateTimeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryDateTimeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryDateTimeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryDateTimeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryDateTimeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryDateTimeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryDateTimeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryDateTimeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryDateTimeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryDateTimeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryDateTimeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryDateTimeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryDateTimeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryDateTimeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryDateTimeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryDateTimeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryDateTimeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryDateTimeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryDateTimeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryDateTimeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryDateTimeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryDateTimeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryDateTimeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryDateTimeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryDateTimeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryDateTimeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryDateTimeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryDateTime" nodes by specifying some conditions
 */
export interface MetadataDeliveryDateTimeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryDateTimeWhereInput[] | null;
  OR?: MetadataDeliveryDateTimeWhereInput[] | null;
  NOT?: MetadataDeliveryDateTimeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryDateTime" node.
 */
export interface MetadataDeliveryDateTimeWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryDateTimeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryDateTimeNestedPhotoCreateInput;
  metadata: MetadataDeliveryDateTimeNestedMetadataCreateInput;
}

export interface MetadataDeliveryDateTimeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryDateTimeNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryDateTimeNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryDateTimeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryDateTimeNestedPhotoCreateInput;
  archive: MetadataDeliveryDateTimeNestedArchiveCreateInput;
}

export interface MetadataDeliveryDateTimeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryDateTimeNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryDateTimeNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryDateTimeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryDateTimeNestedMetadataCreateInput;
  archive: MetadataDeliveryDateTimeNestedArchiveCreateInput;
}

export interface MetadataDeliveryDateTimeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryDateTimeNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryDateTimeNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryDcCreatorNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryDcCreatorNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryDcCreatorNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryDcCreatorNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryDcCreatorNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryDcCreatorNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryDcCreatorNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryDcCreatorNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryDcCreatorNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryDcCreatorNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryDcCreatorNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryDcCreatorNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryDcCreatorNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryDcCreatorNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryDcCreatorNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryDcCreatorNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryDcCreatorNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryDcCreatorNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryDcCreatorNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryDcCreatorNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryDcCreatorNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryDcCreatorNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryDcCreatorNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryDcCreatorNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryDcCreatorNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryDcCreatorNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryDcCreatorNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryDcCreatorNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryDcCreatorNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryDcCreatorNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryDcCreator" nodes by specifying some conditions
 */
export interface MetadataDeliveryDcCreatorWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryDcCreatorWhereInput[] | null;
  OR?: MetadataDeliveryDcCreatorWhereInput[] | null;
  NOT?: MetadataDeliveryDcCreatorWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryDcCreator" node.
 */
export interface MetadataDeliveryDcCreatorWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryDcCreatorWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryDcCreatorNestedPhotoCreateInput;
  metadata: MetadataDeliveryDcCreatorNestedMetadataCreateInput;
}

export interface MetadataDeliveryDcCreatorWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryDcCreatorNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryDcCreatorNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryDcCreatorWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryDcCreatorNestedPhotoCreateInput;
  archive: MetadataDeliveryDcCreatorNestedArchiveCreateInput;
}

export interface MetadataDeliveryDcCreatorWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryDcCreatorNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryDcCreatorNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryDcCreatorWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryDcCreatorNestedMetadataCreateInput;
  archive: MetadataDeliveryDcCreatorNestedArchiveCreateInput;
}

export interface MetadataDeliveryDcCreatorWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryDcCreatorNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryDcCreatorNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryDcRightsNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryDcRightsNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryDcRightsNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryDcRightsNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryDcRightsNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryDcRightsNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryDcRightsNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryDcRightsNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryDcRightsNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryDcRightsNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryDcRightsNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryDcRightsNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryDcRightsNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryDcRightsNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryDcRightsNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryDcRightsNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryDcRightsNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryDcRightsNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryDcRightsNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryDcRightsNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryDcRightsNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryDcRightsNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryDcRightsNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryDcRightsNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryDcRightsNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryDcRightsNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryDcRightsNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryDcRightsNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryDcRightsNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryDcRightsNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryDcRights" nodes by specifying some conditions
 */
export interface MetadataDeliveryDcRightsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryDcRightsWhereInput[] | null;
  OR?: MetadataDeliveryDcRightsWhereInput[] | null;
  NOT?: MetadataDeliveryDcRightsWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryDcRights" node.
 */
export interface MetadataDeliveryDcRightsWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryDcRightsWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryDcRightsNestedPhotoCreateInput;
  metadata: MetadataDeliveryDcRightsNestedMetadataCreateInput;
}

export interface MetadataDeliveryDcRightsWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryDcRightsNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryDcRightsNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryDcRightsWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryDcRightsNestedPhotoCreateInput;
  archive: MetadataDeliveryDcRightsNestedArchiveCreateInput;
}

export interface MetadataDeliveryDcRightsWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryDcRightsNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryDcRightsNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryDcRightsWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryDcRightsNestedMetadataCreateInput;
  archive: MetadataDeliveryDcRightsNestedArchiveCreateInput;
}

export interface MetadataDeliveryDcRightsWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryDcRightsNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryDcRightsNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryFileTypeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryFileTypeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryFileTypeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryFileTypeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryFileTypeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryFileTypeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryFileTypeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryFileTypeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryFileTypeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryFileTypeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryFileTypeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryFileTypeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryFileTypeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryFileTypeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryFileTypeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryFileTypeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryFileTypeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryFileTypeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryFileTypeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryFileTypeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryFileTypeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryFileTypeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryFileTypeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryFileTypeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryFileTypeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryFileTypeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryFileTypeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryFileTypeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryFileTypeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryFileTypeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryFileType" nodes by specifying some conditions
 */
export interface MetadataDeliveryFileTypeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryFileTypeWhereInput[] | null;
  OR?: MetadataDeliveryFileTypeWhereInput[] | null;
  NOT?: MetadataDeliveryFileTypeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryFileType" node.
 */
export interface MetadataDeliveryFileTypeWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryFileTypeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryFileTypeNestedPhotoCreateInput;
  metadata: MetadataDeliveryFileTypeNestedMetadataCreateInput;
}

export interface MetadataDeliveryFileTypeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryFileTypeNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryFileTypeNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryFileTypeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryFileTypeNestedPhotoCreateInput;
  archive: MetadataDeliveryFileTypeNestedArchiveCreateInput;
}

export interface MetadataDeliveryFileTypeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryFileTypeNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryFileTypeNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryFileTypeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryFileTypeNestedMetadataCreateInput;
  archive: MetadataDeliveryFileTypeNestedArchiveCreateInput;
}

export interface MetadataDeliveryFileTypeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryFileTypeNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryFileTypeNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryFolderNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryFolderNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryFolderNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryFolderNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryFolderNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryFolderNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryFolderNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryFolderNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryFolderNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryFolderNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryFolderNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryFolderNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryFolderNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryFolderNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryFolderNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryFolderNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryFolderNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryFolderNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryFolderNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryFolderNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryFolderNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryFolderNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryFolderNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryFolderNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryFolderNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryFolderNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryFolderNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryFolderNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryFolderNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryFolderNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryFolder" nodes by specifying some conditions
 */
export interface MetadataDeliveryFolderWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryFolderWhereInput[] | null;
  OR?: MetadataDeliveryFolderWhereInput[] | null;
  NOT?: MetadataDeliveryFolderWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryFolder" node.
 */
export interface MetadataDeliveryFolderWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryFolderWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryFolderNestedPhotoCreateInput;
  metadata: MetadataDeliveryFolderNestedMetadataCreateInput;
}

export interface MetadataDeliveryFolderWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryFolderNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryFolderNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryFolderWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryFolderNestedPhotoCreateInput;
  archive: MetadataDeliveryFolderNestedArchiveCreateInput;
}

export interface MetadataDeliveryFolderWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryFolderNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryFolderNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryFolderWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryFolderNestedMetadataCreateInput;
  archive: MetadataDeliveryFolderNestedArchiveCreateInput;
}

export interface MetadataDeliveryFolderWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryFolderNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryFolderNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryKindNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryKindNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryKindNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryKindNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryKindNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryKindNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryKindNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryKindNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryKindNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryKindNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryKindNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryKindNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryKindNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryKindNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryKindNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryKindNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryKindNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryKindNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryKindNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryKindNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryKindNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryKindNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryKindNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryKindNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryKindNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryKindNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryKindNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryKindNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryKindNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryKindNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryKind" nodes by specifying some conditions
 */
export interface MetadataDeliveryKindWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryKindWhereInput[] | null;
  OR?: MetadataDeliveryKindWhereInput[] | null;
  NOT?: MetadataDeliveryKindWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryKind" node.
 */
export interface MetadataDeliveryKindWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryKindWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryKindNestedPhotoCreateInput;
  metadata: MetadataDeliveryKindNestedMetadataCreateInput;
}

export interface MetadataDeliveryKindWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryKindNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryKindNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryKindWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryKindNestedPhotoCreateInput;
  archive: MetadataDeliveryKindNestedArchiveCreateInput;
}

export interface MetadataDeliveryKindWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryKindNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryKindNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryKindWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryKindNestedMetadataCreateInput;
  archive: MetadataDeliveryKindNestedArchiveCreateInput;
}

export interface MetadataDeliveryKindWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryKindNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryKindNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryPathNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryPathNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryPathNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryPathNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryPathNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryPathNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryPathNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryPathNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryPathNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryPathNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryPathNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryPathNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryPathNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryPathNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryPathNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryPathNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryPathNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryPathNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryPathNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryPathNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryPathNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryPathNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryPathNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryPathNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryPathNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryPathNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryPathNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryPathNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryPathNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryPathNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryPath" nodes by specifying some conditions
 */
export interface MetadataDeliveryPathWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryPathWhereInput[] | null;
  OR?: MetadataDeliveryPathWhereInput[] | null;
  NOT?: MetadataDeliveryPathWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryPath" node.
 */
export interface MetadataDeliveryPathWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryPathWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryPathNestedPhotoCreateInput;
  metadata: MetadataDeliveryPathNestedMetadataCreateInput;
}

export interface MetadataDeliveryPathWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryPathNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryPathNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryPathWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryPathNestedPhotoCreateInput;
  archive: MetadataDeliveryPathNestedArchiveCreateInput;
}

export interface MetadataDeliveryPathWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryPathNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryPathNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryPathWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryPathNestedMetadataCreateInput;
  archive: MetadataDeliveryPathNestedArchiveCreateInput;
}

export interface MetadataDeliveryPathWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryPathNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryPathNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryPersonShownintheImageNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryPersonShownintheImageNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryPersonShownintheImageNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryPersonShownintheImageNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryPersonShownintheImageNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryPersonShownintheImageNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryPersonShownintheImageNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryPersonShownintheImageNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryPersonShownintheImageNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryPersonShownintheImageNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryPersonShownintheImageNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryPersonShownintheImageNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryPersonShownintheImageNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryPersonShownintheImageNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryPersonShownintheImageNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryPersonShownintheImageNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryPersonShownintheImageNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryPersonShownintheImageNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryPersonShownintheImageNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryPersonShownintheImageNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryPersonShownintheImageNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryPersonShownintheImageNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryPersonShownintheImageNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryPersonShownintheImageNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryPersonShownintheImageNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryPersonShownintheImageNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryPersonShownintheImageNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryPersonShownintheImageNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryPersonShownintheImageNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryPersonShownintheImageNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryPersonShownintheImage" nodes by specifying some conditions
 */
export interface MetadataDeliveryPersonShownintheImageWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryPersonShownintheImageWhereInput[] | null;
  OR?: MetadataDeliveryPersonShownintheImageWhereInput[] | null;
  NOT?: MetadataDeliveryPersonShownintheImageWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryPersonShownintheImage" node.
 */
export interface MetadataDeliveryPersonShownintheImageWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryPersonShownintheImageWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryPersonShownintheImageNestedPhotoCreateInput;
  metadata: MetadataDeliveryPersonShownintheImageNestedMetadataCreateInput;
}

export interface MetadataDeliveryPersonShownintheImageWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryPersonShownintheImageNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryPersonShownintheImageNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryPersonShownintheImageWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryPersonShownintheImageNestedPhotoCreateInput;
  archive: MetadataDeliveryPersonShownintheImageNestedArchiveCreateInput;
}

export interface MetadataDeliveryPersonShownintheImageWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryPersonShownintheImageNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryPersonShownintheImageNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryPersonShownintheImageWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryPersonShownintheImageNestedMetadataCreateInput;
  archive: MetadataDeliveryPersonShownintheImageNestedArchiveCreateInput;
}

export interface MetadataDeliveryPersonShownintheImageWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryPersonShownintheImageNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryPersonShownintheImageNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryPhotoshopCreditNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryPhotoshopCreditNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryPhotoshopCreditNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryPhotoshopCreditNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryPhotoshopCreditNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryPhotoshopCreditNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryPhotoshopCreditNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryPhotoshopCreditNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryPhotoshopCreditNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryPhotoshopCreditNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryPhotoshopCreditNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryPhotoshopCreditNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryPhotoshopCreditNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryPhotoshopCreditNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryPhotoshopCreditNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryPhotoshopCreditNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryPhotoshopCreditNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryPhotoshopCreditNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryPhotoshopCreditNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryPhotoshopCreditNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryPhotoshopCreditNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryPhotoshopCreditNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryPhotoshopCreditNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryPhotoshopCreditNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryPhotoshopCreditNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryPhotoshopCreditNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryPhotoshopCreditNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryPhotoshopCreditNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryPhotoshopCreditNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryPhotoshopCreditNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryPhotoshopCredit" nodes by specifying some conditions
 */
export interface MetadataDeliveryPhotoshopCreditWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryPhotoshopCreditWhereInput[] | null;
  OR?: MetadataDeliveryPhotoshopCreditWhereInput[] | null;
  NOT?: MetadataDeliveryPhotoshopCreditWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryPhotoshopCredit" node.
 */
export interface MetadataDeliveryPhotoshopCreditWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryPhotoshopCreditWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryPhotoshopCreditNestedPhotoCreateInput;
  metadata: MetadataDeliveryPhotoshopCreditNestedMetadataCreateInput;
}

export interface MetadataDeliveryPhotoshopCreditWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryPhotoshopCreditNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryPhotoshopCreditNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryPhotoshopCreditWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryPhotoshopCreditNestedPhotoCreateInput;
  archive: MetadataDeliveryPhotoshopCreditNestedArchiveCreateInput;
}

export interface MetadataDeliveryPhotoshopCreditWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryPhotoshopCreditNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryPhotoshopCreditNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryPhotoshopCreditWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryPhotoshopCreditNestedMetadataCreateInput;
  archive: MetadataDeliveryPhotoshopCreditNestedArchiveCreateInput;
}

export interface MetadataDeliveryPhotoshopCreditWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryPhotoshopCreditNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryPhotoshopCreditNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryPhotoshopSourceNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryPhotoshopSourceNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryPhotoshopSourceNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryPhotoshopSourceNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryPhotoshopSourceNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryPhotoshopSourceNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryPhotoshopSourceNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryPhotoshopSourceNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryPhotoshopSourceNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryPhotoshopSourceNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryPhotoshopSourceNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryPhotoshopSourceNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryPhotoshopSourceNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryPhotoshopSourceNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryPhotoshopSourceNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryPhotoshopSourceNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryPhotoshopSourceNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryPhotoshopSourceNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryPhotoshopSourceNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryPhotoshopSourceNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryPhotoshopSourceNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryPhotoshopSourceNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryPhotoshopSourceNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryPhotoshopSourceNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryPhotoshopSourceNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryPhotoshopSourceNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryPhotoshopSourceNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryPhotoshopSourceNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryPhotoshopSourceNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryPhotoshopSourceNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryPhotoshopSource" nodes by specifying some conditions
 */
export interface MetadataDeliveryPhotoshopSourceWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryPhotoshopSourceWhereInput[] | null;
  OR?: MetadataDeliveryPhotoshopSourceWhereInput[] | null;
  NOT?: MetadataDeliveryPhotoshopSourceWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryPhotoshopSource" node.
 */
export interface MetadataDeliveryPhotoshopSourceWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryPhotoshopSourceWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryPhotoshopSourceNestedPhotoCreateInput;
  metadata: MetadataDeliveryPhotoshopSourceNestedMetadataCreateInput;
}

export interface MetadataDeliveryPhotoshopSourceWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryPhotoshopSourceNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryPhotoshopSourceNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryPhotoshopSourceWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryPhotoshopSourceNestedPhotoCreateInput;
  archive: MetadataDeliveryPhotoshopSourceNestedArchiveCreateInput;
}

export interface MetadataDeliveryPhotoshopSourceWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryPhotoshopSourceNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryPhotoshopSourceNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryPhotoshopSourceWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryPhotoshopSourceNestedMetadataCreateInput;
  archive: MetadataDeliveryPhotoshopSourceNestedArchiveCreateInput;
}

export interface MetadataDeliveryPhotoshopSourceWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryPhotoshopSourceNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryPhotoshopSourceNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryServiceNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryServiceNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryServiceNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryServiceNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryServiceNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryServiceNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryServiceNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryServiceNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryServiceNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryServiceNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryServiceNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryServiceNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryServiceNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryServiceNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryServiceNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryServiceNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryServiceNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryServiceNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryServiceNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryServiceNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryServiceNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryServiceNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryServiceNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryServiceNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryServiceNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryServiceNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryServiceNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryServiceNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryServiceNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryServiceNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryService" nodes by specifying some conditions
 */
export interface MetadataDeliveryServiceWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryServiceWhereInput[] | null;
  OR?: MetadataDeliveryServiceWhereInput[] | null;
  NOT?: MetadataDeliveryServiceWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryService" node.
 */
export interface MetadataDeliveryServiceWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryServiceWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryServiceNestedPhotoCreateInput;
  metadata: MetadataDeliveryServiceNestedMetadataCreateInput;
}

export interface MetadataDeliveryServiceWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryServiceNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryServiceNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryServiceWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryServiceNestedPhotoCreateInput;
  archive: MetadataDeliveryServiceNestedArchiveCreateInput;
}

export interface MetadataDeliveryServiceWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryServiceNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryServiceNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryServiceWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryServiceNestedMetadataCreateInput;
  archive: MetadataDeliveryServiceNestedArchiveCreateInput;
}

export interface MetadataDeliveryServiceWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryServiceNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryServiceNestedArchiveUpdateInput | null;
}

export interface MetadataDeliverySubjectNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliverySubjectNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliverySubjectNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliverySubjectNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliverySubjectNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliverySubjectNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliverySubjectNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliverySubjectNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliverySubjectNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliverySubjectNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliverySubjectNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliverySubjectNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliverySubjectNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliverySubjectNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliverySubjectNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliverySubjectNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliverySubjectNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliverySubjectNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliverySubjectNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliverySubjectNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliverySubjectNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliverySubjectNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliverySubjectNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliverySubjectNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliverySubjectNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliverySubjectNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliverySubjectNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliverySubjectNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliverySubjectNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliverySubjectNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliverySubject" nodes by specifying some conditions
 */
export interface MetadataDeliverySubjectWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliverySubjectWhereInput[] | null;
  OR?: MetadataDeliverySubjectWhereInput[] | null;
  NOT?: MetadataDeliverySubjectWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliverySubject" node.
 */
export interface MetadataDeliverySubjectWhereUniqueInput {
  id: number;
}

export interface MetadataDeliverySubjectWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliverySubjectNestedPhotoCreateInput;
  metadata: MetadataDeliverySubjectNestedMetadataCreateInput;
}

export interface MetadataDeliverySubjectWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliverySubjectNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliverySubjectNestedMetadataUpdateInput | null;
}

export interface MetadataDeliverySubjectWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliverySubjectNestedPhotoCreateInput;
  archive: MetadataDeliverySubjectNestedArchiveCreateInput;
}

export interface MetadataDeliverySubjectWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliverySubjectNestedPhotoUpdateInput | null;
  archive?: MetadataDeliverySubjectNestedArchiveUpdateInput | null;
}

export interface MetadataDeliverySubjectWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliverySubjectNestedMetadataCreateInput;
  archive: MetadataDeliverySubjectNestedArchiveCreateInput;
}

export interface MetadataDeliverySubjectWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliverySubjectNestedMetadataUpdateInput | null;
  archive?: MetadataDeliverySubjectNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryUnderSubjectNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryUnderSubjectNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryUnderSubjectNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDeliveryUnderSubjectNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDeliveryUnderSubjectNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDeliveryUnderSubjectNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDeliveryUnderSubjectNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryUnderSubjectNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryUnderSubjectNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDeliveryUnderSubjectNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDeliveryUnderSubjectNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDeliveryUnderSubjectNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDeliveryUnderSubjectNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryUnderSubjectNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryUnderSubjectNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDeliveryUnderSubjectNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDeliveryUnderSubjectNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDeliveryUnderSubjectNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDeliveryUnderSubjectNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryUnderSubjectNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDeliveryUnderSubjectNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryUnderSubjectNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDeliveryUnderSubjectNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryUnderSubjectNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDeliveryUnderSubjectNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryUnderSubjectNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDeliveryUnderSubjectNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryUnderSubjectNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDeliveryUnderSubjectNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDeliveryUnderSubjectNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDeliveryUnderSubject" nodes by specifying some conditions
 */
export interface MetadataDeliveryUnderSubjectWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDeliveryUnderSubjectWhereInput[] | null;
  OR?: MetadataDeliveryUnderSubjectWhereInput[] | null;
  NOT?: MetadataDeliveryUnderSubjectWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDeliveryUnderSubject" node.
 */
export interface MetadataDeliveryUnderSubjectWhereUniqueInput {
  id: number;
}

export interface MetadataDeliveryUnderSubjectWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDeliveryUnderSubjectNestedPhotoCreateInput;
  metadata: MetadataDeliveryUnderSubjectNestedMetadataCreateInput;
}

export interface MetadataDeliveryUnderSubjectWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryUnderSubjectNestedPhotoUpdateInput | null;
  metadata?: MetadataDeliveryUnderSubjectNestedMetadataUpdateInput | null;
}

export interface MetadataDeliveryUnderSubjectWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDeliveryUnderSubjectNestedPhotoCreateInput;
  archive: MetadataDeliveryUnderSubjectNestedArchiveCreateInput;
}

export interface MetadataDeliveryUnderSubjectWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDeliveryUnderSubjectNestedPhotoUpdateInput | null;
  archive?: MetadataDeliveryUnderSubjectNestedArchiveUpdateInput | null;
}

export interface MetadataDeliveryUnderSubjectWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDeliveryUnderSubjectNestedMetadataCreateInput;
  archive: MetadataDeliveryUnderSubjectNestedArchiveCreateInput;
}

export interface MetadataDeliveryUnderSubjectWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDeliveryUnderSubjectNestedMetadataUpdateInput | null;
  archive?: MetadataDeliveryUnderSubjectNestedArchiveUpdateInput | null;
}

export interface MetadataDepartmentNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDepartmentNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDepartmentNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDepartmentNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDepartmentNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDepartmentNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDepartmentNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDepartmentNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDepartmentNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDepartmentNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDepartmentNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDepartmentNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDepartmentNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDepartmentNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDepartmentNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDepartmentNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDepartmentNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDepartmentNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDepartmentNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDepartmentNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDepartmentNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDepartmentNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDepartmentNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDepartmentNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDepartmentNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDepartmentNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDepartmentNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDepartmentNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDepartmentNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDepartmentNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDepartment" nodes by specifying some conditions
 */
export interface MetadataDepartmentWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDepartmentWhereInput[] | null;
  OR?: MetadataDepartmentWhereInput[] | null;
  NOT?: MetadataDepartmentWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDepartment" node.
 */
export interface MetadataDepartmentWhereUniqueInput {
  id: number;
}

export interface MetadataDepartmentWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDepartmentNestedPhotoCreateInput;
  metadata: MetadataDepartmentNestedMetadataCreateInput;
}

export interface MetadataDepartmentWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDepartmentNestedPhotoUpdateInput | null;
  metadata?: MetadataDepartmentNestedMetadataUpdateInput | null;
}

export interface MetadataDepartmentWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDepartmentNestedPhotoCreateInput;
  archive: MetadataDepartmentNestedArchiveCreateInput;
}

export interface MetadataDepartmentWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDepartmentNestedPhotoUpdateInput | null;
  archive?: MetadataDepartmentNestedArchiveUpdateInput | null;
}

export interface MetadataDepartmentWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDepartmentNestedMetadataCreateInput;
  archive: MetadataDepartmentNestedArchiveCreateInput;
}

export interface MetadataDepartmentWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDepartmentNestedMetadataUpdateInput | null;
  archive?: MetadataDepartmentNestedArchiveUpdateInput | null;
}

export interface MetadataDescriptionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDescriptionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDescriptionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDescriptionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDescriptionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDescriptionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDescriptionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDescriptionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDescriptionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDescriptionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDescriptionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDescriptionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDescriptionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDescriptionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDescriptionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDescriptionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDescriptionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDescriptionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDescriptionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDescriptionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDescriptionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDescriptionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDescriptionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDescriptionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDescriptionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDescriptionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDescriptionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDescriptionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDescriptionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDescriptionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDescription" nodes by specifying some conditions
 */
export interface MetadataDescriptionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDescriptionWhereInput[] | null;
  OR?: MetadataDescriptionWhereInput[] | null;
  NOT?: MetadataDescriptionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDescription" node.
 */
export interface MetadataDescriptionWhereUniqueInput {
  id: number;
}

export interface MetadataDescriptionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDescriptionNestedPhotoCreateInput;
  metadata: MetadataDescriptionNestedMetadataCreateInput;
}

export interface MetadataDescriptionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDescriptionNestedPhotoUpdateInput | null;
  metadata?: MetadataDescriptionNestedMetadataUpdateInput | null;
}

export interface MetadataDescriptionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDescriptionNestedPhotoCreateInput;
  archive: MetadataDescriptionNestedArchiveCreateInput;
}

export interface MetadataDescriptionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDescriptionNestedPhotoUpdateInput | null;
  archive?: MetadataDescriptionNestedArchiveUpdateInput | null;
}

export interface MetadataDescriptionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDescriptionNestedMetadataCreateInput;
  archive: MetadataDescriptionNestedArchiveCreateInput;
}

export interface MetadataDescriptionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDescriptionNestedMetadataUpdateInput | null;
  archive?: MetadataDescriptionNestedArchiveUpdateInput | null;
}

export interface MetadataDigitalAssetUrlNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDigitalAssetUrlNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDigitalAssetUrlNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDigitalAssetUrlNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDigitalAssetUrlNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDigitalAssetUrlNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDigitalAssetUrlNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDigitalAssetUrlNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDigitalAssetUrlNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDigitalAssetUrlNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDigitalAssetUrlNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDigitalAssetUrlNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDigitalAssetUrlNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDigitalAssetUrlNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDigitalAssetUrlNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDigitalAssetUrlNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDigitalAssetUrlNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDigitalAssetUrlNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDigitalAssetUrlNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDigitalAssetUrlNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDigitalAssetUrlNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDigitalAssetUrlNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDigitalAssetUrlNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDigitalAssetUrlNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDigitalAssetUrlNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDigitalAssetUrlNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDigitalAssetUrlNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDigitalAssetUrlNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDigitalAssetUrlNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDigitalAssetUrlNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDigitalAssetUrl" nodes by specifying some conditions
 */
export interface MetadataDigitalAssetUrlWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDigitalAssetUrlWhereInput[] | null;
  OR?: MetadataDigitalAssetUrlWhereInput[] | null;
  NOT?: MetadataDigitalAssetUrlWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDigitalAssetUrl" node.
 */
export interface MetadataDigitalAssetUrlWhereUniqueInput {
  id: number;
}

export interface MetadataDigitalAssetUrlWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDigitalAssetUrlNestedPhotoCreateInput;
  metadata: MetadataDigitalAssetUrlNestedMetadataCreateInput;
}

export interface MetadataDigitalAssetUrlWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDigitalAssetUrlNestedPhotoUpdateInput | null;
  metadata?: MetadataDigitalAssetUrlNestedMetadataUpdateInput | null;
}

export interface MetadataDigitalAssetUrlWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDigitalAssetUrlNestedPhotoCreateInput;
  archive: MetadataDigitalAssetUrlNestedArchiveCreateInput;
}

export interface MetadataDigitalAssetUrlWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDigitalAssetUrlNestedPhotoUpdateInput | null;
  archive?: MetadataDigitalAssetUrlNestedArchiveUpdateInput | null;
}

export interface MetadataDigitalAssetUrlWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDigitalAssetUrlNestedMetadataCreateInput;
  archive: MetadataDigitalAssetUrlNestedArchiveCreateInput;
}

export interface MetadataDigitalAssetUrlWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDigitalAssetUrlNestedMetadataUpdateInput | null;
  archive?: MetadataDigitalAssetUrlNestedArchiveUpdateInput | null;
}

export interface MetadataDivisionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDivisionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDivisionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDivisionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDivisionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDivisionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDivisionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDivisionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDivisionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDivisionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDivisionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDivisionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDivisionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDivisionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDivisionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDivisionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDivisionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDivisionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDivisionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDivisionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDivisionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDivisionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDivisionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDivisionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDivisionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDivisionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDivisionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDivisionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDivisionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDivisionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDivision" nodes by specifying some conditions
 */
export interface MetadataDivisionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDivisionWhereInput[] | null;
  OR?: MetadataDivisionWhereInput[] | null;
  NOT?: MetadataDivisionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDivision" node.
 */
export interface MetadataDivisionWhereUniqueInput {
  id: number;
}

export interface MetadataDivisionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDivisionNestedPhotoCreateInput;
  metadata: MetadataDivisionNestedMetadataCreateInput;
}

export interface MetadataDivisionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDivisionNestedPhotoUpdateInput | null;
  metadata?: MetadataDivisionNestedMetadataUpdateInput | null;
}

export interface MetadataDivisionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDivisionNestedPhotoCreateInput;
  archive: MetadataDivisionNestedArchiveCreateInput;
}

export interface MetadataDivisionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDivisionNestedPhotoUpdateInput | null;
  archive?: MetadataDivisionNestedArchiveUpdateInput | null;
}

export interface MetadataDivisionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDivisionNestedMetadataCreateInput;
  archive: MetadataDivisionNestedArchiveCreateInput;
}

export interface MetadataDivisionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDivisionNestedMetadataUpdateInput | null;
  archive?: MetadataDivisionNestedArchiveUpdateInput | null;
}

export interface MetadataDocumentTextNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDocumentTextNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDocumentTextNestedUpsertArchiveCreateInput | null;
}

export interface MetadataDocumentTextNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataDocumentTextNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataDocumentTextNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataDocumentTextNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDocumentTextNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDocumentTextNestedUpsertMetadataCreateInput | null;
}

export interface MetadataDocumentTextNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataDocumentTextNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataDocumentTextNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataDocumentTextNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDocumentTextNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDocumentTextNestedUpsertPhotoCreateInput | null;
}

export interface MetadataDocumentTextNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataDocumentTextNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataDocumentTextNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataDocumentTextNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDocumentTextNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataDocumentTextNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDocumentTextNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataDocumentTextNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDocumentTextNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataDocumentTextNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDocumentTextNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataDocumentTextNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDocumentTextNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataDocumentTextNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataDocumentTextNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataDocumentText" nodes by specifying some conditions
 */
export interface MetadataDocumentTextWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataDocumentTextWhereInput[] | null;
  OR?: MetadataDocumentTextWhereInput[] | null;
  NOT?: MetadataDocumentTextWhereInput | null;
}

/**
 * Identifies exactly one "MetadataDocumentText" node.
 */
export interface MetadataDocumentTextWhereUniqueInput {
  id: number;
}

export interface MetadataDocumentTextWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataDocumentTextNestedPhotoCreateInput;
  metadata: MetadataDocumentTextNestedMetadataCreateInput;
}

export interface MetadataDocumentTextWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataDocumentTextNestedPhotoUpdateInput | null;
  metadata?: MetadataDocumentTextNestedMetadataUpdateInput | null;
}

export interface MetadataDocumentTextWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataDocumentTextNestedPhotoCreateInput;
  archive: MetadataDocumentTextNestedArchiveCreateInput;
}

export interface MetadataDocumentTextWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataDocumentTextNestedPhotoUpdateInput | null;
  archive?: MetadataDocumentTextNestedArchiveUpdateInput | null;
}

export interface MetadataDocumentTextWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataDocumentTextNestedMetadataCreateInput;
  archive: MetadataDocumentTextNestedArchiveCreateInput;
}

export interface MetadataDocumentTextWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataDocumentTextNestedMetadataUpdateInput | null;
  archive?: MetadataDocumentTextNestedArchiveUpdateInput | null;
}

export interface MetadataEditStatusNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataEditStatusNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataEditStatusNestedUpsertArchiveCreateInput | null;
}

export interface MetadataEditStatusNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataEditStatusNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataEditStatusNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataEditStatusNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataEditStatusNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataEditStatusNestedUpsertMetadataCreateInput | null;
}

export interface MetadataEditStatusNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataEditStatusNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataEditStatusNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataEditStatusNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataEditStatusNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataEditStatusNestedUpsertPhotoCreateInput | null;
}

export interface MetadataEditStatusNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataEditStatusNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataEditStatusNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataEditStatusNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataEditStatusNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataEditStatusNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataEditStatusNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataEditStatusNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataEditStatusNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataEditStatusNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataEditStatusNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataEditStatusNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataEditStatusNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataEditStatusNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataEditStatusNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataEditStatus" nodes by specifying some conditions
 */
export interface MetadataEditStatusWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataEditStatusWhereInput[] | null;
  OR?: MetadataEditStatusWhereInput[] | null;
  NOT?: MetadataEditStatusWhereInput | null;
}

/**
 * Identifies exactly one "MetadataEditStatus" node.
 */
export interface MetadataEditStatusWhereUniqueInput {
  id: number;
}

export interface MetadataEditStatusWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataEditStatusNestedPhotoCreateInput;
  metadata: MetadataEditStatusNestedMetadataCreateInput;
}

export interface MetadataEditStatusWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataEditStatusNestedPhotoUpdateInput | null;
  metadata?: MetadataEditStatusNestedMetadataUpdateInput | null;
}

export interface MetadataEditStatusWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataEditStatusNestedPhotoCreateInput;
  archive: MetadataEditStatusNestedArchiveCreateInput;
}

export interface MetadataEditStatusWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataEditStatusNestedPhotoUpdateInput | null;
  archive?: MetadataEditStatusNestedArchiveUpdateInput | null;
}

export interface MetadataEditStatusWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataEditStatusNestedMetadataCreateInput;
  archive: MetadataEditStatusNestedArchiveCreateInput;
}

export interface MetadataEditStatusWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataEditStatusNestedMetadataUpdateInput | null;
  archive?: MetadataEditStatusNestedArchiveUpdateInput | null;
}

export interface MetadataEditionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataEditionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataEditionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataEditionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataEditionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataEditionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataEditionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataEditionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataEditionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataEditionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataEditionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataEditionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataEditionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataEditionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataEditionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataEditionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataEditionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataEditionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataEditionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataEditionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataEditionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataEditionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataEditionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataEditionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataEditionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataEditionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataEditionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataEditionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataEditionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataEditionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataEdition" nodes by specifying some conditions
 */
export interface MetadataEditionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataEditionWhereInput[] | null;
  OR?: MetadataEditionWhereInput[] | null;
  NOT?: MetadataEditionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataEdition" node.
 */
export interface MetadataEditionWhereUniqueInput {
  id: number;
}

export interface MetadataEditionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataEditionNestedPhotoCreateInput;
  metadata: MetadataEditionNestedMetadataCreateInput;
}

export interface MetadataEditionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataEditionNestedPhotoUpdateInput | null;
  metadata?: MetadataEditionNestedMetadataUpdateInput | null;
}

export interface MetadataEditionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataEditionNestedPhotoCreateInput;
  archive: MetadataEditionNestedArchiveCreateInput;
}

export interface MetadataEditionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataEditionNestedPhotoUpdateInput | null;
  archive?: MetadataEditionNestedArchiveUpdateInput | null;
}

export interface MetadataEditionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataEditionNestedMetadataCreateInput;
  archive: MetadataEditionNestedArchiveCreateInput;
}

export interface MetadataEditionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataEditionNestedMetadataUpdateInput | null;
  archive?: MetadataEditionNestedArchiveUpdateInput | null;
}

export interface MetadataEditorialVersionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataEditorialVersionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataEditorialVersionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataEditorialVersionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataEditorialVersionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataEditorialVersionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataEditorialVersionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataEditorialVersionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataEditorialVersionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataEditorialVersionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataEditorialVersionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataEditorialVersionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataEditorialVersionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataEditorialVersionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataEditorialVersionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataEditorialVersionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataEditorialVersionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataEditorialVersionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataEditorialVersionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataEditorialVersionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataEditorialVersionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataEditorialVersionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataEditorialVersionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataEditorialVersionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataEditorialVersionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataEditorialVersionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataEditorialVersionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataEditorialVersionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataEditorialVersionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataEditorialVersionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataEditorialVersion" nodes by specifying some conditions
 */
export interface MetadataEditorialVersionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataEditorialVersionWhereInput[] | null;
  OR?: MetadataEditorialVersionWhereInput[] | null;
  NOT?: MetadataEditorialVersionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataEditorialVersion" node.
 */
export interface MetadataEditorialVersionWhereUniqueInput {
  id: number;
}

export interface MetadataEditorialVersionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataEditorialVersionNestedPhotoCreateInput;
  metadata: MetadataEditorialVersionNestedMetadataCreateInput;
}

export interface MetadataEditorialVersionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataEditorialVersionNestedPhotoUpdateInput | null;
  metadata?: MetadataEditorialVersionNestedMetadataUpdateInput | null;
}

export interface MetadataEditorialVersionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataEditorialVersionNestedPhotoCreateInput;
  archive: MetadataEditorialVersionNestedArchiveCreateInput;
}

export interface MetadataEditorialVersionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataEditorialVersionNestedPhotoUpdateInput | null;
  archive?: MetadataEditorialVersionNestedArchiveUpdateInput | null;
}

export interface MetadataEditorialVersionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataEditorialVersionNestedMetadataCreateInput;
  archive: MetadataEditorialVersionNestedArchiveCreateInput;
}

export interface MetadataEditorialVersionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataEditorialVersionNestedMetadataUpdateInput | null;
  archive?: MetadataEditorialVersionNestedArchiveUpdateInput | null;
}

export interface MetadataEnvironnementPhotoNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataEnvironnementPhotoNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataEnvironnementPhotoNestedUpsertArchiveCreateInput | null;
}

export interface MetadataEnvironnementPhotoNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataEnvironnementPhotoNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataEnvironnementPhotoNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataEnvironnementPhotoNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataEnvironnementPhotoNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataEnvironnementPhotoNestedUpsertMetadataCreateInput | null;
}

export interface MetadataEnvironnementPhotoNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataEnvironnementPhotoNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataEnvironnementPhotoNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataEnvironnementPhotoNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataEnvironnementPhotoNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataEnvironnementPhotoNestedUpsertPhotoCreateInput | null;
}

export interface MetadataEnvironnementPhotoNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataEnvironnementPhotoNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataEnvironnementPhotoNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataEnvironnementPhotoNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataEnvironnementPhotoNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataEnvironnementPhotoNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataEnvironnementPhotoNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataEnvironnementPhotoNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataEnvironnementPhotoNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataEnvironnementPhotoNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataEnvironnementPhotoNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataEnvironnementPhotoNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataEnvironnementPhotoNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataEnvironnementPhotoNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataEnvironnementPhotoNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataEnvironnementPhoto" nodes by specifying some conditions
 */
export interface MetadataEnvironnementPhotoWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataEnvironnementPhotoWhereInput[] | null;
  OR?: MetadataEnvironnementPhotoWhereInput[] | null;
  NOT?: MetadataEnvironnementPhotoWhereInput | null;
}

/**
 * Identifies exactly one "MetadataEnvironnementPhoto" node.
 */
export interface MetadataEnvironnementPhotoWhereUniqueInput {
  id: number;
}

export interface MetadataEnvironnementPhotoWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataEnvironnementPhotoNestedPhotoCreateInput;
  metadata: MetadataEnvironnementPhotoNestedMetadataCreateInput;
}

export interface MetadataEnvironnementPhotoWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataEnvironnementPhotoNestedPhotoUpdateInput | null;
  metadata?: MetadataEnvironnementPhotoNestedMetadataUpdateInput | null;
}

export interface MetadataEnvironnementPhotoWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataEnvironnementPhotoNestedPhotoCreateInput;
  archive: MetadataEnvironnementPhotoNestedArchiveCreateInput;
}

export interface MetadataEnvironnementPhotoWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataEnvironnementPhotoNestedPhotoUpdateInput | null;
  archive?: MetadataEnvironnementPhotoNestedArchiveUpdateInput | null;
}

export interface MetadataEnvironnementPhotoWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataEnvironnementPhotoNestedMetadataCreateInput;
  archive: MetadataEnvironnementPhotoNestedArchiveCreateInput;
}

export interface MetadataEnvironnementPhotoWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataEnvironnementPhotoNestedMetadataUpdateInput | null;
  archive?: MetadataEnvironnementPhotoNestedArchiveUpdateInput | null;
}

export interface MetadataEquipmentInstitutionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataEquipmentInstitutionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataEquipmentInstitutionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataEquipmentInstitutionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataEquipmentInstitutionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataEquipmentInstitutionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataEquipmentInstitutionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataEquipmentInstitutionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataEquipmentInstitutionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataEquipmentInstitutionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataEquipmentInstitutionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataEquipmentInstitutionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataEquipmentInstitutionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataEquipmentInstitutionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataEquipmentInstitutionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataEquipmentInstitutionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataEquipmentInstitutionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataEquipmentInstitutionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataEquipmentInstitutionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataEquipmentInstitutionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataEquipmentInstitutionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataEquipmentInstitutionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataEquipmentInstitutionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataEquipmentInstitutionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataEquipmentInstitutionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataEquipmentInstitutionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataEquipmentInstitutionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataEquipmentInstitutionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataEquipmentInstitutionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataEquipmentInstitutionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataEquipmentInstitution" nodes by specifying some conditions
 */
export interface MetadataEquipmentInstitutionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataEquipmentInstitutionWhereInput[] | null;
  OR?: MetadataEquipmentInstitutionWhereInput[] | null;
  NOT?: MetadataEquipmentInstitutionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataEquipmentInstitution" node.
 */
export interface MetadataEquipmentInstitutionWhereUniqueInput {
  id: number;
}

export interface MetadataEquipmentInstitutionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataEquipmentInstitutionNestedPhotoCreateInput;
  metadata: MetadataEquipmentInstitutionNestedMetadataCreateInput;
}

export interface MetadataEquipmentInstitutionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataEquipmentInstitutionNestedPhotoUpdateInput | null;
  metadata?: MetadataEquipmentInstitutionNestedMetadataUpdateInput | null;
}

export interface MetadataEquipmentInstitutionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataEquipmentInstitutionNestedPhotoCreateInput;
  archive: MetadataEquipmentInstitutionNestedArchiveCreateInput;
}

export interface MetadataEquipmentInstitutionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataEquipmentInstitutionNestedPhotoUpdateInput | null;
  archive?: MetadataEquipmentInstitutionNestedArchiveUpdateInput | null;
}

export interface MetadataEquipmentInstitutionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataEquipmentInstitutionNestedMetadataCreateInput;
  archive: MetadataEquipmentInstitutionNestedArchiveCreateInput;
}

export interface MetadataEquipmentInstitutionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataEquipmentInstitutionNestedMetadataUpdateInput | null;
  archive?: MetadataEquipmentInstitutionNestedArchiveUpdateInput | null;
}

export interface MetadataEquipmentManufacturerNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataEquipmentManufacturerNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataEquipmentManufacturerNestedUpsertArchiveCreateInput | null;
}

export interface MetadataEquipmentManufacturerNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataEquipmentManufacturerNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataEquipmentManufacturerNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataEquipmentManufacturerNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataEquipmentManufacturerNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataEquipmentManufacturerNestedUpsertMetadataCreateInput | null;
}

export interface MetadataEquipmentManufacturerNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataEquipmentManufacturerNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataEquipmentManufacturerNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataEquipmentManufacturerNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataEquipmentManufacturerNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataEquipmentManufacturerNestedUpsertPhotoCreateInput | null;
}

export interface MetadataEquipmentManufacturerNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataEquipmentManufacturerNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataEquipmentManufacturerNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataEquipmentManufacturerNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataEquipmentManufacturerNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataEquipmentManufacturerNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataEquipmentManufacturerNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataEquipmentManufacturerNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataEquipmentManufacturerNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataEquipmentManufacturerNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataEquipmentManufacturerNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataEquipmentManufacturerNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataEquipmentManufacturerNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataEquipmentManufacturerNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataEquipmentManufacturerNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataEquipmentManufacturer" nodes by specifying some conditions
 */
export interface MetadataEquipmentManufacturerWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataEquipmentManufacturerWhereInput[] | null;
  OR?: MetadataEquipmentManufacturerWhereInput[] | null;
  NOT?: MetadataEquipmentManufacturerWhereInput | null;
}

/**
 * Identifies exactly one "MetadataEquipmentManufacturer" node.
 */
export interface MetadataEquipmentManufacturerWhereUniqueInput {
  id: number;
}

export interface MetadataEquipmentManufacturerWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataEquipmentManufacturerNestedPhotoCreateInput;
  metadata: MetadataEquipmentManufacturerNestedMetadataCreateInput;
}

export interface MetadataEquipmentManufacturerWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataEquipmentManufacturerNestedPhotoUpdateInput | null;
  metadata?: MetadataEquipmentManufacturerNestedMetadataUpdateInput | null;
}

export interface MetadataEquipmentManufacturerWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataEquipmentManufacturerNestedPhotoCreateInput;
  archive: MetadataEquipmentManufacturerNestedArchiveCreateInput;
}

export interface MetadataEquipmentManufacturerWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataEquipmentManufacturerNestedPhotoUpdateInput | null;
  archive?: MetadataEquipmentManufacturerNestedArchiveUpdateInput | null;
}

export interface MetadataEquipmentManufacturerWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataEquipmentManufacturerNestedMetadataCreateInput;
  archive: MetadataEquipmentManufacturerNestedArchiveCreateInput;
}

export interface MetadataEquipmentManufacturerWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataEquipmentManufacturerNestedMetadataUpdateInput | null;
  archive?: MetadataEquipmentManufacturerNestedArchiveUpdateInput | null;
}

export interface MetadataEventNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataEventNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataEventNestedUpsertArchiveCreateInput | null;
}

export interface MetadataEventNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataEventNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataEventNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataEventNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataEventNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataEventNestedUpsertMetadataCreateInput | null;
}

export interface MetadataEventNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataEventNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataEventNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataEventNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataEventNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataEventNestedUpsertPhotoCreateInput | null;
}

export interface MetadataEventNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataEventNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataEventNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataEventNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataEventNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataEventNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataEventNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataEventNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataEventNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataEventNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataEventNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataEventNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataEventNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataEventNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataEventNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataEvent" nodes by specifying some conditions
 */
export interface MetadataEventWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataEventWhereInput[] | null;
  OR?: MetadataEventWhereInput[] | null;
  NOT?: MetadataEventWhereInput | null;
}

/**
 * Identifies exactly one "MetadataEvent" node.
 */
export interface MetadataEventWhereUniqueInput {
  id: number;
}

export interface MetadataEventWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataEventNestedPhotoCreateInput;
  metadata: MetadataEventNestedMetadataCreateInput;
}

export interface MetadataEventWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataEventNestedPhotoUpdateInput | null;
  metadata?: MetadataEventNestedMetadataUpdateInput | null;
}

export interface MetadataEventWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataEventNestedPhotoCreateInput;
  archive: MetadataEventNestedArchiveCreateInput;
}

export interface MetadataEventWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataEventNestedPhotoUpdateInput | null;
  archive?: MetadataEventNestedArchiveUpdateInput | null;
}

export interface MetadataEventWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataEventNestedMetadataCreateInput;
  archive: MetadataEventNestedArchiveCreateInput;
}

export interface MetadataEventWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataEventNestedMetadataUpdateInput | null;
  archive?: MetadataEventNestedArchiveUpdateInput | null;
}

export interface MetadataExifNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataExifNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataExifNestedUpsertArchiveCreateInput | null;
}

export interface MetadataExifNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataExifNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataExifNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataExifNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataExifNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataExifNestedUpsertMetadataCreateInput | null;
}

export interface MetadataExifNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataExifNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataExifNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataExifNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataExifNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataExifNestedUpsertPhotoCreateInput | null;
}

export interface MetadataExifNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataExifNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataExifNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataExifNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataExifNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataExifNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataExifNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataExifNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataExifNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataExifNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataExifNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataExifNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataExifNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataExifNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataExifNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataExif" nodes by specifying some conditions
 */
export interface MetadataExifWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataExifWhereInput[] | null;
  OR?: MetadataExifWhereInput[] | null;
  NOT?: MetadataExifWhereInput | null;
}

/**
 * Identifies exactly one "MetadataExif" node.
 */
export interface MetadataExifWhereUniqueInput {
  id: number;
}

export interface MetadataExifWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataExifNestedPhotoCreateInput;
  metadata: MetadataExifNestedMetadataCreateInput;
}

export interface MetadataExifWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataExifNestedPhotoUpdateInput | null;
  metadata?: MetadataExifNestedMetadataUpdateInput | null;
}

export interface MetadataExifWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataExifNestedPhotoCreateInput;
  archive: MetadataExifNestedArchiveCreateInput;
}

export interface MetadataExifWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataExifNestedPhotoUpdateInput | null;
  archive?: MetadataExifNestedArchiveUpdateInput | null;
}

export interface MetadataExifWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataExifNestedMetadataCreateInput;
  archive: MetadataExifNestedArchiveCreateInput;
}

export interface MetadataExifWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataExifNestedMetadataUpdateInput | null;
  archive?: MetadataExifNestedArchiveUpdateInput | null;
}

export interface MetadataFabStorageNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFabStorageNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFabStorageNestedUpsertArchiveCreateInput | null;
}

export interface MetadataFabStorageNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFabStorageNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFabStorageNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataFabStorageNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFabStorageNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFabStorageNestedUpsertMetadataCreateInput | null;
}

export interface MetadataFabStorageNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFabStorageNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFabStorageNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataFabStorageNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFabStorageNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFabStorageNestedUpsertPhotoCreateInput | null;
}

export interface MetadataFabStorageNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFabStorageNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFabStorageNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataFabStorageNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFabStorageNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFabStorageNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFabStorageNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFabStorageNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFabStorageNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFabStorageNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFabStorageNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFabStorageNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFabStorageNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFabStorageNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataFabStorageNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataFabStorage" nodes by specifying some conditions
 */
export interface MetadataFabStorageWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFabStorageWhereInput[] | null;
  OR?: MetadataFabStorageWhereInput[] | null;
  NOT?: MetadataFabStorageWhereInput | null;
}

/**
 * Identifies exactly one "MetadataFabStorage" node.
 */
export interface MetadataFabStorageWhereUniqueInput {
  id: number;
}

export interface MetadataFabStorageWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataFabStorageNestedPhotoCreateInput;
  metadata: MetadataFabStorageNestedMetadataCreateInput;
}

export interface MetadataFabStorageWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataFabStorageNestedPhotoUpdateInput | null;
  metadata?: MetadataFabStorageNestedMetadataUpdateInput | null;
}

export interface MetadataFabStorageWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataFabStorageNestedPhotoCreateInput;
  archive: MetadataFabStorageNestedArchiveCreateInput;
}

export interface MetadataFabStorageWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataFabStorageNestedPhotoUpdateInput | null;
  archive?: MetadataFabStorageNestedArchiveUpdateInput | null;
}

export interface MetadataFabStorageWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataFabStorageNestedMetadataCreateInput;
  archive: MetadataFabStorageNestedArchiveCreateInput;
}

export interface MetadataFabStorageWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataFabStorageNestedMetadataUpdateInput | null;
  archive?: MetadataFabStorageNestedArchiveUpdateInput | null;
}

export interface MetadataFileCheckForNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileCheckForNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileCheckForNestedUpsertArchiveCreateInput | null;
}

export interface MetadataFileCheckForNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileCheckForNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileCheckForNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataFileCheckForNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileCheckForNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileCheckForNestedUpsertMetadataCreateInput | null;
}

export interface MetadataFileCheckForNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileCheckForNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileCheckForNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataFileCheckForNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileCheckForNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileCheckForNestedUpsertPhotoCreateInput | null;
}

export interface MetadataFileCheckForNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileCheckForNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileCheckForNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataFileCheckForNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileCheckForNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileCheckForNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileCheckForNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileCheckForNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileCheckForNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileCheckForNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileCheckForNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileCheckForNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileCheckForNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileCheckForNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataFileCheckForNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataFileCheckFor" nodes by specifying some conditions
 */
export interface MetadataFileCheckForWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileCheckForWhereInput[] | null;
  OR?: MetadataFileCheckForWhereInput[] | null;
  NOT?: MetadataFileCheckForWhereInput | null;
}

/**
 * Identifies exactly one "MetadataFileCheckFor" node.
 */
export interface MetadataFileCheckForWhereUniqueInput {
  id: number;
}

export interface MetadataFileCheckForWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataFileCheckForNestedPhotoCreateInput;
  metadata: MetadataFileCheckForNestedMetadataCreateInput;
}

export interface MetadataFileCheckForWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataFileCheckForNestedPhotoUpdateInput | null;
  metadata?: MetadataFileCheckForNestedMetadataUpdateInput | null;
}

export interface MetadataFileCheckForWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataFileCheckForNestedPhotoCreateInput;
  archive: MetadataFileCheckForNestedArchiveCreateInput;
}

export interface MetadataFileCheckForWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataFileCheckForNestedPhotoUpdateInput | null;
  archive?: MetadataFileCheckForNestedArchiveUpdateInput | null;
}

export interface MetadataFileCheckForWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataFileCheckForNestedMetadataCreateInput;
  archive: MetadataFileCheckForNestedArchiveCreateInput;
}

export interface MetadataFileCheckForWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataFileCheckForNestedMetadataUpdateInput | null;
  archive?: MetadataFileCheckForNestedArchiveUpdateInput | null;
}

export interface MetadataFileInfoBitsPerPixelNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileInfoBitsPerPixelNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileInfoBitsPerPixelNestedUpsertArchiveCreateInput | null;
}

export interface MetadataFileInfoBitsPerPixelNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileInfoBitsPerPixelNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileInfoBitsPerPixelNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataFileInfoBitsPerPixelNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileInfoBitsPerPixelNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileInfoBitsPerPixelNestedUpsertMetadataCreateInput | null;
}

export interface MetadataFileInfoBitsPerPixelNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileInfoBitsPerPixelNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileInfoBitsPerPixelNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataFileInfoBitsPerPixelNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileInfoBitsPerPixelNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileInfoBitsPerPixelNestedUpsertPhotoCreateInput | null;
}

export interface MetadataFileInfoBitsPerPixelNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileInfoBitsPerPixelNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileInfoBitsPerPixelNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataFileInfoBitsPerPixelNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileInfoBitsPerPixelNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileInfoBitsPerPixelNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileInfoBitsPerPixelNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileInfoBitsPerPixelNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileInfoBitsPerPixelNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileInfoBitsPerPixelNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileInfoBitsPerPixelNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileInfoBitsPerPixelNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileInfoBitsPerPixelNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileInfoBitsPerPixelNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataFileInfoBitsPerPixelNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataFileInfoBitsPerPixel" nodes by specifying some conditions
 */
export interface MetadataFileInfoBitsPerPixelWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileInfoBitsPerPixelWhereInput[] | null;
  OR?: MetadataFileInfoBitsPerPixelWhereInput[] | null;
  NOT?: MetadataFileInfoBitsPerPixelWhereInput | null;
}

/**
 * Identifies exactly one "MetadataFileInfoBitsPerPixel" node.
 */
export interface MetadataFileInfoBitsPerPixelWhereUniqueInput {
  id: number;
}

export interface MetadataFileInfoBitsPerPixelWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataFileInfoBitsPerPixelNestedPhotoCreateInput;
  metadata: MetadataFileInfoBitsPerPixelNestedMetadataCreateInput;
}

export interface MetadataFileInfoBitsPerPixelWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataFileInfoBitsPerPixelNestedPhotoUpdateInput | null;
  metadata?: MetadataFileInfoBitsPerPixelNestedMetadataUpdateInput | null;
}

export interface MetadataFileInfoBitsPerPixelWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataFileInfoBitsPerPixelNestedPhotoCreateInput;
  archive: MetadataFileInfoBitsPerPixelNestedArchiveCreateInput;
}

export interface MetadataFileInfoBitsPerPixelWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataFileInfoBitsPerPixelNestedPhotoUpdateInput | null;
  archive?: MetadataFileInfoBitsPerPixelNestedArchiveUpdateInput | null;
}

export interface MetadataFileInfoBitsPerPixelWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataFileInfoBitsPerPixelNestedMetadataCreateInput;
  archive: MetadataFileInfoBitsPerPixelNestedArchiveCreateInput;
}

export interface MetadataFileInfoBitsPerPixelWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataFileInfoBitsPerPixelNestedMetadataUpdateInput | null;
  archive?: MetadataFileInfoBitsPerPixelNestedArchiveUpdateInput | null;
}

export interface MetadataFileInfoFramesNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileInfoFramesNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileInfoFramesNestedUpsertArchiveCreateInput | null;
}

export interface MetadataFileInfoFramesNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileInfoFramesNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileInfoFramesNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataFileInfoFramesNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileInfoFramesNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileInfoFramesNestedUpsertMetadataCreateInput | null;
}

export interface MetadataFileInfoFramesNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileInfoFramesNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileInfoFramesNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataFileInfoFramesNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileInfoFramesNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileInfoFramesNestedUpsertPhotoCreateInput | null;
}

export interface MetadataFileInfoFramesNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileInfoFramesNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileInfoFramesNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataFileInfoFramesNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileInfoFramesNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileInfoFramesNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileInfoFramesNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileInfoFramesNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileInfoFramesNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileInfoFramesNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileInfoFramesNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileInfoFramesNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileInfoFramesNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileInfoFramesNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataFileInfoFramesNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataFileInfoFrames" nodes by specifying some conditions
 */
export interface MetadataFileInfoFramesWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileInfoFramesWhereInput[] | null;
  OR?: MetadataFileInfoFramesWhereInput[] | null;
  NOT?: MetadataFileInfoFramesWhereInput | null;
}

/**
 * Identifies exactly one "MetadataFileInfoFrames" node.
 */
export interface MetadataFileInfoFramesWhereUniqueInput {
  id: number;
}

export interface MetadataFileInfoFramesWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataFileInfoFramesNestedPhotoCreateInput;
  metadata: MetadataFileInfoFramesNestedMetadataCreateInput;
}

export interface MetadataFileInfoFramesWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataFileInfoFramesNestedPhotoUpdateInput | null;
  metadata?: MetadataFileInfoFramesNestedMetadataUpdateInput | null;
}

export interface MetadataFileInfoFramesWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataFileInfoFramesNestedPhotoCreateInput;
  archive: MetadataFileInfoFramesNestedArchiveCreateInput;
}

export interface MetadataFileInfoFramesWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataFileInfoFramesNestedPhotoUpdateInput | null;
  archive?: MetadataFileInfoFramesNestedArchiveUpdateInput | null;
}

export interface MetadataFileInfoFramesWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataFileInfoFramesNestedMetadataCreateInput;
  archive: MetadataFileInfoFramesNestedArchiveCreateInput;
}

export interface MetadataFileInfoFramesWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataFileInfoFramesNestedMetadataUpdateInput | null;
  archive?: MetadataFileInfoFramesNestedArchiveUpdateInput | null;
}

export interface MetadataFileInfoPhotometricInterpretationNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileInfoPhotometricInterpretationNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileInfoPhotometricInterpretationNestedUpsertArchiveCreateInput | null;
}

export interface MetadataFileInfoPhotometricInterpretationNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileInfoPhotometricInterpretationNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileInfoPhotometricInterpretationNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataFileInfoPhotometricInterpretationNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileInfoPhotometricInterpretationNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileInfoPhotometricInterpretationNestedUpsertMetadataCreateInput | null;
}

export interface MetadataFileInfoPhotometricInterpretationNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileInfoPhotometricInterpretationNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileInfoPhotometricInterpretationNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataFileInfoPhotometricInterpretationNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileInfoPhotometricInterpretationNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileInfoPhotometricInterpretationNestedUpsertPhotoCreateInput | null;
}

export interface MetadataFileInfoPhotometricInterpretationNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileInfoPhotometricInterpretationNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileInfoPhotometricInterpretationNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataFileInfoPhotometricInterpretationNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileInfoPhotometricInterpretationNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileInfoPhotometricInterpretationNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileInfoPhotometricInterpretationNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileInfoPhotometricInterpretationNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileInfoPhotometricInterpretationNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileInfoPhotometricInterpretationNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileInfoPhotometricInterpretationNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileInfoPhotometricInterpretationNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileInfoPhotometricInterpretationNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileInfoPhotometricInterpretationNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataFileInfoPhotometricInterpretationNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataFileInfoPhotometricInterpretation" nodes by specifying some conditions
 */
export interface MetadataFileInfoPhotometricInterpretationWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileInfoPhotometricInterpretationWhereInput[] | null;
  OR?: MetadataFileInfoPhotometricInterpretationWhereInput[] | null;
  NOT?: MetadataFileInfoPhotometricInterpretationWhereInput | null;
}

/**
 * Identifies exactly one "MetadataFileInfoPhotometricInterpretation" node.
 */
export interface MetadataFileInfoPhotometricInterpretationWhereUniqueInput {
  id: number;
}

export interface MetadataFileInfoPhotometricInterpretationWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataFileInfoPhotometricInterpretationNestedPhotoCreateInput;
  metadata: MetadataFileInfoPhotometricInterpretationNestedMetadataCreateInput;
}

export interface MetadataFileInfoPhotometricInterpretationWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataFileInfoPhotometricInterpretationNestedPhotoUpdateInput | null;
  metadata?: MetadataFileInfoPhotometricInterpretationNestedMetadataUpdateInput | null;
}

export interface MetadataFileInfoPhotometricInterpretationWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataFileInfoPhotometricInterpretationNestedPhotoCreateInput;
  archive: MetadataFileInfoPhotometricInterpretationNestedArchiveCreateInput;
}

export interface MetadataFileInfoPhotometricInterpretationWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataFileInfoPhotometricInterpretationNestedPhotoUpdateInput | null;
  archive?: MetadataFileInfoPhotometricInterpretationNestedArchiveUpdateInput | null;
}

export interface MetadataFileInfoPhotometricInterpretationWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataFileInfoPhotometricInterpretationNestedMetadataCreateInput;
  archive: MetadataFileInfoPhotometricInterpretationNestedArchiveCreateInput;
}

export interface MetadataFileInfoPhotometricInterpretationWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataFileInfoPhotometricInterpretationNestedMetadataUpdateInput | null;
  archive?: MetadataFileInfoPhotometricInterpretationNestedArchiveUpdateInput | null;
}

export interface MetadataFileInfoTransferSyntaxNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileInfoTransferSyntaxNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileInfoTransferSyntaxNestedUpsertArchiveCreateInput | null;
}

export interface MetadataFileInfoTransferSyntaxNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileInfoTransferSyntaxNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileInfoTransferSyntaxNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataFileInfoTransferSyntaxNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileInfoTransferSyntaxNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileInfoTransferSyntaxNestedUpsertMetadataCreateInput | null;
}

export interface MetadataFileInfoTransferSyntaxNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileInfoTransferSyntaxNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileInfoTransferSyntaxNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataFileInfoTransferSyntaxNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileInfoTransferSyntaxNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileInfoTransferSyntaxNestedUpsertPhotoCreateInput | null;
}

export interface MetadataFileInfoTransferSyntaxNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileInfoTransferSyntaxNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileInfoTransferSyntaxNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataFileInfoTransferSyntaxNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileInfoTransferSyntaxNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileInfoTransferSyntaxNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileInfoTransferSyntaxNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileInfoTransferSyntaxNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileInfoTransferSyntaxNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileInfoTransferSyntaxNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileInfoTransferSyntaxNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileInfoTransferSyntaxNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileInfoTransferSyntaxNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileInfoTransferSyntaxNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataFileInfoTransferSyntaxNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataFileInfoTransferSyntax" nodes by specifying some conditions
 */
export interface MetadataFileInfoTransferSyntaxWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileInfoTransferSyntaxWhereInput[] | null;
  OR?: MetadataFileInfoTransferSyntaxWhereInput[] | null;
  NOT?: MetadataFileInfoTransferSyntaxWhereInput | null;
}

/**
 * Identifies exactly one "MetadataFileInfoTransferSyntax" node.
 */
export interface MetadataFileInfoTransferSyntaxWhereUniqueInput {
  id: number;
}

export interface MetadataFileInfoTransferSyntaxWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataFileInfoTransferSyntaxNestedPhotoCreateInput;
  metadata: MetadataFileInfoTransferSyntaxNestedMetadataCreateInput;
}

export interface MetadataFileInfoTransferSyntaxWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataFileInfoTransferSyntaxNestedPhotoUpdateInput | null;
  metadata?: MetadataFileInfoTransferSyntaxNestedMetadataUpdateInput | null;
}

export interface MetadataFileInfoTransferSyntaxWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataFileInfoTransferSyntaxNestedPhotoCreateInput;
  archive: MetadataFileInfoTransferSyntaxNestedArchiveCreateInput;
}

export interface MetadataFileInfoTransferSyntaxWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataFileInfoTransferSyntaxNestedPhotoUpdateInput | null;
  archive?: MetadataFileInfoTransferSyntaxNestedArchiveUpdateInput | null;
}

export interface MetadataFileInfoTransferSyntaxWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataFileInfoTransferSyntaxNestedMetadataCreateInput;
  archive: MetadataFileInfoTransferSyntaxNestedArchiveCreateInput;
}

export interface MetadataFileInfoTransferSyntaxWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataFileInfoTransferSyntaxNestedMetadataUpdateInput | null;
  archive?: MetadataFileInfoTransferSyntaxNestedArchiveUpdateInput | null;
}

export interface MetadataFileStageNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileStageNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileStageNestedUpsertArchiveCreateInput | null;
}

export interface MetadataFileStageNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileStageNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileStageNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataFileStageNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileStageNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileStageNestedUpsertMetadataCreateInput | null;
}

export interface MetadataFileStageNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileStageNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileStageNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataFileStageNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileStageNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileStageNestedUpsertPhotoCreateInput | null;
}

export interface MetadataFileStageNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileStageNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileStageNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataFileStageNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileStageNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileStageNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileStageNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileStageNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileStageNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileStageNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileStageNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileStageNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileStageNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileStageNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataFileStageNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataFileStage" nodes by specifying some conditions
 */
export interface MetadataFileStageWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileStageWhereInput[] | null;
  OR?: MetadataFileStageWhereInput[] | null;
  NOT?: MetadataFileStageWhereInput | null;
}

/**
 * Identifies exactly one "MetadataFileStage" node.
 */
export interface MetadataFileStageWhereUniqueInput {
  id: number;
}

export interface MetadataFileStageWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataFileStageNestedPhotoCreateInput;
  metadata: MetadataFileStageNestedMetadataCreateInput;
}

export interface MetadataFileStageWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataFileStageNestedPhotoUpdateInput | null;
  metadata?: MetadataFileStageNestedMetadataUpdateInput | null;
}

export interface MetadataFileStageWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataFileStageNestedPhotoCreateInput;
  archive: MetadataFileStageNestedArchiveCreateInput;
}

export interface MetadataFileStageWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataFileStageNestedPhotoUpdateInput | null;
  archive?: MetadataFileStageNestedArchiveUpdateInput | null;
}

export interface MetadataFileStageWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataFileStageNestedMetadataCreateInput;
  archive: MetadataFileStageNestedArchiveCreateInput;
}

export interface MetadataFileStageWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataFileStageNestedMetadataUpdateInput | null;
  archive?: MetadataFileStageNestedArchiveUpdateInput | null;
}

export interface MetadataFileStatusNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileStatusNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileStatusNestedUpsertArchiveCreateInput | null;
}

export interface MetadataFileStatusNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileStatusNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileStatusNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataFileStatusNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileStatusNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileStatusNestedUpsertMetadataCreateInput | null;
}

export interface MetadataFileStatusNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileStatusNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileStatusNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataFileStatusNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileStatusNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileStatusNestedUpsertPhotoCreateInput | null;
}

export interface MetadataFileStatusNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileStatusNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileStatusNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataFileStatusNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileStatusNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileStatusNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileStatusNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileStatusNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileStatusNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileStatusNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileStatusNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileStatusNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileStatusNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileStatusNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataFileStatusNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataFileStatus" nodes by specifying some conditions
 */
export interface MetadataFileStatusWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileStatusWhereInput[] | null;
  OR?: MetadataFileStatusWhereInput[] | null;
  NOT?: MetadataFileStatusWhereInput | null;
}

/**
 * Identifies exactly one "MetadataFileStatus" node.
 */
export interface MetadataFileStatusWhereUniqueInput {
  id: number;
}

export interface MetadataFileStatusWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataFileStatusNestedPhotoCreateInput;
  metadata: MetadataFileStatusNestedMetadataCreateInput;
}

export interface MetadataFileStatusWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataFileStatusNestedPhotoUpdateInput | null;
  metadata?: MetadataFileStatusNestedMetadataUpdateInput | null;
}

export interface MetadataFileStatusWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataFileStatusNestedPhotoCreateInput;
  archive: MetadataFileStatusNestedArchiveCreateInput;
}

export interface MetadataFileStatusWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataFileStatusNestedPhotoUpdateInput | null;
  archive?: MetadataFileStatusNestedArchiveUpdateInput | null;
}

export interface MetadataFileStatusWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataFileStatusNestedMetadataCreateInput;
  archive: MetadataFileStatusNestedArchiveCreateInput;
}

export interface MetadataFileStatusWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataFileStatusNestedMetadataUpdateInput | null;
  archive?: MetadataFileStatusNestedArchiveUpdateInput | null;
}

export interface MetadataFileTypeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileTypeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileTypeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataFileTypeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFileTypeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFileTypeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataFileTypeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileTypeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileTypeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataFileTypeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFileTypeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFileTypeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataFileTypeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileTypeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileTypeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataFileTypeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFileTypeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFileTypeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataFileTypeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileTypeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFileTypeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileTypeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFileTypeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileTypeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFileTypeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileTypeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFileTypeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileTypeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFileTypeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataFileTypeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataFileType" nodes by specifying some conditions
 */
export interface MetadataFileTypeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFileTypeWhereInput[] | null;
  OR?: MetadataFileTypeWhereInput[] | null;
  NOT?: MetadataFileTypeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataFileType" node.
 */
export interface MetadataFileTypeWhereUniqueInput {
  id: number;
}

export interface MetadataFileTypeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataFileTypeNestedPhotoCreateInput;
  metadata: MetadataFileTypeNestedMetadataCreateInput;
}

export interface MetadataFileTypeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataFileTypeNestedPhotoUpdateInput | null;
  metadata?: MetadataFileTypeNestedMetadataUpdateInput | null;
}

export interface MetadataFileTypeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataFileTypeNestedPhotoCreateInput;
  archive: MetadataFileTypeNestedArchiveCreateInput;
}

export interface MetadataFileTypeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataFileTypeNestedPhotoUpdateInput | null;
  archive?: MetadataFileTypeNestedArchiveUpdateInput | null;
}

export interface MetadataFileTypeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataFileTypeNestedMetadataCreateInput;
  archive: MetadataFileTypeNestedArchiveCreateInput;
}

export interface MetadataFileTypeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataFileTypeNestedMetadataUpdateInput | null;
  archive?: MetadataFileTypeNestedArchiveUpdateInput | null;
}

export interface MetadataFixtureIdentifierNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFixtureIdentifierNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFixtureIdentifierNestedUpsertArchiveCreateInput | null;
}

export interface MetadataFixtureIdentifierNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFixtureIdentifierNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFixtureIdentifierNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataFixtureIdentifierNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFixtureIdentifierNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFixtureIdentifierNestedUpsertMetadataCreateInput | null;
}

export interface MetadataFixtureIdentifierNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFixtureIdentifierNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFixtureIdentifierNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataFixtureIdentifierNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFixtureIdentifierNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFixtureIdentifierNestedUpsertPhotoCreateInput | null;
}

export interface MetadataFixtureIdentifierNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFixtureIdentifierNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFixtureIdentifierNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataFixtureIdentifierNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFixtureIdentifierNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFixtureIdentifierNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFixtureIdentifierNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFixtureIdentifierNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFixtureIdentifierNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFixtureIdentifierNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFixtureIdentifierNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFixtureIdentifierNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFixtureIdentifierNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFixtureIdentifierNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataFixtureIdentifierNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataFixtureIdentifier" nodes by specifying some conditions
 */
export interface MetadataFixtureIdentifierWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFixtureIdentifierWhereInput[] | null;
  OR?: MetadataFixtureIdentifierWhereInput[] | null;
  NOT?: MetadataFixtureIdentifierWhereInput | null;
}

/**
 * Identifies exactly one "MetadataFixtureIdentifier" node.
 */
export interface MetadataFixtureIdentifierWhereUniqueInput {
  id: number;
}

export interface MetadataFixtureIdentifierWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataFixtureIdentifierNestedPhotoCreateInput;
  metadata: MetadataFixtureIdentifierNestedMetadataCreateInput;
}

export interface MetadataFixtureIdentifierWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataFixtureIdentifierNestedPhotoUpdateInput | null;
  metadata?: MetadataFixtureIdentifierNestedMetadataUpdateInput | null;
}

export interface MetadataFixtureIdentifierWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataFixtureIdentifierNestedPhotoCreateInput;
  archive: MetadataFixtureIdentifierNestedArchiveCreateInput;
}

export interface MetadataFixtureIdentifierWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataFixtureIdentifierNestedPhotoUpdateInput | null;
  archive?: MetadataFixtureIdentifierNestedArchiveUpdateInput | null;
}

export interface MetadataFixtureIdentifierWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataFixtureIdentifierNestedMetadataCreateInput;
  archive: MetadataFixtureIdentifierNestedArchiveCreateInput;
}

export interface MetadataFixtureIdentifierWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataFixtureIdentifierNestedMetadataUpdateInput | null;
  archive?: MetadataFixtureIdentifierNestedArchiveUpdateInput | null;
}

export interface MetadataFormatNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFormatNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFormatNestedUpsertArchiveCreateInput | null;
}

export interface MetadataFormatNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataFormatNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataFormatNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataFormatNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFormatNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFormatNestedUpsertMetadataCreateInput | null;
}

export interface MetadataFormatNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataFormatNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataFormatNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataFormatNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFormatNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFormatNestedUpsertPhotoCreateInput | null;
}

export interface MetadataFormatNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataFormatNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataFormatNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataFormatNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFormatNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataFormatNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFormatNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataFormatNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFormatNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataFormatNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFormatNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataFormatNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFormatNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataFormatNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataFormatNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataFormat" nodes by specifying some conditions
 */
export interface MetadataFormatWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataFormatWhereInput[] | null;
  OR?: MetadataFormatWhereInput[] | null;
  NOT?: MetadataFormatWhereInput | null;
}

/**
 * Identifies exactly one "MetadataFormat" node.
 */
export interface MetadataFormatWhereUniqueInput {
  id: number;
}

export interface MetadataFormatWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataFormatNestedPhotoCreateInput;
  metadata: MetadataFormatNestedMetadataCreateInput;
}

export interface MetadataFormatWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataFormatNestedPhotoUpdateInput | null;
  metadata?: MetadataFormatNestedMetadataUpdateInput | null;
}

export interface MetadataFormatWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataFormatNestedPhotoCreateInput;
  archive: MetadataFormatNestedArchiveCreateInput;
}

export interface MetadataFormatWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataFormatNestedPhotoUpdateInput | null;
  archive?: MetadataFormatNestedArchiveUpdateInput | null;
}

export interface MetadataFormatWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataFormatNestedMetadataCreateInput;
  archive: MetadataFormatNestedArchiveCreateInput;
}

export interface MetadataFormatWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataFormatNestedMetadataUpdateInput | null;
  archive?: MetadataFormatNestedArchiveUpdateInput | null;
}

export interface MetadataGpsLatitudeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataGpsLatitudeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataGpsLatitudeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataGpsLatitudeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataGpsLatitudeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataGpsLatitudeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataGpsLatitudeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataGpsLatitudeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataGpsLatitudeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataGpsLatitudeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataGpsLatitudeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataGpsLatitudeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataGpsLatitudeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataGpsLatitudeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataGpsLatitudeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataGpsLatitudeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataGpsLatitudeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataGpsLatitudeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataGpsLatitudeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataGpsLatitudeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataGpsLatitudeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataGpsLatitudeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataGpsLatitudeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataGpsLatitudeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataGpsLatitudeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataGpsLatitudeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataGpsLatitudeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataGpsLatitudeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataGpsLatitudeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataGpsLatitudeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataGpsLatitude" nodes by specifying some conditions
 */
export interface MetadataGpsLatitudeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataGpsLatitudeWhereInput[] | null;
  OR?: MetadataGpsLatitudeWhereInput[] | null;
  NOT?: MetadataGpsLatitudeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataGpsLatitude" node.
 */
export interface MetadataGpsLatitudeWhereUniqueInput {
  id: number;
}

export interface MetadataGpsLatitudeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataGpsLatitudeNestedPhotoCreateInput;
  metadata: MetadataGpsLatitudeNestedMetadataCreateInput;
}

export interface MetadataGpsLatitudeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataGpsLatitudeNestedPhotoUpdateInput | null;
  metadata?: MetadataGpsLatitudeNestedMetadataUpdateInput | null;
}

export interface MetadataGpsLatitudeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataGpsLatitudeNestedPhotoCreateInput;
  archive: MetadataGpsLatitudeNestedArchiveCreateInput;
}

export interface MetadataGpsLatitudeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataGpsLatitudeNestedPhotoUpdateInput | null;
  archive?: MetadataGpsLatitudeNestedArchiveUpdateInput | null;
}

export interface MetadataGpsLatitudeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataGpsLatitudeNestedMetadataCreateInput;
  archive: MetadataGpsLatitudeNestedArchiveCreateInput;
}

export interface MetadataGpsLatitudeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataGpsLatitudeNestedMetadataUpdateInput | null;
  archive?: MetadataGpsLatitudeNestedArchiveUpdateInput | null;
}

export interface MetadataGpsLongitudeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataGpsLongitudeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataGpsLongitudeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataGpsLongitudeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataGpsLongitudeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataGpsLongitudeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataGpsLongitudeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataGpsLongitudeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataGpsLongitudeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataGpsLongitudeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataGpsLongitudeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataGpsLongitudeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataGpsLongitudeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataGpsLongitudeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataGpsLongitudeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataGpsLongitudeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataGpsLongitudeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataGpsLongitudeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataGpsLongitudeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataGpsLongitudeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataGpsLongitudeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataGpsLongitudeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataGpsLongitudeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataGpsLongitudeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataGpsLongitudeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataGpsLongitudeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataGpsLongitudeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataGpsLongitudeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataGpsLongitudeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataGpsLongitudeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataGpsLongitude" nodes by specifying some conditions
 */
export interface MetadataGpsLongitudeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataGpsLongitudeWhereInput[] | null;
  OR?: MetadataGpsLongitudeWhereInput[] | null;
  NOT?: MetadataGpsLongitudeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataGpsLongitude" node.
 */
export interface MetadataGpsLongitudeWhereUniqueInput {
  id: number;
}

export interface MetadataGpsLongitudeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataGpsLongitudeNestedPhotoCreateInput;
  metadata: MetadataGpsLongitudeNestedMetadataCreateInput;
}

export interface MetadataGpsLongitudeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataGpsLongitudeNestedPhotoUpdateInput | null;
  metadata?: MetadataGpsLongitudeNestedMetadataUpdateInput | null;
}

export interface MetadataGpsLongitudeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataGpsLongitudeNestedPhotoCreateInput;
  archive: MetadataGpsLongitudeNestedArchiveCreateInput;
}

export interface MetadataGpsLongitudeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataGpsLongitudeNestedPhotoUpdateInput | null;
  archive?: MetadataGpsLongitudeNestedArchiveUpdateInput | null;
}

export interface MetadataGpsLongitudeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataGpsLongitudeNestedMetadataCreateInput;
  archive: MetadataGpsLongitudeNestedArchiveCreateInput;
}

export interface MetadataGpsLongitudeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataGpsLongitudeNestedMetadataUpdateInput | null;
  archive?: MetadataGpsLongitudeNestedArchiveUpdateInput | null;
}

export interface MetadataGpsProcessingMethodNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataGpsProcessingMethodNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataGpsProcessingMethodNestedUpsertArchiveCreateInput | null;
}

export interface MetadataGpsProcessingMethodNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataGpsProcessingMethodNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataGpsProcessingMethodNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataGpsProcessingMethodNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataGpsProcessingMethodNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataGpsProcessingMethodNestedUpsertMetadataCreateInput | null;
}

export interface MetadataGpsProcessingMethodNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataGpsProcessingMethodNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataGpsProcessingMethodNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataGpsProcessingMethodNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataGpsProcessingMethodNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataGpsProcessingMethodNestedUpsertPhotoCreateInput | null;
}

export interface MetadataGpsProcessingMethodNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataGpsProcessingMethodNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataGpsProcessingMethodNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataGpsProcessingMethodNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataGpsProcessingMethodNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataGpsProcessingMethodNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataGpsProcessingMethodNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataGpsProcessingMethodNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataGpsProcessingMethodNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataGpsProcessingMethodNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataGpsProcessingMethodNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataGpsProcessingMethodNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataGpsProcessingMethodNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataGpsProcessingMethodNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataGpsProcessingMethodNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataGpsProcessingMethod" nodes by specifying some conditions
 */
export interface MetadataGpsProcessingMethodWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataGpsProcessingMethodWhereInput[] | null;
  OR?: MetadataGpsProcessingMethodWhereInput[] | null;
  NOT?: MetadataGpsProcessingMethodWhereInput | null;
}

/**
 * Identifies exactly one "MetadataGpsProcessingMethod" node.
 */
export interface MetadataGpsProcessingMethodWhereUniqueInput {
  id: number;
}

export interface MetadataGpsProcessingMethodWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataGpsProcessingMethodNestedPhotoCreateInput;
  metadata: MetadataGpsProcessingMethodNestedMetadataCreateInput;
}

export interface MetadataGpsProcessingMethodWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataGpsProcessingMethodNestedPhotoUpdateInput | null;
  metadata?: MetadataGpsProcessingMethodNestedMetadataUpdateInput | null;
}

export interface MetadataGpsProcessingMethodWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataGpsProcessingMethodNestedPhotoCreateInput;
  archive: MetadataGpsProcessingMethodNestedArchiveCreateInput;
}

export interface MetadataGpsProcessingMethodWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataGpsProcessingMethodNestedPhotoUpdateInput | null;
  archive?: MetadataGpsProcessingMethodNestedArchiveUpdateInput | null;
}

export interface MetadataGpsProcessingMethodWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataGpsProcessingMethodNestedMetadataCreateInput;
  archive: MetadataGpsProcessingMethodNestedArchiveCreateInput;
}

export interface MetadataGpsProcessingMethodWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataGpsProcessingMethodNestedMetadataUpdateInput | null;
  archive?: MetadataGpsProcessingMethodNestedArchiveUpdateInput | null;
}

export interface MetadataHeaderNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataHeaderNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataHeaderNestedUpsertArchiveCreateInput | null;
}

export interface MetadataHeaderNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataHeaderNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataHeaderNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataHeaderNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataHeaderNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataHeaderNestedUpsertMetadataCreateInput | null;
}

export interface MetadataHeaderNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataHeaderNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataHeaderNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataHeaderNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataHeaderNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataHeaderNestedUpsertPhotoCreateInput | null;
}

export interface MetadataHeaderNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataHeaderNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataHeaderNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataHeaderNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataHeaderNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataHeaderNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataHeaderNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataHeaderNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataHeaderNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataHeaderNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataHeaderNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataHeaderNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataHeaderNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataHeaderNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataHeaderNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataHeaderPrismaNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataHeaderPrismaNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataHeaderPrismaNestedUpsertArchiveCreateInput | null;
}

export interface MetadataHeaderPrismaNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataHeaderPrismaNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataHeaderPrismaNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataHeaderPrismaNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataHeaderPrismaNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataHeaderPrismaNestedUpsertMetadataCreateInput | null;
}

export interface MetadataHeaderPrismaNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataHeaderPrismaNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataHeaderPrismaNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataHeaderPrismaNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataHeaderPrismaNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataHeaderPrismaNestedUpsertPhotoCreateInput | null;
}

export interface MetadataHeaderPrismaNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataHeaderPrismaNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataHeaderPrismaNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataHeaderPrismaNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataHeaderPrismaNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataHeaderPrismaNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataHeaderPrismaNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataHeaderPrismaNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataHeaderPrismaNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataHeaderPrismaNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataHeaderPrismaNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataHeaderPrismaNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataHeaderPrismaNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataHeaderPrismaNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataHeaderPrismaNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataHeaderPrisma" nodes by specifying some conditions
 */
export interface MetadataHeaderPrismaWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataHeaderPrismaWhereInput[] | null;
  OR?: MetadataHeaderPrismaWhereInput[] | null;
  NOT?: MetadataHeaderPrismaWhereInput | null;
}

/**
 * Identifies exactly one "MetadataHeaderPrisma" node.
 */
export interface MetadataHeaderPrismaWhereUniqueInput {
  id: number;
}

export interface MetadataHeaderPrismaWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataHeaderPrismaNestedPhotoCreateInput;
  metadata: MetadataHeaderPrismaNestedMetadataCreateInput;
}

export interface MetadataHeaderPrismaWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataHeaderPrismaNestedPhotoUpdateInput | null;
  metadata?: MetadataHeaderPrismaNestedMetadataUpdateInput | null;
}

export interface MetadataHeaderPrismaWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataHeaderPrismaNestedPhotoCreateInput;
  archive: MetadataHeaderPrismaNestedArchiveCreateInput;
}

export interface MetadataHeaderPrismaWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataHeaderPrismaNestedPhotoUpdateInput | null;
  archive?: MetadataHeaderPrismaNestedArchiveUpdateInput | null;
}

export interface MetadataHeaderPrismaWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataHeaderPrismaNestedMetadataCreateInput;
  archive: MetadataHeaderPrismaNestedArchiveCreateInput;
}

export interface MetadataHeaderPrismaWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataHeaderPrismaNestedMetadataUpdateInput | null;
  archive?: MetadataHeaderPrismaNestedArchiveUpdateInput | null;
}

/**
 * Filters the "MetadataHeader" nodes by specifying some conditions
 */
export interface MetadataHeaderWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataHeaderWhereInput[] | null;
  OR?: MetadataHeaderWhereInput[] | null;
  NOT?: MetadataHeaderWhereInput | null;
}

/**
 * Identifies exactly one "MetadataHeader" node.
 */
export interface MetadataHeaderWhereUniqueInput {
  id: number;
}

export interface MetadataHeaderWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataHeaderNestedPhotoCreateInput;
  metadata: MetadataHeaderNestedMetadataCreateInput;
}

export interface MetadataHeaderWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataHeaderNestedPhotoUpdateInput | null;
  metadata?: MetadataHeaderNestedMetadataUpdateInput | null;
}

export interface MetadataHeaderWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataHeaderNestedPhotoCreateInput;
  archive: MetadataHeaderNestedArchiveCreateInput;
}

export interface MetadataHeaderWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataHeaderNestedPhotoUpdateInput | null;
  archive?: MetadataHeaderNestedArchiveUpdateInput | null;
}

export interface MetadataHeaderWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataHeaderNestedMetadataCreateInput;
  archive: MetadataHeaderNestedArchiveCreateInput;
}

export interface MetadataHeaderWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataHeaderNestedMetadataUpdateInput | null;
  archive?: MetadataHeaderNestedArchiveUpdateInput | null;
}

export interface MetadataHeadlineNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataHeadlineNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataHeadlineNestedUpsertArchiveCreateInput | null;
}

export interface MetadataHeadlineNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataHeadlineNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataHeadlineNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataHeadlineNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataHeadlineNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataHeadlineNestedUpsertMetadataCreateInput | null;
}

export interface MetadataHeadlineNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataHeadlineNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataHeadlineNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataHeadlineNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataHeadlineNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataHeadlineNestedUpsertPhotoCreateInput | null;
}

export interface MetadataHeadlineNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataHeadlineNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataHeadlineNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataHeadlineNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataHeadlineNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataHeadlineNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataHeadlineNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataHeadlineNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataHeadlineNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataHeadlineNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataHeadlineNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataHeadlineNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataHeadlineNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataHeadlineNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataHeadlineNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataHeadline" nodes by specifying some conditions
 */
export interface MetadataHeadlineWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataHeadlineWhereInput[] | null;
  OR?: MetadataHeadlineWhereInput[] | null;
  NOT?: MetadataHeadlineWhereInput | null;
}

/**
 * Identifies exactly one "MetadataHeadline" node.
 */
export interface MetadataHeadlineWhereUniqueInput {
  id: number;
}

export interface MetadataHeadlineWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataHeadlineNestedPhotoCreateInput;
  metadata: MetadataHeadlineNestedMetadataCreateInput;
}

export interface MetadataHeadlineWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataHeadlineNestedPhotoUpdateInput | null;
  metadata?: MetadataHeadlineNestedMetadataUpdateInput | null;
}

export interface MetadataHeadlineWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataHeadlineNestedPhotoCreateInput;
  archive: MetadataHeadlineNestedArchiveCreateInput;
}

export interface MetadataHeadlineWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataHeadlineNestedPhotoUpdateInput | null;
  archive?: MetadataHeadlineNestedArchiveUpdateInput | null;
}

export interface MetadataHeadlineWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataHeadlineNestedMetadataCreateInput;
  archive: MetadataHeadlineNestedArchiveCreateInput;
}

export interface MetadataHeadlineWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataHeadlineNestedMetadataUpdateInput | null;
  archive?: MetadataHeadlineNestedArchiveUpdateInput | null;
}

export interface MetadataHistoryNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataHistoryNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataHistoryNestedUpsertArchiveCreateInput | null;
}

export interface MetadataHistoryNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataHistoryNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataHistoryNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataHistoryNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataHistoryNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataHistoryNestedUpsertMetadataCreateInput | null;
}

export interface MetadataHistoryNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataHistoryNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataHistoryNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataHistoryNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataHistoryNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataHistoryNestedUpsertPhotoCreateInput | null;
}

export interface MetadataHistoryNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataHistoryNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataHistoryNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataHistoryNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataHistoryNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataHistoryNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataHistoryNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataHistoryNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataHistoryNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataHistoryNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataHistoryNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataHistoryNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataHistoryNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataHistoryNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataHistoryNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataHistory" nodes by specifying some conditions
 */
export interface MetadataHistoryWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataHistoryWhereInput[] | null;
  OR?: MetadataHistoryWhereInput[] | null;
  NOT?: MetadataHistoryWhereInput | null;
}

/**
 * Identifies exactly one "MetadataHistory" node.
 */
export interface MetadataHistoryWhereUniqueInput {
  id: number;
}

export interface MetadataHistoryWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataHistoryNestedPhotoCreateInput;
  metadata: MetadataHistoryNestedMetadataCreateInput;
}

export interface MetadataHistoryWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataHistoryNestedPhotoUpdateInput | null;
  metadata?: MetadataHistoryNestedMetadataUpdateInput | null;
}

export interface MetadataHistoryWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataHistoryNestedPhotoCreateInput;
  archive: MetadataHistoryNestedArchiveCreateInput;
}

export interface MetadataHistoryWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataHistoryNestedPhotoUpdateInput | null;
  archive?: MetadataHistoryNestedArchiveUpdateInput | null;
}

export interface MetadataHistoryWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataHistoryNestedMetadataCreateInput;
  archive: MetadataHistoryNestedArchiveCreateInput;
}

export interface MetadataHistoryWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataHistoryNestedMetadataUpdateInput | null;
  archive?: MetadataHistoryNestedArchiveUpdateInput | null;
}

export interface MetadataIccProfileNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIccProfileNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIccProfileNestedUpsertArchiveCreateInput | null;
}

export interface MetadataIccProfileNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIccProfileNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIccProfileNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataIccProfileNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIccProfileNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIccProfileNestedUpsertMetadataCreateInput | null;
}

export interface MetadataIccProfileNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIccProfileNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIccProfileNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataIccProfileNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIccProfileNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIccProfileNestedUpsertPhotoCreateInput | null;
}

export interface MetadataIccProfileNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIccProfileNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIccProfileNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataIccProfileNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIccProfileNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIccProfileNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIccProfileNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIccProfileNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIccProfileNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIccProfileNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIccProfileNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIccProfileNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIccProfileNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIccProfileNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataIccProfileNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataIccProfile" nodes by specifying some conditions
 */
export interface MetadataIccProfileWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIccProfileWhereInput[] | null;
  OR?: MetadataIccProfileWhereInput[] | null;
  NOT?: MetadataIccProfileWhereInput | null;
}

/**
 * Identifies exactly one "MetadataIccProfile" node.
 */
export interface MetadataIccProfileWhereUniqueInput {
  id: number;
}

export interface MetadataIccProfileWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataIccProfileNestedPhotoCreateInput;
  metadata: MetadataIccProfileNestedMetadataCreateInput;
}

export interface MetadataIccProfileWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataIccProfileNestedPhotoUpdateInput | null;
  metadata?: MetadataIccProfileNestedMetadataUpdateInput | null;
}

export interface MetadataIccProfileWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataIccProfileNestedPhotoCreateInput;
  archive: MetadataIccProfileNestedArchiveCreateInput;
}

export interface MetadataIccProfileWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataIccProfileNestedPhotoUpdateInput | null;
  archive?: MetadataIccProfileNestedArchiveUpdateInput | null;
}

export interface MetadataIccProfileWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataIccProfileNestedMetadataCreateInput;
  archive: MetadataIccProfileNestedArchiveCreateInput;
}

export interface MetadataIccProfileWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataIccProfileNestedMetadataUpdateInput | null;
  archive?: MetadataIccProfileNestedArchiveUpdateInput | null;
}

export interface MetadataIdAssignmentNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIdAssignmentNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIdAssignmentNestedUpsertArchiveCreateInput | null;
}

export interface MetadataIdAssignmentNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIdAssignmentNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIdAssignmentNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataIdAssignmentNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIdAssignmentNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIdAssignmentNestedUpsertMetadataCreateInput | null;
}

export interface MetadataIdAssignmentNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIdAssignmentNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIdAssignmentNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataIdAssignmentNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIdAssignmentNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIdAssignmentNestedUpsertPhotoCreateInput | null;
}

export interface MetadataIdAssignmentNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIdAssignmentNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIdAssignmentNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataIdAssignmentNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIdAssignmentNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIdAssignmentNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIdAssignmentNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIdAssignmentNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIdAssignmentNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIdAssignmentNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIdAssignmentNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIdAssignmentNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIdAssignmentNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIdAssignmentNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataIdAssignmentNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataIdAssignment" nodes by specifying some conditions
 */
export interface MetadataIdAssignmentWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIdAssignmentWhereInput[] | null;
  OR?: MetadataIdAssignmentWhereInput[] | null;
  NOT?: MetadataIdAssignmentWhereInput | null;
}

/**
 * Identifies exactly one "MetadataIdAssignment" node.
 */
export interface MetadataIdAssignmentWhereUniqueInput {
  id: number;
}

export interface MetadataIdAssignmentWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataIdAssignmentNestedPhotoCreateInput;
  metadata: MetadataIdAssignmentNestedMetadataCreateInput;
}

export interface MetadataIdAssignmentWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataIdAssignmentNestedPhotoUpdateInput | null;
  metadata?: MetadataIdAssignmentNestedMetadataUpdateInput | null;
}

export interface MetadataIdAssignmentWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataIdAssignmentNestedPhotoCreateInput;
  archive: MetadataIdAssignmentNestedArchiveCreateInput;
}

export interface MetadataIdAssignmentWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataIdAssignmentNestedPhotoUpdateInput | null;
  archive?: MetadataIdAssignmentNestedArchiveUpdateInput | null;
}

export interface MetadataIdAssignmentWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataIdAssignmentNestedMetadataCreateInput;
  archive: MetadataIdAssignmentNestedArchiveCreateInput;
}

export interface MetadataIdAssignmentWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataIdAssignmentNestedMetadataUpdateInput | null;
  archive?: MetadataIdAssignmentNestedArchiveUpdateInput | null;
}

export interface MetadataIdMediaContentNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIdMediaContentNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIdMediaContentNestedUpsertArchiveCreateInput | null;
}

export interface MetadataIdMediaContentNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIdMediaContentNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIdMediaContentNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataIdMediaContentNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIdMediaContentNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIdMediaContentNestedUpsertMetadataCreateInput | null;
}

export interface MetadataIdMediaContentNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIdMediaContentNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIdMediaContentNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataIdMediaContentNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIdMediaContentNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIdMediaContentNestedUpsertPhotoCreateInput | null;
}

export interface MetadataIdMediaContentNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIdMediaContentNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIdMediaContentNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataIdMediaContentNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIdMediaContentNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIdMediaContentNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIdMediaContentNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIdMediaContentNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIdMediaContentNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIdMediaContentNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIdMediaContentNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIdMediaContentNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIdMediaContentNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIdMediaContentNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataIdMediaContentNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataIdMediaContent" nodes by specifying some conditions
 */
export interface MetadataIdMediaContentWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIdMediaContentWhereInput[] | null;
  OR?: MetadataIdMediaContentWhereInput[] | null;
  NOT?: MetadataIdMediaContentWhereInput | null;
}

/**
 * Identifies exactly one "MetadataIdMediaContent" node.
 */
export interface MetadataIdMediaContentWhereUniqueInput {
  id: number;
}

export interface MetadataIdMediaContentWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataIdMediaContentNestedPhotoCreateInput;
  metadata: MetadataIdMediaContentNestedMetadataCreateInput;
}

export interface MetadataIdMediaContentWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataIdMediaContentNestedPhotoUpdateInput | null;
  metadata?: MetadataIdMediaContentNestedMetadataUpdateInput | null;
}

export interface MetadataIdMediaContentWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataIdMediaContentNestedPhotoCreateInput;
  archive: MetadataIdMediaContentNestedArchiveCreateInput;
}

export interface MetadataIdMediaContentWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataIdMediaContentNestedPhotoUpdateInput | null;
  archive?: MetadataIdMediaContentNestedArchiveUpdateInput | null;
}

export interface MetadataIdMediaContentWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataIdMediaContentNestedMetadataCreateInput;
  archive: MetadataIdMediaContentNestedArchiveCreateInput;
}

export interface MetadataIdMediaContentWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataIdMediaContentNestedMetadataUpdateInput | null;
  archive?: MetadataIdMediaContentNestedArchiveUpdateInput | null;
}

export interface MetadataIdPhotoPrismaNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIdPhotoPrismaNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIdPhotoPrismaNestedUpsertArchiveCreateInput | null;
}

export interface MetadataIdPhotoPrismaNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIdPhotoPrismaNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIdPhotoPrismaNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataIdPhotoPrismaNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIdPhotoPrismaNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIdPhotoPrismaNestedUpsertMetadataCreateInput | null;
}

export interface MetadataIdPhotoPrismaNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIdPhotoPrismaNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIdPhotoPrismaNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataIdPhotoPrismaNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIdPhotoPrismaNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIdPhotoPrismaNestedUpsertPhotoCreateInput | null;
}

export interface MetadataIdPhotoPrismaNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIdPhotoPrismaNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIdPhotoPrismaNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataIdPhotoPrismaNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIdPhotoPrismaNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIdPhotoPrismaNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIdPhotoPrismaNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIdPhotoPrismaNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIdPhotoPrismaNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIdPhotoPrismaNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIdPhotoPrismaNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIdPhotoPrismaNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIdPhotoPrismaNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIdPhotoPrismaNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataIdPhotoPrismaNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataIdPhotoPrisma" nodes by specifying some conditions
 */
export interface MetadataIdPhotoPrismaWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIdPhotoPrismaWhereInput[] | null;
  OR?: MetadataIdPhotoPrismaWhereInput[] | null;
  NOT?: MetadataIdPhotoPrismaWhereInput | null;
}

/**
 * Identifies exactly one "MetadataIdPhotoPrisma" node.
 */
export interface MetadataIdPhotoPrismaWhereUniqueInput {
  id: number;
}

export interface MetadataIdPhotoPrismaWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataIdPhotoPrismaNestedPhotoCreateInput;
  metadata: MetadataIdPhotoPrismaNestedMetadataCreateInput;
}

export interface MetadataIdPhotoPrismaWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataIdPhotoPrismaNestedPhotoUpdateInput | null;
  metadata?: MetadataIdPhotoPrismaNestedMetadataUpdateInput | null;
}

export interface MetadataIdPhotoPrismaWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataIdPhotoPrismaNestedPhotoCreateInput;
  archive: MetadataIdPhotoPrismaNestedArchiveCreateInput;
}

export interface MetadataIdPhotoPrismaWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataIdPhotoPrismaNestedPhotoUpdateInput | null;
  archive?: MetadataIdPhotoPrismaNestedArchiveUpdateInput | null;
}

export interface MetadataIdPhotoPrismaWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataIdPhotoPrismaNestedMetadataCreateInput;
  archive: MetadataIdPhotoPrismaNestedArchiveCreateInput;
}

export interface MetadataIdPhotoPrismaWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataIdPhotoPrismaNestedMetadataUpdateInput | null;
  archive?: MetadataIdPhotoPrismaNestedArchiveUpdateInput | null;
}

export interface MetadataIdSubjectNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIdSubjectNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIdSubjectNestedUpsertArchiveCreateInput | null;
}

export interface MetadataIdSubjectNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIdSubjectNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIdSubjectNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataIdSubjectNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIdSubjectNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIdSubjectNestedUpsertMetadataCreateInput | null;
}

export interface MetadataIdSubjectNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIdSubjectNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIdSubjectNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataIdSubjectNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIdSubjectNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIdSubjectNestedUpsertPhotoCreateInput | null;
}

export interface MetadataIdSubjectNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIdSubjectNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIdSubjectNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataIdSubjectNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIdSubjectNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIdSubjectNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIdSubjectNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIdSubjectNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIdSubjectNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIdSubjectNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIdSubjectNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIdSubjectNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIdSubjectNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIdSubjectNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataIdSubjectNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataIdSubject" nodes by specifying some conditions
 */
export interface MetadataIdSubjectWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIdSubjectWhereInput[] | null;
  OR?: MetadataIdSubjectWhereInput[] | null;
  NOT?: MetadataIdSubjectWhereInput | null;
}

/**
 * Identifies exactly one "MetadataIdSubject" node.
 */
export interface MetadataIdSubjectWhereUniqueInput {
  id: number;
}

export interface MetadataIdSubjectWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataIdSubjectNestedPhotoCreateInput;
  metadata: MetadataIdSubjectNestedMetadataCreateInput;
}

export interface MetadataIdSubjectWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataIdSubjectNestedPhotoUpdateInput | null;
  metadata?: MetadataIdSubjectNestedMetadataUpdateInput | null;
}

export interface MetadataIdSubjectWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataIdSubjectNestedPhotoCreateInput;
  archive: MetadataIdSubjectNestedArchiveCreateInput;
}

export interface MetadataIdSubjectWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataIdSubjectNestedPhotoUpdateInput | null;
  archive?: MetadataIdSubjectNestedArchiveUpdateInput | null;
}

export interface MetadataIdSubjectWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataIdSubjectNestedMetadataCreateInput;
  archive: MetadataIdSubjectNestedArchiveCreateInput;
}

export interface MetadataIdSubjectWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataIdSubjectNestedMetadataUpdateInput | null;
  archive?: MetadataIdSubjectNestedArchiveUpdateInput | null;
}

export interface MetadataImageNotesNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataImageNotesNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataImageNotesNestedUpsertArchiveCreateInput | null;
}

export interface MetadataImageNotesNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataImageNotesNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataImageNotesNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataImageNotesNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataImageNotesNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataImageNotesNestedUpsertMetadataCreateInput | null;
}

export interface MetadataImageNotesNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataImageNotesNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataImageNotesNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataImageNotesNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataImageNotesNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataImageNotesNestedUpsertPhotoCreateInput | null;
}

export interface MetadataImageNotesNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataImageNotesNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataImageNotesNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataImageNotesNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataImageNotesNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataImageNotesNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataImageNotesNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataImageNotesNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataImageNotesNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataImageNotesNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataImageNotesNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataImageNotesNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataImageNotesNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataImageNotesNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataImageNotesNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataImageNotes" nodes by specifying some conditions
 */
export interface MetadataImageNotesWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataImageNotesWhereInput[] | null;
  OR?: MetadataImageNotesWhereInput[] | null;
  NOT?: MetadataImageNotesWhereInput | null;
}

/**
 * Identifies exactly one "MetadataImageNotes" node.
 */
export interface MetadataImageNotesWhereUniqueInput {
  id: number;
}

export interface MetadataImageNotesWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataImageNotesNestedPhotoCreateInput;
  metadata: MetadataImageNotesNestedMetadataCreateInput;
}

export interface MetadataImageNotesWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataImageNotesNestedPhotoUpdateInput | null;
  metadata?: MetadataImageNotesNestedMetadataUpdateInput | null;
}

export interface MetadataImageNotesWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataImageNotesNestedPhotoCreateInput;
  archive: MetadataImageNotesNestedArchiveCreateInput;
}

export interface MetadataImageNotesWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataImageNotesNestedPhotoUpdateInput | null;
  archive?: MetadataImageNotesNestedArchiveUpdateInput | null;
}

export interface MetadataImageNotesWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataImageNotesNestedMetadataCreateInput;
  archive: MetadataImageNotesNestedArchiveCreateInput;
}

export interface MetadataImageNotesWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataImageNotesNestedMetadataUpdateInput | null;
  archive?: MetadataImageNotesNestedArchiveUpdateInput | null;
}

export interface MetadataImageUniqueIdNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataImageUniqueIdNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataImageUniqueIdNestedUpsertArchiveCreateInput | null;
}

export interface MetadataImageUniqueIdNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataImageUniqueIdNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataImageUniqueIdNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataImageUniqueIdNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataImageUniqueIdNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataImageUniqueIdNestedUpsertMetadataCreateInput | null;
}

export interface MetadataImageUniqueIdNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataImageUniqueIdNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataImageUniqueIdNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataImageUniqueIdNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataImageUniqueIdNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataImageUniqueIdNestedUpsertPhotoCreateInput | null;
}

export interface MetadataImageUniqueIdNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataImageUniqueIdNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataImageUniqueIdNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataImageUniqueIdNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataImageUniqueIdNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataImageUniqueIdNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataImageUniqueIdNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataImageUniqueIdNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataImageUniqueIdNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataImageUniqueIdNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataImageUniqueIdNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataImageUniqueIdNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataImageUniqueIdNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataImageUniqueIdNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataImageUniqueIdNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataImageUniqueId" nodes by specifying some conditions
 */
export interface MetadataImageUniqueIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataImageUniqueIdWhereInput[] | null;
  OR?: MetadataImageUniqueIdWhereInput[] | null;
  NOT?: MetadataImageUniqueIdWhereInput | null;
}

/**
 * Identifies exactly one "MetadataImageUniqueId" node.
 */
export interface MetadataImageUniqueIdWhereUniqueInput {
  id: number;
}

export interface MetadataImageUniqueIdWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataImageUniqueIdNestedPhotoCreateInput;
  metadata: MetadataImageUniqueIdNestedMetadataCreateInput;
}

export interface MetadataImageUniqueIdWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataImageUniqueIdNestedPhotoUpdateInput | null;
  metadata?: MetadataImageUniqueIdNestedMetadataUpdateInput | null;
}

export interface MetadataImageUniqueIdWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataImageUniqueIdNestedPhotoCreateInput;
  archive: MetadataImageUniqueIdNestedArchiveCreateInput;
}

export interface MetadataImageUniqueIdWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataImageUniqueIdNestedPhotoUpdateInput | null;
  archive?: MetadataImageUniqueIdNestedArchiveUpdateInput | null;
}

export interface MetadataImageUniqueIdWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataImageUniqueIdNestedMetadataCreateInput;
  archive: MetadataImageUniqueIdNestedArchiveCreateInput;
}

export interface MetadataImageUniqueIdWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataImageUniqueIdNestedMetadataUpdateInput | null;
  archive?: MetadataImageUniqueIdNestedArchiveUpdateInput | null;
}

export interface MetadataInstructionsNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataInstructionsNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataInstructionsNestedUpsertArchiveCreateInput | null;
}

export interface MetadataInstructionsNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataInstructionsNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataInstructionsNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataInstructionsNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataInstructionsNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataInstructionsNestedUpsertMetadataCreateInput | null;
}

export interface MetadataInstructionsNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataInstructionsNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataInstructionsNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataInstructionsNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataInstructionsNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataInstructionsNestedUpsertPhotoCreateInput | null;
}

export interface MetadataInstructionsNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataInstructionsNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataInstructionsNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataInstructionsNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataInstructionsNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataInstructionsNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataInstructionsNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataInstructionsNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataInstructionsNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataInstructionsNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataInstructionsNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataInstructionsNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataInstructionsNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataInstructionsNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataInstructionsNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataInstructions" nodes by specifying some conditions
 */
export interface MetadataInstructionsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataInstructionsWhereInput[] | null;
  OR?: MetadataInstructionsWhereInput[] | null;
  NOT?: MetadataInstructionsWhereInput | null;
}

/**
 * Identifies exactly one "MetadataInstructions" node.
 */
export interface MetadataInstructionsWhereUniqueInput {
  id: number;
}

export interface MetadataInstructionsWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataInstructionsNestedPhotoCreateInput;
  metadata: MetadataInstructionsNestedMetadataCreateInput;
}

export interface MetadataInstructionsWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataInstructionsNestedPhotoUpdateInput | null;
  metadata?: MetadataInstructionsNestedMetadataUpdateInput | null;
}

export interface MetadataInstructionsWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataInstructionsNestedPhotoCreateInput;
  archive: MetadataInstructionsNestedArchiveCreateInput;
}

export interface MetadataInstructionsWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataInstructionsNestedPhotoUpdateInput | null;
  archive?: MetadataInstructionsNestedArchiveUpdateInput | null;
}

export interface MetadataInstructionsWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataInstructionsNestedMetadataCreateInput;
  archive: MetadataInstructionsNestedArchiveCreateInput;
}

export interface MetadataInstructionsWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataInstructionsNestedMetadataUpdateInput | null;
  archive?: MetadataInstructionsNestedArchiveUpdateInput | null;
}

export interface MetadataIntellectualGenreNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIntellectualGenreNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIntellectualGenreNestedUpsertArchiveCreateInput | null;
}

export interface MetadataIntellectualGenreNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIntellectualGenreNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIntellectualGenreNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataIntellectualGenreNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIntellectualGenreNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIntellectualGenreNestedUpsertMetadataCreateInput | null;
}

export interface MetadataIntellectualGenreNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIntellectualGenreNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIntellectualGenreNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataIntellectualGenreNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIntellectualGenreNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIntellectualGenreNestedUpsertPhotoCreateInput | null;
}

export interface MetadataIntellectualGenreNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIntellectualGenreNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIntellectualGenreNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataIntellectualGenreNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIntellectualGenreNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIntellectualGenreNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIntellectualGenreNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIntellectualGenreNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIntellectualGenreNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIntellectualGenreNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIntellectualGenreNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIntellectualGenreNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIntellectualGenreNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIntellectualGenreNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataIntellectualGenreNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataIntellectualGenre" nodes by specifying some conditions
 */
export interface MetadataIntellectualGenreWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIntellectualGenreWhereInput[] | null;
  OR?: MetadataIntellectualGenreWhereInput[] | null;
  NOT?: MetadataIntellectualGenreWhereInput | null;
}

/**
 * Identifies exactly one "MetadataIntellectualGenre" node.
 */
export interface MetadataIntellectualGenreWhereUniqueInput {
  id: number;
}

export interface MetadataIntellectualGenreWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataIntellectualGenreNestedPhotoCreateInput;
  metadata: MetadataIntellectualGenreNestedMetadataCreateInput;
}

export interface MetadataIntellectualGenreWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataIntellectualGenreNestedPhotoUpdateInput | null;
  metadata?: MetadataIntellectualGenreNestedMetadataUpdateInput | null;
}

export interface MetadataIntellectualGenreWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataIntellectualGenreNestedPhotoCreateInput;
  archive: MetadataIntellectualGenreNestedArchiveCreateInput;
}

export interface MetadataIntellectualGenreWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataIntellectualGenreNestedPhotoUpdateInput | null;
  archive?: MetadataIntellectualGenreNestedArchiveUpdateInput | null;
}

export interface MetadataIntellectualGenreWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataIntellectualGenreNestedMetadataCreateInput;
  archive: MetadataIntellectualGenreNestedArchiveCreateInput;
}

export interface MetadataIntellectualGenreWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataIntellectualGenreNestedMetadataUpdateInput | null;
  archive?: MetadataIntellectualGenreNestedArchiveUpdateInput | null;
}

export interface MetadataIsoSpeedRatingsNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIsoSpeedRatingsNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIsoSpeedRatingsNestedUpsertArchiveCreateInput | null;
}

export interface MetadataIsoSpeedRatingsNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIsoSpeedRatingsNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIsoSpeedRatingsNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataIsoSpeedRatingsNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIsoSpeedRatingsNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIsoSpeedRatingsNestedUpsertMetadataCreateInput | null;
}

export interface MetadataIsoSpeedRatingsNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIsoSpeedRatingsNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIsoSpeedRatingsNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataIsoSpeedRatingsNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIsoSpeedRatingsNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIsoSpeedRatingsNestedUpsertPhotoCreateInput | null;
}

export interface MetadataIsoSpeedRatingsNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIsoSpeedRatingsNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIsoSpeedRatingsNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataIsoSpeedRatingsNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIsoSpeedRatingsNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIsoSpeedRatingsNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIsoSpeedRatingsNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIsoSpeedRatingsNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIsoSpeedRatingsNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIsoSpeedRatingsNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIsoSpeedRatingsNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIsoSpeedRatingsNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIsoSpeedRatingsNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIsoSpeedRatingsNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataIsoSpeedRatingsNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataIsoSpeedRatings" nodes by specifying some conditions
 */
export interface MetadataIsoSpeedRatingsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIsoSpeedRatingsWhereInput[] | null;
  OR?: MetadataIsoSpeedRatingsWhereInput[] | null;
  NOT?: MetadataIsoSpeedRatingsWhereInput | null;
}

/**
 * Identifies exactly one "MetadataIsoSpeedRatings" node.
 */
export interface MetadataIsoSpeedRatingsWhereUniqueInput {
  id: number;
}

export interface MetadataIsoSpeedRatingsWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataIsoSpeedRatingsNestedPhotoCreateInput;
  metadata: MetadataIsoSpeedRatingsNestedMetadataCreateInput;
}

export interface MetadataIsoSpeedRatingsWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataIsoSpeedRatingsNestedPhotoUpdateInput | null;
  metadata?: MetadataIsoSpeedRatingsNestedMetadataUpdateInput | null;
}

export interface MetadataIsoSpeedRatingsWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataIsoSpeedRatingsNestedPhotoCreateInput;
  archive: MetadataIsoSpeedRatingsNestedArchiveCreateInput;
}

export interface MetadataIsoSpeedRatingsWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataIsoSpeedRatingsNestedPhotoUpdateInput | null;
  archive?: MetadataIsoSpeedRatingsNestedArchiveUpdateInput | null;
}

export interface MetadataIsoSpeedRatingsWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataIsoSpeedRatingsNestedMetadataCreateInput;
  archive: MetadataIsoSpeedRatingsNestedArchiveCreateInput;
}

export interface MetadataIsoSpeedRatingsWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataIsoSpeedRatingsNestedMetadataUpdateInput | null;
  archive?: MetadataIsoSpeedRatingsNestedArchiveUpdateInput | null;
}

export interface MetadataIssueNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIssueNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIssueNestedUpsertArchiveCreateInput | null;
}

export interface MetadataIssueNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataIssueNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataIssueNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataIssueNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIssueNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIssueNestedUpsertMetadataCreateInput | null;
}

export interface MetadataIssueNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataIssueNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataIssueNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataIssueNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIssueNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIssueNestedUpsertPhotoCreateInput | null;
}

export interface MetadataIssueNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataIssueNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataIssueNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataIssueNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIssueNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataIssueNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIssueNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataIssueNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIssueNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataIssueNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIssueNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataIssueNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIssueNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataIssueNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataIssueNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataIssue" nodes by specifying some conditions
 */
export interface MetadataIssueWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataIssueWhereInput[] | null;
  OR?: MetadataIssueWhereInput[] | null;
  NOT?: MetadataIssueWhereInput | null;
}

/**
 * Identifies exactly one "MetadataIssue" node.
 */
export interface MetadataIssueWhereUniqueInput {
  id: number;
}

export interface MetadataIssueWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataIssueNestedPhotoCreateInput;
  metadata: MetadataIssueNestedMetadataCreateInput;
}

export interface MetadataIssueWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataIssueNestedPhotoUpdateInput | null;
  metadata?: MetadataIssueNestedMetadataUpdateInput | null;
}

export interface MetadataIssueWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataIssueNestedPhotoCreateInput;
  archive: MetadataIssueNestedArchiveCreateInput;
}

export interface MetadataIssueWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataIssueNestedPhotoUpdateInput | null;
  archive?: MetadataIssueNestedArchiveUpdateInput | null;
}

export interface MetadataIssueWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataIssueNestedMetadataCreateInput;
  archive: MetadataIssueNestedArchiveCreateInput;
}

export interface MetadataIssueWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataIssueNestedMetadataUpdateInput | null;
  archive?: MetadataIssueNestedArchiveUpdateInput | null;
}

export interface MetadataJobIdNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataJobIdNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataJobIdNestedUpsertArchiveCreateInput | null;
}

export interface MetadataJobIdNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataJobIdNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataJobIdNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataJobIdNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataJobIdNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataJobIdNestedUpsertMetadataCreateInput | null;
}

export interface MetadataJobIdNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataJobIdNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataJobIdNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataJobIdNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataJobIdNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataJobIdNestedUpsertPhotoCreateInput | null;
}

export interface MetadataJobIdNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataJobIdNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataJobIdNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataJobIdNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataJobIdNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataJobIdNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataJobIdNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataJobIdNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataJobIdNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataJobIdNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataJobIdNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataJobIdNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataJobIdNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataJobIdNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataJobIdNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataJobId" nodes by specifying some conditions
 */
export interface MetadataJobIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataJobIdWhereInput[] | null;
  OR?: MetadataJobIdWhereInput[] | null;
  NOT?: MetadataJobIdWhereInput | null;
}

/**
 * Identifies exactly one "MetadataJobId" node.
 */
export interface MetadataJobIdWhereUniqueInput {
  id: number;
}

export interface MetadataJobIdWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataJobIdNestedPhotoCreateInput;
  metadata: MetadataJobIdNestedMetadataCreateInput;
}

export interface MetadataJobIdWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataJobIdNestedPhotoUpdateInput | null;
  metadata?: MetadataJobIdNestedMetadataUpdateInput | null;
}

export interface MetadataJobIdWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataJobIdNestedPhotoCreateInput;
  archive: MetadataJobIdNestedArchiveCreateInput;
}

export interface MetadataJobIdWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataJobIdNestedPhotoUpdateInput | null;
  archive?: MetadataJobIdNestedArchiveUpdateInput | null;
}

export interface MetadataJobIdWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataJobIdNestedMetadataCreateInput;
  archive: MetadataJobIdNestedArchiveCreateInput;
}

export interface MetadataJobIdWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataJobIdNestedMetadataUpdateInput | null;
  archive?: MetadataJobIdNestedArchiveUpdateInput | null;
}

export interface MetadataJobProcessingNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataJobProcessingNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataJobProcessingNestedUpsertArchiveCreateInput | null;
}

export interface MetadataJobProcessingNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataJobProcessingNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataJobProcessingNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataJobProcessingNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataJobProcessingNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataJobProcessingNestedUpsertMetadataCreateInput | null;
}

export interface MetadataJobProcessingNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataJobProcessingNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataJobProcessingNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataJobProcessingNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataJobProcessingNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataJobProcessingNestedUpsertPhotoCreateInput | null;
}

export interface MetadataJobProcessingNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataJobProcessingNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataJobProcessingNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataJobProcessingNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataJobProcessingNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataJobProcessingNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataJobProcessingNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataJobProcessingNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataJobProcessingNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataJobProcessingNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataJobProcessingNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataJobProcessingNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataJobProcessingNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataJobProcessingNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataJobProcessingNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataJobProcessing" nodes by specifying some conditions
 */
export interface MetadataJobProcessingWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataJobProcessingWhereInput[] | null;
  OR?: MetadataJobProcessingWhereInput[] | null;
  NOT?: MetadataJobProcessingWhereInput | null;
}

/**
 * Identifies exactly one "MetadataJobProcessing" node.
 */
export interface MetadataJobProcessingWhereUniqueInput {
  id: number;
}

export interface MetadataJobProcessingWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataJobProcessingNestedPhotoCreateInput;
  metadata: MetadataJobProcessingNestedMetadataCreateInput;
}

export interface MetadataJobProcessingWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataJobProcessingNestedPhotoUpdateInput | null;
  metadata?: MetadataJobProcessingNestedMetadataUpdateInput | null;
}

export interface MetadataJobProcessingWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataJobProcessingNestedPhotoCreateInput;
  archive: MetadataJobProcessingNestedArchiveCreateInput;
}

export interface MetadataJobProcessingWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataJobProcessingNestedPhotoUpdateInput | null;
  archive?: MetadataJobProcessingNestedArchiveUpdateInput | null;
}

export interface MetadataJobProcessingWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataJobProcessingNestedMetadataCreateInput;
  archive: MetadataJobProcessingNestedArchiveCreateInput;
}

export interface MetadataJobProcessingWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataJobProcessingNestedMetadataUpdateInput | null;
  archive?: MetadataJobProcessingNestedArchiveUpdateInput | null;
}

export interface MetadataLayoutPictureEditingNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataLayoutPictureEditingNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataLayoutPictureEditingNestedUpsertArchiveCreateInput | null;
}

export interface MetadataLayoutPictureEditingNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataLayoutPictureEditingNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataLayoutPictureEditingNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataLayoutPictureEditingNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataLayoutPictureEditingNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataLayoutPictureEditingNestedUpsertMetadataCreateInput | null;
}

export interface MetadataLayoutPictureEditingNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataLayoutPictureEditingNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataLayoutPictureEditingNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataLayoutPictureEditingNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataLayoutPictureEditingNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataLayoutPictureEditingNestedUpsertPhotoCreateInput | null;
}

export interface MetadataLayoutPictureEditingNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataLayoutPictureEditingNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataLayoutPictureEditingNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataLayoutPictureEditingNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataLayoutPictureEditingNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataLayoutPictureEditingNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataLayoutPictureEditingNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataLayoutPictureEditingNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataLayoutPictureEditingNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataLayoutPictureEditingNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataLayoutPictureEditingNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataLayoutPictureEditingNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataLayoutPictureEditingNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataLayoutPictureEditingNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataLayoutPictureEditingNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataLayoutPictureEditing" nodes by specifying some conditions
 */
export interface MetadataLayoutPictureEditingWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataLayoutPictureEditingWhereInput[] | null;
  OR?: MetadataLayoutPictureEditingWhereInput[] | null;
  NOT?: MetadataLayoutPictureEditingWhereInput | null;
}

/**
 * Identifies exactly one "MetadataLayoutPictureEditing" node.
 */
export interface MetadataLayoutPictureEditingWhereUniqueInput {
  id: number;
}

export interface MetadataLayoutPictureEditingWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataLayoutPictureEditingNestedPhotoCreateInput;
  metadata: MetadataLayoutPictureEditingNestedMetadataCreateInput;
}

export interface MetadataLayoutPictureEditingWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataLayoutPictureEditingNestedPhotoUpdateInput | null;
  metadata?: MetadataLayoutPictureEditingNestedMetadataUpdateInput | null;
}

export interface MetadataLayoutPictureEditingWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataLayoutPictureEditingNestedPhotoCreateInput;
  archive: MetadataLayoutPictureEditingNestedArchiveCreateInput;
}

export interface MetadataLayoutPictureEditingWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataLayoutPictureEditingNestedPhotoUpdateInput | null;
  archive?: MetadataLayoutPictureEditingNestedArchiveUpdateInput | null;
}

export interface MetadataLayoutPictureEditingWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataLayoutPictureEditingNestedMetadataCreateInput;
  archive: MetadataLayoutPictureEditingNestedArchiveCreateInput;
}

export interface MetadataLayoutPictureEditingWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataLayoutPictureEditingNestedMetadataUpdateInput | null;
  archive?: MetadataLayoutPictureEditingNestedArchiveUpdateInput | null;
}

export interface MetadataLayoutStorageNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataLayoutStorageNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataLayoutStorageNestedUpsertArchiveCreateInput | null;
}

export interface MetadataLayoutStorageNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataLayoutStorageNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataLayoutStorageNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataLayoutStorageNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataLayoutStorageNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataLayoutStorageNestedUpsertMetadataCreateInput | null;
}

export interface MetadataLayoutStorageNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataLayoutStorageNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataLayoutStorageNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataLayoutStorageNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataLayoutStorageNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataLayoutStorageNestedUpsertPhotoCreateInput | null;
}

export interface MetadataLayoutStorageNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataLayoutStorageNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataLayoutStorageNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataLayoutStorageNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataLayoutStorageNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataLayoutStorageNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataLayoutStorageNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataLayoutStorageNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataLayoutStorageNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataLayoutStorageNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataLayoutStorageNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataLayoutStorageNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataLayoutStorageNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataLayoutStorageNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataLayoutStorageNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataLayoutStorage" nodes by specifying some conditions
 */
export interface MetadataLayoutStorageWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataLayoutStorageWhereInput[] | null;
  OR?: MetadataLayoutStorageWhereInput[] | null;
  NOT?: MetadataLayoutStorageWhereInput | null;
}

/**
 * Identifies exactly one "MetadataLayoutStorage" node.
 */
export interface MetadataLayoutStorageWhereUniqueInput {
  id: number;
}

export interface MetadataLayoutStorageWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataLayoutStorageNestedPhotoCreateInput;
  metadata: MetadataLayoutStorageNestedMetadataCreateInput;
}

export interface MetadataLayoutStorageWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataLayoutStorageNestedPhotoUpdateInput | null;
  metadata?: MetadataLayoutStorageNestedMetadataUpdateInput | null;
}

export interface MetadataLayoutStorageWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataLayoutStorageNestedPhotoCreateInput;
  archive: MetadataLayoutStorageNestedArchiveCreateInput;
}

export interface MetadataLayoutStorageWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataLayoutStorageNestedPhotoUpdateInput | null;
  archive?: MetadataLayoutStorageNestedArchiveUpdateInput | null;
}

export interface MetadataLayoutStorageWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataLayoutStorageNestedMetadataCreateInput;
  archive: MetadataLayoutStorageNestedArchiveCreateInput;
}

export interface MetadataLayoutStorageWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataLayoutStorageNestedMetadataUpdateInput | null;
  archive?: MetadataLayoutStorageNestedArchiveUpdateInput | null;
}

export interface MetadataLicenseNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataLicenseNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataLicenseNestedUpsertArchiveCreateInput | null;
}

export interface MetadataLicenseNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataLicenseNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataLicenseNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataLicenseNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataLicenseNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataLicenseNestedUpsertMetadataCreateInput | null;
}

export interface MetadataLicenseNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataLicenseNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataLicenseNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataLicenseNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataLicenseNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataLicenseNestedUpsertPhotoCreateInput | null;
}

export interface MetadataLicenseNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataLicenseNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataLicenseNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataLicenseNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataLicenseNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataLicenseNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataLicenseNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataLicenseNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataLicenseNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataLicenseNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataLicenseNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataLicenseNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataLicenseNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataLicenseNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataLicenseNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataLicense" nodes by specifying some conditions
 */
export interface MetadataLicenseWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataLicenseWhereInput[] | null;
  OR?: MetadataLicenseWhereInput[] | null;
  NOT?: MetadataLicenseWhereInput | null;
}

/**
 * Identifies exactly one "MetadataLicense" node.
 */
export interface MetadataLicenseWhereUniqueInput {
  id: number;
}

export interface MetadataLicenseWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataLicenseNestedPhotoCreateInput;
  metadata: MetadataLicenseNestedMetadataCreateInput;
}

export interface MetadataLicenseWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataLicenseNestedPhotoUpdateInput | null;
  metadata?: MetadataLicenseNestedMetadataUpdateInput | null;
}

export interface MetadataLicenseWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataLicenseNestedPhotoCreateInput;
  archive: MetadataLicenseNestedArchiveCreateInput;
}

export interface MetadataLicenseWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataLicenseNestedPhotoUpdateInput | null;
  archive?: MetadataLicenseNestedArchiveUpdateInput | null;
}

export interface MetadataLicenseWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataLicenseNestedMetadataCreateInput;
  archive: MetadataLicenseNestedArchiveCreateInput;
}

export interface MetadataLicenseWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataLicenseNestedMetadataUpdateInput | null;
  archive?: MetadataLicenseNestedArchiveUpdateInput | null;
}

export interface MetadataLinksNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataLinksNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataLinksNestedUpsertArchiveCreateInput | null;
}

export interface MetadataLinksNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataLinksNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataLinksNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataLinksNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataLinksNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataLinksNestedUpsertMetadataCreateInput | null;
}

export interface MetadataLinksNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataLinksNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataLinksNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataLinksNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataLinksNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataLinksNestedUpsertPhotoCreateInput | null;
}

export interface MetadataLinksNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataLinksNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataLinksNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataLinksNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataLinksNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataLinksNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataLinksNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataLinksNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataLinksNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataLinksNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataLinksNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataLinksNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataLinksNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataLinksNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataLinksNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataLinks" nodes by specifying some conditions
 */
export interface MetadataLinksWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataLinksWhereInput[] | null;
  OR?: MetadataLinksWhereInput[] | null;
  NOT?: MetadataLinksWhereInput | null;
}

/**
 * Identifies exactly one "MetadataLinks" node.
 */
export interface MetadataLinksWhereUniqueInput {
  id: number;
}

export interface MetadataLinksWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataLinksNestedPhotoCreateInput;
  metadata: MetadataLinksNestedMetadataCreateInput;
}

export interface MetadataLinksWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataLinksNestedPhotoUpdateInput | null;
  metadata?: MetadataLinksNestedMetadataUpdateInput | null;
}

export interface MetadataLinksWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataLinksNestedPhotoCreateInput;
  archive: MetadataLinksNestedArchiveCreateInput;
}

export interface MetadataLinksWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataLinksNestedPhotoUpdateInput | null;
  archive?: MetadataLinksNestedArchiveUpdateInput | null;
}

export interface MetadataLinksWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataLinksNestedMetadataCreateInput;
  archive: MetadataLinksNestedArchiveCreateInput;
}

export interface MetadataLinksWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataLinksNestedMetadataUpdateInput | null;
  archive?: MetadataLinksNestedArchiveUpdateInput | null;
}

export interface MetadataLocalCaptionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataLocalCaptionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataLocalCaptionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataLocalCaptionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataLocalCaptionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataLocalCaptionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataLocalCaptionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataLocalCaptionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataLocalCaptionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataLocalCaptionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataLocalCaptionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataLocalCaptionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataLocalCaptionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataLocalCaptionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataLocalCaptionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataLocalCaptionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataLocalCaptionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataLocalCaptionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataLocalCaptionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataLocalCaptionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataLocalCaptionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataLocalCaptionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataLocalCaptionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataLocalCaptionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataLocalCaptionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataLocalCaptionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataLocalCaptionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataLocalCaptionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataLocalCaptionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataLocalCaptionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataLocalCaption" nodes by specifying some conditions
 */
export interface MetadataLocalCaptionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataLocalCaptionWhereInput[] | null;
  OR?: MetadataLocalCaptionWhereInput[] | null;
  NOT?: MetadataLocalCaptionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataLocalCaption" node.
 */
export interface MetadataLocalCaptionWhereUniqueInput {
  id: number;
}

export interface MetadataLocalCaptionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataLocalCaptionNestedPhotoCreateInput;
  metadata: MetadataLocalCaptionNestedMetadataCreateInput;
}

export interface MetadataLocalCaptionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataLocalCaptionNestedPhotoUpdateInput | null;
  metadata?: MetadataLocalCaptionNestedMetadataUpdateInput | null;
}

export interface MetadataLocalCaptionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataLocalCaptionNestedPhotoCreateInput;
  archive: MetadataLocalCaptionNestedArchiveCreateInput;
}

export interface MetadataLocalCaptionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataLocalCaptionNestedPhotoUpdateInput | null;
  archive?: MetadataLocalCaptionNestedArchiveUpdateInput | null;
}

export interface MetadataLocalCaptionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataLocalCaptionNestedMetadataCreateInput;
  archive: MetadataLocalCaptionNestedArchiveCreateInput;
}

export interface MetadataLocalCaptionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataLocalCaptionNestedMetadataUpdateInput | null;
  archive?: MetadataLocalCaptionNestedArchiveUpdateInput | null;
}

export interface MetadataLocationNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataLocationNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataLocationNestedUpsertArchiveCreateInput | null;
}

export interface MetadataLocationNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataLocationNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataLocationNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataLocationNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataLocationNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataLocationNestedUpsertMetadataCreateInput | null;
}

export interface MetadataLocationNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataLocationNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataLocationNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataLocationNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataLocationNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataLocationNestedUpsertPhotoCreateInput | null;
}

export interface MetadataLocationNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataLocationNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataLocationNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataLocationNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataLocationNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataLocationNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataLocationNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataLocationNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataLocationNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataLocationNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataLocationNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataLocationNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataLocationNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataLocationNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataLocationNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataLocation" nodes by specifying some conditions
 */
export interface MetadataLocationWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataLocationWhereInput[] | null;
  OR?: MetadataLocationWhereInput[] | null;
  NOT?: MetadataLocationWhereInput | null;
}

/**
 * Identifies exactly one "MetadataLocation" node.
 */
export interface MetadataLocationWhereUniqueInput {
  id: number;
}

export interface MetadataLocationWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataLocationNestedPhotoCreateInput;
  metadata: MetadataLocationNestedMetadataCreateInput;
}

export interface MetadataLocationWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataLocationNestedPhotoUpdateInput | null;
  metadata?: MetadataLocationNestedMetadataUpdateInput | null;
}

export interface MetadataLocationWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataLocationNestedPhotoCreateInput;
  archive: MetadataLocationNestedArchiveCreateInput;
}

export interface MetadataLocationWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataLocationNestedPhotoUpdateInput | null;
  archive?: MetadataLocationNestedArchiveUpdateInput | null;
}

export interface MetadataLocationWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataLocationNestedMetadataCreateInput;
  archive: MetadataLocationNestedArchiveCreateInput;
}

export interface MetadataLocationWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataLocationNestedMetadataUpdateInput | null;
  archive?: MetadataLocationNestedArchiveUpdateInput | null;
}

export interface MetadataMakeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataMakeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataMakeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataMakeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataMakeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataMakeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataMakeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataMakeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataMakeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataMakeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataMakeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataMakeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataMakeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataMakeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataMakeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataMakeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataMakeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataMakeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataMakeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataMakeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataMakeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataMakeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataMakeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataMakeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataMakeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataMakeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataMakeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataMakeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataMakeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataMakeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataMake" nodes by specifying some conditions
 */
export interface MetadataMakeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataMakeWhereInput[] | null;
  OR?: MetadataMakeWhereInput[] | null;
  NOT?: MetadataMakeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataMake" node.
 */
export interface MetadataMakeWhereUniqueInput {
  id: number;
}

export interface MetadataMakeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataMakeNestedPhotoCreateInput;
  metadata: MetadataMakeNestedMetadataCreateInput;
}

export interface MetadataMakeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataMakeNestedPhotoUpdateInput | null;
  metadata?: MetadataMakeNestedMetadataUpdateInput | null;
}

export interface MetadataMakeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataMakeNestedPhotoCreateInput;
  archive: MetadataMakeNestedArchiveCreateInput;
}

export interface MetadataMakeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataMakeNestedPhotoUpdateInput | null;
  archive?: MetadataMakeNestedArchiveUpdateInput | null;
}

export interface MetadataMakeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataMakeNestedMetadataCreateInput;
  archive: MetadataMakeNestedArchiveCreateInput;
}

export interface MetadataMakeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataMakeNestedMetadataUpdateInput | null;
  archive?: MetadataMakeNestedArchiveUpdateInput | null;
}

export interface MetadataManifestNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataManifestNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataManifestNestedUpsertArchiveCreateInput | null;
}

export interface MetadataManifestNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataManifestNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataManifestNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataManifestNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataManifestNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataManifestNestedUpsertMetadataCreateInput | null;
}

export interface MetadataManifestNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataManifestNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataManifestNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataManifestNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataManifestNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataManifestNestedUpsertPhotoCreateInput | null;
}

export interface MetadataManifestNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataManifestNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataManifestNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataManifestNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataManifestNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataManifestNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataManifestNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataManifestNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataManifestNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataManifestNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataManifestNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataManifestNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataManifestNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataManifestNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataManifestNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataManifest" nodes by specifying some conditions
 */
export interface MetadataManifestWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataManifestWhereInput[] | null;
  OR?: MetadataManifestWhereInput[] | null;
  NOT?: MetadataManifestWhereInput | null;
}

/**
 * Identifies exactly one "MetadataManifest" node.
 */
export interface MetadataManifestWhereUniqueInput {
  id: number;
}

export interface MetadataManifestWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataManifestNestedPhotoCreateInput;
  metadata: MetadataManifestNestedMetadataCreateInput;
}

export interface MetadataManifestWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataManifestNestedPhotoUpdateInput | null;
  metadata?: MetadataManifestNestedMetadataUpdateInput | null;
}

export interface MetadataManifestWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataManifestNestedPhotoCreateInput;
  archive: MetadataManifestNestedArchiveCreateInput;
}

export interface MetadataManifestWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataManifestNestedPhotoUpdateInput | null;
  archive?: MetadataManifestNestedArchiveUpdateInput | null;
}

export interface MetadataManifestWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataManifestNestedMetadataCreateInput;
  archive: MetadataManifestNestedArchiveCreateInput;
}

export interface MetadataManifestWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataManifestNestedMetadataUpdateInput | null;
  archive?: MetadataManifestNestedArchiveUpdateInput | null;
}

export interface MetadataMarkedNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataMarkedNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataMarkedNestedUpsertArchiveCreateInput | null;
}

export interface MetadataMarkedNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataMarkedNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataMarkedNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataMarkedNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataMarkedNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataMarkedNestedUpsertMetadataCreateInput | null;
}

export interface MetadataMarkedNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataMarkedNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataMarkedNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataMarkedNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataMarkedNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataMarkedNestedUpsertPhotoCreateInput | null;
}

export interface MetadataMarkedNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataMarkedNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataMarkedNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataMarkedNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataMarkedNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataMarkedNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataMarkedNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataMarkedNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataMarkedNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataMarkedNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataMarkedNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataMarkedNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataMarkedNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataMarkedNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataMarkedNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataMarked" nodes by specifying some conditions
 */
export interface MetadataMarkedWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataMarkedWhereInput[] | null;
  OR?: MetadataMarkedWhereInput[] | null;
  NOT?: MetadataMarkedWhereInput | null;
}

/**
 * Identifies exactly one "MetadataMarked" node.
 */
export interface MetadataMarkedWhereUniqueInput {
  id: number;
}

export interface MetadataMarkedWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataMarkedNestedPhotoCreateInput;
  metadata: MetadataMarkedNestedMetadataCreateInput;
}

export interface MetadataMarkedWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataMarkedNestedPhotoUpdateInput | null;
  metadata?: MetadataMarkedNestedMetadataUpdateInput | null;
}

export interface MetadataMarkedWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataMarkedNestedPhotoCreateInput;
  archive: MetadataMarkedNestedArchiveCreateInput;
}

export interface MetadataMarkedWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataMarkedNestedPhotoUpdateInput | null;
  archive?: MetadataMarkedNestedArchiveUpdateInput | null;
}

export interface MetadataMarkedWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataMarkedNestedMetadataCreateInput;
  archive: MetadataMarkedNestedArchiveCreateInput;
}

export interface MetadataMarkedWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataMarkedNestedMetadataUpdateInput | null;
  archive?: MetadataMarkedNestedArchiveUpdateInput | null;
}

export interface MetadataMasterDocumentIdNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataMasterDocumentIdNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataMasterDocumentIdNestedUpsertArchiveCreateInput | null;
}

export interface MetadataMasterDocumentIdNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataMasterDocumentIdNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataMasterDocumentIdNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataMasterDocumentIdNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataMasterDocumentIdNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataMasterDocumentIdNestedUpsertMetadataCreateInput | null;
}

export interface MetadataMasterDocumentIdNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataMasterDocumentIdNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataMasterDocumentIdNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataMasterDocumentIdNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataMasterDocumentIdNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataMasterDocumentIdNestedUpsertPhotoCreateInput | null;
}

export interface MetadataMasterDocumentIdNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataMasterDocumentIdNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataMasterDocumentIdNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataMasterDocumentIdNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataMasterDocumentIdNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataMasterDocumentIdNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataMasterDocumentIdNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataMasterDocumentIdNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataMasterDocumentIdNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataMasterDocumentIdNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataMasterDocumentIdNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataMasterDocumentIdNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataMasterDocumentIdNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataMasterDocumentIdNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataMasterDocumentIdNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataMasterDocumentId" nodes by specifying some conditions
 */
export interface MetadataMasterDocumentIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataMasterDocumentIdWhereInput[] | null;
  OR?: MetadataMasterDocumentIdWhereInput[] | null;
  NOT?: MetadataMasterDocumentIdWhereInput | null;
}

/**
 * Identifies exactly one "MetadataMasterDocumentId" node.
 */
export interface MetadataMasterDocumentIdWhereUniqueInput {
  id: number;
}

export interface MetadataMasterDocumentIdWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataMasterDocumentIdNestedPhotoCreateInput;
  metadata: MetadataMasterDocumentIdNestedMetadataCreateInput;
}

export interface MetadataMasterDocumentIdWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataMasterDocumentIdNestedPhotoUpdateInput | null;
  metadata?: MetadataMasterDocumentIdNestedMetadataUpdateInput | null;
}

export interface MetadataMasterDocumentIdWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataMasterDocumentIdNestedPhotoCreateInput;
  archive: MetadataMasterDocumentIdNestedArchiveCreateInput;
}

export interface MetadataMasterDocumentIdWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataMasterDocumentIdNestedPhotoUpdateInput | null;
  archive?: MetadataMasterDocumentIdNestedArchiveUpdateInput | null;
}

export interface MetadataMasterDocumentIdWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataMasterDocumentIdNestedMetadataCreateInput;
  archive: MetadataMasterDocumentIdNestedArchiveCreateInput;
}

export interface MetadataMasterDocumentIdWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataMasterDocumentIdNestedMetadataUpdateInput | null;
  archive?: MetadataMasterDocumentIdNestedArchiveUpdateInput | null;
}

export interface MetadataMaxAvailHeightNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataMaxAvailHeightNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataMaxAvailHeightNestedUpsertArchiveCreateInput | null;
}

export interface MetadataMaxAvailHeightNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataMaxAvailHeightNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataMaxAvailHeightNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataMaxAvailHeightNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataMaxAvailHeightNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataMaxAvailHeightNestedUpsertMetadataCreateInput | null;
}

export interface MetadataMaxAvailHeightNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataMaxAvailHeightNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataMaxAvailHeightNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataMaxAvailHeightNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataMaxAvailHeightNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataMaxAvailHeightNestedUpsertPhotoCreateInput | null;
}

export interface MetadataMaxAvailHeightNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataMaxAvailHeightNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataMaxAvailHeightNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataMaxAvailHeightNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataMaxAvailHeightNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataMaxAvailHeightNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataMaxAvailHeightNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataMaxAvailHeightNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataMaxAvailHeightNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataMaxAvailHeightNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataMaxAvailHeightNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataMaxAvailHeightNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataMaxAvailHeightNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataMaxAvailHeightNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataMaxAvailHeightNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataMaxAvailHeight" nodes by specifying some conditions
 */
export interface MetadataMaxAvailHeightWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataMaxAvailHeightWhereInput[] | null;
  OR?: MetadataMaxAvailHeightWhereInput[] | null;
  NOT?: MetadataMaxAvailHeightWhereInput | null;
}

/**
 * Identifies exactly one "MetadataMaxAvailHeight" node.
 */
export interface MetadataMaxAvailHeightWhereUniqueInput {
  id: number;
}

export interface MetadataMaxAvailHeightWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataMaxAvailHeightNestedPhotoCreateInput;
  metadata: MetadataMaxAvailHeightNestedMetadataCreateInput;
}

export interface MetadataMaxAvailHeightWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataMaxAvailHeightNestedPhotoUpdateInput | null;
  metadata?: MetadataMaxAvailHeightNestedMetadataUpdateInput | null;
}

export interface MetadataMaxAvailHeightWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataMaxAvailHeightNestedPhotoCreateInput;
  archive: MetadataMaxAvailHeightNestedArchiveCreateInput;
}

export interface MetadataMaxAvailHeightWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataMaxAvailHeightNestedPhotoUpdateInput | null;
  archive?: MetadataMaxAvailHeightNestedArchiveUpdateInput | null;
}

export interface MetadataMaxAvailHeightWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataMaxAvailHeightNestedMetadataCreateInput;
  archive: MetadataMaxAvailHeightNestedArchiveCreateInput;
}

export interface MetadataMaxAvailHeightWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataMaxAvailHeightNestedMetadataUpdateInput | null;
  archive?: MetadataMaxAvailHeightNestedArchiveUpdateInput | null;
}

export interface MetadataMaxAvailWidthNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataMaxAvailWidthNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataMaxAvailWidthNestedUpsertArchiveCreateInput | null;
}

export interface MetadataMaxAvailWidthNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataMaxAvailWidthNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataMaxAvailWidthNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataMaxAvailWidthNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataMaxAvailWidthNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataMaxAvailWidthNestedUpsertMetadataCreateInput | null;
}

export interface MetadataMaxAvailWidthNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataMaxAvailWidthNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataMaxAvailWidthNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataMaxAvailWidthNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataMaxAvailWidthNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataMaxAvailWidthNestedUpsertPhotoCreateInput | null;
}

export interface MetadataMaxAvailWidthNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataMaxAvailWidthNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataMaxAvailWidthNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataMaxAvailWidthNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataMaxAvailWidthNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataMaxAvailWidthNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataMaxAvailWidthNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataMaxAvailWidthNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataMaxAvailWidthNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataMaxAvailWidthNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataMaxAvailWidthNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataMaxAvailWidthNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataMaxAvailWidthNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataMaxAvailWidthNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataMaxAvailWidthNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataMaxAvailWidth" nodes by specifying some conditions
 */
export interface MetadataMaxAvailWidthWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataMaxAvailWidthWhereInput[] | null;
  OR?: MetadataMaxAvailWidthWhereInput[] | null;
  NOT?: MetadataMaxAvailWidthWhereInput | null;
}

/**
 * Identifies exactly one "MetadataMaxAvailWidth" node.
 */
export interface MetadataMaxAvailWidthWhereUniqueInput {
  id: number;
}

export interface MetadataMaxAvailWidthWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataMaxAvailWidthNestedPhotoCreateInput;
  metadata: MetadataMaxAvailWidthNestedMetadataCreateInput;
}

export interface MetadataMaxAvailWidthWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataMaxAvailWidthNestedPhotoUpdateInput | null;
  metadata?: MetadataMaxAvailWidthNestedMetadataUpdateInput | null;
}

export interface MetadataMaxAvailWidthWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataMaxAvailWidthNestedPhotoCreateInput;
  archive: MetadataMaxAvailWidthNestedArchiveCreateInput;
}

export interface MetadataMaxAvailWidthWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataMaxAvailWidthNestedPhotoUpdateInput | null;
  archive?: MetadataMaxAvailWidthNestedArchiveUpdateInput | null;
}

export interface MetadataMaxAvailWidthWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataMaxAvailWidthNestedMetadataCreateInput;
  archive: MetadataMaxAvailWidthNestedArchiveCreateInput;
}

export interface MetadataMaxAvailWidthWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataMaxAvailWidthNestedMetadataUpdateInput | null;
  archive?: MetadataMaxAvailWidthNestedArchiveUpdateInput | null;
}

export interface MetadataMinorModelAgeDisclosureNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataMinorModelAgeDisclosureNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataMinorModelAgeDisclosureNestedUpsertArchiveCreateInput | null;
}

export interface MetadataMinorModelAgeDisclosureNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataMinorModelAgeDisclosureNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataMinorModelAgeDisclosureNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataMinorModelAgeDisclosureNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataMinorModelAgeDisclosureNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataMinorModelAgeDisclosureNestedUpsertMetadataCreateInput | null;
}

export interface MetadataMinorModelAgeDisclosureNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataMinorModelAgeDisclosureNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataMinorModelAgeDisclosureNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataMinorModelAgeDisclosureNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataMinorModelAgeDisclosureNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataMinorModelAgeDisclosureNestedUpsertPhotoCreateInput | null;
}

export interface MetadataMinorModelAgeDisclosureNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataMinorModelAgeDisclosureNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataMinorModelAgeDisclosureNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataMinorModelAgeDisclosureNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataMinorModelAgeDisclosureNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataMinorModelAgeDisclosureNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataMinorModelAgeDisclosureNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataMinorModelAgeDisclosureNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataMinorModelAgeDisclosureNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataMinorModelAgeDisclosureNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataMinorModelAgeDisclosureNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataMinorModelAgeDisclosureNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataMinorModelAgeDisclosureNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataMinorModelAgeDisclosureNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataMinorModelAgeDisclosureNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataMinorModelAgeDisclosure" nodes by specifying some conditions
 */
export interface MetadataMinorModelAgeDisclosureWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataMinorModelAgeDisclosureWhereInput[] | null;
  OR?: MetadataMinorModelAgeDisclosureWhereInput[] | null;
  NOT?: MetadataMinorModelAgeDisclosureWhereInput | null;
}

/**
 * Identifies exactly one "MetadataMinorModelAgeDisclosure" node.
 */
export interface MetadataMinorModelAgeDisclosureWhereUniqueInput {
  id: number;
}

export interface MetadataMinorModelAgeDisclosureWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataMinorModelAgeDisclosureNestedPhotoCreateInput;
  metadata: MetadataMinorModelAgeDisclosureNestedMetadataCreateInput;
}

export interface MetadataMinorModelAgeDisclosureWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataMinorModelAgeDisclosureNestedPhotoUpdateInput | null;
  metadata?: MetadataMinorModelAgeDisclosureNestedMetadataUpdateInput | null;
}

export interface MetadataMinorModelAgeDisclosureWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataMinorModelAgeDisclosureNestedPhotoCreateInput;
  archive: MetadataMinorModelAgeDisclosureNestedArchiveCreateInput;
}

export interface MetadataMinorModelAgeDisclosureWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataMinorModelAgeDisclosureNestedPhotoUpdateInput | null;
  archive?: MetadataMinorModelAgeDisclosureNestedArchiveUpdateInput | null;
}

export interface MetadataMinorModelAgeDisclosureWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataMinorModelAgeDisclosureNestedMetadataCreateInput;
  archive: MetadataMinorModelAgeDisclosureNestedArchiveCreateInput;
}

export interface MetadataMinorModelAgeDisclosureWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataMinorModelAgeDisclosureNestedMetadataUpdateInput | null;
  archive?: MetadataMinorModelAgeDisclosureNestedArchiveUpdateInput | null;
}

export interface MetadataModelAgeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataModelAgeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataModelAgeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataModelAgeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataModelAgeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataModelAgeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataModelAgeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataModelAgeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataModelAgeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataModelAgeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataModelAgeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataModelAgeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataModelAgeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataModelAgeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataModelAgeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataModelAgeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataModelAgeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataModelAgeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataModelAgeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataModelAgeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataModelAgeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataModelAgeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataModelAgeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataModelAgeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataModelAgeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataModelAgeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataModelAgeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataModelAgeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataModelAgeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataModelAgeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataModelAge" nodes by specifying some conditions
 */
export interface MetadataModelAgeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataModelAgeWhereInput[] | null;
  OR?: MetadataModelAgeWhereInput[] | null;
  NOT?: MetadataModelAgeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataModelAge" node.
 */
export interface MetadataModelAgeWhereUniqueInput {
  id: number;
}

export interface MetadataModelAgeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataModelAgeNestedPhotoCreateInput;
  metadata: MetadataModelAgeNestedMetadataCreateInput;
}

export interface MetadataModelAgeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataModelAgeNestedPhotoUpdateInput | null;
  metadata?: MetadataModelAgeNestedMetadataUpdateInput | null;
}

export interface MetadataModelAgeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataModelAgeNestedPhotoCreateInput;
  archive: MetadataModelAgeNestedArchiveCreateInput;
}

export interface MetadataModelAgeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataModelAgeNestedPhotoUpdateInput | null;
  archive?: MetadataModelAgeNestedArchiveUpdateInput | null;
}

export interface MetadataModelAgeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataModelAgeNestedMetadataCreateInput;
  archive: MetadataModelAgeNestedArchiveCreateInput;
}

export interface MetadataModelAgeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataModelAgeNestedMetadataUpdateInput | null;
  archive?: MetadataModelAgeNestedArchiveUpdateInput | null;
}

export interface MetadataModelNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataModelNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataModelNestedUpsertArchiveCreateInput | null;
}

export interface MetadataModelNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataModelNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataModelNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataModelNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataModelNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataModelNestedUpsertMetadataCreateInput | null;
}

export interface MetadataModelNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataModelNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataModelNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataModelNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataModelNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataModelNestedUpsertPhotoCreateInput | null;
}

export interface MetadataModelNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataModelNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataModelNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataModelNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataModelNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataModelNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataModelNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataModelNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataModelNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataModelNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataModelNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataModelNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataModelNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataModelNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataModelNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataModelReleaseIdNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataModelReleaseIdNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataModelReleaseIdNestedUpsertArchiveCreateInput | null;
}

export interface MetadataModelReleaseIdNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataModelReleaseIdNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataModelReleaseIdNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataModelReleaseIdNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataModelReleaseIdNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataModelReleaseIdNestedUpsertMetadataCreateInput | null;
}

export interface MetadataModelReleaseIdNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataModelReleaseIdNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataModelReleaseIdNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataModelReleaseIdNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataModelReleaseIdNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataModelReleaseIdNestedUpsertPhotoCreateInput | null;
}

export interface MetadataModelReleaseIdNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataModelReleaseIdNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataModelReleaseIdNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataModelReleaseIdNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataModelReleaseIdNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataModelReleaseIdNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataModelReleaseIdNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataModelReleaseIdNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataModelReleaseIdNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataModelReleaseIdNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataModelReleaseIdNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataModelReleaseIdNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataModelReleaseIdNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataModelReleaseIdNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataModelReleaseIdNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataModelReleaseId" nodes by specifying some conditions
 */
export interface MetadataModelReleaseIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataModelReleaseIdWhereInput[] | null;
  OR?: MetadataModelReleaseIdWhereInput[] | null;
  NOT?: MetadataModelReleaseIdWhereInput | null;
}

/**
 * Identifies exactly one "MetadataModelReleaseId" node.
 */
export interface MetadataModelReleaseIdWhereUniqueInput {
  id: number;
}

export interface MetadataModelReleaseIdWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataModelReleaseIdNestedPhotoCreateInput;
  metadata: MetadataModelReleaseIdNestedMetadataCreateInput;
}

export interface MetadataModelReleaseIdWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataModelReleaseIdNestedPhotoUpdateInput | null;
  metadata?: MetadataModelReleaseIdNestedMetadataUpdateInput | null;
}

export interface MetadataModelReleaseIdWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataModelReleaseIdNestedPhotoCreateInput;
  archive: MetadataModelReleaseIdNestedArchiveCreateInput;
}

export interface MetadataModelReleaseIdWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataModelReleaseIdNestedPhotoUpdateInput | null;
  archive?: MetadataModelReleaseIdNestedArchiveUpdateInput | null;
}

export interface MetadataModelReleaseIdWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataModelReleaseIdNestedMetadataCreateInput;
  archive: MetadataModelReleaseIdNestedArchiveCreateInput;
}

export interface MetadataModelReleaseIdWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataModelReleaseIdNestedMetadataUpdateInput | null;
  archive?: MetadataModelReleaseIdNestedArchiveUpdateInput | null;
}

export interface MetadataModelReleaseStatusNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataModelReleaseStatusNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataModelReleaseStatusNestedUpsertArchiveCreateInput | null;
}

export interface MetadataModelReleaseStatusNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataModelReleaseStatusNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataModelReleaseStatusNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataModelReleaseStatusNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataModelReleaseStatusNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataModelReleaseStatusNestedUpsertMetadataCreateInput | null;
}

export interface MetadataModelReleaseStatusNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataModelReleaseStatusNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataModelReleaseStatusNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataModelReleaseStatusNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataModelReleaseStatusNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataModelReleaseStatusNestedUpsertPhotoCreateInput | null;
}

export interface MetadataModelReleaseStatusNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataModelReleaseStatusNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataModelReleaseStatusNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataModelReleaseStatusNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataModelReleaseStatusNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataModelReleaseStatusNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataModelReleaseStatusNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataModelReleaseStatusNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataModelReleaseStatusNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataModelReleaseStatusNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataModelReleaseStatusNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataModelReleaseStatusNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataModelReleaseStatusNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataModelReleaseStatusNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataModelReleaseStatusNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataModelReleaseStatus" nodes by specifying some conditions
 */
export interface MetadataModelReleaseStatusWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataModelReleaseStatusWhereInput[] | null;
  OR?: MetadataModelReleaseStatusWhereInput[] | null;
  NOT?: MetadataModelReleaseStatusWhereInput | null;
}

/**
 * Identifies exactly one "MetadataModelReleaseStatus" node.
 */
export interface MetadataModelReleaseStatusWhereUniqueInput {
  id: number;
}

export interface MetadataModelReleaseStatusWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataModelReleaseStatusNestedPhotoCreateInput;
  metadata: MetadataModelReleaseStatusNestedMetadataCreateInput;
}

export interface MetadataModelReleaseStatusWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataModelReleaseStatusNestedPhotoUpdateInput | null;
  metadata?: MetadataModelReleaseStatusNestedMetadataUpdateInput | null;
}

export interface MetadataModelReleaseStatusWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataModelReleaseStatusNestedPhotoCreateInput;
  archive: MetadataModelReleaseStatusNestedArchiveCreateInput;
}

export interface MetadataModelReleaseStatusWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataModelReleaseStatusNestedPhotoUpdateInput | null;
  archive?: MetadataModelReleaseStatusNestedArchiveUpdateInput | null;
}

export interface MetadataModelReleaseStatusWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataModelReleaseStatusNestedMetadataCreateInput;
  archive: MetadataModelReleaseStatusNestedArchiveCreateInput;
}

export interface MetadataModelReleaseStatusWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataModelReleaseStatusNestedMetadataUpdateInput | null;
  archive?: MetadataModelReleaseStatusNestedArchiveUpdateInput | null;
}

/**
 * Filters the "MetadataModel" nodes by specifying some conditions
 */
export interface MetadataModelWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataModelWhereInput[] | null;
  OR?: MetadataModelWhereInput[] | null;
  NOT?: MetadataModelWhereInput | null;
}

/**
 * Identifies exactly one "MetadataModel" node.
 */
export interface MetadataModelWhereUniqueInput {
  id: number;
}

export interface MetadataModelWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataModelNestedPhotoCreateInput;
  metadata: MetadataModelNestedMetadataCreateInput;
}

export interface MetadataModelWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataModelNestedPhotoUpdateInput | null;
  metadata?: MetadataModelNestedMetadataUpdateInput | null;
}

export interface MetadataModelWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataModelNestedPhotoCreateInput;
  archive: MetadataModelNestedArchiveCreateInput;
}

export interface MetadataModelWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataModelNestedPhotoUpdateInput | null;
  archive?: MetadataModelNestedArchiveUpdateInput | null;
}

export interface MetadataModelWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataModelNestedMetadataCreateInput;
  archive: MetadataModelNestedArchiveCreateInput;
}

export interface MetadataModelWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataModelNestedMetadataUpdateInput | null;
  archive?: MetadataModelNestedArchiveUpdateInput | null;
}

export interface MetadataMorePermissionsNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataMorePermissionsNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataMorePermissionsNestedUpsertArchiveCreateInput | null;
}

export interface MetadataMorePermissionsNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataMorePermissionsNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataMorePermissionsNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataMorePermissionsNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataMorePermissionsNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataMorePermissionsNestedUpsertMetadataCreateInput | null;
}

export interface MetadataMorePermissionsNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataMorePermissionsNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataMorePermissionsNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataMorePermissionsNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataMorePermissionsNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataMorePermissionsNestedUpsertPhotoCreateInput | null;
}

export interface MetadataMorePermissionsNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataMorePermissionsNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataMorePermissionsNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataMorePermissionsNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataMorePermissionsNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataMorePermissionsNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataMorePermissionsNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataMorePermissionsNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataMorePermissionsNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataMorePermissionsNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataMorePermissionsNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataMorePermissionsNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataMorePermissionsNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataMorePermissionsNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataMorePermissionsNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataMorePermissions" nodes by specifying some conditions
 */
export interface MetadataMorePermissionsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataMorePermissionsWhereInput[] | null;
  OR?: MetadataMorePermissionsWhereInput[] | null;
  NOT?: MetadataMorePermissionsWhereInput | null;
}

/**
 * Identifies exactly one "MetadataMorePermissions" node.
 */
export interface MetadataMorePermissionsWhereUniqueInput {
  id: number;
}

export interface MetadataMorePermissionsWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataMorePermissionsNestedPhotoCreateInput;
  metadata: MetadataMorePermissionsNestedMetadataCreateInput;
}

export interface MetadataMorePermissionsWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataMorePermissionsNestedPhotoUpdateInput | null;
  metadata?: MetadataMorePermissionsNestedMetadataUpdateInput | null;
}

export interface MetadataMorePermissionsWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataMorePermissionsNestedPhotoCreateInput;
  archive: MetadataMorePermissionsNestedArchiveCreateInput;
}

export interface MetadataMorePermissionsWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataMorePermissionsNestedPhotoUpdateInput | null;
  archive?: MetadataMorePermissionsNestedArchiveUpdateInput | null;
}

export interface MetadataMorePermissionsWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataMorePermissionsNestedMetadataCreateInput;
  archive: MetadataMorePermissionsNestedArchiveCreateInput;
}

export interface MetadataMorePermissionsWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataMorePermissionsNestedMetadataUpdateInput | null;
  archive?: MetadataMorePermissionsNestedArchiveUpdateInput | null;
}

export interface MetadataNestedMetadataAccessRightsListCreateInput {
  connect?: MetadataAccessRightsWhereUniqueInput[] | null;
  create?: MetadataAccessRightsWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataAccessRightsListUpdateInput {
  connect?: MetadataAccessRightsWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataAccessRightsListUpdateInput[] | null;
  create?: MetadataAccessRightsWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataAccessRightsListUpdateInput[] | null;
  delete?: MetadataAccessRightsWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataAddlModelInfoListCreateInput {
  connect?: MetadataAddlModelInfoWhereUniqueInput[] | null;
  create?: MetadataAddlModelInfoWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataAddlModelInfoListUpdateInput {
  connect?: MetadataAddlModelInfoWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataAddlModelInfoListUpdateInput[] | null;
  create?: MetadataAddlModelInfoWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataAddlModelInfoListUpdateInput[] | null;
  delete?: MetadataAddlModelInfoWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataArchiveChildListCreateInput {
  connect?: MetadataArchiveChildWhereUniqueInput[] | null;
  create?: MetadataArchiveChildWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataArchiveChildListUpdateInput {
  connect?: MetadataArchiveChildWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataArchiveChildListUpdateInput[] | null;
  create?: MetadataArchiveChildWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataArchiveChildListUpdateInput[] | null;
  delete?: MetadataArchiveChildWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataArchiveParentListCreateInput {
  connect?: MetadataArchiveParentWhereUniqueInput[] | null;
  create?: MetadataArchiveParentWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataArchiveParentListUpdateInput {
  connect?: MetadataArchiveParentWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataArchiveParentListUpdateInput[] | null;
  create?: MetadataArchiveParentWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataArchiveParentListUpdateInput[] | null;
  delete?: MetadataArchiveParentWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataAssetStateListCreateInput {
  connect?: MetadataAssetStateWhereUniqueInput[] | null;
  create?: MetadataAssetStateWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataAssetStateListUpdateInput {
  connect?: MetadataAssetStateWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataAssetStateListUpdateInput[] | null;
  create?: MetadataAssetStateWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataAssetStateListUpdateInput[] | null;
  delete?: MetadataAssetStateWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataAttributionNameListCreateInput {
  connect?: MetadataAttributionNameWhereUniqueInput[] | null;
  create?: MetadataAttributionNameWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataAttributionNameListUpdateInput {
  connect?: MetadataAttributionNameWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataAttributionNameListUpdateInput[] | null;
  create?: MetadataAttributionNameWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataAttributionNameListUpdateInput[] | null;
  delete?: MetadataAttributionNameWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataAttributionUrlListCreateInput {
  connect?: MetadataAttributionUrlWhereUniqueInput[] | null;
  create?: MetadataAttributionUrlWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataAttributionUrlListUpdateInput {
  connect?: MetadataAttributionUrlWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataAttributionUrlListUpdateInput[] | null;
  create?: MetadataAttributionUrlWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataAttributionUrlListUpdateInput[] | null;
  delete?: MetadataAttributionUrlWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataAudienceListCreateInput {
  connect?: MetadataAudienceWhereUniqueInput[] | null;
  create?: MetadataAudienceWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataAudienceListUpdateInput {
  connect?: MetadataAudienceWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataAudienceListUpdateInput[] | null;
  create?: MetadataAudienceWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataAudienceListUpdateInput[] | null;
  delete?: MetadataAudienceWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataAuthorsPositionListCreateInput {
  connect?: MetadataAuthorsPositionWhereUniqueInput[] | null;
  create?: MetadataAuthorsPositionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataAuthorsPositionListUpdateInput {
  connect?: MetadataAuthorsPositionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataAuthorsPositionListUpdateInput[] | null;
  create?: MetadataAuthorsPositionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataAuthorsPositionListUpdateInput[] | null;
  delete?: MetadataAuthorsPositionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataBackupNameListCreateInput {
  connect?: MetadataBackupNameWhereUniqueInput[] | null;
  create?: MetadataBackupNameWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataBackupNameListUpdateInput {
  connect?: MetadataBackupNameWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataBackupNameListUpdateInput[] | null;
  create?: MetadataBackupNameWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataBackupNameListUpdateInput[] | null;
  delete?: MetadataBackupNameWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataBitmapGrayscalePictureListCreateInput {
  connect?: MetadataBitmapGrayscalePictureWhereUniqueInput[] | null;
  create?: MetadataBitmapGrayscalePictureWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataBitmapGrayscalePictureListUpdateInput {
  connect?: MetadataBitmapGrayscalePictureWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataBitmapGrayscalePictureListUpdateInput[] | null;
  create?: MetadataBitmapGrayscalePictureWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataBitmapGrayscalePictureListUpdateInput[] | null;
  delete?: MetadataBitmapGrayscalePictureWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataBrandListCreateInput {
  connect?: MetadataBrandWhereUniqueInput[] | null;
  create?: MetadataBrandWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataBrandListUpdateInput {
  connect?: MetadataBrandWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataBrandListUpdateInput[] | null;
  create?: MetadataBrandWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataBrandListUpdateInput[] | null;
  delete?: MetadataBrandWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataBrandPrismaListCreateInput {
  connect?: MetadataBrandPrismaWhereUniqueInput[] | null;
  create?: MetadataBrandPrismaWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataBrandPrismaListUpdateInput {
  connect?: MetadataBrandPrismaWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataBrandPrismaListUpdateInput[] | null;
  create?: MetadataBrandPrismaWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataBrandPrismaListUpdateInput[] | null;
  delete?: MetadataBrandPrismaWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataBrandShortNameListCreateInput {
  connect?: MetadataBrandShortNameWhereUniqueInput[] | null;
  create?: MetadataBrandShortNameWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataBrandShortNameListUpdateInput {
  connect?: MetadataBrandShortNameWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataBrandShortNameListUpdateInput[] | null;
  create?: MetadataBrandShortNameWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataBrandShortNameListUpdateInput[] | null;
  delete?: MetadataBrandShortNameWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCaptionWriterListCreateInput {
  connect?: MetadataCaptionWriterWhereUniqueInput[] | null;
  create?: MetadataCaptionWriterWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCaptionWriterListUpdateInput {
  connect?: MetadataCaptionWriterWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCaptionWriterListUpdateInput[] | null;
  create?: MetadataCaptionWriterWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCaptionWriterListUpdateInput[] | null;
  delete?: MetadataCaptionWriterWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCategoryListCreateInput {
  connect?: MetadataCategoryWhereUniqueInput[] | null;
  create?: MetadataCategoryWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCategoryListUpdateInput {
  connect?: MetadataCategoryWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCategoryListUpdateInput[] | null;
  create?: MetadataCategoryWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCategoryListUpdateInput[] | null;
  delete?: MetadataCategoryWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCategoryPrismaListCreateInput {
  connect?: MetadataCategoryPrismaWhereUniqueInput[] | null;
  create?: MetadataCategoryPrismaWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCategoryPrismaListUpdateInput {
  connect?: MetadataCategoryPrismaWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCategoryPrismaListUpdateInput[] | null;
  create?: MetadataCategoryPrismaWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCategoryPrismaListUpdateInput[] | null;
  delete?: MetadataCategoryPrismaWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCertificateListCreateInput {
  connect?: MetadataCertificateWhereUniqueInput[] | null;
  create?: MetadataCertificateWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCertificateListUpdateInput {
  connect?: MetadataCertificateWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCertificateListUpdateInput[] | null;
  create?: MetadataCertificateWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCertificateListUpdateInput[] | null;
  delete?: MetadataCertificateWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCiAdrCityListCreateInput {
  connect?: MetadataCiAdrCityWhereUniqueInput[] | null;
  create?: MetadataCiAdrCityWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCiAdrCityListUpdateInput {
  connect?: MetadataCiAdrCityWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCiAdrCityListUpdateInput[] | null;
  create?: MetadataCiAdrCityWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCiAdrCityListUpdateInput[] | null;
  delete?: MetadataCiAdrCityWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCiAdrCtryListCreateInput {
  connect?: MetadataCiAdrCtryWhereUniqueInput[] | null;
  create?: MetadataCiAdrCtryWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCiAdrCtryListUpdateInput {
  connect?: MetadataCiAdrCtryWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCiAdrCtryListUpdateInput[] | null;
  create?: MetadataCiAdrCtryWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCiAdrCtryListUpdateInput[] | null;
  delete?: MetadataCiAdrCtryWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCiAdrExtadrListCreateInput {
  connect?: MetadataCiAdrExtadrWhereUniqueInput[] | null;
  create?: MetadataCiAdrExtadrWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCiAdrExtadrListUpdateInput {
  connect?: MetadataCiAdrExtadrWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCiAdrExtadrListUpdateInput[] | null;
  create?: MetadataCiAdrExtadrWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCiAdrExtadrListUpdateInput[] | null;
  delete?: MetadataCiAdrExtadrWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCiAdrPcodeListCreateInput {
  connect?: MetadataCiAdrPcodeWhereUniqueInput[] | null;
  create?: MetadataCiAdrPcodeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCiAdrPcodeListUpdateInput {
  connect?: MetadataCiAdrPcodeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCiAdrPcodeListUpdateInput[] | null;
  create?: MetadataCiAdrPcodeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCiAdrPcodeListUpdateInput[] | null;
  delete?: MetadataCiAdrPcodeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCiAdrRegionListCreateInput {
  connect?: MetadataCiAdrRegionWhereUniqueInput[] | null;
  create?: MetadataCiAdrRegionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCiAdrRegionListUpdateInput {
  connect?: MetadataCiAdrRegionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCiAdrRegionListUpdateInput[] | null;
  create?: MetadataCiAdrRegionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCiAdrRegionListUpdateInput[] | null;
  delete?: MetadataCiAdrRegionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCiEmailWorkListCreateInput {
  connect?: MetadataCiEmailWorkWhereUniqueInput[] | null;
  create?: MetadataCiEmailWorkWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCiEmailWorkListUpdateInput {
  connect?: MetadataCiEmailWorkWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCiEmailWorkListUpdateInput[] | null;
  create?: MetadataCiEmailWorkWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCiEmailWorkListUpdateInput[] | null;
  delete?: MetadataCiEmailWorkWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCiTelWorkListCreateInput {
  connect?: MetadataCiTelWorkWhereUniqueInput[] | null;
  create?: MetadataCiTelWorkWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCiTelWorkListUpdateInput {
  connect?: MetadataCiTelWorkWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCiTelWorkListUpdateInput[] | null;
  create?: MetadataCiTelWorkWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCiTelWorkListUpdateInput[] | null;
  delete?: MetadataCiTelWorkWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCiUrlWorkListCreateInput {
  connect?: MetadataCiUrlWorkWhereUniqueInput[] | null;
  create?: MetadataCiUrlWorkWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCiUrlWorkListUpdateInput {
  connect?: MetadataCiUrlWorkWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCiUrlWorkListUpdateInput[] | null;
  create?: MetadataCiUrlWorkWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCiUrlWorkListUpdateInput[] | null;
  delete?: MetadataCiUrlWorkWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCityListCreateInput {
  connect?: MetadataCityWhereUniqueInput[] | null;
  create?: MetadataCityWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCityListUpdateInput {
  connect?: MetadataCityWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCityListUpdateInput[] | null;
  create?: MetadataCityWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCityListUpdateInput[] | null;
  delete?: MetadataCityWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataClassifyListCreateInput {
  connect?: MetadataClassifyWhereUniqueInput[] | null;
  create?: MetadataClassifyWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataClassifyListUpdateInput {
  connect?: MetadataClassifyWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataClassifyListUpdateInput[] | null;
  create?: MetadataClassifyWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataClassifyListUpdateInput[] | null;
  delete?: MetadataClassifyWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCompanyListCreateInput {
  connect?: MetadataCompanyWhereUniqueInput[] | null;
  create?: MetadataCompanyWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCompanyListUpdateInput {
  connect?: MetadataCompanyWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCompanyListUpdateInput[] | null;
  create?: MetadataCompanyWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCompanyListUpdateInput[] | null;
  delete?: MetadataCompanyWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCompanyShortNameListCreateInput {
  connect?: MetadataCompanyShortNameWhereUniqueInput[] | null;
  create?: MetadataCompanyShortNameWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCompanyShortNameListUpdateInput {
  connect?: MetadataCompanyShortNameWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCompanyShortNameListUpdateInput[] | null;
  create?: MetadataCompanyShortNameWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCompanyShortNameListUpdateInput[] | null;
  delete?: MetadataCompanyShortNameWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataConfidentialityListCreateInput {
  connect?: MetadataConfidentialityWhereUniqueInput[] | null;
  create?: MetadataConfidentialityWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataConfidentialityListUpdateInput {
  connect?: MetadataConfidentialityWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataConfidentialityListUpdateInput[] | null;
  create?: MetadataConfidentialityWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataConfidentialityListUpdateInput[] | null;
  delete?: MetadataConfidentialityWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataContainedInListCreateInput {
  connect?: MetadataContainedInWhereUniqueInput[] | null;
  create?: MetadataContainedInWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataContainedInListUpdateInput {
  connect?: MetadataContainedInWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataContainedInListUpdateInput[] | null;
  create?: MetadataContainedInWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataContainedInListUpdateInput[] | null;
  delete?: MetadataContainedInWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataContentMediaKindListCreateInput {
  connect?: MetadataContentMediaKindWhereUniqueInput[] | null;
  create?: MetadataContentMediaKindWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataContentMediaKindListUpdateInput {
  connect?: MetadataContentMediaKindWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataContentMediaKindListUpdateInput[] | null;
  create?: MetadataContentMediaKindWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataContentMediaKindListUpdateInput[] | null;
  delete?: MetadataContentMediaKindWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataContentValueListCreateInput {
  connect?: MetadataContentValueWhereUniqueInput[] | null;
  create?: MetadataContentValueWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataContentValueListUpdateInput {
  connect?: MetadataContentValueWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataContentValueListUpdateInput[] | null;
  create?: MetadataContentValueWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataContentValueListUpdateInput[] | null;
  delete?: MetadataContentValueWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCopyrightLayerListCreateInput {
  connect?: MetadataCopyrightLayerWhereUniqueInput[] | null;
  create?: MetadataCopyrightLayerWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCopyrightLayerListUpdateInput {
  connect?: MetadataCopyrightLayerWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCopyrightLayerListUpdateInput[] | null;
  create?: MetadataCopyrightLayerWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCopyrightLayerListUpdateInput[] | null;
  delete?: MetadataCopyrightLayerWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCountryCodeListCreateInput {
  connect?: MetadataCountryCodeWhereUniqueInput[] | null;
  create?: MetadataCountryCodeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCountryCodeListUpdateInput {
  connect?: MetadataCountryCodeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCountryCodeListUpdateInput[] | null;
  create?: MetadataCountryCodeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCountryCodeListUpdateInput[] | null;
  delete?: MetadataCountryCodeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCountryListCreateInput {
  connect?: MetadataCountryWhereUniqueInput[] | null;
  create?: MetadataCountryWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCountryListUpdateInput {
  connect?: MetadataCountryWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCountryListUpdateInput[] | null;
  create?: MetadataCountryWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCountryListUpdateInput[] | null;
  delete?: MetadataCountryWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCreatedTimeListCreateInput {
  connect?: MetadataCreatedTimeWhereUniqueInput[] | null;
  create?: MetadataCreatedTimeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCreatedTimeListUpdateInput {
  connect?: MetadataCreatedTimeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCreatedTimeListUpdateInput[] | null;
  create?: MetadataCreatedTimeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCreatedTimeListUpdateInput[] | null;
  delete?: MetadataCreatedTimeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCreatorListCreateInput {
  connect?: MetadataCreatorWhereUniqueInput[] | null;
  create?: MetadataCreatorWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCreatorListUpdateInput {
  connect?: MetadataCreatorWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCreatorListUpdateInput[] | null;
  create?: MetadataCreatorWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCreatorListUpdateInput[] | null;
  delete?: MetadataCreatorWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCreatorToolListCreateInput {
  connect?: MetadataCreatorToolWhereUniqueInput[] | null;
  create?: MetadataCreatorToolWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCreatorToolListUpdateInput {
  connect?: MetadataCreatorToolWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCreatorToolListUpdateInput[] | null;
  create?: MetadataCreatorToolWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCreatorToolListUpdateInput[] | null;
  delete?: MetadataCreatorToolWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCreditListCreateInput {
  connect?: MetadataCreditWhereUniqueInput[] | null;
  create?: MetadataCreditWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCreditListUpdateInput {
  connect?: MetadataCreditWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCreditListUpdateInput[] | null;
  create?: MetadataCreditWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCreditListUpdateInput[] | null;
  delete?: MetadataCreditWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCustomField14ListCreateInput {
  connect?: MetadataCustomField14WhereUniqueInput[] | null;
  create?: MetadataCustomField14WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCustomField14ListUpdateInput {
  connect?: MetadataCustomField14WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCustomField14ListUpdateInput[] | null;
  create?: MetadataCustomField14WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCustomField14ListUpdateInput[] | null;
  delete?: MetadataCustomField14WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCustomField15ListCreateInput {
  connect?: MetadataCustomField15WhereUniqueInput[] | null;
  create?: MetadataCustomField15WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCustomField15ListUpdateInput {
  connect?: MetadataCustomField15WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCustomField15ListUpdateInput[] | null;
  create?: MetadataCustomField15WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCustomField15ListUpdateInput[] | null;
  delete?: MetadataCustomField15WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCustomField16ListCreateInput {
  connect?: MetadataCustomField16WhereUniqueInput[] | null;
  create?: MetadataCustomField16WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCustomField16ListUpdateInput {
  connect?: MetadataCustomField16WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCustomField16ListUpdateInput[] | null;
  create?: MetadataCustomField16WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCustomField16ListUpdateInput[] | null;
  delete?: MetadataCustomField16WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCustomField17ListCreateInput {
  connect?: MetadataCustomField17WhereUniqueInput[] | null;
  create?: MetadataCustomField17WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCustomField17ListUpdateInput {
  connect?: MetadataCustomField17WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCustomField17ListUpdateInput[] | null;
  create?: MetadataCustomField17WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCustomField17ListUpdateInput[] | null;
  delete?: MetadataCustomField17WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCustomField20ListCreateInput {
  connect?: MetadataCustomField20WhereUniqueInput[] | null;
  create?: MetadataCustomField20WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCustomField20ListUpdateInput {
  connect?: MetadataCustomField20WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCustomField20ListUpdateInput[] | null;
  create?: MetadataCustomField20WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCustomField20ListUpdateInput[] | null;
  delete?: MetadataCustomField20WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCustomField5ListCreateInput {
  connect?: MetadataCustomField5WhereUniqueInput[] | null;
  create?: MetadataCustomField5WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCustomField5ListUpdateInput {
  connect?: MetadataCustomField5WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCustomField5ListUpdateInput[] | null;
  create?: MetadataCustomField5WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCustomField5ListUpdateInput[] | null;
  delete?: MetadataCustomField5WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCustomField6ListCreateInput {
  connect?: MetadataCustomField6WhereUniqueInput[] | null;
  create?: MetadataCustomField6WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCustomField6ListUpdateInput {
  connect?: MetadataCustomField6WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCustomField6ListUpdateInput[] | null;
  create?: MetadataCustomField6WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCustomField6ListUpdateInput[] | null;
  delete?: MetadataCustomField6WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCustomField7ListCreateInput {
  connect?: MetadataCustomField7WhereUniqueInput[] | null;
  create?: MetadataCustomField7WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCustomField7ListUpdateInput {
  connect?: MetadataCustomField7WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCustomField7ListUpdateInput[] | null;
  create?: MetadataCustomField7WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCustomField7ListUpdateInput[] | null;
  delete?: MetadataCustomField7WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataCustomField9ListCreateInput {
  connect?: MetadataCustomField9WhereUniqueInput[] | null;
  create?: MetadataCustomField9WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataCustomField9ListUpdateInput {
  connect?: MetadataCustomField9WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataCustomField9ListUpdateInput[] | null;
  create?: MetadataCustomField9WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataCustomField9ListUpdateInput[] | null;
  delete?: MetadataCustomField9WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDateCreatedListCreateInput {
  connect?: MetadataDateCreatedWhereUniqueInput[] | null;
  create?: MetadataDateCreatedWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDateCreatedListUpdateInput {
  connect?: MetadataDateCreatedWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDateCreatedListUpdateInput[] | null;
  create?: MetadataDateCreatedWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDateCreatedListUpdateInput[] | null;
  delete?: MetadataDateCreatedWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDateTimeDigitizedListCreateInput {
  connect?: MetadataDateTimeDigitizedWhereUniqueInput[] | null;
  create?: MetadataDateTimeDigitizedWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDateTimeDigitizedListUpdateInput {
  connect?: MetadataDateTimeDigitizedWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDateTimeDigitizedListUpdateInput[] | null;
  create?: MetadataDateTimeDigitizedWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDateTimeDigitizedListUpdateInput[] | null;
  delete?: MetadataDateTimeDigitizedWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDateTimeOriginalListCreateInput {
  connect?: MetadataDateTimeOriginalWhereUniqueInput[] | null;
  create?: MetadataDateTimeOriginalWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDateTimeOriginalListUpdateInput {
  connect?: MetadataDateTimeOriginalWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDateTimeOriginalListUpdateInput[] | null;
  create?: MetadataDateTimeOriginalWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDateTimeOriginalListUpdateInput[] | null;
  delete?: MetadataDateTimeOriginalWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryAccountListCreateInput {
  connect?: MetadataDeliveryAccountWhereUniqueInput[] | null;
  create?: MetadataDeliveryAccountWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryAccountListUpdateInput {
  connect?: MetadataDeliveryAccountWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryAccountListUpdateInput[] | null;
  create?: MetadataDeliveryAccountWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryAccountListUpdateInput[] | null;
  delete?: MetadataDeliveryAccountWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryCompanyListCreateInput {
  connect?: MetadataDeliveryCompanyWhereUniqueInput[] | null;
  create?: MetadataDeliveryCompanyWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryCompanyListUpdateInput {
  connect?: MetadataDeliveryCompanyWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryCompanyListUpdateInput[] | null;
  create?: MetadataDeliveryCompanyWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryCompanyListUpdateInput[] | null;
  delete?: MetadataDeliveryCompanyWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryCopyrightListCreateInput {
  connect?: MetadataDeliveryCopyrightWhereUniqueInput[] | null;
  create?: MetadataDeliveryCopyrightWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryCopyrightListUpdateInput {
  connect?: MetadataDeliveryCopyrightWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryCopyrightListUpdateInput[] | null;
  create?: MetadataDeliveryCopyrightWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryCopyrightListUpdateInput[] | null;
  delete?: MetadataDeliveryCopyrightWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryDateFolderListCreateInput {
  connect?: MetadataDeliveryDateFolderWhereUniqueInput[] | null;
  create?: MetadataDeliveryDateFolderWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryDateFolderListUpdateInput {
  connect?: MetadataDeliveryDateFolderWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryDateFolderListUpdateInput[] | null;
  create?: MetadataDeliveryDateFolderWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryDateFolderListUpdateInput[] | null;
  delete?: MetadataDeliveryDateFolderWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryDateTimeListCreateInput {
  connect?: MetadataDeliveryDateTimeWhereUniqueInput[] | null;
  create?: MetadataDeliveryDateTimeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryDateTimeListUpdateInput {
  connect?: MetadataDeliveryDateTimeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryDateTimeListUpdateInput[] | null;
  create?: MetadataDeliveryDateTimeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryDateTimeListUpdateInput[] | null;
  delete?: MetadataDeliveryDateTimeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryDcCreatorListCreateInput {
  connect?: MetadataDeliveryDcCreatorWhereUniqueInput[] | null;
  create?: MetadataDeliveryDcCreatorWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryDcCreatorListUpdateInput {
  connect?: MetadataDeliveryDcCreatorWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryDcCreatorListUpdateInput[] | null;
  create?: MetadataDeliveryDcCreatorWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryDcCreatorListUpdateInput[] | null;
  delete?: MetadataDeliveryDcCreatorWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryDcRightsListCreateInput {
  connect?: MetadataDeliveryDcRightsWhereUniqueInput[] | null;
  create?: MetadataDeliveryDcRightsWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryDcRightsListUpdateInput {
  connect?: MetadataDeliveryDcRightsWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryDcRightsListUpdateInput[] | null;
  create?: MetadataDeliveryDcRightsWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryDcRightsListUpdateInput[] | null;
  delete?: MetadataDeliveryDcRightsWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryFileTypeListCreateInput {
  connect?: MetadataDeliveryFileTypeWhereUniqueInput[] | null;
  create?: MetadataDeliveryFileTypeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryFileTypeListUpdateInput {
  connect?: MetadataDeliveryFileTypeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryFileTypeListUpdateInput[] | null;
  create?: MetadataDeliveryFileTypeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryFileTypeListUpdateInput[] | null;
  delete?: MetadataDeliveryFileTypeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryFolderListCreateInput {
  connect?: MetadataDeliveryFolderWhereUniqueInput[] | null;
  create?: MetadataDeliveryFolderWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryFolderListUpdateInput {
  connect?: MetadataDeliveryFolderWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryFolderListUpdateInput[] | null;
  create?: MetadataDeliveryFolderWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryFolderListUpdateInput[] | null;
  delete?: MetadataDeliveryFolderWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryKindListCreateInput {
  connect?: MetadataDeliveryKindWhereUniqueInput[] | null;
  create?: MetadataDeliveryKindWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryKindListUpdateInput {
  connect?: MetadataDeliveryKindWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryKindListUpdateInput[] | null;
  create?: MetadataDeliveryKindWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryKindListUpdateInput[] | null;
  delete?: MetadataDeliveryKindWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryPathListCreateInput {
  connect?: MetadataDeliveryPathWhereUniqueInput[] | null;
  create?: MetadataDeliveryPathWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryPathListUpdateInput {
  connect?: MetadataDeliveryPathWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryPathListUpdateInput[] | null;
  create?: MetadataDeliveryPathWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryPathListUpdateInput[] | null;
  delete?: MetadataDeliveryPathWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryPersonShownintheImageListCreateInput {
  connect?: MetadataDeliveryPersonShownintheImageWhereUniqueInput[] | null;
  create?: MetadataDeliveryPersonShownintheImageWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryPersonShownintheImageListUpdateInput {
  connect?: MetadataDeliveryPersonShownintheImageWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryPersonShownintheImageListUpdateInput[] | null;
  create?: MetadataDeliveryPersonShownintheImageWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryPersonShownintheImageListUpdateInput[] | null;
  delete?: MetadataDeliveryPersonShownintheImageWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryPhotoshopCreditListCreateInput {
  connect?: MetadataDeliveryPhotoshopCreditWhereUniqueInput[] | null;
  create?: MetadataDeliveryPhotoshopCreditWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryPhotoshopCreditListUpdateInput {
  connect?: MetadataDeliveryPhotoshopCreditWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryPhotoshopCreditListUpdateInput[] | null;
  create?: MetadataDeliveryPhotoshopCreditWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryPhotoshopCreditListUpdateInput[] | null;
  delete?: MetadataDeliveryPhotoshopCreditWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryPhotoshopSourceListCreateInput {
  connect?: MetadataDeliveryPhotoshopSourceWhereUniqueInput[] | null;
  create?: MetadataDeliveryPhotoshopSourceWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryPhotoshopSourceListUpdateInput {
  connect?: MetadataDeliveryPhotoshopSourceWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryPhotoshopSourceListUpdateInput[] | null;
  create?: MetadataDeliveryPhotoshopSourceWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryPhotoshopSourceListUpdateInput[] | null;
  delete?: MetadataDeliveryPhotoshopSourceWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryServiceListCreateInput {
  connect?: MetadataDeliveryServiceWhereUniqueInput[] | null;
  create?: MetadataDeliveryServiceWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryServiceListUpdateInput {
  connect?: MetadataDeliveryServiceWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryServiceListUpdateInput[] | null;
  create?: MetadataDeliveryServiceWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryServiceListUpdateInput[] | null;
  delete?: MetadataDeliveryServiceWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliverySubjectListCreateInput {
  connect?: MetadataDeliverySubjectWhereUniqueInput[] | null;
  create?: MetadataDeliverySubjectWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliverySubjectListUpdateInput {
  connect?: MetadataDeliverySubjectWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliverySubjectListUpdateInput[] | null;
  create?: MetadataDeliverySubjectWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliverySubjectListUpdateInput[] | null;
  delete?: MetadataDeliverySubjectWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDeliveryUnderSubjectListCreateInput {
  connect?: MetadataDeliveryUnderSubjectWhereUniqueInput[] | null;
  create?: MetadataDeliveryUnderSubjectWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDeliveryUnderSubjectListUpdateInput {
  connect?: MetadataDeliveryUnderSubjectWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDeliveryUnderSubjectListUpdateInput[] | null;
  create?: MetadataDeliveryUnderSubjectWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDeliveryUnderSubjectListUpdateInput[] | null;
  delete?: MetadataDeliveryUnderSubjectWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDepartmentListCreateInput {
  connect?: MetadataDepartmentWhereUniqueInput[] | null;
  create?: MetadataDepartmentWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDepartmentListUpdateInput {
  connect?: MetadataDepartmentWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDepartmentListUpdateInput[] | null;
  create?: MetadataDepartmentWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDepartmentListUpdateInput[] | null;
  delete?: MetadataDepartmentWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDescriptionListCreateInput {
  connect?: MetadataDescriptionWhereUniqueInput[] | null;
  create?: MetadataDescriptionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDescriptionListUpdateInput {
  connect?: MetadataDescriptionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDescriptionListUpdateInput[] | null;
  create?: MetadataDescriptionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDescriptionListUpdateInput[] | null;
  delete?: MetadataDescriptionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDigitalAssetUrlListCreateInput {
  connect?: MetadataDigitalAssetUrlWhereUniqueInput[] | null;
  create?: MetadataDigitalAssetUrlWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDigitalAssetUrlListUpdateInput {
  connect?: MetadataDigitalAssetUrlWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDigitalAssetUrlListUpdateInput[] | null;
  create?: MetadataDigitalAssetUrlWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDigitalAssetUrlListUpdateInput[] | null;
  delete?: MetadataDigitalAssetUrlWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDivisionListCreateInput {
  connect?: MetadataDivisionWhereUniqueInput[] | null;
  create?: MetadataDivisionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDivisionListUpdateInput {
  connect?: MetadataDivisionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDivisionListUpdateInput[] | null;
  create?: MetadataDivisionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDivisionListUpdateInput[] | null;
  delete?: MetadataDivisionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataDocumentTextListCreateInput {
  connect?: MetadataDocumentTextWhereUniqueInput[] | null;
  create?: MetadataDocumentTextWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataDocumentTextListUpdateInput {
  connect?: MetadataDocumentTextWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataDocumentTextListUpdateInput[] | null;
  create?: MetadataDocumentTextWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataDocumentTextListUpdateInput[] | null;
  delete?: MetadataDocumentTextWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataEditStatusListCreateInput {
  connect?: MetadataEditStatusWhereUniqueInput[] | null;
  create?: MetadataEditStatusWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataEditStatusListUpdateInput {
  connect?: MetadataEditStatusWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataEditStatusListUpdateInput[] | null;
  create?: MetadataEditStatusWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataEditStatusListUpdateInput[] | null;
  delete?: MetadataEditStatusWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataEditionListCreateInput {
  connect?: MetadataEditionWhereUniqueInput[] | null;
  create?: MetadataEditionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataEditionListUpdateInput {
  connect?: MetadataEditionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataEditionListUpdateInput[] | null;
  create?: MetadataEditionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataEditionListUpdateInput[] | null;
  delete?: MetadataEditionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataEditorialVersionListCreateInput {
  connect?: MetadataEditorialVersionWhereUniqueInput[] | null;
  create?: MetadataEditorialVersionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataEditorialVersionListUpdateInput {
  connect?: MetadataEditorialVersionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataEditorialVersionListUpdateInput[] | null;
  create?: MetadataEditorialVersionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataEditorialVersionListUpdateInput[] | null;
  delete?: MetadataEditorialVersionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataEnvironnementPhotoListCreateInput {
  connect?: MetadataEnvironnementPhotoWhereUniqueInput[] | null;
  create?: MetadataEnvironnementPhotoWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataEnvironnementPhotoListUpdateInput {
  connect?: MetadataEnvironnementPhotoWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataEnvironnementPhotoListUpdateInput[] | null;
  create?: MetadataEnvironnementPhotoWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataEnvironnementPhotoListUpdateInput[] | null;
  delete?: MetadataEnvironnementPhotoWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataEquipmentInstitutionListCreateInput {
  connect?: MetadataEquipmentInstitutionWhereUniqueInput[] | null;
  create?: MetadataEquipmentInstitutionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataEquipmentInstitutionListUpdateInput {
  connect?: MetadataEquipmentInstitutionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataEquipmentInstitutionListUpdateInput[] | null;
  create?: MetadataEquipmentInstitutionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataEquipmentInstitutionListUpdateInput[] | null;
  delete?: MetadataEquipmentInstitutionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataEquipmentManufacturerListCreateInput {
  connect?: MetadataEquipmentManufacturerWhereUniqueInput[] | null;
  create?: MetadataEquipmentManufacturerWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataEquipmentManufacturerListUpdateInput {
  connect?: MetadataEquipmentManufacturerWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataEquipmentManufacturerListUpdateInput[] | null;
  create?: MetadataEquipmentManufacturerWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataEquipmentManufacturerListUpdateInput[] | null;
  delete?: MetadataEquipmentManufacturerWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataEventListCreateInput {
  connect?: MetadataEventWhereUniqueInput[] | null;
  create?: MetadataEventWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataEventListUpdateInput {
  connect?: MetadataEventWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataEventListUpdateInput[] | null;
  create?: MetadataEventWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataEventListUpdateInput[] | null;
  delete?: MetadataEventWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataExifListCreateInput {
  connect?: MetadataExifWhereUniqueInput[] | null;
  create?: MetadataExifWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataExifListUpdateInput {
  connect?: MetadataExifWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataExifListUpdateInput[] | null;
  create?: MetadataExifWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataExifListUpdateInput[] | null;
  delete?: MetadataExifWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataFabStorageListCreateInput {
  connect?: MetadataFabStorageWhereUniqueInput[] | null;
  create?: MetadataFabStorageWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataFabStorageListUpdateInput {
  connect?: MetadataFabStorageWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataFabStorageListUpdateInput[] | null;
  create?: MetadataFabStorageWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataFabStorageListUpdateInput[] | null;
  delete?: MetadataFabStorageWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataFileCheckForListCreateInput {
  connect?: MetadataFileCheckForWhereUniqueInput[] | null;
  create?: MetadataFileCheckForWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataFileCheckForListUpdateInput {
  connect?: MetadataFileCheckForWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataFileCheckForListUpdateInput[] | null;
  create?: MetadataFileCheckForWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataFileCheckForListUpdateInput[] | null;
  delete?: MetadataFileCheckForWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataFileInfoBitsPerPixelListCreateInput {
  connect?: MetadataFileInfoBitsPerPixelWhereUniqueInput[] | null;
  create?: MetadataFileInfoBitsPerPixelWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataFileInfoBitsPerPixelListUpdateInput {
  connect?: MetadataFileInfoBitsPerPixelWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataFileInfoBitsPerPixelListUpdateInput[] | null;
  create?: MetadataFileInfoBitsPerPixelWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataFileInfoBitsPerPixelListUpdateInput[] | null;
  delete?: MetadataFileInfoBitsPerPixelWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataFileInfoFramesListCreateInput {
  connect?: MetadataFileInfoFramesWhereUniqueInput[] | null;
  create?: MetadataFileInfoFramesWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataFileInfoFramesListUpdateInput {
  connect?: MetadataFileInfoFramesWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataFileInfoFramesListUpdateInput[] | null;
  create?: MetadataFileInfoFramesWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataFileInfoFramesListUpdateInput[] | null;
  delete?: MetadataFileInfoFramesWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataFileInfoPhotometricInterpretationListCreateInput {
  connect?: MetadataFileInfoPhotometricInterpretationWhereUniqueInput[] | null;
  create?: MetadataFileInfoPhotometricInterpretationWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataFileInfoPhotometricInterpretationListUpdateInput {
  connect?: MetadataFileInfoPhotometricInterpretationWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataFileInfoPhotometricInterpretationListUpdateInput[] | null;
  create?: MetadataFileInfoPhotometricInterpretationWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataFileInfoPhotometricInterpretationListUpdateInput[] | null;
  delete?: MetadataFileInfoPhotometricInterpretationWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataFileInfoTransferSyntaxListCreateInput {
  connect?: MetadataFileInfoTransferSyntaxWhereUniqueInput[] | null;
  create?: MetadataFileInfoTransferSyntaxWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataFileInfoTransferSyntaxListUpdateInput {
  connect?: MetadataFileInfoTransferSyntaxWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataFileInfoTransferSyntaxListUpdateInput[] | null;
  create?: MetadataFileInfoTransferSyntaxWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataFileInfoTransferSyntaxListUpdateInput[] | null;
  delete?: MetadataFileInfoTransferSyntaxWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataFileStageListCreateInput {
  connect?: MetadataFileStageWhereUniqueInput[] | null;
  create?: MetadataFileStageWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataFileStageListUpdateInput {
  connect?: MetadataFileStageWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataFileStageListUpdateInput[] | null;
  create?: MetadataFileStageWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataFileStageListUpdateInput[] | null;
  delete?: MetadataFileStageWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataFileStatusListCreateInput {
  connect?: MetadataFileStatusWhereUniqueInput[] | null;
  create?: MetadataFileStatusWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataFileStatusListUpdateInput {
  connect?: MetadataFileStatusWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataFileStatusListUpdateInput[] | null;
  create?: MetadataFileStatusWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataFileStatusListUpdateInput[] | null;
  delete?: MetadataFileStatusWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataFileTypeListCreateInput {
  connect?: MetadataFileTypeWhereUniqueInput[] | null;
  create?: MetadataFileTypeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataFileTypeListUpdateInput {
  connect?: MetadataFileTypeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataFileTypeListUpdateInput[] | null;
  create?: MetadataFileTypeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataFileTypeListUpdateInput[] | null;
  delete?: MetadataFileTypeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataFixtureIdentifierListCreateInput {
  connect?: MetadataFixtureIdentifierWhereUniqueInput[] | null;
  create?: MetadataFixtureIdentifierWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataFixtureIdentifierListUpdateInput {
  connect?: MetadataFixtureIdentifierWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataFixtureIdentifierListUpdateInput[] | null;
  create?: MetadataFixtureIdentifierWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataFixtureIdentifierListUpdateInput[] | null;
  delete?: MetadataFixtureIdentifierWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataFormatListCreateInput {
  connect?: MetadataFormatWhereUniqueInput[] | null;
  create?: MetadataFormatWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataFormatListUpdateInput {
  connect?: MetadataFormatWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataFormatListUpdateInput[] | null;
  create?: MetadataFormatWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataFormatListUpdateInput[] | null;
  delete?: MetadataFormatWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataGpsLatitudeListCreateInput {
  connect?: MetadataGpsLatitudeWhereUniqueInput[] | null;
  create?: MetadataGpsLatitudeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataGpsLatitudeListUpdateInput {
  connect?: MetadataGpsLatitudeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataGpsLatitudeListUpdateInput[] | null;
  create?: MetadataGpsLatitudeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataGpsLatitudeListUpdateInput[] | null;
  delete?: MetadataGpsLatitudeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataGpsLongitudeListCreateInput {
  connect?: MetadataGpsLongitudeWhereUniqueInput[] | null;
  create?: MetadataGpsLongitudeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataGpsLongitudeListUpdateInput {
  connect?: MetadataGpsLongitudeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataGpsLongitudeListUpdateInput[] | null;
  create?: MetadataGpsLongitudeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataGpsLongitudeListUpdateInput[] | null;
  delete?: MetadataGpsLongitudeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataGpsProcessingMethodListCreateInput {
  connect?: MetadataGpsProcessingMethodWhereUniqueInput[] | null;
  create?: MetadataGpsProcessingMethodWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataGpsProcessingMethodListUpdateInput {
  connect?: MetadataGpsProcessingMethodWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataGpsProcessingMethodListUpdateInput[] | null;
  create?: MetadataGpsProcessingMethodWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataGpsProcessingMethodListUpdateInput[] | null;
  delete?: MetadataGpsProcessingMethodWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataHeaderListCreateInput {
  connect?: MetadataHeaderWhereUniqueInput[] | null;
  create?: MetadataHeaderWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataHeaderListUpdateInput {
  connect?: MetadataHeaderWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataHeaderListUpdateInput[] | null;
  create?: MetadataHeaderWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataHeaderListUpdateInput[] | null;
  delete?: MetadataHeaderWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataHeaderPrismaListCreateInput {
  connect?: MetadataHeaderPrismaWhereUniqueInput[] | null;
  create?: MetadataHeaderPrismaWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataHeaderPrismaListUpdateInput {
  connect?: MetadataHeaderPrismaWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataHeaderPrismaListUpdateInput[] | null;
  create?: MetadataHeaderPrismaWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataHeaderPrismaListUpdateInput[] | null;
  delete?: MetadataHeaderPrismaWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataHeadlineListCreateInput {
  connect?: MetadataHeadlineWhereUniqueInput[] | null;
  create?: MetadataHeadlineWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataHeadlineListUpdateInput {
  connect?: MetadataHeadlineWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataHeadlineListUpdateInput[] | null;
  create?: MetadataHeadlineWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataHeadlineListUpdateInput[] | null;
  delete?: MetadataHeadlineWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataHistoryListCreateInput {
  connect?: MetadataHistoryWhereUniqueInput[] | null;
  create?: MetadataHistoryWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataHistoryListUpdateInput {
  connect?: MetadataHistoryWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataHistoryListUpdateInput[] | null;
  create?: MetadataHistoryWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataHistoryListUpdateInput[] | null;
  delete?: MetadataHistoryWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataIccProfileListCreateInput {
  connect?: MetadataIccProfileWhereUniqueInput[] | null;
  create?: MetadataIccProfileWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataIccProfileListUpdateInput {
  connect?: MetadataIccProfileWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataIccProfileListUpdateInput[] | null;
  create?: MetadataIccProfileWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataIccProfileListUpdateInput[] | null;
  delete?: MetadataIccProfileWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataIdAssignmentListCreateInput {
  connect?: MetadataIdAssignmentWhereUniqueInput[] | null;
  create?: MetadataIdAssignmentWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataIdAssignmentListUpdateInput {
  connect?: MetadataIdAssignmentWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataIdAssignmentListUpdateInput[] | null;
  create?: MetadataIdAssignmentWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataIdAssignmentListUpdateInput[] | null;
  delete?: MetadataIdAssignmentWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataIdMediaContentListCreateInput {
  connect?: MetadataIdMediaContentWhereUniqueInput[] | null;
  create?: MetadataIdMediaContentWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataIdMediaContentListUpdateInput {
  connect?: MetadataIdMediaContentWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataIdMediaContentListUpdateInput[] | null;
  create?: MetadataIdMediaContentWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataIdMediaContentListUpdateInput[] | null;
  delete?: MetadataIdMediaContentWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataIdPhotoPrismaListCreateInput {
  connect?: MetadataIdPhotoPrismaWhereUniqueInput[] | null;
  create?: MetadataIdPhotoPrismaWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataIdPhotoPrismaListUpdateInput {
  connect?: MetadataIdPhotoPrismaWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataIdPhotoPrismaListUpdateInput[] | null;
  create?: MetadataIdPhotoPrismaWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataIdPhotoPrismaListUpdateInput[] | null;
  delete?: MetadataIdPhotoPrismaWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataIdSubjectListCreateInput {
  connect?: MetadataIdSubjectWhereUniqueInput[] | null;
  create?: MetadataIdSubjectWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataIdSubjectListUpdateInput {
  connect?: MetadataIdSubjectWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataIdSubjectListUpdateInput[] | null;
  create?: MetadataIdSubjectWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataIdSubjectListUpdateInput[] | null;
  delete?: MetadataIdSubjectWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataImageNotesListCreateInput {
  connect?: MetadataImageNotesWhereUniqueInput[] | null;
  create?: MetadataImageNotesWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataImageNotesListUpdateInput {
  connect?: MetadataImageNotesWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataImageNotesListUpdateInput[] | null;
  create?: MetadataImageNotesWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataImageNotesListUpdateInput[] | null;
  delete?: MetadataImageNotesWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataImageUniqueIdListCreateInput {
  connect?: MetadataImageUniqueIdWhereUniqueInput[] | null;
  create?: MetadataImageUniqueIdWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataImageUniqueIdListUpdateInput {
  connect?: MetadataImageUniqueIdWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataImageUniqueIdListUpdateInput[] | null;
  create?: MetadataImageUniqueIdWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataImageUniqueIdListUpdateInput[] | null;
  delete?: MetadataImageUniqueIdWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataInstructionsListCreateInput {
  connect?: MetadataInstructionsWhereUniqueInput[] | null;
  create?: MetadataInstructionsWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataInstructionsListUpdateInput {
  connect?: MetadataInstructionsWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataInstructionsListUpdateInput[] | null;
  create?: MetadataInstructionsWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataInstructionsListUpdateInput[] | null;
  delete?: MetadataInstructionsWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataIntellectualGenreListCreateInput {
  connect?: MetadataIntellectualGenreWhereUniqueInput[] | null;
  create?: MetadataIntellectualGenreWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataIntellectualGenreListUpdateInput {
  connect?: MetadataIntellectualGenreWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataIntellectualGenreListUpdateInput[] | null;
  create?: MetadataIntellectualGenreWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataIntellectualGenreListUpdateInput[] | null;
  delete?: MetadataIntellectualGenreWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataIsoSpeedRatingsListCreateInput {
  connect?: MetadataIsoSpeedRatingsWhereUniqueInput[] | null;
  create?: MetadataIsoSpeedRatingsWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataIsoSpeedRatingsListUpdateInput {
  connect?: MetadataIsoSpeedRatingsWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataIsoSpeedRatingsListUpdateInput[] | null;
  create?: MetadataIsoSpeedRatingsWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataIsoSpeedRatingsListUpdateInput[] | null;
  delete?: MetadataIsoSpeedRatingsWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataIssueListCreateInput {
  connect?: MetadataIssueWhereUniqueInput[] | null;
  create?: MetadataIssueWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataIssueListUpdateInput {
  connect?: MetadataIssueWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataIssueListUpdateInput[] | null;
  create?: MetadataIssueWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataIssueListUpdateInput[] | null;
  delete?: MetadataIssueWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataJobIdListCreateInput {
  connect?: MetadataJobIdWhereUniqueInput[] | null;
  create?: MetadataJobIdWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataJobIdListUpdateInput {
  connect?: MetadataJobIdWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataJobIdListUpdateInput[] | null;
  create?: MetadataJobIdWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataJobIdListUpdateInput[] | null;
  delete?: MetadataJobIdWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataJobProcessingListCreateInput {
  connect?: MetadataJobProcessingWhereUniqueInput[] | null;
  create?: MetadataJobProcessingWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataJobProcessingListUpdateInput {
  connect?: MetadataJobProcessingWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataJobProcessingListUpdateInput[] | null;
  create?: MetadataJobProcessingWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataJobProcessingListUpdateInput[] | null;
  delete?: MetadataJobProcessingWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataLayoutPictureEditingListCreateInput {
  connect?: MetadataLayoutPictureEditingWhereUniqueInput[] | null;
  create?: MetadataLayoutPictureEditingWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataLayoutPictureEditingListUpdateInput {
  connect?: MetadataLayoutPictureEditingWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataLayoutPictureEditingListUpdateInput[] | null;
  create?: MetadataLayoutPictureEditingWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataLayoutPictureEditingListUpdateInput[] | null;
  delete?: MetadataLayoutPictureEditingWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataLayoutStorageListCreateInput {
  connect?: MetadataLayoutStorageWhereUniqueInput[] | null;
  create?: MetadataLayoutStorageWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataLayoutStorageListUpdateInput {
  connect?: MetadataLayoutStorageWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataLayoutStorageListUpdateInput[] | null;
  create?: MetadataLayoutStorageWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataLayoutStorageListUpdateInput[] | null;
  delete?: MetadataLayoutStorageWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataLicenseListCreateInput {
  connect?: MetadataLicenseWhereUniqueInput[] | null;
  create?: MetadataLicenseWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataLicenseListUpdateInput {
  connect?: MetadataLicenseWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataLicenseListUpdateInput[] | null;
  create?: MetadataLicenseWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataLicenseListUpdateInput[] | null;
  delete?: MetadataLicenseWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataLinksListCreateInput {
  connect?: MetadataLinksWhereUniqueInput[] | null;
  create?: MetadataLinksWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataLinksListUpdateInput {
  connect?: MetadataLinksWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataLinksListUpdateInput[] | null;
  create?: MetadataLinksWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataLinksListUpdateInput[] | null;
  delete?: MetadataLinksWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataLocalCaptionListCreateInput {
  connect?: MetadataLocalCaptionWhereUniqueInput[] | null;
  create?: MetadataLocalCaptionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataLocalCaptionListUpdateInput {
  connect?: MetadataLocalCaptionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataLocalCaptionListUpdateInput[] | null;
  create?: MetadataLocalCaptionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataLocalCaptionListUpdateInput[] | null;
  delete?: MetadataLocalCaptionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataLocationListCreateInput {
  connect?: MetadataLocationWhereUniqueInput[] | null;
  create?: MetadataLocationWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataLocationListUpdateInput {
  connect?: MetadataLocationWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataLocationListUpdateInput[] | null;
  create?: MetadataLocationWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataLocationListUpdateInput[] | null;
  delete?: MetadataLocationWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataMakeListCreateInput {
  connect?: MetadataMakeWhereUniqueInput[] | null;
  create?: MetadataMakeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataMakeListUpdateInput {
  connect?: MetadataMakeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataMakeListUpdateInput[] | null;
  create?: MetadataMakeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataMakeListUpdateInput[] | null;
  delete?: MetadataMakeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataManifestListCreateInput {
  connect?: MetadataManifestWhereUniqueInput[] | null;
  create?: MetadataManifestWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataManifestListUpdateInput {
  connect?: MetadataManifestWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataManifestListUpdateInput[] | null;
  create?: MetadataManifestWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataManifestListUpdateInput[] | null;
  delete?: MetadataManifestWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataMarkedListCreateInput {
  connect?: MetadataMarkedWhereUniqueInput[] | null;
  create?: MetadataMarkedWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataMarkedListUpdateInput {
  connect?: MetadataMarkedWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataMarkedListUpdateInput[] | null;
  create?: MetadataMarkedWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataMarkedListUpdateInput[] | null;
  delete?: MetadataMarkedWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataMasterDocumentIdListCreateInput {
  connect?: MetadataMasterDocumentIdWhereUniqueInput[] | null;
  create?: MetadataMasterDocumentIdWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataMasterDocumentIdListUpdateInput {
  connect?: MetadataMasterDocumentIdWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataMasterDocumentIdListUpdateInput[] | null;
  create?: MetadataMasterDocumentIdWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataMasterDocumentIdListUpdateInput[] | null;
  delete?: MetadataMasterDocumentIdWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataMaxAvailHeightListCreateInput {
  connect?: MetadataMaxAvailHeightWhereUniqueInput[] | null;
  create?: MetadataMaxAvailHeightWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataMaxAvailHeightListUpdateInput {
  connect?: MetadataMaxAvailHeightWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataMaxAvailHeightListUpdateInput[] | null;
  create?: MetadataMaxAvailHeightWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataMaxAvailHeightListUpdateInput[] | null;
  delete?: MetadataMaxAvailHeightWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataMaxAvailWidthListCreateInput {
  connect?: MetadataMaxAvailWidthWhereUniqueInput[] | null;
  create?: MetadataMaxAvailWidthWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataMaxAvailWidthListUpdateInput {
  connect?: MetadataMaxAvailWidthWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataMaxAvailWidthListUpdateInput[] | null;
  create?: MetadataMaxAvailWidthWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataMaxAvailWidthListUpdateInput[] | null;
  delete?: MetadataMaxAvailWidthWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataMinorModelAgeDisclosureListCreateInput {
  connect?: MetadataMinorModelAgeDisclosureWhereUniqueInput[] | null;
  create?: MetadataMinorModelAgeDisclosureWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataMinorModelAgeDisclosureListUpdateInput {
  connect?: MetadataMinorModelAgeDisclosureWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataMinorModelAgeDisclosureListUpdateInput[] | null;
  create?: MetadataMinorModelAgeDisclosureWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataMinorModelAgeDisclosureListUpdateInput[] | null;
  delete?: MetadataMinorModelAgeDisclosureWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataModelAgeListCreateInput {
  connect?: MetadataModelAgeWhereUniqueInput[] | null;
  create?: MetadataModelAgeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataModelAgeListUpdateInput {
  connect?: MetadataModelAgeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataModelAgeListUpdateInput[] | null;
  create?: MetadataModelAgeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataModelAgeListUpdateInput[] | null;
  delete?: MetadataModelAgeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataModelListCreateInput {
  connect?: MetadataModelWhereUniqueInput[] | null;
  create?: MetadataModelWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataModelListUpdateInput {
  connect?: MetadataModelWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataModelListUpdateInput[] | null;
  create?: MetadataModelWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataModelListUpdateInput[] | null;
  delete?: MetadataModelWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataModelReleaseIdListCreateInput {
  connect?: MetadataModelReleaseIdWhereUniqueInput[] | null;
  create?: MetadataModelReleaseIdWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataModelReleaseIdListUpdateInput {
  connect?: MetadataModelReleaseIdWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataModelReleaseIdListUpdateInput[] | null;
  create?: MetadataModelReleaseIdWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataModelReleaseIdListUpdateInput[] | null;
  delete?: MetadataModelReleaseIdWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataModelReleaseStatusListCreateInput {
  connect?: MetadataModelReleaseStatusWhereUniqueInput[] | null;
  create?: MetadataModelReleaseStatusWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataModelReleaseStatusListUpdateInput {
  connect?: MetadataModelReleaseStatusWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataModelReleaseStatusListUpdateInput[] | null;
  create?: MetadataModelReleaseStatusWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataModelReleaseStatusListUpdateInput[] | null;
  delete?: MetadataModelReleaseStatusWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataMorePermissionsListCreateInput {
  connect?: MetadataMorePermissionsWhereUniqueInput[] | null;
  create?: MetadataMorePermissionsWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataMorePermissionsListUpdateInput {
  connect?: MetadataMorePermissionsWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataMorePermissionsListUpdateInput[] | null;
  create?: MetadataMorePermissionsWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataMorePermissionsListUpdateInput[] | null;
  delete?: MetadataMorePermissionsWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataObjectCycleListCreateInput {
  connect?: MetadataObjectCycleWhereUniqueInput[] | null;
  create?: MetadataObjectCycleWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataObjectCycleListUpdateInput {
  connect?: MetadataObjectCycleWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataObjectCycleListUpdateInput[] | null;
  create?: MetadataObjectCycleWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataObjectCycleListUpdateInput[] | null;
  delete?: MetadataObjectCycleWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataOrganisationInImageNameListCreateInput {
  connect?: MetadataOrganisationInImageNameWhereUniqueInput[] | null;
  create?: MetadataOrganisationInImageNameWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataOrganisationInImageNameListUpdateInput {
  connect?: MetadataOrganisationInImageNameWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataOrganisationInImageNameListUpdateInput[] | null;
  create?: MetadataOrganisationInImageNameWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataOrganisationInImageNameListUpdateInput[] | null;
  delete?: MetadataOrganisationInImageNameWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataOriginalColorListCreateInput {
  connect?: MetadataOriginalColorWhereUniqueInput[] | null;
  create?: MetadataOriginalColorWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataOriginalColorListUpdateInput {
  connect?: MetadataOriginalColorWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataOriginalColorListUpdateInput[] | null;
  create?: MetadataOriginalColorWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataOriginalColorListUpdateInput[] | null;
  delete?: MetadataOriginalColorWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataOriginalIccProfileListCreateInput {
  connect?: MetadataOriginalIccProfileWhereUniqueInput[] | null;
  create?: MetadataOriginalIccProfileWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataOriginalIccProfileListUpdateInput {
  connect?: MetadataOriginalIccProfileWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataOriginalIccProfileListUpdateInput[] | null;
  create?: MetadataOriginalIccProfileWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataOriginalIccProfileListUpdateInput[] | null;
  delete?: MetadataOriginalIccProfileWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataOriginalNameListCreateInput {
  connect?: MetadataOriginalNameWhereUniqueInput[] | null;
  create?: MetadataOriginalNameWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataOriginalNameListUpdateInput {
  connect?: MetadataOriginalNameWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataOriginalNameListUpdateInput[] | null;
  create?: MetadataOriginalNameWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataOriginalNameListUpdateInput[] | null;
  delete?: MetadataOriginalNameWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataOriginalWeigthListCreateInput {
  connect?: MetadataOriginalWeigthWhereUniqueInput[] | null;
  create?: MetadataOriginalWeigthWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataOriginalWeigthListUpdateInput {
  connect?: MetadataOriginalWeigthWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataOriginalWeigthListUpdateInput[] | null;
  create?: MetadataOriginalWeigthWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataOriginalWeigthListUpdateInput[] | null;
  delete?: MetadataOriginalWeigthWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataOwnerIdListCreateInput {
  connect?: MetadataOwnerIdWhereUniqueInput[] | null;
  create?: MetadataOwnerIdWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataOwnerIdListUpdateInput {
  connect?: MetadataOwnerIdWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataOwnerIdListUpdateInput[] | null;
  create?: MetadataOwnerIdWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataOwnerIdListUpdateInput[] | null;
  delete?: MetadataOwnerIdWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataOwnerListCreateInput {
  connect?: MetadataOwnerWhereUniqueInput[] | null;
  create?: MetadataOwnerWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataOwnerListUpdateInput {
  connect?: MetadataOwnerWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataOwnerListUpdateInput[] | null;
  create?: MetadataOwnerWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataOwnerListUpdateInput[] | null;
  delete?: MetadataOwnerWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPageListCreateInput {
  connect?: MetadataPageWhereUniqueInput[] | null;
  create?: MetadataPageWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPageListUpdateInput {
  connect?: MetadataPageWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPageListUpdateInput[] | null;
  create?: MetadataPageWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPageListUpdateInput[] | null;
  delete?: MetadataPageWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPagePrismaListCreateInput {
  connect?: MetadataPagePrismaWhereUniqueInput[] | null;
  create?: MetadataPagePrismaWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPagePrismaListUpdateInput {
  connect?: MetadataPagePrismaWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPagePrismaListUpdateInput[] | null;
  create?: MetadataPagePrismaWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPagePrismaListUpdateInput[] | null;
  delete?: MetadataPagePrismaWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPaintBasedCorrectionsListCreateInput {
  connect?: MetadataPaintBasedCorrectionsWhereUniqueInput[] | null;
  create?: MetadataPaintBasedCorrectionsWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPaintBasedCorrectionsListUpdateInput {
  connect?: MetadataPaintBasedCorrectionsWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPaintBasedCorrectionsListUpdateInput[] | null;
  create?: MetadataPaintBasedCorrectionsWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPaintBasedCorrectionsListUpdateInput[] | null;
  delete?: MetadataPaintBasedCorrectionsWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPatientDobListCreateInput {
  connect?: MetadataPatientDobWhereUniqueInput[] | null;
  create?: MetadataPatientDobWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPatientDobListUpdateInput {
  connect?: MetadataPatientDobWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPatientDobListUpdateInput[] | null;
  create?: MetadataPatientDobWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPatientDobListUpdateInput[] | null;
  delete?: MetadataPatientDobWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPatientIdListCreateInput {
  connect?: MetadataPatientIdWhereUniqueInput[] | null;
  create?: MetadataPatientIdWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPatientIdListUpdateInput {
  connect?: MetadataPatientIdWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPatientIdListUpdateInput[] | null;
  create?: MetadataPatientIdWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPatientIdListUpdateInput[] | null;
  delete?: MetadataPatientIdWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPatientNameListCreateInput {
  connect?: MetadataPatientNameWhereUniqueInput[] | null;
  create?: MetadataPatientNameWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPatientNameListUpdateInput {
  connect?: MetadataPatientNameWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPatientNameListUpdateInput[] | null;
  create?: MetadataPatientNameWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPatientNameListUpdateInput[] | null;
  delete?: MetadataPatientNameWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPatientSexListCreateInput {
  connect?: MetadataPatientSexWhereUniqueInput[] | null;
  create?: MetadataPatientSexWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPatientSexListUpdateInput {
  connect?: MetadataPatientSexWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPatientSexListUpdateInput[] | null;
  create?: MetadataPatientSexWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPatientSexListUpdateInput[] | null;
  delete?: MetadataPatientSexWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPersonInImageListCreateInput {
  connect?: MetadataPersonInImageWhereUniqueInput[] | null;
  create?: MetadataPersonInImageWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPersonInImageListUpdateInput {
  connect?: MetadataPersonInImageWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPersonInImageListUpdateInput[] | null;
  create?: MetadataPersonInImageWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPersonInImageListUpdateInput[] | null;
  delete?: MetadataPersonInImageWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPhotoStorageListCreateInput {
  connect?: MetadataPhotoStorageWhereUniqueInput[] | null;
  create?: MetadataPhotoStorageWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPhotoStorageListUpdateInput {
  connect?: MetadataPhotoStorageWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPhotoStorageListUpdateInput[] | null;
  create?: MetadataPhotoStorageWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPhotoStorageListUpdateInput[] | null;
  delete?: MetadataPhotoStorageWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPrepressPictureEditingListCreateInput {
  connect?: MetadataPrepressPictureEditingWhereUniqueInput[] | null;
  create?: MetadataPrepressPictureEditingWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPrepressPictureEditingListUpdateInput {
  connect?: MetadataPrepressPictureEditingWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPrepressPictureEditingListUpdateInput[] | null;
  create?: MetadataPrepressPictureEditingWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPrepressPictureEditingListUpdateInput[] | null;
  delete?: MetadataPrepressPictureEditingWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPriceLevelListCreateInput {
  connect?: MetadataPriceLevelWhereUniqueInput[] | null;
  create?: MetadataPriceLevelWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPriceLevelListUpdateInput {
  connect?: MetadataPriceLevelWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPriceLevelListUpdateInput[] | null;
  create?: MetadataPriceLevelWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPriceLevelListUpdateInput[] | null;
  delete?: MetadataPriceLevelWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPrintingProfileListCreateInput {
  connect?: MetadataPrintingProfileWhereUniqueInput[] | null;
  create?: MetadataPrintingProfileWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPrintingProfileListUpdateInput {
  connect?: MetadataPrintingProfileWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPrintingProfileListUpdateInput[] | null;
  create?: MetadataPrintingProfileWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPrintingProfileListUpdateInput[] | null;
  delete?: MetadataPrintingProfileWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPrismaProductionListCreateInput {
  connect?: MetadataPrismaProductionWhereUniqueInput[] | null;
  create?: MetadataPrismaProductionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPrismaProductionListUpdateInput {
  connect?: MetadataPrismaProductionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPrismaProductionListUpdateInput[] | null;
  create?: MetadataPrismaProductionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPrismaProductionListUpdateInput[] | null;
  delete?: MetadataPrismaProductionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataProcessHistoryListCreateInput {
  connect?: MetadataProcessHistoryWhereUniqueInput[] | null;
  create?: MetadataProcessHistoryWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataProcessHistoryListUpdateInput {
  connect?: MetadataProcessHistoryWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataProcessHistoryListUpdateInput[] | null;
  create?: MetadataProcessHistoryWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataProcessHistoryListUpdateInput[] | null;
  delete?: MetadataProcessHistoryWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataProcessParameterListCreateInput {
  connect?: MetadataProcessParameterWhereUniqueInput[] | null;
  create?: MetadataProcessParameterWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataProcessParameterListUpdateInput {
  connect?: MetadataProcessParameterWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataProcessParameterListUpdateInput[] | null;
  create?: MetadataProcessParameterWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataProcessParameterListUpdateInput[] | null;
  delete?: MetadataProcessParameterWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataProcessStatusListCreateInput {
  connect?: MetadataProcessStatusWhereUniqueInput[] | null;
  create?: MetadataProcessStatusWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataProcessStatusListUpdateInput {
  connect?: MetadataProcessStatusWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataProcessStatusListUpdateInput[] | null;
  create?: MetadataProcessStatusWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataProcessStatusListUpdateInput[] | null;
  delete?: MetadataProcessStatusWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataProductListCreateInput {
  connect?: MetadataProductWhereUniqueInput[] | null;
  create?: MetadataProductWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataProductListUpdateInput {
  connect?: MetadataProductWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataProductListUpdateInput[] | null;
  create?: MetadataProductWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataProductListUpdateInput[] | null;
  delete?: MetadataProductWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataProductShortNameListCreateInput {
  connect?: MetadataProductShortNameWhereUniqueInput[] | null;
  create?: MetadataProductShortNameWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataProductShortNameListUpdateInput {
  connect?: MetadataProductShortNameWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataProductShortNameListUpdateInput[] | null;
  create?: MetadataProductShortNameWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataProductShortNameListUpdateInput[] | null;
  delete?: MetadataProductShortNameWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataProductsListCreateInput {
  connect?: MetadataProductsWhereUniqueInput[] | null;
  create?: MetadataProductsWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataProductsListUpdateInput {
  connect?: MetadataProductsWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataProductsListUpdateInput[] | null;
  create?: MetadataProductsWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataProductsListUpdateInput[] | null;
  delete?: MetadataProductsWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataProduitsListCreateInput {
  connect?: MetadataProduitsWhereUniqueInput[] | null;
  create?: MetadataProduitsWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataProduitsListUpdateInput {
  connect?: MetadataProduitsWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataProduitsListUpdateInput[] | null;
  create?: MetadataProduitsWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataProduitsListUpdateInput[] | null;
  delete?: MetadataProduitsWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataProgramVersionListCreateInput {
  connect?: MetadataProgramVersionWhereUniqueInput[] | null;
  create?: MetadataProgramVersionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataProgramVersionListUpdateInput {
  connect?: MetadataProgramVersionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataProgramVersionListUpdateInput[] | null;
  create?: MetadataProgramVersionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataProgramVersionListUpdateInput[] | null;
  delete?: MetadataProgramVersionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPropertyReleaseIdListCreateInput {
  connect?: MetadataPropertyReleaseIdWhereUniqueInput[] | null;
  create?: MetadataPropertyReleaseIdWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPropertyReleaseIdListUpdateInput {
  connect?: MetadataPropertyReleaseIdWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPropertyReleaseIdListUpdateInput[] | null;
  create?: MetadataPropertyReleaseIdWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPropertyReleaseIdListUpdateInput[] | null;
  delete?: MetadataPropertyReleaseIdWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPropertyReleaseStatusListCreateInput {
  connect?: MetadataPropertyReleaseStatusWhereUniqueInput[] | null;
  create?: MetadataPropertyReleaseStatusWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPropertyReleaseStatusListUpdateInput {
  connect?: MetadataPropertyReleaseStatusWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPropertyReleaseStatusListUpdateInput[] | null;
  create?: MetadataPropertyReleaseStatusWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPropertyReleaseStatusListUpdateInput[] | null;
  delete?: MetadataPropertyReleaseStatusWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPublisherListCreateInput {
  connect?: MetadataPublisherWhereUniqueInput[] | null;
  create?: MetadataPublisherWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPublisherListUpdateInput {
  connect?: MetadataPublisherWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPublisherListUpdateInput[] | null;
  create?: MetadataPublisherWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPublisherListUpdateInput[] | null;
  delete?: MetadataPublisherWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPublishingIssueListCreateInput {
  connect?: MetadataPublishingIssueWhereUniqueInput[] | null;
  create?: MetadataPublishingIssueWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPublishingIssueListUpdateInput {
  connect?: MetadataPublishingIssueWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPublishingIssueListUpdateInput[] | null;
  create?: MetadataPublishingIssueWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPublishingIssueListUpdateInput[] | null;
  delete?: MetadataPublishingIssueWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataPublishingSubjectListCreateInput {
  connect?: MetadataPublishingSubjectWhereUniqueInput[] | null;
  create?: MetadataPublishingSubjectWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataPublishingSubjectListUpdateInput {
  connect?: MetadataPublishingSubjectWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataPublishingSubjectListUpdateInput[] | null;
  create?: MetadataPublishingSubjectWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataPublishingSubjectListUpdateInput[] | null;
  delete?: MetadataPublishingSubjectWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataQualifiedUsePrismaByListCreateInput {
  connect?: MetadataQualifiedUsePrismaByWhereUniqueInput[] | null;
  create?: MetadataQualifiedUsePrismaByWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataQualifiedUsePrismaByListUpdateInput {
  connect?: MetadataQualifiedUsePrismaByWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataQualifiedUsePrismaByListUpdateInput[] | null;
  create?: MetadataQualifiedUsePrismaByWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataQualifiedUsePrismaByListUpdateInput[] | null;
  delete?: MetadataQualifiedUsePrismaByWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataQualifiedUsePrismaDateListCreateInput {
  connect?: MetadataQualifiedUsePrismaDateWhereUniqueInput[] | null;
  create?: MetadataQualifiedUsePrismaDateWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataQualifiedUsePrismaDateListUpdateInput {
  connect?: MetadataQualifiedUsePrismaDateWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataQualifiedUsePrismaDateListUpdateInput[] | null;
  create?: MetadataQualifiedUsePrismaDateWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataQualifiedUsePrismaDateListUpdateInput[] | null;
  delete?: MetadataQualifiedUsePrismaDateWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataQualifiedUsePrismaDurationListCreateInput {
  connect?: MetadataQualifiedUsePrismaDurationWhereUniqueInput[] | null;
  create?: MetadataQualifiedUsePrismaDurationWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataQualifiedUsePrismaDurationListUpdateInput {
  connect?: MetadataQualifiedUsePrismaDurationWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataQualifiedUsePrismaDurationListUpdateInput[] | null;
  create?: MetadataQualifiedUsePrismaDurationWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataQualifiedUsePrismaDurationListUpdateInput[] | null;
  delete?: MetadataQualifiedUsePrismaDurationWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataQualifiedUsePrismaSupportListCreateInput {
  connect?: MetadataQualifiedUsePrismaSupportWhereUniqueInput[] | null;
  create?: MetadataQualifiedUsePrismaSupportWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataQualifiedUsePrismaSupportListUpdateInput {
  connect?: MetadataQualifiedUsePrismaSupportWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataQualifiedUsePrismaSupportListUpdateInput[] | null;
  create?: MetadataQualifiedUsePrismaSupportWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataQualifiedUsePrismaSupportListUpdateInput[] | null;
  delete?: MetadataQualifiedUsePrismaSupportWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataRatingListCreateInput {
  connect?: MetadataRatingWhereUniqueInput[] | null;
  create?: MetadataRatingWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataRatingListUpdateInput {
  connect?: MetadataRatingWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataRatingListUpdateInput[] | null;
  create?: MetadataRatingWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataRatingListUpdateInput[] | null;
  delete?: MetadataRatingWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataReferenceDateListCreateInput {
  connect?: MetadataReferenceDateWhereUniqueInput[] | null;
  create?: MetadataReferenceDateWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataReferenceDateListUpdateInput {
  connect?: MetadataReferenceDateWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataReferenceDateListUpdateInput[] | null;
  create?: MetadataReferenceDateWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataReferenceDateListUpdateInput[] | null;
  delete?: MetadataReferenceDateWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataReferenceNumberListCreateInput {
  connect?: MetadataReferenceNumberWhereUniqueInput[] | null;
  create?: MetadataReferenceNumberWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataReferenceNumberListUpdateInput {
  connect?: MetadataReferenceNumberWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataReferenceNumberListUpdateInput[] | null;
  create?: MetadataReferenceNumberWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataReferenceNumberListUpdateInput[] | null;
  delete?: MetadataReferenceNumberWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataReferenceServiceListCreateInput {
  connect?: MetadataReferenceServiceWhereUniqueInput[] | null;
  create?: MetadataReferenceServiceWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataReferenceServiceListUpdateInput {
  connect?: MetadataReferenceServiceWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataReferenceServiceListUpdateInput[] | null;
  create?: MetadataReferenceServiceWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataReferenceServiceListUpdateInput[] | null;
  delete?: MetadataReferenceServiceWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataReleaseDateListCreateInput {
  connect?: MetadataReleaseDateWhereUniqueInput[] | null;
  create?: MetadataReleaseDateWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataReleaseDateListUpdateInput {
  connect?: MetadataReleaseDateWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataReleaseDateListUpdateInput[] | null;
  create?: MetadataReleaseDateWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataReleaseDateListUpdateInput[] | null;
  delete?: MetadataReleaseDateWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataReleaseTimeListCreateInput {
  connect?: MetadataReleaseTimeWhereUniqueInput[] | null;
  create?: MetadataReleaseTimeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataReleaseTimeListUpdateInput {
  connect?: MetadataReleaseTimeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataReleaseTimeListUpdateInput[] | null;
  create?: MetadataReleaseTimeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataReleaseTimeListUpdateInput[] | null;
  delete?: MetadataReleaseTimeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataRequiredPermissionListCreateInput {
  connect?: MetadataRequiredPermissionWhereUniqueInput[] | null;
  create?: MetadataRequiredPermissionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataRequiredPermissionListUpdateInput {
  connect?: MetadataRequiredPermissionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataRequiredPermissionListUpdateInput[] | null;
  create?: MetadataRequiredPermissionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataRequiredPermissionListUpdateInput[] | null;
  delete?: MetadataRequiredPermissionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataResolutionKindListCreateInput {
  connect?: MetadataResolutionKindWhereUniqueInput[] | null;
  create?: MetadataResolutionKindWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataResolutionKindListUpdateInput {
  connect?: MetadataResolutionKindWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataResolutionKindListUpdateInput[] | null;
  create?: MetadataResolutionKindWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataResolutionKindListUpdateInput[] | null;
  delete?: MetadataResolutionKindWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataRightsListCreateInput {
  connect?: MetadataRightsWhereUniqueInput[] | null;
  create?: MetadataRightsWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataRightsListUpdateInput {
  connect?: MetadataRightsWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataRightsListUpdateInput[] | null;
  create?: MetadataRightsWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataRightsListUpdateInput[] | null;
  delete?: MetadataRightsWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataRoyaltyfreeListCreateInput {
  connect?: MetadataRoyaltyfreeWhereUniqueInput[] | null;
  create?: MetadataRoyaltyfreeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataRoyaltyfreeListUpdateInput {
  connect?: MetadataRoyaltyfreeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataRoyaltyfreeListUpdateInput[] | null;
  create?: MetadataRoyaltyfreeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataRoyaltyfreeListUpdateInput[] | null;
  delete?: MetadataRoyaltyfreeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataSceneListCreateInput {
  connect?: MetadataSceneWhereUniqueInput[] | null;
  create?: MetadataSceneWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataSceneListUpdateInput {
  connect?: MetadataSceneWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataSceneListUpdateInput[] | null;
  create?: MetadataSceneWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataSceneListUpdateInput[] | null;
  delete?: MetadataSceneWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataSectionListCreateInput {
  connect?: MetadataSectionWhereUniqueInput[] | null;
  create?: MetadataSectionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataSectionListUpdateInput {
  connect?: MetadataSectionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataSectionListUpdateInput[] | null;
  create?: MetadataSectionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataSectionListUpdateInput[] | null;
  delete?: MetadataSectionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataSentToListCreateInput {
  connect?: MetadataSentToWhereUniqueInput[] | null;
  create?: MetadataSentToWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataSentToListUpdateInput {
  connect?: MetadataSentToWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataSentToListUpdateInput[] | null;
  create?: MetadataSentToWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataSentToListUpdateInput[] | null;
  delete?: MetadataSentToWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataSerialNumberListCreateInput {
  connect?: MetadataSerialNumberWhereUniqueInput[] | null;
  create?: MetadataSerialNumberWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataSerialNumberListUpdateInput {
  connect?: MetadataSerialNumberWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataSerialNumberListUpdateInput[] | null;
  create?: MetadataSerialNumberWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataSerialNumberListUpdateInput[] | null;
  delete?: MetadataSerialNumberWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataSeriesDateTimeListCreateInput {
  connect?: MetadataSeriesDateTimeWhereUniqueInput[] | null;
  create?: MetadataSeriesDateTimeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataSeriesDateTimeListUpdateInput {
  connect?: MetadataSeriesDateTimeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataSeriesDateTimeListUpdateInput[] | null;
  create?: MetadataSeriesDateTimeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataSeriesDateTimeListUpdateInput[] | null;
  delete?: MetadataSeriesDateTimeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataSeriesDescriptionListCreateInput {
  connect?: MetadataSeriesDescriptionWhereUniqueInput[] | null;
  create?: MetadataSeriesDescriptionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataSeriesDescriptionListUpdateInput {
  connect?: MetadataSeriesDescriptionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataSeriesDescriptionListUpdateInput[] | null;
  create?: MetadataSeriesDescriptionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataSeriesDescriptionListUpdateInput[] | null;
  delete?: MetadataSeriesDescriptionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataSeriesModalityListCreateInput {
  connect?: MetadataSeriesModalityWhereUniqueInput[] | null;
  create?: MetadataSeriesModalityWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataSeriesModalityListUpdateInput {
  connect?: MetadataSeriesModalityWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataSeriesModalityListUpdateInput[] | null;
  create?: MetadataSeriesModalityWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataSeriesModalityListUpdateInput[] | null;
  delete?: MetadataSeriesModalityWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataSeriesNumberListCreateInput {
  connect?: MetadataSeriesNumberWhereUniqueInput[] | null;
  create?: MetadataSeriesNumberWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataSeriesNumberListUpdateInput {
  connect?: MetadataSeriesNumberWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataSeriesNumberListUpdateInput[] | null;
  create?: MetadataSeriesNumberWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataSeriesNumberListUpdateInput[] | null;
  delete?: MetadataSeriesNumberWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataShortUniqueIdListCreateInput {
  connect?: MetadataShortUniqueIdWhereUniqueInput[] | null;
  create?: MetadataShortUniqueIdWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataShortUniqueIdListUpdateInput {
  connect?: MetadataShortUniqueIdWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataShortUniqueIdListUpdateInput[] | null;
  create?: MetadataShortUniqueIdWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataShortUniqueIdListUpdateInput[] | null;
  delete?: MetadataShortUniqueIdWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataSourceListCreateInput {
  connect?: MetadataSourceWhereUniqueInput[] | null;
  create?: MetadataSourceWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataSourceListUpdateInput {
  connect?: MetadataSourceWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataSourceListUpdateInput[] | null;
  create?: MetadataSourceWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataSourceListUpdateInput[] | null;
  delete?: MetadataSourceWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataStateListCreateInput {
  connect?: MetadataStateWhereUniqueInput[] | null;
  create?: MetadataStateWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataStateListUpdateInput {
  connect?: MetadataStateWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataStateListUpdateInput[] | null;
  create?: MetadataStateWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataStateListUpdateInput[] | null;
  delete?: MetadataStateWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataStudyDateTimeListCreateInput {
  connect?: MetadataStudyDateTimeWhereUniqueInput[] | null;
  create?: MetadataStudyDateTimeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataStudyDateTimeListUpdateInput {
  connect?: MetadataStudyDateTimeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataStudyDateTimeListUpdateInput[] | null;
  create?: MetadataStudyDateTimeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataStudyDateTimeListUpdateInput[] | null;
  delete?: MetadataStudyDateTimeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataStudyDescriptionListCreateInput {
  connect?: MetadataStudyDescriptionWhereUniqueInput[] | null;
  create?: MetadataStudyDescriptionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataStudyDescriptionListUpdateInput {
  connect?: MetadataStudyDescriptionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataStudyDescriptionListUpdateInput[] | null;
  create?: MetadataStudyDescriptionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataStudyDescriptionListUpdateInput[] | null;
  delete?: MetadataStudyDescriptionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataStudyIdListCreateInput {
  connect?: MetadataStudyIdWhereUniqueInput[] | null;
  create?: MetadataStudyIdWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataStudyIdListUpdateInput {
  connect?: MetadataStudyIdWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataStudyIdListUpdateInput[] | null;
  create?: MetadataStudyIdWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataStudyIdListUpdateInput[] | null;
  delete?: MetadataStudyIdWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataStudyPhysicianListCreateInput {
  connect?: MetadataStudyPhysicianWhereUniqueInput[] | null;
  create?: MetadataStudyPhysicianWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataStudyPhysicianListUpdateInput {
  connect?: MetadataStudyPhysicianWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataStudyPhysicianListUpdateInput[] | null;
  create?: MetadataStudyPhysicianWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataStudyPhysicianListUpdateInput[] | null;
  delete?: MetadataStudyPhysicianWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataSubjectCodeListCreateInput {
  connect?: MetadataSubjectCodeWhereUniqueInput[] | null;
  create?: MetadataSubjectCodeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataSubjectCodeListUpdateInput {
  connect?: MetadataSubjectCodeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataSubjectCodeListUpdateInput[] | null;
  create?: MetadataSubjectCodeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataSubjectCodeListUpdateInput[] | null;
  delete?: MetadataSubjectCodeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataSubjectListCreateInput {
  connect?: MetadataSubjectWhereUniqueInput[] | null;
  create?: MetadataSubjectWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataSubjectListUpdateInput {
  connect?: MetadataSubjectWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataSubjectListUpdateInput[] | null;
  create?: MetadataSubjectWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataSubjectListUpdateInput[] | null;
  delete?: MetadataSubjectWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataSubjectPrismaListCreateInput {
  connect?: MetadataSubjectPrismaWhereUniqueInput[] | null;
  create?: MetadataSubjectPrismaWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataSubjectPrismaListUpdateInput {
  connect?: MetadataSubjectPrismaWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataSubjectPrismaListUpdateInput[] | null;
  create?: MetadataSubjectPrismaWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataSubjectPrismaListUpdateInput[] | null;
  delete?: MetadataSubjectPrismaWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataSupplementalCategoriesListCreateInput {
  connect?: MetadataSupplementalCategoriesWhereUniqueInput[] | null;
  create?: MetadataSupplementalCategoriesWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataSupplementalCategoriesListUpdateInput {
  connect?: MetadataSupplementalCategoriesWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataSupplementalCategoriesListUpdateInput[] | null;
  create?: MetadataSupplementalCategoriesWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataSupplementalCategoriesListUpdateInput[] | null;
  delete?: MetadataSupplementalCategoriesWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataTargetVersionListCreateInput {
  connect?: MetadataTargetVersionWhereUniqueInput[] | null;
  create?: MetadataTargetVersionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataTargetVersionListUpdateInput {
  connect?: MetadataTargetVersionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataTargetVersionListUpdateInput[] | null;
  create?: MetadataTargetVersionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataTargetVersionListUpdateInput[] | null;
  delete?: MetadataTargetVersionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataTitleListCreateInput {
  connect?: MetadataTitleWhereUniqueInput[] | null;
  create?: MetadataTitleWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataTitleListUpdateInput {
  connect?: MetadataTitleWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataTitleListUpdateInput[] | null;
  create?: MetadataTitleWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataTitleListUpdateInput[] | null;
  delete?: MetadataTitleWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataToneCurveListCreateInput {
  connect?: MetadataToneCurveWhereUniqueInput[] | null;
  create?: MetadataToneCurveWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataToneCurveListUpdateInput {
  connect?: MetadataToneCurveWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataToneCurveListUpdateInput[] | null;
  create?: MetadataToneCurveWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataToneCurveListUpdateInput[] | null;
  delete?: MetadataToneCurveWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataTransferredByEmailListCreateInput {
  connect?: MetadataTransferredByEmailWhereUniqueInput[] | null;
  create?: MetadataTransferredByEmailWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataTransferredByEmailListUpdateInput {
  connect?: MetadataTransferredByEmailWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataTransferredByEmailListUpdateInput[] | null;
  create?: MetadataTransferredByEmailWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataTransferredByEmailListUpdateInput[] | null;
  delete?: MetadataTransferredByEmailWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataTransferredByFullNameListCreateInput {
  connect?: MetadataTransferredByFullNameWhereUniqueInput[] | null;
  create?: MetadataTransferredByFullNameWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataTransferredByFullNameListUpdateInput {
  connect?: MetadataTransferredByFullNameWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataTransferredByFullNameListUpdateInput[] | null;
  create?: MetadataTransferredByFullNameWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataTransferredByFullNameListUpdateInput[] | null;
  delete?: MetadataTransferredByFullNameWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataTransferredByListCreateInput {
  connect?: MetadataTransferredByWhereUniqueInput[] | null;
  create?: MetadataTransferredByWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataTransferredByListUpdateInput {
  connect?: MetadataTransferredByWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataTransferredByListUpdateInput[] | null;
  create?: MetadataTransferredByWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataTransferredByListUpdateInput[] | null;
  delete?: MetadataTransferredByWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataTransmissionReferenceListCreateInput {
  connect?: MetadataTransmissionReferenceWhereUniqueInput[] | null;
  create?: MetadataTransmissionReferenceWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataTransmissionReferenceListUpdateInput {
  connect?: MetadataTransmissionReferenceWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataTransmissionReferenceListUpdateInput[] | null;
  create?: MetadataTransmissionReferenceWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataTransmissionReferenceListUpdateInput[] | null;
  delete?: MetadataTransmissionReferenceWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataTypeListCreateInput {
  connect?: MetadataTypeWhereUniqueInput[] | null;
  create?: MetadataTypeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataTypeListUpdateInput {
  connect?: MetadataTypeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataTypeListUpdateInput[] | null;
  create?: MetadataTypeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataTypeListUpdateInput[] | null;
  delete?: MetadataTypeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUnderUnderSubjectListCreateInput {
  connect?: MetadataUnderUnderSubjectWhereUniqueInput[] | null;
  create?: MetadataUnderUnderSubjectWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUnderUnderSubjectListUpdateInput {
  connect?: MetadataUnderUnderSubjectWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUnderUnderSubjectListUpdateInput[] | null;
  create?: MetadataUnderUnderSubjectWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUnderUnderSubjectListUpdateInput[] | null;
  delete?: MetadataUnderUnderSubjectWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUndersubjectListCreateInput {
  connect?: MetadataUndersubjectWhereUniqueInput[] | null;
  create?: MetadataUndersubjectWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUndersubjectListUpdateInput {
  connect?: MetadataUndersubjectWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUndersubjectListUpdateInput[] | null;
  create?: MetadataUndersubjectWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUndersubjectListUpdateInput[] | null;
  delete?: MetadataUndersubjectWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUniqueIdListCreateInput {
  connect?: MetadataUniqueIdWhereUniqueInput[] | null;
  create?: MetadataUniqueIdWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUniqueIdListUpdateInput {
  connect?: MetadataUniqueIdWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUniqueIdListUpdateInput[] | null;
  create?: MetadataUniqueIdWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUniqueIdListUpdateInput[] | null;
  delete?: MetadataUniqueIdWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUnitListCreateInput {
  connect?: MetadataUnitWhereUniqueInput[] | null;
  create?: MetadataUnitWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUnitListUpdateInput {
  connect?: MetadataUnitWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUnitListUpdateInput[] | null;
  create?: MetadataUnitWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUnitListUpdateInput[] | null;
  delete?: MetadataUnitWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUnitShortNameListCreateInput {
  connect?: MetadataUnitShortNameWhereUniqueInput[] | null;
  create?: MetadataUnitShortNameWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUnitShortNameListUpdateInput {
  connect?: MetadataUnitShortNameWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUnitShortNameListUpdateInput[] | null;
  create?: MetadataUnitShortNameWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUnitShortNameListUpdateInput[] | null;
  delete?: MetadataUnitShortNameWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUploadTimeListCreateInput {
  connect?: MetadataUploadTimeWhereUniqueInput[] | null;
  create?: MetadataUploadTimeWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUploadTimeListUpdateInput {
  connect?: MetadataUploadTimeWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUploadTimeListUpdateInput[] | null;
  create?: MetadataUploadTimeWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUploadTimeListUpdateInput[] | null;
  delete?: MetadataUploadTimeWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUploadedByFullNameListCreateInput {
  connect?: MetadataUploadedByFullNameWhereUniqueInput[] | null;
  create?: MetadataUploadedByFullNameWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUploadedByFullNameListUpdateInput {
  connect?: MetadataUploadedByFullNameWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUploadedByFullNameListUpdateInput[] | null;
  create?: MetadataUploadedByFullNameWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUploadedByFullNameListUpdateInput[] | null;
  delete?: MetadataUploadedByFullNameWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUploadedByListCreateInput {
  connect?: MetadataUploadedByWhereUniqueInput[] | null;
  create?: MetadataUploadedByWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUploadedByListUpdateInput {
  connect?: MetadataUploadedByWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUploadedByListUpdateInput[] | null;
  create?: MetadataUploadedByWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUploadedByListUpdateInput[] | null;
  delete?: MetadataUploadedByWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUrgencyListCreateInput {
  connect?: MetadataUrgencyWhereUniqueInput[] | null;
  create?: MetadataUrgencyWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUrgencyListUpdateInput {
  connect?: MetadataUrgencyWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUrgencyListUpdateInput[] | null;
  create?: MetadataUrgencyWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUrgencyListUpdateInput[] | null;
  delete?: MetadataUrgencyWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUsageTermsListCreateInput {
  connect?: MetadataUsageTermsWhereUniqueInput[] | null;
  create?: MetadataUsageTermsWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUsageTermsListUpdateInput {
  connect?: MetadataUsageTermsWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUsageTermsListUpdateInput[] | null;
  create?: MetadataUsageTermsWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUsageTermsListUpdateInput[] | null;
  delete?: MetadataUsageTermsWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUserCommentListCreateInput {
  connect?: MetadataUserCommentWhereUniqueInput[] | null;
  create?: MetadataUserCommentWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUserCommentListUpdateInput {
  connect?: MetadataUserCommentWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUserCommentListUpdateInput[] | null;
  create?: MetadataUserCommentWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUserCommentListUpdateInput[] | null;
  delete?: MetadataUserCommentWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUserDefined195ListCreateInput {
  connect?: MetadataUserDefined195WhereUniqueInput[] | null;
  create?: MetadataUserDefined195WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUserDefined195ListUpdateInput {
  connect?: MetadataUserDefined195WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUserDefined195ListUpdateInput[] | null;
  create?: MetadataUserDefined195WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUserDefined195ListUpdateInput[] | null;
  delete?: MetadataUserDefined195WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUserDefined237ListCreateInput {
  connect?: MetadataUserDefined237WhereUniqueInput[] | null;
  create?: MetadataUserDefined237WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUserDefined237ListUpdateInput {
  connect?: MetadataUserDefined237WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUserDefined237ListUpdateInput[] | null;
  create?: MetadataUserDefined237WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUserDefined237ListUpdateInput[] | null;
  delete?: MetadataUserDefined237WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUserDefined238ListCreateInput {
  connect?: MetadataUserDefined238WhereUniqueInput[] | null;
  create?: MetadataUserDefined238WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUserDefined238ListUpdateInput {
  connect?: MetadataUserDefined238WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUserDefined238ListUpdateInput[] | null;
  create?: MetadataUserDefined238WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUserDefined238ListUpdateInput[] | null;
  delete?: MetadataUserDefined238WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUserDefined239ListCreateInput {
  connect?: MetadataUserDefined239WhereUniqueInput[] | null;
  create?: MetadataUserDefined239WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUserDefined239ListUpdateInput {
  connect?: MetadataUserDefined239WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUserDefined239ListUpdateInput[] | null;
  create?: MetadataUserDefined239WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUserDefined239ListUpdateInput[] | null;
  delete?: MetadataUserDefined239WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUserDefined242ListCreateInput {
  connect?: MetadataUserDefined242WhereUniqueInput[] | null;
  create?: MetadataUserDefined242WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUserDefined242ListUpdateInput {
  connect?: MetadataUserDefined242WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUserDefined242ListUpdateInput[] | null;
  create?: MetadataUserDefined242WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUserDefined242ListUpdateInput[] | null;
  delete?: MetadataUserDefined242WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataUserDefined62ListCreateInput {
  connect?: MetadataUserDefined62WhereUniqueInput[] | null;
  create?: MetadataUserDefined62WithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataUserDefined62ListUpdateInput {
  connect?: MetadataUserDefined62WhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataUserDefined62ListUpdateInput[] | null;
  create?: MetadataUserDefined62WithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataUserDefined62ListUpdateInput[] | null;
  delete?: MetadataUserDefined62WhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataValidListCreateInput {
  connect?: MetadataValidWhereUniqueInput[] | null;
  create?: MetadataValidWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataValidListUpdateInput {
  connect?: MetadataValidWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataValidListUpdateInput[] | null;
  create?: MetadataValidWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataValidListUpdateInput[] | null;
  delete?: MetadataValidWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataVersionListCreateInput {
  connect?: MetadataVersionWhereUniqueInput[] | null;
  create?: MetadataVersionWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataVersionListUpdateInput {
  connect?: MetadataVersionWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataVersionListUpdateInput[] | null;
  create?: MetadataVersionWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataVersionListUpdateInput[] | null;
  delete?: MetadataVersionWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataWebStatementListCreateInput {
  connect?: MetadataWebStatementWhereUniqueInput[] | null;
  create?: MetadataWebStatementWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataWebStatementListUpdateInput {
  connect?: MetadataWebStatementWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataWebStatementListUpdateInput[] | null;
  create?: MetadataWebStatementWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataWebStatementListUpdateInput[] | null;
  delete?: MetadataWebStatementWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataWorkflowKindListCreateInput {
  connect?: MetadataWorkflowKindWhereUniqueInput[] | null;
  create?: MetadataWorkflowKindWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataWorkflowKindListUpdateInput {
  connect?: MetadataWorkflowKindWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataWorkflowKindListUpdateInput[] | null;
  create?: MetadataWorkflowKindWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataWorkflowKindListUpdateInput[] | null;
  delete?: MetadataWorkflowKindWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataXmpFileStampsListCreateInput {
  connect?: MetadataXmpFileStampsWhereUniqueInput[] | null;
  create?: MetadataXmpFileStampsWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataXmpFileStampsListUpdateInput {
  connect?: MetadataXmpFileStampsWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataXmpFileStampsListUpdateInput[] | null;
  create?: MetadataXmpFileStampsWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataXmpFileStampsListUpdateInput[] | null;
  delete?: MetadataXmpFileStampsWhereUniqueInput[] | null;
}

export interface MetadataNestedMetadataXmpHistoryListCreateInput {
  connect?: MetadataXmpHistoryWhereUniqueInput[] | null;
  create?: MetadataXmpHistoryWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedMetadataXmpHistoryListUpdateInput {
  connect?: MetadataXmpHistoryWhereUniqueInput[] | null;
  update?: MetadataNestedUpdateMetadataXmpHistoryListUpdateInput[] | null;
  create?: MetadataXmpHistoryWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertMetadataXmpHistoryListUpdateInput[] | null;
  delete?: MetadataXmpHistoryWhereUniqueInput[] | null;
}

export interface MetadataNestedPhotoMetadatasCreateInput {
  connect?: PhotoMetadataWhereUniqueInput[] | null;
  create?: PhotoMetadataWithForcedMetadataCreateInput[] | null;
}

export interface MetadataNestedPhotoMetadatasUpdateInput {
  connect?: PhotoMetadataWhereUniqueInput[] | null;
  update?: MetadataNestedUpdatePhotoMetadatasUpdateInput[] | null;
  create?: PhotoMetadataWithForcedMetadataCreateInput[] | null;
  upsert?: MetadataNestedUpsertPhotoMetadatasUpdateInput[] | null;
  delete?: PhotoMetadataWhereUniqueInput[] | null;
}

export interface MetadataNestedUpdateMetadataAccessRightsListUpdateInput {
  where: MetadataAccessRightsWhereUniqueInput;
  data: MetadataAccessRightsWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataAddlModelInfoListUpdateInput {
  where: MetadataAddlModelInfoWhereUniqueInput;
  data: MetadataAddlModelInfoWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataArchiveChildListUpdateInput {
  where: MetadataArchiveChildWhereUniqueInput;
  data: MetadataArchiveChildWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataArchiveParentListUpdateInput {
  where: MetadataArchiveParentWhereUniqueInput;
  data: MetadataArchiveParentWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataAssetStateListUpdateInput {
  where: MetadataAssetStateWhereUniqueInput;
  data: MetadataAssetStateWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataAttributionNameListUpdateInput {
  where: MetadataAttributionNameWhereUniqueInput;
  data: MetadataAttributionNameWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataAttributionUrlListUpdateInput {
  where: MetadataAttributionUrlWhereUniqueInput;
  data: MetadataAttributionUrlWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataAudienceListUpdateInput {
  where: MetadataAudienceWhereUniqueInput;
  data: MetadataAudienceWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataAuthorsPositionListUpdateInput {
  where: MetadataAuthorsPositionWhereUniqueInput;
  data: MetadataAuthorsPositionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataBackupNameListUpdateInput {
  where: MetadataBackupNameWhereUniqueInput;
  data: MetadataBackupNameWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataBitmapGrayscalePictureListUpdateInput {
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
  data: MetadataBitmapGrayscalePictureWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataBrandListUpdateInput {
  where: MetadataBrandWhereUniqueInput;
  data: MetadataBrandWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataBrandPrismaListUpdateInput {
  where: MetadataBrandPrismaWhereUniqueInput;
  data: MetadataBrandPrismaWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataBrandShortNameListUpdateInput {
  where: MetadataBrandShortNameWhereUniqueInput;
  data: MetadataBrandShortNameWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCaptionWriterListUpdateInput {
  where: MetadataCaptionWriterWhereUniqueInput;
  data: MetadataCaptionWriterWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCategoryListUpdateInput {
  where: MetadataCategoryWhereUniqueInput;
  data: MetadataCategoryWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCategoryPrismaListUpdateInput {
  where: MetadataCategoryPrismaWhereUniqueInput;
  data: MetadataCategoryPrismaWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCertificateListUpdateInput {
  where: MetadataCertificateWhereUniqueInput;
  data: MetadataCertificateWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCiAdrCityListUpdateInput {
  where: MetadataCiAdrCityWhereUniqueInput;
  data: MetadataCiAdrCityWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCiAdrCtryListUpdateInput {
  where: MetadataCiAdrCtryWhereUniqueInput;
  data: MetadataCiAdrCtryWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCiAdrExtadrListUpdateInput {
  where: MetadataCiAdrExtadrWhereUniqueInput;
  data: MetadataCiAdrExtadrWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCiAdrPcodeListUpdateInput {
  where: MetadataCiAdrPcodeWhereUniqueInput;
  data: MetadataCiAdrPcodeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCiAdrRegionListUpdateInput {
  where: MetadataCiAdrRegionWhereUniqueInput;
  data: MetadataCiAdrRegionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCiEmailWorkListUpdateInput {
  where: MetadataCiEmailWorkWhereUniqueInput;
  data: MetadataCiEmailWorkWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCiTelWorkListUpdateInput {
  where: MetadataCiTelWorkWhereUniqueInput;
  data: MetadataCiTelWorkWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCiUrlWorkListUpdateInput {
  where: MetadataCiUrlWorkWhereUniqueInput;
  data: MetadataCiUrlWorkWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCityListUpdateInput {
  where: MetadataCityWhereUniqueInput;
  data: MetadataCityWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataClassifyListUpdateInput {
  where: MetadataClassifyWhereUniqueInput;
  data: MetadataClassifyWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCompanyListUpdateInput {
  where: MetadataCompanyWhereUniqueInput;
  data: MetadataCompanyWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCompanyShortNameListUpdateInput {
  where: MetadataCompanyShortNameWhereUniqueInput;
  data: MetadataCompanyShortNameWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataConfidentialityListUpdateInput {
  where: MetadataConfidentialityWhereUniqueInput;
  data: MetadataConfidentialityWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataContainedInListUpdateInput {
  where: MetadataContainedInWhereUniqueInput;
  data: MetadataContainedInWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataContentMediaKindListUpdateInput {
  where: MetadataContentMediaKindWhereUniqueInput;
  data: MetadataContentMediaKindWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataContentValueListUpdateInput {
  where: MetadataContentValueWhereUniqueInput;
  data: MetadataContentValueWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCopyrightLayerListUpdateInput {
  where: MetadataCopyrightLayerWhereUniqueInput;
  data: MetadataCopyrightLayerWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCountryCodeListUpdateInput {
  where: MetadataCountryCodeWhereUniqueInput;
  data: MetadataCountryCodeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCountryListUpdateInput {
  where: MetadataCountryWhereUniqueInput;
  data: MetadataCountryWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCreatedTimeListUpdateInput {
  where: MetadataCreatedTimeWhereUniqueInput;
  data: MetadataCreatedTimeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCreatorListUpdateInput {
  where: MetadataCreatorWhereUniqueInput;
  data: MetadataCreatorWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCreatorToolListUpdateInput {
  where: MetadataCreatorToolWhereUniqueInput;
  data: MetadataCreatorToolWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCreditListUpdateInput {
  where: MetadataCreditWhereUniqueInput;
  data: MetadataCreditWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCustomField14ListUpdateInput {
  where: MetadataCustomField14WhereUniqueInput;
  data: MetadataCustomField14WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCustomField15ListUpdateInput {
  where: MetadataCustomField15WhereUniqueInput;
  data: MetadataCustomField15WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCustomField16ListUpdateInput {
  where: MetadataCustomField16WhereUniqueInput;
  data: MetadataCustomField16WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCustomField17ListUpdateInput {
  where: MetadataCustomField17WhereUniqueInput;
  data: MetadataCustomField17WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCustomField20ListUpdateInput {
  where: MetadataCustomField20WhereUniqueInput;
  data: MetadataCustomField20WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCustomField5ListUpdateInput {
  where: MetadataCustomField5WhereUniqueInput;
  data: MetadataCustomField5WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCustomField6ListUpdateInput {
  where: MetadataCustomField6WhereUniqueInput;
  data: MetadataCustomField6WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCustomField7ListUpdateInput {
  where: MetadataCustomField7WhereUniqueInput;
  data: MetadataCustomField7WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataCustomField9ListUpdateInput {
  where: MetadataCustomField9WhereUniqueInput;
  data: MetadataCustomField9WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDateCreatedListUpdateInput {
  where: MetadataDateCreatedWhereUniqueInput;
  data: MetadataDateCreatedWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDateTimeDigitizedListUpdateInput {
  where: MetadataDateTimeDigitizedWhereUniqueInput;
  data: MetadataDateTimeDigitizedWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDateTimeOriginalListUpdateInput {
  where: MetadataDateTimeOriginalWhereUniqueInput;
  data: MetadataDateTimeOriginalWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryAccountListUpdateInput {
  where: MetadataDeliveryAccountWhereUniqueInput;
  data: MetadataDeliveryAccountWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryCompanyListUpdateInput {
  where: MetadataDeliveryCompanyWhereUniqueInput;
  data: MetadataDeliveryCompanyWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryCopyrightListUpdateInput {
  where: MetadataDeliveryCopyrightWhereUniqueInput;
  data: MetadataDeliveryCopyrightWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryDateFolderListUpdateInput {
  where: MetadataDeliveryDateFolderWhereUniqueInput;
  data: MetadataDeliveryDateFolderWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryDateTimeListUpdateInput {
  where: MetadataDeliveryDateTimeWhereUniqueInput;
  data: MetadataDeliveryDateTimeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryDcCreatorListUpdateInput {
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
  data: MetadataDeliveryDcCreatorWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryDcRightsListUpdateInput {
  where: MetadataDeliveryDcRightsWhereUniqueInput;
  data: MetadataDeliveryDcRightsWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryFileTypeListUpdateInput {
  where: MetadataDeliveryFileTypeWhereUniqueInput;
  data: MetadataDeliveryFileTypeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryFolderListUpdateInput {
  where: MetadataDeliveryFolderWhereUniqueInput;
  data: MetadataDeliveryFolderWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryKindListUpdateInput {
  where: MetadataDeliveryKindWhereUniqueInput;
  data: MetadataDeliveryKindWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryPathListUpdateInput {
  where: MetadataDeliveryPathWhereUniqueInput;
  data: MetadataDeliveryPathWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryPersonShownintheImageListUpdateInput {
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
  data: MetadataDeliveryPersonShownintheImageWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryPhotoshopCreditListUpdateInput {
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
  data: MetadataDeliveryPhotoshopCreditWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryPhotoshopSourceListUpdateInput {
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
  data: MetadataDeliveryPhotoshopSourceWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryServiceListUpdateInput {
  where: MetadataDeliveryServiceWhereUniqueInput;
  data: MetadataDeliveryServiceWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliverySubjectListUpdateInput {
  where: MetadataDeliverySubjectWhereUniqueInput;
  data: MetadataDeliverySubjectWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDeliveryUnderSubjectListUpdateInput {
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
  data: MetadataDeliveryUnderSubjectWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDepartmentListUpdateInput {
  where: MetadataDepartmentWhereUniqueInput;
  data: MetadataDepartmentWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDescriptionListUpdateInput {
  where: MetadataDescriptionWhereUniqueInput;
  data: MetadataDescriptionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDigitalAssetUrlListUpdateInput {
  where: MetadataDigitalAssetUrlWhereUniqueInput;
  data: MetadataDigitalAssetUrlWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDivisionListUpdateInput {
  where: MetadataDivisionWhereUniqueInput;
  data: MetadataDivisionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataDocumentTextListUpdateInput {
  where: MetadataDocumentTextWhereUniqueInput;
  data: MetadataDocumentTextWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataEditStatusListUpdateInput {
  where: MetadataEditStatusWhereUniqueInput;
  data: MetadataEditStatusWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataEditionListUpdateInput {
  where: MetadataEditionWhereUniqueInput;
  data: MetadataEditionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataEditorialVersionListUpdateInput {
  where: MetadataEditorialVersionWhereUniqueInput;
  data: MetadataEditorialVersionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataEnvironnementPhotoListUpdateInput {
  where: MetadataEnvironnementPhotoWhereUniqueInput;
  data: MetadataEnvironnementPhotoWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataEquipmentInstitutionListUpdateInput {
  where: MetadataEquipmentInstitutionWhereUniqueInput;
  data: MetadataEquipmentInstitutionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataEquipmentManufacturerListUpdateInput {
  where: MetadataEquipmentManufacturerWhereUniqueInput;
  data: MetadataEquipmentManufacturerWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataEventListUpdateInput {
  where: MetadataEventWhereUniqueInput;
  data: MetadataEventWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataExifListUpdateInput {
  where: MetadataExifWhereUniqueInput;
  data: MetadataExifWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataFabStorageListUpdateInput {
  where: MetadataFabStorageWhereUniqueInput;
  data: MetadataFabStorageWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataFileCheckForListUpdateInput {
  where: MetadataFileCheckForWhereUniqueInput;
  data: MetadataFileCheckForWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataFileInfoBitsPerPixelListUpdateInput {
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
  data: MetadataFileInfoBitsPerPixelWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataFileInfoFramesListUpdateInput {
  where: MetadataFileInfoFramesWhereUniqueInput;
  data: MetadataFileInfoFramesWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataFileInfoPhotometricInterpretationListUpdateInput {
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
  data: MetadataFileInfoPhotometricInterpretationWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataFileInfoTransferSyntaxListUpdateInput {
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
  data: MetadataFileInfoTransferSyntaxWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataFileStageListUpdateInput {
  where: MetadataFileStageWhereUniqueInput;
  data: MetadataFileStageWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataFileStatusListUpdateInput {
  where: MetadataFileStatusWhereUniqueInput;
  data: MetadataFileStatusWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataFileTypeListUpdateInput {
  where: MetadataFileTypeWhereUniqueInput;
  data: MetadataFileTypeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataFixtureIdentifierListUpdateInput {
  where: MetadataFixtureIdentifierWhereUniqueInput;
  data: MetadataFixtureIdentifierWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataFormatListUpdateInput {
  where: MetadataFormatWhereUniqueInput;
  data: MetadataFormatWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataGpsLatitudeListUpdateInput {
  where: MetadataGpsLatitudeWhereUniqueInput;
  data: MetadataGpsLatitudeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataGpsLongitudeListUpdateInput {
  where: MetadataGpsLongitudeWhereUniqueInput;
  data: MetadataGpsLongitudeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataGpsProcessingMethodListUpdateInput {
  where: MetadataGpsProcessingMethodWhereUniqueInput;
  data: MetadataGpsProcessingMethodWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataHeaderListUpdateInput {
  where: MetadataHeaderWhereUniqueInput;
  data: MetadataHeaderWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataHeaderPrismaListUpdateInput {
  where: MetadataHeaderPrismaWhereUniqueInput;
  data: MetadataHeaderPrismaWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataHeadlineListUpdateInput {
  where: MetadataHeadlineWhereUniqueInput;
  data: MetadataHeadlineWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataHistoryListUpdateInput {
  where: MetadataHistoryWhereUniqueInput;
  data: MetadataHistoryWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataIccProfileListUpdateInput {
  where: MetadataIccProfileWhereUniqueInput;
  data: MetadataIccProfileWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataIdAssignmentListUpdateInput {
  where: MetadataIdAssignmentWhereUniqueInput;
  data: MetadataIdAssignmentWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataIdMediaContentListUpdateInput {
  where: MetadataIdMediaContentWhereUniqueInput;
  data: MetadataIdMediaContentWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataIdPhotoPrismaListUpdateInput {
  where: MetadataIdPhotoPrismaWhereUniqueInput;
  data: MetadataIdPhotoPrismaWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataIdSubjectListUpdateInput {
  where: MetadataIdSubjectWhereUniqueInput;
  data: MetadataIdSubjectWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataImageNotesListUpdateInput {
  where: MetadataImageNotesWhereUniqueInput;
  data: MetadataImageNotesWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataImageUniqueIdListUpdateInput {
  where: MetadataImageUniqueIdWhereUniqueInput;
  data: MetadataImageUniqueIdWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataInstructionsListUpdateInput {
  where: MetadataInstructionsWhereUniqueInput;
  data: MetadataInstructionsWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataIntellectualGenreListUpdateInput {
  where: MetadataIntellectualGenreWhereUniqueInput;
  data: MetadataIntellectualGenreWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataIsoSpeedRatingsListUpdateInput {
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
  data: MetadataIsoSpeedRatingsWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataIssueListUpdateInput {
  where: MetadataIssueWhereUniqueInput;
  data: MetadataIssueWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataJobIdListUpdateInput {
  where: MetadataJobIdWhereUniqueInput;
  data: MetadataJobIdWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataJobProcessingListUpdateInput {
  where: MetadataJobProcessingWhereUniqueInput;
  data: MetadataJobProcessingWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataLayoutPictureEditingListUpdateInput {
  where: MetadataLayoutPictureEditingWhereUniqueInput;
  data: MetadataLayoutPictureEditingWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataLayoutStorageListUpdateInput {
  where: MetadataLayoutStorageWhereUniqueInput;
  data: MetadataLayoutStorageWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataLicenseListUpdateInput {
  where: MetadataLicenseWhereUniqueInput;
  data: MetadataLicenseWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataLinksListUpdateInput {
  where: MetadataLinksWhereUniqueInput;
  data: MetadataLinksWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataLocalCaptionListUpdateInput {
  where: MetadataLocalCaptionWhereUniqueInput;
  data: MetadataLocalCaptionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataLocationListUpdateInput {
  where: MetadataLocationWhereUniqueInput;
  data: MetadataLocationWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataMakeListUpdateInput {
  where: MetadataMakeWhereUniqueInput;
  data: MetadataMakeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataManifestListUpdateInput {
  where: MetadataManifestWhereUniqueInput;
  data: MetadataManifestWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataMarkedListUpdateInput {
  where: MetadataMarkedWhereUniqueInput;
  data: MetadataMarkedWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataMasterDocumentIdListUpdateInput {
  where: MetadataMasterDocumentIdWhereUniqueInput;
  data: MetadataMasterDocumentIdWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataMaxAvailHeightListUpdateInput {
  where: MetadataMaxAvailHeightWhereUniqueInput;
  data: MetadataMaxAvailHeightWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataMaxAvailWidthListUpdateInput {
  where: MetadataMaxAvailWidthWhereUniqueInput;
  data: MetadataMaxAvailWidthWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataMinorModelAgeDisclosureListUpdateInput {
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
  data: MetadataMinorModelAgeDisclosureWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataModelAgeListUpdateInput {
  where: MetadataModelAgeWhereUniqueInput;
  data: MetadataModelAgeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataModelListUpdateInput {
  where: MetadataModelWhereUniqueInput;
  data: MetadataModelWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataModelReleaseIdListUpdateInput {
  where: MetadataModelReleaseIdWhereUniqueInput;
  data: MetadataModelReleaseIdWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataModelReleaseStatusListUpdateInput {
  where: MetadataModelReleaseStatusWhereUniqueInput;
  data: MetadataModelReleaseStatusWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataMorePermissionsListUpdateInput {
  where: MetadataMorePermissionsWhereUniqueInput;
  data: MetadataMorePermissionsWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataObjectCycleListUpdateInput {
  where: MetadataObjectCycleWhereUniqueInput;
  data: MetadataObjectCycleWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataOrganisationInImageNameListUpdateInput {
  where: MetadataOrganisationInImageNameWhereUniqueInput;
  data: MetadataOrganisationInImageNameWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataOriginalColorListUpdateInput {
  where: MetadataOriginalColorWhereUniqueInput;
  data: MetadataOriginalColorWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataOriginalIccProfileListUpdateInput {
  where: MetadataOriginalIccProfileWhereUniqueInput;
  data: MetadataOriginalIccProfileWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataOriginalNameListUpdateInput {
  where: MetadataOriginalNameWhereUniqueInput;
  data: MetadataOriginalNameWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataOriginalWeigthListUpdateInput {
  where: MetadataOriginalWeigthWhereUniqueInput;
  data: MetadataOriginalWeigthWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataOwnerIdListUpdateInput {
  where: MetadataOwnerIdWhereUniqueInput;
  data: MetadataOwnerIdWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataOwnerListUpdateInput {
  where: MetadataOwnerWhereUniqueInput;
  data: MetadataOwnerWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPageListUpdateInput {
  where: MetadataPageWhereUniqueInput;
  data: MetadataPageWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPagePrismaListUpdateInput {
  where: MetadataPagePrismaWhereUniqueInput;
  data: MetadataPagePrismaWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPaintBasedCorrectionsListUpdateInput {
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
  data: MetadataPaintBasedCorrectionsWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPatientDobListUpdateInput {
  where: MetadataPatientDobWhereUniqueInput;
  data: MetadataPatientDobWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPatientIdListUpdateInput {
  where: MetadataPatientIdWhereUniqueInput;
  data: MetadataPatientIdWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPatientNameListUpdateInput {
  where: MetadataPatientNameWhereUniqueInput;
  data: MetadataPatientNameWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPatientSexListUpdateInput {
  where: MetadataPatientSexWhereUniqueInput;
  data: MetadataPatientSexWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPersonInImageListUpdateInput {
  where: MetadataPersonInImageWhereUniqueInput;
  data: MetadataPersonInImageWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPhotoStorageListUpdateInput {
  where: MetadataPhotoStorageWhereUniqueInput;
  data: MetadataPhotoStorageWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPrepressPictureEditingListUpdateInput {
  where: MetadataPrepressPictureEditingWhereUniqueInput;
  data: MetadataPrepressPictureEditingWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPriceLevelListUpdateInput {
  where: MetadataPriceLevelWhereUniqueInput;
  data: MetadataPriceLevelWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPrintingProfileListUpdateInput {
  where: MetadataPrintingProfileWhereUniqueInput;
  data: MetadataPrintingProfileWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPrismaProductionListUpdateInput {
  where: MetadataPrismaProductionWhereUniqueInput;
  data: MetadataPrismaProductionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataProcessHistoryListUpdateInput {
  where: MetadataProcessHistoryWhereUniqueInput;
  data: MetadataProcessHistoryWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataProcessParameterListUpdateInput {
  where: MetadataProcessParameterWhereUniqueInput;
  data: MetadataProcessParameterWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataProcessStatusListUpdateInput {
  where: MetadataProcessStatusWhereUniqueInput;
  data: MetadataProcessStatusWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataProductListUpdateInput {
  where: MetadataProductWhereUniqueInput;
  data: MetadataProductWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataProductShortNameListUpdateInput {
  where: MetadataProductShortNameWhereUniqueInput;
  data: MetadataProductShortNameWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataProductsListUpdateInput {
  where: MetadataProductsWhereUniqueInput;
  data: MetadataProductsWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataProduitsListUpdateInput {
  where: MetadataProduitsWhereUniqueInput;
  data: MetadataProduitsWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataProgramVersionListUpdateInput {
  where: MetadataProgramVersionWhereUniqueInput;
  data: MetadataProgramVersionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPropertyReleaseIdListUpdateInput {
  where: MetadataPropertyReleaseIdWhereUniqueInput;
  data: MetadataPropertyReleaseIdWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPropertyReleaseStatusListUpdateInput {
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
  data: MetadataPropertyReleaseStatusWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPublisherListUpdateInput {
  where: MetadataPublisherWhereUniqueInput;
  data: MetadataPublisherWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPublishingIssueListUpdateInput {
  where: MetadataPublishingIssueWhereUniqueInput;
  data: MetadataPublishingIssueWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataPublishingSubjectListUpdateInput {
  where: MetadataPublishingSubjectWhereUniqueInput;
  data: MetadataPublishingSubjectWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataQualifiedUsePrismaByListUpdateInput {
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
  data: MetadataQualifiedUsePrismaByWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataQualifiedUsePrismaDateListUpdateInput {
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
  data: MetadataQualifiedUsePrismaDateWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataQualifiedUsePrismaDurationListUpdateInput {
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
  data: MetadataQualifiedUsePrismaDurationWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataQualifiedUsePrismaSupportListUpdateInput {
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
  data: MetadataQualifiedUsePrismaSupportWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataRatingListUpdateInput {
  where: MetadataRatingWhereUniqueInput;
  data: MetadataRatingWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataReferenceDateListUpdateInput {
  where: MetadataReferenceDateWhereUniqueInput;
  data: MetadataReferenceDateWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataReferenceNumberListUpdateInput {
  where: MetadataReferenceNumberWhereUniqueInput;
  data: MetadataReferenceNumberWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataReferenceServiceListUpdateInput {
  where: MetadataReferenceServiceWhereUniqueInput;
  data: MetadataReferenceServiceWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataReleaseDateListUpdateInput {
  where: MetadataReleaseDateWhereUniqueInput;
  data: MetadataReleaseDateWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataReleaseTimeListUpdateInput {
  where: MetadataReleaseTimeWhereUniqueInput;
  data: MetadataReleaseTimeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataRequiredPermissionListUpdateInput {
  where: MetadataRequiredPermissionWhereUniqueInput;
  data: MetadataRequiredPermissionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataResolutionKindListUpdateInput {
  where: MetadataResolutionKindWhereUniqueInput;
  data: MetadataResolutionKindWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataRightsListUpdateInput {
  where: MetadataRightsWhereUniqueInput;
  data: MetadataRightsWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataRoyaltyfreeListUpdateInput {
  where: MetadataRoyaltyfreeWhereUniqueInput;
  data: MetadataRoyaltyfreeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataSceneListUpdateInput {
  where: MetadataSceneWhereUniqueInput;
  data: MetadataSceneWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataSectionListUpdateInput {
  where: MetadataSectionWhereUniqueInput;
  data: MetadataSectionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataSentToListUpdateInput {
  where: MetadataSentToWhereUniqueInput;
  data: MetadataSentToWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataSerialNumberListUpdateInput {
  where: MetadataSerialNumberWhereUniqueInput;
  data: MetadataSerialNumberWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataSeriesDateTimeListUpdateInput {
  where: MetadataSeriesDateTimeWhereUniqueInput;
  data: MetadataSeriesDateTimeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataSeriesDescriptionListUpdateInput {
  where: MetadataSeriesDescriptionWhereUniqueInput;
  data: MetadataSeriesDescriptionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataSeriesModalityListUpdateInput {
  where: MetadataSeriesModalityWhereUniqueInput;
  data: MetadataSeriesModalityWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataSeriesNumberListUpdateInput {
  where: MetadataSeriesNumberWhereUniqueInput;
  data: MetadataSeriesNumberWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataShortUniqueIdListUpdateInput {
  where: MetadataShortUniqueIdWhereUniqueInput;
  data: MetadataShortUniqueIdWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataSourceListUpdateInput {
  where: MetadataSourceWhereUniqueInput;
  data: MetadataSourceWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataStateListUpdateInput {
  where: MetadataStateWhereUniqueInput;
  data: MetadataStateWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataStudyDateTimeListUpdateInput {
  where: MetadataStudyDateTimeWhereUniqueInput;
  data: MetadataStudyDateTimeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataStudyDescriptionListUpdateInput {
  where: MetadataStudyDescriptionWhereUniqueInput;
  data: MetadataStudyDescriptionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataStudyIdListUpdateInput {
  where: MetadataStudyIdWhereUniqueInput;
  data: MetadataStudyIdWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataStudyPhysicianListUpdateInput {
  where: MetadataStudyPhysicianWhereUniqueInput;
  data: MetadataStudyPhysicianWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataSubjectCodeListUpdateInput {
  where: MetadataSubjectCodeWhereUniqueInput;
  data: MetadataSubjectCodeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataSubjectListUpdateInput {
  where: MetadataSubjectWhereUniqueInput;
  data: MetadataSubjectWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataSubjectPrismaListUpdateInput {
  where: MetadataSubjectPrismaWhereUniqueInput;
  data: MetadataSubjectPrismaWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataSupplementalCategoriesListUpdateInput {
  where: MetadataSupplementalCategoriesWhereUniqueInput;
  data: MetadataSupplementalCategoriesWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataTargetVersionListUpdateInput {
  where: MetadataTargetVersionWhereUniqueInput;
  data: MetadataTargetVersionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataTitleListUpdateInput {
  where: MetadataTitleWhereUniqueInput;
  data: MetadataTitleWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataToneCurveListUpdateInput {
  where: MetadataToneCurveWhereUniqueInput;
  data: MetadataToneCurveWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataTransferredByEmailListUpdateInput {
  where: MetadataTransferredByEmailWhereUniqueInput;
  data: MetadataTransferredByEmailWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataTransferredByFullNameListUpdateInput {
  where: MetadataTransferredByFullNameWhereUniqueInput;
  data: MetadataTransferredByFullNameWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataTransferredByListUpdateInput {
  where: MetadataTransferredByWhereUniqueInput;
  data: MetadataTransferredByWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataTransmissionReferenceListUpdateInput {
  where: MetadataTransmissionReferenceWhereUniqueInput;
  data: MetadataTransmissionReferenceWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataTypeListUpdateInput {
  where: MetadataTypeWhereUniqueInput;
  data: MetadataTypeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUnderUnderSubjectListUpdateInput {
  where: MetadataUnderUnderSubjectWhereUniqueInput;
  data: MetadataUnderUnderSubjectWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUndersubjectListUpdateInput {
  where: MetadataUndersubjectWhereUniqueInput;
  data: MetadataUndersubjectWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUniqueIdListUpdateInput {
  where: MetadataUniqueIdWhereUniqueInput;
  data: MetadataUniqueIdWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUnitListUpdateInput {
  where: MetadataUnitWhereUniqueInput;
  data: MetadataUnitWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUnitShortNameListUpdateInput {
  where: MetadataUnitShortNameWhereUniqueInput;
  data: MetadataUnitShortNameWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUploadTimeListUpdateInput {
  where: MetadataUploadTimeWhereUniqueInput;
  data: MetadataUploadTimeWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUploadedByFullNameListUpdateInput {
  where: MetadataUploadedByFullNameWhereUniqueInput;
  data: MetadataUploadedByFullNameWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUploadedByListUpdateInput {
  where: MetadataUploadedByWhereUniqueInput;
  data: MetadataUploadedByWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUrgencyListUpdateInput {
  where: MetadataUrgencyWhereUniqueInput;
  data: MetadataUrgencyWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUsageTermsListUpdateInput {
  where: MetadataUsageTermsWhereUniqueInput;
  data: MetadataUsageTermsWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUserCommentListUpdateInput {
  where: MetadataUserCommentWhereUniqueInput;
  data: MetadataUserCommentWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUserDefined195ListUpdateInput {
  where: MetadataUserDefined195WhereUniqueInput;
  data: MetadataUserDefined195WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUserDefined237ListUpdateInput {
  where: MetadataUserDefined237WhereUniqueInput;
  data: MetadataUserDefined237WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUserDefined238ListUpdateInput {
  where: MetadataUserDefined238WhereUniqueInput;
  data: MetadataUserDefined238WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUserDefined239ListUpdateInput {
  where: MetadataUserDefined239WhereUniqueInput;
  data: MetadataUserDefined239WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUserDefined242ListUpdateInput {
  where: MetadataUserDefined242WhereUniqueInput;
  data: MetadataUserDefined242WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataUserDefined62ListUpdateInput {
  where: MetadataUserDefined62WhereUniqueInput;
  data: MetadataUserDefined62WithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataValidListUpdateInput {
  where: MetadataValidWhereUniqueInput;
  data: MetadataValidWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataVersionListUpdateInput {
  where: MetadataVersionWhereUniqueInput;
  data: MetadataVersionWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataWebStatementListUpdateInput {
  where: MetadataWebStatementWhereUniqueInput;
  data: MetadataWebStatementWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataWorkflowKindListUpdateInput {
  where: MetadataWorkflowKindWhereUniqueInput;
  data: MetadataWorkflowKindWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataXmpFileStampsListUpdateInput {
  where: MetadataXmpFileStampsWhereUniqueInput;
  data: MetadataXmpFileStampsWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdateMetadataXmpHistoryListUpdateInput {
  where: MetadataXmpHistoryWhereUniqueInput;
  data: MetadataXmpHistoryWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpdatePhotoMetadatasUpdateInput {
  where: PhotoMetadataWhereUniqueInput;
  data: PhotoMetadataWithForcedMetadataUpdateInput;
}

export interface MetadataNestedUpsertMetadataAccessRightsListUpdateInput {
  where: MetadataAccessRightsWhereUniqueInput;
  update: MetadataAccessRightsWithForcedMetadataUpdateInput;
  create: MetadataAccessRightsWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataAddlModelInfoListUpdateInput {
  where: MetadataAddlModelInfoWhereUniqueInput;
  update: MetadataAddlModelInfoWithForcedMetadataUpdateInput;
  create: MetadataAddlModelInfoWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataArchiveChildListUpdateInput {
  where: MetadataArchiveChildWhereUniqueInput;
  update: MetadataArchiveChildWithForcedMetadataUpdateInput;
  create: MetadataArchiveChildWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataArchiveParentListUpdateInput {
  where: MetadataArchiveParentWhereUniqueInput;
  update: MetadataArchiveParentWithForcedMetadataUpdateInput;
  create: MetadataArchiveParentWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataAssetStateListUpdateInput {
  where: MetadataAssetStateWhereUniqueInput;
  update: MetadataAssetStateWithForcedMetadataUpdateInput;
  create: MetadataAssetStateWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataAttributionNameListUpdateInput {
  where: MetadataAttributionNameWhereUniqueInput;
  update: MetadataAttributionNameWithForcedMetadataUpdateInput;
  create: MetadataAttributionNameWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataAttributionUrlListUpdateInput {
  where: MetadataAttributionUrlWhereUniqueInput;
  update: MetadataAttributionUrlWithForcedMetadataUpdateInput;
  create: MetadataAttributionUrlWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataAudienceListUpdateInput {
  where: MetadataAudienceWhereUniqueInput;
  update: MetadataAudienceWithForcedMetadataUpdateInput;
  create: MetadataAudienceWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataAuthorsPositionListUpdateInput {
  where: MetadataAuthorsPositionWhereUniqueInput;
  update: MetadataAuthorsPositionWithForcedMetadataUpdateInput;
  create: MetadataAuthorsPositionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataBackupNameListUpdateInput {
  where: MetadataBackupNameWhereUniqueInput;
  update: MetadataBackupNameWithForcedMetadataUpdateInput;
  create: MetadataBackupNameWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataBitmapGrayscalePictureListUpdateInput {
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
  update: MetadataBitmapGrayscalePictureWithForcedMetadataUpdateInput;
  create: MetadataBitmapGrayscalePictureWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataBrandListUpdateInput {
  where: MetadataBrandWhereUniqueInput;
  update: MetadataBrandWithForcedMetadataUpdateInput;
  create: MetadataBrandWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataBrandPrismaListUpdateInput {
  where: MetadataBrandPrismaWhereUniqueInput;
  update: MetadataBrandPrismaWithForcedMetadataUpdateInput;
  create: MetadataBrandPrismaWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataBrandShortNameListUpdateInput {
  where: MetadataBrandShortNameWhereUniqueInput;
  update: MetadataBrandShortNameWithForcedMetadataUpdateInput;
  create: MetadataBrandShortNameWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCaptionWriterListUpdateInput {
  where: MetadataCaptionWriterWhereUniqueInput;
  update: MetadataCaptionWriterWithForcedMetadataUpdateInput;
  create: MetadataCaptionWriterWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCategoryListUpdateInput {
  where: MetadataCategoryWhereUniqueInput;
  update: MetadataCategoryWithForcedMetadataUpdateInput;
  create: MetadataCategoryWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCategoryPrismaListUpdateInput {
  where: MetadataCategoryPrismaWhereUniqueInput;
  update: MetadataCategoryPrismaWithForcedMetadataUpdateInput;
  create: MetadataCategoryPrismaWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCertificateListUpdateInput {
  where: MetadataCertificateWhereUniqueInput;
  update: MetadataCertificateWithForcedMetadataUpdateInput;
  create: MetadataCertificateWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCiAdrCityListUpdateInput {
  where: MetadataCiAdrCityWhereUniqueInput;
  update: MetadataCiAdrCityWithForcedMetadataUpdateInput;
  create: MetadataCiAdrCityWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCiAdrCtryListUpdateInput {
  where: MetadataCiAdrCtryWhereUniqueInput;
  update: MetadataCiAdrCtryWithForcedMetadataUpdateInput;
  create: MetadataCiAdrCtryWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCiAdrExtadrListUpdateInput {
  where: MetadataCiAdrExtadrWhereUniqueInput;
  update: MetadataCiAdrExtadrWithForcedMetadataUpdateInput;
  create: MetadataCiAdrExtadrWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCiAdrPcodeListUpdateInput {
  where: MetadataCiAdrPcodeWhereUniqueInput;
  update: MetadataCiAdrPcodeWithForcedMetadataUpdateInput;
  create: MetadataCiAdrPcodeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCiAdrRegionListUpdateInput {
  where: MetadataCiAdrRegionWhereUniqueInput;
  update: MetadataCiAdrRegionWithForcedMetadataUpdateInput;
  create: MetadataCiAdrRegionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCiEmailWorkListUpdateInput {
  where: MetadataCiEmailWorkWhereUniqueInput;
  update: MetadataCiEmailWorkWithForcedMetadataUpdateInput;
  create: MetadataCiEmailWorkWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCiTelWorkListUpdateInput {
  where: MetadataCiTelWorkWhereUniqueInput;
  update: MetadataCiTelWorkWithForcedMetadataUpdateInput;
  create: MetadataCiTelWorkWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCiUrlWorkListUpdateInput {
  where: MetadataCiUrlWorkWhereUniqueInput;
  update: MetadataCiUrlWorkWithForcedMetadataUpdateInput;
  create: MetadataCiUrlWorkWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCityListUpdateInput {
  where: MetadataCityWhereUniqueInput;
  update: MetadataCityWithForcedMetadataUpdateInput;
  create: MetadataCityWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataClassifyListUpdateInput {
  where: MetadataClassifyWhereUniqueInput;
  update: MetadataClassifyWithForcedMetadataUpdateInput;
  create: MetadataClassifyWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCompanyListUpdateInput {
  where: MetadataCompanyWhereUniqueInput;
  update: MetadataCompanyWithForcedMetadataUpdateInput;
  create: MetadataCompanyWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCompanyShortNameListUpdateInput {
  where: MetadataCompanyShortNameWhereUniqueInput;
  update: MetadataCompanyShortNameWithForcedMetadataUpdateInput;
  create: MetadataCompanyShortNameWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataConfidentialityListUpdateInput {
  where: MetadataConfidentialityWhereUniqueInput;
  update: MetadataConfidentialityWithForcedMetadataUpdateInput;
  create: MetadataConfidentialityWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataContainedInListUpdateInput {
  where: MetadataContainedInWhereUniqueInput;
  update: MetadataContainedInWithForcedMetadataUpdateInput;
  create: MetadataContainedInWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataContentMediaKindListUpdateInput {
  where: MetadataContentMediaKindWhereUniqueInput;
  update: MetadataContentMediaKindWithForcedMetadataUpdateInput;
  create: MetadataContentMediaKindWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataContentValueListUpdateInput {
  where: MetadataContentValueWhereUniqueInput;
  update: MetadataContentValueWithForcedMetadataUpdateInput;
  create: MetadataContentValueWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCopyrightLayerListUpdateInput {
  where: MetadataCopyrightLayerWhereUniqueInput;
  update: MetadataCopyrightLayerWithForcedMetadataUpdateInput;
  create: MetadataCopyrightLayerWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCountryCodeListUpdateInput {
  where: MetadataCountryCodeWhereUniqueInput;
  update: MetadataCountryCodeWithForcedMetadataUpdateInput;
  create: MetadataCountryCodeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCountryListUpdateInput {
  where: MetadataCountryWhereUniqueInput;
  update: MetadataCountryWithForcedMetadataUpdateInput;
  create: MetadataCountryWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCreatedTimeListUpdateInput {
  where: MetadataCreatedTimeWhereUniqueInput;
  update: MetadataCreatedTimeWithForcedMetadataUpdateInput;
  create: MetadataCreatedTimeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCreatorListUpdateInput {
  where: MetadataCreatorWhereUniqueInput;
  update: MetadataCreatorWithForcedMetadataUpdateInput;
  create: MetadataCreatorWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCreatorToolListUpdateInput {
  where: MetadataCreatorToolWhereUniqueInput;
  update: MetadataCreatorToolWithForcedMetadataUpdateInput;
  create: MetadataCreatorToolWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCreditListUpdateInput {
  where: MetadataCreditWhereUniqueInput;
  update: MetadataCreditWithForcedMetadataUpdateInput;
  create: MetadataCreditWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCustomField14ListUpdateInput {
  where: MetadataCustomField14WhereUniqueInput;
  update: MetadataCustomField14WithForcedMetadataUpdateInput;
  create: MetadataCustomField14WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCustomField15ListUpdateInput {
  where: MetadataCustomField15WhereUniqueInput;
  update: MetadataCustomField15WithForcedMetadataUpdateInput;
  create: MetadataCustomField15WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCustomField16ListUpdateInput {
  where: MetadataCustomField16WhereUniqueInput;
  update: MetadataCustomField16WithForcedMetadataUpdateInput;
  create: MetadataCustomField16WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCustomField17ListUpdateInput {
  where: MetadataCustomField17WhereUniqueInput;
  update: MetadataCustomField17WithForcedMetadataUpdateInput;
  create: MetadataCustomField17WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCustomField20ListUpdateInput {
  where: MetadataCustomField20WhereUniqueInput;
  update: MetadataCustomField20WithForcedMetadataUpdateInput;
  create: MetadataCustomField20WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCustomField5ListUpdateInput {
  where: MetadataCustomField5WhereUniqueInput;
  update: MetadataCustomField5WithForcedMetadataUpdateInput;
  create: MetadataCustomField5WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCustomField6ListUpdateInput {
  where: MetadataCustomField6WhereUniqueInput;
  update: MetadataCustomField6WithForcedMetadataUpdateInput;
  create: MetadataCustomField6WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCustomField7ListUpdateInput {
  where: MetadataCustomField7WhereUniqueInput;
  update: MetadataCustomField7WithForcedMetadataUpdateInput;
  create: MetadataCustomField7WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataCustomField9ListUpdateInput {
  where: MetadataCustomField9WhereUniqueInput;
  update: MetadataCustomField9WithForcedMetadataUpdateInput;
  create: MetadataCustomField9WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDateCreatedListUpdateInput {
  where: MetadataDateCreatedWhereUniqueInput;
  update: MetadataDateCreatedWithForcedMetadataUpdateInput;
  create: MetadataDateCreatedWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDateTimeDigitizedListUpdateInput {
  where: MetadataDateTimeDigitizedWhereUniqueInput;
  update: MetadataDateTimeDigitizedWithForcedMetadataUpdateInput;
  create: MetadataDateTimeDigitizedWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDateTimeOriginalListUpdateInput {
  where: MetadataDateTimeOriginalWhereUniqueInput;
  update: MetadataDateTimeOriginalWithForcedMetadataUpdateInput;
  create: MetadataDateTimeOriginalWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryAccountListUpdateInput {
  where: MetadataDeliveryAccountWhereUniqueInput;
  update: MetadataDeliveryAccountWithForcedMetadataUpdateInput;
  create: MetadataDeliveryAccountWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryCompanyListUpdateInput {
  where: MetadataDeliveryCompanyWhereUniqueInput;
  update: MetadataDeliveryCompanyWithForcedMetadataUpdateInput;
  create: MetadataDeliveryCompanyWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryCopyrightListUpdateInput {
  where: MetadataDeliveryCopyrightWhereUniqueInput;
  update: MetadataDeliveryCopyrightWithForcedMetadataUpdateInput;
  create: MetadataDeliveryCopyrightWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryDateFolderListUpdateInput {
  where: MetadataDeliveryDateFolderWhereUniqueInput;
  update: MetadataDeliveryDateFolderWithForcedMetadataUpdateInput;
  create: MetadataDeliveryDateFolderWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryDateTimeListUpdateInput {
  where: MetadataDeliveryDateTimeWhereUniqueInput;
  update: MetadataDeliveryDateTimeWithForcedMetadataUpdateInput;
  create: MetadataDeliveryDateTimeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryDcCreatorListUpdateInput {
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
  update: MetadataDeliveryDcCreatorWithForcedMetadataUpdateInput;
  create: MetadataDeliveryDcCreatorWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryDcRightsListUpdateInput {
  where: MetadataDeliveryDcRightsWhereUniqueInput;
  update: MetadataDeliveryDcRightsWithForcedMetadataUpdateInput;
  create: MetadataDeliveryDcRightsWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryFileTypeListUpdateInput {
  where: MetadataDeliveryFileTypeWhereUniqueInput;
  update: MetadataDeliveryFileTypeWithForcedMetadataUpdateInput;
  create: MetadataDeliveryFileTypeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryFolderListUpdateInput {
  where: MetadataDeliveryFolderWhereUniqueInput;
  update: MetadataDeliveryFolderWithForcedMetadataUpdateInput;
  create: MetadataDeliveryFolderWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryKindListUpdateInput {
  where: MetadataDeliveryKindWhereUniqueInput;
  update: MetadataDeliveryKindWithForcedMetadataUpdateInput;
  create: MetadataDeliveryKindWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryPathListUpdateInput {
  where: MetadataDeliveryPathWhereUniqueInput;
  update: MetadataDeliveryPathWithForcedMetadataUpdateInput;
  create: MetadataDeliveryPathWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryPersonShownintheImageListUpdateInput {
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
  update: MetadataDeliveryPersonShownintheImageWithForcedMetadataUpdateInput;
  create: MetadataDeliveryPersonShownintheImageWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryPhotoshopCreditListUpdateInput {
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
  update: MetadataDeliveryPhotoshopCreditWithForcedMetadataUpdateInput;
  create: MetadataDeliveryPhotoshopCreditWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryPhotoshopSourceListUpdateInput {
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
  update: MetadataDeliveryPhotoshopSourceWithForcedMetadataUpdateInput;
  create: MetadataDeliveryPhotoshopSourceWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryServiceListUpdateInput {
  where: MetadataDeliveryServiceWhereUniqueInput;
  update: MetadataDeliveryServiceWithForcedMetadataUpdateInput;
  create: MetadataDeliveryServiceWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliverySubjectListUpdateInput {
  where: MetadataDeliverySubjectWhereUniqueInput;
  update: MetadataDeliverySubjectWithForcedMetadataUpdateInput;
  create: MetadataDeliverySubjectWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDeliveryUnderSubjectListUpdateInput {
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
  update: MetadataDeliveryUnderSubjectWithForcedMetadataUpdateInput;
  create: MetadataDeliveryUnderSubjectWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDepartmentListUpdateInput {
  where: MetadataDepartmentWhereUniqueInput;
  update: MetadataDepartmentWithForcedMetadataUpdateInput;
  create: MetadataDepartmentWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDescriptionListUpdateInput {
  where: MetadataDescriptionWhereUniqueInput;
  update: MetadataDescriptionWithForcedMetadataUpdateInput;
  create: MetadataDescriptionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDigitalAssetUrlListUpdateInput {
  where: MetadataDigitalAssetUrlWhereUniqueInput;
  update: MetadataDigitalAssetUrlWithForcedMetadataUpdateInput;
  create: MetadataDigitalAssetUrlWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDivisionListUpdateInput {
  where: MetadataDivisionWhereUniqueInput;
  update: MetadataDivisionWithForcedMetadataUpdateInput;
  create: MetadataDivisionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataDocumentTextListUpdateInput {
  where: MetadataDocumentTextWhereUniqueInput;
  update: MetadataDocumentTextWithForcedMetadataUpdateInput;
  create: MetadataDocumentTextWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataEditStatusListUpdateInput {
  where: MetadataEditStatusWhereUniqueInput;
  update: MetadataEditStatusWithForcedMetadataUpdateInput;
  create: MetadataEditStatusWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataEditionListUpdateInput {
  where: MetadataEditionWhereUniqueInput;
  update: MetadataEditionWithForcedMetadataUpdateInput;
  create: MetadataEditionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataEditorialVersionListUpdateInput {
  where: MetadataEditorialVersionWhereUniqueInput;
  update: MetadataEditorialVersionWithForcedMetadataUpdateInput;
  create: MetadataEditorialVersionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataEnvironnementPhotoListUpdateInput {
  where: MetadataEnvironnementPhotoWhereUniqueInput;
  update: MetadataEnvironnementPhotoWithForcedMetadataUpdateInput;
  create: MetadataEnvironnementPhotoWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataEquipmentInstitutionListUpdateInput {
  where: MetadataEquipmentInstitutionWhereUniqueInput;
  update: MetadataEquipmentInstitutionWithForcedMetadataUpdateInput;
  create: MetadataEquipmentInstitutionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataEquipmentManufacturerListUpdateInput {
  where: MetadataEquipmentManufacturerWhereUniqueInput;
  update: MetadataEquipmentManufacturerWithForcedMetadataUpdateInput;
  create: MetadataEquipmentManufacturerWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataEventListUpdateInput {
  where: MetadataEventWhereUniqueInput;
  update: MetadataEventWithForcedMetadataUpdateInput;
  create: MetadataEventWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataExifListUpdateInput {
  where: MetadataExifWhereUniqueInput;
  update: MetadataExifWithForcedMetadataUpdateInput;
  create: MetadataExifWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataFabStorageListUpdateInput {
  where: MetadataFabStorageWhereUniqueInput;
  update: MetadataFabStorageWithForcedMetadataUpdateInput;
  create: MetadataFabStorageWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataFileCheckForListUpdateInput {
  where: MetadataFileCheckForWhereUniqueInput;
  update: MetadataFileCheckForWithForcedMetadataUpdateInput;
  create: MetadataFileCheckForWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataFileInfoBitsPerPixelListUpdateInput {
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
  update: MetadataFileInfoBitsPerPixelWithForcedMetadataUpdateInput;
  create: MetadataFileInfoBitsPerPixelWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataFileInfoFramesListUpdateInput {
  where: MetadataFileInfoFramesWhereUniqueInput;
  update: MetadataFileInfoFramesWithForcedMetadataUpdateInput;
  create: MetadataFileInfoFramesWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataFileInfoPhotometricInterpretationListUpdateInput {
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
  update: MetadataFileInfoPhotometricInterpretationWithForcedMetadataUpdateInput;
  create: MetadataFileInfoPhotometricInterpretationWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataFileInfoTransferSyntaxListUpdateInput {
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
  update: MetadataFileInfoTransferSyntaxWithForcedMetadataUpdateInput;
  create: MetadataFileInfoTransferSyntaxWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataFileStageListUpdateInput {
  where: MetadataFileStageWhereUniqueInput;
  update: MetadataFileStageWithForcedMetadataUpdateInput;
  create: MetadataFileStageWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataFileStatusListUpdateInput {
  where: MetadataFileStatusWhereUniqueInput;
  update: MetadataFileStatusWithForcedMetadataUpdateInput;
  create: MetadataFileStatusWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataFileTypeListUpdateInput {
  where: MetadataFileTypeWhereUniqueInput;
  update: MetadataFileTypeWithForcedMetadataUpdateInput;
  create: MetadataFileTypeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataFixtureIdentifierListUpdateInput {
  where: MetadataFixtureIdentifierWhereUniqueInput;
  update: MetadataFixtureIdentifierWithForcedMetadataUpdateInput;
  create: MetadataFixtureIdentifierWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataFormatListUpdateInput {
  where: MetadataFormatWhereUniqueInput;
  update: MetadataFormatWithForcedMetadataUpdateInput;
  create: MetadataFormatWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataGpsLatitudeListUpdateInput {
  where: MetadataGpsLatitudeWhereUniqueInput;
  update: MetadataGpsLatitudeWithForcedMetadataUpdateInput;
  create: MetadataGpsLatitudeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataGpsLongitudeListUpdateInput {
  where: MetadataGpsLongitudeWhereUniqueInput;
  update: MetadataGpsLongitudeWithForcedMetadataUpdateInput;
  create: MetadataGpsLongitudeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataGpsProcessingMethodListUpdateInput {
  where: MetadataGpsProcessingMethodWhereUniqueInput;
  update: MetadataGpsProcessingMethodWithForcedMetadataUpdateInput;
  create: MetadataGpsProcessingMethodWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataHeaderListUpdateInput {
  where: MetadataHeaderWhereUniqueInput;
  update: MetadataHeaderWithForcedMetadataUpdateInput;
  create: MetadataHeaderWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataHeaderPrismaListUpdateInput {
  where: MetadataHeaderPrismaWhereUniqueInput;
  update: MetadataHeaderPrismaWithForcedMetadataUpdateInput;
  create: MetadataHeaderPrismaWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataHeadlineListUpdateInput {
  where: MetadataHeadlineWhereUniqueInput;
  update: MetadataHeadlineWithForcedMetadataUpdateInput;
  create: MetadataHeadlineWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataHistoryListUpdateInput {
  where: MetadataHistoryWhereUniqueInput;
  update: MetadataHistoryWithForcedMetadataUpdateInput;
  create: MetadataHistoryWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataIccProfileListUpdateInput {
  where: MetadataIccProfileWhereUniqueInput;
  update: MetadataIccProfileWithForcedMetadataUpdateInput;
  create: MetadataIccProfileWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataIdAssignmentListUpdateInput {
  where: MetadataIdAssignmentWhereUniqueInput;
  update: MetadataIdAssignmentWithForcedMetadataUpdateInput;
  create: MetadataIdAssignmentWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataIdMediaContentListUpdateInput {
  where: MetadataIdMediaContentWhereUniqueInput;
  update: MetadataIdMediaContentWithForcedMetadataUpdateInput;
  create: MetadataIdMediaContentWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataIdPhotoPrismaListUpdateInput {
  where: MetadataIdPhotoPrismaWhereUniqueInput;
  update: MetadataIdPhotoPrismaWithForcedMetadataUpdateInput;
  create: MetadataIdPhotoPrismaWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataIdSubjectListUpdateInput {
  where: MetadataIdSubjectWhereUniqueInput;
  update: MetadataIdSubjectWithForcedMetadataUpdateInput;
  create: MetadataIdSubjectWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataImageNotesListUpdateInput {
  where: MetadataImageNotesWhereUniqueInput;
  update: MetadataImageNotesWithForcedMetadataUpdateInput;
  create: MetadataImageNotesWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataImageUniqueIdListUpdateInput {
  where: MetadataImageUniqueIdWhereUniqueInput;
  update: MetadataImageUniqueIdWithForcedMetadataUpdateInput;
  create: MetadataImageUniqueIdWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataInstructionsListUpdateInput {
  where: MetadataInstructionsWhereUniqueInput;
  update: MetadataInstructionsWithForcedMetadataUpdateInput;
  create: MetadataInstructionsWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataIntellectualGenreListUpdateInput {
  where: MetadataIntellectualGenreWhereUniqueInput;
  update: MetadataIntellectualGenreWithForcedMetadataUpdateInput;
  create: MetadataIntellectualGenreWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataIsoSpeedRatingsListUpdateInput {
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
  update: MetadataIsoSpeedRatingsWithForcedMetadataUpdateInput;
  create: MetadataIsoSpeedRatingsWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataIssueListUpdateInput {
  where: MetadataIssueWhereUniqueInput;
  update: MetadataIssueWithForcedMetadataUpdateInput;
  create: MetadataIssueWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataJobIdListUpdateInput {
  where: MetadataJobIdWhereUniqueInput;
  update: MetadataJobIdWithForcedMetadataUpdateInput;
  create: MetadataJobIdWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataJobProcessingListUpdateInput {
  where: MetadataJobProcessingWhereUniqueInput;
  update: MetadataJobProcessingWithForcedMetadataUpdateInput;
  create: MetadataJobProcessingWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataLayoutPictureEditingListUpdateInput {
  where: MetadataLayoutPictureEditingWhereUniqueInput;
  update: MetadataLayoutPictureEditingWithForcedMetadataUpdateInput;
  create: MetadataLayoutPictureEditingWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataLayoutStorageListUpdateInput {
  where: MetadataLayoutStorageWhereUniqueInput;
  update: MetadataLayoutStorageWithForcedMetadataUpdateInput;
  create: MetadataLayoutStorageWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataLicenseListUpdateInput {
  where: MetadataLicenseWhereUniqueInput;
  update: MetadataLicenseWithForcedMetadataUpdateInput;
  create: MetadataLicenseWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataLinksListUpdateInput {
  where: MetadataLinksWhereUniqueInput;
  update: MetadataLinksWithForcedMetadataUpdateInput;
  create: MetadataLinksWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataLocalCaptionListUpdateInput {
  where: MetadataLocalCaptionWhereUniqueInput;
  update: MetadataLocalCaptionWithForcedMetadataUpdateInput;
  create: MetadataLocalCaptionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataLocationListUpdateInput {
  where: MetadataLocationWhereUniqueInput;
  update: MetadataLocationWithForcedMetadataUpdateInput;
  create: MetadataLocationWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataMakeListUpdateInput {
  where: MetadataMakeWhereUniqueInput;
  update: MetadataMakeWithForcedMetadataUpdateInput;
  create: MetadataMakeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataManifestListUpdateInput {
  where: MetadataManifestWhereUniqueInput;
  update: MetadataManifestWithForcedMetadataUpdateInput;
  create: MetadataManifestWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataMarkedListUpdateInput {
  where: MetadataMarkedWhereUniqueInput;
  update: MetadataMarkedWithForcedMetadataUpdateInput;
  create: MetadataMarkedWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataMasterDocumentIdListUpdateInput {
  where: MetadataMasterDocumentIdWhereUniqueInput;
  update: MetadataMasterDocumentIdWithForcedMetadataUpdateInput;
  create: MetadataMasterDocumentIdWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataMaxAvailHeightListUpdateInput {
  where: MetadataMaxAvailHeightWhereUniqueInput;
  update: MetadataMaxAvailHeightWithForcedMetadataUpdateInput;
  create: MetadataMaxAvailHeightWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataMaxAvailWidthListUpdateInput {
  where: MetadataMaxAvailWidthWhereUniqueInput;
  update: MetadataMaxAvailWidthWithForcedMetadataUpdateInput;
  create: MetadataMaxAvailWidthWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataMinorModelAgeDisclosureListUpdateInput {
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
  update: MetadataMinorModelAgeDisclosureWithForcedMetadataUpdateInput;
  create: MetadataMinorModelAgeDisclosureWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataModelAgeListUpdateInput {
  where: MetadataModelAgeWhereUniqueInput;
  update: MetadataModelAgeWithForcedMetadataUpdateInput;
  create: MetadataModelAgeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataModelListUpdateInput {
  where: MetadataModelWhereUniqueInput;
  update: MetadataModelWithForcedMetadataUpdateInput;
  create: MetadataModelWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataModelReleaseIdListUpdateInput {
  where: MetadataModelReleaseIdWhereUniqueInput;
  update: MetadataModelReleaseIdWithForcedMetadataUpdateInput;
  create: MetadataModelReleaseIdWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataModelReleaseStatusListUpdateInput {
  where: MetadataModelReleaseStatusWhereUniqueInput;
  update: MetadataModelReleaseStatusWithForcedMetadataUpdateInput;
  create: MetadataModelReleaseStatusWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataMorePermissionsListUpdateInput {
  where: MetadataMorePermissionsWhereUniqueInput;
  update: MetadataMorePermissionsWithForcedMetadataUpdateInput;
  create: MetadataMorePermissionsWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataObjectCycleListUpdateInput {
  where: MetadataObjectCycleWhereUniqueInput;
  update: MetadataObjectCycleWithForcedMetadataUpdateInput;
  create: MetadataObjectCycleWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataOrganisationInImageNameListUpdateInput {
  where: MetadataOrganisationInImageNameWhereUniqueInput;
  update: MetadataOrganisationInImageNameWithForcedMetadataUpdateInput;
  create: MetadataOrganisationInImageNameWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataOriginalColorListUpdateInput {
  where: MetadataOriginalColorWhereUniqueInput;
  update: MetadataOriginalColorWithForcedMetadataUpdateInput;
  create: MetadataOriginalColorWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataOriginalIccProfileListUpdateInput {
  where: MetadataOriginalIccProfileWhereUniqueInput;
  update: MetadataOriginalIccProfileWithForcedMetadataUpdateInput;
  create: MetadataOriginalIccProfileWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataOriginalNameListUpdateInput {
  where: MetadataOriginalNameWhereUniqueInput;
  update: MetadataOriginalNameWithForcedMetadataUpdateInput;
  create: MetadataOriginalNameWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataOriginalWeigthListUpdateInput {
  where: MetadataOriginalWeigthWhereUniqueInput;
  update: MetadataOriginalWeigthWithForcedMetadataUpdateInput;
  create: MetadataOriginalWeigthWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataOwnerIdListUpdateInput {
  where: MetadataOwnerIdWhereUniqueInput;
  update: MetadataOwnerIdWithForcedMetadataUpdateInput;
  create: MetadataOwnerIdWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataOwnerListUpdateInput {
  where: MetadataOwnerWhereUniqueInput;
  update: MetadataOwnerWithForcedMetadataUpdateInput;
  create: MetadataOwnerWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPageListUpdateInput {
  where: MetadataPageWhereUniqueInput;
  update: MetadataPageWithForcedMetadataUpdateInput;
  create: MetadataPageWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPagePrismaListUpdateInput {
  where: MetadataPagePrismaWhereUniqueInput;
  update: MetadataPagePrismaWithForcedMetadataUpdateInput;
  create: MetadataPagePrismaWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPaintBasedCorrectionsListUpdateInput {
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
  update: MetadataPaintBasedCorrectionsWithForcedMetadataUpdateInput;
  create: MetadataPaintBasedCorrectionsWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPatientDobListUpdateInput {
  where: MetadataPatientDobWhereUniqueInput;
  update: MetadataPatientDobWithForcedMetadataUpdateInput;
  create: MetadataPatientDobWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPatientIdListUpdateInput {
  where: MetadataPatientIdWhereUniqueInput;
  update: MetadataPatientIdWithForcedMetadataUpdateInput;
  create: MetadataPatientIdWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPatientNameListUpdateInput {
  where: MetadataPatientNameWhereUniqueInput;
  update: MetadataPatientNameWithForcedMetadataUpdateInput;
  create: MetadataPatientNameWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPatientSexListUpdateInput {
  where: MetadataPatientSexWhereUniqueInput;
  update: MetadataPatientSexWithForcedMetadataUpdateInput;
  create: MetadataPatientSexWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPersonInImageListUpdateInput {
  where: MetadataPersonInImageWhereUniqueInput;
  update: MetadataPersonInImageWithForcedMetadataUpdateInput;
  create: MetadataPersonInImageWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPhotoStorageListUpdateInput {
  where: MetadataPhotoStorageWhereUniqueInput;
  update: MetadataPhotoStorageWithForcedMetadataUpdateInput;
  create: MetadataPhotoStorageWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPrepressPictureEditingListUpdateInput {
  where: MetadataPrepressPictureEditingWhereUniqueInput;
  update: MetadataPrepressPictureEditingWithForcedMetadataUpdateInput;
  create: MetadataPrepressPictureEditingWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPriceLevelListUpdateInput {
  where: MetadataPriceLevelWhereUniqueInput;
  update: MetadataPriceLevelWithForcedMetadataUpdateInput;
  create: MetadataPriceLevelWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPrintingProfileListUpdateInput {
  where: MetadataPrintingProfileWhereUniqueInput;
  update: MetadataPrintingProfileWithForcedMetadataUpdateInput;
  create: MetadataPrintingProfileWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPrismaProductionListUpdateInput {
  where: MetadataPrismaProductionWhereUniqueInput;
  update: MetadataPrismaProductionWithForcedMetadataUpdateInput;
  create: MetadataPrismaProductionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataProcessHistoryListUpdateInput {
  where: MetadataProcessHistoryWhereUniqueInput;
  update: MetadataProcessHistoryWithForcedMetadataUpdateInput;
  create: MetadataProcessHistoryWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataProcessParameterListUpdateInput {
  where: MetadataProcessParameterWhereUniqueInput;
  update: MetadataProcessParameterWithForcedMetadataUpdateInput;
  create: MetadataProcessParameterWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataProcessStatusListUpdateInput {
  where: MetadataProcessStatusWhereUniqueInput;
  update: MetadataProcessStatusWithForcedMetadataUpdateInput;
  create: MetadataProcessStatusWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataProductListUpdateInput {
  where: MetadataProductWhereUniqueInput;
  update: MetadataProductWithForcedMetadataUpdateInput;
  create: MetadataProductWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataProductShortNameListUpdateInput {
  where: MetadataProductShortNameWhereUniqueInput;
  update: MetadataProductShortNameWithForcedMetadataUpdateInput;
  create: MetadataProductShortNameWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataProductsListUpdateInput {
  where: MetadataProductsWhereUniqueInput;
  update: MetadataProductsWithForcedMetadataUpdateInput;
  create: MetadataProductsWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataProduitsListUpdateInput {
  where: MetadataProduitsWhereUniqueInput;
  update: MetadataProduitsWithForcedMetadataUpdateInput;
  create: MetadataProduitsWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataProgramVersionListUpdateInput {
  where: MetadataProgramVersionWhereUniqueInput;
  update: MetadataProgramVersionWithForcedMetadataUpdateInput;
  create: MetadataProgramVersionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPropertyReleaseIdListUpdateInput {
  where: MetadataPropertyReleaseIdWhereUniqueInput;
  update: MetadataPropertyReleaseIdWithForcedMetadataUpdateInput;
  create: MetadataPropertyReleaseIdWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPropertyReleaseStatusListUpdateInput {
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
  update: MetadataPropertyReleaseStatusWithForcedMetadataUpdateInput;
  create: MetadataPropertyReleaseStatusWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPublisherListUpdateInput {
  where: MetadataPublisherWhereUniqueInput;
  update: MetadataPublisherWithForcedMetadataUpdateInput;
  create: MetadataPublisherWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPublishingIssueListUpdateInput {
  where: MetadataPublishingIssueWhereUniqueInput;
  update: MetadataPublishingIssueWithForcedMetadataUpdateInput;
  create: MetadataPublishingIssueWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataPublishingSubjectListUpdateInput {
  where: MetadataPublishingSubjectWhereUniqueInput;
  update: MetadataPublishingSubjectWithForcedMetadataUpdateInput;
  create: MetadataPublishingSubjectWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataQualifiedUsePrismaByListUpdateInput {
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
  update: MetadataQualifiedUsePrismaByWithForcedMetadataUpdateInput;
  create: MetadataQualifiedUsePrismaByWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataQualifiedUsePrismaDateListUpdateInput {
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
  update: MetadataQualifiedUsePrismaDateWithForcedMetadataUpdateInput;
  create: MetadataQualifiedUsePrismaDateWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataQualifiedUsePrismaDurationListUpdateInput {
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
  update: MetadataQualifiedUsePrismaDurationWithForcedMetadataUpdateInput;
  create: MetadataQualifiedUsePrismaDurationWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataQualifiedUsePrismaSupportListUpdateInput {
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
  update: MetadataQualifiedUsePrismaSupportWithForcedMetadataUpdateInput;
  create: MetadataQualifiedUsePrismaSupportWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataRatingListUpdateInput {
  where: MetadataRatingWhereUniqueInput;
  update: MetadataRatingWithForcedMetadataUpdateInput;
  create: MetadataRatingWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataReferenceDateListUpdateInput {
  where: MetadataReferenceDateWhereUniqueInput;
  update: MetadataReferenceDateWithForcedMetadataUpdateInput;
  create: MetadataReferenceDateWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataReferenceNumberListUpdateInput {
  where: MetadataReferenceNumberWhereUniqueInput;
  update: MetadataReferenceNumberWithForcedMetadataUpdateInput;
  create: MetadataReferenceNumberWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataReferenceServiceListUpdateInput {
  where: MetadataReferenceServiceWhereUniqueInput;
  update: MetadataReferenceServiceWithForcedMetadataUpdateInput;
  create: MetadataReferenceServiceWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataReleaseDateListUpdateInput {
  where: MetadataReleaseDateWhereUniqueInput;
  update: MetadataReleaseDateWithForcedMetadataUpdateInput;
  create: MetadataReleaseDateWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataReleaseTimeListUpdateInput {
  where: MetadataReleaseTimeWhereUniqueInput;
  update: MetadataReleaseTimeWithForcedMetadataUpdateInput;
  create: MetadataReleaseTimeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataRequiredPermissionListUpdateInput {
  where: MetadataRequiredPermissionWhereUniqueInput;
  update: MetadataRequiredPermissionWithForcedMetadataUpdateInput;
  create: MetadataRequiredPermissionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataResolutionKindListUpdateInput {
  where: MetadataResolutionKindWhereUniqueInput;
  update: MetadataResolutionKindWithForcedMetadataUpdateInput;
  create: MetadataResolutionKindWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataRightsListUpdateInput {
  where: MetadataRightsWhereUniqueInput;
  update: MetadataRightsWithForcedMetadataUpdateInput;
  create: MetadataRightsWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataRoyaltyfreeListUpdateInput {
  where: MetadataRoyaltyfreeWhereUniqueInput;
  update: MetadataRoyaltyfreeWithForcedMetadataUpdateInput;
  create: MetadataRoyaltyfreeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataSceneListUpdateInput {
  where: MetadataSceneWhereUniqueInput;
  update: MetadataSceneWithForcedMetadataUpdateInput;
  create: MetadataSceneWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataSectionListUpdateInput {
  where: MetadataSectionWhereUniqueInput;
  update: MetadataSectionWithForcedMetadataUpdateInput;
  create: MetadataSectionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataSentToListUpdateInput {
  where: MetadataSentToWhereUniqueInput;
  update: MetadataSentToWithForcedMetadataUpdateInput;
  create: MetadataSentToWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataSerialNumberListUpdateInput {
  where: MetadataSerialNumberWhereUniqueInput;
  update: MetadataSerialNumberWithForcedMetadataUpdateInput;
  create: MetadataSerialNumberWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataSeriesDateTimeListUpdateInput {
  where: MetadataSeriesDateTimeWhereUniqueInput;
  update: MetadataSeriesDateTimeWithForcedMetadataUpdateInput;
  create: MetadataSeriesDateTimeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataSeriesDescriptionListUpdateInput {
  where: MetadataSeriesDescriptionWhereUniqueInput;
  update: MetadataSeriesDescriptionWithForcedMetadataUpdateInput;
  create: MetadataSeriesDescriptionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataSeriesModalityListUpdateInput {
  where: MetadataSeriesModalityWhereUniqueInput;
  update: MetadataSeriesModalityWithForcedMetadataUpdateInput;
  create: MetadataSeriesModalityWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataSeriesNumberListUpdateInput {
  where: MetadataSeriesNumberWhereUniqueInput;
  update: MetadataSeriesNumberWithForcedMetadataUpdateInput;
  create: MetadataSeriesNumberWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataShortUniqueIdListUpdateInput {
  where: MetadataShortUniqueIdWhereUniqueInput;
  update: MetadataShortUniqueIdWithForcedMetadataUpdateInput;
  create: MetadataShortUniqueIdWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataSourceListUpdateInput {
  where: MetadataSourceWhereUniqueInput;
  update: MetadataSourceWithForcedMetadataUpdateInput;
  create: MetadataSourceWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataStateListUpdateInput {
  where: MetadataStateWhereUniqueInput;
  update: MetadataStateWithForcedMetadataUpdateInput;
  create: MetadataStateWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataStudyDateTimeListUpdateInput {
  where: MetadataStudyDateTimeWhereUniqueInput;
  update: MetadataStudyDateTimeWithForcedMetadataUpdateInput;
  create: MetadataStudyDateTimeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataStudyDescriptionListUpdateInput {
  where: MetadataStudyDescriptionWhereUniqueInput;
  update: MetadataStudyDescriptionWithForcedMetadataUpdateInput;
  create: MetadataStudyDescriptionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataStudyIdListUpdateInput {
  where: MetadataStudyIdWhereUniqueInput;
  update: MetadataStudyIdWithForcedMetadataUpdateInput;
  create: MetadataStudyIdWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataStudyPhysicianListUpdateInput {
  where: MetadataStudyPhysicianWhereUniqueInput;
  update: MetadataStudyPhysicianWithForcedMetadataUpdateInput;
  create: MetadataStudyPhysicianWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataSubjectCodeListUpdateInput {
  where: MetadataSubjectCodeWhereUniqueInput;
  update: MetadataSubjectCodeWithForcedMetadataUpdateInput;
  create: MetadataSubjectCodeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataSubjectListUpdateInput {
  where: MetadataSubjectWhereUniqueInput;
  update: MetadataSubjectWithForcedMetadataUpdateInput;
  create: MetadataSubjectWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataSubjectPrismaListUpdateInput {
  where: MetadataSubjectPrismaWhereUniqueInput;
  update: MetadataSubjectPrismaWithForcedMetadataUpdateInput;
  create: MetadataSubjectPrismaWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataSupplementalCategoriesListUpdateInput {
  where: MetadataSupplementalCategoriesWhereUniqueInput;
  update: MetadataSupplementalCategoriesWithForcedMetadataUpdateInput;
  create: MetadataSupplementalCategoriesWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataTargetVersionListUpdateInput {
  where: MetadataTargetVersionWhereUniqueInput;
  update: MetadataTargetVersionWithForcedMetadataUpdateInput;
  create: MetadataTargetVersionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataTitleListUpdateInput {
  where: MetadataTitleWhereUniqueInput;
  update: MetadataTitleWithForcedMetadataUpdateInput;
  create: MetadataTitleWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataToneCurveListUpdateInput {
  where: MetadataToneCurveWhereUniqueInput;
  update: MetadataToneCurveWithForcedMetadataUpdateInput;
  create: MetadataToneCurveWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataTransferredByEmailListUpdateInput {
  where: MetadataTransferredByEmailWhereUniqueInput;
  update: MetadataTransferredByEmailWithForcedMetadataUpdateInput;
  create: MetadataTransferredByEmailWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataTransferredByFullNameListUpdateInput {
  where: MetadataTransferredByFullNameWhereUniqueInput;
  update: MetadataTransferredByFullNameWithForcedMetadataUpdateInput;
  create: MetadataTransferredByFullNameWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataTransferredByListUpdateInput {
  where: MetadataTransferredByWhereUniqueInput;
  update: MetadataTransferredByWithForcedMetadataUpdateInput;
  create: MetadataTransferredByWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataTransmissionReferenceListUpdateInput {
  where: MetadataTransmissionReferenceWhereUniqueInput;
  update: MetadataTransmissionReferenceWithForcedMetadataUpdateInput;
  create: MetadataTransmissionReferenceWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataTypeListUpdateInput {
  where: MetadataTypeWhereUniqueInput;
  update: MetadataTypeWithForcedMetadataUpdateInput;
  create: MetadataTypeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUnderUnderSubjectListUpdateInput {
  where: MetadataUnderUnderSubjectWhereUniqueInput;
  update: MetadataUnderUnderSubjectWithForcedMetadataUpdateInput;
  create: MetadataUnderUnderSubjectWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUndersubjectListUpdateInput {
  where: MetadataUndersubjectWhereUniqueInput;
  update: MetadataUndersubjectWithForcedMetadataUpdateInput;
  create: MetadataUndersubjectWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUniqueIdListUpdateInput {
  where: MetadataUniqueIdWhereUniqueInput;
  update: MetadataUniqueIdWithForcedMetadataUpdateInput;
  create: MetadataUniqueIdWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUnitListUpdateInput {
  where: MetadataUnitWhereUniqueInput;
  update: MetadataUnitWithForcedMetadataUpdateInput;
  create: MetadataUnitWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUnitShortNameListUpdateInput {
  where: MetadataUnitShortNameWhereUniqueInput;
  update: MetadataUnitShortNameWithForcedMetadataUpdateInput;
  create: MetadataUnitShortNameWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUploadTimeListUpdateInput {
  where: MetadataUploadTimeWhereUniqueInput;
  update: MetadataUploadTimeWithForcedMetadataUpdateInput;
  create: MetadataUploadTimeWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUploadedByFullNameListUpdateInput {
  where: MetadataUploadedByFullNameWhereUniqueInput;
  update: MetadataUploadedByFullNameWithForcedMetadataUpdateInput;
  create: MetadataUploadedByFullNameWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUploadedByListUpdateInput {
  where: MetadataUploadedByWhereUniqueInput;
  update: MetadataUploadedByWithForcedMetadataUpdateInput;
  create: MetadataUploadedByWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUrgencyListUpdateInput {
  where: MetadataUrgencyWhereUniqueInput;
  update: MetadataUrgencyWithForcedMetadataUpdateInput;
  create: MetadataUrgencyWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUsageTermsListUpdateInput {
  where: MetadataUsageTermsWhereUniqueInput;
  update: MetadataUsageTermsWithForcedMetadataUpdateInput;
  create: MetadataUsageTermsWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUserCommentListUpdateInput {
  where: MetadataUserCommentWhereUniqueInput;
  update: MetadataUserCommentWithForcedMetadataUpdateInput;
  create: MetadataUserCommentWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUserDefined195ListUpdateInput {
  where: MetadataUserDefined195WhereUniqueInput;
  update: MetadataUserDefined195WithForcedMetadataUpdateInput;
  create: MetadataUserDefined195WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUserDefined237ListUpdateInput {
  where: MetadataUserDefined237WhereUniqueInput;
  update: MetadataUserDefined237WithForcedMetadataUpdateInput;
  create: MetadataUserDefined237WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUserDefined238ListUpdateInput {
  where: MetadataUserDefined238WhereUniqueInput;
  update: MetadataUserDefined238WithForcedMetadataUpdateInput;
  create: MetadataUserDefined238WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUserDefined239ListUpdateInput {
  where: MetadataUserDefined239WhereUniqueInput;
  update: MetadataUserDefined239WithForcedMetadataUpdateInput;
  create: MetadataUserDefined239WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUserDefined242ListUpdateInput {
  where: MetadataUserDefined242WhereUniqueInput;
  update: MetadataUserDefined242WithForcedMetadataUpdateInput;
  create: MetadataUserDefined242WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataUserDefined62ListUpdateInput {
  where: MetadataUserDefined62WhereUniqueInput;
  update: MetadataUserDefined62WithForcedMetadataUpdateInput;
  create: MetadataUserDefined62WithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataValidListUpdateInput {
  where: MetadataValidWhereUniqueInput;
  update: MetadataValidWithForcedMetadataUpdateInput;
  create: MetadataValidWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataVersionListUpdateInput {
  where: MetadataVersionWhereUniqueInput;
  update: MetadataVersionWithForcedMetadataUpdateInput;
  create: MetadataVersionWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataWebStatementListUpdateInput {
  where: MetadataWebStatementWhereUniqueInput;
  update: MetadataWebStatementWithForcedMetadataUpdateInput;
  create: MetadataWebStatementWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataWorkflowKindListUpdateInput {
  where: MetadataWorkflowKindWhereUniqueInput;
  update: MetadataWorkflowKindWithForcedMetadataUpdateInput;
  create: MetadataWorkflowKindWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataXmpFileStampsListUpdateInput {
  where: MetadataXmpFileStampsWhereUniqueInput;
  update: MetadataXmpFileStampsWithForcedMetadataUpdateInput;
  create: MetadataXmpFileStampsWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertMetadataXmpHistoryListUpdateInput {
  where: MetadataXmpHistoryWhereUniqueInput;
  update: MetadataXmpHistoryWithForcedMetadataUpdateInput;
  create: MetadataXmpHistoryWithForcedMetadataCreateInput;
}

export interface MetadataNestedUpsertPhotoMetadatasUpdateInput {
  where: PhotoMetadataWhereUniqueInput;
  update: PhotoMetadataWithForcedMetadataUpdateInput;
  create: PhotoMetadataWithForcedMetadataCreateInput;
}

export interface MetadataObjectCycleNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataObjectCycleNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataObjectCycleNestedUpsertArchiveCreateInput | null;
}

export interface MetadataObjectCycleNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataObjectCycleNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataObjectCycleNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataObjectCycleNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataObjectCycleNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataObjectCycleNestedUpsertMetadataCreateInput | null;
}

export interface MetadataObjectCycleNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataObjectCycleNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataObjectCycleNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataObjectCycleNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataObjectCycleNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataObjectCycleNestedUpsertPhotoCreateInput | null;
}

export interface MetadataObjectCycleNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataObjectCycleNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataObjectCycleNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataObjectCycleNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataObjectCycleNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataObjectCycleNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataObjectCycleNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataObjectCycleNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataObjectCycleNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataObjectCycleNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataObjectCycleNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataObjectCycleNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataObjectCycleNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataObjectCycleNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataObjectCycleNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataObjectCycle" nodes by specifying some conditions
 */
export interface MetadataObjectCycleWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataObjectCycleWhereInput[] | null;
  OR?: MetadataObjectCycleWhereInput[] | null;
  NOT?: MetadataObjectCycleWhereInput | null;
}

/**
 * Identifies exactly one "MetadataObjectCycle" node.
 */
export interface MetadataObjectCycleWhereUniqueInput {
  id: number;
}

export interface MetadataObjectCycleWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataObjectCycleNestedPhotoCreateInput;
  metadata: MetadataObjectCycleNestedMetadataCreateInput;
}

export interface MetadataObjectCycleWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataObjectCycleNestedPhotoUpdateInput | null;
  metadata?: MetadataObjectCycleNestedMetadataUpdateInput | null;
}

export interface MetadataObjectCycleWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataObjectCycleNestedPhotoCreateInput;
  archive: MetadataObjectCycleNestedArchiveCreateInput;
}

export interface MetadataObjectCycleWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataObjectCycleNestedPhotoUpdateInput | null;
  archive?: MetadataObjectCycleNestedArchiveUpdateInput | null;
}

export interface MetadataObjectCycleWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataObjectCycleNestedMetadataCreateInput;
  archive: MetadataObjectCycleNestedArchiveCreateInput;
}

export interface MetadataObjectCycleWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataObjectCycleNestedMetadataUpdateInput | null;
  archive?: MetadataObjectCycleNestedArchiveUpdateInput | null;
}

export interface MetadataOrganisationInImageNameNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataOrganisationInImageNameNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataOrganisationInImageNameNestedUpsertArchiveCreateInput | null;
}

export interface MetadataOrganisationInImageNameNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataOrganisationInImageNameNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataOrganisationInImageNameNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataOrganisationInImageNameNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataOrganisationInImageNameNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataOrganisationInImageNameNestedUpsertMetadataCreateInput | null;
}

export interface MetadataOrganisationInImageNameNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataOrganisationInImageNameNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataOrganisationInImageNameNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataOrganisationInImageNameNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataOrganisationInImageNameNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataOrganisationInImageNameNestedUpsertPhotoCreateInput | null;
}

export interface MetadataOrganisationInImageNameNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataOrganisationInImageNameNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataOrganisationInImageNameNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataOrganisationInImageNameNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataOrganisationInImageNameNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataOrganisationInImageNameNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataOrganisationInImageNameNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataOrganisationInImageNameNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataOrganisationInImageNameNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataOrganisationInImageNameNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataOrganisationInImageNameNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataOrganisationInImageNameNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataOrganisationInImageNameNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataOrganisationInImageNameNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataOrganisationInImageNameNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataOrganisationInImageName" nodes by specifying some conditions
 */
export interface MetadataOrganisationInImageNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataOrganisationInImageNameWhereInput[] | null;
  OR?: MetadataOrganisationInImageNameWhereInput[] | null;
  NOT?: MetadataOrganisationInImageNameWhereInput | null;
}

/**
 * Identifies exactly one "MetadataOrganisationInImageName" node.
 */
export interface MetadataOrganisationInImageNameWhereUniqueInput {
  id: number;
}

export interface MetadataOrganisationInImageNameWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataOrganisationInImageNameNestedPhotoCreateInput;
  metadata: MetadataOrganisationInImageNameNestedMetadataCreateInput;
}

export interface MetadataOrganisationInImageNameWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataOrganisationInImageNameNestedPhotoUpdateInput | null;
  metadata?: MetadataOrganisationInImageNameNestedMetadataUpdateInput | null;
}

export interface MetadataOrganisationInImageNameWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataOrganisationInImageNameNestedPhotoCreateInput;
  archive: MetadataOrganisationInImageNameNestedArchiveCreateInput;
}

export interface MetadataOrganisationInImageNameWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataOrganisationInImageNameNestedPhotoUpdateInput | null;
  archive?: MetadataOrganisationInImageNameNestedArchiveUpdateInput | null;
}

export interface MetadataOrganisationInImageNameWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataOrganisationInImageNameNestedMetadataCreateInput;
  archive: MetadataOrganisationInImageNameNestedArchiveCreateInput;
}

export interface MetadataOrganisationInImageNameWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataOrganisationInImageNameNestedMetadataUpdateInput | null;
  archive?: MetadataOrganisationInImageNameNestedArchiveUpdateInput | null;
}

export interface MetadataOriginalColorNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataOriginalColorNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataOriginalColorNestedUpsertArchiveCreateInput | null;
}

export interface MetadataOriginalColorNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataOriginalColorNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataOriginalColorNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataOriginalColorNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataOriginalColorNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataOriginalColorNestedUpsertMetadataCreateInput | null;
}

export interface MetadataOriginalColorNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataOriginalColorNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataOriginalColorNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataOriginalColorNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataOriginalColorNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataOriginalColorNestedUpsertPhotoCreateInput | null;
}

export interface MetadataOriginalColorNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataOriginalColorNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataOriginalColorNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataOriginalColorNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataOriginalColorNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataOriginalColorNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataOriginalColorNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataOriginalColorNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataOriginalColorNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataOriginalColorNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataOriginalColorNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataOriginalColorNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataOriginalColorNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataOriginalColorNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataOriginalColorNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataOriginalColor" nodes by specifying some conditions
 */
export interface MetadataOriginalColorWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataOriginalColorWhereInput[] | null;
  OR?: MetadataOriginalColorWhereInput[] | null;
  NOT?: MetadataOriginalColorWhereInput | null;
}

/**
 * Identifies exactly one "MetadataOriginalColor" node.
 */
export interface MetadataOriginalColorWhereUniqueInput {
  id: number;
}

export interface MetadataOriginalColorWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataOriginalColorNestedPhotoCreateInput;
  metadata: MetadataOriginalColorNestedMetadataCreateInput;
}

export interface MetadataOriginalColorWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataOriginalColorNestedPhotoUpdateInput | null;
  metadata?: MetadataOriginalColorNestedMetadataUpdateInput | null;
}

export interface MetadataOriginalColorWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataOriginalColorNestedPhotoCreateInput;
  archive: MetadataOriginalColorNestedArchiveCreateInput;
}

export interface MetadataOriginalColorWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataOriginalColorNestedPhotoUpdateInput | null;
  archive?: MetadataOriginalColorNestedArchiveUpdateInput | null;
}

export interface MetadataOriginalColorWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataOriginalColorNestedMetadataCreateInput;
  archive: MetadataOriginalColorNestedArchiveCreateInput;
}

export interface MetadataOriginalColorWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataOriginalColorNestedMetadataUpdateInput | null;
  archive?: MetadataOriginalColorNestedArchiveUpdateInput | null;
}

export interface MetadataOriginalIccProfileNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataOriginalIccProfileNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataOriginalIccProfileNestedUpsertArchiveCreateInput | null;
}

export interface MetadataOriginalIccProfileNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataOriginalIccProfileNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataOriginalIccProfileNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataOriginalIccProfileNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataOriginalIccProfileNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataOriginalIccProfileNestedUpsertMetadataCreateInput | null;
}

export interface MetadataOriginalIccProfileNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataOriginalIccProfileNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataOriginalIccProfileNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataOriginalIccProfileNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataOriginalIccProfileNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataOriginalIccProfileNestedUpsertPhotoCreateInput | null;
}

export interface MetadataOriginalIccProfileNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataOriginalIccProfileNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataOriginalIccProfileNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataOriginalIccProfileNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataOriginalIccProfileNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataOriginalIccProfileNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataOriginalIccProfileNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataOriginalIccProfileNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataOriginalIccProfileNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataOriginalIccProfileNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataOriginalIccProfileNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataOriginalIccProfileNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataOriginalIccProfileNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataOriginalIccProfileNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataOriginalIccProfileNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataOriginalIccProfile" nodes by specifying some conditions
 */
export interface MetadataOriginalIccProfileWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataOriginalIccProfileWhereInput[] | null;
  OR?: MetadataOriginalIccProfileWhereInput[] | null;
  NOT?: MetadataOriginalIccProfileWhereInput | null;
}

/**
 * Identifies exactly one "MetadataOriginalIccProfile" node.
 */
export interface MetadataOriginalIccProfileWhereUniqueInput {
  id: number;
}

export interface MetadataOriginalIccProfileWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataOriginalIccProfileNestedPhotoCreateInput;
  metadata: MetadataOriginalIccProfileNestedMetadataCreateInput;
}

export interface MetadataOriginalIccProfileWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataOriginalIccProfileNestedPhotoUpdateInput | null;
  metadata?: MetadataOriginalIccProfileNestedMetadataUpdateInput | null;
}

export interface MetadataOriginalIccProfileWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataOriginalIccProfileNestedPhotoCreateInput;
  archive: MetadataOriginalIccProfileNestedArchiveCreateInput;
}

export interface MetadataOriginalIccProfileWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataOriginalIccProfileNestedPhotoUpdateInput | null;
  archive?: MetadataOriginalIccProfileNestedArchiveUpdateInput | null;
}

export interface MetadataOriginalIccProfileWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataOriginalIccProfileNestedMetadataCreateInput;
  archive: MetadataOriginalIccProfileNestedArchiveCreateInput;
}

export interface MetadataOriginalIccProfileWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataOriginalIccProfileNestedMetadataUpdateInput | null;
  archive?: MetadataOriginalIccProfileNestedArchiveUpdateInput | null;
}

export interface MetadataOriginalNameNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataOriginalNameNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataOriginalNameNestedUpsertArchiveCreateInput | null;
}

export interface MetadataOriginalNameNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataOriginalNameNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataOriginalNameNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataOriginalNameNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataOriginalNameNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataOriginalNameNestedUpsertMetadataCreateInput | null;
}

export interface MetadataOriginalNameNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataOriginalNameNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataOriginalNameNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataOriginalNameNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataOriginalNameNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataOriginalNameNestedUpsertPhotoCreateInput | null;
}

export interface MetadataOriginalNameNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataOriginalNameNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataOriginalNameNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataOriginalNameNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataOriginalNameNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataOriginalNameNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataOriginalNameNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataOriginalNameNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataOriginalNameNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataOriginalNameNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataOriginalNameNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataOriginalNameNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataOriginalNameNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataOriginalNameNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataOriginalNameNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataOriginalName" nodes by specifying some conditions
 */
export interface MetadataOriginalNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataOriginalNameWhereInput[] | null;
  OR?: MetadataOriginalNameWhereInput[] | null;
  NOT?: MetadataOriginalNameWhereInput | null;
}

/**
 * Identifies exactly one "MetadataOriginalName" node.
 */
export interface MetadataOriginalNameWhereUniqueInput {
  id: number;
}

export interface MetadataOriginalNameWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataOriginalNameNestedPhotoCreateInput;
  metadata: MetadataOriginalNameNestedMetadataCreateInput;
}

export interface MetadataOriginalNameWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataOriginalNameNestedPhotoUpdateInput | null;
  metadata?: MetadataOriginalNameNestedMetadataUpdateInput | null;
}

export interface MetadataOriginalNameWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataOriginalNameNestedPhotoCreateInput;
  archive: MetadataOriginalNameNestedArchiveCreateInput;
}

export interface MetadataOriginalNameWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataOriginalNameNestedPhotoUpdateInput | null;
  archive?: MetadataOriginalNameNestedArchiveUpdateInput | null;
}

export interface MetadataOriginalNameWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataOriginalNameNestedMetadataCreateInput;
  archive: MetadataOriginalNameNestedArchiveCreateInput;
}

export interface MetadataOriginalNameWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataOriginalNameNestedMetadataUpdateInput | null;
  archive?: MetadataOriginalNameNestedArchiveUpdateInput | null;
}

export interface MetadataOriginalWeigthNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataOriginalWeigthNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataOriginalWeigthNestedUpsertArchiveCreateInput | null;
}

export interface MetadataOriginalWeigthNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataOriginalWeigthNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataOriginalWeigthNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataOriginalWeigthNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataOriginalWeigthNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataOriginalWeigthNestedUpsertMetadataCreateInput | null;
}

export interface MetadataOriginalWeigthNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataOriginalWeigthNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataOriginalWeigthNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataOriginalWeigthNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataOriginalWeigthNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataOriginalWeigthNestedUpsertPhotoCreateInput | null;
}

export interface MetadataOriginalWeigthNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataOriginalWeigthNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataOriginalWeigthNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataOriginalWeigthNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataOriginalWeigthNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataOriginalWeigthNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataOriginalWeigthNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataOriginalWeigthNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataOriginalWeigthNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataOriginalWeigthNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataOriginalWeigthNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataOriginalWeigthNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataOriginalWeigthNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataOriginalWeigthNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataOriginalWeigthNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataOriginalWeigth" nodes by specifying some conditions
 */
export interface MetadataOriginalWeigthWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataOriginalWeigthWhereInput[] | null;
  OR?: MetadataOriginalWeigthWhereInput[] | null;
  NOT?: MetadataOriginalWeigthWhereInput | null;
}

/**
 * Identifies exactly one "MetadataOriginalWeigth" node.
 */
export interface MetadataOriginalWeigthWhereUniqueInput {
  id: number;
}

export interface MetadataOriginalWeigthWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataOriginalWeigthNestedPhotoCreateInput;
  metadata: MetadataOriginalWeigthNestedMetadataCreateInput;
}

export interface MetadataOriginalWeigthWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataOriginalWeigthNestedPhotoUpdateInput | null;
  metadata?: MetadataOriginalWeigthNestedMetadataUpdateInput | null;
}

export interface MetadataOriginalWeigthWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataOriginalWeigthNestedPhotoCreateInput;
  archive: MetadataOriginalWeigthNestedArchiveCreateInput;
}

export interface MetadataOriginalWeigthWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataOriginalWeigthNestedPhotoUpdateInput | null;
  archive?: MetadataOriginalWeigthNestedArchiveUpdateInput | null;
}

export interface MetadataOriginalWeigthWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataOriginalWeigthNestedMetadataCreateInput;
  archive: MetadataOriginalWeigthNestedArchiveCreateInput;
}

export interface MetadataOriginalWeigthWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataOriginalWeigthNestedMetadataUpdateInput | null;
  archive?: MetadataOriginalWeigthNestedArchiveUpdateInput | null;
}

export interface MetadataOwnerIdNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataOwnerIdNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataOwnerIdNestedUpsertArchiveCreateInput | null;
}

export interface MetadataOwnerIdNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataOwnerIdNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataOwnerIdNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataOwnerIdNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataOwnerIdNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataOwnerIdNestedUpsertMetadataCreateInput | null;
}

export interface MetadataOwnerIdNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataOwnerIdNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataOwnerIdNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataOwnerIdNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataOwnerIdNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataOwnerIdNestedUpsertPhotoCreateInput | null;
}

export interface MetadataOwnerIdNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataOwnerIdNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataOwnerIdNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataOwnerIdNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataOwnerIdNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataOwnerIdNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataOwnerIdNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataOwnerIdNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataOwnerIdNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataOwnerIdNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataOwnerIdNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataOwnerIdNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataOwnerIdNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataOwnerIdNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataOwnerIdNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataOwnerId" nodes by specifying some conditions
 */
export interface MetadataOwnerIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataOwnerIdWhereInput[] | null;
  OR?: MetadataOwnerIdWhereInput[] | null;
  NOT?: MetadataOwnerIdWhereInput | null;
}

/**
 * Identifies exactly one "MetadataOwnerId" node.
 */
export interface MetadataOwnerIdWhereUniqueInput {
  id: number;
}

export interface MetadataOwnerIdWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataOwnerIdNestedPhotoCreateInput;
  metadata: MetadataOwnerIdNestedMetadataCreateInput;
}

export interface MetadataOwnerIdWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataOwnerIdNestedPhotoUpdateInput | null;
  metadata?: MetadataOwnerIdNestedMetadataUpdateInput | null;
}

export interface MetadataOwnerIdWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataOwnerIdNestedPhotoCreateInput;
  archive: MetadataOwnerIdNestedArchiveCreateInput;
}

export interface MetadataOwnerIdWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataOwnerIdNestedPhotoUpdateInput | null;
  archive?: MetadataOwnerIdNestedArchiveUpdateInput | null;
}

export interface MetadataOwnerIdWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataOwnerIdNestedMetadataCreateInput;
  archive: MetadataOwnerIdNestedArchiveCreateInput;
}

export interface MetadataOwnerIdWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataOwnerIdNestedMetadataUpdateInput | null;
  archive?: MetadataOwnerIdNestedArchiveUpdateInput | null;
}

export interface MetadataOwnerNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataOwnerNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataOwnerNestedUpsertArchiveCreateInput | null;
}

export interface MetadataOwnerNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataOwnerNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataOwnerNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataOwnerNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataOwnerNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataOwnerNestedUpsertMetadataCreateInput | null;
}

export interface MetadataOwnerNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataOwnerNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataOwnerNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataOwnerNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataOwnerNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataOwnerNestedUpsertPhotoCreateInput | null;
}

export interface MetadataOwnerNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataOwnerNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataOwnerNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataOwnerNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataOwnerNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataOwnerNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataOwnerNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataOwnerNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataOwnerNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataOwnerNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataOwnerNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataOwnerNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataOwnerNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataOwnerNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataOwnerNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataOwner" nodes by specifying some conditions
 */
export interface MetadataOwnerWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataOwnerWhereInput[] | null;
  OR?: MetadataOwnerWhereInput[] | null;
  NOT?: MetadataOwnerWhereInput | null;
}

/**
 * Identifies exactly one "MetadataOwner" node.
 */
export interface MetadataOwnerWhereUniqueInput {
  id: number;
}

export interface MetadataOwnerWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataOwnerNestedPhotoCreateInput;
  metadata: MetadataOwnerNestedMetadataCreateInput;
}

export interface MetadataOwnerWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataOwnerNestedPhotoUpdateInput | null;
  metadata?: MetadataOwnerNestedMetadataUpdateInput | null;
}

export interface MetadataOwnerWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataOwnerNestedPhotoCreateInput;
  archive: MetadataOwnerNestedArchiveCreateInput;
}

export interface MetadataOwnerWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataOwnerNestedPhotoUpdateInput | null;
  archive?: MetadataOwnerNestedArchiveUpdateInput | null;
}

export interface MetadataOwnerWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataOwnerNestedMetadataCreateInput;
  archive: MetadataOwnerNestedArchiveCreateInput;
}

export interface MetadataOwnerWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataOwnerNestedMetadataUpdateInput | null;
  archive?: MetadataOwnerNestedArchiveUpdateInput | null;
}

export interface MetadataPageNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPageNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPageNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPageNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPageNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPageNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPageNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPageNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPageNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPageNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPageNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPageNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPageNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPageNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPageNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPageNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPageNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPageNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPageNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPageNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPageNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPageNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPageNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPageNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPageNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPageNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPageNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPageNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPageNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPageNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPagePrismaNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPagePrismaNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPagePrismaNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPagePrismaNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPagePrismaNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPagePrismaNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPagePrismaNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPagePrismaNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPagePrismaNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPagePrismaNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPagePrismaNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPagePrismaNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPagePrismaNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPagePrismaNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPagePrismaNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPagePrismaNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPagePrismaNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPagePrismaNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPagePrismaNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPagePrismaNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPagePrismaNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPagePrismaNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPagePrismaNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPagePrismaNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPagePrismaNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPagePrismaNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPagePrismaNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPagePrismaNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPagePrismaNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPagePrismaNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPagePrisma" nodes by specifying some conditions
 */
export interface MetadataPagePrismaWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPagePrismaWhereInput[] | null;
  OR?: MetadataPagePrismaWhereInput[] | null;
  NOT?: MetadataPagePrismaWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPagePrisma" node.
 */
export interface MetadataPagePrismaWhereUniqueInput {
  id: number;
}

export interface MetadataPagePrismaWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPagePrismaNestedPhotoCreateInput;
  metadata: MetadataPagePrismaNestedMetadataCreateInput;
}

export interface MetadataPagePrismaWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPagePrismaNestedPhotoUpdateInput | null;
  metadata?: MetadataPagePrismaNestedMetadataUpdateInput | null;
}

export interface MetadataPagePrismaWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPagePrismaNestedPhotoCreateInput;
  archive: MetadataPagePrismaNestedArchiveCreateInput;
}

export interface MetadataPagePrismaWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPagePrismaNestedPhotoUpdateInput | null;
  archive?: MetadataPagePrismaNestedArchiveUpdateInput | null;
}

export interface MetadataPagePrismaWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPagePrismaNestedMetadataCreateInput;
  archive: MetadataPagePrismaNestedArchiveCreateInput;
}

export interface MetadataPagePrismaWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPagePrismaNestedMetadataUpdateInput | null;
  archive?: MetadataPagePrismaNestedArchiveUpdateInput | null;
}

/**
 * Filters the "MetadataPage" nodes by specifying some conditions
 */
export interface MetadataPageWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPageWhereInput[] | null;
  OR?: MetadataPageWhereInput[] | null;
  NOT?: MetadataPageWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPage" node.
 */
export interface MetadataPageWhereUniqueInput {
  id: number;
}

export interface MetadataPageWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPageNestedPhotoCreateInput;
  metadata: MetadataPageNestedMetadataCreateInput;
}

export interface MetadataPageWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPageNestedPhotoUpdateInput | null;
  metadata?: MetadataPageNestedMetadataUpdateInput | null;
}

export interface MetadataPageWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPageNestedPhotoCreateInput;
  archive: MetadataPageNestedArchiveCreateInput;
}

export interface MetadataPageWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPageNestedPhotoUpdateInput | null;
  archive?: MetadataPageNestedArchiveUpdateInput | null;
}

export interface MetadataPageWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPageNestedMetadataCreateInput;
  archive: MetadataPageNestedArchiveCreateInput;
}

export interface MetadataPageWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPageNestedMetadataUpdateInput | null;
  archive?: MetadataPageNestedArchiveUpdateInput | null;
}

export interface MetadataPaintBasedCorrectionsNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPaintBasedCorrectionsNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPaintBasedCorrectionsNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPaintBasedCorrectionsNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPaintBasedCorrectionsNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPaintBasedCorrectionsNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPaintBasedCorrectionsNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPaintBasedCorrectionsNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPaintBasedCorrectionsNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPaintBasedCorrectionsNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPaintBasedCorrectionsNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPaintBasedCorrectionsNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPaintBasedCorrectionsNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPaintBasedCorrectionsNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPaintBasedCorrectionsNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPaintBasedCorrectionsNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPaintBasedCorrectionsNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPaintBasedCorrectionsNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPaintBasedCorrectionsNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPaintBasedCorrectionsNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPaintBasedCorrectionsNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPaintBasedCorrectionsNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPaintBasedCorrectionsNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPaintBasedCorrectionsNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPaintBasedCorrectionsNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPaintBasedCorrectionsNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPaintBasedCorrectionsNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPaintBasedCorrectionsNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPaintBasedCorrectionsNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPaintBasedCorrectionsNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPaintBasedCorrections" nodes by specifying some conditions
 */
export interface MetadataPaintBasedCorrectionsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPaintBasedCorrectionsWhereInput[] | null;
  OR?: MetadataPaintBasedCorrectionsWhereInput[] | null;
  NOT?: MetadataPaintBasedCorrectionsWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPaintBasedCorrections" node.
 */
export interface MetadataPaintBasedCorrectionsWhereUniqueInput {
  id: number;
}

export interface MetadataPaintBasedCorrectionsWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPaintBasedCorrectionsNestedPhotoCreateInput;
  metadata: MetadataPaintBasedCorrectionsNestedMetadataCreateInput;
}

export interface MetadataPaintBasedCorrectionsWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPaintBasedCorrectionsNestedPhotoUpdateInput | null;
  metadata?: MetadataPaintBasedCorrectionsNestedMetadataUpdateInput | null;
}

export interface MetadataPaintBasedCorrectionsWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPaintBasedCorrectionsNestedPhotoCreateInput;
  archive: MetadataPaintBasedCorrectionsNestedArchiveCreateInput;
}

export interface MetadataPaintBasedCorrectionsWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPaintBasedCorrectionsNestedPhotoUpdateInput | null;
  archive?: MetadataPaintBasedCorrectionsNestedArchiveUpdateInput | null;
}

export interface MetadataPaintBasedCorrectionsWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPaintBasedCorrectionsNestedMetadataCreateInput;
  archive: MetadataPaintBasedCorrectionsNestedArchiveCreateInput;
}

export interface MetadataPaintBasedCorrectionsWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPaintBasedCorrectionsNestedMetadataUpdateInput | null;
  archive?: MetadataPaintBasedCorrectionsNestedArchiveUpdateInput | null;
}

export interface MetadataPatientDobNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPatientDobNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPatientDobNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPatientDobNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPatientDobNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPatientDobNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPatientDobNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPatientDobNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPatientDobNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPatientDobNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPatientDobNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPatientDobNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPatientDobNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPatientDobNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPatientDobNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPatientDobNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPatientDobNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPatientDobNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPatientDobNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPatientDobNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPatientDobNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPatientDobNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPatientDobNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPatientDobNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPatientDobNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPatientDobNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPatientDobNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPatientDobNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPatientDobNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPatientDobNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPatientDob" nodes by specifying some conditions
 */
export interface MetadataPatientDobWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPatientDobWhereInput[] | null;
  OR?: MetadataPatientDobWhereInput[] | null;
  NOT?: MetadataPatientDobWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPatientDob" node.
 */
export interface MetadataPatientDobWhereUniqueInput {
  id: number;
}

export interface MetadataPatientDobWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPatientDobNestedPhotoCreateInput;
  metadata: MetadataPatientDobNestedMetadataCreateInput;
}

export interface MetadataPatientDobWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPatientDobNestedPhotoUpdateInput | null;
  metadata?: MetadataPatientDobNestedMetadataUpdateInput | null;
}

export interface MetadataPatientDobWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPatientDobNestedPhotoCreateInput;
  archive: MetadataPatientDobNestedArchiveCreateInput;
}

export interface MetadataPatientDobWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPatientDobNestedPhotoUpdateInput | null;
  archive?: MetadataPatientDobNestedArchiveUpdateInput | null;
}

export interface MetadataPatientDobWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPatientDobNestedMetadataCreateInput;
  archive: MetadataPatientDobNestedArchiveCreateInput;
}

export interface MetadataPatientDobWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPatientDobNestedMetadataUpdateInput | null;
  archive?: MetadataPatientDobNestedArchiveUpdateInput | null;
}

export interface MetadataPatientIdNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPatientIdNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPatientIdNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPatientIdNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPatientIdNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPatientIdNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPatientIdNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPatientIdNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPatientIdNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPatientIdNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPatientIdNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPatientIdNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPatientIdNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPatientIdNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPatientIdNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPatientIdNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPatientIdNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPatientIdNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPatientIdNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPatientIdNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPatientIdNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPatientIdNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPatientIdNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPatientIdNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPatientIdNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPatientIdNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPatientIdNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPatientIdNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPatientIdNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPatientIdNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPatientId" nodes by specifying some conditions
 */
export interface MetadataPatientIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPatientIdWhereInput[] | null;
  OR?: MetadataPatientIdWhereInput[] | null;
  NOT?: MetadataPatientIdWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPatientId" node.
 */
export interface MetadataPatientIdWhereUniqueInput {
  id: number;
}

export interface MetadataPatientIdWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPatientIdNestedPhotoCreateInput;
  metadata: MetadataPatientIdNestedMetadataCreateInput;
}

export interface MetadataPatientIdWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPatientIdNestedPhotoUpdateInput | null;
  metadata?: MetadataPatientIdNestedMetadataUpdateInput | null;
}

export interface MetadataPatientIdWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPatientIdNestedPhotoCreateInput;
  archive: MetadataPatientIdNestedArchiveCreateInput;
}

export interface MetadataPatientIdWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPatientIdNestedPhotoUpdateInput | null;
  archive?: MetadataPatientIdNestedArchiveUpdateInput | null;
}

export interface MetadataPatientIdWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPatientIdNestedMetadataCreateInput;
  archive: MetadataPatientIdNestedArchiveCreateInput;
}

export interface MetadataPatientIdWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPatientIdNestedMetadataUpdateInput | null;
  archive?: MetadataPatientIdNestedArchiveUpdateInput | null;
}

export interface MetadataPatientNameNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPatientNameNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPatientNameNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPatientNameNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPatientNameNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPatientNameNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPatientNameNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPatientNameNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPatientNameNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPatientNameNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPatientNameNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPatientNameNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPatientNameNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPatientNameNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPatientNameNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPatientNameNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPatientNameNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPatientNameNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPatientNameNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPatientNameNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPatientNameNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPatientNameNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPatientNameNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPatientNameNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPatientNameNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPatientNameNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPatientNameNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPatientNameNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPatientNameNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPatientNameNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPatientName" nodes by specifying some conditions
 */
export interface MetadataPatientNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPatientNameWhereInput[] | null;
  OR?: MetadataPatientNameWhereInput[] | null;
  NOT?: MetadataPatientNameWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPatientName" node.
 */
export interface MetadataPatientNameWhereUniqueInput {
  id: number;
}

export interface MetadataPatientNameWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPatientNameNestedPhotoCreateInput;
  metadata: MetadataPatientNameNestedMetadataCreateInput;
}

export interface MetadataPatientNameWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPatientNameNestedPhotoUpdateInput | null;
  metadata?: MetadataPatientNameNestedMetadataUpdateInput | null;
}

export interface MetadataPatientNameWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPatientNameNestedPhotoCreateInput;
  archive: MetadataPatientNameNestedArchiveCreateInput;
}

export interface MetadataPatientNameWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPatientNameNestedPhotoUpdateInput | null;
  archive?: MetadataPatientNameNestedArchiveUpdateInput | null;
}

export interface MetadataPatientNameWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPatientNameNestedMetadataCreateInput;
  archive: MetadataPatientNameNestedArchiveCreateInput;
}

export interface MetadataPatientNameWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPatientNameNestedMetadataUpdateInput | null;
  archive?: MetadataPatientNameNestedArchiveUpdateInput | null;
}

export interface MetadataPatientSexNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPatientSexNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPatientSexNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPatientSexNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPatientSexNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPatientSexNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPatientSexNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPatientSexNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPatientSexNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPatientSexNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPatientSexNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPatientSexNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPatientSexNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPatientSexNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPatientSexNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPatientSexNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPatientSexNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPatientSexNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPatientSexNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPatientSexNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPatientSexNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPatientSexNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPatientSexNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPatientSexNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPatientSexNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPatientSexNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPatientSexNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPatientSexNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPatientSexNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPatientSexNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPatientSex" nodes by specifying some conditions
 */
export interface MetadataPatientSexWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPatientSexWhereInput[] | null;
  OR?: MetadataPatientSexWhereInput[] | null;
  NOT?: MetadataPatientSexWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPatientSex" node.
 */
export interface MetadataPatientSexWhereUniqueInput {
  id: number;
}

export interface MetadataPatientSexWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPatientSexNestedPhotoCreateInput;
  metadata: MetadataPatientSexNestedMetadataCreateInput;
}

export interface MetadataPatientSexWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPatientSexNestedPhotoUpdateInput | null;
  metadata?: MetadataPatientSexNestedMetadataUpdateInput | null;
}

export interface MetadataPatientSexWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPatientSexNestedPhotoCreateInput;
  archive: MetadataPatientSexNestedArchiveCreateInput;
}

export interface MetadataPatientSexWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPatientSexNestedPhotoUpdateInput | null;
  archive?: MetadataPatientSexNestedArchiveUpdateInput | null;
}

export interface MetadataPatientSexWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPatientSexNestedMetadataCreateInput;
  archive: MetadataPatientSexNestedArchiveCreateInput;
}

export interface MetadataPatientSexWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPatientSexNestedMetadataUpdateInput | null;
  archive?: MetadataPatientSexNestedArchiveUpdateInput | null;
}

export interface MetadataPersonInImageNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPersonInImageNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPersonInImageNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPersonInImageNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPersonInImageNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPersonInImageNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPersonInImageNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPersonInImageNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPersonInImageNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPersonInImageNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPersonInImageNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPersonInImageNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPersonInImageNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPersonInImageNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPersonInImageNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPersonInImageNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPersonInImageNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPersonInImageNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPersonInImageNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPersonInImageNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPersonInImageNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPersonInImageNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPersonInImageNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPersonInImageNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPersonInImageNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPersonInImageNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPersonInImageNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPersonInImageNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPersonInImageNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPersonInImageNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPersonInImage" nodes by specifying some conditions
 */
export interface MetadataPersonInImageWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPersonInImageWhereInput[] | null;
  OR?: MetadataPersonInImageWhereInput[] | null;
  NOT?: MetadataPersonInImageWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPersonInImage" node.
 */
export interface MetadataPersonInImageWhereUniqueInput {
  id: number;
}

export interface MetadataPersonInImageWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPersonInImageNestedPhotoCreateInput;
  metadata: MetadataPersonInImageNestedMetadataCreateInput;
}

export interface MetadataPersonInImageWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPersonInImageNestedPhotoUpdateInput | null;
  metadata?: MetadataPersonInImageNestedMetadataUpdateInput | null;
}

export interface MetadataPersonInImageWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPersonInImageNestedPhotoCreateInput;
  archive: MetadataPersonInImageNestedArchiveCreateInput;
}

export interface MetadataPersonInImageWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPersonInImageNestedPhotoUpdateInput | null;
  archive?: MetadataPersonInImageNestedArchiveUpdateInput | null;
}

export interface MetadataPersonInImageWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPersonInImageNestedMetadataCreateInput;
  archive: MetadataPersonInImageNestedArchiveCreateInput;
}

export interface MetadataPersonInImageWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPersonInImageNestedMetadataUpdateInput | null;
  archive?: MetadataPersonInImageNestedArchiveUpdateInput | null;
}

export interface MetadataPhotoStorageNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPhotoStorageNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPhotoStorageNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPhotoStorageNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPhotoStorageNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPhotoStorageNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPhotoStorageNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPhotoStorageNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPhotoStorageNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPhotoStorageNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPhotoStorageNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPhotoStorageNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPhotoStorageNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPhotoStorageNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPhotoStorageNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPhotoStorageNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPhotoStorageNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPhotoStorageNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPhotoStorageNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPhotoStorageNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPhotoStorageNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPhotoStorageNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPhotoStorageNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPhotoStorageNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPhotoStorageNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPhotoStorageNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPhotoStorageNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPhotoStorageNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPhotoStorageNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPhotoStorageNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPhotoStorage" nodes by specifying some conditions
 */
export interface MetadataPhotoStorageWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPhotoStorageWhereInput[] | null;
  OR?: MetadataPhotoStorageWhereInput[] | null;
  NOT?: MetadataPhotoStorageWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPhotoStorage" node.
 */
export interface MetadataPhotoStorageWhereUniqueInput {
  id: number;
}

export interface MetadataPhotoStorageWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPhotoStorageNestedPhotoCreateInput;
  metadata: MetadataPhotoStorageNestedMetadataCreateInput;
}

export interface MetadataPhotoStorageWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPhotoStorageNestedPhotoUpdateInput | null;
  metadata?: MetadataPhotoStorageNestedMetadataUpdateInput | null;
}

export interface MetadataPhotoStorageWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPhotoStorageNestedPhotoCreateInput;
  archive: MetadataPhotoStorageNestedArchiveCreateInput;
}

export interface MetadataPhotoStorageWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPhotoStorageNestedPhotoUpdateInput | null;
  archive?: MetadataPhotoStorageNestedArchiveUpdateInput | null;
}

export interface MetadataPhotoStorageWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPhotoStorageNestedMetadataCreateInput;
  archive: MetadataPhotoStorageNestedArchiveCreateInput;
}

export interface MetadataPhotoStorageWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPhotoStorageNestedMetadataUpdateInput | null;
  archive?: MetadataPhotoStorageNestedArchiveUpdateInput | null;
}

export interface MetadataPrepressPictureEditingNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPrepressPictureEditingNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPrepressPictureEditingNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPrepressPictureEditingNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPrepressPictureEditingNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPrepressPictureEditingNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPrepressPictureEditingNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPrepressPictureEditingNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPrepressPictureEditingNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPrepressPictureEditingNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPrepressPictureEditingNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPrepressPictureEditingNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPrepressPictureEditingNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPrepressPictureEditingNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPrepressPictureEditingNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPrepressPictureEditingNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPrepressPictureEditingNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPrepressPictureEditingNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPrepressPictureEditingNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPrepressPictureEditingNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPrepressPictureEditingNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPrepressPictureEditingNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPrepressPictureEditingNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPrepressPictureEditingNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPrepressPictureEditingNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPrepressPictureEditingNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPrepressPictureEditingNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPrepressPictureEditingNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPrepressPictureEditingNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPrepressPictureEditingNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPrepressPictureEditing" nodes by specifying some conditions
 */
export interface MetadataPrepressPictureEditingWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPrepressPictureEditingWhereInput[] | null;
  OR?: MetadataPrepressPictureEditingWhereInput[] | null;
  NOT?: MetadataPrepressPictureEditingWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPrepressPictureEditing" node.
 */
export interface MetadataPrepressPictureEditingWhereUniqueInput {
  id: number;
}

export interface MetadataPrepressPictureEditingWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPrepressPictureEditingNestedPhotoCreateInput;
  metadata: MetadataPrepressPictureEditingNestedMetadataCreateInput;
}

export interface MetadataPrepressPictureEditingWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPrepressPictureEditingNestedPhotoUpdateInput | null;
  metadata?: MetadataPrepressPictureEditingNestedMetadataUpdateInput | null;
}

export interface MetadataPrepressPictureEditingWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPrepressPictureEditingNestedPhotoCreateInput;
  archive: MetadataPrepressPictureEditingNestedArchiveCreateInput;
}

export interface MetadataPrepressPictureEditingWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPrepressPictureEditingNestedPhotoUpdateInput | null;
  archive?: MetadataPrepressPictureEditingNestedArchiveUpdateInput | null;
}

export interface MetadataPrepressPictureEditingWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPrepressPictureEditingNestedMetadataCreateInput;
  archive: MetadataPrepressPictureEditingNestedArchiveCreateInput;
}

export interface MetadataPrepressPictureEditingWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPrepressPictureEditingNestedMetadataUpdateInput | null;
  archive?: MetadataPrepressPictureEditingNestedArchiveUpdateInput | null;
}

export interface MetadataPriceLevelNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPriceLevelNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPriceLevelNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPriceLevelNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPriceLevelNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPriceLevelNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPriceLevelNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPriceLevelNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPriceLevelNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPriceLevelNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPriceLevelNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPriceLevelNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPriceLevelNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPriceLevelNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPriceLevelNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPriceLevelNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPriceLevelNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPriceLevelNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPriceLevelNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPriceLevelNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPriceLevelNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPriceLevelNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPriceLevelNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPriceLevelNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPriceLevelNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPriceLevelNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPriceLevelNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPriceLevelNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPriceLevelNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPriceLevelNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPriceLevel" nodes by specifying some conditions
 */
export interface MetadataPriceLevelWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPriceLevelWhereInput[] | null;
  OR?: MetadataPriceLevelWhereInput[] | null;
  NOT?: MetadataPriceLevelWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPriceLevel" node.
 */
export interface MetadataPriceLevelWhereUniqueInput {
  id: number;
}

export interface MetadataPriceLevelWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPriceLevelNestedPhotoCreateInput;
  metadata: MetadataPriceLevelNestedMetadataCreateInput;
}

export interface MetadataPriceLevelWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPriceLevelNestedPhotoUpdateInput | null;
  metadata?: MetadataPriceLevelNestedMetadataUpdateInput | null;
}

export interface MetadataPriceLevelWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPriceLevelNestedPhotoCreateInput;
  archive: MetadataPriceLevelNestedArchiveCreateInput;
}

export interface MetadataPriceLevelWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPriceLevelNestedPhotoUpdateInput | null;
  archive?: MetadataPriceLevelNestedArchiveUpdateInput | null;
}

export interface MetadataPriceLevelWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPriceLevelNestedMetadataCreateInput;
  archive: MetadataPriceLevelNestedArchiveCreateInput;
}

export interface MetadataPriceLevelWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPriceLevelNestedMetadataUpdateInput | null;
  archive?: MetadataPriceLevelNestedArchiveUpdateInput | null;
}

export interface MetadataPrintingProfileNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPrintingProfileNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPrintingProfileNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPrintingProfileNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPrintingProfileNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPrintingProfileNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPrintingProfileNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPrintingProfileNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPrintingProfileNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPrintingProfileNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPrintingProfileNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPrintingProfileNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPrintingProfileNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPrintingProfileNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPrintingProfileNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPrintingProfileNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPrintingProfileNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPrintingProfileNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPrintingProfileNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPrintingProfileNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPrintingProfileNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPrintingProfileNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPrintingProfileNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPrintingProfileNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPrintingProfileNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPrintingProfileNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPrintingProfileNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPrintingProfileNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPrintingProfileNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPrintingProfileNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPrintingProfile" nodes by specifying some conditions
 */
export interface MetadataPrintingProfileWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPrintingProfileWhereInput[] | null;
  OR?: MetadataPrintingProfileWhereInput[] | null;
  NOT?: MetadataPrintingProfileWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPrintingProfile" node.
 */
export interface MetadataPrintingProfileWhereUniqueInput {
  id: number;
}

export interface MetadataPrintingProfileWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPrintingProfileNestedPhotoCreateInput;
  metadata: MetadataPrintingProfileNestedMetadataCreateInput;
}

export interface MetadataPrintingProfileWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPrintingProfileNestedPhotoUpdateInput | null;
  metadata?: MetadataPrintingProfileNestedMetadataUpdateInput | null;
}

export interface MetadataPrintingProfileWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPrintingProfileNestedPhotoCreateInput;
  archive: MetadataPrintingProfileNestedArchiveCreateInput;
}

export interface MetadataPrintingProfileWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPrintingProfileNestedPhotoUpdateInput | null;
  archive?: MetadataPrintingProfileNestedArchiveUpdateInput | null;
}

export interface MetadataPrintingProfileWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPrintingProfileNestedMetadataCreateInput;
  archive: MetadataPrintingProfileNestedArchiveCreateInput;
}

export interface MetadataPrintingProfileWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPrintingProfileNestedMetadataUpdateInput | null;
  archive?: MetadataPrintingProfileNestedArchiveUpdateInput | null;
}

export interface MetadataPrismaProductionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPrismaProductionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPrismaProductionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPrismaProductionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPrismaProductionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPrismaProductionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPrismaProductionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPrismaProductionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPrismaProductionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPrismaProductionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPrismaProductionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPrismaProductionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPrismaProductionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPrismaProductionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPrismaProductionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPrismaProductionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPrismaProductionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPrismaProductionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPrismaProductionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPrismaProductionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPrismaProductionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPrismaProductionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPrismaProductionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPrismaProductionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPrismaProductionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPrismaProductionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPrismaProductionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPrismaProductionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPrismaProductionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPrismaProductionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPrismaProduction" nodes by specifying some conditions
 */
export interface MetadataPrismaProductionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPrismaProductionWhereInput[] | null;
  OR?: MetadataPrismaProductionWhereInput[] | null;
  NOT?: MetadataPrismaProductionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPrismaProduction" node.
 */
export interface MetadataPrismaProductionWhereUniqueInput {
  id: number;
}

export interface MetadataPrismaProductionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPrismaProductionNestedPhotoCreateInput;
  metadata: MetadataPrismaProductionNestedMetadataCreateInput;
}

export interface MetadataPrismaProductionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPrismaProductionNestedPhotoUpdateInput | null;
  metadata?: MetadataPrismaProductionNestedMetadataUpdateInput | null;
}

export interface MetadataPrismaProductionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPrismaProductionNestedPhotoCreateInput;
  archive: MetadataPrismaProductionNestedArchiveCreateInput;
}

export interface MetadataPrismaProductionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPrismaProductionNestedPhotoUpdateInput | null;
  archive?: MetadataPrismaProductionNestedArchiveUpdateInput | null;
}

export interface MetadataPrismaProductionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPrismaProductionNestedMetadataCreateInput;
  archive: MetadataPrismaProductionNestedArchiveCreateInput;
}

export interface MetadataPrismaProductionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPrismaProductionNestedMetadataUpdateInput | null;
  archive?: MetadataPrismaProductionNestedArchiveUpdateInput | null;
}

export interface MetadataProcessHistoryNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProcessHistoryNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProcessHistoryNestedUpsertArchiveCreateInput | null;
}

export interface MetadataProcessHistoryNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProcessHistoryNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProcessHistoryNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataProcessHistoryNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProcessHistoryNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProcessHistoryNestedUpsertMetadataCreateInput | null;
}

export interface MetadataProcessHistoryNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProcessHistoryNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProcessHistoryNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataProcessHistoryNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProcessHistoryNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProcessHistoryNestedUpsertPhotoCreateInput | null;
}

export interface MetadataProcessHistoryNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProcessHistoryNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProcessHistoryNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataProcessHistoryNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProcessHistoryNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProcessHistoryNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProcessHistoryNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProcessHistoryNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProcessHistoryNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProcessHistoryNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProcessHistoryNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProcessHistoryNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProcessHistoryNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProcessHistoryNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataProcessHistoryNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataProcessHistory" nodes by specifying some conditions
 */
export interface MetadataProcessHistoryWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProcessHistoryWhereInput[] | null;
  OR?: MetadataProcessHistoryWhereInput[] | null;
  NOT?: MetadataProcessHistoryWhereInput | null;
}

/**
 * Identifies exactly one "MetadataProcessHistory" node.
 */
export interface MetadataProcessHistoryWhereUniqueInput {
  id: number;
}

export interface MetadataProcessHistoryWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataProcessHistoryNestedPhotoCreateInput;
  metadata: MetadataProcessHistoryNestedMetadataCreateInput;
}

export interface MetadataProcessHistoryWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataProcessHistoryNestedPhotoUpdateInput | null;
  metadata?: MetadataProcessHistoryNestedMetadataUpdateInput | null;
}

export interface MetadataProcessHistoryWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataProcessHistoryNestedPhotoCreateInput;
  archive: MetadataProcessHistoryNestedArchiveCreateInput;
}

export interface MetadataProcessHistoryWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataProcessHistoryNestedPhotoUpdateInput | null;
  archive?: MetadataProcessHistoryNestedArchiveUpdateInput | null;
}

export interface MetadataProcessHistoryWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataProcessHistoryNestedMetadataCreateInput;
  archive: MetadataProcessHistoryNestedArchiveCreateInput;
}

export interface MetadataProcessHistoryWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataProcessHistoryNestedMetadataUpdateInput | null;
  archive?: MetadataProcessHistoryNestedArchiveUpdateInput | null;
}

export interface MetadataProcessParameterNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProcessParameterNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProcessParameterNestedUpsertArchiveCreateInput | null;
}

export interface MetadataProcessParameterNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProcessParameterNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProcessParameterNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataProcessParameterNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProcessParameterNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProcessParameterNestedUpsertMetadataCreateInput | null;
}

export interface MetadataProcessParameterNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProcessParameterNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProcessParameterNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataProcessParameterNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProcessParameterNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProcessParameterNestedUpsertPhotoCreateInput | null;
}

export interface MetadataProcessParameterNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProcessParameterNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProcessParameterNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataProcessParameterNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProcessParameterNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProcessParameterNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProcessParameterNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProcessParameterNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProcessParameterNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProcessParameterNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProcessParameterNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProcessParameterNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProcessParameterNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProcessParameterNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataProcessParameterNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataProcessParameter" nodes by specifying some conditions
 */
export interface MetadataProcessParameterWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProcessParameterWhereInput[] | null;
  OR?: MetadataProcessParameterWhereInput[] | null;
  NOT?: MetadataProcessParameterWhereInput | null;
}

/**
 * Identifies exactly one "MetadataProcessParameter" node.
 */
export interface MetadataProcessParameterWhereUniqueInput {
  id: number;
}

export interface MetadataProcessParameterWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataProcessParameterNestedPhotoCreateInput;
  metadata: MetadataProcessParameterNestedMetadataCreateInput;
}

export interface MetadataProcessParameterWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataProcessParameterNestedPhotoUpdateInput | null;
  metadata?: MetadataProcessParameterNestedMetadataUpdateInput | null;
}

export interface MetadataProcessParameterWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataProcessParameterNestedPhotoCreateInput;
  archive: MetadataProcessParameterNestedArchiveCreateInput;
}

export interface MetadataProcessParameterWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataProcessParameterNestedPhotoUpdateInput | null;
  archive?: MetadataProcessParameterNestedArchiveUpdateInput | null;
}

export interface MetadataProcessParameterWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataProcessParameterNestedMetadataCreateInput;
  archive: MetadataProcessParameterNestedArchiveCreateInput;
}

export interface MetadataProcessParameterWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataProcessParameterNestedMetadataUpdateInput | null;
  archive?: MetadataProcessParameterNestedArchiveUpdateInput | null;
}

export interface MetadataProcessStatusNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProcessStatusNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProcessStatusNestedUpsertArchiveCreateInput | null;
}

export interface MetadataProcessStatusNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProcessStatusNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProcessStatusNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataProcessStatusNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProcessStatusNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProcessStatusNestedUpsertMetadataCreateInput | null;
}

export interface MetadataProcessStatusNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProcessStatusNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProcessStatusNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataProcessStatusNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProcessStatusNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProcessStatusNestedUpsertPhotoCreateInput | null;
}

export interface MetadataProcessStatusNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProcessStatusNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProcessStatusNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataProcessStatusNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProcessStatusNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProcessStatusNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProcessStatusNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProcessStatusNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProcessStatusNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProcessStatusNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProcessStatusNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProcessStatusNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProcessStatusNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProcessStatusNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataProcessStatusNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataProcessStatus" nodes by specifying some conditions
 */
export interface MetadataProcessStatusWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProcessStatusWhereInput[] | null;
  OR?: MetadataProcessStatusWhereInput[] | null;
  NOT?: MetadataProcessStatusWhereInput | null;
}

/**
 * Identifies exactly one "MetadataProcessStatus" node.
 */
export interface MetadataProcessStatusWhereUniqueInput {
  id: number;
}

export interface MetadataProcessStatusWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataProcessStatusNestedPhotoCreateInput;
  metadata: MetadataProcessStatusNestedMetadataCreateInput;
}

export interface MetadataProcessStatusWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataProcessStatusNestedPhotoUpdateInput | null;
  metadata?: MetadataProcessStatusNestedMetadataUpdateInput | null;
}

export interface MetadataProcessStatusWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataProcessStatusNestedPhotoCreateInput;
  archive: MetadataProcessStatusNestedArchiveCreateInput;
}

export interface MetadataProcessStatusWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataProcessStatusNestedPhotoUpdateInput | null;
  archive?: MetadataProcessStatusNestedArchiveUpdateInput | null;
}

export interface MetadataProcessStatusWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataProcessStatusNestedMetadataCreateInput;
  archive: MetadataProcessStatusNestedArchiveCreateInput;
}

export interface MetadataProcessStatusWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataProcessStatusNestedMetadataUpdateInput | null;
  archive?: MetadataProcessStatusNestedArchiveUpdateInput | null;
}

export interface MetadataProductNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProductNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProductNestedUpsertArchiveCreateInput | null;
}

export interface MetadataProductNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProductNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProductNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataProductNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProductNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProductNestedUpsertMetadataCreateInput | null;
}

export interface MetadataProductNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProductNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProductNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataProductNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProductNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProductNestedUpsertPhotoCreateInput | null;
}

export interface MetadataProductNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProductNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProductNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataProductNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProductNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProductNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProductNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProductNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProductNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProductNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProductNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProductNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProductNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProductNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataProductNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataProductShortNameNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProductShortNameNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProductShortNameNestedUpsertArchiveCreateInput | null;
}

export interface MetadataProductShortNameNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProductShortNameNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProductShortNameNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataProductShortNameNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProductShortNameNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProductShortNameNestedUpsertMetadataCreateInput | null;
}

export interface MetadataProductShortNameNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProductShortNameNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProductShortNameNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataProductShortNameNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProductShortNameNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProductShortNameNestedUpsertPhotoCreateInput | null;
}

export interface MetadataProductShortNameNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProductShortNameNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProductShortNameNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataProductShortNameNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProductShortNameNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProductShortNameNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProductShortNameNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProductShortNameNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProductShortNameNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProductShortNameNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProductShortNameNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProductShortNameNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProductShortNameNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProductShortNameNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataProductShortNameNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataProductShortName" nodes by specifying some conditions
 */
export interface MetadataProductShortNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProductShortNameWhereInput[] | null;
  OR?: MetadataProductShortNameWhereInput[] | null;
  NOT?: MetadataProductShortNameWhereInput | null;
}

/**
 * Identifies exactly one "MetadataProductShortName" node.
 */
export interface MetadataProductShortNameWhereUniqueInput {
  id: number;
}

export interface MetadataProductShortNameWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataProductShortNameNestedPhotoCreateInput;
  metadata: MetadataProductShortNameNestedMetadataCreateInput;
}

export interface MetadataProductShortNameWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataProductShortNameNestedPhotoUpdateInput | null;
  metadata?: MetadataProductShortNameNestedMetadataUpdateInput | null;
}

export interface MetadataProductShortNameWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataProductShortNameNestedPhotoCreateInput;
  archive: MetadataProductShortNameNestedArchiveCreateInput;
}

export interface MetadataProductShortNameWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataProductShortNameNestedPhotoUpdateInput | null;
  archive?: MetadataProductShortNameNestedArchiveUpdateInput | null;
}

export interface MetadataProductShortNameWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataProductShortNameNestedMetadataCreateInput;
  archive: MetadataProductShortNameNestedArchiveCreateInput;
}

export interface MetadataProductShortNameWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataProductShortNameNestedMetadataUpdateInput | null;
  archive?: MetadataProductShortNameNestedArchiveUpdateInput | null;
}

/**
 * Filters the "MetadataProduct" nodes by specifying some conditions
 */
export interface MetadataProductWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProductWhereInput[] | null;
  OR?: MetadataProductWhereInput[] | null;
  NOT?: MetadataProductWhereInput | null;
}

/**
 * Identifies exactly one "MetadataProduct" node.
 */
export interface MetadataProductWhereUniqueInput {
  id: number;
}

export interface MetadataProductWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataProductNestedPhotoCreateInput;
  metadata: MetadataProductNestedMetadataCreateInput;
}

export interface MetadataProductWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataProductNestedPhotoUpdateInput | null;
  metadata?: MetadataProductNestedMetadataUpdateInput | null;
}

export interface MetadataProductWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataProductNestedPhotoCreateInput;
  archive: MetadataProductNestedArchiveCreateInput;
}

export interface MetadataProductWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataProductNestedPhotoUpdateInput | null;
  archive?: MetadataProductNestedArchiveUpdateInput | null;
}

export interface MetadataProductWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataProductNestedMetadataCreateInput;
  archive: MetadataProductNestedArchiveCreateInput;
}

export interface MetadataProductWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataProductNestedMetadataUpdateInput | null;
  archive?: MetadataProductNestedArchiveUpdateInput | null;
}

export interface MetadataProductsNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProductsNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProductsNestedUpsertArchiveCreateInput | null;
}

export interface MetadataProductsNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProductsNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProductsNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataProductsNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProductsNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProductsNestedUpsertMetadataCreateInput | null;
}

export interface MetadataProductsNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProductsNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProductsNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataProductsNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProductsNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProductsNestedUpsertPhotoCreateInput | null;
}

export interface MetadataProductsNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProductsNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProductsNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataProductsNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProductsNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProductsNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProductsNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProductsNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProductsNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProductsNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProductsNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProductsNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProductsNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProductsNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataProductsNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataProducts" nodes by specifying some conditions
 */
export interface MetadataProductsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProductsWhereInput[] | null;
  OR?: MetadataProductsWhereInput[] | null;
  NOT?: MetadataProductsWhereInput | null;
}

/**
 * Identifies exactly one "MetadataProducts" node.
 */
export interface MetadataProductsWhereUniqueInput {
  id: number;
}

export interface MetadataProductsWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataProductsNestedPhotoCreateInput;
  metadata: MetadataProductsNestedMetadataCreateInput;
}

export interface MetadataProductsWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataProductsNestedPhotoUpdateInput | null;
  metadata?: MetadataProductsNestedMetadataUpdateInput | null;
}

export interface MetadataProductsWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataProductsNestedPhotoCreateInput;
  archive: MetadataProductsNestedArchiveCreateInput;
}

export interface MetadataProductsWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataProductsNestedPhotoUpdateInput | null;
  archive?: MetadataProductsNestedArchiveUpdateInput | null;
}

export interface MetadataProductsWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataProductsNestedMetadataCreateInput;
  archive: MetadataProductsNestedArchiveCreateInput;
}

export interface MetadataProductsWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataProductsNestedMetadataUpdateInput | null;
  archive?: MetadataProductsNestedArchiveUpdateInput | null;
}

export interface MetadataProduitsNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProduitsNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProduitsNestedUpsertArchiveCreateInput | null;
}

export interface MetadataProduitsNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProduitsNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProduitsNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataProduitsNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProduitsNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProduitsNestedUpsertMetadataCreateInput | null;
}

export interface MetadataProduitsNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProduitsNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProduitsNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataProduitsNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProduitsNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProduitsNestedUpsertPhotoCreateInput | null;
}

export interface MetadataProduitsNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProduitsNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProduitsNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataProduitsNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProduitsNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProduitsNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProduitsNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProduitsNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProduitsNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProduitsNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProduitsNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProduitsNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProduitsNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProduitsNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataProduitsNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataProduits" nodes by specifying some conditions
 */
export interface MetadataProduitsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProduitsWhereInput[] | null;
  OR?: MetadataProduitsWhereInput[] | null;
  NOT?: MetadataProduitsWhereInput | null;
}

/**
 * Identifies exactly one "MetadataProduits" node.
 */
export interface MetadataProduitsWhereUniqueInput {
  id: number;
}

export interface MetadataProduitsWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataProduitsNestedPhotoCreateInput;
  metadata: MetadataProduitsNestedMetadataCreateInput;
}

export interface MetadataProduitsWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataProduitsNestedPhotoUpdateInput | null;
  metadata?: MetadataProduitsNestedMetadataUpdateInput | null;
}

export interface MetadataProduitsWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataProduitsNestedPhotoCreateInput;
  archive: MetadataProduitsNestedArchiveCreateInput;
}

export interface MetadataProduitsWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataProduitsNestedPhotoUpdateInput | null;
  archive?: MetadataProduitsNestedArchiveUpdateInput | null;
}

export interface MetadataProduitsWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataProduitsNestedMetadataCreateInput;
  archive: MetadataProduitsNestedArchiveCreateInput;
}

export interface MetadataProduitsWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataProduitsNestedMetadataUpdateInput | null;
  archive?: MetadataProduitsNestedArchiveUpdateInput | null;
}

export interface MetadataProgramVersionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProgramVersionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProgramVersionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataProgramVersionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataProgramVersionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataProgramVersionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataProgramVersionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProgramVersionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProgramVersionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataProgramVersionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataProgramVersionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataProgramVersionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataProgramVersionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProgramVersionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProgramVersionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataProgramVersionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataProgramVersionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataProgramVersionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataProgramVersionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProgramVersionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataProgramVersionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProgramVersionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataProgramVersionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProgramVersionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataProgramVersionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProgramVersionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataProgramVersionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProgramVersionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataProgramVersionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataProgramVersionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataProgramVersion" nodes by specifying some conditions
 */
export interface MetadataProgramVersionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataProgramVersionWhereInput[] | null;
  OR?: MetadataProgramVersionWhereInput[] | null;
  NOT?: MetadataProgramVersionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataProgramVersion" node.
 */
export interface MetadataProgramVersionWhereUniqueInput {
  id: number;
}

export interface MetadataProgramVersionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataProgramVersionNestedPhotoCreateInput;
  metadata: MetadataProgramVersionNestedMetadataCreateInput;
}

export interface MetadataProgramVersionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataProgramVersionNestedPhotoUpdateInput | null;
  metadata?: MetadataProgramVersionNestedMetadataUpdateInput | null;
}

export interface MetadataProgramVersionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataProgramVersionNestedPhotoCreateInput;
  archive: MetadataProgramVersionNestedArchiveCreateInput;
}

export interface MetadataProgramVersionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataProgramVersionNestedPhotoUpdateInput | null;
  archive?: MetadataProgramVersionNestedArchiveUpdateInput | null;
}

export interface MetadataProgramVersionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataProgramVersionNestedMetadataCreateInput;
  archive: MetadataProgramVersionNestedArchiveCreateInput;
}

export interface MetadataProgramVersionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataProgramVersionNestedMetadataUpdateInput | null;
  archive?: MetadataProgramVersionNestedArchiveUpdateInput | null;
}

export interface MetadataPropertyReleaseIdNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPropertyReleaseIdNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPropertyReleaseIdNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPropertyReleaseIdNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPropertyReleaseIdNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPropertyReleaseIdNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPropertyReleaseIdNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPropertyReleaseIdNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPropertyReleaseIdNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPropertyReleaseIdNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPropertyReleaseIdNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPropertyReleaseIdNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPropertyReleaseIdNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPropertyReleaseIdNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPropertyReleaseIdNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPropertyReleaseIdNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPropertyReleaseIdNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPropertyReleaseIdNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPropertyReleaseIdNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPropertyReleaseIdNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPropertyReleaseIdNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPropertyReleaseIdNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPropertyReleaseIdNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPropertyReleaseIdNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPropertyReleaseIdNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPropertyReleaseIdNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPropertyReleaseIdNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPropertyReleaseIdNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPropertyReleaseIdNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPropertyReleaseIdNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPropertyReleaseId" nodes by specifying some conditions
 */
export interface MetadataPropertyReleaseIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPropertyReleaseIdWhereInput[] | null;
  OR?: MetadataPropertyReleaseIdWhereInput[] | null;
  NOT?: MetadataPropertyReleaseIdWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPropertyReleaseId" node.
 */
export interface MetadataPropertyReleaseIdWhereUniqueInput {
  id: number;
}

export interface MetadataPropertyReleaseIdWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPropertyReleaseIdNestedPhotoCreateInput;
  metadata: MetadataPropertyReleaseIdNestedMetadataCreateInput;
}

export interface MetadataPropertyReleaseIdWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPropertyReleaseIdNestedPhotoUpdateInput | null;
  metadata?: MetadataPropertyReleaseIdNestedMetadataUpdateInput | null;
}

export interface MetadataPropertyReleaseIdWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPropertyReleaseIdNestedPhotoCreateInput;
  archive: MetadataPropertyReleaseIdNestedArchiveCreateInput;
}

export interface MetadataPropertyReleaseIdWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPropertyReleaseIdNestedPhotoUpdateInput | null;
  archive?: MetadataPropertyReleaseIdNestedArchiveUpdateInput | null;
}

export interface MetadataPropertyReleaseIdWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPropertyReleaseIdNestedMetadataCreateInput;
  archive: MetadataPropertyReleaseIdNestedArchiveCreateInput;
}

export interface MetadataPropertyReleaseIdWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPropertyReleaseIdNestedMetadataUpdateInput | null;
  archive?: MetadataPropertyReleaseIdNestedArchiveUpdateInput | null;
}

export interface MetadataPropertyReleaseStatusNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPropertyReleaseStatusNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPropertyReleaseStatusNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPropertyReleaseStatusNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPropertyReleaseStatusNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPropertyReleaseStatusNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPropertyReleaseStatusNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPropertyReleaseStatusNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPropertyReleaseStatusNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPropertyReleaseStatusNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPropertyReleaseStatusNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPropertyReleaseStatusNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPropertyReleaseStatusNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPropertyReleaseStatusNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPropertyReleaseStatusNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPropertyReleaseStatusNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPropertyReleaseStatusNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPropertyReleaseStatusNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPropertyReleaseStatusNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPropertyReleaseStatusNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPropertyReleaseStatusNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPropertyReleaseStatusNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPropertyReleaseStatusNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPropertyReleaseStatusNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPropertyReleaseStatusNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPropertyReleaseStatusNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPropertyReleaseStatusNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPropertyReleaseStatusNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPropertyReleaseStatusNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPropertyReleaseStatusNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPropertyReleaseStatus" nodes by specifying some conditions
 */
export interface MetadataPropertyReleaseStatusWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPropertyReleaseStatusWhereInput[] | null;
  OR?: MetadataPropertyReleaseStatusWhereInput[] | null;
  NOT?: MetadataPropertyReleaseStatusWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPropertyReleaseStatus" node.
 */
export interface MetadataPropertyReleaseStatusWhereUniqueInput {
  id: number;
}

export interface MetadataPropertyReleaseStatusWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPropertyReleaseStatusNestedPhotoCreateInput;
  metadata: MetadataPropertyReleaseStatusNestedMetadataCreateInput;
}

export interface MetadataPropertyReleaseStatusWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPropertyReleaseStatusNestedPhotoUpdateInput | null;
  metadata?: MetadataPropertyReleaseStatusNestedMetadataUpdateInput | null;
}

export interface MetadataPropertyReleaseStatusWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPropertyReleaseStatusNestedPhotoCreateInput;
  archive: MetadataPropertyReleaseStatusNestedArchiveCreateInput;
}

export interface MetadataPropertyReleaseStatusWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPropertyReleaseStatusNestedPhotoUpdateInput | null;
  archive?: MetadataPropertyReleaseStatusNestedArchiveUpdateInput | null;
}

export interface MetadataPropertyReleaseStatusWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPropertyReleaseStatusNestedMetadataCreateInput;
  archive: MetadataPropertyReleaseStatusNestedArchiveCreateInput;
}

export interface MetadataPropertyReleaseStatusWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPropertyReleaseStatusNestedMetadataUpdateInput | null;
  archive?: MetadataPropertyReleaseStatusNestedArchiveUpdateInput | null;
}

export interface MetadataPublisherNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPublisherNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPublisherNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPublisherNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPublisherNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPublisherNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPublisherNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPublisherNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPublisherNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPublisherNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPublisherNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPublisherNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPublisherNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPublisherNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPublisherNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPublisherNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPublisherNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPublisherNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPublisherNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPublisherNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPublisherNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPublisherNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPublisherNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPublisherNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPublisherNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPublisherNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPublisherNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPublisherNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPublisherNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPublisherNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPublisher" nodes by specifying some conditions
 */
export interface MetadataPublisherWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPublisherWhereInput[] | null;
  OR?: MetadataPublisherWhereInput[] | null;
  NOT?: MetadataPublisherWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPublisher" node.
 */
export interface MetadataPublisherWhereUniqueInput {
  id: number;
}

export interface MetadataPublisherWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPublisherNestedPhotoCreateInput;
  metadata: MetadataPublisherNestedMetadataCreateInput;
}

export interface MetadataPublisherWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPublisherNestedPhotoUpdateInput | null;
  metadata?: MetadataPublisherNestedMetadataUpdateInput | null;
}

export interface MetadataPublisherWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPublisherNestedPhotoCreateInput;
  archive: MetadataPublisherNestedArchiveCreateInput;
}

export interface MetadataPublisherWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPublisherNestedPhotoUpdateInput | null;
  archive?: MetadataPublisherNestedArchiveUpdateInput | null;
}

export interface MetadataPublisherWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPublisherNestedMetadataCreateInput;
  archive: MetadataPublisherNestedArchiveCreateInput;
}

export interface MetadataPublisherWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPublisherNestedMetadataUpdateInput | null;
  archive?: MetadataPublisherNestedArchiveUpdateInput | null;
}

export interface MetadataPublishingIssueNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPublishingIssueNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPublishingIssueNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPublishingIssueNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPublishingIssueNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPublishingIssueNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPublishingIssueNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPublishingIssueNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPublishingIssueNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPublishingIssueNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPublishingIssueNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPublishingIssueNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPublishingIssueNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPublishingIssueNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPublishingIssueNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPublishingIssueNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPublishingIssueNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPublishingIssueNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPublishingIssueNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPublishingIssueNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPublishingIssueNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPublishingIssueNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPublishingIssueNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPublishingIssueNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPublishingIssueNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPublishingIssueNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPublishingIssueNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPublishingIssueNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPublishingIssueNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPublishingIssueNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPublishingIssue" nodes by specifying some conditions
 */
export interface MetadataPublishingIssueWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPublishingIssueWhereInput[] | null;
  OR?: MetadataPublishingIssueWhereInput[] | null;
  NOT?: MetadataPublishingIssueWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPublishingIssue" node.
 */
export interface MetadataPublishingIssueWhereUniqueInput {
  id: number;
}

export interface MetadataPublishingIssueWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPublishingIssueNestedPhotoCreateInput;
  metadata: MetadataPublishingIssueNestedMetadataCreateInput;
}

export interface MetadataPublishingIssueWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPublishingIssueNestedPhotoUpdateInput | null;
  metadata?: MetadataPublishingIssueNestedMetadataUpdateInput | null;
}

export interface MetadataPublishingIssueWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPublishingIssueNestedPhotoCreateInput;
  archive: MetadataPublishingIssueNestedArchiveCreateInput;
}

export interface MetadataPublishingIssueWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPublishingIssueNestedPhotoUpdateInput | null;
  archive?: MetadataPublishingIssueNestedArchiveUpdateInput | null;
}

export interface MetadataPublishingIssueWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPublishingIssueNestedMetadataCreateInput;
  archive: MetadataPublishingIssueNestedArchiveCreateInput;
}

export interface MetadataPublishingIssueWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPublishingIssueNestedMetadataUpdateInput | null;
  archive?: MetadataPublishingIssueNestedArchiveUpdateInput | null;
}

export interface MetadataPublishingSubjectNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPublishingSubjectNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPublishingSubjectNestedUpsertArchiveCreateInput | null;
}

export interface MetadataPublishingSubjectNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataPublishingSubjectNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataPublishingSubjectNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataPublishingSubjectNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPublishingSubjectNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPublishingSubjectNestedUpsertMetadataCreateInput | null;
}

export interface MetadataPublishingSubjectNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataPublishingSubjectNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataPublishingSubjectNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataPublishingSubjectNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPublishingSubjectNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPublishingSubjectNestedUpsertPhotoCreateInput | null;
}

export interface MetadataPublishingSubjectNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataPublishingSubjectNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataPublishingSubjectNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataPublishingSubjectNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPublishingSubjectNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataPublishingSubjectNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPublishingSubjectNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataPublishingSubjectNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPublishingSubjectNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataPublishingSubjectNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPublishingSubjectNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataPublishingSubjectNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPublishingSubjectNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataPublishingSubjectNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataPublishingSubjectNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataPublishingSubject" nodes by specifying some conditions
 */
export interface MetadataPublishingSubjectWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataPublishingSubjectWhereInput[] | null;
  OR?: MetadataPublishingSubjectWhereInput[] | null;
  NOT?: MetadataPublishingSubjectWhereInput | null;
}

/**
 * Identifies exactly one "MetadataPublishingSubject" node.
 */
export interface MetadataPublishingSubjectWhereUniqueInput {
  id: number;
}

export interface MetadataPublishingSubjectWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataPublishingSubjectNestedPhotoCreateInput;
  metadata: MetadataPublishingSubjectNestedMetadataCreateInput;
}

export interface MetadataPublishingSubjectWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataPublishingSubjectNestedPhotoUpdateInput | null;
  metadata?: MetadataPublishingSubjectNestedMetadataUpdateInput | null;
}

export interface MetadataPublishingSubjectWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataPublishingSubjectNestedPhotoCreateInput;
  archive: MetadataPublishingSubjectNestedArchiveCreateInput;
}

export interface MetadataPublishingSubjectWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataPublishingSubjectNestedPhotoUpdateInput | null;
  archive?: MetadataPublishingSubjectNestedArchiveUpdateInput | null;
}

export interface MetadataPublishingSubjectWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataPublishingSubjectNestedMetadataCreateInput;
  archive: MetadataPublishingSubjectNestedArchiveCreateInput;
}

export interface MetadataPublishingSubjectWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataPublishingSubjectNestedMetadataUpdateInput | null;
  archive?: MetadataPublishingSubjectNestedArchiveUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaByNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaByNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaByNestedUpsertArchiveCreateInput | null;
}

export interface MetadataQualifiedUsePrismaByNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaByNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaByNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaByNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaByNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaByNestedUpsertMetadataCreateInput | null;
}

export interface MetadataQualifiedUsePrismaByNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaByNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaByNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaByNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaByNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaByNestedUpsertPhotoCreateInput | null;
}

export interface MetadataQualifiedUsePrismaByNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaByNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaByNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaByNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataQualifiedUsePrismaByNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataQualifiedUsePrismaByNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataQualifiedUsePrismaByNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataQualifiedUsePrismaByNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataQualifiedUsePrismaByNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataQualifiedUsePrismaByNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaByNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaByNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataQualifiedUsePrismaByNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataQualifiedUsePrismaByNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataQualifiedUsePrismaByNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataQualifiedUsePrismaBy" nodes by specifying some conditions
 */
export interface MetadataQualifiedUsePrismaByWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataQualifiedUsePrismaByWhereInput[] | null;
  OR?: MetadataQualifiedUsePrismaByWhereInput[] | null;
  NOT?: MetadataQualifiedUsePrismaByWhereInput | null;
}

/**
 * Identifies exactly one "MetadataQualifiedUsePrismaBy" node.
 */
export interface MetadataQualifiedUsePrismaByWhereUniqueInput {
  id: number;
}

export interface MetadataQualifiedUsePrismaByWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataQualifiedUsePrismaByNestedPhotoCreateInput;
  metadata: MetadataQualifiedUsePrismaByNestedMetadataCreateInput;
}

export interface MetadataQualifiedUsePrismaByWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataQualifiedUsePrismaByNestedPhotoUpdateInput | null;
  metadata?: MetadataQualifiedUsePrismaByNestedMetadataUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaByWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataQualifiedUsePrismaByNestedPhotoCreateInput;
  archive: MetadataQualifiedUsePrismaByNestedArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaByWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataQualifiedUsePrismaByNestedPhotoUpdateInput | null;
  archive?: MetadataQualifiedUsePrismaByNestedArchiveUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaByWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataQualifiedUsePrismaByNestedMetadataCreateInput;
  archive: MetadataQualifiedUsePrismaByNestedArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaByWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataQualifiedUsePrismaByNestedMetadataUpdateInput | null;
  archive?: MetadataQualifiedUsePrismaByNestedArchiveUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaDateNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaDateNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaDateNestedUpsertArchiveCreateInput | null;
}

export interface MetadataQualifiedUsePrismaDateNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaDateNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaDateNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaDateNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaDateNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaDateNestedUpsertMetadataCreateInput | null;
}

export interface MetadataQualifiedUsePrismaDateNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaDateNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaDateNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaDateNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaDateNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaDateNestedUpsertPhotoCreateInput | null;
}

export interface MetadataQualifiedUsePrismaDateNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaDateNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaDateNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaDateNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataQualifiedUsePrismaDateNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataQualifiedUsePrismaDateNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataQualifiedUsePrismaDateNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataQualifiedUsePrismaDateNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataQualifiedUsePrismaDateNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataQualifiedUsePrismaDateNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaDateNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaDateNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataQualifiedUsePrismaDateNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataQualifiedUsePrismaDateNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataQualifiedUsePrismaDateNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataQualifiedUsePrismaDate" nodes by specifying some conditions
 */
export interface MetadataQualifiedUsePrismaDateWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataQualifiedUsePrismaDateWhereInput[] | null;
  OR?: MetadataQualifiedUsePrismaDateWhereInput[] | null;
  NOT?: MetadataQualifiedUsePrismaDateWhereInput | null;
}

/**
 * Identifies exactly one "MetadataQualifiedUsePrismaDate" node.
 */
export interface MetadataQualifiedUsePrismaDateWhereUniqueInput {
  id: number;
}

export interface MetadataQualifiedUsePrismaDateWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataQualifiedUsePrismaDateNestedPhotoCreateInput;
  metadata: MetadataQualifiedUsePrismaDateNestedMetadataCreateInput;
}

export interface MetadataQualifiedUsePrismaDateWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataQualifiedUsePrismaDateNestedPhotoUpdateInput | null;
  metadata?: MetadataQualifiedUsePrismaDateNestedMetadataUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaDateWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataQualifiedUsePrismaDateNestedPhotoCreateInput;
  archive: MetadataQualifiedUsePrismaDateNestedArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaDateWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataQualifiedUsePrismaDateNestedPhotoUpdateInput | null;
  archive?: MetadataQualifiedUsePrismaDateNestedArchiveUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaDateWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataQualifiedUsePrismaDateNestedMetadataCreateInput;
  archive: MetadataQualifiedUsePrismaDateNestedArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaDateWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataQualifiedUsePrismaDateNestedMetadataUpdateInput | null;
  archive?: MetadataQualifiedUsePrismaDateNestedArchiveUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaDurationNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaDurationNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaDurationNestedUpsertArchiveCreateInput | null;
}

export interface MetadataQualifiedUsePrismaDurationNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaDurationNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaDurationNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaDurationNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaDurationNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaDurationNestedUpsertMetadataCreateInput | null;
}

export interface MetadataQualifiedUsePrismaDurationNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaDurationNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaDurationNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaDurationNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaDurationNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaDurationNestedUpsertPhotoCreateInput | null;
}

export interface MetadataQualifiedUsePrismaDurationNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaDurationNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaDurationNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaDurationNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataQualifiedUsePrismaDurationNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataQualifiedUsePrismaDurationNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataQualifiedUsePrismaDurationNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataQualifiedUsePrismaDurationNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataQualifiedUsePrismaDurationNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataQualifiedUsePrismaDurationNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaDurationNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaDurationNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataQualifiedUsePrismaDurationNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataQualifiedUsePrismaDurationNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataQualifiedUsePrismaDurationNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataQualifiedUsePrismaDuration" nodes by specifying some conditions
 */
export interface MetadataQualifiedUsePrismaDurationWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataQualifiedUsePrismaDurationWhereInput[] | null;
  OR?: MetadataQualifiedUsePrismaDurationWhereInput[] | null;
  NOT?: MetadataQualifiedUsePrismaDurationWhereInput | null;
}

/**
 * Identifies exactly one "MetadataQualifiedUsePrismaDuration" node.
 */
export interface MetadataQualifiedUsePrismaDurationWhereUniqueInput {
  id: number;
}

export interface MetadataQualifiedUsePrismaDurationWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataQualifiedUsePrismaDurationNestedPhotoCreateInput;
  metadata: MetadataQualifiedUsePrismaDurationNestedMetadataCreateInput;
}

export interface MetadataQualifiedUsePrismaDurationWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataQualifiedUsePrismaDurationNestedPhotoUpdateInput | null;
  metadata?: MetadataQualifiedUsePrismaDurationNestedMetadataUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaDurationWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataQualifiedUsePrismaDurationNestedPhotoCreateInput;
  archive: MetadataQualifiedUsePrismaDurationNestedArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaDurationWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataQualifiedUsePrismaDurationNestedPhotoUpdateInput | null;
  archive?: MetadataQualifiedUsePrismaDurationNestedArchiveUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaDurationWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataQualifiedUsePrismaDurationNestedMetadataCreateInput;
  archive: MetadataQualifiedUsePrismaDurationNestedArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaDurationWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataQualifiedUsePrismaDurationNestedMetadataUpdateInput | null;
  archive?: MetadataQualifiedUsePrismaDurationNestedArchiveUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaSupportNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaSupportNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaSupportNestedUpsertArchiveCreateInput | null;
}

export interface MetadataQualifiedUsePrismaSupportNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaSupportNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaSupportNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaSupportNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaSupportNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaSupportNestedUpsertMetadataCreateInput | null;
}

export interface MetadataQualifiedUsePrismaSupportNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaSupportNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaSupportNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaSupportNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaSupportNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaSupportNestedUpsertPhotoCreateInput | null;
}

export interface MetadataQualifiedUsePrismaSupportNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataQualifiedUsePrismaSupportNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataQualifiedUsePrismaSupportNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaSupportNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataQualifiedUsePrismaSupportNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataQualifiedUsePrismaSupportNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataQualifiedUsePrismaSupportNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataQualifiedUsePrismaSupportNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataQualifiedUsePrismaSupportNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataQualifiedUsePrismaSupportNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaSupportNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaSupportNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataQualifiedUsePrismaSupportNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataQualifiedUsePrismaSupportNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataQualifiedUsePrismaSupportNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataQualifiedUsePrismaSupport" nodes by specifying some conditions
 */
export interface MetadataQualifiedUsePrismaSupportWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataQualifiedUsePrismaSupportWhereInput[] | null;
  OR?: MetadataQualifiedUsePrismaSupportWhereInput[] | null;
  NOT?: MetadataQualifiedUsePrismaSupportWhereInput | null;
}

/**
 * Identifies exactly one "MetadataQualifiedUsePrismaSupport" node.
 */
export interface MetadataQualifiedUsePrismaSupportWhereUniqueInput {
  id: number;
}

export interface MetadataQualifiedUsePrismaSupportWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataQualifiedUsePrismaSupportNestedPhotoCreateInput;
  metadata: MetadataQualifiedUsePrismaSupportNestedMetadataCreateInput;
}

export interface MetadataQualifiedUsePrismaSupportWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataQualifiedUsePrismaSupportNestedPhotoUpdateInput | null;
  metadata?: MetadataQualifiedUsePrismaSupportNestedMetadataUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaSupportWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataQualifiedUsePrismaSupportNestedPhotoCreateInput;
  archive: MetadataQualifiedUsePrismaSupportNestedArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaSupportWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataQualifiedUsePrismaSupportNestedPhotoUpdateInput | null;
  archive?: MetadataQualifiedUsePrismaSupportNestedArchiveUpdateInput | null;
}

export interface MetadataQualifiedUsePrismaSupportWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataQualifiedUsePrismaSupportNestedMetadataCreateInput;
  archive: MetadataQualifiedUsePrismaSupportNestedArchiveCreateInput;
}

export interface MetadataQualifiedUsePrismaSupportWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataQualifiedUsePrismaSupportNestedMetadataUpdateInput | null;
  archive?: MetadataQualifiedUsePrismaSupportNestedArchiveUpdateInput | null;
}

export interface MetadataRatingNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataRatingNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataRatingNestedUpsertArchiveCreateInput | null;
}

export interface MetadataRatingNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataRatingNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataRatingNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataRatingNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataRatingNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataRatingNestedUpsertMetadataCreateInput | null;
}

export interface MetadataRatingNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataRatingNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataRatingNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataRatingNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataRatingNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataRatingNestedUpsertPhotoCreateInput | null;
}

export interface MetadataRatingNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataRatingNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataRatingNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataRatingNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataRatingNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataRatingNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataRatingNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataRatingNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataRatingNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataRatingNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataRatingNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataRatingNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataRatingNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataRatingNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataRatingNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataRating" nodes by specifying some conditions
 */
export interface MetadataRatingWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataRatingWhereInput[] | null;
  OR?: MetadataRatingWhereInput[] | null;
  NOT?: MetadataRatingWhereInput | null;
}

/**
 * Identifies exactly one "MetadataRating" node.
 */
export interface MetadataRatingWhereUniqueInput {
  id: number;
}

export interface MetadataRatingWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataRatingNestedPhotoCreateInput;
  metadata: MetadataRatingNestedMetadataCreateInput;
}

export interface MetadataRatingWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataRatingNestedPhotoUpdateInput | null;
  metadata?: MetadataRatingNestedMetadataUpdateInput | null;
}

export interface MetadataRatingWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataRatingNestedPhotoCreateInput;
  archive: MetadataRatingNestedArchiveCreateInput;
}

export interface MetadataRatingWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataRatingNestedPhotoUpdateInput | null;
  archive?: MetadataRatingNestedArchiveUpdateInput | null;
}

export interface MetadataRatingWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataRatingNestedMetadataCreateInput;
  archive: MetadataRatingNestedArchiveCreateInput;
}

export interface MetadataRatingWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataRatingNestedMetadataUpdateInput | null;
  archive?: MetadataRatingNestedArchiveUpdateInput | null;
}

export interface MetadataReferenceDateNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataReferenceDateNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataReferenceDateNestedUpsertArchiveCreateInput | null;
}

export interface MetadataReferenceDateNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataReferenceDateNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataReferenceDateNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataReferenceDateNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataReferenceDateNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataReferenceDateNestedUpsertMetadataCreateInput | null;
}

export interface MetadataReferenceDateNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataReferenceDateNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataReferenceDateNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataReferenceDateNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataReferenceDateNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataReferenceDateNestedUpsertPhotoCreateInput | null;
}

export interface MetadataReferenceDateNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataReferenceDateNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataReferenceDateNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataReferenceDateNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataReferenceDateNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataReferenceDateNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataReferenceDateNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataReferenceDateNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataReferenceDateNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataReferenceDateNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataReferenceDateNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataReferenceDateNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataReferenceDateNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataReferenceDateNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataReferenceDateNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataReferenceDate" nodes by specifying some conditions
 */
export interface MetadataReferenceDateWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataReferenceDateWhereInput[] | null;
  OR?: MetadataReferenceDateWhereInput[] | null;
  NOT?: MetadataReferenceDateWhereInput | null;
}

/**
 * Identifies exactly one "MetadataReferenceDate" node.
 */
export interface MetadataReferenceDateWhereUniqueInput {
  id: number;
}

export interface MetadataReferenceDateWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataReferenceDateNestedPhotoCreateInput;
  metadata: MetadataReferenceDateNestedMetadataCreateInput;
}

export interface MetadataReferenceDateWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataReferenceDateNestedPhotoUpdateInput | null;
  metadata?: MetadataReferenceDateNestedMetadataUpdateInput | null;
}

export interface MetadataReferenceDateWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataReferenceDateNestedPhotoCreateInput;
  archive: MetadataReferenceDateNestedArchiveCreateInput;
}

export interface MetadataReferenceDateWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataReferenceDateNestedPhotoUpdateInput | null;
  archive?: MetadataReferenceDateNestedArchiveUpdateInput | null;
}

export interface MetadataReferenceDateWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataReferenceDateNestedMetadataCreateInput;
  archive: MetadataReferenceDateNestedArchiveCreateInput;
}

export interface MetadataReferenceDateWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataReferenceDateNestedMetadataUpdateInput | null;
  archive?: MetadataReferenceDateNestedArchiveUpdateInput | null;
}

export interface MetadataReferenceNumberNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataReferenceNumberNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataReferenceNumberNestedUpsertArchiveCreateInput | null;
}

export interface MetadataReferenceNumberNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataReferenceNumberNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataReferenceNumberNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataReferenceNumberNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataReferenceNumberNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataReferenceNumberNestedUpsertMetadataCreateInput | null;
}

export interface MetadataReferenceNumberNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataReferenceNumberNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataReferenceNumberNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataReferenceNumberNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataReferenceNumberNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataReferenceNumberNestedUpsertPhotoCreateInput | null;
}

export interface MetadataReferenceNumberNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataReferenceNumberNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataReferenceNumberNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataReferenceNumberNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataReferenceNumberNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataReferenceNumberNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataReferenceNumberNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataReferenceNumberNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataReferenceNumberNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataReferenceNumberNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataReferenceNumberNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataReferenceNumberNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataReferenceNumberNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataReferenceNumberNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataReferenceNumberNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataReferenceNumber" nodes by specifying some conditions
 */
export interface MetadataReferenceNumberWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataReferenceNumberWhereInput[] | null;
  OR?: MetadataReferenceNumberWhereInput[] | null;
  NOT?: MetadataReferenceNumberWhereInput | null;
}

/**
 * Identifies exactly one "MetadataReferenceNumber" node.
 */
export interface MetadataReferenceNumberWhereUniqueInput {
  id: number;
}

export interface MetadataReferenceNumberWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataReferenceNumberNestedPhotoCreateInput;
  metadata: MetadataReferenceNumberNestedMetadataCreateInput;
}

export interface MetadataReferenceNumberWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataReferenceNumberNestedPhotoUpdateInput | null;
  metadata?: MetadataReferenceNumberNestedMetadataUpdateInput | null;
}

export interface MetadataReferenceNumberWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataReferenceNumberNestedPhotoCreateInput;
  archive: MetadataReferenceNumberNestedArchiveCreateInput;
}

export interface MetadataReferenceNumberWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataReferenceNumberNestedPhotoUpdateInput | null;
  archive?: MetadataReferenceNumberNestedArchiveUpdateInput | null;
}

export interface MetadataReferenceNumberWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataReferenceNumberNestedMetadataCreateInput;
  archive: MetadataReferenceNumberNestedArchiveCreateInput;
}

export interface MetadataReferenceNumberWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataReferenceNumberNestedMetadataUpdateInput | null;
  archive?: MetadataReferenceNumberNestedArchiveUpdateInput | null;
}

export interface MetadataReferenceServiceNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataReferenceServiceNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataReferenceServiceNestedUpsertArchiveCreateInput | null;
}

export interface MetadataReferenceServiceNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataReferenceServiceNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataReferenceServiceNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataReferenceServiceNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataReferenceServiceNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataReferenceServiceNestedUpsertMetadataCreateInput | null;
}

export interface MetadataReferenceServiceNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataReferenceServiceNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataReferenceServiceNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataReferenceServiceNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataReferenceServiceNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataReferenceServiceNestedUpsertPhotoCreateInput | null;
}

export interface MetadataReferenceServiceNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataReferenceServiceNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataReferenceServiceNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataReferenceServiceNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataReferenceServiceNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataReferenceServiceNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataReferenceServiceNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataReferenceServiceNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataReferenceServiceNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataReferenceServiceNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataReferenceServiceNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataReferenceServiceNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataReferenceServiceNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataReferenceServiceNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataReferenceServiceNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataReferenceService" nodes by specifying some conditions
 */
export interface MetadataReferenceServiceWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataReferenceServiceWhereInput[] | null;
  OR?: MetadataReferenceServiceWhereInput[] | null;
  NOT?: MetadataReferenceServiceWhereInput | null;
}

/**
 * Identifies exactly one "MetadataReferenceService" node.
 */
export interface MetadataReferenceServiceWhereUniqueInput {
  id: number;
}

export interface MetadataReferenceServiceWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataReferenceServiceNestedPhotoCreateInput;
  metadata: MetadataReferenceServiceNestedMetadataCreateInput;
}

export interface MetadataReferenceServiceWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataReferenceServiceNestedPhotoUpdateInput | null;
  metadata?: MetadataReferenceServiceNestedMetadataUpdateInput | null;
}

export interface MetadataReferenceServiceWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataReferenceServiceNestedPhotoCreateInput;
  archive: MetadataReferenceServiceNestedArchiveCreateInput;
}

export interface MetadataReferenceServiceWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataReferenceServiceNestedPhotoUpdateInput | null;
  archive?: MetadataReferenceServiceNestedArchiveUpdateInput | null;
}

export interface MetadataReferenceServiceWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataReferenceServiceNestedMetadataCreateInput;
  archive: MetadataReferenceServiceNestedArchiveCreateInput;
}

export interface MetadataReferenceServiceWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataReferenceServiceNestedMetadataUpdateInput | null;
  archive?: MetadataReferenceServiceNestedArchiveUpdateInput | null;
}

export interface MetadataReleaseDateNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataReleaseDateNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataReleaseDateNestedUpsertArchiveCreateInput | null;
}

export interface MetadataReleaseDateNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataReleaseDateNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataReleaseDateNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataReleaseDateNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataReleaseDateNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataReleaseDateNestedUpsertMetadataCreateInput | null;
}

export interface MetadataReleaseDateNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataReleaseDateNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataReleaseDateNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataReleaseDateNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataReleaseDateNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataReleaseDateNestedUpsertPhotoCreateInput | null;
}

export interface MetadataReleaseDateNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataReleaseDateNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataReleaseDateNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataReleaseDateNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataReleaseDateNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataReleaseDateNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataReleaseDateNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataReleaseDateNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataReleaseDateNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataReleaseDateNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataReleaseDateNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataReleaseDateNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataReleaseDateNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataReleaseDateNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataReleaseDateNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataReleaseDate" nodes by specifying some conditions
 */
export interface MetadataReleaseDateWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataReleaseDateWhereInput[] | null;
  OR?: MetadataReleaseDateWhereInput[] | null;
  NOT?: MetadataReleaseDateWhereInput | null;
}

/**
 * Identifies exactly one "MetadataReleaseDate" node.
 */
export interface MetadataReleaseDateWhereUniqueInput {
  id: number;
}

export interface MetadataReleaseDateWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataReleaseDateNestedPhotoCreateInput;
  metadata: MetadataReleaseDateNestedMetadataCreateInput;
}

export interface MetadataReleaseDateWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataReleaseDateNestedPhotoUpdateInput | null;
  metadata?: MetadataReleaseDateNestedMetadataUpdateInput | null;
}

export interface MetadataReleaseDateWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataReleaseDateNestedPhotoCreateInput;
  archive: MetadataReleaseDateNestedArchiveCreateInput;
}

export interface MetadataReleaseDateWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataReleaseDateNestedPhotoUpdateInput | null;
  archive?: MetadataReleaseDateNestedArchiveUpdateInput | null;
}

export interface MetadataReleaseDateWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataReleaseDateNestedMetadataCreateInput;
  archive: MetadataReleaseDateNestedArchiveCreateInput;
}

export interface MetadataReleaseDateWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataReleaseDateNestedMetadataUpdateInput | null;
  archive?: MetadataReleaseDateNestedArchiveUpdateInput | null;
}

export interface MetadataReleaseTimeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataReleaseTimeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataReleaseTimeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataReleaseTimeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataReleaseTimeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataReleaseTimeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataReleaseTimeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataReleaseTimeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataReleaseTimeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataReleaseTimeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataReleaseTimeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataReleaseTimeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataReleaseTimeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataReleaseTimeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataReleaseTimeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataReleaseTimeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataReleaseTimeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataReleaseTimeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataReleaseTimeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataReleaseTimeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataReleaseTimeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataReleaseTimeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataReleaseTimeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataReleaseTimeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataReleaseTimeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataReleaseTimeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataReleaseTimeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataReleaseTimeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataReleaseTimeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataReleaseTimeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataReleaseTime" nodes by specifying some conditions
 */
export interface MetadataReleaseTimeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataReleaseTimeWhereInput[] | null;
  OR?: MetadataReleaseTimeWhereInput[] | null;
  NOT?: MetadataReleaseTimeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataReleaseTime" node.
 */
export interface MetadataReleaseTimeWhereUniqueInput {
  id: number;
}

export interface MetadataReleaseTimeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataReleaseTimeNestedPhotoCreateInput;
  metadata: MetadataReleaseTimeNestedMetadataCreateInput;
}

export interface MetadataReleaseTimeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataReleaseTimeNestedPhotoUpdateInput | null;
  metadata?: MetadataReleaseTimeNestedMetadataUpdateInput | null;
}

export interface MetadataReleaseTimeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataReleaseTimeNestedPhotoCreateInput;
  archive: MetadataReleaseTimeNestedArchiveCreateInput;
}

export interface MetadataReleaseTimeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataReleaseTimeNestedPhotoUpdateInput | null;
  archive?: MetadataReleaseTimeNestedArchiveUpdateInput | null;
}

export interface MetadataReleaseTimeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataReleaseTimeNestedMetadataCreateInput;
  archive: MetadataReleaseTimeNestedArchiveCreateInput;
}

export interface MetadataReleaseTimeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataReleaseTimeNestedMetadataUpdateInput | null;
  archive?: MetadataReleaseTimeNestedArchiveUpdateInput | null;
}

export interface MetadataRequiredPermissionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataRequiredPermissionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataRequiredPermissionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataRequiredPermissionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataRequiredPermissionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataRequiredPermissionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataRequiredPermissionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataRequiredPermissionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataRequiredPermissionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataRequiredPermissionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataRequiredPermissionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataRequiredPermissionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataRequiredPermissionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataRequiredPermissionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataRequiredPermissionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataRequiredPermissionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataRequiredPermissionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataRequiredPermissionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataRequiredPermissionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataRequiredPermissionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataRequiredPermissionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataRequiredPermissionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataRequiredPermissionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataRequiredPermissionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataRequiredPermissionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataRequiredPermissionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataRequiredPermissionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataRequiredPermissionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataRequiredPermissionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataRequiredPermissionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataRequiredPermission" nodes by specifying some conditions
 */
export interface MetadataRequiredPermissionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataRequiredPermissionWhereInput[] | null;
  OR?: MetadataRequiredPermissionWhereInput[] | null;
  NOT?: MetadataRequiredPermissionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataRequiredPermission" node.
 */
export interface MetadataRequiredPermissionWhereUniqueInput {
  id: number;
}

export interface MetadataRequiredPermissionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataRequiredPermissionNestedPhotoCreateInput;
  metadata: MetadataRequiredPermissionNestedMetadataCreateInput;
}

export interface MetadataRequiredPermissionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataRequiredPermissionNestedPhotoUpdateInput | null;
  metadata?: MetadataRequiredPermissionNestedMetadataUpdateInput | null;
}

export interface MetadataRequiredPermissionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataRequiredPermissionNestedPhotoCreateInput;
  archive: MetadataRequiredPermissionNestedArchiveCreateInput;
}

export interface MetadataRequiredPermissionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataRequiredPermissionNestedPhotoUpdateInput | null;
  archive?: MetadataRequiredPermissionNestedArchiveUpdateInput | null;
}

export interface MetadataRequiredPermissionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataRequiredPermissionNestedMetadataCreateInput;
  archive: MetadataRequiredPermissionNestedArchiveCreateInput;
}

export interface MetadataRequiredPermissionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataRequiredPermissionNestedMetadataUpdateInput | null;
  archive?: MetadataRequiredPermissionNestedArchiveUpdateInput | null;
}

export interface MetadataResolutionKindNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataResolutionKindNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataResolutionKindNestedUpsertArchiveCreateInput | null;
}

export interface MetadataResolutionKindNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataResolutionKindNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataResolutionKindNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataResolutionKindNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataResolutionKindNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataResolutionKindNestedUpsertMetadataCreateInput | null;
}

export interface MetadataResolutionKindNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataResolutionKindNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataResolutionKindNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataResolutionKindNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataResolutionKindNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataResolutionKindNestedUpsertPhotoCreateInput | null;
}

export interface MetadataResolutionKindNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataResolutionKindNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataResolutionKindNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataResolutionKindNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataResolutionKindNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataResolutionKindNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataResolutionKindNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataResolutionKindNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataResolutionKindNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataResolutionKindNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataResolutionKindNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataResolutionKindNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataResolutionKindNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataResolutionKindNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataResolutionKindNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataResolutionKind" nodes by specifying some conditions
 */
export interface MetadataResolutionKindWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataResolutionKindWhereInput[] | null;
  OR?: MetadataResolutionKindWhereInput[] | null;
  NOT?: MetadataResolutionKindWhereInput | null;
}

/**
 * Identifies exactly one "MetadataResolutionKind" node.
 */
export interface MetadataResolutionKindWhereUniqueInput {
  id: number;
}

export interface MetadataResolutionKindWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataResolutionKindNestedPhotoCreateInput;
  metadata: MetadataResolutionKindNestedMetadataCreateInput;
}

export interface MetadataResolutionKindWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataResolutionKindNestedPhotoUpdateInput | null;
  metadata?: MetadataResolutionKindNestedMetadataUpdateInput | null;
}

export interface MetadataResolutionKindWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataResolutionKindNestedPhotoCreateInput;
  archive: MetadataResolutionKindNestedArchiveCreateInput;
}

export interface MetadataResolutionKindWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataResolutionKindNestedPhotoUpdateInput | null;
  archive?: MetadataResolutionKindNestedArchiveUpdateInput | null;
}

export interface MetadataResolutionKindWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataResolutionKindNestedMetadataCreateInput;
  archive: MetadataResolutionKindNestedArchiveCreateInput;
}

export interface MetadataResolutionKindWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataResolutionKindNestedMetadataUpdateInput | null;
  archive?: MetadataResolutionKindNestedArchiveUpdateInput | null;
}

export interface MetadataRightsNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataRightsNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataRightsNestedUpsertArchiveCreateInput | null;
}

export interface MetadataRightsNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataRightsNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataRightsNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataRightsNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataRightsNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataRightsNestedUpsertMetadataCreateInput | null;
}

export interface MetadataRightsNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataRightsNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataRightsNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataRightsNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataRightsNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataRightsNestedUpsertPhotoCreateInput | null;
}

export interface MetadataRightsNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataRightsNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataRightsNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataRightsNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataRightsNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataRightsNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataRightsNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataRightsNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataRightsNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataRightsNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataRightsNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataRightsNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataRightsNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataRightsNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataRightsNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataRights" nodes by specifying some conditions
 */
export interface MetadataRightsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataRightsWhereInput[] | null;
  OR?: MetadataRightsWhereInput[] | null;
  NOT?: MetadataRightsWhereInput | null;
}

/**
 * Identifies exactly one "MetadataRights" node.
 */
export interface MetadataRightsWhereUniqueInput {
  id: number;
}

export interface MetadataRightsWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataRightsNestedPhotoCreateInput;
  metadata: MetadataRightsNestedMetadataCreateInput;
}

export interface MetadataRightsWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataRightsNestedPhotoUpdateInput | null;
  metadata?: MetadataRightsNestedMetadataUpdateInput | null;
}

export interface MetadataRightsWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataRightsNestedPhotoCreateInput;
  archive: MetadataRightsNestedArchiveCreateInput;
}

export interface MetadataRightsWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataRightsNestedPhotoUpdateInput | null;
  archive?: MetadataRightsNestedArchiveUpdateInput | null;
}

export interface MetadataRightsWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataRightsNestedMetadataCreateInput;
  archive: MetadataRightsNestedArchiveCreateInput;
}

export interface MetadataRightsWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataRightsNestedMetadataUpdateInput | null;
  archive?: MetadataRightsNestedArchiveUpdateInput | null;
}

export interface MetadataRoyaltyfreeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataRoyaltyfreeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataRoyaltyfreeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataRoyaltyfreeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataRoyaltyfreeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataRoyaltyfreeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataRoyaltyfreeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataRoyaltyfreeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataRoyaltyfreeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataRoyaltyfreeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataRoyaltyfreeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataRoyaltyfreeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataRoyaltyfreeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataRoyaltyfreeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataRoyaltyfreeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataRoyaltyfreeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataRoyaltyfreeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataRoyaltyfreeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataRoyaltyfreeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataRoyaltyfreeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataRoyaltyfreeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataRoyaltyfreeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataRoyaltyfreeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataRoyaltyfreeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataRoyaltyfreeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataRoyaltyfreeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataRoyaltyfreeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataRoyaltyfreeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataRoyaltyfreeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataRoyaltyfreeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataRoyaltyfree" nodes by specifying some conditions
 */
export interface MetadataRoyaltyfreeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataRoyaltyfreeWhereInput[] | null;
  OR?: MetadataRoyaltyfreeWhereInput[] | null;
  NOT?: MetadataRoyaltyfreeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataRoyaltyfree" node.
 */
export interface MetadataRoyaltyfreeWhereUniqueInput {
  id: number;
}

export interface MetadataRoyaltyfreeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataRoyaltyfreeNestedPhotoCreateInput;
  metadata: MetadataRoyaltyfreeNestedMetadataCreateInput;
}

export interface MetadataRoyaltyfreeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataRoyaltyfreeNestedPhotoUpdateInput | null;
  metadata?: MetadataRoyaltyfreeNestedMetadataUpdateInput | null;
}

export interface MetadataRoyaltyfreeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataRoyaltyfreeNestedPhotoCreateInput;
  archive: MetadataRoyaltyfreeNestedArchiveCreateInput;
}

export interface MetadataRoyaltyfreeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataRoyaltyfreeNestedPhotoUpdateInput | null;
  archive?: MetadataRoyaltyfreeNestedArchiveUpdateInput | null;
}

export interface MetadataRoyaltyfreeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataRoyaltyfreeNestedMetadataCreateInput;
  archive: MetadataRoyaltyfreeNestedArchiveCreateInput;
}

export interface MetadataRoyaltyfreeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataRoyaltyfreeNestedMetadataUpdateInput | null;
  archive?: MetadataRoyaltyfreeNestedArchiveUpdateInput | null;
}

export interface MetadataSceneNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSceneNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSceneNestedUpsertArchiveCreateInput | null;
}

export interface MetadataSceneNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSceneNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSceneNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataSceneNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSceneNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSceneNestedUpsertMetadataCreateInput | null;
}

export interface MetadataSceneNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSceneNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSceneNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataSceneNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSceneNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSceneNestedUpsertPhotoCreateInput | null;
}

export interface MetadataSceneNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSceneNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSceneNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataSceneNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSceneNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSceneNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSceneNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSceneNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSceneNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSceneNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSceneNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSceneNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSceneNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSceneNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataSceneNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataScene" nodes by specifying some conditions
 */
export interface MetadataSceneWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSceneWhereInput[] | null;
  OR?: MetadataSceneWhereInput[] | null;
  NOT?: MetadataSceneWhereInput | null;
}

/**
 * Identifies exactly one "MetadataScene" node.
 */
export interface MetadataSceneWhereUniqueInput {
  id: number;
}

export interface MetadataSceneWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataSceneNestedPhotoCreateInput;
  metadata: MetadataSceneNestedMetadataCreateInput;
}

export interface MetadataSceneWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataSceneNestedPhotoUpdateInput | null;
  metadata?: MetadataSceneNestedMetadataUpdateInput | null;
}

export interface MetadataSceneWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataSceneNestedPhotoCreateInput;
  archive: MetadataSceneNestedArchiveCreateInput;
}

export interface MetadataSceneWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataSceneNestedPhotoUpdateInput | null;
  archive?: MetadataSceneNestedArchiveUpdateInput | null;
}

export interface MetadataSceneWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataSceneNestedMetadataCreateInput;
  archive: MetadataSceneNestedArchiveCreateInput;
}

export interface MetadataSceneWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataSceneNestedMetadataUpdateInput | null;
  archive?: MetadataSceneNestedArchiveUpdateInput | null;
}

export interface MetadataSectionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSectionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSectionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataSectionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSectionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSectionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataSectionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSectionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSectionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataSectionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSectionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSectionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataSectionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSectionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSectionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataSectionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSectionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSectionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataSectionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSectionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSectionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSectionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSectionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSectionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSectionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSectionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSectionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSectionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSectionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataSectionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataSection" nodes by specifying some conditions
 */
export interface MetadataSectionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSectionWhereInput[] | null;
  OR?: MetadataSectionWhereInput[] | null;
  NOT?: MetadataSectionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataSection" node.
 */
export interface MetadataSectionWhereUniqueInput {
  id: number;
}

export interface MetadataSectionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataSectionNestedPhotoCreateInput;
  metadata: MetadataSectionNestedMetadataCreateInput;
}

export interface MetadataSectionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataSectionNestedPhotoUpdateInput | null;
  metadata?: MetadataSectionNestedMetadataUpdateInput | null;
}

export interface MetadataSectionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataSectionNestedPhotoCreateInput;
  archive: MetadataSectionNestedArchiveCreateInput;
}

export interface MetadataSectionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataSectionNestedPhotoUpdateInput | null;
  archive?: MetadataSectionNestedArchiveUpdateInput | null;
}

export interface MetadataSectionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataSectionNestedMetadataCreateInput;
  archive: MetadataSectionNestedArchiveCreateInput;
}

export interface MetadataSectionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataSectionNestedMetadataUpdateInput | null;
  archive?: MetadataSectionNestedArchiveUpdateInput | null;
}

export interface MetadataSentToNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSentToNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSentToNestedUpsertArchiveCreateInput | null;
}

export interface MetadataSentToNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSentToNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSentToNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataSentToNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSentToNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSentToNestedUpsertMetadataCreateInput | null;
}

export interface MetadataSentToNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSentToNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSentToNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataSentToNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSentToNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSentToNestedUpsertPhotoCreateInput | null;
}

export interface MetadataSentToNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSentToNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSentToNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataSentToNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSentToNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSentToNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSentToNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSentToNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSentToNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSentToNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSentToNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSentToNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSentToNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSentToNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataSentToNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataSentTo" nodes by specifying some conditions
 */
export interface MetadataSentToWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSentToWhereInput[] | null;
  OR?: MetadataSentToWhereInput[] | null;
  NOT?: MetadataSentToWhereInput | null;
}

/**
 * Identifies exactly one "MetadataSentTo" node.
 */
export interface MetadataSentToWhereUniqueInput {
  id: number;
}

export interface MetadataSentToWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataSentToNestedPhotoCreateInput;
  metadata: MetadataSentToNestedMetadataCreateInput;
}

export interface MetadataSentToWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataSentToNestedPhotoUpdateInput | null;
  metadata?: MetadataSentToNestedMetadataUpdateInput | null;
}

export interface MetadataSentToWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataSentToNestedPhotoCreateInput;
  archive: MetadataSentToNestedArchiveCreateInput;
}

export interface MetadataSentToWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataSentToNestedPhotoUpdateInput | null;
  archive?: MetadataSentToNestedArchiveUpdateInput | null;
}

export interface MetadataSentToWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataSentToNestedMetadataCreateInput;
  archive: MetadataSentToNestedArchiveCreateInput;
}

export interface MetadataSentToWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataSentToNestedMetadataUpdateInput | null;
  archive?: MetadataSentToNestedArchiveUpdateInput | null;
}

export interface MetadataSerialNumberNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSerialNumberNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSerialNumberNestedUpsertArchiveCreateInput | null;
}

export interface MetadataSerialNumberNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSerialNumberNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSerialNumberNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataSerialNumberNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSerialNumberNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSerialNumberNestedUpsertMetadataCreateInput | null;
}

export interface MetadataSerialNumberNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSerialNumberNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSerialNumberNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataSerialNumberNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSerialNumberNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSerialNumberNestedUpsertPhotoCreateInput | null;
}

export interface MetadataSerialNumberNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSerialNumberNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSerialNumberNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataSerialNumberNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSerialNumberNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSerialNumberNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSerialNumberNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSerialNumberNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSerialNumberNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSerialNumberNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSerialNumberNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSerialNumberNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSerialNumberNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSerialNumberNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataSerialNumberNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataSerialNumber" nodes by specifying some conditions
 */
export interface MetadataSerialNumberWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSerialNumberWhereInput[] | null;
  OR?: MetadataSerialNumberWhereInput[] | null;
  NOT?: MetadataSerialNumberWhereInput | null;
}

/**
 * Identifies exactly one "MetadataSerialNumber" node.
 */
export interface MetadataSerialNumberWhereUniqueInput {
  id: number;
}

export interface MetadataSerialNumberWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataSerialNumberNestedPhotoCreateInput;
  metadata: MetadataSerialNumberNestedMetadataCreateInput;
}

export interface MetadataSerialNumberWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataSerialNumberNestedPhotoUpdateInput | null;
  metadata?: MetadataSerialNumberNestedMetadataUpdateInput | null;
}

export interface MetadataSerialNumberWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataSerialNumberNestedPhotoCreateInput;
  archive: MetadataSerialNumberNestedArchiveCreateInput;
}

export interface MetadataSerialNumberWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataSerialNumberNestedPhotoUpdateInput | null;
  archive?: MetadataSerialNumberNestedArchiveUpdateInput | null;
}

export interface MetadataSerialNumberWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataSerialNumberNestedMetadataCreateInput;
  archive: MetadataSerialNumberNestedArchiveCreateInput;
}

export interface MetadataSerialNumberWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataSerialNumberNestedMetadataUpdateInput | null;
  archive?: MetadataSerialNumberNestedArchiveUpdateInput | null;
}

export interface MetadataSeriesDateTimeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSeriesDateTimeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSeriesDateTimeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataSeriesDateTimeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSeriesDateTimeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSeriesDateTimeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataSeriesDateTimeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSeriesDateTimeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSeriesDateTimeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataSeriesDateTimeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSeriesDateTimeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSeriesDateTimeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataSeriesDateTimeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSeriesDateTimeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSeriesDateTimeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataSeriesDateTimeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSeriesDateTimeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSeriesDateTimeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataSeriesDateTimeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSeriesDateTimeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSeriesDateTimeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSeriesDateTimeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSeriesDateTimeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSeriesDateTimeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSeriesDateTimeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSeriesDateTimeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSeriesDateTimeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSeriesDateTimeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSeriesDateTimeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataSeriesDateTimeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataSeriesDateTime" nodes by specifying some conditions
 */
export interface MetadataSeriesDateTimeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSeriesDateTimeWhereInput[] | null;
  OR?: MetadataSeriesDateTimeWhereInput[] | null;
  NOT?: MetadataSeriesDateTimeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataSeriesDateTime" node.
 */
export interface MetadataSeriesDateTimeWhereUniqueInput {
  id: number;
}

export interface MetadataSeriesDateTimeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataSeriesDateTimeNestedPhotoCreateInput;
  metadata: MetadataSeriesDateTimeNestedMetadataCreateInput;
}

export interface MetadataSeriesDateTimeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataSeriesDateTimeNestedPhotoUpdateInput | null;
  metadata?: MetadataSeriesDateTimeNestedMetadataUpdateInput | null;
}

export interface MetadataSeriesDateTimeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataSeriesDateTimeNestedPhotoCreateInput;
  archive: MetadataSeriesDateTimeNestedArchiveCreateInput;
}

export interface MetadataSeriesDateTimeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataSeriesDateTimeNestedPhotoUpdateInput | null;
  archive?: MetadataSeriesDateTimeNestedArchiveUpdateInput | null;
}

export interface MetadataSeriesDateTimeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataSeriesDateTimeNestedMetadataCreateInput;
  archive: MetadataSeriesDateTimeNestedArchiveCreateInput;
}

export interface MetadataSeriesDateTimeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataSeriesDateTimeNestedMetadataUpdateInput | null;
  archive?: MetadataSeriesDateTimeNestedArchiveUpdateInput | null;
}

export interface MetadataSeriesDescriptionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSeriesDescriptionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSeriesDescriptionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataSeriesDescriptionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSeriesDescriptionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSeriesDescriptionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataSeriesDescriptionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSeriesDescriptionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSeriesDescriptionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataSeriesDescriptionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSeriesDescriptionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSeriesDescriptionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataSeriesDescriptionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSeriesDescriptionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSeriesDescriptionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataSeriesDescriptionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSeriesDescriptionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSeriesDescriptionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataSeriesDescriptionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSeriesDescriptionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSeriesDescriptionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSeriesDescriptionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSeriesDescriptionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSeriesDescriptionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSeriesDescriptionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSeriesDescriptionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSeriesDescriptionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSeriesDescriptionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSeriesDescriptionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataSeriesDescriptionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataSeriesDescription" nodes by specifying some conditions
 */
export interface MetadataSeriesDescriptionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSeriesDescriptionWhereInput[] | null;
  OR?: MetadataSeriesDescriptionWhereInput[] | null;
  NOT?: MetadataSeriesDescriptionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataSeriesDescription" node.
 */
export interface MetadataSeriesDescriptionWhereUniqueInput {
  id: number;
}

export interface MetadataSeriesDescriptionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataSeriesDescriptionNestedPhotoCreateInput;
  metadata: MetadataSeriesDescriptionNestedMetadataCreateInput;
}

export interface MetadataSeriesDescriptionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataSeriesDescriptionNestedPhotoUpdateInput | null;
  metadata?: MetadataSeriesDescriptionNestedMetadataUpdateInput | null;
}

export interface MetadataSeriesDescriptionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataSeriesDescriptionNestedPhotoCreateInput;
  archive: MetadataSeriesDescriptionNestedArchiveCreateInput;
}

export interface MetadataSeriesDescriptionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataSeriesDescriptionNestedPhotoUpdateInput | null;
  archive?: MetadataSeriesDescriptionNestedArchiveUpdateInput | null;
}

export interface MetadataSeriesDescriptionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataSeriesDescriptionNestedMetadataCreateInput;
  archive: MetadataSeriesDescriptionNestedArchiveCreateInput;
}

export interface MetadataSeriesDescriptionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataSeriesDescriptionNestedMetadataUpdateInput | null;
  archive?: MetadataSeriesDescriptionNestedArchiveUpdateInput | null;
}

export interface MetadataSeriesModalityNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSeriesModalityNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSeriesModalityNestedUpsertArchiveCreateInput | null;
}

export interface MetadataSeriesModalityNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSeriesModalityNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSeriesModalityNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataSeriesModalityNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSeriesModalityNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSeriesModalityNestedUpsertMetadataCreateInput | null;
}

export interface MetadataSeriesModalityNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSeriesModalityNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSeriesModalityNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataSeriesModalityNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSeriesModalityNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSeriesModalityNestedUpsertPhotoCreateInput | null;
}

export interface MetadataSeriesModalityNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSeriesModalityNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSeriesModalityNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataSeriesModalityNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSeriesModalityNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSeriesModalityNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSeriesModalityNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSeriesModalityNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSeriesModalityNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSeriesModalityNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSeriesModalityNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSeriesModalityNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSeriesModalityNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSeriesModalityNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataSeriesModalityNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataSeriesModality" nodes by specifying some conditions
 */
export interface MetadataSeriesModalityWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSeriesModalityWhereInput[] | null;
  OR?: MetadataSeriesModalityWhereInput[] | null;
  NOT?: MetadataSeriesModalityWhereInput | null;
}

/**
 * Identifies exactly one "MetadataSeriesModality" node.
 */
export interface MetadataSeriesModalityWhereUniqueInput {
  id: number;
}

export interface MetadataSeriesModalityWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataSeriesModalityNestedPhotoCreateInput;
  metadata: MetadataSeriesModalityNestedMetadataCreateInput;
}

export interface MetadataSeriesModalityWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataSeriesModalityNestedPhotoUpdateInput | null;
  metadata?: MetadataSeriesModalityNestedMetadataUpdateInput | null;
}

export interface MetadataSeriesModalityWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataSeriesModalityNestedPhotoCreateInput;
  archive: MetadataSeriesModalityNestedArchiveCreateInput;
}

export interface MetadataSeriesModalityWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataSeriesModalityNestedPhotoUpdateInput | null;
  archive?: MetadataSeriesModalityNestedArchiveUpdateInput | null;
}

export interface MetadataSeriesModalityWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataSeriesModalityNestedMetadataCreateInput;
  archive: MetadataSeriesModalityNestedArchiveCreateInput;
}

export interface MetadataSeriesModalityWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataSeriesModalityNestedMetadataUpdateInput | null;
  archive?: MetadataSeriesModalityNestedArchiveUpdateInput | null;
}

export interface MetadataSeriesNumberNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSeriesNumberNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSeriesNumberNestedUpsertArchiveCreateInput | null;
}

export interface MetadataSeriesNumberNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSeriesNumberNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSeriesNumberNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataSeriesNumberNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSeriesNumberNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSeriesNumberNestedUpsertMetadataCreateInput | null;
}

export interface MetadataSeriesNumberNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSeriesNumberNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSeriesNumberNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataSeriesNumberNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSeriesNumberNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSeriesNumberNestedUpsertPhotoCreateInput | null;
}

export interface MetadataSeriesNumberNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSeriesNumberNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSeriesNumberNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataSeriesNumberNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSeriesNumberNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSeriesNumberNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSeriesNumberNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSeriesNumberNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSeriesNumberNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSeriesNumberNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSeriesNumberNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSeriesNumberNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSeriesNumberNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSeriesNumberNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataSeriesNumberNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataSeriesNumber" nodes by specifying some conditions
 */
export interface MetadataSeriesNumberWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSeriesNumberWhereInput[] | null;
  OR?: MetadataSeriesNumberWhereInput[] | null;
  NOT?: MetadataSeriesNumberWhereInput | null;
}

/**
 * Identifies exactly one "MetadataSeriesNumber" node.
 */
export interface MetadataSeriesNumberWhereUniqueInput {
  id: number;
}

export interface MetadataSeriesNumberWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataSeriesNumberNestedPhotoCreateInput;
  metadata: MetadataSeriesNumberNestedMetadataCreateInput;
}

export interface MetadataSeriesNumberWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataSeriesNumberNestedPhotoUpdateInput | null;
  metadata?: MetadataSeriesNumberNestedMetadataUpdateInput | null;
}

export interface MetadataSeriesNumberWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataSeriesNumberNestedPhotoCreateInput;
  archive: MetadataSeriesNumberNestedArchiveCreateInput;
}

export interface MetadataSeriesNumberWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataSeriesNumberNestedPhotoUpdateInput | null;
  archive?: MetadataSeriesNumberNestedArchiveUpdateInput | null;
}

export interface MetadataSeriesNumberWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataSeriesNumberNestedMetadataCreateInput;
  archive: MetadataSeriesNumberNestedArchiveCreateInput;
}

export interface MetadataSeriesNumberWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataSeriesNumberNestedMetadataUpdateInput | null;
  archive?: MetadataSeriesNumberNestedArchiveUpdateInput | null;
}

export interface MetadataShortUniqueIdNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataShortUniqueIdNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataShortUniqueIdNestedUpsertArchiveCreateInput | null;
}

export interface MetadataShortUniqueIdNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataShortUniqueIdNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataShortUniqueIdNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataShortUniqueIdNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataShortUniqueIdNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataShortUniqueIdNestedUpsertMetadataCreateInput | null;
}

export interface MetadataShortUniqueIdNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataShortUniqueIdNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataShortUniqueIdNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataShortUniqueIdNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataShortUniqueIdNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataShortUniqueIdNestedUpsertPhotoCreateInput | null;
}

export interface MetadataShortUniqueIdNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataShortUniqueIdNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataShortUniqueIdNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataShortUniqueIdNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataShortUniqueIdNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataShortUniqueIdNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataShortUniqueIdNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataShortUniqueIdNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataShortUniqueIdNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataShortUniqueIdNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataShortUniqueIdNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataShortUniqueIdNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataShortUniqueIdNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataShortUniqueIdNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataShortUniqueIdNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataShortUniqueId" nodes by specifying some conditions
 */
export interface MetadataShortUniqueIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataShortUniqueIdWhereInput[] | null;
  OR?: MetadataShortUniqueIdWhereInput[] | null;
  NOT?: MetadataShortUniqueIdWhereInput | null;
}

/**
 * Identifies exactly one "MetadataShortUniqueId" node.
 */
export interface MetadataShortUniqueIdWhereUniqueInput {
  id: number;
}

export interface MetadataShortUniqueIdWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataShortUniqueIdNestedPhotoCreateInput;
  metadata: MetadataShortUniqueIdNestedMetadataCreateInput;
}

export interface MetadataShortUniqueIdWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataShortUniqueIdNestedPhotoUpdateInput | null;
  metadata?: MetadataShortUniqueIdNestedMetadataUpdateInput | null;
}

export interface MetadataShortUniqueIdWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataShortUniqueIdNestedPhotoCreateInput;
  archive: MetadataShortUniqueIdNestedArchiveCreateInput;
}

export interface MetadataShortUniqueIdWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataShortUniqueIdNestedPhotoUpdateInput | null;
  archive?: MetadataShortUniqueIdNestedArchiveUpdateInput | null;
}

export interface MetadataShortUniqueIdWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataShortUniqueIdNestedMetadataCreateInput;
  archive: MetadataShortUniqueIdNestedArchiveCreateInput;
}

export interface MetadataShortUniqueIdWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataShortUniqueIdNestedMetadataUpdateInput | null;
  archive?: MetadataShortUniqueIdNestedArchiveUpdateInput | null;
}

export interface MetadataSourceNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSourceNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSourceNestedUpsertArchiveCreateInput | null;
}

export interface MetadataSourceNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSourceNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSourceNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataSourceNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSourceNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSourceNestedUpsertMetadataCreateInput | null;
}

export interface MetadataSourceNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSourceNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSourceNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataSourceNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSourceNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSourceNestedUpsertPhotoCreateInput | null;
}

export interface MetadataSourceNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSourceNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSourceNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataSourceNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSourceNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSourceNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSourceNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSourceNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSourceNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSourceNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSourceNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSourceNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSourceNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSourceNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataSourceNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataSource" nodes by specifying some conditions
 */
export interface MetadataSourceWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSourceWhereInput[] | null;
  OR?: MetadataSourceWhereInput[] | null;
  NOT?: MetadataSourceWhereInput | null;
}

/**
 * Identifies exactly one "MetadataSource" node.
 */
export interface MetadataSourceWhereUniqueInput {
  id: number;
}

export interface MetadataSourceWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataSourceNestedPhotoCreateInput;
  metadata: MetadataSourceNestedMetadataCreateInput;
}

export interface MetadataSourceWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataSourceNestedPhotoUpdateInput | null;
  metadata?: MetadataSourceNestedMetadataUpdateInput | null;
}

export interface MetadataSourceWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataSourceNestedPhotoCreateInput;
  archive: MetadataSourceNestedArchiveCreateInput;
}

export interface MetadataSourceWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataSourceNestedPhotoUpdateInput | null;
  archive?: MetadataSourceNestedArchiveUpdateInput | null;
}

export interface MetadataSourceWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataSourceNestedMetadataCreateInput;
  archive: MetadataSourceNestedArchiveCreateInput;
}

export interface MetadataSourceWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataSourceNestedMetadataUpdateInput | null;
  archive?: MetadataSourceNestedArchiveUpdateInput | null;
}

export interface MetadataStateNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataStateNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataStateNestedUpsertArchiveCreateInput | null;
}

export interface MetadataStateNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataStateNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataStateNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataStateNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataStateNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataStateNestedUpsertMetadataCreateInput | null;
}

export interface MetadataStateNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataStateNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataStateNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataStateNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataStateNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataStateNestedUpsertPhotoCreateInput | null;
}

export interface MetadataStateNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataStateNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataStateNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataStateNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataStateNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataStateNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataStateNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataStateNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataStateNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataStateNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataStateNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataStateNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataStateNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataStateNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataStateNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataState" nodes by specifying some conditions
 */
export interface MetadataStateWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataStateWhereInput[] | null;
  OR?: MetadataStateWhereInput[] | null;
  NOT?: MetadataStateWhereInput | null;
}

/**
 * Identifies exactly one "MetadataState" node.
 */
export interface MetadataStateWhereUniqueInput {
  id: number;
}

export interface MetadataStateWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataStateNestedPhotoCreateInput;
  metadata: MetadataStateNestedMetadataCreateInput;
}

export interface MetadataStateWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataStateNestedPhotoUpdateInput | null;
  metadata?: MetadataStateNestedMetadataUpdateInput | null;
}

export interface MetadataStateWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataStateNestedPhotoCreateInput;
  archive: MetadataStateNestedArchiveCreateInput;
}

export interface MetadataStateWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataStateNestedPhotoUpdateInput | null;
  archive?: MetadataStateNestedArchiveUpdateInput | null;
}

export interface MetadataStateWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataStateNestedMetadataCreateInput;
  archive: MetadataStateNestedArchiveCreateInput;
}

export interface MetadataStateWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataStateNestedMetadataUpdateInput | null;
  archive?: MetadataStateNestedArchiveUpdateInput | null;
}

export interface MetadataStudyDateTimeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataStudyDateTimeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataStudyDateTimeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataStudyDateTimeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataStudyDateTimeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataStudyDateTimeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataStudyDateTimeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataStudyDateTimeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataStudyDateTimeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataStudyDateTimeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataStudyDateTimeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataStudyDateTimeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataStudyDateTimeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataStudyDateTimeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataStudyDateTimeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataStudyDateTimeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataStudyDateTimeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataStudyDateTimeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataStudyDateTimeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataStudyDateTimeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataStudyDateTimeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataStudyDateTimeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataStudyDateTimeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataStudyDateTimeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataStudyDateTimeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataStudyDateTimeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataStudyDateTimeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataStudyDateTimeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataStudyDateTimeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataStudyDateTimeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataStudyDateTime" nodes by specifying some conditions
 */
export interface MetadataStudyDateTimeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataStudyDateTimeWhereInput[] | null;
  OR?: MetadataStudyDateTimeWhereInput[] | null;
  NOT?: MetadataStudyDateTimeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataStudyDateTime" node.
 */
export interface MetadataStudyDateTimeWhereUniqueInput {
  id: number;
}

export interface MetadataStudyDateTimeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataStudyDateTimeNestedPhotoCreateInput;
  metadata: MetadataStudyDateTimeNestedMetadataCreateInput;
}

export interface MetadataStudyDateTimeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataStudyDateTimeNestedPhotoUpdateInput | null;
  metadata?: MetadataStudyDateTimeNestedMetadataUpdateInput | null;
}

export interface MetadataStudyDateTimeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataStudyDateTimeNestedPhotoCreateInput;
  archive: MetadataStudyDateTimeNestedArchiveCreateInput;
}

export interface MetadataStudyDateTimeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataStudyDateTimeNestedPhotoUpdateInput | null;
  archive?: MetadataStudyDateTimeNestedArchiveUpdateInput | null;
}

export interface MetadataStudyDateTimeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataStudyDateTimeNestedMetadataCreateInput;
  archive: MetadataStudyDateTimeNestedArchiveCreateInput;
}

export interface MetadataStudyDateTimeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataStudyDateTimeNestedMetadataUpdateInput | null;
  archive?: MetadataStudyDateTimeNestedArchiveUpdateInput | null;
}

export interface MetadataStudyDescriptionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataStudyDescriptionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataStudyDescriptionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataStudyDescriptionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataStudyDescriptionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataStudyDescriptionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataStudyDescriptionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataStudyDescriptionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataStudyDescriptionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataStudyDescriptionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataStudyDescriptionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataStudyDescriptionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataStudyDescriptionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataStudyDescriptionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataStudyDescriptionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataStudyDescriptionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataStudyDescriptionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataStudyDescriptionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataStudyDescriptionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataStudyDescriptionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataStudyDescriptionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataStudyDescriptionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataStudyDescriptionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataStudyDescriptionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataStudyDescriptionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataStudyDescriptionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataStudyDescriptionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataStudyDescriptionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataStudyDescriptionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataStudyDescriptionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataStudyDescription" nodes by specifying some conditions
 */
export interface MetadataStudyDescriptionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataStudyDescriptionWhereInput[] | null;
  OR?: MetadataStudyDescriptionWhereInput[] | null;
  NOT?: MetadataStudyDescriptionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataStudyDescription" node.
 */
export interface MetadataStudyDescriptionWhereUniqueInput {
  id: number;
}

export interface MetadataStudyDescriptionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataStudyDescriptionNestedPhotoCreateInput;
  metadata: MetadataStudyDescriptionNestedMetadataCreateInput;
}

export interface MetadataStudyDescriptionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataStudyDescriptionNestedPhotoUpdateInput | null;
  metadata?: MetadataStudyDescriptionNestedMetadataUpdateInput | null;
}

export interface MetadataStudyDescriptionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataStudyDescriptionNestedPhotoCreateInput;
  archive: MetadataStudyDescriptionNestedArchiveCreateInput;
}

export interface MetadataStudyDescriptionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataStudyDescriptionNestedPhotoUpdateInput | null;
  archive?: MetadataStudyDescriptionNestedArchiveUpdateInput | null;
}

export interface MetadataStudyDescriptionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataStudyDescriptionNestedMetadataCreateInput;
  archive: MetadataStudyDescriptionNestedArchiveCreateInput;
}

export interface MetadataStudyDescriptionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataStudyDescriptionNestedMetadataUpdateInput | null;
  archive?: MetadataStudyDescriptionNestedArchiveUpdateInput | null;
}

export interface MetadataStudyIdNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataStudyIdNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataStudyIdNestedUpsertArchiveCreateInput | null;
}

export interface MetadataStudyIdNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataStudyIdNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataStudyIdNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataStudyIdNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataStudyIdNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataStudyIdNestedUpsertMetadataCreateInput | null;
}

export interface MetadataStudyIdNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataStudyIdNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataStudyIdNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataStudyIdNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataStudyIdNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataStudyIdNestedUpsertPhotoCreateInput | null;
}

export interface MetadataStudyIdNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataStudyIdNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataStudyIdNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataStudyIdNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataStudyIdNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataStudyIdNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataStudyIdNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataStudyIdNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataStudyIdNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataStudyIdNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataStudyIdNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataStudyIdNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataStudyIdNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataStudyIdNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataStudyIdNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataStudyId" nodes by specifying some conditions
 */
export interface MetadataStudyIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataStudyIdWhereInput[] | null;
  OR?: MetadataStudyIdWhereInput[] | null;
  NOT?: MetadataStudyIdWhereInput | null;
}

/**
 * Identifies exactly one "MetadataStudyId" node.
 */
export interface MetadataStudyIdWhereUniqueInput {
  id: number;
}

export interface MetadataStudyIdWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataStudyIdNestedPhotoCreateInput;
  metadata: MetadataStudyIdNestedMetadataCreateInput;
}

export interface MetadataStudyIdWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataStudyIdNestedPhotoUpdateInput | null;
  metadata?: MetadataStudyIdNestedMetadataUpdateInput | null;
}

export interface MetadataStudyIdWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataStudyIdNestedPhotoCreateInput;
  archive: MetadataStudyIdNestedArchiveCreateInput;
}

export interface MetadataStudyIdWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataStudyIdNestedPhotoUpdateInput | null;
  archive?: MetadataStudyIdNestedArchiveUpdateInput | null;
}

export interface MetadataStudyIdWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataStudyIdNestedMetadataCreateInput;
  archive: MetadataStudyIdNestedArchiveCreateInput;
}

export interface MetadataStudyIdWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataStudyIdNestedMetadataUpdateInput | null;
  archive?: MetadataStudyIdNestedArchiveUpdateInput | null;
}

export interface MetadataStudyPhysicianNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataStudyPhysicianNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataStudyPhysicianNestedUpsertArchiveCreateInput | null;
}

export interface MetadataStudyPhysicianNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataStudyPhysicianNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataStudyPhysicianNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataStudyPhysicianNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataStudyPhysicianNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataStudyPhysicianNestedUpsertMetadataCreateInput | null;
}

export interface MetadataStudyPhysicianNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataStudyPhysicianNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataStudyPhysicianNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataStudyPhysicianNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataStudyPhysicianNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataStudyPhysicianNestedUpsertPhotoCreateInput | null;
}

export interface MetadataStudyPhysicianNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataStudyPhysicianNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataStudyPhysicianNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataStudyPhysicianNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataStudyPhysicianNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataStudyPhysicianNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataStudyPhysicianNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataStudyPhysicianNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataStudyPhysicianNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataStudyPhysicianNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataStudyPhysicianNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataStudyPhysicianNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataStudyPhysicianNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataStudyPhysicianNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataStudyPhysicianNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataStudyPhysician" nodes by specifying some conditions
 */
export interface MetadataStudyPhysicianWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataStudyPhysicianWhereInput[] | null;
  OR?: MetadataStudyPhysicianWhereInput[] | null;
  NOT?: MetadataStudyPhysicianWhereInput | null;
}

/**
 * Identifies exactly one "MetadataStudyPhysician" node.
 */
export interface MetadataStudyPhysicianWhereUniqueInput {
  id: number;
}

export interface MetadataStudyPhysicianWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataStudyPhysicianNestedPhotoCreateInput;
  metadata: MetadataStudyPhysicianNestedMetadataCreateInput;
}

export interface MetadataStudyPhysicianWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataStudyPhysicianNestedPhotoUpdateInput | null;
  metadata?: MetadataStudyPhysicianNestedMetadataUpdateInput | null;
}

export interface MetadataStudyPhysicianWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataStudyPhysicianNestedPhotoCreateInput;
  archive: MetadataStudyPhysicianNestedArchiveCreateInput;
}

export interface MetadataStudyPhysicianWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataStudyPhysicianNestedPhotoUpdateInput | null;
  archive?: MetadataStudyPhysicianNestedArchiveUpdateInput | null;
}

export interface MetadataStudyPhysicianWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataStudyPhysicianNestedMetadataCreateInput;
  archive: MetadataStudyPhysicianNestedArchiveCreateInput;
}

export interface MetadataStudyPhysicianWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataStudyPhysicianNestedMetadataUpdateInput | null;
  archive?: MetadataStudyPhysicianNestedArchiveUpdateInput | null;
}

export interface MetadataSubjectCodeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSubjectCodeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSubjectCodeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataSubjectCodeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSubjectCodeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSubjectCodeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataSubjectCodeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSubjectCodeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSubjectCodeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataSubjectCodeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSubjectCodeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSubjectCodeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataSubjectCodeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSubjectCodeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSubjectCodeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataSubjectCodeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSubjectCodeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSubjectCodeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataSubjectCodeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSubjectCodeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSubjectCodeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSubjectCodeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSubjectCodeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSubjectCodeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSubjectCodeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSubjectCodeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSubjectCodeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSubjectCodeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSubjectCodeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataSubjectCodeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataSubjectCode" nodes by specifying some conditions
 */
export interface MetadataSubjectCodeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSubjectCodeWhereInput[] | null;
  OR?: MetadataSubjectCodeWhereInput[] | null;
  NOT?: MetadataSubjectCodeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataSubjectCode" node.
 */
export interface MetadataSubjectCodeWhereUniqueInput {
  id: number;
}

export interface MetadataSubjectCodeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataSubjectCodeNestedPhotoCreateInput;
  metadata: MetadataSubjectCodeNestedMetadataCreateInput;
}

export interface MetadataSubjectCodeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataSubjectCodeNestedPhotoUpdateInput | null;
  metadata?: MetadataSubjectCodeNestedMetadataUpdateInput | null;
}

export interface MetadataSubjectCodeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataSubjectCodeNestedPhotoCreateInput;
  archive: MetadataSubjectCodeNestedArchiveCreateInput;
}

export interface MetadataSubjectCodeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataSubjectCodeNestedPhotoUpdateInput | null;
  archive?: MetadataSubjectCodeNestedArchiveUpdateInput | null;
}

export interface MetadataSubjectCodeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataSubjectCodeNestedMetadataCreateInput;
  archive: MetadataSubjectCodeNestedArchiveCreateInput;
}

export interface MetadataSubjectCodeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataSubjectCodeNestedMetadataUpdateInput | null;
  archive?: MetadataSubjectCodeNestedArchiveUpdateInput | null;
}

export interface MetadataSubjectNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSubjectNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSubjectNestedUpsertArchiveCreateInput | null;
}

export interface MetadataSubjectNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSubjectNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSubjectNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataSubjectNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSubjectNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSubjectNestedUpsertMetadataCreateInput | null;
}

export interface MetadataSubjectNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSubjectNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSubjectNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataSubjectNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSubjectNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSubjectNestedUpsertPhotoCreateInput | null;
}

export interface MetadataSubjectNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSubjectNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSubjectNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataSubjectNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSubjectNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSubjectNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSubjectNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSubjectNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSubjectNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSubjectNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSubjectNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSubjectNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSubjectNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSubjectNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataSubjectNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataSubjectPrismaNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSubjectPrismaNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSubjectPrismaNestedUpsertArchiveCreateInput | null;
}

export interface MetadataSubjectPrismaNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSubjectPrismaNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSubjectPrismaNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataSubjectPrismaNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSubjectPrismaNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSubjectPrismaNestedUpsertMetadataCreateInput | null;
}

export interface MetadataSubjectPrismaNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSubjectPrismaNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSubjectPrismaNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataSubjectPrismaNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSubjectPrismaNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSubjectPrismaNestedUpsertPhotoCreateInput | null;
}

export interface MetadataSubjectPrismaNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSubjectPrismaNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSubjectPrismaNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataSubjectPrismaNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSubjectPrismaNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSubjectPrismaNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSubjectPrismaNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSubjectPrismaNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSubjectPrismaNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSubjectPrismaNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSubjectPrismaNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSubjectPrismaNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSubjectPrismaNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSubjectPrismaNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataSubjectPrismaNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataSubjectPrisma" nodes by specifying some conditions
 */
export interface MetadataSubjectPrismaWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSubjectPrismaWhereInput[] | null;
  OR?: MetadataSubjectPrismaWhereInput[] | null;
  NOT?: MetadataSubjectPrismaWhereInput | null;
}

/**
 * Identifies exactly one "MetadataSubjectPrisma" node.
 */
export interface MetadataSubjectPrismaWhereUniqueInput {
  id: number;
}

export interface MetadataSubjectPrismaWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataSubjectPrismaNestedPhotoCreateInput;
  metadata: MetadataSubjectPrismaNestedMetadataCreateInput;
}

export interface MetadataSubjectPrismaWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataSubjectPrismaNestedPhotoUpdateInput | null;
  metadata?: MetadataSubjectPrismaNestedMetadataUpdateInput | null;
}

export interface MetadataSubjectPrismaWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataSubjectPrismaNestedPhotoCreateInput;
  archive: MetadataSubjectPrismaNestedArchiveCreateInput;
}

export interface MetadataSubjectPrismaWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataSubjectPrismaNestedPhotoUpdateInput | null;
  archive?: MetadataSubjectPrismaNestedArchiveUpdateInput | null;
}

export interface MetadataSubjectPrismaWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataSubjectPrismaNestedMetadataCreateInput;
  archive: MetadataSubjectPrismaNestedArchiveCreateInput;
}

export interface MetadataSubjectPrismaWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataSubjectPrismaNestedMetadataUpdateInput | null;
  archive?: MetadataSubjectPrismaNestedArchiveUpdateInput | null;
}

/**
 * Filters the "MetadataSubject" nodes by specifying some conditions
 */
export interface MetadataSubjectWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSubjectWhereInput[] | null;
  OR?: MetadataSubjectWhereInput[] | null;
  NOT?: MetadataSubjectWhereInput | null;
}

/**
 * Identifies exactly one "MetadataSubject" node.
 */
export interface MetadataSubjectWhereUniqueInput {
  id: number;
}

export interface MetadataSubjectWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataSubjectNestedPhotoCreateInput;
  metadata: MetadataSubjectNestedMetadataCreateInput;
}

export interface MetadataSubjectWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataSubjectNestedPhotoUpdateInput | null;
  metadata?: MetadataSubjectNestedMetadataUpdateInput | null;
}

export interface MetadataSubjectWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataSubjectNestedPhotoCreateInput;
  archive: MetadataSubjectNestedArchiveCreateInput;
}

export interface MetadataSubjectWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataSubjectNestedPhotoUpdateInput | null;
  archive?: MetadataSubjectNestedArchiveUpdateInput | null;
}

export interface MetadataSubjectWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataSubjectNestedMetadataCreateInput;
  archive: MetadataSubjectNestedArchiveCreateInput;
}

export interface MetadataSubjectWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataSubjectNestedMetadataUpdateInput | null;
  archive?: MetadataSubjectNestedArchiveUpdateInput | null;
}

export interface MetadataSupplementalCategoriesNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSupplementalCategoriesNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSupplementalCategoriesNestedUpsertArchiveCreateInput | null;
}

export interface MetadataSupplementalCategoriesNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataSupplementalCategoriesNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataSupplementalCategoriesNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataSupplementalCategoriesNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSupplementalCategoriesNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSupplementalCategoriesNestedUpsertMetadataCreateInput | null;
}

export interface MetadataSupplementalCategoriesNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataSupplementalCategoriesNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataSupplementalCategoriesNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataSupplementalCategoriesNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSupplementalCategoriesNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSupplementalCategoriesNestedUpsertPhotoCreateInput | null;
}

export interface MetadataSupplementalCategoriesNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataSupplementalCategoriesNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataSupplementalCategoriesNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataSupplementalCategoriesNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSupplementalCategoriesNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataSupplementalCategoriesNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSupplementalCategoriesNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataSupplementalCategoriesNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSupplementalCategoriesNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataSupplementalCategoriesNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSupplementalCategoriesNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataSupplementalCategoriesNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSupplementalCategoriesNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataSupplementalCategoriesNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataSupplementalCategoriesNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataSupplementalCategories" nodes by specifying some conditions
 */
export interface MetadataSupplementalCategoriesWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataSupplementalCategoriesWhereInput[] | null;
  OR?: MetadataSupplementalCategoriesWhereInput[] | null;
  NOT?: MetadataSupplementalCategoriesWhereInput | null;
}

/**
 * Identifies exactly one "MetadataSupplementalCategories" node.
 */
export interface MetadataSupplementalCategoriesWhereUniqueInput {
  id: number;
}

export interface MetadataSupplementalCategoriesWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataSupplementalCategoriesNestedPhotoCreateInput;
  metadata: MetadataSupplementalCategoriesNestedMetadataCreateInput;
}

export interface MetadataSupplementalCategoriesWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataSupplementalCategoriesNestedPhotoUpdateInput | null;
  metadata?: MetadataSupplementalCategoriesNestedMetadataUpdateInput | null;
}

export interface MetadataSupplementalCategoriesWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataSupplementalCategoriesNestedPhotoCreateInput;
  archive: MetadataSupplementalCategoriesNestedArchiveCreateInput;
}

export interface MetadataSupplementalCategoriesWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataSupplementalCategoriesNestedPhotoUpdateInput | null;
  archive?: MetadataSupplementalCategoriesNestedArchiveUpdateInput | null;
}

export interface MetadataSupplementalCategoriesWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataSupplementalCategoriesNestedMetadataCreateInput;
  archive: MetadataSupplementalCategoriesNestedArchiveCreateInput;
}

export interface MetadataSupplementalCategoriesWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataSupplementalCategoriesNestedMetadataUpdateInput | null;
  archive?: MetadataSupplementalCategoriesNestedArchiveUpdateInput | null;
}

export interface MetadataTargetVersionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataTargetVersionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataTargetVersionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataTargetVersionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataTargetVersionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataTargetVersionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataTargetVersionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataTargetVersionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataTargetVersionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataTargetVersionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataTargetVersionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataTargetVersionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataTargetVersionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataTargetVersionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataTargetVersionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataTargetVersionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataTargetVersionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataTargetVersionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataTargetVersionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataTargetVersionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataTargetVersionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataTargetVersionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataTargetVersionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataTargetVersionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataTargetVersionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataTargetVersionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataTargetVersionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataTargetVersionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataTargetVersionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataTargetVersionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataTargetVersion" nodes by specifying some conditions
 */
export interface MetadataTargetVersionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataTargetVersionWhereInput[] | null;
  OR?: MetadataTargetVersionWhereInput[] | null;
  NOT?: MetadataTargetVersionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataTargetVersion" node.
 */
export interface MetadataTargetVersionWhereUniqueInput {
  id: number;
}

export interface MetadataTargetVersionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataTargetVersionNestedPhotoCreateInput;
  metadata: MetadataTargetVersionNestedMetadataCreateInput;
}

export interface MetadataTargetVersionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataTargetVersionNestedPhotoUpdateInput | null;
  metadata?: MetadataTargetVersionNestedMetadataUpdateInput | null;
}

export interface MetadataTargetVersionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataTargetVersionNestedPhotoCreateInput;
  archive: MetadataTargetVersionNestedArchiveCreateInput;
}

export interface MetadataTargetVersionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataTargetVersionNestedPhotoUpdateInput | null;
  archive?: MetadataTargetVersionNestedArchiveUpdateInput | null;
}

export interface MetadataTargetVersionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataTargetVersionNestedMetadataCreateInput;
  archive: MetadataTargetVersionNestedArchiveCreateInput;
}

export interface MetadataTargetVersionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataTargetVersionNestedMetadataUpdateInput | null;
  archive?: MetadataTargetVersionNestedArchiveUpdateInput | null;
}

export interface MetadataTitleNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataTitleNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataTitleNestedUpsertArchiveCreateInput | null;
}

export interface MetadataTitleNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataTitleNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataTitleNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataTitleNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataTitleNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataTitleNestedUpsertMetadataCreateInput | null;
}

export interface MetadataTitleNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataTitleNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataTitleNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataTitleNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataTitleNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataTitleNestedUpsertPhotoCreateInput | null;
}

export interface MetadataTitleNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataTitleNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataTitleNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataTitleNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataTitleNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataTitleNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataTitleNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataTitleNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataTitleNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataTitleNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataTitleNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataTitleNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataTitleNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataTitleNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataTitleNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataTitle" nodes by specifying some conditions
 */
export interface MetadataTitleWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataTitleWhereInput[] | null;
  OR?: MetadataTitleWhereInput[] | null;
  NOT?: MetadataTitleWhereInput | null;
}

/**
 * Identifies exactly one "MetadataTitle" node.
 */
export interface MetadataTitleWhereUniqueInput {
  id: number;
}

export interface MetadataTitleWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataTitleNestedPhotoCreateInput;
  metadata: MetadataTitleNestedMetadataCreateInput;
}

export interface MetadataTitleWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataTitleNestedPhotoUpdateInput | null;
  metadata?: MetadataTitleNestedMetadataUpdateInput | null;
}

export interface MetadataTitleWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataTitleNestedPhotoCreateInput;
  archive: MetadataTitleNestedArchiveCreateInput;
}

export interface MetadataTitleWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataTitleNestedPhotoUpdateInput | null;
  archive?: MetadataTitleNestedArchiveUpdateInput | null;
}

export interface MetadataTitleWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataTitleNestedMetadataCreateInput;
  archive: MetadataTitleNestedArchiveCreateInput;
}

export interface MetadataTitleWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataTitleNestedMetadataUpdateInput | null;
  archive?: MetadataTitleNestedArchiveUpdateInput | null;
}

export interface MetadataToneCurveNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataToneCurveNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataToneCurveNestedUpsertArchiveCreateInput | null;
}

export interface MetadataToneCurveNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataToneCurveNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataToneCurveNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataToneCurveNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataToneCurveNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataToneCurveNestedUpsertMetadataCreateInput | null;
}

export interface MetadataToneCurveNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataToneCurveNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataToneCurveNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataToneCurveNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataToneCurveNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataToneCurveNestedUpsertPhotoCreateInput | null;
}

export interface MetadataToneCurveNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataToneCurveNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataToneCurveNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataToneCurveNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataToneCurveNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataToneCurveNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataToneCurveNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataToneCurveNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataToneCurveNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataToneCurveNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataToneCurveNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataToneCurveNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataToneCurveNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataToneCurveNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataToneCurveNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataToneCurve" nodes by specifying some conditions
 */
export interface MetadataToneCurveWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataToneCurveWhereInput[] | null;
  OR?: MetadataToneCurveWhereInput[] | null;
  NOT?: MetadataToneCurveWhereInput | null;
}

/**
 * Identifies exactly one "MetadataToneCurve" node.
 */
export interface MetadataToneCurveWhereUniqueInput {
  id: number;
}

export interface MetadataToneCurveWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataToneCurveNestedPhotoCreateInput;
  metadata: MetadataToneCurveNestedMetadataCreateInput;
}

export interface MetadataToneCurveWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataToneCurveNestedPhotoUpdateInput | null;
  metadata?: MetadataToneCurveNestedMetadataUpdateInput | null;
}

export interface MetadataToneCurveWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataToneCurveNestedPhotoCreateInput;
  archive: MetadataToneCurveNestedArchiveCreateInput;
}

export interface MetadataToneCurveWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataToneCurveNestedPhotoUpdateInput | null;
  archive?: MetadataToneCurveNestedArchiveUpdateInput | null;
}

export interface MetadataToneCurveWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataToneCurveNestedMetadataCreateInput;
  archive: MetadataToneCurveNestedArchiveCreateInput;
}

export interface MetadataToneCurveWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataToneCurveNestedMetadataUpdateInput | null;
  archive?: MetadataToneCurveNestedArchiveUpdateInput | null;
}

export interface MetadataTransferredByEmailNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataTransferredByEmailNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataTransferredByEmailNestedUpsertArchiveCreateInput | null;
}

export interface MetadataTransferredByEmailNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataTransferredByEmailNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataTransferredByEmailNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataTransferredByEmailNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataTransferredByEmailNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataTransferredByEmailNestedUpsertMetadataCreateInput | null;
}

export interface MetadataTransferredByEmailNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataTransferredByEmailNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataTransferredByEmailNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataTransferredByEmailNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataTransferredByEmailNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataTransferredByEmailNestedUpsertPhotoCreateInput | null;
}

export interface MetadataTransferredByEmailNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataTransferredByEmailNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataTransferredByEmailNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataTransferredByEmailNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataTransferredByEmailNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataTransferredByEmailNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataTransferredByEmailNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataTransferredByEmailNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataTransferredByEmailNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataTransferredByEmailNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataTransferredByEmailNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataTransferredByEmailNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataTransferredByEmailNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataTransferredByEmailNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataTransferredByEmailNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataTransferredByEmail" nodes by specifying some conditions
 */
export interface MetadataTransferredByEmailWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataTransferredByEmailWhereInput[] | null;
  OR?: MetadataTransferredByEmailWhereInput[] | null;
  NOT?: MetadataTransferredByEmailWhereInput | null;
}

/**
 * Identifies exactly one "MetadataTransferredByEmail" node.
 */
export interface MetadataTransferredByEmailWhereUniqueInput {
  id: number;
}

export interface MetadataTransferredByEmailWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataTransferredByEmailNestedPhotoCreateInput;
  metadata: MetadataTransferredByEmailNestedMetadataCreateInput;
}

export interface MetadataTransferredByEmailWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataTransferredByEmailNestedPhotoUpdateInput | null;
  metadata?: MetadataTransferredByEmailNestedMetadataUpdateInput | null;
}

export interface MetadataTransferredByEmailWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataTransferredByEmailNestedPhotoCreateInput;
  archive: MetadataTransferredByEmailNestedArchiveCreateInput;
}

export interface MetadataTransferredByEmailWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataTransferredByEmailNestedPhotoUpdateInput | null;
  archive?: MetadataTransferredByEmailNestedArchiveUpdateInput | null;
}

export interface MetadataTransferredByEmailWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataTransferredByEmailNestedMetadataCreateInput;
  archive: MetadataTransferredByEmailNestedArchiveCreateInput;
}

export interface MetadataTransferredByEmailWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataTransferredByEmailNestedMetadataUpdateInput | null;
  archive?: MetadataTransferredByEmailNestedArchiveUpdateInput | null;
}

export interface MetadataTransferredByFullNameNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataTransferredByFullNameNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataTransferredByFullNameNestedUpsertArchiveCreateInput | null;
}

export interface MetadataTransferredByFullNameNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataTransferredByFullNameNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataTransferredByFullNameNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataTransferredByFullNameNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataTransferredByFullNameNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataTransferredByFullNameNestedUpsertMetadataCreateInput | null;
}

export interface MetadataTransferredByFullNameNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataTransferredByFullNameNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataTransferredByFullNameNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataTransferredByFullNameNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataTransferredByFullNameNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataTransferredByFullNameNestedUpsertPhotoCreateInput | null;
}

export interface MetadataTransferredByFullNameNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataTransferredByFullNameNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataTransferredByFullNameNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataTransferredByFullNameNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataTransferredByFullNameNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataTransferredByFullNameNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataTransferredByFullNameNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataTransferredByFullNameNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataTransferredByFullNameNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataTransferredByFullNameNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataTransferredByFullNameNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataTransferredByFullNameNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataTransferredByFullNameNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataTransferredByFullNameNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataTransferredByFullNameNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataTransferredByFullName" nodes by specifying some conditions
 */
export interface MetadataTransferredByFullNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataTransferredByFullNameWhereInput[] | null;
  OR?: MetadataTransferredByFullNameWhereInput[] | null;
  NOT?: MetadataTransferredByFullNameWhereInput | null;
}

/**
 * Identifies exactly one "MetadataTransferredByFullName" node.
 */
export interface MetadataTransferredByFullNameWhereUniqueInput {
  id: number;
}

export interface MetadataTransferredByFullNameWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataTransferredByFullNameNestedPhotoCreateInput;
  metadata: MetadataTransferredByFullNameNestedMetadataCreateInput;
}

export interface MetadataTransferredByFullNameWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataTransferredByFullNameNestedPhotoUpdateInput | null;
  metadata?: MetadataTransferredByFullNameNestedMetadataUpdateInput | null;
}

export interface MetadataTransferredByFullNameWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataTransferredByFullNameNestedPhotoCreateInput;
  archive: MetadataTransferredByFullNameNestedArchiveCreateInput;
}

export interface MetadataTransferredByFullNameWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataTransferredByFullNameNestedPhotoUpdateInput | null;
  archive?: MetadataTransferredByFullNameNestedArchiveUpdateInput | null;
}

export interface MetadataTransferredByFullNameWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataTransferredByFullNameNestedMetadataCreateInput;
  archive: MetadataTransferredByFullNameNestedArchiveCreateInput;
}

export interface MetadataTransferredByFullNameWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataTransferredByFullNameNestedMetadataUpdateInput | null;
  archive?: MetadataTransferredByFullNameNestedArchiveUpdateInput | null;
}

export interface MetadataTransferredByNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataTransferredByNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataTransferredByNestedUpsertArchiveCreateInput | null;
}

export interface MetadataTransferredByNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataTransferredByNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataTransferredByNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataTransferredByNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataTransferredByNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataTransferredByNestedUpsertMetadataCreateInput | null;
}

export interface MetadataTransferredByNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataTransferredByNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataTransferredByNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataTransferredByNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataTransferredByNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataTransferredByNestedUpsertPhotoCreateInput | null;
}

export interface MetadataTransferredByNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataTransferredByNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataTransferredByNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataTransferredByNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataTransferredByNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataTransferredByNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataTransferredByNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataTransferredByNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataTransferredByNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataTransferredByNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataTransferredByNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataTransferredByNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataTransferredByNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataTransferredByNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataTransferredByNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataTransferredBy" nodes by specifying some conditions
 */
export interface MetadataTransferredByWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataTransferredByWhereInput[] | null;
  OR?: MetadataTransferredByWhereInput[] | null;
  NOT?: MetadataTransferredByWhereInput | null;
}

/**
 * Identifies exactly one "MetadataTransferredBy" node.
 */
export interface MetadataTransferredByWhereUniqueInput {
  id: number;
}

export interface MetadataTransferredByWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataTransferredByNestedPhotoCreateInput;
  metadata: MetadataTransferredByNestedMetadataCreateInput;
}

export interface MetadataTransferredByWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataTransferredByNestedPhotoUpdateInput | null;
  metadata?: MetadataTransferredByNestedMetadataUpdateInput | null;
}

export interface MetadataTransferredByWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataTransferredByNestedPhotoCreateInput;
  archive: MetadataTransferredByNestedArchiveCreateInput;
}

export interface MetadataTransferredByWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataTransferredByNestedPhotoUpdateInput | null;
  archive?: MetadataTransferredByNestedArchiveUpdateInput | null;
}

export interface MetadataTransferredByWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataTransferredByNestedMetadataCreateInput;
  archive: MetadataTransferredByNestedArchiveCreateInput;
}

export interface MetadataTransferredByWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataTransferredByNestedMetadataUpdateInput | null;
  archive?: MetadataTransferredByNestedArchiveUpdateInput | null;
}

export interface MetadataTransmissionReferenceNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataTransmissionReferenceNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataTransmissionReferenceNestedUpsertArchiveCreateInput | null;
}

export interface MetadataTransmissionReferenceNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataTransmissionReferenceNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataTransmissionReferenceNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataTransmissionReferenceNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataTransmissionReferenceNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataTransmissionReferenceNestedUpsertMetadataCreateInput | null;
}

export interface MetadataTransmissionReferenceNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataTransmissionReferenceNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataTransmissionReferenceNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataTransmissionReferenceNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataTransmissionReferenceNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataTransmissionReferenceNestedUpsertPhotoCreateInput | null;
}

export interface MetadataTransmissionReferenceNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataTransmissionReferenceNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataTransmissionReferenceNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataTransmissionReferenceNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataTransmissionReferenceNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataTransmissionReferenceNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataTransmissionReferenceNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataTransmissionReferenceNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataTransmissionReferenceNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataTransmissionReferenceNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataTransmissionReferenceNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataTransmissionReferenceNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataTransmissionReferenceNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataTransmissionReferenceNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataTransmissionReferenceNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataTransmissionReference" nodes by specifying some conditions
 */
export interface MetadataTransmissionReferenceWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataTransmissionReferenceWhereInput[] | null;
  OR?: MetadataTransmissionReferenceWhereInput[] | null;
  NOT?: MetadataTransmissionReferenceWhereInput | null;
}

/**
 * Identifies exactly one "MetadataTransmissionReference" node.
 */
export interface MetadataTransmissionReferenceWhereUniqueInput {
  id: number;
}

export interface MetadataTransmissionReferenceWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataTransmissionReferenceNestedPhotoCreateInput;
  metadata: MetadataTransmissionReferenceNestedMetadataCreateInput;
}

export interface MetadataTransmissionReferenceWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataTransmissionReferenceNestedPhotoUpdateInput | null;
  metadata?: MetadataTransmissionReferenceNestedMetadataUpdateInput | null;
}

export interface MetadataTransmissionReferenceWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataTransmissionReferenceNestedPhotoCreateInput;
  archive: MetadataTransmissionReferenceNestedArchiveCreateInput;
}

export interface MetadataTransmissionReferenceWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataTransmissionReferenceNestedPhotoUpdateInput | null;
  archive?: MetadataTransmissionReferenceNestedArchiveUpdateInput | null;
}

export interface MetadataTransmissionReferenceWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataTransmissionReferenceNestedMetadataCreateInput;
  archive: MetadataTransmissionReferenceNestedArchiveCreateInput;
}

export interface MetadataTransmissionReferenceWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataTransmissionReferenceNestedMetadataUpdateInput | null;
  archive?: MetadataTransmissionReferenceNestedArchiveUpdateInput | null;
}

export interface MetadataTypeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataTypeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataTypeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataTypeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataTypeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataTypeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataTypeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataTypeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataTypeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataTypeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataTypeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataTypeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataTypeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataTypeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataTypeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataTypeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataTypeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataTypeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataTypeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataTypeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataTypeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataTypeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataTypeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataTypeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataTypeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataTypeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataTypeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataTypeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataTypeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataTypeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataType" nodes by specifying some conditions
 */
export interface MetadataTypeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataTypeWhereInput[] | null;
  OR?: MetadataTypeWhereInput[] | null;
  NOT?: MetadataTypeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataType" node.
 */
export interface MetadataTypeWhereUniqueInput {
  id: number;
}

export interface MetadataTypeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataTypeNestedPhotoCreateInput;
  metadata: MetadataTypeNestedMetadataCreateInput;
}

export interface MetadataTypeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataTypeNestedPhotoUpdateInput | null;
  metadata?: MetadataTypeNestedMetadataUpdateInput | null;
}

export interface MetadataTypeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataTypeNestedPhotoCreateInput;
  archive: MetadataTypeNestedArchiveCreateInput;
}

export interface MetadataTypeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataTypeNestedPhotoUpdateInput | null;
  archive?: MetadataTypeNestedArchiveUpdateInput | null;
}

export interface MetadataTypeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataTypeNestedMetadataCreateInput;
  archive: MetadataTypeNestedArchiveCreateInput;
}

export interface MetadataTypeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataTypeNestedMetadataUpdateInput | null;
  archive?: MetadataTypeNestedArchiveUpdateInput | null;
}

export interface MetadataUnderUnderSubjectNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUnderUnderSubjectNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUnderUnderSubjectNestedUpsertArchiveCreateInput | null;
}

export interface MetadataUnderUnderSubjectNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUnderUnderSubjectNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUnderUnderSubjectNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUnderUnderSubjectNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUnderUnderSubjectNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUnderUnderSubjectNestedUpsertMetadataCreateInput | null;
}

export interface MetadataUnderUnderSubjectNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUnderUnderSubjectNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUnderUnderSubjectNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUnderUnderSubjectNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUnderUnderSubjectNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUnderUnderSubjectNestedUpsertPhotoCreateInput | null;
}

export interface MetadataUnderUnderSubjectNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUnderUnderSubjectNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUnderUnderSubjectNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUnderUnderSubjectNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUnderUnderSubjectNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUnderUnderSubjectNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUnderUnderSubjectNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUnderUnderSubjectNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUnderUnderSubjectNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUnderUnderSubjectNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUnderUnderSubjectNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUnderUnderSubjectNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUnderUnderSubjectNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUnderUnderSubjectNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUnderUnderSubjectNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUnderUnderSubject" nodes by specifying some conditions
 */
export interface MetadataUnderUnderSubjectWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUnderUnderSubjectWhereInput[] | null;
  OR?: MetadataUnderUnderSubjectWhereInput[] | null;
  NOT?: MetadataUnderUnderSubjectWhereInput | null;
}

/**
 * Identifies exactly one "MetadataUnderUnderSubject" node.
 */
export interface MetadataUnderUnderSubjectWhereUniqueInput {
  id: number;
}

export interface MetadataUnderUnderSubjectWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUnderUnderSubjectNestedPhotoCreateInput;
  metadata: MetadataUnderUnderSubjectNestedMetadataCreateInput;
}

export interface MetadataUnderUnderSubjectWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUnderUnderSubjectNestedPhotoUpdateInput | null;
  metadata?: MetadataUnderUnderSubjectNestedMetadataUpdateInput | null;
}

export interface MetadataUnderUnderSubjectWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUnderUnderSubjectNestedPhotoCreateInput;
  archive: MetadataUnderUnderSubjectNestedArchiveCreateInput;
}

export interface MetadataUnderUnderSubjectWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUnderUnderSubjectNestedPhotoUpdateInput | null;
  archive?: MetadataUnderUnderSubjectNestedArchiveUpdateInput | null;
}

export interface MetadataUnderUnderSubjectWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUnderUnderSubjectNestedMetadataCreateInput;
  archive: MetadataUnderUnderSubjectNestedArchiveCreateInput;
}

export interface MetadataUnderUnderSubjectWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUnderUnderSubjectNestedMetadataUpdateInput | null;
  archive?: MetadataUnderUnderSubjectNestedArchiveUpdateInput | null;
}

export interface MetadataUndersubjectNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUndersubjectNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUndersubjectNestedUpsertArchiveCreateInput | null;
}

export interface MetadataUndersubjectNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUndersubjectNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUndersubjectNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUndersubjectNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUndersubjectNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUndersubjectNestedUpsertMetadataCreateInput | null;
}

export interface MetadataUndersubjectNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUndersubjectNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUndersubjectNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUndersubjectNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUndersubjectNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUndersubjectNestedUpsertPhotoCreateInput | null;
}

export interface MetadataUndersubjectNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUndersubjectNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUndersubjectNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUndersubjectNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUndersubjectNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUndersubjectNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUndersubjectNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUndersubjectNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUndersubjectNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUndersubjectNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUndersubjectNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUndersubjectNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUndersubjectNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUndersubjectNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUndersubjectNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUndersubject" nodes by specifying some conditions
 */
export interface MetadataUndersubjectWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUndersubjectWhereInput[] | null;
  OR?: MetadataUndersubjectWhereInput[] | null;
  NOT?: MetadataUndersubjectWhereInput | null;
}

/**
 * Identifies exactly one "MetadataUndersubject" node.
 */
export interface MetadataUndersubjectWhereUniqueInput {
  id: number;
}

export interface MetadataUndersubjectWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUndersubjectNestedPhotoCreateInput;
  metadata: MetadataUndersubjectNestedMetadataCreateInput;
}

export interface MetadataUndersubjectWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUndersubjectNestedPhotoUpdateInput | null;
  metadata?: MetadataUndersubjectNestedMetadataUpdateInput | null;
}

export interface MetadataUndersubjectWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUndersubjectNestedPhotoCreateInput;
  archive: MetadataUndersubjectNestedArchiveCreateInput;
}

export interface MetadataUndersubjectWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUndersubjectNestedPhotoUpdateInput | null;
  archive?: MetadataUndersubjectNestedArchiveUpdateInput | null;
}

export interface MetadataUndersubjectWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUndersubjectNestedMetadataCreateInput;
  archive: MetadataUndersubjectNestedArchiveCreateInput;
}

export interface MetadataUndersubjectWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUndersubjectNestedMetadataUpdateInput | null;
  archive?: MetadataUndersubjectNestedArchiveUpdateInput | null;
}

export interface MetadataUniqueIdNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUniqueIdNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUniqueIdNestedUpsertArchiveCreateInput | null;
}

export interface MetadataUniqueIdNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUniqueIdNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUniqueIdNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUniqueIdNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUniqueIdNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUniqueIdNestedUpsertMetadataCreateInput | null;
}

export interface MetadataUniqueIdNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUniqueIdNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUniqueIdNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUniqueIdNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUniqueIdNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUniqueIdNestedUpsertPhotoCreateInput | null;
}

export interface MetadataUniqueIdNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUniqueIdNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUniqueIdNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUniqueIdNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUniqueIdNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUniqueIdNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUniqueIdNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUniqueIdNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUniqueIdNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUniqueIdNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUniqueIdNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUniqueIdNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUniqueIdNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUniqueIdNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUniqueIdNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUniqueId" nodes by specifying some conditions
 */
export interface MetadataUniqueIdWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUniqueIdWhereInput[] | null;
  OR?: MetadataUniqueIdWhereInput[] | null;
  NOT?: MetadataUniqueIdWhereInput | null;
}

/**
 * Identifies exactly one "MetadataUniqueId" node.
 */
export interface MetadataUniqueIdWhereUniqueInput {
  id: number;
}

export interface MetadataUniqueIdWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUniqueIdNestedPhotoCreateInput;
  metadata: MetadataUniqueIdNestedMetadataCreateInput;
}

export interface MetadataUniqueIdWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUniqueIdNestedPhotoUpdateInput | null;
  metadata?: MetadataUniqueIdNestedMetadataUpdateInput | null;
}

export interface MetadataUniqueIdWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUniqueIdNestedPhotoCreateInput;
  archive: MetadataUniqueIdNestedArchiveCreateInput;
}

export interface MetadataUniqueIdWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUniqueIdNestedPhotoUpdateInput | null;
  archive?: MetadataUniqueIdNestedArchiveUpdateInput | null;
}

export interface MetadataUniqueIdWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUniqueIdNestedMetadataCreateInput;
  archive: MetadataUniqueIdNestedArchiveCreateInput;
}

export interface MetadataUniqueIdWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUniqueIdNestedMetadataUpdateInput | null;
  archive?: MetadataUniqueIdNestedArchiveUpdateInput | null;
}

export interface MetadataUnitNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUnitNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUnitNestedUpsertArchiveCreateInput | null;
}

export interface MetadataUnitNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUnitNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUnitNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUnitNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUnitNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUnitNestedUpsertMetadataCreateInput | null;
}

export interface MetadataUnitNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUnitNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUnitNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUnitNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUnitNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUnitNestedUpsertPhotoCreateInput | null;
}

export interface MetadataUnitNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUnitNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUnitNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUnitNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUnitNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUnitNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUnitNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUnitNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUnitNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUnitNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUnitNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUnitNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUnitNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUnitNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUnitNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUnitShortNameNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUnitShortNameNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUnitShortNameNestedUpsertArchiveCreateInput | null;
}

export interface MetadataUnitShortNameNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUnitShortNameNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUnitShortNameNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUnitShortNameNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUnitShortNameNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUnitShortNameNestedUpsertMetadataCreateInput | null;
}

export interface MetadataUnitShortNameNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUnitShortNameNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUnitShortNameNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUnitShortNameNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUnitShortNameNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUnitShortNameNestedUpsertPhotoCreateInput | null;
}

export interface MetadataUnitShortNameNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUnitShortNameNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUnitShortNameNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUnitShortNameNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUnitShortNameNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUnitShortNameNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUnitShortNameNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUnitShortNameNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUnitShortNameNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUnitShortNameNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUnitShortNameNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUnitShortNameNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUnitShortNameNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUnitShortNameNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUnitShortNameNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUnitShortName" nodes by specifying some conditions
 */
export interface MetadataUnitShortNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUnitShortNameWhereInput[] | null;
  OR?: MetadataUnitShortNameWhereInput[] | null;
  NOT?: MetadataUnitShortNameWhereInput | null;
}

/**
 * Identifies exactly one "MetadataUnitShortName" node.
 */
export interface MetadataUnitShortNameWhereUniqueInput {
  id: number;
}

export interface MetadataUnitShortNameWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUnitShortNameNestedPhotoCreateInput;
  metadata: MetadataUnitShortNameNestedMetadataCreateInput;
}

export interface MetadataUnitShortNameWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUnitShortNameNestedPhotoUpdateInput | null;
  metadata?: MetadataUnitShortNameNestedMetadataUpdateInput | null;
}

export interface MetadataUnitShortNameWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUnitShortNameNestedPhotoCreateInput;
  archive: MetadataUnitShortNameNestedArchiveCreateInput;
}

export interface MetadataUnitShortNameWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUnitShortNameNestedPhotoUpdateInput | null;
  archive?: MetadataUnitShortNameNestedArchiveUpdateInput | null;
}

export interface MetadataUnitShortNameWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUnitShortNameNestedMetadataCreateInput;
  archive: MetadataUnitShortNameNestedArchiveCreateInput;
}

export interface MetadataUnitShortNameWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUnitShortNameNestedMetadataUpdateInput | null;
  archive?: MetadataUnitShortNameNestedArchiveUpdateInput | null;
}

/**
 * Filters the "MetadataUnit" nodes by specifying some conditions
 */
export interface MetadataUnitWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUnitWhereInput[] | null;
  OR?: MetadataUnitWhereInput[] | null;
  NOT?: MetadataUnitWhereInput | null;
}

/**
 * Identifies exactly one "MetadataUnit" node.
 */
export interface MetadataUnitWhereUniqueInput {
  id: number;
}

export interface MetadataUnitWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUnitNestedPhotoCreateInput;
  metadata: MetadataUnitNestedMetadataCreateInput;
}

export interface MetadataUnitWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUnitNestedPhotoUpdateInput | null;
  metadata?: MetadataUnitNestedMetadataUpdateInput | null;
}

export interface MetadataUnitWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUnitNestedPhotoCreateInput;
  archive: MetadataUnitNestedArchiveCreateInput;
}

export interface MetadataUnitWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUnitNestedPhotoUpdateInput | null;
  archive?: MetadataUnitNestedArchiveUpdateInput | null;
}

export interface MetadataUnitWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUnitNestedMetadataCreateInput;
  archive: MetadataUnitNestedArchiveCreateInput;
}

export interface MetadataUnitWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUnitNestedMetadataUpdateInput | null;
  archive?: MetadataUnitNestedArchiveUpdateInput | null;
}

export interface MetadataUpdateInput {
  name?: MetadataNameEnumType | null;
  legacyName?: string | null;
  label?: string | null;
  namespace?: string | null;
  structName?: string | null;
  type?: MetadataValueTypeEnum | null;
  photoMetadatas?: MetadataNestedPhotoMetadatasUpdateInput | null;
  metadataSerialNumberList?: MetadataNestedMetadataSerialNumberListUpdateInput | null;
  metadataAttributionNameList?: MetadataNestedMetadataAttributionNameListUpdateInput | null;
  metadataAttributionUrlList?: MetadataNestedMetadataAttributionUrlListUpdateInput | null;
  metadataLicenseList?: MetadataNestedMetadataLicenseListUpdateInput | null;
  metadataMorePermissionsList?: MetadataNestedMetadataMorePermissionsListUpdateInput | null;
  metadataPaintBasedCorrectionsList?: MetadataNestedMetadataPaintBasedCorrectionsListUpdateInput | null;
  metadataToneCurveList?: MetadataNestedMetadataToneCurveListUpdateInput | null;
  metadataCreatorList?: MetadataNestedMetadataCreatorListUpdateInput | null;
  metadataDescriptionList?: MetadataNestedMetadataDescriptionListUpdateInput | null;
  metadataFormatList?: MetadataNestedMetadataFormatListUpdateInput | null;
  metadataPageList?: MetadataNestedMetadataPageListUpdateInput | null;
  metadataPublisherList?: MetadataNestedMetadataPublisherListUpdateInput | null;
  metadataRightsList?: MetadataNestedMetadataRightsListUpdateInput | null;
  metadataSubjectList?: MetadataNestedMetadataSubjectListUpdateInput | null;
  metadataTitleList?: MetadataNestedMetadataTitleListUpdateInput | null;
  metadataTypeList?: MetadataNestedMetadataTypeListUpdateInput | null;
  metadataAccessRightsList?: MetadataNestedMetadataAccessRightsListUpdateInput | null;
  metadataAudienceList?: MetadataNestedMetadataAudienceListUpdateInput | null;
  metadataValidList?: MetadataNestedMetadataValidListUpdateInput | null;
  metadataEquipmentInstitutionList?: MetadataNestedMetadataEquipmentInstitutionListUpdateInput | null;
  metadataEquipmentManufacturerList?: MetadataNestedMetadataEquipmentManufacturerListUpdateInput | null;
  metadataFileInfoBitsPerPixelList?: MetadataNestedMetadataFileInfoBitsPerPixelListUpdateInput | null;
  metadataFileInfoFramesList?: MetadataNestedMetadataFileInfoFramesListUpdateInput | null;
  metadataFileInfoPhotometricInterpretationList?: MetadataNestedMetadataFileInfoPhotometricInterpretationListUpdateInput | null;
  metadataFileInfoTransferSyntaxList?: MetadataNestedMetadataFileInfoTransferSyntaxListUpdateInput | null;
  metadataPatientDobList?: MetadataNestedMetadataPatientDobListUpdateInput | null;
  metadataPatientIdList?: MetadataNestedMetadataPatientIdListUpdateInput | null;
  metadataPatientNameList?: MetadataNestedMetadataPatientNameListUpdateInput | null;
  metadataPatientSexList?: MetadataNestedMetadataPatientSexListUpdateInput | null;
  metadataSeriesDateTimeList?: MetadataNestedMetadataSeriesDateTimeListUpdateInput | null;
  metadataSeriesDescriptionList?: MetadataNestedMetadataSeriesDescriptionListUpdateInput | null;
  metadataSeriesModalityList?: MetadataNestedMetadataSeriesModalityListUpdateInput | null;
  metadataSeriesNumberList?: MetadataNestedMetadataSeriesNumberListUpdateInput | null;
  metadataStudyDateTimeList?: MetadataNestedMetadataStudyDateTimeListUpdateInput | null;
  metadataStudyDescriptionList?: MetadataNestedMetadataStudyDescriptionListUpdateInput | null;
  metadataStudyIdList?: MetadataNestedMetadataStudyIdListUpdateInput | null;
  metadataStudyPhysicianList?: MetadataNestedMetadataStudyPhysicianListUpdateInput | null;
  metadataDateTimeDigitizedList?: MetadataNestedMetadataDateTimeDigitizedListUpdateInput | null;
  metadataDateTimeOriginalList?: MetadataNestedMetadataDateTimeOriginalListUpdateInput | null;
  metadataGpsLatitudeList?: MetadataNestedMetadataGpsLatitudeListUpdateInput | null;
  metadataGpsLongitudeList?: MetadataNestedMetadataGpsLongitudeListUpdateInput | null;
  metadataGpsProcessingMethodList?: MetadataNestedMetadataGpsProcessingMethodListUpdateInput | null;
  metadataImageUniqueIdList?: MetadataNestedMetadataImageUniqueIdListUpdateInput | null;
  metadataIsoSpeedRatingsList?: MetadataNestedMetadataIsoSpeedRatingsListUpdateInput | null;
  metadataUserCommentList?: MetadataNestedMetadataUserCommentListUpdateInput | null;
  metadataCustomField14List?: MetadataNestedMetadataCustomField14ListUpdateInput | null;
  metadataCustomField15List?: MetadataNestedMetadataCustomField15ListUpdateInput | null;
  metadataCustomField16List?: MetadataNestedMetadataCustomField16ListUpdateInput | null;
  metadataCustomField17List?: MetadataNestedMetadataCustomField17ListUpdateInput | null;
  metadataCustomField20List?: MetadataNestedMetadataCustomField20ListUpdateInput | null;
  metadataCustomField5List?: MetadataNestedMetadataCustomField5ListUpdateInput | null;
  metadataCustomField6List?: MetadataNestedMetadataCustomField6ListUpdateInput | null;
  metadataCustomField7List?: MetadataNestedMetadataCustomField7ListUpdateInput | null;
  metadataCustomField9List?: MetadataNestedMetadataCustomField9ListUpdateInput | null;
  metadataCreatedTimeList?: MetadataNestedMetadataCreatedTimeListUpdateInput | null;
  metadataEditStatusList?: MetadataNestedMetadataEditStatusListUpdateInput | null;
  metadataFixtureIdentifierList?: MetadataNestedMetadataFixtureIdentifierListUpdateInput | null;
  metadataLocalCaptionList?: MetadataNestedMetadataLocalCaptionListUpdateInput | null;
  metadataProgramVersionList?: MetadataNestedMetadataProgramVersionListUpdateInput | null;
  metadataReferenceDateList?: MetadataNestedMetadataReferenceDateListUpdateInput | null;
  metadataReferenceNumberList?: MetadataNestedMetadataReferenceNumberListUpdateInput | null;
  metadataReferenceServiceList?: MetadataNestedMetadataReferenceServiceListUpdateInput | null;
  metadataReleaseDateList?: MetadataNestedMetadataReleaseDateListUpdateInput | null;
  metadataReleaseTimeList?: MetadataNestedMetadataReleaseTimeListUpdateInput | null;
  metadataAssetStateList?: MetadataNestedMetadataAssetStateListUpdateInput | null;
  metadataClassifyList?: MetadataNestedMetadataClassifyListUpdateInput | null;
  metadataContainedInList?: MetadataNestedMetadataContainedInListUpdateInput | null;
  metadataContentValueList?: MetadataNestedMetadataContentValueListUpdateInput | null;
  metadataDocumentTextList?: MetadataNestedMetadataDocumentTextListUpdateInput | null;
  metadataExifList?: MetadataNestedMetadataExifListUpdateInput | null;
  metadataHistoryList?: MetadataNestedMetadataHistoryListUpdateInput | null;
  metadataImageNotesList?: MetadataNestedMetadataImageNotesListUpdateInput | null;
  metadataJobIdList?: MetadataNestedMetadataJobIdListUpdateInput | null;
  metadataLinksList?: MetadataNestedMetadataLinksListUpdateInput | null;
  metadataMasterDocumentIdList?: MetadataNestedMetadataMasterDocumentIdListUpdateInput | null;
  metadataObjectCycleList?: MetadataNestedMetadataObjectCycleListUpdateInput | null;
  metadataOwnerIdList?: MetadataNestedMetadataOwnerIdListUpdateInput | null;
  metadataShortUniqueIdList?: MetadataNestedMetadataShortUniqueIdListUpdateInput | null;
  metadataTransferredByList?: MetadataNestedMetadataTransferredByListUpdateInput | null;
  metadataTransferredByEmailList?: MetadataNestedMetadataTransferredByEmailListUpdateInput | null;
  metadataTransferredByFullNameList?: MetadataNestedMetadataTransferredByFullNameListUpdateInput | null;
  metadataUniqueIdList?: MetadataNestedMetadataUniqueIdListUpdateInput | null;
  metadataUploadedByList?: MetadataNestedMetadataUploadedByListUpdateInput | null;
  metadataUploadedByFullNameList?: MetadataNestedMetadataUploadedByFullNameListUpdateInput | null;
  metadataUploadTimeList?: MetadataNestedMetadataUploadTimeListUpdateInput | null;
  metadataUserDefined195List?: MetadataNestedMetadataUserDefined195ListUpdateInput | null;
  metadataUserDefined237List?: MetadataNestedMetadataUserDefined237ListUpdateInput | null;
  metadataUserDefined238List?: MetadataNestedMetadataUserDefined238ListUpdateInput | null;
  metadataUserDefined239List?: MetadataNestedMetadataUserDefined239ListUpdateInput | null;
  metadataUserDefined242List?: MetadataNestedMetadataUserDefined242ListUpdateInput | null;
  metadataUserDefined62List?: MetadataNestedMetadataUserDefined62ListUpdateInput | null;
  metadataCiAdrCityList?: MetadataNestedMetadataCiAdrCityListUpdateInput | null;
  metadataCiAdrCtryList?: MetadataNestedMetadataCiAdrCtryListUpdateInput | null;
  metadataCiAdrExtadrList?: MetadataNestedMetadataCiAdrExtadrListUpdateInput | null;
  metadataCiAdrPcodeList?: MetadataNestedMetadataCiAdrPcodeListUpdateInput | null;
  metadataCiAdrRegionList?: MetadataNestedMetadataCiAdrRegionListUpdateInput | null;
  metadataCiEmailWorkList?: MetadataNestedMetadataCiEmailWorkListUpdateInput | null;
  metadataCiTelWorkList?: MetadataNestedMetadataCiTelWorkListUpdateInput | null;
  metadataCiUrlWorkList?: MetadataNestedMetadataCiUrlWorkListUpdateInput | null;
  metadataCountryCodeList?: MetadataNestedMetadataCountryCodeListUpdateInput | null;
  metadataIntellectualGenreList?: MetadataNestedMetadataIntellectualGenreListUpdateInput | null;
  metadataLocationList?: MetadataNestedMetadataLocationListUpdateInput | null;
  metadataSceneList?: MetadataNestedMetadataSceneListUpdateInput | null;
  metadataSubjectCodeList?: MetadataNestedMetadataSubjectCodeListUpdateInput | null;
  metadataAddlModelInfoList?: MetadataNestedMetadataAddlModelInfoListUpdateInput | null;
  metadataEventList?: MetadataNestedMetadataEventListUpdateInput | null;
  metadataMaxAvailHeightList?: MetadataNestedMetadataMaxAvailHeightListUpdateInput | null;
  metadataMaxAvailWidthList?: MetadataNestedMetadataMaxAvailWidthListUpdateInput | null;
  metadataModelAgeList?: MetadataNestedMetadataModelAgeListUpdateInput | null;
  metadataOrganisationInImageNameList?: MetadataNestedMetadataOrganisationInImageNameListUpdateInput | null;
  metadataPersonInImageList?: MetadataNestedMetadataPersonInImageListUpdateInput | null;
  metadataAuthorsPositionList?: MetadataNestedMetadataAuthorsPositionListUpdateInput | null;
  metadataCaptionWriterList?: MetadataNestedMetadataCaptionWriterListUpdateInput | null;
  metadataCategoryList?: MetadataNestedMetadataCategoryListUpdateInput | null;
  metadataCityList?: MetadataNestedMetadataCityListUpdateInput | null;
  metadataCountryList?: MetadataNestedMetadataCountryListUpdateInput | null;
  metadataCreditList?: MetadataNestedMetadataCreditListUpdateInput | null;
  metadataDateCreatedList?: MetadataNestedMetadataDateCreatedListUpdateInput | null;
  metadataHeadlineList?: MetadataNestedMetadataHeadlineListUpdateInput | null;
  metadataIccProfileList?: MetadataNestedMetadataIccProfileListUpdateInput | null;
  metadataInstructionsList?: MetadataNestedMetadataInstructionsListUpdateInput | null;
  metadataSourceList?: MetadataNestedMetadataSourceListUpdateInput | null;
  metadataStateList?: MetadataNestedMetadataStateListUpdateInput | null;
  metadataSupplementalCategoriesList?: MetadataNestedMetadataSupplementalCategoriesListUpdateInput | null;
  metadataTransmissionReferenceList?: MetadataNestedMetadataTransmissionReferenceListUpdateInput | null;
  metadataUrgencyList?: MetadataNestedMetadataUrgencyListUpdateInput | null;
  metadataMinorModelAgeDisclosureList?: MetadataNestedMetadataMinorModelAgeDisclosureListUpdateInput | null;
  metadataModelReleaseIdList?: MetadataNestedMetadataModelReleaseIdListUpdateInput | null;
  metadataModelReleaseStatusList?: MetadataNestedMetadataModelReleaseStatusListUpdateInput | null;
  metadataPropertyReleaseIdList?: MetadataNestedMetadataPropertyReleaseIdListUpdateInput | null;
  metadataPropertyReleaseStatusList?: MetadataNestedMetadataPropertyReleaseStatusListUpdateInput | null;
  metadataVersionList?: MetadataNestedMetadataVersionListUpdateInput | null;
  metadataBrandList?: MetadataNestedMetadataBrandListUpdateInput | null;
  metadataDivisionList?: MetadataNestedMetadataDivisionListUpdateInput | null;
  metadataHeaderList?: MetadataNestedMetadataHeaderListUpdateInput | null;
  metadataProductsList?: MetadataNestedMetadataProductsListUpdateInput | null;
  metadataProduitsList?: MetadataNestedMetadataProduitsListUpdateInput | null;
  metadataPublishingIssueList?: MetadataNestedMetadataPublishingIssueListUpdateInput | null;
  metadataPublishingSubjectList?: MetadataNestedMetadataPublishingSubjectListUpdateInput | null;
  metadataCompanyList?: MetadataNestedMetadataCompanyListUpdateInput | null;
  metadataCompanyShortNameList?: MetadataNestedMetadataCompanyShortNameListUpdateInput | null;
  metadataConfidentialityList?: MetadataNestedMetadataConfidentialityListUpdateInput | null;
  metadataEditorialVersionList?: MetadataNestedMetadataEditorialVersionListUpdateInput | null;
  metadataFileCheckForList?: MetadataNestedMetadataFileCheckForListUpdateInput | null;
  metadataFileStageList?: MetadataNestedMetadataFileStageListUpdateInput | null;
  metadataFileStatusList?: MetadataNestedMetadataFileStatusListUpdateInput | null;
  metadataSentToList?: MetadataNestedMetadataSentToListUpdateInput | null;
  metadataTargetVersionList?: MetadataNestedMetadataTargetVersionListUpdateInput | null;
  metadataUnitList?: MetadataNestedMetadataUnitListUpdateInput | null;
  metadataArchiveChildList?: MetadataNestedMetadataArchiveChildListUpdateInput | null;
  metadataArchiveParentList?: MetadataNestedMetadataArchiveParentListUpdateInput | null;
  metadataBackupNameList?: MetadataNestedMetadataBackupNameListUpdateInput | null;
  metadataBitmapGrayscalePictureList?: MetadataNestedMetadataBitmapGrayscalePictureListUpdateInput | null;
  metadataBrandPrismaList?: MetadataNestedMetadataBrandPrismaListUpdateInput | null;
  metadataBrandShortNameList?: MetadataNestedMetadataBrandShortNameListUpdateInput | null;
  metadataCategoryPrismaList?: MetadataNestedMetadataCategoryPrismaListUpdateInput | null;
  metadataContentMediaKindList?: MetadataNestedMetadataContentMediaKindListUpdateInput | null;
  metadataCopyrightLayerList?: MetadataNestedMetadataCopyrightLayerListUpdateInput | null;
  metadataDeliveryAccountList?: MetadataNestedMetadataDeliveryAccountListUpdateInput | null;
  metadataDeliveryCompanyList?: MetadataNestedMetadataDeliveryCompanyListUpdateInput | null;
  metadataDeliveryCopyrightList?: MetadataNestedMetadataDeliveryCopyrightListUpdateInput | null;
  metadataDeliveryDateFolderList?: MetadataNestedMetadataDeliveryDateFolderListUpdateInput | null;
  metadataDeliveryDateTimeList?: MetadataNestedMetadataDeliveryDateTimeListUpdateInput | null;
  metadataDeliveryDcCreatorList?: MetadataNestedMetadataDeliveryDcCreatorListUpdateInput | null;
  metadataDeliveryDcRightsList?: MetadataNestedMetadataDeliveryDcRightsListUpdateInput | null;
  metadataDeliveryFileTypeList?: MetadataNestedMetadataDeliveryFileTypeListUpdateInput | null;
  metadataDeliveryFolderList?: MetadataNestedMetadataDeliveryFolderListUpdateInput | null;
  metadataDeliveryKindList?: MetadataNestedMetadataDeliveryKindListUpdateInput | null;
  metadataDeliveryPathList?: MetadataNestedMetadataDeliveryPathListUpdateInput | null;
  metadataDeliveryPersonShownintheImageList?: MetadataNestedMetadataDeliveryPersonShownintheImageListUpdateInput | null;
  metadataDeliveryPhotoshopCreditList?: MetadataNestedMetadataDeliveryPhotoshopCreditListUpdateInput | null;
  metadataDeliveryPhotoshopSourceList?: MetadataNestedMetadataDeliveryPhotoshopSourceListUpdateInput | null;
  metadataDeliveryServiceList?: MetadataNestedMetadataDeliveryServiceListUpdateInput | null;
  metadataDeliverySubjectList?: MetadataNestedMetadataDeliverySubjectListUpdateInput | null;
  metadataDeliveryUnderSubjectList?: MetadataNestedMetadataDeliveryUnderSubjectListUpdateInput | null;
  metadataDepartmentList?: MetadataNestedMetadataDepartmentListUpdateInput | null;
  metadataDigitalAssetUrlList?: MetadataNestedMetadataDigitalAssetUrlListUpdateInput | null;
  metadataEditionList?: MetadataNestedMetadataEditionListUpdateInput | null;
  metadataEnvironnementPhotoList?: MetadataNestedMetadataEnvironnementPhotoListUpdateInput | null;
  metadataFabStorageList?: MetadataNestedMetadataFabStorageListUpdateInput | null;
  metadataFileTypeList?: MetadataNestedMetadataFileTypeListUpdateInput | null;
  metadataHeaderPrismaList?: MetadataNestedMetadataHeaderPrismaListUpdateInput | null;
  metadataIdSubjectList?: MetadataNestedMetadataIdSubjectListUpdateInput | null;
  metadataIdAssignmentList?: MetadataNestedMetadataIdAssignmentListUpdateInput | null;
  metadataIdMediaContentList?: MetadataNestedMetadataIdMediaContentListUpdateInput | null;
  metadataIdPhotoPrismaList?: MetadataNestedMetadataIdPhotoPrismaListUpdateInput | null;
  metadataIssueList?: MetadataNestedMetadataIssueListUpdateInput | null;
  metadataJobProcessingList?: MetadataNestedMetadataJobProcessingListUpdateInput | null;
  metadataLayoutPictureEditingList?: MetadataNestedMetadataLayoutPictureEditingListUpdateInput | null;
  metadataLayoutStorageList?: MetadataNestedMetadataLayoutStorageListUpdateInput | null;
  metadataOriginalColorList?: MetadataNestedMetadataOriginalColorListUpdateInput | null;
  metadataOriginalIccProfileList?: MetadataNestedMetadataOriginalIccProfileListUpdateInput | null;
  metadataOriginalNameList?: MetadataNestedMetadataOriginalNameListUpdateInput | null;
  metadataOriginalWeigthList?: MetadataNestedMetadataOriginalWeigthListUpdateInput | null;
  metadataPagePrismaList?: MetadataNestedMetadataPagePrismaListUpdateInput | null;
  metadataPhotoStorageList?: MetadataNestedMetadataPhotoStorageListUpdateInput | null;
  metadataPrepressPictureEditingList?: MetadataNestedMetadataPrepressPictureEditingListUpdateInput | null;
  metadataPriceLevelList?: MetadataNestedMetadataPriceLevelListUpdateInput | null;
  metadataPrintingProfileList?: MetadataNestedMetadataPrintingProfileListUpdateInput | null;
  metadataPrismaProductionList?: MetadataNestedMetadataPrismaProductionListUpdateInput | null;
  metadataProcessHistoryList?: MetadataNestedMetadataProcessHistoryListUpdateInput | null;
  metadataProcessParameterList?: MetadataNestedMetadataProcessParameterListUpdateInput | null;
  metadataProcessStatusList?: MetadataNestedMetadataProcessStatusListUpdateInput | null;
  metadataProductList?: MetadataNestedMetadataProductListUpdateInput | null;
  metadataProductShortNameList?: MetadataNestedMetadataProductShortNameListUpdateInput | null;
  metadataQualifiedUsePrismaByList?: MetadataNestedMetadataQualifiedUsePrismaByListUpdateInput | null;
  metadataQualifiedUsePrismaDateList?: MetadataNestedMetadataQualifiedUsePrismaDateListUpdateInput | null;
  metadataQualifiedUsePrismaDurationList?: MetadataNestedMetadataQualifiedUsePrismaDurationListUpdateInput | null;
  metadataQualifiedUsePrismaSupportList?: MetadataNestedMetadataQualifiedUsePrismaSupportListUpdateInput | null;
  metadataRequiredPermissionList?: MetadataNestedMetadataRequiredPermissionListUpdateInput | null;
  metadataResolutionKindList?: MetadataNestedMetadataResolutionKindListUpdateInput | null;
  metadataRoyaltyfreeList?: MetadataNestedMetadataRoyaltyfreeListUpdateInput | null;
  metadataSectionList?: MetadataNestedMetadataSectionListUpdateInput | null;
  metadataSubjectPrismaList?: MetadataNestedMetadataSubjectPrismaListUpdateInput | null;
  metadataUndersubjectList?: MetadataNestedMetadataUndersubjectListUpdateInput | null;
  metadataUnderUnderSubjectList?: MetadataNestedMetadataUnderUnderSubjectListUpdateInput | null;
  metadataUnitShortNameList?: MetadataNestedMetadataUnitShortNameListUpdateInput | null;
  metadataWorkflowKindList?: MetadataNestedMetadataWorkflowKindListUpdateInput | null;
  metadataMakeList?: MetadataNestedMetadataMakeListUpdateInput | null;
  metadataModelList?: MetadataNestedMetadataModelListUpdateInput | null;
  metadataCreatorToolList?: MetadataNestedMetadataCreatorToolListUpdateInput | null;
  metadataRatingList?: MetadataNestedMetadataRatingListUpdateInput | null;
  metadataXmpFileStampsList?: MetadataNestedMetadataXmpFileStampsListUpdateInput | null;
  metadataManifestList?: MetadataNestedMetadataManifestListUpdateInput | null;
  metadataXmpHistoryList?: MetadataNestedMetadataXmpHistoryListUpdateInput | null;
  metadataCertificateList?: MetadataNestedMetadataCertificateListUpdateInput | null;
  metadataMarkedList?: MetadataNestedMetadataMarkedListUpdateInput | null;
  metadataOwnerList?: MetadataNestedMetadataOwnerListUpdateInput | null;
  metadataUsageTermsList?: MetadataNestedMetadataUsageTermsListUpdateInput | null;
  metadataWebStatementList?: MetadataNestedMetadataWebStatementListUpdateInput | null;
}

export interface MetadataUploadTimeNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUploadTimeNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUploadTimeNestedUpsertArchiveCreateInput | null;
}

export interface MetadataUploadTimeNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUploadTimeNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUploadTimeNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUploadTimeNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUploadTimeNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUploadTimeNestedUpsertMetadataCreateInput | null;
}

export interface MetadataUploadTimeNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUploadTimeNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUploadTimeNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUploadTimeNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUploadTimeNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUploadTimeNestedUpsertPhotoCreateInput | null;
}

export interface MetadataUploadTimeNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUploadTimeNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUploadTimeNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUploadTimeNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUploadTimeNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUploadTimeNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUploadTimeNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUploadTimeNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUploadTimeNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUploadTimeNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUploadTimeNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUploadTimeNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUploadTimeNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUploadTimeNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUploadTimeNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUploadTime" nodes by specifying some conditions
 */
export interface MetadataUploadTimeWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUploadTimeWhereInput[] | null;
  OR?: MetadataUploadTimeWhereInput[] | null;
  NOT?: MetadataUploadTimeWhereInput | null;
}

/**
 * Identifies exactly one "MetadataUploadTime" node.
 */
export interface MetadataUploadTimeWhereUniqueInput {
  id: number;
}

export interface MetadataUploadTimeWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUploadTimeNestedPhotoCreateInput;
  metadata: MetadataUploadTimeNestedMetadataCreateInput;
}

export interface MetadataUploadTimeWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUploadTimeNestedPhotoUpdateInput | null;
  metadata?: MetadataUploadTimeNestedMetadataUpdateInput | null;
}

export interface MetadataUploadTimeWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUploadTimeNestedPhotoCreateInput;
  archive: MetadataUploadTimeNestedArchiveCreateInput;
}

export interface MetadataUploadTimeWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUploadTimeNestedPhotoUpdateInput | null;
  archive?: MetadataUploadTimeNestedArchiveUpdateInput | null;
}

export interface MetadataUploadTimeWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUploadTimeNestedMetadataCreateInput;
  archive: MetadataUploadTimeNestedArchiveCreateInput;
}

export interface MetadataUploadTimeWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUploadTimeNestedMetadataUpdateInput | null;
  archive?: MetadataUploadTimeNestedArchiveUpdateInput | null;
}

export interface MetadataUploadedByFullNameNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUploadedByFullNameNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUploadedByFullNameNestedUpsertArchiveCreateInput | null;
}

export interface MetadataUploadedByFullNameNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUploadedByFullNameNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUploadedByFullNameNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUploadedByFullNameNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUploadedByFullNameNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUploadedByFullNameNestedUpsertMetadataCreateInput | null;
}

export interface MetadataUploadedByFullNameNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUploadedByFullNameNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUploadedByFullNameNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUploadedByFullNameNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUploadedByFullNameNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUploadedByFullNameNestedUpsertPhotoCreateInput | null;
}

export interface MetadataUploadedByFullNameNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUploadedByFullNameNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUploadedByFullNameNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUploadedByFullNameNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUploadedByFullNameNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUploadedByFullNameNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUploadedByFullNameNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUploadedByFullNameNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUploadedByFullNameNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUploadedByFullNameNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUploadedByFullNameNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUploadedByFullNameNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUploadedByFullNameNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUploadedByFullNameNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUploadedByFullNameNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUploadedByFullName" nodes by specifying some conditions
 */
export interface MetadataUploadedByFullNameWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUploadedByFullNameWhereInput[] | null;
  OR?: MetadataUploadedByFullNameWhereInput[] | null;
  NOT?: MetadataUploadedByFullNameWhereInput | null;
}

/**
 * Identifies exactly one "MetadataUploadedByFullName" node.
 */
export interface MetadataUploadedByFullNameWhereUniqueInput {
  id: number;
}

export interface MetadataUploadedByFullNameWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUploadedByFullNameNestedPhotoCreateInput;
  metadata: MetadataUploadedByFullNameNestedMetadataCreateInput;
}

export interface MetadataUploadedByFullNameWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUploadedByFullNameNestedPhotoUpdateInput | null;
  metadata?: MetadataUploadedByFullNameNestedMetadataUpdateInput | null;
}

export interface MetadataUploadedByFullNameWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUploadedByFullNameNestedPhotoCreateInput;
  archive: MetadataUploadedByFullNameNestedArchiveCreateInput;
}

export interface MetadataUploadedByFullNameWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUploadedByFullNameNestedPhotoUpdateInput | null;
  archive?: MetadataUploadedByFullNameNestedArchiveUpdateInput | null;
}

export interface MetadataUploadedByFullNameWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUploadedByFullNameNestedMetadataCreateInput;
  archive: MetadataUploadedByFullNameNestedArchiveCreateInput;
}

export interface MetadataUploadedByFullNameWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUploadedByFullNameNestedMetadataUpdateInput | null;
  archive?: MetadataUploadedByFullNameNestedArchiveUpdateInput | null;
}

export interface MetadataUploadedByNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUploadedByNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUploadedByNestedUpsertArchiveCreateInput | null;
}

export interface MetadataUploadedByNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUploadedByNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUploadedByNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUploadedByNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUploadedByNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUploadedByNestedUpsertMetadataCreateInput | null;
}

export interface MetadataUploadedByNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUploadedByNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUploadedByNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUploadedByNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUploadedByNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUploadedByNestedUpsertPhotoCreateInput | null;
}

export interface MetadataUploadedByNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUploadedByNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUploadedByNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUploadedByNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUploadedByNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUploadedByNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUploadedByNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUploadedByNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUploadedByNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUploadedByNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUploadedByNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUploadedByNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUploadedByNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUploadedByNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUploadedByNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUploadedBy" nodes by specifying some conditions
 */
export interface MetadataUploadedByWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUploadedByWhereInput[] | null;
  OR?: MetadataUploadedByWhereInput[] | null;
  NOT?: MetadataUploadedByWhereInput | null;
}

/**
 * Identifies exactly one "MetadataUploadedBy" node.
 */
export interface MetadataUploadedByWhereUniqueInput {
  id: number;
}

export interface MetadataUploadedByWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUploadedByNestedPhotoCreateInput;
  metadata: MetadataUploadedByNestedMetadataCreateInput;
}

export interface MetadataUploadedByWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUploadedByNestedPhotoUpdateInput | null;
  metadata?: MetadataUploadedByNestedMetadataUpdateInput | null;
}

export interface MetadataUploadedByWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUploadedByNestedPhotoCreateInput;
  archive: MetadataUploadedByNestedArchiveCreateInput;
}

export interface MetadataUploadedByWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUploadedByNestedPhotoUpdateInput | null;
  archive?: MetadataUploadedByNestedArchiveUpdateInput | null;
}

export interface MetadataUploadedByWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUploadedByNestedMetadataCreateInput;
  archive: MetadataUploadedByNestedArchiveCreateInput;
}

export interface MetadataUploadedByWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUploadedByNestedMetadataUpdateInput | null;
  archive?: MetadataUploadedByNestedArchiveUpdateInput | null;
}

export interface MetadataUrgencyNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUrgencyNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUrgencyNestedUpsertArchiveCreateInput | null;
}

export interface MetadataUrgencyNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUrgencyNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUrgencyNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUrgencyNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUrgencyNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUrgencyNestedUpsertMetadataCreateInput | null;
}

export interface MetadataUrgencyNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUrgencyNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUrgencyNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUrgencyNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUrgencyNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUrgencyNestedUpsertPhotoCreateInput | null;
}

export interface MetadataUrgencyNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUrgencyNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUrgencyNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUrgencyNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUrgencyNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUrgencyNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUrgencyNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUrgencyNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUrgencyNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUrgencyNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUrgencyNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUrgencyNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUrgencyNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUrgencyNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUrgencyNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUrgency" nodes by specifying some conditions
 */
export interface MetadataUrgencyWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUrgencyWhereInput[] | null;
  OR?: MetadataUrgencyWhereInput[] | null;
  NOT?: MetadataUrgencyWhereInput | null;
}

/**
 * Identifies exactly one "MetadataUrgency" node.
 */
export interface MetadataUrgencyWhereUniqueInput {
  id: number;
}

export interface MetadataUrgencyWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUrgencyNestedPhotoCreateInput;
  metadata: MetadataUrgencyNestedMetadataCreateInput;
}

export interface MetadataUrgencyWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUrgencyNestedPhotoUpdateInput | null;
  metadata?: MetadataUrgencyNestedMetadataUpdateInput | null;
}

export interface MetadataUrgencyWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUrgencyNestedPhotoCreateInput;
  archive: MetadataUrgencyNestedArchiveCreateInput;
}

export interface MetadataUrgencyWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUrgencyNestedPhotoUpdateInput | null;
  archive?: MetadataUrgencyNestedArchiveUpdateInput | null;
}

export interface MetadataUrgencyWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUrgencyNestedMetadataCreateInput;
  archive: MetadataUrgencyNestedArchiveCreateInput;
}

export interface MetadataUrgencyWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUrgencyNestedMetadataUpdateInput | null;
  archive?: MetadataUrgencyNestedArchiveUpdateInput | null;
}

export interface MetadataUsageTermsNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUsageTermsNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUsageTermsNestedUpsertArchiveCreateInput | null;
}

export interface MetadataUsageTermsNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUsageTermsNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUsageTermsNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUsageTermsNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUsageTermsNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUsageTermsNestedUpsertMetadataCreateInput | null;
}

export interface MetadataUsageTermsNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUsageTermsNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUsageTermsNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUsageTermsNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUsageTermsNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUsageTermsNestedUpsertPhotoCreateInput | null;
}

export interface MetadataUsageTermsNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUsageTermsNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUsageTermsNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUsageTermsNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUsageTermsNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUsageTermsNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUsageTermsNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUsageTermsNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUsageTermsNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUsageTermsNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUsageTermsNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUsageTermsNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUsageTermsNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUsageTermsNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUsageTermsNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUsageTerms" nodes by specifying some conditions
 */
export interface MetadataUsageTermsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUsageTermsWhereInput[] | null;
  OR?: MetadataUsageTermsWhereInput[] | null;
  NOT?: MetadataUsageTermsWhereInput | null;
}

/**
 * Identifies exactly one "MetadataUsageTerms" node.
 */
export interface MetadataUsageTermsWhereUniqueInput {
  id: number;
}

export interface MetadataUsageTermsWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUsageTermsNestedPhotoCreateInput;
  metadata: MetadataUsageTermsNestedMetadataCreateInput;
}

export interface MetadataUsageTermsWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUsageTermsNestedPhotoUpdateInput | null;
  metadata?: MetadataUsageTermsNestedMetadataUpdateInput | null;
}

export interface MetadataUsageTermsWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUsageTermsNestedPhotoCreateInput;
  archive: MetadataUsageTermsNestedArchiveCreateInput;
}

export interface MetadataUsageTermsWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUsageTermsNestedPhotoUpdateInput | null;
  archive?: MetadataUsageTermsNestedArchiveUpdateInput | null;
}

export interface MetadataUsageTermsWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUsageTermsNestedMetadataCreateInput;
  archive: MetadataUsageTermsNestedArchiveCreateInput;
}

export interface MetadataUsageTermsWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUsageTermsNestedMetadataUpdateInput | null;
  archive?: MetadataUsageTermsNestedArchiveUpdateInput | null;
}

export interface MetadataUserCommentNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUserCommentNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUserCommentNestedUpsertArchiveCreateInput | null;
}

export interface MetadataUserCommentNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUserCommentNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUserCommentNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUserCommentNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUserCommentNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUserCommentNestedUpsertMetadataCreateInput | null;
}

export interface MetadataUserCommentNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUserCommentNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUserCommentNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUserCommentNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUserCommentNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUserCommentNestedUpsertPhotoCreateInput | null;
}

export interface MetadataUserCommentNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUserCommentNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUserCommentNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUserCommentNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUserCommentNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUserCommentNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUserCommentNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUserCommentNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUserCommentNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUserCommentNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUserCommentNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUserCommentNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUserCommentNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUserCommentNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUserCommentNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUserComment" nodes by specifying some conditions
 */
export interface MetadataUserCommentWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUserCommentWhereInput[] | null;
  OR?: MetadataUserCommentWhereInput[] | null;
  NOT?: MetadataUserCommentWhereInput | null;
}

/**
 * Identifies exactly one "MetadataUserComment" node.
 */
export interface MetadataUserCommentWhereUniqueInput {
  id: number;
}

export interface MetadataUserCommentWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUserCommentNestedPhotoCreateInput;
  metadata: MetadataUserCommentNestedMetadataCreateInput;
}

export interface MetadataUserCommentWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUserCommentNestedPhotoUpdateInput | null;
  metadata?: MetadataUserCommentNestedMetadataUpdateInput | null;
}

export interface MetadataUserCommentWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUserCommentNestedPhotoCreateInput;
  archive: MetadataUserCommentNestedArchiveCreateInput;
}

export interface MetadataUserCommentWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUserCommentNestedPhotoUpdateInput | null;
  archive?: MetadataUserCommentNestedArchiveUpdateInput | null;
}

export interface MetadataUserCommentWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUserCommentNestedMetadataCreateInput;
  archive: MetadataUserCommentNestedArchiveCreateInput;
}

export interface MetadataUserCommentWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUserCommentNestedMetadataUpdateInput | null;
  archive?: MetadataUserCommentNestedArchiveUpdateInput | null;
}

export interface MetadataUserDefined195NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUserDefined195NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUserDefined195NestedUpsertArchiveCreateInput | null;
}

export interface MetadataUserDefined195NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUserDefined195NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUserDefined195NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUserDefined195NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUserDefined195NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUserDefined195NestedUpsertMetadataCreateInput | null;
}

export interface MetadataUserDefined195NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUserDefined195NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUserDefined195NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUserDefined195NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUserDefined195NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUserDefined195NestedUpsertPhotoCreateInput | null;
}

export interface MetadataUserDefined195NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUserDefined195NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUserDefined195NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUserDefined195NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUserDefined195NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUserDefined195NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUserDefined195NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUserDefined195NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUserDefined195NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUserDefined195NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUserDefined195NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUserDefined195NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUserDefined195NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUserDefined195NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUserDefined195NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUserDefined195" nodes by specifying some conditions
 */
export interface MetadataUserDefined195WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUserDefined195WhereInput[] | null;
  OR?: MetadataUserDefined195WhereInput[] | null;
  NOT?: MetadataUserDefined195WhereInput | null;
}

/**
 * Identifies exactly one "MetadataUserDefined195" node.
 */
export interface MetadataUserDefined195WhereUniqueInput {
  id: number;
}

export interface MetadataUserDefined195WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUserDefined195NestedPhotoCreateInput;
  metadata: MetadataUserDefined195NestedMetadataCreateInput;
}

export interface MetadataUserDefined195WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUserDefined195NestedPhotoUpdateInput | null;
  metadata?: MetadataUserDefined195NestedMetadataUpdateInput | null;
}

export interface MetadataUserDefined195WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUserDefined195NestedPhotoCreateInput;
  archive: MetadataUserDefined195NestedArchiveCreateInput;
}

export interface MetadataUserDefined195WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUserDefined195NestedPhotoUpdateInput | null;
  archive?: MetadataUserDefined195NestedArchiveUpdateInput | null;
}

export interface MetadataUserDefined195WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUserDefined195NestedMetadataCreateInput;
  archive: MetadataUserDefined195NestedArchiveCreateInput;
}

export interface MetadataUserDefined195WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUserDefined195NestedMetadataUpdateInput | null;
  archive?: MetadataUserDefined195NestedArchiveUpdateInput | null;
}

export interface MetadataUserDefined237NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUserDefined237NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUserDefined237NestedUpsertArchiveCreateInput | null;
}

export interface MetadataUserDefined237NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUserDefined237NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUserDefined237NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUserDefined237NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUserDefined237NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUserDefined237NestedUpsertMetadataCreateInput | null;
}

export interface MetadataUserDefined237NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUserDefined237NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUserDefined237NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUserDefined237NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUserDefined237NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUserDefined237NestedUpsertPhotoCreateInput | null;
}

export interface MetadataUserDefined237NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUserDefined237NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUserDefined237NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUserDefined237NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUserDefined237NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUserDefined237NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUserDefined237NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUserDefined237NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUserDefined237NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUserDefined237NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUserDefined237NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUserDefined237NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUserDefined237NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUserDefined237NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUserDefined237NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUserDefined237" nodes by specifying some conditions
 */
export interface MetadataUserDefined237WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUserDefined237WhereInput[] | null;
  OR?: MetadataUserDefined237WhereInput[] | null;
  NOT?: MetadataUserDefined237WhereInput | null;
}

/**
 * Identifies exactly one "MetadataUserDefined237" node.
 */
export interface MetadataUserDefined237WhereUniqueInput {
  id: number;
}

export interface MetadataUserDefined237WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUserDefined237NestedPhotoCreateInput;
  metadata: MetadataUserDefined237NestedMetadataCreateInput;
}

export interface MetadataUserDefined237WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUserDefined237NestedPhotoUpdateInput | null;
  metadata?: MetadataUserDefined237NestedMetadataUpdateInput | null;
}

export interface MetadataUserDefined237WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUserDefined237NestedPhotoCreateInput;
  archive: MetadataUserDefined237NestedArchiveCreateInput;
}

export interface MetadataUserDefined237WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUserDefined237NestedPhotoUpdateInput | null;
  archive?: MetadataUserDefined237NestedArchiveUpdateInput | null;
}

export interface MetadataUserDefined237WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUserDefined237NestedMetadataCreateInput;
  archive: MetadataUserDefined237NestedArchiveCreateInput;
}

export interface MetadataUserDefined237WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUserDefined237NestedMetadataUpdateInput | null;
  archive?: MetadataUserDefined237NestedArchiveUpdateInput | null;
}

export interface MetadataUserDefined238NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUserDefined238NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUserDefined238NestedUpsertArchiveCreateInput | null;
}

export interface MetadataUserDefined238NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUserDefined238NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUserDefined238NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUserDefined238NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUserDefined238NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUserDefined238NestedUpsertMetadataCreateInput | null;
}

export interface MetadataUserDefined238NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUserDefined238NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUserDefined238NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUserDefined238NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUserDefined238NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUserDefined238NestedUpsertPhotoCreateInput | null;
}

export interface MetadataUserDefined238NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUserDefined238NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUserDefined238NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUserDefined238NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUserDefined238NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUserDefined238NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUserDefined238NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUserDefined238NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUserDefined238NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUserDefined238NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUserDefined238NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUserDefined238NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUserDefined238NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUserDefined238NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUserDefined238NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUserDefined238" nodes by specifying some conditions
 */
export interface MetadataUserDefined238WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUserDefined238WhereInput[] | null;
  OR?: MetadataUserDefined238WhereInput[] | null;
  NOT?: MetadataUserDefined238WhereInput | null;
}

/**
 * Identifies exactly one "MetadataUserDefined238" node.
 */
export interface MetadataUserDefined238WhereUniqueInput {
  id: number;
}

export interface MetadataUserDefined238WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUserDefined238NestedPhotoCreateInput;
  metadata: MetadataUserDefined238NestedMetadataCreateInput;
}

export interface MetadataUserDefined238WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUserDefined238NestedPhotoUpdateInput | null;
  metadata?: MetadataUserDefined238NestedMetadataUpdateInput | null;
}

export interface MetadataUserDefined238WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUserDefined238NestedPhotoCreateInput;
  archive: MetadataUserDefined238NestedArchiveCreateInput;
}

export interface MetadataUserDefined238WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUserDefined238NestedPhotoUpdateInput | null;
  archive?: MetadataUserDefined238NestedArchiveUpdateInput | null;
}

export interface MetadataUserDefined238WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUserDefined238NestedMetadataCreateInput;
  archive: MetadataUserDefined238NestedArchiveCreateInput;
}

export interface MetadataUserDefined238WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUserDefined238NestedMetadataUpdateInput | null;
  archive?: MetadataUserDefined238NestedArchiveUpdateInput | null;
}

export interface MetadataUserDefined239NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUserDefined239NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUserDefined239NestedUpsertArchiveCreateInput | null;
}

export interface MetadataUserDefined239NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUserDefined239NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUserDefined239NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUserDefined239NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUserDefined239NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUserDefined239NestedUpsertMetadataCreateInput | null;
}

export interface MetadataUserDefined239NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUserDefined239NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUserDefined239NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUserDefined239NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUserDefined239NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUserDefined239NestedUpsertPhotoCreateInput | null;
}

export interface MetadataUserDefined239NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUserDefined239NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUserDefined239NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUserDefined239NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUserDefined239NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUserDefined239NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUserDefined239NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUserDefined239NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUserDefined239NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUserDefined239NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUserDefined239NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUserDefined239NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUserDefined239NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUserDefined239NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUserDefined239NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUserDefined239" nodes by specifying some conditions
 */
export interface MetadataUserDefined239WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUserDefined239WhereInput[] | null;
  OR?: MetadataUserDefined239WhereInput[] | null;
  NOT?: MetadataUserDefined239WhereInput | null;
}

/**
 * Identifies exactly one "MetadataUserDefined239" node.
 */
export interface MetadataUserDefined239WhereUniqueInput {
  id: number;
}

export interface MetadataUserDefined239WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUserDefined239NestedPhotoCreateInput;
  metadata: MetadataUserDefined239NestedMetadataCreateInput;
}

export interface MetadataUserDefined239WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUserDefined239NestedPhotoUpdateInput | null;
  metadata?: MetadataUserDefined239NestedMetadataUpdateInput | null;
}

export interface MetadataUserDefined239WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUserDefined239NestedPhotoCreateInput;
  archive: MetadataUserDefined239NestedArchiveCreateInput;
}

export interface MetadataUserDefined239WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUserDefined239NestedPhotoUpdateInput | null;
  archive?: MetadataUserDefined239NestedArchiveUpdateInput | null;
}

export interface MetadataUserDefined239WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUserDefined239NestedMetadataCreateInput;
  archive: MetadataUserDefined239NestedArchiveCreateInput;
}

export interface MetadataUserDefined239WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUserDefined239NestedMetadataUpdateInput | null;
  archive?: MetadataUserDefined239NestedArchiveUpdateInput | null;
}

export interface MetadataUserDefined242NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUserDefined242NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUserDefined242NestedUpsertArchiveCreateInput | null;
}

export interface MetadataUserDefined242NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUserDefined242NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUserDefined242NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUserDefined242NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUserDefined242NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUserDefined242NestedUpsertMetadataCreateInput | null;
}

export interface MetadataUserDefined242NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUserDefined242NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUserDefined242NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUserDefined242NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUserDefined242NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUserDefined242NestedUpsertPhotoCreateInput | null;
}

export interface MetadataUserDefined242NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUserDefined242NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUserDefined242NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUserDefined242NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUserDefined242NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUserDefined242NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUserDefined242NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUserDefined242NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUserDefined242NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUserDefined242NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUserDefined242NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUserDefined242NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUserDefined242NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUserDefined242NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUserDefined242NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUserDefined242" nodes by specifying some conditions
 */
export interface MetadataUserDefined242WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUserDefined242WhereInput[] | null;
  OR?: MetadataUserDefined242WhereInput[] | null;
  NOT?: MetadataUserDefined242WhereInput | null;
}

/**
 * Identifies exactly one "MetadataUserDefined242" node.
 */
export interface MetadataUserDefined242WhereUniqueInput {
  id: number;
}

export interface MetadataUserDefined242WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUserDefined242NestedPhotoCreateInput;
  metadata: MetadataUserDefined242NestedMetadataCreateInput;
}

export interface MetadataUserDefined242WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUserDefined242NestedPhotoUpdateInput | null;
  metadata?: MetadataUserDefined242NestedMetadataUpdateInput | null;
}

export interface MetadataUserDefined242WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUserDefined242NestedPhotoCreateInput;
  archive: MetadataUserDefined242NestedArchiveCreateInput;
}

export interface MetadataUserDefined242WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUserDefined242NestedPhotoUpdateInput | null;
  archive?: MetadataUserDefined242NestedArchiveUpdateInput | null;
}

export interface MetadataUserDefined242WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUserDefined242NestedMetadataCreateInput;
  archive: MetadataUserDefined242NestedArchiveCreateInput;
}

export interface MetadataUserDefined242WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUserDefined242NestedMetadataUpdateInput | null;
  archive?: MetadataUserDefined242NestedArchiveUpdateInput | null;
}

export interface MetadataUserDefined62NestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUserDefined62NestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUserDefined62NestedUpsertArchiveCreateInput | null;
}

export interface MetadataUserDefined62NestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataUserDefined62NestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataUserDefined62NestedUpsertArchiveUpdateInput | null;
}

export interface MetadataUserDefined62NestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUserDefined62NestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUserDefined62NestedUpsertMetadataCreateInput | null;
}

export interface MetadataUserDefined62NestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataUserDefined62NestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataUserDefined62NestedUpsertMetadataUpdateInput | null;
}

export interface MetadataUserDefined62NestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUserDefined62NestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUserDefined62NestedUpsertPhotoCreateInput | null;
}

export interface MetadataUserDefined62NestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataUserDefined62NestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataUserDefined62NestedUpsertPhotoUpdateInput | null;
}

export interface MetadataUserDefined62NestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUserDefined62NestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataUserDefined62NestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUserDefined62NestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataUserDefined62NestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUserDefined62NestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataUserDefined62NestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUserDefined62NestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataUserDefined62NestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUserDefined62NestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataUserDefined62NestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataUserDefined62NestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataUserDefined62" nodes by specifying some conditions
 */
export interface MetadataUserDefined62WhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataUserDefined62WhereInput[] | null;
  OR?: MetadataUserDefined62WhereInput[] | null;
  NOT?: MetadataUserDefined62WhereInput | null;
}

/**
 * Identifies exactly one "MetadataUserDefined62" node.
 */
export interface MetadataUserDefined62WhereUniqueInput {
  id: number;
}

export interface MetadataUserDefined62WithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataUserDefined62NestedPhotoCreateInput;
  metadata: MetadataUserDefined62NestedMetadataCreateInput;
}

export interface MetadataUserDefined62WithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataUserDefined62NestedPhotoUpdateInput | null;
  metadata?: MetadataUserDefined62NestedMetadataUpdateInput | null;
}

export interface MetadataUserDefined62WithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataUserDefined62NestedPhotoCreateInput;
  archive: MetadataUserDefined62NestedArchiveCreateInput;
}

export interface MetadataUserDefined62WithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataUserDefined62NestedPhotoUpdateInput | null;
  archive?: MetadataUserDefined62NestedArchiveUpdateInput | null;
}

export interface MetadataUserDefined62WithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataUserDefined62NestedMetadataCreateInput;
  archive: MetadataUserDefined62NestedArchiveCreateInput;
}

export interface MetadataUserDefined62WithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataUserDefined62NestedMetadataUpdateInput | null;
  archive?: MetadataUserDefined62NestedArchiveUpdateInput | null;
}

export interface MetadataValidNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataValidNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataValidNestedUpsertArchiveCreateInput | null;
}

export interface MetadataValidNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataValidNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataValidNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataValidNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataValidNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataValidNestedUpsertMetadataCreateInput | null;
}

export interface MetadataValidNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataValidNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataValidNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataValidNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataValidNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataValidNestedUpsertPhotoCreateInput | null;
}

export interface MetadataValidNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataValidNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataValidNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataValidNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataValidNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataValidNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataValidNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataValidNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataValidNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataValidNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataValidNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataValidNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataValidNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataValidNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataValidNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataValid" nodes by specifying some conditions
 */
export interface MetadataValidWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataValidWhereInput[] | null;
  OR?: MetadataValidWhereInput[] | null;
  NOT?: MetadataValidWhereInput | null;
}

/**
 * Identifies exactly one "MetadataValid" node.
 */
export interface MetadataValidWhereUniqueInput {
  id: number;
}

export interface MetadataValidWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataValidNestedPhotoCreateInput;
  metadata: MetadataValidNestedMetadataCreateInput;
}

export interface MetadataValidWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataValidNestedPhotoUpdateInput | null;
  metadata?: MetadataValidNestedMetadataUpdateInput | null;
}

export interface MetadataValidWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataValidNestedPhotoCreateInput;
  archive: MetadataValidNestedArchiveCreateInput;
}

export interface MetadataValidWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataValidNestedPhotoUpdateInput | null;
  archive?: MetadataValidNestedArchiveUpdateInput | null;
}

export interface MetadataValidWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataValidNestedMetadataCreateInput;
  archive: MetadataValidNestedArchiveCreateInput;
}

export interface MetadataValidWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataValidNestedMetadataUpdateInput | null;
  archive?: MetadataValidNestedArchiveUpdateInput | null;
}

export interface MetadataVersionNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataVersionNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataVersionNestedUpsertArchiveCreateInput | null;
}

export interface MetadataVersionNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataVersionNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataVersionNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataVersionNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataVersionNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataVersionNestedUpsertMetadataCreateInput | null;
}

export interface MetadataVersionNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataVersionNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataVersionNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataVersionNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataVersionNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataVersionNestedUpsertPhotoCreateInput | null;
}

export interface MetadataVersionNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataVersionNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataVersionNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataVersionNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataVersionNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataVersionNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataVersionNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataVersionNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataVersionNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataVersionNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataVersionNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataVersionNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataVersionNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataVersionNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataVersionNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataVersion" nodes by specifying some conditions
 */
export interface MetadataVersionWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataVersionWhereInput[] | null;
  OR?: MetadataVersionWhereInput[] | null;
  NOT?: MetadataVersionWhereInput | null;
}

/**
 * Identifies exactly one "MetadataVersion" node.
 */
export interface MetadataVersionWhereUniqueInput {
  id: number;
}

export interface MetadataVersionWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataVersionNestedPhotoCreateInput;
  metadata: MetadataVersionNestedMetadataCreateInput;
}

export interface MetadataVersionWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataVersionNestedPhotoUpdateInput | null;
  metadata?: MetadataVersionNestedMetadataUpdateInput | null;
}

export interface MetadataVersionWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataVersionNestedPhotoCreateInput;
  archive: MetadataVersionNestedArchiveCreateInput;
}

export interface MetadataVersionWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataVersionNestedPhotoUpdateInput | null;
  archive?: MetadataVersionNestedArchiveUpdateInput | null;
}

export interface MetadataVersionWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataVersionNestedMetadataCreateInput;
  archive: MetadataVersionNestedArchiveCreateInput;
}

export interface MetadataVersionWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataVersionNestedMetadataUpdateInput | null;
  archive?: MetadataVersionNestedArchiveUpdateInput | null;
}

export interface MetadataWebStatementNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataWebStatementNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataWebStatementNestedUpsertArchiveCreateInput | null;
}

export interface MetadataWebStatementNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataWebStatementNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataWebStatementNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataWebStatementNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataWebStatementNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataWebStatementNestedUpsertMetadataCreateInput | null;
}

export interface MetadataWebStatementNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataWebStatementNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataWebStatementNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataWebStatementNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataWebStatementNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataWebStatementNestedUpsertPhotoCreateInput | null;
}

export interface MetadataWebStatementNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataWebStatementNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataWebStatementNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataWebStatementNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataWebStatementNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataWebStatementNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataWebStatementNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataWebStatementNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataWebStatementNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataWebStatementNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataWebStatementNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataWebStatementNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataWebStatementNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataWebStatementNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataWebStatementNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataWebStatement" nodes by specifying some conditions
 */
export interface MetadataWebStatementWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataWebStatementWhereInput[] | null;
  OR?: MetadataWebStatementWhereInput[] | null;
  NOT?: MetadataWebStatementWhereInput | null;
}

/**
 * Identifies exactly one "MetadataWebStatement" node.
 */
export interface MetadataWebStatementWhereUniqueInput {
  id: number;
}

export interface MetadataWebStatementWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataWebStatementNestedPhotoCreateInput;
  metadata: MetadataWebStatementNestedMetadataCreateInput;
}

export interface MetadataWebStatementWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataWebStatementNestedPhotoUpdateInput | null;
  metadata?: MetadataWebStatementNestedMetadataUpdateInput | null;
}

export interface MetadataWebStatementWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataWebStatementNestedPhotoCreateInput;
  archive: MetadataWebStatementNestedArchiveCreateInput;
}

export interface MetadataWebStatementWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataWebStatementNestedPhotoUpdateInput | null;
  archive?: MetadataWebStatementNestedArchiveUpdateInput | null;
}

export interface MetadataWebStatementWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataWebStatementNestedMetadataCreateInput;
  archive: MetadataWebStatementNestedArchiveCreateInput;
}

export interface MetadataWebStatementWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataWebStatementNestedMetadataUpdateInput | null;
  archive?: MetadataWebStatementNestedArchiveUpdateInput | null;
}

/**
 * Filters the "Metadata" nodes by specifying some conditions
 */
export interface MetadataWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  name?: MetadataNameEnumType | null;
  name_not?: MetadataNameEnumType | null;
  name_in?: MetadataNameEnumType[] | null;
  name_not_in?: MetadataNameEnumType[] | null;
  legacyName?: string | null;
  legacyName_not?: string | null;
  legacyName_is_null?: boolean | null;
  legacyName_in?: (string | null)[] | null;
  legacyName_not_in?: (string | null)[] | null;
  legacyName_contains?: string | null;
  legacyName_not_contains?: string | null;
  legacyName_starts_with?: string | null;
  legacyName_not_starts_with?: string | null;
  legacyName_ends_with?: string | null;
  legacyName_not_ends_with?: string | null;
  legacyName_lt?: string | null;
  legacyName_lte?: string | null;
  legacyName_gt?: string | null;
  legacyName_gte?: string | null;
  label?: string | null;
  label_not?: string | null;
  label_in?: string[] | null;
  label_not_in?: string[] | null;
  label_contains?: string | null;
  label_not_contains?: string | null;
  label_starts_with?: string | null;
  label_not_starts_with?: string | null;
  label_ends_with?: string | null;
  label_not_ends_with?: string | null;
  label_lt?: string | null;
  label_lte?: string | null;
  label_gt?: string | null;
  label_gte?: string | null;
  namespace?: string | null;
  namespace_not?: string | null;
  namespace_in?: string[] | null;
  namespace_not_in?: string[] | null;
  namespace_contains?: string | null;
  namespace_not_contains?: string | null;
  namespace_starts_with?: string | null;
  namespace_not_starts_with?: string | null;
  namespace_ends_with?: string | null;
  namespace_not_ends_with?: string | null;
  namespace_lt?: string | null;
  namespace_lte?: string | null;
  namespace_gt?: string | null;
  namespace_gte?: string | null;
  structName?: string | null;
  structName_not?: string | null;
  structName_is_null?: boolean | null;
  structName_in?: (string | null)[] | null;
  structName_not_in?: (string | null)[] | null;
  structName_contains?: string | null;
  structName_not_contains?: string | null;
  structName_starts_with?: string | null;
  structName_not_starts_with?: string | null;
  structName_ends_with?: string | null;
  structName_not_ends_with?: string | null;
  structName_lt?: string | null;
  structName_lte?: string | null;
  structName_gt?: string | null;
  structName_gte?: string | null;
  type?: MetadataValueTypeEnum | null;
  type_not?: MetadataValueTypeEnum | null;
  type_in?: MetadataValueTypeEnum[] | null;
  type_not_in?: MetadataValueTypeEnum[] | null;
  photoMetadatas_some?: PhotoMetadataWhereInput | null;
  metadataSerialNumberList_some?: MetadataSerialNumberWhereInput | null;
  metadataAttributionNameList_some?: MetadataAttributionNameWhereInput | null;
  metadataAttributionUrlList_some?: MetadataAttributionUrlWhereInput | null;
  metadataLicenseList_some?: MetadataLicenseWhereInput | null;
  metadataMorePermissionsList_some?: MetadataMorePermissionsWhereInput | null;
  metadataPaintBasedCorrectionsList_some?: MetadataPaintBasedCorrectionsWhereInput | null;
  metadataToneCurveList_some?: MetadataToneCurveWhereInput | null;
  metadataCreatorList_some?: MetadataCreatorWhereInput | null;
  metadataDescriptionList_some?: MetadataDescriptionWhereInput | null;
  metadataFormatList_some?: MetadataFormatWhereInput | null;
  metadataPageList_some?: MetadataPageWhereInput | null;
  metadataPublisherList_some?: MetadataPublisherWhereInput | null;
  metadataRightsList_some?: MetadataRightsWhereInput | null;
  metadataSubjectList_some?: MetadataSubjectWhereInput | null;
  metadataTitleList_some?: MetadataTitleWhereInput | null;
  metadataTypeList_some?: MetadataTypeWhereInput | null;
  metadataAccessRightsList_some?: MetadataAccessRightsWhereInput | null;
  metadataAudienceList_some?: MetadataAudienceWhereInput | null;
  metadataValidList_some?: MetadataValidWhereInput | null;
  metadataEquipmentInstitutionList_some?: MetadataEquipmentInstitutionWhereInput | null;
  metadataEquipmentManufacturerList_some?: MetadataEquipmentManufacturerWhereInput | null;
  metadataFileInfoBitsPerPixelList_some?: MetadataFileInfoBitsPerPixelWhereInput | null;
  metadataFileInfoFramesList_some?: MetadataFileInfoFramesWhereInput | null;
  metadataFileInfoPhotometricInterpretationList_some?: MetadataFileInfoPhotometricInterpretationWhereInput | null;
  metadataFileInfoTransferSyntaxList_some?: MetadataFileInfoTransferSyntaxWhereInput | null;
  metadataPatientDobList_some?: MetadataPatientDobWhereInput | null;
  metadataPatientIdList_some?: MetadataPatientIdWhereInput | null;
  metadataPatientNameList_some?: MetadataPatientNameWhereInput | null;
  metadataPatientSexList_some?: MetadataPatientSexWhereInput | null;
  metadataSeriesDateTimeList_some?: MetadataSeriesDateTimeWhereInput | null;
  metadataSeriesDescriptionList_some?: MetadataSeriesDescriptionWhereInput | null;
  metadataSeriesModalityList_some?: MetadataSeriesModalityWhereInput | null;
  metadataSeriesNumberList_some?: MetadataSeriesNumberWhereInput | null;
  metadataStudyDateTimeList_some?: MetadataStudyDateTimeWhereInput | null;
  metadataStudyDescriptionList_some?: MetadataStudyDescriptionWhereInput | null;
  metadataStudyIdList_some?: MetadataStudyIdWhereInput | null;
  metadataStudyPhysicianList_some?: MetadataStudyPhysicianWhereInput | null;
  metadataDateTimeDigitizedList_some?: MetadataDateTimeDigitizedWhereInput | null;
  metadataDateTimeOriginalList_some?: MetadataDateTimeOriginalWhereInput | null;
  metadataGpsLatitudeList_some?: MetadataGpsLatitudeWhereInput | null;
  metadataGpsLongitudeList_some?: MetadataGpsLongitudeWhereInput | null;
  metadataGpsProcessingMethodList_some?: MetadataGpsProcessingMethodWhereInput | null;
  metadataImageUniqueIdList_some?: MetadataImageUniqueIdWhereInput | null;
  metadataIsoSpeedRatingsList_some?: MetadataIsoSpeedRatingsWhereInput | null;
  metadataUserCommentList_some?: MetadataUserCommentWhereInput | null;
  metadataCustomField14List_some?: MetadataCustomField14WhereInput | null;
  metadataCustomField15List_some?: MetadataCustomField15WhereInput | null;
  metadataCustomField16List_some?: MetadataCustomField16WhereInput | null;
  metadataCustomField17List_some?: MetadataCustomField17WhereInput | null;
  metadataCustomField20List_some?: MetadataCustomField20WhereInput | null;
  metadataCustomField5List_some?: MetadataCustomField5WhereInput | null;
  metadataCustomField6List_some?: MetadataCustomField6WhereInput | null;
  metadataCustomField7List_some?: MetadataCustomField7WhereInput | null;
  metadataCustomField9List_some?: MetadataCustomField9WhereInput | null;
  metadataCreatedTimeList_some?: MetadataCreatedTimeWhereInput | null;
  metadataEditStatusList_some?: MetadataEditStatusWhereInput | null;
  metadataFixtureIdentifierList_some?: MetadataFixtureIdentifierWhereInput | null;
  metadataLocalCaptionList_some?: MetadataLocalCaptionWhereInput | null;
  metadataProgramVersionList_some?: MetadataProgramVersionWhereInput | null;
  metadataReferenceDateList_some?: MetadataReferenceDateWhereInput | null;
  metadataReferenceNumberList_some?: MetadataReferenceNumberWhereInput | null;
  metadataReferenceServiceList_some?: MetadataReferenceServiceWhereInput | null;
  metadataReleaseDateList_some?: MetadataReleaseDateWhereInput | null;
  metadataReleaseTimeList_some?: MetadataReleaseTimeWhereInput | null;
  metadataAssetStateList_some?: MetadataAssetStateWhereInput | null;
  metadataClassifyList_some?: MetadataClassifyWhereInput | null;
  metadataContainedInList_some?: MetadataContainedInWhereInput | null;
  metadataContentValueList_some?: MetadataContentValueWhereInput | null;
  metadataDocumentTextList_some?: MetadataDocumentTextWhereInput | null;
  metadataExifList_some?: MetadataExifWhereInput | null;
  metadataHistoryList_some?: MetadataHistoryWhereInput | null;
  metadataImageNotesList_some?: MetadataImageNotesWhereInput | null;
  metadataJobIdList_some?: MetadataJobIdWhereInput | null;
  metadataLinksList_some?: MetadataLinksWhereInput | null;
  metadataMasterDocumentIdList_some?: MetadataMasterDocumentIdWhereInput | null;
  metadataObjectCycleList_some?: MetadataObjectCycleWhereInput | null;
  metadataOwnerIdList_some?: MetadataOwnerIdWhereInput | null;
  metadataShortUniqueIdList_some?: MetadataShortUniqueIdWhereInput | null;
  metadataTransferredByList_some?: MetadataTransferredByWhereInput | null;
  metadataTransferredByEmailList_some?: MetadataTransferredByEmailWhereInput | null;
  metadataTransferredByFullNameList_some?: MetadataTransferredByFullNameWhereInput | null;
  metadataUniqueIdList_some?: MetadataUniqueIdWhereInput | null;
  metadataUploadedByList_some?: MetadataUploadedByWhereInput | null;
  metadataUploadedByFullNameList_some?: MetadataUploadedByFullNameWhereInput | null;
  metadataUploadTimeList_some?: MetadataUploadTimeWhereInput | null;
  metadataUserDefined195List_some?: MetadataUserDefined195WhereInput | null;
  metadataUserDefined237List_some?: MetadataUserDefined237WhereInput | null;
  metadataUserDefined238List_some?: MetadataUserDefined238WhereInput | null;
  metadataUserDefined239List_some?: MetadataUserDefined239WhereInput | null;
  metadataUserDefined242List_some?: MetadataUserDefined242WhereInput | null;
  metadataUserDefined62List_some?: MetadataUserDefined62WhereInput | null;
  metadataCiAdrCityList_some?: MetadataCiAdrCityWhereInput | null;
  metadataCiAdrCtryList_some?: MetadataCiAdrCtryWhereInput | null;
  metadataCiAdrExtadrList_some?: MetadataCiAdrExtadrWhereInput | null;
  metadataCiAdrPcodeList_some?: MetadataCiAdrPcodeWhereInput | null;
  metadataCiAdrRegionList_some?: MetadataCiAdrRegionWhereInput | null;
  metadataCiEmailWorkList_some?: MetadataCiEmailWorkWhereInput | null;
  metadataCiTelWorkList_some?: MetadataCiTelWorkWhereInput | null;
  metadataCiUrlWorkList_some?: MetadataCiUrlWorkWhereInput | null;
  metadataCountryCodeList_some?: MetadataCountryCodeWhereInput | null;
  metadataIntellectualGenreList_some?: MetadataIntellectualGenreWhereInput | null;
  metadataLocationList_some?: MetadataLocationWhereInput | null;
  metadataSceneList_some?: MetadataSceneWhereInput | null;
  metadataSubjectCodeList_some?: MetadataSubjectCodeWhereInput | null;
  metadataAddlModelInfoList_some?: MetadataAddlModelInfoWhereInput | null;
  metadataEventList_some?: MetadataEventWhereInput | null;
  metadataMaxAvailHeightList_some?: MetadataMaxAvailHeightWhereInput | null;
  metadataMaxAvailWidthList_some?: MetadataMaxAvailWidthWhereInput | null;
  metadataModelAgeList_some?: MetadataModelAgeWhereInput | null;
  metadataOrganisationInImageNameList_some?: MetadataOrganisationInImageNameWhereInput | null;
  metadataPersonInImageList_some?: MetadataPersonInImageWhereInput | null;
  metadataAuthorsPositionList_some?: MetadataAuthorsPositionWhereInput | null;
  metadataCaptionWriterList_some?: MetadataCaptionWriterWhereInput | null;
  metadataCategoryList_some?: MetadataCategoryWhereInput | null;
  metadataCityList_some?: MetadataCityWhereInput | null;
  metadataCountryList_some?: MetadataCountryWhereInput | null;
  metadataCreditList_some?: MetadataCreditWhereInput | null;
  metadataDateCreatedList_some?: MetadataDateCreatedWhereInput | null;
  metadataHeadlineList_some?: MetadataHeadlineWhereInput | null;
  metadataIccProfileList_some?: MetadataIccProfileWhereInput | null;
  metadataInstructionsList_some?: MetadataInstructionsWhereInput | null;
  metadataSourceList_some?: MetadataSourceWhereInput | null;
  metadataStateList_some?: MetadataStateWhereInput | null;
  metadataSupplementalCategoriesList_some?: MetadataSupplementalCategoriesWhereInput | null;
  metadataTransmissionReferenceList_some?: MetadataTransmissionReferenceWhereInput | null;
  metadataUrgencyList_some?: MetadataUrgencyWhereInput | null;
  metadataMinorModelAgeDisclosureList_some?: MetadataMinorModelAgeDisclosureWhereInput | null;
  metadataModelReleaseIdList_some?: MetadataModelReleaseIdWhereInput | null;
  metadataModelReleaseStatusList_some?: MetadataModelReleaseStatusWhereInput | null;
  metadataPropertyReleaseIdList_some?: MetadataPropertyReleaseIdWhereInput | null;
  metadataPropertyReleaseStatusList_some?: MetadataPropertyReleaseStatusWhereInput | null;
  metadataVersionList_some?: MetadataVersionWhereInput | null;
  metadataBrandList_some?: MetadataBrandWhereInput | null;
  metadataDivisionList_some?: MetadataDivisionWhereInput | null;
  metadataHeaderList_some?: MetadataHeaderWhereInput | null;
  metadataProductsList_some?: MetadataProductsWhereInput | null;
  metadataProduitsList_some?: MetadataProduitsWhereInput | null;
  metadataPublishingIssueList_some?: MetadataPublishingIssueWhereInput | null;
  metadataPublishingSubjectList_some?: MetadataPublishingSubjectWhereInput | null;
  metadataCompanyList_some?: MetadataCompanyWhereInput | null;
  metadataCompanyShortNameList_some?: MetadataCompanyShortNameWhereInput | null;
  metadataConfidentialityList_some?: MetadataConfidentialityWhereInput | null;
  metadataEditorialVersionList_some?: MetadataEditorialVersionWhereInput | null;
  metadataFileCheckForList_some?: MetadataFileCheckForWhereInput | null;
  metadataFileStageList_some?: MetadataFileStageWhereInput | null;
  metadataFileStatusList_some?: MetadataFileStatusWhereInput | null;
  metadataSentToList_some?: MetadataSentToWhereInput | null;
  metadataTargetVersionList_some?: MetadataTargetVersionWhereInput | null;
  metadataUnitList_some?: MetadataUnitWhereInput | null;
  metadataArchiveChildList_some?: MetadataArchiveChildWhereInput | null;
  metadataArchiveParentList_some?: MetadataArchiveParentWhereInput | null;
  metadataBackupNameList_some?: MetadataBackupNameWhereInput | null;
  metadataBitmapGrayscalePictureList_some?: MetadataBitmapGrayscalePictureWhereInput | null;
  metadataBrandPrismaList_some?: MetadataBrandPrismaWhereInput | null;
  metadataBrandShortNameList_some?: MetadataBrandShortNameWhereInput | null;
  metadataCategoryPrismaList_some?: MetadataCategoryPrismaWhereInput | null;
  metadataContentMediaKindList_some?: MetadataContentMediaKindWhereInput | null;
  metadataCopyrightLayerList_some?: MetadataCopyrightLayerWhereInput | null;
  metadataDeliveryAccountList_some?: MetadataDeliveryAccountWhereInput | null;
  metadataDeliveryCompanyList_some?: MetadataDeliveryCompanyWhereInput | null;
  metadataDeliveryCopyrightList_some?: MetadataDeliveryCopyrightWhereInput | null;
  metadataDeliveryDateFolderList_some?: MetadataDeliveryDateFolderWhereInput | null;
  metadataDeliveryDateTimeList_some?: MetadataDeliveryDateTimeWhereInput | null;
  metadataDeliveryDcCreatorList_some?: MetadataDeliveryDcCreatorWhereInput | null;
  metadataDeliveryDcRightsList_some?: MetadataDeliveryDcRightsWhereInput | null;
  metadataDeliveryFileTypeList_some?: MetadataDeliveryFileTypeWhereInput | null;
  metadataDeliveryFolderList_some?: MetadataDeliveryFolderWhereInput | null;
  metadataDeliveryKindList_some?: MetadataDeliveryKindWhereInput | null;
  metadataDeliveryPathList_some?: MetadataDeliveryPathWhereInput | null;
  metadataDeliveryPersonShownintheImageList_some?: MetadataDeliveryPersonShownintheImageWhereInput | null;
  metadataDeliveryPhotoshopCreditList_some?: MetadataDeliveryPhotoshopCreditWhereInput | null;
  metadataDeliveryPhotoshopSourceList_some?: MetadataDeliveryPhotoshopSourceWhereInput | null;
  metadataDeliveryServiceList_some?: MetadataDeliveryServiceWhereInput | null;
  metadataDeliverySubjectList_some?: MetadataDeliverySubjectWhereInput | null;
  metadataDeliveryUnderSubjectList_some?: MetadataDeliveryUnderSubjectWhereInput | null;
  metadataDepartmentList_some?: MetadataDepartmentWhereInput | null;
  metadataDigitalAssetUrlList_some?: MetadataDigitalAssetUrlWhereInput | null;
  metadataEditionList_some?: MetadataEditionWhereInput | null;
  metadataEnvironnementPhotoList_some?: MetadataEnvironnementPhotoWhereInput | null;
  metadataFabStorageList_some?: MetadataFabStorageWhereInput | null;
  metadataFileTypeList_some?: MetadataFileTypeWhereInput | null;
  metadataHeaderPrismaList_some?: MetadataHeaderPrismaWhereInput | null;
  metadataIdSubjectList_some?: MetadataIdSubjectWhereInput | null;
  metadataIdAssignmentList_some?: MetadataIdAssignmentWhereInput | null;
  metadataIdMediaContentList_some?: MetadataIdMediaContentWhereInput | null;
  metadataIdPhotoPrismaList_some?: MetadataIdPhotoPrismaWhereInput | null;
  metadataIssueList_some?: MetadataIssueWhereInput | null;
  metadataJobProcessingList_some?: MetadataJobProcessingWhereInput | null;
  metadataLayoutPictureEditingList_some?: MetadataLayoutPictureEditingWhereInput | null;
  metadataLayoutStorageList_some?: MetadataLayoutStorageWhereInput | null;
  metadataOriginalColorList_some?: MetadataOriginalColorWhereInput | null;
  metadataOriginalIccProfileList_some?: MetadataOriginalIccProfileWhereInput | null;
  metadataOriginalNameList_some?: MetadataOriginalNameWhereInput | null;
  metadataOriginalWeigthList_some?: MetadataOriginalWeigthWhereInput | null;
  metadataPagePrismaList_some?: MetadataPagePrismaWhereInput | null;
  metadataPhotoStorageList_some?: MetadataPhotoStorageWhereInput | null;
  metadataPrepressPictureEditingList_some?: MetadataPrepressPictureEditingWhereInput | null;
  metadataPriceLevelList_some?: MetadataPriceLevelWhereInput | null;
  metadataPrintingProfileList_some?: MetadataPrintingProfileWhereInput | null;
  metadataPrismaProductionList_some?: MetadataPrismaProductionWhereInput | null;
  metadataProcessHistoryList_some?: MetadataProcessHistoryWhereInput | null;
  metadataProcessParameterList_some?: MetadataProcessParameterWhereInput | null;
  metadataProcessStatusList_some?: MetadataProcessStatusWhereInput | null;
  metadataProductList_some?: MetadataProductWhereInput | null;
  metadataProductShortNameList_some?: MetadataProductShortNameWhereInput | null;
  metadataQualifiedUsePrismaByList_some?: MetadataQualifiedUsePrismaByWhereInput | null;
  metadataQualifiedUsePrismaDateList_some?: MetadataQualifiedUsePrismaDateWhereInput | null;
  metadataQualifiedUsePrismaDurationList_some?: MetadataQualifiedUsePrismaDurationWhereInput | null;
  metadataQualifiedUsePrismaSupportList_some?: MetadataQualifiedUsePrismaSupportWhereInput | null;
  metadataRequiredPermissionList_some?: MetadataRequiredPermissionWhereInput | null;
  metadataResolutionKindList_some?: MetadataResolutionKindWhereInput | null;
  metadataRoyaltyfreeList_some?: MetadataRoyaltyfreeWhereInput | null;
  metadataSectionList_some?: MetadataSectionWhereInput | null;
  metadataSubjectPrismaList_some?: MetadataSubjectPrismaWhereInput | null;
  metadataUndersubjectList_some?: MetadataUndersubjectWhereInput | null;
  metadataUnderUnderSubjectList_some?: MetadataUnderUnderSubjectWhereInput | null;
  metadataUnitShortNameList_some?: MetadataUnitShortNameWhereInput | null;
  metadataWorkflowKindList_some?: MetadataWorkflowKindWhereInput | null;
  metadataMakeList_some?: MetadataMakeWhereInput | null;
  metadataModelList_some?: MetadataModelWhereInput | null;
  metadataCreatorToolList_some?: MetadataCreatorToolWhereInput | null;
  metadataRatingList_some?: MetadataRatingWhereInput | null;
  metadataXmpFileStampsList_some?: MetadataXmpFileStampsWhereInput | null;
  metadataManifestList_some?: MetadataManifestWhereInput | null;
  metadataXmpHistoryList_some?: MetadataXmpHistoryWhereInput | null;
  metadataCertificateList_some?: MetadataCertificateWhereInput | null;
  metadataMarkedList_some?: MetadataMarkedWhereInput | null;
  metadataOwnerList_some?: MetadataOwnerWhereInput | null;
  metadataUsageTermsList_some?: MetadataUsageTermsWhereInput | null;
  metadataWebStatementList_some?: MetadataWebStatementWhereInput | null;
  AND?: MetadataWhereInput[] | null;
  OR?: MetadataWhereInput[] | null;
  NOT?: MetadataWhereInput | null;
}

/**
 * Identifies exactly one "Metadata" node with one of these unique values:
 * - _id
 * - id
 * - name
 */
export interface MetadataWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  name?: MetadataNameEnumType | null;
}

export interface MetadataWorkflowKindNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataWorkflowKindNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataWorkflowKindNestedUpsertArchiveCreateInput | null;
}

export interface MetadataWorkflowKindNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataWorkflowKindNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataWorkflowKindNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataWorkflowKindNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataWorkflowKindNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataWorkflowKindNestedUpsertMetadataCreateInput | null;
}

export interface MetadataWorkflowKindNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataWorkflowKindNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataWorkflowKindNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataWorkflowKindNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataWorkflowKindNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataWorkflowKindNestedUpsertPhotoCreateInput | null;
}

export interface MetadataWorkflowKindNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataWorkflowKindNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataWorkflowKindNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataWorkflowKindNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataWorkflowKindNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataWorkflowKindNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataWorkflowKindNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataWorkflowKindNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataWorkflowKindNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataWorkflowKindNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataWorkflowKindNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataWorkflowKindNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataWorkflowKindNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataWorkflowKindNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataWorkflowKindNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataWorkflowKind" nodes by specifying some conditions
 */
export interface MetadataWorkflowKindWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataWorkflowKindWhereInput[] | null;
  OR?: MetadataWorkflowKindWhereInput[] | null;
  NOT?: MetadataWorkflowKindWhereInput | null;
}

/**
 * Identifies exactly one "MetadataWorkflowKind" node.
 */
export interface MetadataWorkflowKindWhereUniqueInput {
  id: number;
}

export interface MetadataWorkflowKindWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataWorkflowKindNestedPhotoCreateInput;
  metadata: MetadataWorkflowKindNestedMetadataCreateInput;
}

export interface MetadataWorkflowKindWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataWorkflowKindNestedPhotoUpdateInput | null;
  metadata?: MetadataWorkflowKindNestedMetadataUpdateInput | null;
}

export interface MetadataWorkflowKindWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataWorkflowKindNestedPhotoCreateInput;
  archive: MetadataWorkflowKindNestedArchiveCreateInput;
}

export interface MetadataWorkflowKindWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataWorkflowKindNestedPhotoUpdateInput | null;
  archive?: MetadataWorkflowKindNestedArchiveUpdateInput | null;
}

export interface MetadataWorkflowKindWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataWorkflowKindNestedMetadataCreateInput;
  archive: MetadataWorkflowKindNestedArchiveCreateInput;
}

export interface MetadataWorkflowKindWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataWorkflowKindNestedMetadataUpdateInput | null;
  archive?: MetadataWorkflowKindNestedArchiveUpdateInput | null;
}

export interface MetadataXmpFileStampsNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataXmpFileStampsNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataXmpFileStampsNestedUpsertArchiveCreateInput | null;
}

export interface MetadataXmpFileStampsNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataXmpFileStampsNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataXmpFileStampsNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataXmpFileStampsNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataXmpFileStampsNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataXmpFileStampsNestedUpsertMetadataCreateInput | null;
}

export interface MetadataXmpFileStampsNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataXmpFileStampsNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataXmpFileStampsNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataXmpFileStampsNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataXmpFileStampsNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataXmpFileStampsNestedUpsertPhotoCreateInput | null;
}

export interface MetadataXmpFileStampsNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataXmpFileStampsNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataXmpFileStampsNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataXmpFileStampsNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataXmpFileStampsNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataXmpFileStampsNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataXmpFileStampsNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataXmpFileStampsNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataXmpFileStampsNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataXmpFileStampsNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataXmpFileStampsNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataXmpFileStampsNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataXmpFileStampsNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataXmpFileStampsNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataXmpFileStampsNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataXmpFileStamps" nodes by specifying some conditions
 */
export interface MetadataXmpFileStampsWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataXmpFileStampsWhereInput[] | null;
  OR?: MetadataXmpFileStampsWhereInput[] | null;
  NOT?: MetadataXmpFileStampsWhereInput | null;
}

/**
 * Identifies exactly one "MetadataXmpFileStamps" node.
 */
export interface MetadataXmpFileStampsWhereUniqueInput {
  id: number;
}

export interface MetadataXmpFileStampsWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataXmpFileStampsNestedPhotoCreateInput;
  metadata: MetadataXmpFileStampsNestedMetadataCreateInput;
}

export interface MetadataXmpFileStampsWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataXmpFileStampsNestedPhotoUpdateInput | null;
  metadata?: MetadataXmpFileStampsNestedMetadataUpdateInput | null;
}

export interface MetadataXmpFileStampsWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataXmpFileStampsNestedPhotoCreateInput;
  archive: MetadataXmpFileStampsNestedArchiveCreateInput;
}

export interface MetadataXmpFileStampsWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataXmpFileStampsNestedPhotoUpdateInput | null;
  archive?: MetadataXmpFileStampsNestedArchiveUpdateInput | null;
}

export interface MetadataXmpFileStampsWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataXmpFileStampsNestedMetadataCreateInput;
  archive: MetadataXmpFileStampsNestedArchiveCreateInput;
}

export interface MetadataXmpFileStampsWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataXmpFileStampsNestedMetadataUpdateInput | null;
  archive?: MetadataXmpFileStampsNestedArchiveUpdateInput | null;
}

export interface MetadataXmpHistoryNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataXmpHistoryNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataXmpHistoryNestedUpsertArchiveCreateInput | null;
}

export interface MetadataXmpHistoryNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: MetadataXmpHistoryNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: MetadataXmpHistoryNestedUpsertArchiveUpdateInput | null;
}

export interface MetadataXmpHistoryNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataXmpHistoryNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataXmpHistoryNestedUpsertMetadataCreateInput | null;
}

export interface MetadataXmpHistoryNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: MetadataXmpHistoryNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: MetadataXmpHistoryNestedUpsertMetadataUpdateInput | null;
}

export interface MetadataXmpHistoryNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataXmpHistoryNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataXmpHistoryNestedUpsertPhotoCreateInput | null;
}

export interface MetadataXmpHistoryNestedPhotoUpdateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: MetadataXmpHistoryNestedUpdatePhotoUpdateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: MetadataXmpHistoryNestedUpsertPhotoUpdateInput | null;
}

export interface MetadataXmpHistoryNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataXmpHistoryNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface MetadataXmpHistoryNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataXmpHistoryNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface MetadataXmpHistoryNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataXmpHistoryNestedUpdatePhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface MetadataXmpHistoryNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataXmpHistoryNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface MetadataXmpHistoryNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataXmpHistoryNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface MetadataXmpHistoryNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

export interface MetadataXmpHistoryNestedUpsertPhotoUpdateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "MetadataXmpHistory" nodes by specifying some conditions
 */
export interface MetadataXmpHistoryWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: MetadataXmpHistoryWhereInput[] | null;
  OR?: MetadataXmpHistoryWhereInput[] | null;
  NOT?: MetadataXmpHistoryWhereInput | null;
}

/**
 * Identifies exactly one "MetadataXmpHistory" node.
 */
export interface MetadataXmpHistoryWhereUniqueInput {
  id: number;
}

export interface MetadataXmpHistoryWithForcedArchiveCreateInput {
  value?: string | null;
  photo: MetadataXmpHistoryNestedPhotoCreateInput;
  metadata: MetadataXmpHistoryNestedMetadataCreateInput;
}

export interface MetadataXmpHistoryWithForcedArchiveUpdateInput {
  value?: string | null;
  photo?: MetadataXmpHistoryNestedPhotoUpdateInput | null;
  metadata?: MetadataXmpHistoryNestedMetadataUpdateInput | null;
}

export interface MetadataXmpHistoryWithForcedMetadataCreateInput {
  value?: string | null;
  photo: MetadataXmpHistoryNestedPhotoCreateInput;
  archive: MetadataXmpHistoryNestedArchiveCreateInput;
}

export interface MetadataXmpHistoryWithForcedMetadataUpdateInput {
  value?: string | null;
  photo?: MetadataXmpHistoryNestedPhotoUpdateInput | null;
  archive?: MetadataXmpHistoryNestedArchiveUpdateInput | null;
}

export interface MetadataXmpHistoryWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: MetadataXmpHistoryNestedMetadataCreateInput;
  archive: MetadataXmpHistoryNestedArchiveCreateInput;
}

export interface MetadataXmpHistoryWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: MetadataXmpHistoryNestedMetadataUpdateInput | null;
  archive?: MetadataXmpHistoryNestedArchiveUpdateInput | null;
}

export interface PhotoCreateInput {
  id?: string | null;
  originalName?: string | null;
  filesize?: number | null;
  height?: number | null;
  width?: number | null;
  contentType?: string | null;
  isUploaded?: boolean | null;
  isLegacy?: boolean | null;
  archive: PhotoNestedArchiveCreateInput;
  category?: PhotoNestedCategoryCreateInput | null;
  agency?: PhotoNestedAgencyCreateInput | null;
  deliverySubject?: PhotoNestedDeliverySubjectCreateInput | null;
  importQueue?: PhotoNestedImportQueueCreateInput | null;
  photoMetadatas?: PhotoNestedPhotoMetadatasCreateInput | null;
  Metadata_SerialNumber?: PhotoNestedMetadataSerialnumberCreateInput | null;
  Metadata_attributionName?: PhotoNestedMetadataAttributionnameCreateInput | null;
  Metadata_attributionURL?: PhotoNestedMetadataAttributionurlCreateInput | null;
  Metadata_license?: PhotoNestedMetadataLicenseCreateInput | null;
  Metadata_morePermissions?: PhotoNestedMetadataMorepermissionsCreateInput | null;
  Metadata_PaintBasedCorrections?: PhotoNestedMetadataPaintbasedcorrectionsCreateInput | null;
  Metadata_ToneCurve?: PhotoNestedMetadataTonecurveCreateInput | null;
  Metadata_creator?: PhotoNestedMetadataCreatorCreateInput | null;
  Metadata_description?: PhotoNestedMetadataDescriptionCreateInput | null;
  Metadata_Format?: PhotoNestedMetadataFormatCreateInput | null;
  Metadata_Page?: PhotoNestedMetadataPageCreateInput | null;
  Metadata_Publisher?: PhotoNestedMetadataPublisherCreateInput | null;
  Metadata_rights?: PhotoNestedMetadataRightsCreateInput | null;
  Metadata_subject?: PhotoNestedMetadataSubjectCreateInput | null;
  Metadata_title?: PhotoNestedMetadataTitleCreateInput | null;
  Metadata_Type?: PhotoNestedMetadataTypeCreateInput | null;
  Metadata_AccessRights?: PhotoNestedMetadataAccessrightsCreateInput | null;
  Metadata_Audience?: PhotoNestedMetadataAudienceCreateInput | null;
  Metadata_Valid?: PhotoNestedMetadataValidCreateInput | null;
  Metadata_EquipmentInstitution?: PhotoNestedMetadataEquipmentinstitutionCreateInput | null;
  Metadata_EquipmentManufacturer?: PhotoNestedMetadataEquipmentmanufacturerCreateInput | null;
  Metadata_FileInfoBitsPerPixel?: PhotoNestedMetadataFileinfobitsperpixelCreateInput | null;
  Metadata_FileInfoFrames?: PhotoNestedMetadataFileinfoframesCreateInput | null;
  Metadata_FileInfoPhotometricInterpretation?: PhotoNestedMetadataFileinfophotometricinterpretationCreateInput | null;
  Metadata_FileInfoTransferSyntax?: PhotoNestedMetadataFileinfotransfersyntaxCreateInput | null;
  Metadata_PatientDOB?: PhotoNestedMetadataPatientdobCreateInput | null;
  Metadata_PatientID?: PhotoNestedMetadataPatientidCreateInput | null;
  Metadata_PatientName?: PhotoNestedMetadataPatientnameCreateInput | null;
  Metadata_PatientSex?: PhotoNestedMetadataPatientsexCreateInput | null;
  Metadata_SeriesDateTime?: PhotoNestedMetadataSeriesdatetimeCreateInput | null;
  Metadata_SeriesDescription?: PhotoNestedMetadataSeriesdescriptionCreateInput | null;
  Metadata_SeriesModality?: PhotoNestedMetadataSeriesmodalityCreateInput | null;
  Metadata_SeriesNumber?: PhotoNestedMetadataSeriesnumberCreateInput | null;
  Metadata_StudyDateTime?: PhotoNestedMetadataStudydatetimeCreateInput | null;
  Metadata_StudyDescription?: PhotoNestedMetadataStudydescriptionCreateInput | null;
  Metadata_StudyID?: PhotoNestedMetadataStudyidCreateInput | null;
  Metadata_StudyPhysician?: PhotoNestedMetadataStudyphysicianCreateInput | null;
  Metadata_DateTimeDigitized?: PhotoNestedMetadataDatetimedigitizedCreateInput | null;
  Metadata_DateTimeOriginal?: PhotoNestedMetadataDatetimeoriginalCreateInput | null;
  Metadata_GPSLatitude?: PhotoNestedMetadataGpslatitudeCreateInput | null;
  Metadata_GPSLongitude?: PhotoNestedMetadataGpslongitudeCreateInput | null;
  Metadata_GPSProcessingMethod?: PhotoNestedMetadataGpsprocessingmethodCreateInput | null;
  Metadata_ImageUniqueID?: PhotoNestedMetadataImageuniqueidCreateInput | null;
  Metadata_ISOSpeedRatings?: PhotoNestedMetadataIsospeedratingsCreateInput | null;
  Metadata_UserComment?: PhotoNestedMetadataUsercommentCreateInput | null;
  Metadata_CustomField14?: PhotoNestedMetadataCustomfield14CreateInput | null;
  Metadata_CustomField15?: PhotoNestedMetadataCustomfield15CreateInput | null;
  Metadata_CustomField16?: PhotoNestedMetadataCustomfield16CreateInput | null;
  Metadata_CustomField17?: PhotoNestedMetadataCustomfield17CreateInput | null;
  Metadata_CustomField20?: PhotoNestedMetadataCustomfield20CreateInput | null;
  Metadata_CustomField5?: PhotoNestedMetadataCustomfield5CreateInput | null;
  Metadata_CustomField6?: PhotoNestedMetadataCustomfield6CreateInput | null;
  Metadata_CustomField7?: PhotoNestedMetadataCustomfield7CreateInput | null;
  Metadata_CustomField9?: PhotoNestedMetadataCustomfield9CreateInput | null;
  Metadata_CreatedTime?: PhotoNestedMetadataCreatedtimeCreateInput | null;
  Metadata_EditStatus?: PhotoNestedMetadataEditstatusCreateInput | null;
  Metadata_FixtureIdentifier?: PhotoNestedMetadataFixtureidentifierCreateInput | null;
  Metadata_LocalCaption?: PhotoNestedMetadataLocalcaptionCreateInput | null;
  Metadata_ProgramVersion?: PhotoNestedMetadataProgramversionCreateInput | null;
  Metadata_ReferenceDate?: PhotoNestedMetadataReferencedateCreateInput | null;
  Metadata_ReferenceNumber?: PhotoNestedMetadataReferencenumberCreateInput | null;
  Metadata_ReferenceService?: PhotoNestedMetadataReferenceserviceCreateInput | null;
  Metadata_ReleaseDate?: PhotoNestedMetadataReleasedateCreateInput | null;
  Metadata_ReleaseTime?: PhotoNestedMetadataReleasetimeCreateInput | null;
  Metadata_AssetState?: PhotoNestedMetadataAssetstateCreateInput | null;
  Metadata_Classify?: PhotoNestedMetadataClassifyCreateInput | null;
  Metadata_ContainedIn?: PhotoNestedMetadataContainedinCreateInput | null;
  Metadata_ContentValue?: PhotoNestedMetadataContentvalueCreateInput | null;
  Metadata_DocumentText?: PhotoNestedMetadataDocumenttextCreateInput | null;
  Metadata_Exif?: PhotoNestedMetadataExifCreateInput | null;
  Metadata_History?: PhotoNestedMetadataHistoryCreateInput | null;
  Metadata_ImageNotes?: PhotoNestedMetadataImagenotesCreateInput | null;
  Metadata_JobId?: PhotoNestedMetadataJobidCreateInput | null;
  Metadata_Links?: PhotoNestedMetadataLinksCreateInput | null;
  Metadata_MasterDocumentId?: PhotoNestedMetadataMasterdocumentidCreateInput | null;
  Metadata_ObjectCycle?: PhotoNestedMetadataObjectcycleCreateInput | null;
  Metadata_OwnerId?: PhotoNestedMetadataOwneridCreateInput | null;
  Metadata_ShortUniqueId?: PhotoNestedMetadataShortuniqueidCreateInput | null;
  Metadata_TransferredBy?: PhotoNestedMetadataTransferredbyCreateInput | null;
  Metadata_TransferredByEmail?: PhotoNestedMetadataTransferredbyemailCreateInput | null;
  Metadata_TransferredByFullName?: PhotoNestedMetadataTransferredbyfullnameCreateInput | null;
  Metadata_UniqueId?: PhotoNestedMetadataUniqueidCreateInput | null;
  Metadata_UploadedBy?: PhotoNestedMetadataUploadedbyCreateInput | null;
  Metadata_UploadedByFullName?: PhotoNestedMetadataUploadedbyfullnameCreateInput | null;
  Metadata_UploadTime?: PhotoNestedMetadataUploadtimeCreateInput | null;
  Metadata_UserDefined195?: PhotoNestedMetadataUserdefined195CreateInput | null;
  Metadata_UserDefined237?: PhotoNestedMetadataUserdefined237CreateInput | null;
  Metadata_UserDefined238?: PhotoNestedMetadataUserdefined238CreateInput | null;
  Metadata_UserDefined239?: PhotoNestedMetadataUserdefined239CreateInput | null;
  Metadata_UserDefined242?: PhotoNestedMetadataUserdefined242CreateInput | null;
  Metadata_UserDefined62?: PhotoNestedMetadataUserdefined62CreateInput | null;
  Metadata_CiAdrCity?: PhotoNestedMetadataCiadrcityCreateInput | null;
  Metadata_CiAdrCtry?: PhotoNestedMetadataCiadrctryCreateInput | null;
  Metadata_CiAdrExtadr?: PhotoNestedMetadataCiadrextadrCreateInput | null;
  Metadata_CiAdrPcode?: PhotoNestedMetadataCiadrpcodeCreateInput | null;
  Metadata_CiAdrRegion?: PhotoNestedMetadataCiadrregionCreateInput | null;
  Metadata_CiEmailWork?: PhotoNestedMetadataCiemailworkCreateInput | null;
  Metadata_CiTelWork?: PhotoNestedMetadataCitelworkCreateInput | null;
  Metadata_CiUrlWork?: PhotoNestedMetadataCiurlworkCreateInput | null;
  Metadata_CountryCode?: PhotoNestedMetadataCountrycodeCreateInput | null;
  Metadata_IntellectualGenre?: PhotoNestedMetadataIntellectualgenreCreateInput | null;
  Metadata_Location?: PhotoNestedMetadataLocationCreateInput | null;
  Metadata_Scene?: PhotoNestedMetadataSceneCreateInput | null;
  Metadata_SubjectCode?: PhotoNestedMetadataSubjectcodeCreateInput | null;
  Metadata_AddlModelInfo?: PhotoNestedMetadataAddlmodelinfoCreateInput | null;
  Metadata_Event?: PhotoNestedMetadataEventCreateInput | null;
  Metadata_MaxAvailHeight?: PhotoNestedMetadataMaxavailheightCreateInput | null;
  Metadata_MaxAvailWidth?: PhotoNestedMetadataMaxavailwidthCreateInput | null;
  Metadata_ModelAge?: PhotoNestedMetadataModelageCreateInput | null;
  Metadata_OrganisationInImageName?: PhotoNestedMetadataOrganisationinimagenameCreateInput | null;
  Metadata_PersonInImage?: PhotoNestedMetadataPersoninimageCreateInput | null;
  Metadata_AuthorsPosition?: PhotoNestedMetadataAuthorspositionCreateInput | null;
  Metadata_CaptionWriter?: PhotoNestedMetadataCaptionwriterCreateInput | null;
  Metadata_Category?: PhotoNestedMetadataCategoryCreateInput | null;
  Metadata_City?: PhotoNestedMetadataCityCreateInput | null;
  Metadata_Country?: PhotoNestedMetadataCountryCreateInput | null;
  Metadata_Credit?: PhotoNestedMetadataCreditCreateInput | null;
  Metadata_DateCreated?: PhotoNestedMetadataDatecreatedCreateInput | null;
  Metadata_Headline?: PhotoNestedMetadataHeadlineCreateInput | null;
  Metadata_ICCProfile?: PhotoNestedMetadataIccprofileCreateInput | null;
  Metadata_Instructions?: PhotoNestedMetadataInstructionsCreateInput | null;
  Metadata_Source?: PhotoNestedMetadataSourceCreateInput | null;
  Metadata_State?: PhotoNestedMetadataStateCreateInput | null;
  Metadata_SupplementalCategories?: PhotoNestedMetadataSupplementalcategoriesCreateInput | null;
  Metadata_TransmissionReference?: PhotoNestedMetadataTransmissionreferenceCreateInput | null;
  Metadata_Urgency?: PhotoNestedMetadataUrgencyCreateInput | null;
  Metadata_MinorModelAgeDisclosure?: PhotoNestedMetadataMinormodelagedisclosureCreateInput | null;
  Metadata_ModelReleaseID?: PhotoNestedMetadataModelreleaseidCreateInput | null;
  Metadata_ModelReleaseStatus?: PhotoNestedMetadataModelreleasestatusCreateInput | null;
  Metadata_PropertyReleaseID?: PhotoNestedMetadataPropertyreleaseidCreateInput | null;
  Metadata_PropertyReleaseStatus?: PhotoNestedMetadataPropertyreleasestatusCreateInput | null;
  Metadata_Version?: PhotoNestedMetadataVersionCreateInput | null;
  Metadata_Brand?: PhotoNestedMetadataBrandCreateInput | null;
  Metadata_Division?: PhotoNestedMetadataDivisionCreateInput | null;
  Metadata_Header?: PhotoNestedMetadataHeaderCreateInput | null;
  Metadata_Products?: PhotoNestedMetadataProductsCreateInput | null;
  Metadata_Produits?: PhotoNestedMetadataProduitsCreateInput | null;
  Metadata_publishingIssue?: PhotoNestedMetadataPublishingissueCreateInput | null;
  Metadata_publishingSubject?: PhotoNestedMetadataPublishingsubjectCreateInput | null;
  Metadata_Company?: PhotoNestedMetadataCompanyCreateInput | null;
  Metadata_CompanyShortName?: PhotoNestedMetadataCompanyshortnameCreateInput | null;
  Metadata_Confidentiality?: PhotoNestedMetadataConfidentialityCreateInput | null;
  Metadata_EditorialVersion?: PhotoNestedMetadataEditorialversionCreateInput | null;
  Metadata_FileCheckFor?: PhotoNestedMetadataFilecheckforCreateInput | null;
  Metadata_FileStage?: PhotoNestedMetadataFilestageCreateInput | null;
  Metadata_FileStatus?: PhotoNestedMetadataFilestatusCreateInput | null;
  Metadata_SentTo?: PhotoNestedMetadataSenttoCreateInput | null;
  Metadata_TargetVersion?: PhotoNestedMetadataTargetversionCreateInput | null;
  Metadata_Unit?: PhotoNestedMetadataUnitCreateInput | null;
  Metadata_Archive_child?: PhotoNestedMetadataArchiveChildCreateInput | null;
  Metadata_Archive_parent?: PhotoNestedMetadataArchiveParentCreateInput | null;
  Metadata_BackupName?: PhotoNestedMetadataBackupnameCreateInput | null;
  Metadata_BitmapGrayscalePicture?: PhotoNestedMetadataBitmapgrayscalepictureCreateInput | null;
  Metadata_BrandPrisma?: PhotoNestedMetadataBrandprismaCreateInput | null;
  Metadata_BrandShortName?: PhotoNestedMetadataBrandshortnameCreateInput | null;
  Metadata_CategoryPrisma?: PhotoNestedMetadataCategoryprismaCreateInput | null;
  Metadata_ContentMediaKind?: PhotoNestedMetadataContentmediakindCreateInput | null;
  Metadata_CopyrightLayer?: PhotoNestedMetadataCopyrightlayerCreateInput | null;
  Metadata_DeliveryAccount?: PhotoNestedMetadataDeliveryaccountCreateInput | null;
  Metadata_DeliveryCompany?: PhotoNestedMetadataDeliverycompanyCreateInput | null;
  Metadata_DeliveryCopyright?: PhotoNestedMetadataDeliverycopyrightCreateInput | null;
  Metadata_DeliveryDateFolder?: PhotoNestedMetadataDeliverydatefolderCreateInput | null;
  Metadata_DeliveryDateTime?: PhotoNestedMetadataDeliverydatetimeCreateInput | null;
  Metadata_DeliveryDcCreator?: PhotoNestedMetadataDeliverydccreatorCreateInput | null;
  Metadata_DeliveryDcRights?: PhotoNestedMetadataDeliverydcrightsCreateInput | null;
  Metadata_DeliveryFileType?: PhotoNestedMetadataDeliveryfiletypeCreateInput | null;
  Metadata_DeliveryFolder?: PhotoNestedMetadataDeliveryfolderCreateInput | null;
  Metadata_DeliveryKind?: PhotoNestedMetadataDeliverykindCreateInput | null;
  Metadata_DeliveryPath?: PhotoNestedMetadataDeliverypathCreateInput | null;
  Metadata_DeliveryPersonShownintheImage?: PhotoNestedMetadataDeliverypersonshownintheimageCreateInput | null;
  Metadata_DeliveryPhotoshopCredit?: PhotoNestedMetadataDeliveryphotoshopcreditCreateInput | null;
  Metadata_DeliveryPhotoshopSource?: PhotoNestedMetadataDeliveryphotoshopsourceCreateInput | null;
  Metadata_DeliveryService?: PhotoNestedMetadataDeliveryserviceCreateInput | null;
  Metadata_DeliverySubject?: PhotoNestedMetadataDeliverysubjectCreateInput | null;
  Metadata_DeliveryUnderSubject?: PhotoNestedMetadataDeliveryundersubjectCreateInput | null;
  Metadata_Department?: PhotoNestedMetadataDepartmentCreateInput | null;
  Metadata_DigitalAssetURL?: PhotoNestedMetadataDigitalasseturlCreateInput | null;
  Metadata_Edition?: PhotoNestedMetadataEditionCreateInput | null;
  Metadata_EnvironnementPhoto?: PhotoNestedMetadataEnvironnementphotoCreateInput | null;
  Metadata_FabStorage?: PhotoNestedMetadataFabstorageCreateInput | null;
  Metadata_FileType?: PhotoNestedMetadataFiletypeCreateInput | null;
  Metadata_HeaderPrisma?: PhotoNestedMetadataHeaderprismaCreateInput | null;
  Metadata_ID_subject?: PhotoNestedMetadataIdSubjectCreateInput | null;
  Metadata_IdAssignment?: PhotoNestedMetadataIdassignmentCreateInput | null;
  Metadata_IdMediaContent?: PhotoNestedMetadataIdmediacontentCreateInput | null;
  Metadata_IdPhotoPrisma?: PhotoNestedMetadataIdphotoprismaCreateInput | null;
  Metadata_Issue?: PhotoNestedMetadataIssueCreateInput | null;
  Metadata_JobProcessing?: PhotoNestedMetadataJobprocessingCreateInput | null;
  Metadata_LayoutPictureEditing?: PhotoNestedMetadataLayoutpictureeditingCreateInput | null;
  Metadata_LayoutStorage?: PhotoNestedMetadataLayoutstorageCreateInput | null;
  Metadata_OriginalColor?: PhotoNestedMetadataOriginalcolorCreateInput | null;
  Metadata_OriginalICCProfile?: PhotoNestedMetadataOriginaliccprofileCreateInput | null;
  Metadata_OriginalName?: PhotoNestedMetadataOriginalnameCreateInput | null;
  Metadata_OriginalWeigth?: PhotoNestedMetadataOriginalweigthCreateInput | null;
  Metadata_PagePrisma?: PhotoNestedMetadataPageprismaCreateInput | null;
  Metadata_PhotoStorage?: PhotoNestedMetadataPhotostorageCreateInput | null;
  Metadata_PrepressPictureEditing?: PhotoNestedMetadataPrepresspictureeditingCreateInput | null;
  Metadata_PriceLevel?: PhotoNestedMetadataPricelevelCreateInput | null;
  Metadata_PrintingProfile?: PhotoNestedMetadataPrintingprofileCreateInput | null;
  Metadata_PrismaProduction?: PhotoNestedMetadataPrismaproductionCreateInput | null;
  Metadata_ProcessHistory?: PhotoNestedMetadataProcesshistoryCreateInput | null;
  Metadata_ProcessParameter?: PhotoNestedMetadataProcessparameterCreateInput | null;
  Metadata_ProcessStatus?: PhotoNestedMetadataProcessstatusCreateInput | null;
  Metadata_Product?: PhotoNestedMetadataProductCreateInput | null;
  Metadata_ProductShortName?: PhotoNestedMetadataProductshortnameCreateInput | null;
  Metadata_QualifiedUsePrismaBy?: PhotoNestedMetadataQualifieduseprismabyCreateInput | null;
  Metadata_QualifiedUsePrismaDate?: PhotoNestedMetadataQualifieduseprismadateCreateInput | null;
  Metadata_QualifiedUsePrismaDuration?: PhotoNestedMetadataQualifieduseprismadurationCreateInput | null;
  Metadata_QualifiedUsePrismaSupport?: PhotoNestedMetadataQualifieduseprismasupportCreateInput | null;
  Metadata_RequiredPermission?: PhotoNestedMetadataRequiredpermissionCreateInput | null;
  Metadata_ResolutionKind?: PhotoNestedMetadataResolutionkindCreateInput | null;
  Metadata_Royaltyfree?: PhotoNestedMetadataRoyaltyfreeCreateInput | null;
  Metadata_Section?: PhotoNestedMetadataSectionCreateInput | null;
  Metadata_SubjectPrisma?: PhotoNestedMetadataSubjectprismaCreateInput | null;
  Metadata_Undersubject?: PhotoNestedMetadataUndersubjectCreateInput | null;
  Metadata_UnderUnderSubject?: PhotoNestedMetadataUnderundersubjectCreateInput | null;
  Metadata_UnitShortName?: PhotoNestedMetadataUnitshortnameCreateInput | null;
  Metadata_WorkflowKind?: PhotoNestedMetadataWorkflowkindCreateInput | null;
  Metadata_Make?: PhotoNestedMetadataMakeCreateInput | null;
  Metadata_Model?: PhotoNestedMetadataModelCreateInput | null;
  Metadata_CreatorTool?: PhotoNestedMetadataCreatortoolCreateInput | null;
  Metadata_Rating?: PhotoNestedMetadataRatingCreateInput | null;
  Metadata_XMPFileStamps?: PhotoNestedMetadataXmpfilestampsCreateInput | null;
  Metadata_Manifest?: PhotoNestedMetadataManifestCreateInput | null;
  Metadata_XMPHistory?: PhotoNestedMetadataXmphistoryCreateInput | null;
  Metadata_Certificate?: PhotoNestedMetadataCertificateCreateInput | null;
  Metadata_Marked?: PhotoNestedMetadataMarkedCreateInput | null;
  Metadata_Owner?: PhotoNestedMetadataOwnerCreateInput | null;
  Metadata_UsageTerms?: PhotoNestedMetadataUsagetermsCreateInput | null;
  Metadata_WebStatement?: PhotoNestedMetadataWebstatementCreateInput | null;
}

export interface PhotoMetadataNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: PhotoMetadataNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: PhotoMetadataNestedUpsertArchiveCreateInput | null;
}

export interface PhotoMetadataNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: PhotoMetadataNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: PhotoMetadataNestedUpsertArchiveUpdateInput | null;
}

export interface PhotoMetadataNestedMetadataCreateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: PhotoMetadataNestedUpdateMetadataCreateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: PhotoMetadataNestedUpsertMetadataCreateInput | null;
}

export interface PhotoMetadataNestedMetadataUpdateInput {
  connect?: MetadataWhereUniqueInput | null;
  update?: PhotoMetadataNestedUpdateMetadataUpdateInput | null;
  create?: MetadataCreateInput | null;
  upsert?: PhotoMetadataNestedUpsertMetadataUpdateInput | null;
}

export interface PhotoMetadataNestedPhotoCreateInput {
  connect?: PhotoWhereUniqueInput | null;
  update?: PhotoMetadataNestedUpdatePhotoCreateInput | null;
  create?: PhotoCreateInput | null;
  upsert?: PhotoMetadataNestedUpsertPhotoCreateInput | null;
}

export interface PhotoMetadataNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface PhotoMetadataNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface PhotoMetadataNestedUpdateMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface PhotoMetadataNestedUpdateMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  data: MetadataUpdateInput;
}

export interface PhotoMetadataNestedUpdatePhotoCreateInput {
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}

export interface PhotoMetadataNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface PhotoMetadataNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface PhotoMetadataNestedUpsertMetadataCreateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface PhotoMetadataNestedUpsertMetadataUpdateInput {
  where: MetadataWhereUniqueInput;
  update: MetadataUpdateInput;
  create: MetadataCreateInput;
}

export interface PhotoMetadataNestedUpsertPhotoCreateInput {
  where: PhotoWhereUniqueInput;
  update: PhotoUpdateInput;
  create: PhotoCreateInput;
}

/**
 * Filters the "PhotoMetadata" nodes by specifying some conditions
 */
export interface PhotoMetadataWhereInput {
  id?: number | null;
  id_not?: number | null;
  id_in?: number[] | null;
  id_not_in?: number[] | null;
  id_lt?: number | null;
  id_lte?: number | null;
  id_gt?: number | null;
  id_gte?: number | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  value?: string | null;
  value_not?: string | null;
  value_is_null?: boolean | null;
  value_in?: (string | null)[] | null;
  value_not_in?: (string | null)[] | null;
  value_contains?: string | null;
  value_not_contains?: string | null;
  value_starts_with?: string | null;
  value_not_starts_with?: string | null;
  value_ends_with?: string | null;
  value_not_ends_with?: string | null;
  value_lt?: string | null;
  value_lte?: string | null;
  value_gt?: string | null;
  value_gte?: string | null;
  photo?: PhotoWhereInput | null;
  metadata?: MetadataWhereInput | null;
  archive?: ArchiveWhereInput | null;
  AND?: PhotoMetadataWhereInput[] | null;
  OR?: PhotoMetadataWhereInput[] | null;
  NOT?: PhotoMetadataWhereInput | null;
}

/**
 * Identifies exactly one "PhotoMetadata" node.
 */
export interface PhotoMetadataWhereUniqueInput {
  id: number;
}

export interface PhotoMetadataWithForcedArchiveCreateInput {
  value?: string | null;
  photo: PhotoMetadataNestedPhotoCreateInput;
  metadata: PhotoMetadataNestedMetadataCreateInput;
}

export interface PhotoMetadataWithForcedArchiveUpdateInput {
  value?: string | null;
  metadata?: PhotoMetadataNestedMetadataUpdateInput | null;
}

export interface PhotoMetadataWithForcedMetadataCreateInput {
  value?: string | null;
  photo: PhotoMetadataNestedPhotoCreateInput;
  archive: PhotoMetadataNestedArchiveCreateInput;
}

export interface PhotoMetadataWithForcedMetadataUpdateInput {
  value?: string | null;
  archive?: PhotoMetadataNestedArchiveUpdateInput | null;
}

export interface PhotoMetadataWithForcedPhotoCreateInput {
  value?: string | null;
  metadata: PhotoMetadataNestedMetadataCreateInput;
  archive: PhotoMetadataNestedArchiveCreateInput;
}

export interface PhotoMetadataWithForcedPhotoUpdateInput {
  value?: string | null;
  metadata?: PhotoMetadataNestedMetadataUpdateInput | null;
  archive?: PhotoMetadataNestedArchiveUpdateInput | null;
}

export interface PhotoNestedAgencyCreateInput {
  connect?: AgencyWhereUniqueInput | null;
  update?: PhotoNestedUpdateAgencyCreateInput | null;
  create?: AgencyCreateInput | null;
  upsert?: PhotoNestedUpsertAgencyCreateInput | null;
}

export interface PhotoNestedAgencyUpdateInput {
  connect?: AgencyWhereUniqueInput | null;
  update?: PhotoNestedUpdateAgencyUpdateInput | null;
  create?: AgencyCreateInput | null;
  upsert?: PhotoNestedUpsertAgencyUpdateInput | null;
  disconnect?: boolean | null;
}

export interface PhotoNestedArchiveCreateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: PhotoNestedUpdateArchiveCreateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: PhotoNestedUpsertArchiveCreateInput | null;
}

export interface PhotoNestedArchiveUpdateInput {
  connect?: ArchiveWhereUniqueInput | null;
  update?: PhotoNestedUpdateArchiveUpdateInput | null;
  create?: ArchiveCreateInput | null;
  upsert?: PhotoNestedUpsertArchiveUpdateInput | null;
}

export interface PhotoNestedCategoryCreateInput {
  connect?: CategoryWhereUniqueInput | null;
  update?: PhotoNestedUpdateCategoryCreateInput | null;
  create?: CategoryCreateInput | null;
  upsert?: PhotoNestedUpsertCategoryCreateInput | null;
}

export interface PhotoNestedCategoryUpdateInput {
  connect?: CategoryWhereUniqueInput | null;
  update?: PhotoNestedUpdateCategoryUpdateInput | null;
  create?: CategoryCreateInput | null;
  upsert?: PhotoNestedUpsertCategoryUpdateInput | null;
  disconnect?: boolean | null;
}

export interface PhotoNestedDeliverySubjectCreateInput {
  connect?: DeliverySubjectWhereUniqueInput | null;
  update?: PhotoNestedUpdateDeliverySubjectCreateInput | null;
  create?: DeliverySubjectCreateInput | null;
  upsert?: PhotoNestedUpsertDeliverySubjectCreateInput | null;
}

export interface PhotoNestedDeliverySubjectUpdateInput {
  connect?: DeliverySubjectWhereUniqueInput | null;
  update?: PhotoNestedUpdateDeliverySubjectUpdateInput | null;
  create?: DeliverySubjectCreateInput | null;
  upsert?: PhotoNestedUpsertDeliverySubjectUpdateInput | null;
  disconnect?: boolean | null;
}

export interface PhotoNestedImportQueueCreateInput {
  connect?: ImportQueueWhereUniqueInput | null;
  create?: ImportQueueWithForcedPhotoCreateInput | null;
}

export interface PhotoNestedImportQueueUpdateInput {
  connect?: ImportQueueWhereUniqueInput | null;
  disconnect?: boolean | null;
  update?: PhotoNestedUpdateImportQueueUpdateInput | null;
  create?: ImportQueueWithForcedPhotoCreateInput | null;
  upsert?: PhotoNestedUpsertImportQueueUpdateInput | null;
  delete?: boolean | null;
}

export interface PhotoNestedMetadataAccessrightsCreateInput {
  connect?: MetadataAccessRightsWhereUniqueInput[] | null;
  create?: MetadataAccessRightsWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataAccessrightsUpdateInput {
  connect?: MetadataAccessRightsWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataAccessrightsUpdateInput[] | null;
  create?: MetadataAccessRightsWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataAccessrightsUpdateInput[] | null;
  delete?: MetadataAccessRightsWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataAddlmodelinfoCreateInput {
  connect?: MetadataAddlModelInfoWhereUniqueInput[] | null;
  create?: MetadataAddlModelInfoWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataAddlmodelinfoUpdateInput {
  connect?: MetadataAddlModelInfoWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataAddlmodelinfoUpdateInput[] | null;
  create?: MetadataAddlModelInfoWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataAddlmodelinfoUpdateInput[] | null;
  delete?: MetadataAddlModelInfoWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataArchiveChildCreateInput {
  connect?: MetadataArchiveChildWhereUniqueInput[] | null;
  create?: MetadataArchiveChildWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataArchiveChildUpdateInput {
  connect?: MetadataArchiveChildWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataArchiveChildUpdateInput[] | null;
  create?: MetadataArchiveChildWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataArchiveChildUpdateInput[] | null;
  delete?: MetadataArchiveChildWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataArchiveParentCreateInput {
  connect?: MetadataArchiveParentWhereUniqueInput[] | null;
  create?: MetadataArchiveParentWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataArchiveParentUpdateInput {
  connect?: MetadataArchiveParentWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataArchiveParentUpdateInput[] | null;
  create?: MetadataArchiveParentWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataArchiveParentUpdateInput[] | null;
  delete?: MetadataArchiveParentWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataAssetstateCreateInput {
  connect?: MetadataAssetStateWhereUniqueInput[] | null;
  create?: MetadataAssetStateWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataAssetstateUpdateInput {
  connect?: MetadataAssetStateWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataAssetstateUpdateInput[] | null;
  create?: MetadataAssetStateWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataAssetstateUpdateInput[] | null;
  delete?: MetadataAssetStateWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataAttributionnameCreateInput {
  connect?: MetadataAttributionNameWhereUniqueInput[] | null;
  create?: MetadataAttributionNameWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataAttributionnameUpdateInput {
  connect?: MetadataAttributionNameWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataAttributionnameUpdateInput[] | null;
  create?: MetadataAttributionNameWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataAttributionnameUpdateInput[] | null;
  delete?: MetadataAttributionNameWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataAttributionurlCreateInput {
  connect?: MetadataAttributionUrlWhereUniqueInput[] | null;
  create?: MetadataAttributionUrlWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataAttributionurlUpdateInput {
  connect?: MetadataAttributionUrlWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataAttributionurlUpdateInput[] | null;
  create?: MetadataAttributionUrlWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataAttributionurlUpdateInput[] | null;
  delete?: MetadataAttributionUrlWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataAudienceCreateInput {
  connect?: MetadataAudienceWhereUniqueInput[] | null;
  create?: MetadataAudienceWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataAudienceUpdateInput {
  connect?: MetadataAudienceWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataAudienceUpdateInput[] | null;
  create?: MetadataAudienceWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataAudienceUpdateInput[] | null;
  delete?: MetadataAudienceWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataAuthorspositionCreateInput {
  connect?: MetadataAuthorsPositionWhereUniqueInput[] | null;
  create?: MetadataAuthorsPositionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataAuthorspositionUpdateInput {
  connect?: MetadataAuthorsPositionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataAuthorspositionUpdateInput[] | null;
  create?: MetadataAuthorsPositionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataAuthorspositionUpdateInput[] | null;
  delete?: MetadataAuthorsPositionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataBackupnameCreateInput {
  connect?: MetadataBackupNameWhereUniqueInput[] | null;
  create?: MetadataBackupNameWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataBackupnameUpdateInput {
  connect?: MetadataBackupNameWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataBackupnameUpdateInput[] | null;
  create?: MetadataBackupNameWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataBackupnameUpdateInput[] | null;
  delete?: MetadataBackupNameWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataBitmapgrayscalepictureCreateInput {
  connect?: MetadataBitmapGrayscalePictureWhereUniqueInput[] | null;
  create?: MetadataBitmapGrayscalePictureWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataBitmapgrayscalepictureUpdateInput {
  connect?: MetadataBitmapGrayscalePictureWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataBitmapgrayscalepictureUpdateInput[] | null;
  create?: MetadataBitmapGrayscalePictureWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataBitmapgrayscalepictureUpdateInput[] | null;
  delete?: MetadataBitmapGrayscalePictureWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataBrandCreateInput {
  connect?: MetadataBrandWhereUniqueInput[] | null;
  create?: MetadataBrandWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataBrandUpdateInput {
  connect?: MetadataBrandWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataBrandUpdateInput[] | null;
  create?: MetadataBrandWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataBrandUpdateInput[] | null;
  delete?: MetadataBrandWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataBrandprismaCreateInput {
  connect?: MetadataBrandPrismaWhereUniqueInput[] | null;
  create?: MetadataBrandPrismaWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataBrandprismaUpdateInput {
  connect?: MetadataBrandPrismaWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataBrandprismaUpdateInput[] | null;
  create?: MetadataBrandPrismaWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataBrandprismaUpdateInput[] | null;
  delete?: MetadataBrandPrismaWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataBrandshortnameCreateInput {
  connect?: MetadataBrandShortNameWhereUniqueInput[] | null;
  create?: MetadataBrandShortNameWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataBrandshortnameUpdateInput {
  connect?: MetadataBrandShortNameWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataBrandshortnameUpdateInput[] | null;
  create?: MetadataBrandShortNameWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataBrandshortnameUpdateInput[] | null;
  delete?: MetadataBrandShortNameWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCaptionwriterCreateInput {
  connect?: MetadataCaptionWriterWhereUniqueInput[] | null;
  create?: MetadataCaptionWriterWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCaptionwriterUpdateInput {
  connect?: MetadataCaptionWriterWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCaptionwriterUpdateInput[] | null;
  create?: MetadataCaptionWriterWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCaptionwriterUpdateInput[] | null;
  delete?: MetadataCaptionWriterWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCategoryCreateInput {
  connect?: MetadataCategoryWhereUniqueInput[] | null;
  create?: MetadataCategoryWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCategoryUpdateInput {
  connect?: MetadataCategoryWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCategoryUpdateInput[] | null;
  create?: MetadataCategoryWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCategoryUpdateInput[] | null;
  delete?: MetadataCategoryWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCategoryprismaCreateInput {
  connect?: MetadataCategoryPrismaWhereUniqueInput[] | null;
  create?: MetadataCategoryPrismaWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCategoryprismaUpdateInput {
  connect?: MetadataCategoryPrismaWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCategoryprismaUpdateInput[] | null;
  create?: MetadataCategoryPrismaWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCategoryprismaUpdateInput[] | null;
  delete?: MetadataCategoryPrismaWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCertificateCreateInput {
  connect?: MetadataCertificateWhereUniqueInput[] | null;
  create?: MetadataCertificateWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCertificateUpdateInput {
  connect?: MetadataCertificateWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCertificateUpdateInput[] | null;
  create?: MetadataCertificateWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCertificateUpdateInput[] | null;
  delete?: MetadataCertificateWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCiadrcityCreateInput {
  connect?: MetadataCiAdrCityWhereUniqueInput[] | null;
  create?: MetadataCiAdrCityWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCiadrcityUpdateInput {
  connect?: MetadataCiAdrCityWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCiadrcityUpdateInput[] | null;
  create?: MetadataCiAdrCityWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCiadrcityUpdateInput[] | null;
  delete?: MetadataCiAdrCityWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCiadrctryCreateInput {
  connect?: MetadataCiAdrCtryWhereUniqueInput[] | null;
  create?: MetadataCiAdrCtryWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCiadrctryUpdateInput {
  connect?: MetadataCiAdrCtryWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCiadrctryUpdateInput[] | null;
  create?: MetadataCiAdrCtryWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCiadrctryUpdateInput[] | null;
  delete?: MetadataCiAdrCtryWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCiadrextadrCreateInput {
  connect?: MetadataCiAdrExtadrWhereUniqueInput[] | null;
  create?: MetadataCiAdrExtadrWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCiadrextadrUpdateInput {
  connect?: MetadataCiAdrExtadrWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCiadrextadrUpdateInput[] | null;
  create?: MetadataCiAdrExtadrWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCiadrextadrUpdateInput[] | null;
  delete?: MetadataCiAdrExtadrWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCiadrpcodeCreateInput {
  connect?: MetadataCiAdrPcodeWhereUniqueInput[] | null;
  create?: MetadataCiAdrPcodeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCiadrpcodeUpdateInput {
  connect?: MetadataCiAdrPcodeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCiadrpcodeUpdateInput[] | null;
  create?: MetadataCiAdrPcodeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCiadrpcodeUpdateInput[] | null;
  delete?: MetadataCiAdrPcodeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCiadrregionCreateInput {
  connect?: MetadataCiAdrRegionWhereUniqueInput[] | null;
  create?: MetadataCiAdrRegionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCiadrregionUpdateInput {
  connect?: MetadataCiAdrRegionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCiadrregionUpdateInput[] | null;
  create?: MetadataCiAdrRegionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCiadrregionUpdateInput[] | null;
  delete?: MetadataCiAdrRegionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCiemailworkCreateInput {
  connect?: MetadataCiEmailWorkWhereUniqueInput[] | null;
  create?: MetadataCiEmailWorkWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCiemailworkUpdateInput {
  connect?: MetadataCiEmailWorkWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCiemailworkUpdateInput[] | null;
  create?: MetadataCiEmailWorkWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCiemailworkUpdateInput[] | null;
  delete?: MetadataCiEmailWorkWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCitelworkCreateInput {
  connect?: MetadataCiTelWorkWhereUniqueInput[] | null;
  create?: MetadataCiTelWorkWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCitelworkUpdateInput {
  connect?: MetadataCiTelWorkWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCitelworkUpdateInput[] | null;
  create?: MetadataCiTelWorkWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCitelworkUpdateInput[] | null;
  delete?: MetadataCiTelWorkWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCityCreateInput {
  connect?: MetadataCityWhereUniqueInput[] | null;
  create?: MetadataCityWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCityUpdateInput {
  connect?: MetadataCityWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCityUpdateInput[] | null;
  create?: MetadataCityWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCityUpdateInput[] | null;
  delete?: MetadataCityWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCiurlworkCreateInput {
  connect?: MetadataCiUrlWorkWhereUniqueInput[] | null;
  create?: MetadataCiUrlWorkWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCiurlworkUpdateInput {
  connect?: MetadataCiUrlWorkWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCiurlworkUpdateInput[] | null;
  create?: MetadataCiUrlWorkWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCiurlworkUpdateInput[] | null;
  delete?: MetadataCiUrlWorkWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataClassifyCreateInput {
  connect?: MetadataClassifyWhereUniqueInput[] | null;
  create?: MetadataClassifyWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataClassifyUpdateInput {
  connect?: MetadataClassifyWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataClassifyUpdateInput[] | null;
  create?: MetadataClassifyWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataClassifyUpdateInput[] | null;
  delete?: MetadataClassifyWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCompanyCreateInput {
  connect?: MetadataCompanyWhereUniqueInput[] | null;
  create?: MetadataCompanyWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCompanyUpdateInput {
  connect?: MetadataCompanyWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCompanyUpdateInput[] | null;
  create?: MetadataCompanyWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCompanyUpdateInput[] | null;
  delete?: MetadataCompanyWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCompanyshortnameCreateInput {
  connect?: MetadataCompanyShortNameWhereUniqueInput[] | null;
  create?: MetadataCompanyShortNameWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCompanyshortnameUpdateInput {
  connect?: MetadataCompanyShortNameWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCompanyshortnameUpdateInput[] | null;
  create?: MetadataCompanyShortNameWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCompanyshortnameUpdateInput[] | null;
  delete?: MetadataCompanyShortNameWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataConfidentialityCreateInput {
  connect?: MetadataConfidentialityWhereUniqueInput[] | null;
  create?: MetadataConfidentialityWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataConfidentialityUpdateInput {
  connect?: MetadataConfidentialityWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataConfidentialityUpdateInput[] | null;
  create?: MetadataConfidentialityWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataConfidentialityUpdateInput[] | null;
  delete?: MetadataConfidentialityWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataContainedinCreateInput {
  connect?: MetadataContainedInWhereUniqueInput[] | null;
  create?: MetadataContainedInWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataContainedinUpdateInput {
  connect?: MetadataContainedInWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataContainedinUpdateInput[] | null;
  create?: MetadataContainedInWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataContainedinUpdateInput[] | null;
  delete?: MetadataContainedInWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataContentmediakindCreateInput {
  connect?: MetadataContentMediaKindWhereUniqueInput[] | null;
  create?: MetadataContentMediaKindWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataContentmediakindUpdateInput {
  connect?: MetadataContentMediaKindWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataContentmediakindUpdateInput[] | null;
  create?: MetadataContentMediaKindWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataContentmediakindUpdateInput[] | null;
  delete?: MetadataContentMediaKindWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataContentvalueCreateInput {
  connect?: MetadataContentValueWhereUniqueInput[] | null;
  create?: MetadataContentValueWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataContentvalueUpdateInput {
  connect?: MetadataContentValueWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataContentvalueUpdateInput[] | null;
  create?: MetadataContentValueWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataContentvalueUpdateInput[] | null;
  delete?: MetadataContentValueWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCopyrightlayerCreateInput {
  connect?: MetadataCopyrightLayerWhereUniqueInput[] | null;
  create?: MetadataCopyrightLayerWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCopyrightlayerUpdateInput {
  connect?: MetadataCopyrightLayerWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCopyrightlayerUpdateInput[] | null;
  create?: MetadataCopyrightLayerWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCopyrightlayerUpdateInput[] | null;
  delete?: MetadataCopyrightLayerWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCountryCreateInput {
  connect?: MetadataCountryWhereUniqueInput[] | null;
  create?: MetadataCountryWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCountryUpdateInput {
  connect?: MetadataCountryWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCountryUpdateInput[] | null;
  create?: MetadataCountryWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCountryUpdateInput[] | null;
  delete?: MetadataCountryWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCountrycodeCreateInput {
  connect?: MetadataCountryCodeWhereUniqueInput[] | null;
  create?: MetadataCountryCodeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCountrycodeUpdateInput {
  connect?: MetadataCountryCodeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCountrycodeUpdateInput[] | null;
  create?: MetadataCountryCodeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCountrycodeUpdateInput[] | null;
  delete?: MetadataCountryCodeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCreatedtimeCreateInput {
  connect?: MetadataCreatedTimeWhereUniqueInput[] | null;
  create?: MetadataCreatedTimeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCreatedtimeUpdateInput {
  connect?: MetadataCreatedTimeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCreatedtimeUpdateInput[] | null;
  create?: MetadataCreatedTimeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCreatedtimeUpdateInput[] | null;
  delete?: MetadataCreatedTimeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCreatorCreateInput {
  connect?: MetadataCreatorWhereUniqueInput[] | null;
  create?: MetadataCreatorWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCreatorUpdateInput {
  connect?: MetadataCreatorWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCreatorUpdateInput[] | null;
  create?: MetadataCreatorWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCreatorUpdateInput[] | null;
  delete?: MetadataCreatorWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCreatortoolCreateInput {
  connect?: MetadataCreatorToolWhereUniqueInput[] | null;
  create?: MetadataCreatorToolWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCreatortoolUpdateInput {
  connect?: MetadataCreatorToolWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCreatortoolUpdateInput[] | null;
  create?: MetadataCreatorToolWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCreatortoolUpdateInput[] | null;
  delete?: MetadataCreatorToolWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCreditCreateInput {
  connect?: MetadataCreditWhereUniqueInput[] | null;
  create?: MetadataCreditWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCreditUpdateInput {
  connect?: MetadataCreditWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCreditUpdateInput[] | null;
  create?: MetadataCreditWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCreditUpdateInput[] | null;
  delete?: MetadataCreditWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCustomfield14CreateInput {
  connect?: MetadataCustomField14WhereUniqueInput[] | null;
  create?: MetadataCustomField14WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCustomfield14UpdateInput {
  connect?: MetadataCustomField14WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCustomfield14UpdateInput[] | null;
  create?: MetadataCustomField14WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCustomfield14UpdateInput[] | null;
  delete?: MetadataCustomField14WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCustomfield15CreateInput {
  connect?: MetadataCustomField15WhereUniqueInput[] | null;
  create?: MetadataCustomField15WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCustomfield15UpdateInput {
  connect?: MetadataCustomField15WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCustomfield15UpdateInput[] | null;
  create?: MetadataCustomField15WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCustomfield15UpdateInput[] | null;
  delete?: MetadataCustomField15WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCustomfield16CreateInput {
  connect?: MetadataCustomField16WhereUniqueInput[] | null;
  create?: MetadataCustomField16WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCustomfield16UpdateInput {
  connect?: MetadataCustomField16WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCustomfield16UpdateInput[] | null;
  create?: MetadataCustomField16WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCustomfield16UpdateInput[] | null;
  delete?: MetadataCustomField16WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCustomfield17CreateInput {
  connect?: MetadataCustomField17WhereUniqueInput[] | null;
  create?: MetadataCustomField17WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCustomfield17UpdateInput {
  connect?: MetadataCustomField17WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCustomfield17UpdateInput[] | null;
  create?: MetadataCustomField17WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCustomfield17UpdateInput[] | null;
  delete?: MetadataCustomField17WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCustomfield20CreateInput {
  connect?: MetadataCustomField20WhereUniqueInput[] | null;
  create?: MetadataCustomField20WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCustomfield20UpdateInput {
  connect?: MetadataCustomField20WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCustomfield20UpdateInput[] | null;
  create?: MetadataCustomField20WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCustomfield20UpdateInput[] | null;
  delete?: MetadataCustomField20WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCustomfield5CreateInput {
  connect?: MetadataCustomField5WhereUniqueInput[] | null;
  create?: MetadataCustomField5WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCustomfield5UpdateInput {
  connect?: MetadataCustomField5WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCustomfield5UpdateInput[] | null;
  create?: MetadataCustomField5WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCustomfield5UpdateInput[] | null;
  delete?: MetadataCustomField5WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCustomfield6CreateInput {
  connect?: MetadataCustomField6WhereUniqueInput[] | null;
  create?: MetadataCustomField6WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCustomfield6UpdateInput {
  connect?: MetadataCustomField6WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCustomfield6UpdateInput[] | null;
  create?: MetadataCustomField6WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCustomfield6UpdateInput[] | null;
  delete?: MetadataCustomField6WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCustomfield7CreateInput {
  connect?: MetadataCustomField7WhereUniqueInput[] | null;
  create?: MetadataCustomField7WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCustomfield7UpdateInput {
  connect?: MetadataCustomField7WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCustomfield7UpdateInput[] | null;
  create?: MetadataCustomField7WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCustomfield7UpdateInput[] | null;
  delete?: MetadataCustomField7WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataCustomfield9CreateInput {
  connect?: MetadataCustomField9WhereUniqueInput[] | null;
  create?: MetadataCustomField9WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataCustomfield9UpdateInput {
  connect?: MetadataCustomField9WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataCustomfield9UpdateInput[] | null;
  create?: MetadataCustomField9WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataCustomfield9UpdateInput[] | null;
  delete?: MetadataCustomField9WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDatecreatedCreateInput {
  connect?: MetadataDateCreatedWhereUniqueInput[] | null;
  create?: MetadataDateCreatedWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDatecreatedUpdateInput {
  connect?: MetadataDateCreatedWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDatecreatedUpdateInput[] | null;
  create?: MetadataDateCreatedWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDatecreatedUpdateInput[] | null;
  delete?: MetadataDateCreatedWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDatetimedigitizedCreateInput {
  connect?: MetadataDateTimeDigitizedWhereUniqueInput[] | null;
  create?: MetadataDateTimeDigitizedWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDatetimedigitizedUpdateInput {
  connect?: MetadataDateTimeDigitizedWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDatetimedigitizedUpdateInput[] | null;
  create?: MetadataDateTimeDigitizedWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDatetimedigitizedUpdateInput[] | null;
  delete?: MetadataDateTimeDigitizedWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDatetimeoriginalCreateInput {
  connect?: MetadataDateTimeOriginalWhereUniqueInput[] | null;
  create?: MetadataDateTimeOriginalWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDatetimeoriginalUpdateInput {
  connect?: MetadataDateTimeOriginalWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDatetimeoriginalUpdateInput[] | null;
  create?: MetadataDateTimeOriginalWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDatetimeoriginalUpdateInput[] | null;
  delete?: MetadataDateTimeOriginalWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliveryaccountCreateInput {
  connect?: MetadataDeliveryAccountWhereUniqueInput[] | null;
  create?: MetadataDeliveryAccountWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliveryaccountUpdateInput {
  connect?: MetadataDeliveryAccountWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliveryaccountUpdateInput[] | null;
  create?: MetadataDeliveryAccountWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliveryaccountUpdateInput[] | null;
  delete?: MetadataDeliveryAccountWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliverycompanyCreateInput {
  connect?: MetadataDeliveryCompanyWhereUniqueInput[] | null;
  create?: MetadataDeliveryCompanyWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliverycompanyUpdateInput {
  connect?: MetadataDeliveryCompanyWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliverycompanyUpdateInput[] | null;
  create?: MetadataDeliveryCompanyWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliverycompanyUpdateInput[] | null;
  delete?: MetadataDeliveryCompanyWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliverycopyrightCreateInput {
  connect?: MetadataDeliveryCopyrightWhereUniqueInput[] | null;
  create?: MetadataDeliveryCopyrightWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliverycopyrightUpdateInput {
  connect?: MetadataDeliveryCopyrightWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliverycopyrightUpdateInput[] | null;
  create?: MetadataDeliveryCopyrightWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliverycopyrightUpdateInput[] | null;
  delete?: MetadataDeliveryCopyrightWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliverydatefolderCreateInput {
  connect?: MetadataDeliveryDateFolderWhereUniqueInput[] | null;
  create?: MetadataDeliveryDateFolderWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliverydatefolderUpdateInput {
  connect?: MetadataDeliveryDateFolderWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliverydatefolderUpdateInput[] | null;
  create?: MetadataDeliveryDateFolderWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliverydatefolderUpdateInput[] | null;
  delete?: MetadataDeliveryDateFolderWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliverydatetimeCreateInput {
  connect?: MetadataDeliveryDateTimeWhereUniqueInput[] | null;
  create?: MetadataDeliveryDateTimeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliverydatetimeUpdateInput {
  connect?: MetadataDeliveryDateTimeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliverydatetimeUpdateInput[] | null;
  create?: MetadataDeliveryDateTimeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliverydatetimeUpdateInput[] | null;
  delete?: MetadataDeliveryDateTimeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliverydccreatorCreateInput {
  connect?: MetadataDeliveryDcCreatorWhereUniqueInput[] | null;
  create?: MetadataDeliveryDcCreatorWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliverydccreatorUpdateInput {
  connect?: MetadataDeliveryDcCreatorWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliverydccreatorUpdateInput[] | null;
  create?: MetadataDeliveryDcCreatorWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliverydccreatorUpdateInput[] | null;
  delete?: MetadataDeliveryDcCreatorWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliverydcrightsCreateInput {
  connect?: MetadataDeliveryDcRightsWhereUniqueInput[] | null;
  create?: MetadataDeliveryDcRightsWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliverydcrightsUpdateInput {
  connect?: MetadataDeliveryDcRightsWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliverydcrightsUpdateInput[] | null;
  create?: MetadataDeliveryDcRightsWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliverydcrightsUpdateInput[] | null;
  delete?: MetadataDeliveryDcRightsWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliveryfiletypeCreateInput {
  connect?: MetadataDeliveryFileTypeWhereUniqueInput[] | null;
  create?: MetadataDeliveryFileTypeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliveryfiletypeUpdateInput {
  connect?: MetadataDeliveryFileTypeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliveryfiletypeUpdateInput[] | null;
  create?: MetadataDeliveryFileTypeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliveryfiletypeUpdateInput[] | null;
  delete?: MetadataDeliveryFileTypeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliveryfolderCreateInput {
  connect?: MetadataDeliveryFolderWhereUniqueInput[] | null;
  create?: MetadataDeliveryFolderWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliveryfolderUpdateInput {
  connect?: MetadataDeliveryFolderWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliveryfolderUpdateInput[] | null;
  create?: MetadataDeliveryFolderWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliveryfolderUpdateInput[] | null;
  delete?: MetadataDeliveryFolderWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliverykindCreateInput {
  connect?: MetadataDeliveryKindWhereUniqueInput[] | null;
  create?: MetadataDeliveryKindWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliverykindUpdateInput {
  connect?: MetadataDeliveryKindWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliverykindUpdateInput[] | null;
  create?: MetadataDeliveryKindWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliverykindUpdateInput[] | null;
  delete?: MetadataDeliveryKindWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliverypathCreateInput {
  connect?: MetadataDeliveryPathWhereUniqueInput[] | null;
  create?: MetadataDeliveryPathWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliverypathUpdateInput {
  connect?: MetadataDeliveryPathWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliverypathUpdateInput[] | null;
  create?: MetadataDeliveryPathWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliverypathUpdateInput[] | null;
  delete?: MetadataDeliveryPathWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliverypersonshownintheimageCreateInput {
  connect?: MetadataDeliveryPersonShownintheImageWhereUniqueInput[] | null;
  create?: MetadataDeliveryPersonShownintheImageWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliverypersonshownintheimageUpdateInput {
  connect?: MetadataDeliveryPersonShownintheImageWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliverypersonshownintheimageUpdateInput[] | null;
  create?: MetadataDeliveryPersonShownintheImageWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliverypersonshownintheimageUpdateInput[] | null;
  delete?: MetadataDeliveryPersonShownintheImageWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliveryphotoshopcreditCreateInput {
  connect?: MetadataDeliveryPhotoshopCreditWhereUniqueInput[] | null;
  create?: MetadataDeliveryPhotoshopCreditWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliveryphotoshopcreditUpdateInput {
  connect?: MetadataDeliveryPhotoshopCreditWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliveryphotoshopcreditUpdateInput[] | null;
  create?: MetadataDeliveryPhotoshopCreditWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliveryphotoshopcreditUpdateInput[] | null;
  delete?: MetadataDeliveryPhotoshopCreditWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliveryphotoshopsourceCreateInput {
  connect?: MetadataDeliveryPhotoshopSourceWhereUniqueInput[] | null;
  create?: MetadataDeliveryPhotoshopSourceWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliveryphotoshopsourceUpdateInput {
  connect?: MetadataDeliveryPhotoshopSourceWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliveryphotoshopsourceUpdateInput[] | null;
  create?: MetadataDeliveryPhotoshopSourceWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliveryphotoshopsourceUpdateInput[] | null;
  delete?: MetadataDeliveryPhotoshopSourceWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliveryserviceCreateInput {
  connect?: MetadataDeliveryServiceWhereUniqueInput[] | null;
  create?: MetadataDeliveryServiceWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliveryserviceUpdateInput {
  connect?: MetadataDeliveryServiceWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliveryserviceUpdateInput[] | null;
  create?: MetadataDeliveryServiceWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliveryserviceUpdateInput[] | null;
  delete?: MetadataDeliveryServiceWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliverysubjectCreateInput {
  connect?: MetadataDeliverySubjectWhereUniqueInput[] | null;
  create?: MetadataDeliverySubjectWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliverysubjectUpdateInput {
  connect?: MetadataDeliverySubjectWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliverysubjectUpdateInput[] | null;
  create?: MetadataDeliverySubjectWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliverysubjectUpdateInput[] | null;
  delete?: MetadataDeliverySubjectWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDeliveryundersubjectCreateInput {
  connect?: MetadataDeliveryUnderSubjectWhereUniqueInput[] | null;
  create?: MetadataDeliveryUnderSubjectWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDeliveryundersubjectUpdateInput {
  connect?: MetadataDeliveryUnderSubjectWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDeliveryundersubjectUpdateInput[] | null;
  create?: MetadataDeliveryUnderSubjectWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDeliveryundersubjectUpdateInput[] | null;
  delete?: MetadataDeliveryUnderSubjectWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDepartmentCreateInput {
  connect?: MetadataDepartmentWhereUniqueInput[] | null;
  create?: MetadataDepartmentWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDepartmentUpdateInput {
  connect?: MetadataDepartmentWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDepartmentUpdateInput[] | null;
  create?: MetadataDepartmentWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDepartmentUpdateInput[] | null;
  delete?: MetadataDepartmentWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDescriptionCreateInput {
  connect?: MetadataDescriptionWhereUniqueInput[] | null;
  create?: MetadataDescriptionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDescriptionUpdateInput {
  connect?: MetadataDescriptionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDescriptionUpdateInput[] | null;
  create?: MetadataDescriptionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDescriptionUpdateInput[] | null;
  delete?: MetadataDescriptionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDigitalasseturlCreateInput {
  connect?: MetadataDigitalAssetUrlWhereUniqueInput[] | null;
  create?: MetadataDigitalAssetUrlWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDigitalasseturlUpdateInput {
  connect?: MetadataDigitalAssetUrlWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDigitalasseturlUpdateInput[] | null;
  create?: MetadataDigitalAssetUrlWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDigitalasseturlUpdateInput[] | null;
  delete?: MetadataDigitalAssetUrlWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDivisionCreateInput {
  connect?: MetadataDivisionWhereUniqueInput[] | null;
  create?: MetadataDivisionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDivisionUpdateInput {
  connect?: MetadataDivisionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDivisionUpdateInput[] | null;
  create?: MetadataDivisionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDivisionUpdateInput[] | null;
  delete?: MetadataDivisionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataDocumenttextCreateInput {
  connect?: MetadataDocumentTextWhereUniqueInput[] | null;
  create?: MetadataDocumentTextWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataDocumenttextUpdateInput {
  connect?: MetadataDocumentTextWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataDocumenttextUpdateInput[] | null;
  create?: MetadataDocumentTextWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataDocumenttextUpdateInput[] | null;
  delete?: MetadataDocumentTextWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataEditionCreateInput {
  connect?: MetadataEditionWhereUniqueInput[] | null;
  create?: MetadataEditionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataEditionUpdateInput {
  connect?: MetadataEditionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataEditionUpdateInput[] | null;
  create?: MetadataEditionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataEditionUpdateInput[] | null;
  delete?: MetadataEditionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataEditorialversionCreateInput {
  connect?: MetadataEditorialVersionWhereUniqueInput[] | null;
  create?: MetadataEditorialVersionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataEditorialversionUpdateInput {
  connect?: MetadataEditorialVersionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataEditorialversionUpdateInput[] | null;
  create?: MetadataEditorialVersionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataEditorialversionUpdateInput[] | null;
  delete?: MetadataEditorialVersionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataEditstatusCreateInput {
  connect?: MetadataEditStatusWhereUniqueInput[] | null;
  create?: MetadataEditStatusWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataEditstatusUpdateInput {
  connect?: MetadataEditStatusWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataEditstatusUpdateInput[] | null;
  create?: MetadataEditStatusWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataEditstatusUpdateInput[] | null;
  delete?: MetadataEditStatusWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataEnvironnementphotoCreateInput {
  connect?: MetadataEnvironnementPhotoWhereUniqueInput[] | null;
  create?: MetadataEnvironnementPhotoWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataEnvironnementphotoUpdateInput {
  connect?: MetadataEnvironnementPhotoWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataEnvironnementphotoUpdateInput[] | null;
  create?: MetadataEnvironnementPhotoWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataEnvironnementphotoUpdateInput[] | null;
  delete?: MetadataEnvironnementPhotoWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataEquipmentinstitutionCreateInput {
  connect?: MetadataEquipmentInstitutionWhereUniqueInput[] | null;
  create?: MetadataEquipmentInstitutionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataEquipmentinstitutionUpdateInput {
  connect?: MetadataEquipmentInstitutionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataEquipmentinstitutionUpdateInput[] | null;
  create?: MetadataEquipmentInstitutionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataEquipmentinstitutionUpdateInput[] | null;
  delete?: MetadataEquipmentInstitutionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataEquipmentmanufacturerCreateInput {
  connect?: MetadataEquipmentManufacturerWhereUniqueInput[] | null;
  create?: MetadataEquipmentManufacturerWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataEquipmentmanufacturerUpdateInput {
  connect?: MetadataEquipmentManufacturerWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataEquipmentmanufacturerUpdateInput[] | null;
  create?: MetadataEquipmentManufacturerWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataEquipmentmanufacturerUpdateInput[] | null;
  delete?: MetadataEquipmentManufacturerWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataEventCreateInput {
  connect?: MetadataEventWhereUniqueInput[] | null;
  create?: MetadataEventWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataEventUpdateInput {
  connect?: MetadataEventWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataEventUpdateInput[] | null;
  create?: MetadataEventWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataEventUpdateInput[] | null;
  delete?: MetadataEventWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataExifCreateInput {
  connect?: MetadataExifWhereUniqueInput[] | null;
  create?: MetadataExifWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataExifUpdateInput {
  connect?: MetadataExifWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataExifUpdateInput[] | null;
  create?: MetadataExifWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataExifUpdateInput[] | null;
  delete?: MetadataExifWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataFabstorageCreateInput {
  connect?: MetadataFabStorageWhereUniqueInput[] | null;
  create?: MetadataFabStorageWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataFabstorageUpdateInput {
  connect?: MetadataFabStorageWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataFabstorageUpdateInput[] | null;
  create?: MetadataFabStorageWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataFabstorageUpdateInput[] | null;
  delete?: MetadataFabStorageWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataFilecheckforCreateInput {
  connect?: MetadataFileCheckForWhereUniqueInput[] | null;
  create?: MetadataFileCheckForWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataFilecheckforUpdateInput {
  connect?: MetadataFileCheckForWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataFilecheckforUpdateInput[] | null;
  create?: MetadataFileCheckForWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataFilecheckforUpdateInput[] | null;
  delete?: MetadataFileCheckForWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataFileinfobitsperpixelCreateInput {
  connect?: MetadataFileInfoBitsPerPixelWhereUniqueInput[] | null;
  create?: MetadataFileInfoBitsPerPixelWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataFileinfobitsperpixelUpdateInput {
  connect?: MetadataFileInfoBitsPerPixelWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataFileinfobitsperpixelUpdateInput[] | null;
  create?: MetadataFileInfoBitsPerPixelWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataFileinfobitsperpixelUpdateInput[] | null;
  delete?: MetadataFileInfoBitsPerPixelWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataFileinfoframesCreateInput {
  connect?: MetadataFileInfoFramesWhereUniqueInput[] | null;
  create?: MetadataFileInfoFramesWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataFileinfoframesUpdateInput {
  connect?: MetadataFileInfoFramesWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataFileinfoframesUpdateInput[] | null;
  create?: MetadataFileInfoFramesWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataFileinfoframesUpdateInput[] | null;
  delete?: MetadataFileInfoFramesWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataFileinfophotometricinterpretationCreateInput {
  connect?: MetadataFileInfoPhotometricInterpretationWhereUniqueInput[] | null;
  create?: MetadataFileInfoPhotometricInterpretationWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataFileinfophotometricinterpretationUpdateInput {
  connect?: MetadataFileInfoPhotometricInterpretationWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataFileinfophotometricinterpretationUpdateInput[] | null;
  create?: MetadataFileInfoPhotometricInterpretationWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataFileinfophotometricinterpretationUpdateInput[] | null;
  delete?: MetadataFileInfoPhotometricInterpretationWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataFileinfotransfersyntaxCreateInput {
  connect?: MetadataFileInfoTransferSyntaxWhereUniqueInput[] | null;
  create?: MetadataFileInfoTransferSyntaxWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataFileinfotransfersyntaxUpdateInput {
  connect?: MetadataFileInfoTransferSyntaxWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataFileinfotransfersyntaxUpdateInput[] | null;
  create?: MetadataFileInfoTransferSyntaxWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataFileinfotransfersyntaxUpdateInput[] | null;
  delete?: MetadataFileInfoTransferSyntaxWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataFilestageCreateInput {
  connect?: MetadataFileStageWhereUniqueInput[] | null;
  create?: MetadataFileStageWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataFilestageUpdateInput {
  connect?: MetadataFileStageWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataFilestageUpdateInput[] | null;
  create?: MetadataFileStageWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataFilestageUpdateInput[] | null;
  delete?: MetadataFileStageWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataFilestatusCreateInput {
  connect?: MetadataFileStatusWhereUniqueInput[] | null;
  create?: MetadataFileStatusWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataFilestatusUpdateInput {
  connect?: MetadataFileStatusWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataFilestatusUpdateInput[] | null;
  create?: MetadataFileStatusWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataFilestatusUpdateInput[] | null;
  delete?: MetadataFileStatusWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataFiletypeCreateInput {
  connect?: MetadataFileTypeWhereUniqueInput[] | null;
  create?: MetadataFileTypeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataFiletypeUpdateInput {
  connect?: MetadataFileTypeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataFiletypeUpdateInput[] | null;
  create?: MetadataFileTypeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataFiletypeUpdateInput[] | null;
  delete?: MetadataFileTypeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataFixtureidentifierCreateInput {
  connect?: MetadataFixtureIdentifierWhereUniqueInput[] | null;
  create?: MetadataFixtureIdentifierWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataFixtureidentifierUpdateInput {
  connect?: MetadataFixtureIdentifierWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataFixtureidentifierUpdateInput[] | null;
  create?: MetadataFixtureIdentifierWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataFixtureidentifierUpdateInput[] | null;
  delete?: MetadataFixtureIdentifierWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataFormatCreateInput {
  connect?: MetadataFormatWhereUniqueInput[] | null;
  create?: MetadataFormatWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataFormatUpdateInput {
  connect?: MetadataFormatWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataFormatUpdateInput[] | null;
  create?: MetadataFormatWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataFormatUpdateInput[] | null;
  delete?: MetadataFormatWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataGpslatitudeCreateInput {
  connect?: MetadataGpsLatitudeWhereUniqueInput[] | null;
  create?: MetadataGpsLatitudeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataGpslatitudeUpdateInput {
  connect?: MetadataGpsLatitudeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataGpslatitudeUpdateInput[] | null;
  create?: MetadataGpsLatitudeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataGpslatitudeUpdateInput[] | null;
  delete?: MetadataGpsLatitudeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataGpslongitudeCreateInput {
  connect?: MetadataGpsLongitudeWhereUniqueInput[] | null;
  create?: MetadataGpsLongitudeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataGpslongitudeUpdateInput {
  connect?: MetadataGpsLongitudeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataGpslongitudeUpdateInput[] | null;
  create?: MetadataGpsLongitudeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataGpslongitudeUpdateInput[] | null;
  delete?: MetadataGpsLongitudeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataGpsprocessingmethodCreateInput {
  connect?: MetadataGpsProcessingMethodWhereUniqueInput[] | null;
  create?: MetadataGpsProcessingMethodWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataGpsprocessingmethodUpdateInput {
  connect?: MetadataGpsProcessingMethodWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataGpsprocessingmethodUpdateInput[] | null;
  create?: MetadataGpsProcessingMethodWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataGpsprocessingmethodUpdateInput[] | null;
  delete?: MetadataGpsProcessingMethodWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataHeaderCreateInput {
  connect?: MetadataHeaderWhereUniqueInput[] | null;
  create?: MetadataHeaderWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataHeaderUpdateInput {
  connect?: MetadataHeaderWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataHeaderUpdateInput[] | null;
  create?: MetadataHeaderWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataHeaderUpdateInput[] | null;
  delete?: MetadataHeaderWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataHeaderprismaCreateInput {
  connect?: MetadataHeaderPrismaWhereUniqueInput[] | null;
  create?: MetadataHeaderPrismaWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataHeaderprismaUpdateInput {
  connect?: MetadataHeaderPrismaWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataHeaderprismaUpdateInput[] | null;
  create?: MetadataHeaderPrismaWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataHeaderprismaUpdateInput[] | null;
  delete?: MetadataHeaderPrismaWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataHeadlineCreateInput {
  connect?: MetadataHeadlineWhereUniqueInput[] | null;
  create?: MetadataHeadlineWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataHeadlineUpdateInput {
  connect?: MetadataHeadlineWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataHeadlineUpdateInput[] | null;
  create?: MetadataHeadlineWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataHeadlineUpdateInput[] | null;
  delete?: MetadataHeadlineWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataHistoryCreateInput {
  connect?: MetadataHistoryWhereUniqueInput[] | null;
  create?: MetadataHistoryWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataHistoryUpdateInput {
  connect?: MetadataHistoryWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataHistoryUpdateInput[] | null;
  create?: MetadataHistoryWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataHistoryUpdateInput[] | null;
  delete?: MetadataHistoryWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataIccprofileCreateInput {
  connect?: MetadataIccProfileWhereUniqueInput[] | null;
  create?: MetadataIccProfileWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataIccprofileUpdateInput {
  connect?: MetadataIccProfileWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataIccprofileUpdateInput[] | null;
  create?: MetadataIccProfileWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataIccprofileUpdateInput[] | null;
  delete?: MetadataIccProfileWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataIdSubjectCreateInput {
  connect?: MetadataIdSubjectWhereUniqueInput[] | null;
  create?: MetadataIdSubjectWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataIdSubjectUpdateInput {
  connect?: MetadataIdSubjectWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataIdSubjectUpdateInput[] | null;
  create?: MetadataIdSubjectWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataIdSubjectUpdateInput[] | null;
  delete?: MetadataIdSubjectWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataIdassignmentCreateInput {
  connect?: MetadataIdAssignmentWhereUniqueInput[] | null;
  create?: MetadataIdAssignmentWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataIdassignmentUpdateInput {
  connect?: MetadataIdAssignmentWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataIdassignmentUpdateInput[] | null;
  create?: MetadataIdAssignmentWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataIdassignmentUpdateInput[] | null;
  delete?: MetadataIdAssignmentWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataIdmediacontentCreateInput {
  connect?: MetadataIdMediaContentWhereUniqueInput[] | null;
  create?: MetadataIdMediaContentWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataIdmediacontentUpdateInput {
  connect?: MetadataIdMediaContentWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataIdmediacontentUpdateInput[] | null;
  create?: MetadataIdMediaContentWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataIdmediacontentUpdateInput[] | null;
  delete?: MetadataIdMediaContentWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataIdphotoprismaCreateInput {
  connect?: MetadataIdPhotoPrismaWhereUniqueInput[] | null;
  create?: MetadataIdPhotoPrismaWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataIdphotoprismaUpdateInput {
  connect?: MetadataIdPhotoPrismaWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataIdphotoprismaUpdateInput[] | null;
  create?: MetadataIdPhotoPrismaWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataIdphotoprismaUpdateInput[] | null;
  delete?: MetadataIdPhotoPrismaWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataImagenotesCreateInput {
  connect?: MetadataImageNotesWhereUniqueInput[] | null;
  create?: MetadataImageNotesWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataImagenotesUpdateInput {
  connect?: MetadataImageNotesWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataImagenotesUpdateInput[] | null;
  create?: MetadataImageNotesWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataImagenotesUpdateInput[] | null;
  delete?: MetadataImageNotesWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataImageuniqueidCreateInput {
  connect?: MetadataImageUniqueIdWhereUniqueInput[] | null;
  create?: MetadataImageUniqueIdWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataImageuniqueidUpdateInput {
  connect?: MetadataImageUniqueIdWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataImageuniqueidUpdateInput[] | null;
  create?: MetadataImageUniqueIdWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataImageuniqueidUpdateInput[] | null;
  delete?: MetadataImageUniqueIdWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataInstructionsCreateInput {
  connect?: MetadataInstructionsWhereUniqueInput[] | null;
  create?: MetadataInstructionsWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataInstructionsUpdateInput {
  connect?: MetadataInstructionsWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataInstructionsUpdateInput[] | null;
  create?: MetadataInstructionsWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataInstructionsUpdateInput[] | null;
  delete?: MetadataInstructionsWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataIntellectualgenreCreateInput {
  connect?: MetadataIntellectualGenreWhereUniqueInput[] | null;
  create?: MetadataIntellectualGenreWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataIntellectualgenreUpdateInput {
  connect?: MetadataIntellectualGenreWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataIntellectualgenreUpdateInput[] | null;
  create?: MetadataIntellectualGenreWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataIntellectualgenreUpdateInput[] | null;
  delete?: MetadataIntellectualGenreWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataIsospeedratingsCreateInput {
  connect?: MetadataIsoSpeedRatingsWhereUniqueInput[] | null;
  create?: MetadataIsoSpeedRatingsWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataIsospeedratingsUpdateInput {
  connect?: MetadataIsoSpeedRatingsWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataIsospeedratingsUpdateInput[] | null;
  create?: MetadataIsoSpeedRatingsWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataIsospeedratingsUpdateInput[] | null;
  delete?: MetadataIsoSpeedRatingsWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataIssueCreateInput {
  connect?: MetadataIssueWhereUniqueInput[] | null;
  create?: MetadataIssueWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataIssueUpdateInput {
  connect?: MetadataIssueWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataIssueUpdateInput[] | null;
  create?: MetadataIssueWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataIssueUpdateInput[] | null;
  delete?: MetadataIssueWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataJobidCreateInput {
  connect?: MetadataJobIdWhereUniqueInput[] | null;
  create?: MetadataJobIdWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataJobidUpdateInput {
  connect?: MetadataJobIdWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataJobidUpdateInput[] | null;
  create?: MetadataJobIdWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataJobidUpdateInput[] | null;
  delete?: MetadataJobIdWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataJobprocessingCreateInput {
  connect?: MetadataJobProcessingWhereUniqueInput[] | null;
  create?: MetadataJobProcessingWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataJobprocessingUpdateInput {
  connect?: MetadataJobProcessingWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataJobprocessingUpdateInput[] | null;
  create?: MetadataJobProcessingWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataJobprocessingUpdateInput[] | null;
  delete?: MetadataJobProcessingWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataLayoutpictureeditingCreateInput {
  connect?: MetadataLayoutPictureEditingWhereUniqueInput[] | null;
  create?: MetadataLayoutPictureEditingWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataLayoutpictureeditingUpdateInput {
  connect?: MetadataLayoutPictureEditingWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataLayoutpictureeditingUpdateInput[] | null;
  create?: MetadataLayoutPictureEditingWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataLayoutpictureeditingUpdateInput[] | null;
  delete?: MetadataLayoutPictureEditingWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataLayoutstorageCreateInput {
  connect?: MetadataLayoutStorageWhereUniqueInput[] | null;
  create?: MetadataLayoutStorageWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataLayoutstorageUpdateInput {
  connect?: MetadataLayoutStorageWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataLayoutstorageUpdateInput[] | null;
  create?: MetadataLayoutStorageWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataLayoutstorageUpdateInput[] | null;
  delete?: MetadataLayoutStorageWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataLicenseCreateInput {
  connect?: MetadataLicenseWhereUniqueInput[] | null;
  create?: MetadataLicenseWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataLicenseUpdateInput {
  connect?: MetadataLicenseWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataLicenseUpdateInput[] | null;
  create?: MetadataLicenseWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataLicenseUpdateInput[] | null;
  delete?: MetadataLicenseWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataLinksCreateInput {
  connect?: MetadataLinksWhereUniqueInput[] | null;
  create?: MetadataLinksWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataLinksUpdateInput {
  connect?: MetadataLinksWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataLinksUpdateInput[] | null;
  create?: MetadataLinksWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataLinksUpdateInput[] | null;
  delete?: MetadataLinksWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataLocalcaptionCreateInput {
  connect?: MetadataLocalCaptionWhereUniqueInput[] | null;
  create?: MetadataLocalCaptionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataLocalcaptionUpdateInput {
  connect?: MetadataLocalCaptionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataLocalcaptionUpdateInput[] | null;
  create?: MetadataLocalCaptionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataLocalcaptionUpdateInput[] | null;
  delete?: MetadataLocalCaptionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataLocationCreateInput {
  connect?: MetadataLocationWhereUniqueInput[] | null;
  create?: MetadataLocationWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataLocationUpdateInput {
  connect?: MetadataLocationWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataLocationUpdateInput[] | null;
  create?: MetadataLocationWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataLocationUpdateInput[] | null;
  delete?: MetadataLocationWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataMakeCreateInput {
  connect?: MetadataMakeWhereUniqueInput[] | null;
  create?: MetadataMakeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataMakeUpdateInput {
  connect?: MetadataMakeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataMakeUpdateInput[] | null;
  create?: MetadataMakeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataMakeUpdateInput[] | null;
  delete?: MetadataMakeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataManifestCreateInput {
  connect?: MetadataManifestWhereUniqueInput[] | null;
  create?: MetadataManifestWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataManifestUpdateInput {
  connect?: MetadataManifestWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataManifestUpdateInput[] | null;
  create?: MetadataManifestWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataManifestUpdateInput[] | null;
  delete?: MetadataManifestWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataMarkedCreateInput {
  connect?: MetadataMarkedWhereUniqueInput[] | null;
  create?: MetadataMarkedWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataMarkedUpdateInput {
  connect?: MetadataMarkedWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataMarkedUpdateInput[] | null;
  create?: MetadataMarkedWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataMarkedUpdateInput[] | null;
  delete?: MetadataMarkedWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataMasterdocumentidCreateInput {
  connect?: MetadataMasterDocumentIdWhereUniqueInput[] | null;
  create?: MetadataMasterDocumentIdWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataMasterdocumentidUpdateInput {
  connect?: MetadataMasterDocumentIdWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataMasterdocumentidUpdateInput[] | null;
  create?: MetadataMasterDocumentIdWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataMasterdocumentidUpdateInput[] | null;
  delete?: MetadataMasterDocumentIdWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataMaxavailheightCreateInput {
  connect?: MetadataMaxAvailHeightWhereUniqueInput[] | null;
  create?: MetadataMaxAvailHeightWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataMaxavailheightUpdateInput {
  connect?: MetadataMaxAvailHeightWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataMaxavailheightUpdateInput[] | null;
  create?: MetadataMaxAvailHeightWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataMaxavailheightUpdateInput[] | null;
  delete?: MetadataMaxAvailHeightWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataMaxavailwidthCreateInput {
  connect?: MetadataMaxAvailWidthWhereUniqueInput[] | null;
  create?: MetadataMaxAvailWidthWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataMaxavailwidthUpdateInput {
  connect?: MetadataMaxAvailWidthWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataMaxavailwidthUpdateInput[] | null;
  create?: MetadataMaxAvailWidthWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataMaxavailwidthUpdateInput[] | null;
  delete?: MetadataMaxAvailWidthWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataMinormodelagedisclosureCreateInput {
  connect?: MetadataMinorModelAgeDisclosureWhereUniqueInput[] | null;
  create?: MetadataMinorModelAgeDisclosureWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataMinormodelagedisclosureUpdateInput {
  connect?: MetadataMinorModelAgeDisclosureWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataMinormodelagedisclosureUpdateInput[] | null;
  create?: MetadataMinorModelAgeDisclosureWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataMinormodelagedisclosureUpdateInput[] | null;
  delete?: MetadataMinorModelAgeDisclosureWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataModelCreateInput {
  connect?: MetadataModelWhereUniqueInput[] | null;
  create?: MetadataModelWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataModelUpdateInput {
  connect?: MetadataModelWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataModelUpdateInput[] | null;
  create?: MetadataModelWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataModelUpdateInput[] | null;
  delete?: MetadataModelWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataModelageCreateInput {
  connect?: MetadataModelAgeWhereUniqueInput[] | null;
  create?: MetadataModelAgeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataModelageUpdateInput {
  connect?: MetadataModelAgeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataModelageUpdateInput[] | null;
  create?: MetadataModelAgeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataModelageUpdateInput[] | null;
  delete?: MetadataModelAgeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataModelreleaseidCreateInput {
  connect?: MetadataModelReleaseIdWhereUniqueInput[] | null;
  create?: MetadataModelReleaseIdWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataModelreleaseidUpdateInput {
  connect?: MetadataModelReleaseIdWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataModelreleaseidUpdateInput[] | null;
  create?: MetadataModelReleaseIdWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataModelreleaseidUpdateInput[] | null;
  delete?: MetadataModelReleaseIdWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataModelreleasestatusCreateInput {
  connect?: MetadataModelReleaseStatusWhereUniqueInput[] | null;
  create?: MetadataModelReleaseStatusWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataModelreleasestatusUpdateInput {
  connect?: MetadataModelReleaseStatusWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataModelreleasestatusUpdateInput[] | null;
  create?: MetadataModelReleaseStatusWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataModelreleasestatusUpdateInput[] | null;
  delete?: MetadataModelReleaseStatusWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataMorepermissionsCreateInput {
  connect?: MetadataMorePermissionsWhereUniqueInput[] | null;
  create?: MetadataMorePermissionsWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataMorepermissionsUpdateInput {
  connect?: MetadataMorePermissionsWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataMorepermissionsUpdateInput[] | null;
  create?: MetadataMorePermissionsWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataMorepermissionsUpdateInput[] | null;
  delete?: MetadataMorePermissionsWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataObjectcycleCreateInput {
  connect?: MetadataObjectCycleWhereUniqueInput[] | null;
  create?: MetadataObjectCycleWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataObjectcycleUpdateInput {
  connect?: MetadataObjectCycleWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataObjectcycleUpdateInput[] | null;
  create?: MetadataObjectCycleWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataObjectcycleUpdateInput[] | null;
  delete?: MetadataObjectCycleWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataOrganisationinimagenameCreateInput {
  connect?: MetadataOrganisationInImageNameWhereUniqueInput[] | null;
  create?: MetadataOrganisationInImageNameWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataOrganisationinimagenameUpdateInput {
  connect?: MetadataOrganisationInImageNameWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataOrganisationinimagenameUpdateInput[] | null;
  create?: MetadataOrganisationInImageNameWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataOrganisationinimagenameUpdateInput[] | null;
  delete?: MetadataOrganisationInImageNameWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataOriginalcolorCreateInput {
  connect?: MetadataOriginalColorWhereUniqueInput[] | null;
  create?: MetadataOriginalColorWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataOriginalcolorUpdateInput {
  connect?: MetadataOriginalColorWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataOriginalcolorUpdateInput[] | null;
  create?: MetadataOriginalColorWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataOriginalcolorUpdateInput[] | null;
  delete?: MetadataOriginalColorWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataOriginaliccprofileCreateInput {
  connect?: MetadataOriginalIccProfileWhereUniqueInput[] | null;
  create?: MetadataOriginalIccProfileWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataOriginaliccprofileUpdateInput {
  connect?: MetadataOriginalIccProfileWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataOriginaliccprofileUpdateInput[] | null;
  create?: MetadataOriginalIccProfileWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataOriginaliccprofileUpdateInput[] | null;
  delete?: MetadataOriginalIccProfileWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataOriginalnameCreateInput {
  connect?: MetadataOriginalNameWhereUniqueInput[] | null;
  create?: MetadataOriginalNameWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataOriginalnameUpdateInput {
  connect?: MetadataOriginalNameWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataOriginalnameUpdateInput[] | null;
  create?: MetadataOriginalNameWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataOriginalnameUpdateInput[] | null;
  delete?: MetadataOriginalNameWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataOriginalweigthCreateInput {
  connect?: MetadataOriginalWeigthWhereUniqueInput[] | null;
  create?: MetadataOriginalWeigthWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataOriginalweigthUpdateInput {
  connect?: MetadataOriginalWeigthWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataOriginalweigthUpdateInput[] | null;
  create?: MetadataOriginalWeigthWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataOriginalweigthUpdateInput[] | null;
  delete?: MetadataOriginalWeigthWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataOwnerCreateInput {
  connect?: MetadataOwnerWhereUniqueInput[] | null;
  create?: MetadataOwnerWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataOwnerUpdateInput {
  connect?: MetadataOwnerWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataOwnerUpdateInput[] | null;
  create?: MetadataOwnerWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataOwnerUpdateInput[] | null;
  delete?: MetadataOwnerWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataOwneridCreateInput {
  connect?: MetadataOwnerIdWhereUniqueInput[] | null;
  create?: MetadataOwnerIdWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataOwneridUpdateInput {
  connect?: MetadataOwnerIdWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataOwneridUpdateInput[] | null;
  create?: MetadataOwnerIdWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataOwneridUpdateInput[] | null;
  delete?: MetadataOwnerIdWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPageCreateInput {
  connect?: MetadataPageWhereUniqueInput[] | null;
  create?: MetadataPageWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPageUpdateInput {
  connect?: MetadataPageWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPageUpdateInput[] | null;
  create?: MetadataPageWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPageUpdateInput[] | null;
  delete?: MetadataPageWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPageprismaCreateInput {
  connect?: MetadataPagePrismaWhereUniqueInput[] | null;
  create?: MetadataPagePrismaWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPageprismaUpdateInput {
  connect?: MetadataPagePrismaWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPageprismaUpdateInput[] | null;
  create?: MetadataPagePrismaWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPageprismaUpdateInput[] | null;
  delete?: MetadataPagePrismaWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPaintbasedcorrectionsCreateInput {
  connect?: MetadataPaintBasedCorrectionsWhereUniqueInput[] | null;
  create?: MetadataPaintBasedCorrectionsWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPaintbasedcorrectionsUpdateInput {
  connect?: MetadataPaintBasedCorrectionsWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPaintbasedcorrectionsUpdateInput[] | null;
  create?: MetadataPaintBasedCorrectionsWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPaintbasedcorrectionsUpdateInput[] | null;
  delete?: MetadataPaintBasedCorrectionsWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPatientdobCreateInput {
  connect?: MetadataPatientDobWhereUniqueInput[] | null;
  create?: MetadataPatientDobWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPatientdobUpdateInput {
  connect?: MetadataPatientDobWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPatientdobUpdateInput[] | null;
  create?: MetadataPatientDobWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPatientdobUpdateInput[] | null;
  delete?: MetadataPatientDobWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPatientidCreateInput {
  connect?: MetadataPatientIdWhereUniqueInput[] | null;
  create?: MetadataPatientIdWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPatientidUpdateInput {
  connect?: MetadataPatientIdWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPatientidUpdateInput[] | null;
  create?: MetadataPatientIdWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPatientidUpdateInput[] | null;
  delete?: MetadataPatientIdWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPatientnameCreateInput {
  connect?: MetadataPatientNameWhereUniqueInput[] | null;
  create?: MetadataPatientNameWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPatientnameUpdateInput {
  connect?: MetadataPatientNameWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPatientnameUpdateInput[] | null;
  create?: MetadataPatientNameWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPatientnameUpdateInput[] | null;
  delete?: MetadataPatientNameWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPatientsexCreateInput {
  connect?: MetadataPatientSexWhereUniqueInput[] | null;
  create?: MetadataPatientSexWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPatientsexUpdateInput {
  connect?: MetadataPatientSexWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPatientsexUpdateInput[] | null;
  create?: MetadataPatientSexWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPatientsexUpdateInput[] | null;
  delete?: MetadataPatientSexWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPersoninimageCreateInput {
  connect?: MetadataPersonInImageWhereUniqueInput[] | null;
  create?: MetadataPersonInImageWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPersoninimageUpdateInput {
  connect?: MetadataPersonInImageWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPersoninimageUpdateInput[] | null;
  create?: MetadataPersonInImageWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPersoninimageUpdateInput[] | null;
  delete?: MetadataPersonInImageWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPhotostorageCreateInput {
  connect?: MetadataPhotoStorageWhereUniqueInput[] | null;
  create?: MetadataPhotoStorageWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPhotostorageUpdateInput {
  connect?: MetadataPhotoStorageWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPhotostorageUpdateInput[] | null;
  create?: MetadataPhotoStorageWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPhotostorageUpdateInput[] | null;
  delete?: MetadataPhotoStorageWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPrepresspictureeditingCreateInput {
  connect?: MetadataPrepressPictureEditingWhereUniqueInput[] | null;
  create?: MetadataPrepressPictureEditingWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPrepresspictureeditingUpdateInput {
  connect?: MetadataPrepressPictureEditingWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPrepresspictureeditingUpdateInput[] | null;
  create?: MetadataPrepressPictureEditingWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPrepresspictureeditingUpdateInput[] | null;
  delete?: MetadataPrepressPictureEditingWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPricelevelCreateInput {
  connect?: MetadataPriceLevelWhereUniqueInput[] | null;
  create?: MetadataPriceLevelWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPricelevelUpdateInput {
  connect?: MetadataPriceLevelWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPricelevelUpdateInput[] | null;
  create?: MetadataPriceLevelWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPricelevelUpdateInput[] | null;
  delete?: MetadataPriceLevelWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPrintingprofileCreateInput {
  connect?: MetadataPrintingProfileWhereUniqueInput[] | null;
  create?: MetadataPrintingProfileWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPrintingprofileUpdateInput {
  connect?: MetadataPrintingProfileWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPrintingprofileUpdateInput[] | null;
  create?: MetadataPrintingProfileWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPrintingprofileUpdateInput[] | null;
  delete?: MetadataPrintingProfileWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPrismaproductionCreateInput {
  connect?: MetadataPrismaProductionWhereUniqueInput[] | null;
  create?: MetadataPrismaProductionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPrismaproductionUpdateInput {
  connect?: MetadataPrismaProductionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPrismaproductionUpdateInput[] | null;
  create?: MetadataPrismaProductionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPrismaproductionUpdateInput[] | null;
  delete?: MetadataPrismaProductionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataProcesshistoryCreateInput {
  connect?: MetadataProcessHistoryWhereUniqueInput[] | null;
  create?: MetadataProcessHistoryWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataProcesshistoryUpdateInput {
  connect?: MetadataProcessHistoryWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataProcesshistoryUpdateInput[] | null;
  create?: MetadataProcessHistoryWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataProcesshistoryUpdateInput[] | null;
  delete?: MetadataProcessHistoryWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataProcessparameterCreateInput {
  connect?: MetadataProcessParameterWhereUniqueInput[] | null;
  create?: MetadataProcessParameterWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataProcessparameterUpdateInput {
  connect?: MetadataProcessParameterWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataProcessparameterUpdateInput[] | null;
  create?: MetadataProcessParameterWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataProcessparameterUpdateInput[] | null;
  delete?: MetadataProcessParameterWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataProcessstatusCreateInput {
  connect?: MetadataProcessStatusWhereUniqueInput[] | null;
  create?: MetadataProcessStatusWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataProcessstatusUpdateInput {
  connect?: MetadataProcessStatusWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataProcessstatusUpdateInput[] | null;
  create?: MetadataProcessStatusWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataProcessstatusUpdateInput[] | null;
  delete?: MetadataProcessStatusWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataProductCreateInput {
  connect?: MetadataProductWhereUniqueInput[] | null;
  create?: MetadataProductWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataProductUpdateInput {
  connect?: MetadataProductWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataProductUpdateInput[] | null;
  create?: MetadataProductWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataProductUpdateInput[] | null;
  delete?: MetadataProductWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataProductsCreateInput {
  connect?: MetadataProductsWhereUniqueInput[] | null;
  create?: MetadataProductsWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataProductsUpdateInput {
  connect?: MetadataProductsWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataProductsUpdateInput[] | null;
  create?: MetadataProductsWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataProductsUpdateInput[] | null;
  delete?: MetadataProductsWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataProductshortnameCreateInput {
  connect?: MetadataProductShortNameWhereUniqueInput[] | null;
  create?: MetadataProductShortNameWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataProductshortnameUpdateInput {
  connect?: MetadataProductShortNameWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataProductshortnameUpdateInput[] | null;
  create?: MetadataProductShortNameWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataProductshortnameUpdateInput[] | null;
  delete?: MetadataProductShortNameWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataProduitsCreateInput {
  connect?: MetadataProduitsWhereUniqueInput[] | null;
  create?: MetadataProduitsWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataProduitsUpdateInput {
  connect?: MetadataProduitsWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataProduitsUpdateInput[] | null;
  create?: MetadataProduitsWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataProduitsUpdateInput[] | null;
  delete?: MetadataProduitsWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataProgramversionCreateInput {
  connect?: MetadataProgramVersionWhereUniqueInput[] | null;
  create?: MetadataProgramVersionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataProgramversionUpdateInput {
  connect?: MetadataProgramVersionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataProgramversionUpdateInput[] | null;
  create?: MetadataProgramVersionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataProgramversionUpdateInput[] | null;
  delete?: MetadataProgramVersionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPropertyreleaseidCreateInput {
  connect?: MetadataPropertyReleaseIdWhereUniqueInput[] | null;
  create?: MetadataPropertyReleaseIdWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPropertyreleaseidUpdateInput {
  connect?: MetadataPropertyReleaseIdWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPropertyreleaseidUpdateInput[] | null;
  create?: MetadataPropertyReleaseIdWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPropertyreleaseidUpdateInput[] | null;
  delete?: MetadataPropertyReleaseIdWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPropertyreleasestatusCreateInput {
  connect?: MetadataPropertyReleaseStatusWhereUniqueInput[] | null;
  create?: MetadataPropertyReleaseStatusWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPropertyreleasestatusUpdateInput {
  connect?: MetadataPropertyReleaseStatusWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPropertyreleasestatusUpdateInput[] | null;
  create?: MetadataPropertyReleaseStatusWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPropertyreleasestatusUpdateInput[] | null;
  delete?: MetadataPropertyReleaseStatusWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPublisherCreateInput {
  connect?: MetadataPublisherWhereUniqueInput[] | null;
  create?: MetadataPublisherWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPublisherUpdateInput {
  connect?: MetadataPublisherWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPublisherUpdateInput[] | null;
  create?: MetadataPublisherWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPublisherUpdateInput[] | null;
  delete?: MetadataPublisherWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPublishingissueCreateInput {
  connect?: MetadataPublishingIssueWhereUniqueInput[] | null;
  create?: MetadataPublishingIssueWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPublishingissueUpdateInput {
  connect?: MetadataPublishingIssueWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPublishingissueUpdateInput[] | null;
  create?: MetadataPublishingIssueWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPublishingissueUpdateInput[] | null;
  delete?: MetadataPublishingIssueWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataPublishingsubjectCreateInput {
  connect?: MetadataPublishingSubjectWhereUniqueInput[] | null;
  create?: MetadataPublishingSubjectWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataPublishingsubjectUpdateInput {
  connect?: MetadataPublishingSubjectWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataPublishingsubjectUpdateInput[] | null;
  create?: MetadataPublishingSubjectWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataPublishingsubjectUpdateInput[] | null;
  delete?: MetadataPublishingSubjectWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataQualifieduseprismabyCreateInput {
  connect?: MetadataQualifiedUsePrismaByWhereUniqueInput[] | null;
  create?: MetadataQualifiedUsePrismaByWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataQualifieduseprismabyUpdateInput {
  connect?: MetadataQualifiedUsePrismaByWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataQualifieduseprismabyUpdateInput[] | null;
  create?: MetadataQualifiedUsePrismaByWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataQualifieduseprismabyUpdateInput[] | null;
  delete?: MetadataQualifiedUsePrismaByWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataQualifieduseprismadateCreateInput {
  connect?: MetadataQualifiedUsePrismaDateWhereUniqueInput[] | null;
  create?: MetadataQualifiedUsePrismaDateWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataQualifieduseprismadateUpdateInput {
  connect?: MetadataQualifiedUsePrismaDateWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataQualifieduseprismadateUpdateInput[] | null;
  create?: MetadataQualifiedUsePrismaDateWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataQualifieduseprismadateUpdateInput[] | null;
  delete?: MetadataQualifiedUsePrismaDateWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataQualifieduseprismadurationCreateInput {
  connect?: MetadataQualifiedUsePrismaDurationWhereUniqueInput[] | null;
  create?: MetadataQualifiedUsePrismaDurationWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataQualifieduseprismadurationUpdateInput {
  connect?: MetadataQualifiedUsePrismaDurationWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataQualifieduseprismadurationUpdateInput[] | null;
  create?: MetadataQualifiedUsePrismaDurationWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataQualifieduseprismadurationUpdateInput[] | null;
  delete?: MetadataQualifiedUsePrismaDurationWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataQualifieduseprismasupportCreateInput {
  connect?: MetadataQualifiedUsePrismaSupportWhereUniqueInput[] | null;
  create?: MetadataQualifiedUsePrismaSupportWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataQualifieduseprismasupportUpdateInput {
  connect?: MetadataQualifiedUsePrismaSupportWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataQualifieduseprismasupportUpdateInput[] | null;
  create?: MetadataQualifiedUsePrismaSupportWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataQualifieduseprismasupportUpdateInput[] | null;
  delete?: MetadataQualifiedUsePrismaSupportWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataRatingCreateInput {
  connect?: MetadataRatingWhereUniqueInput[] | null;
  create?: MetadataRatingWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataRatingUpdateInput {
  connect?: MetadataRatingWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataRatingUpdateInput[] | null;
  create?: MetadataRatingWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataRatingUpdateInput[] | null;
  delete?: MetadataRatingWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataReferencedateCreateInput {
  connect?: MetadataReferenceDateWhereUniqueInput[] | null;
  create?: MetadataReferenceDateWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataReferencedateUpdateInput {
  connect?: MetadataReferenceDateWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataReferencedateUpdateInput[] | null;
  create?: MetadataReferenceDateWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataReferencedateUpdateInput[] | null;
  delete?: MetadataReferenceDateWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataReferencenumberCreateInput {
  connect?: MetadataReferenceNumberWhereUniqueInput[] | null;
  create?: MetadataReferenceNumberWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataReferencenumberUpdateInput {
  connect?: MetadataReferenceNumberWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataReferencenumberUpdateInput[] | null;
  create?: MetadataReferenceNumberWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataReferencenumberUpdateInput[] | null;
  delete?: MetadataReferenceNumberWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataReferenceserviceCreateInput {
  connect?: MetadataReferenceServiceWhereUniqueInput[] | null;
  create?: MetadataReferenceServiceWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataReferenceserviceUpdateInput {
  connect?: MetadataReferenceServiceWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataReferenceserviceUpdateInput[] | null;
  create?: MetadataReferenceServiceWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataReferenceserviceUpdateInput[] | null;
  delete?: MetadataReferenceServiceWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataReleasedateCreateInput {
  connect?: MetadataReleaseDateWhereUniqueInput[] | null;
  create?: MetadataReleaseDateWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataReleasedateUpdateInput {
  connect?: MetadataReleaseDateWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataReleasedateUpdateInput[] | null;
  create?: MetadataReleaseDateWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataReleasedateUpdateInput[] | null;
  delete?: MetadataReleaseDateWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataReleasetimeCreateInput {
  connect?: MetadataReleaseTimeWhereUniqueInput[] | null;
  create?: MetadataReleaseTimeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataReleasetimeUpdateInput {
  connect?: MetadataReleaseTimeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataReleasetimeUpdateInput[] | null;
  create?: MetadataReleaseTimeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataReleasetimeUpdateInput[] | null;
  delete?: MetadataReleaseTimeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataRequiredpermissionCreateInput {
  connect?: MetadataRequiredPermissionWhereUniqueInput[] | null;
  create?: MetadataRequiredPermissionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataRequiredpermissionUpdateInput {
  connect?: MetadataRequiredPermissionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataRequiredpermissionUpdateInput[] | null;
  create?: MetadataRequiredPermissionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataRequiredpermissionUpdateInput[] | null;
  delete?: MetadataRequiredPermissionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataResolutionkindCreateInput {
  connect?: MetadataResolutionKindWhereUniqueInput[] | null;
  create?: MetadataResolutionKindWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataResolutionkindUpdateInput {
  connect?: MetadataResolutionKindWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataResolutionkindUpdateInput[] | null;
  create?: MetadataResolutionKindWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataResolutionkindUpdateInput[] | null;
  delete?: MetadataResolutionKindWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataRightsCreateInput {
  connect?: MetadataRightsWhereUniqueInput[] | null;
  create?: MetadataRightsWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataRightsUpdateInput {
  connect?: MetadataRightsWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataRightsUpdateInput[] | null;
  create?: MetadataRightsWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataRightsUpdateInput[] | null;
  delete?: MetadataRightsWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataRoyaltyfreeCreateInput {
  connect?: MetadataRoyaltyfreeWhereUniqueInput[] | null;
  create?: MetadataRoyaltyfreeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataRoyaltyfreeUpdateInput {
  connect?: MetadataRoyaltyfreeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataRoyaltyfreeUpdateInput[] | null;
  create?: MetadataRoyaltyfreeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataRoyaltyfreeUpdateInput[] | null;
  delete?: MetadataRoyaltyfreeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataSceneCreateInput {
  connect?: MetadataSceneWhereUniqueInput[] | null;
  create?: MetadataSceneWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataSceneUpdateInput {
  connect?: MetadataSceneWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataSceneUpdateInput[] | null;
  create?: MetadataSceneWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataSceneUpdateInput[] | null;
  delete?: MetadataSceneWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataSectionCreateInput {
  connect?: MetadataSectionWhereUniqueInput[] | null;
  create?: MetadataSectionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataSectionUpdateInput {
  connect?: MetadataSectionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataSectionUpdateInput[] | null;
  create?: MetadataSectionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataSectionUpdateInput[] | null;
  delete?: MetadataSectionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataSenttoCreateInput {
  connect?: MetadataSentToWhereUniqueInput[] | null;
  create?: MetadataSentToWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataSenttoUpdateInput {
  connect?: MetadataSentToWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataSenttoUpdateInput[] | null;
  create?: MetadataSentToWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataSenttoUpdateInput[] | null;
  delete?: MetadataSentToWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataSerialnumberCreateInput {
  connect?: MetadataSerialNumberWhereUniqueInput[] | null;
  create?: MetadataSerialNumberWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataSerialnumberUpdateInput {
  connect?: MetadataSerialNumberWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataSerialnumberUpdateInput[] | null;
  create?: MetadataSerialNumberWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataSerialnumberUpdateInput[] | null;
  delete?: MetadataSerialNumberWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataSeriesdatetimeCreateInput {
  connect?: MetadataSeriesDateTimeWhereUniqueInput[] | null;
  create?: MetadataSeriesDateTimeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataSeriesdatetimeUpdateInput {
  connect?: MetadataSeriesDateTimeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataSeriesdatetimeUpdateInput[] | null;
  create?: MetadataSeriesDateTimeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataSeriesdatetimeUpdateInput[] | null;
  delete?: MetadataSeriesDateTimeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataSeriesdescriptionCreateInput {
  connect?: MetadataSeriesDescriptionWhereUniqueInput[] | null;
  create?: MetadataSeriesDescriptionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataSeriesdescriptionUpdateInput {
  connect?: MetadataSeriesDescriptionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataSeriesdescriptionUpdateInput[] | null;
  create?: MetadataSeriesDescriptionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataSeriesdescriptionUpdateInput[] | null;
  delete?: MetadataSeriesDescriptionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataSeriesmodalityCreateInput {
  connect?: MetadataSeriesModalityWhereUniqueInput[] | null;
  create?: MetadataSeriesModalityWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataSeriesmodalityUpdateInput {
  connect?: MetadataSeriesModalityWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataSeriesmodalityUpdateInput[] | null;
  create?: MetadataSeriesModalityWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataSeriesmodalityUpdateInput[] | null;
  delete?: MetadataSeriesModalityWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataSeriesnumberCreateInput {
  connect?: MetadataSeriesNumberWhereUniqueInput[] | null;
  create?: MetadataSeriesNumberWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataSeriesnumberUpdateInput {
  connect?: MetadataSeriesNumberWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataSeriesnumberUpdateInput[] | null;
  create?: MetadataSeriesNumberWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataSeriesnumberUpdateInput[] | null;
  delete?: MetadataSeriesNumberWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataShortuniqueidCreateInput {
  connect?: MetadataShortUniqueIdWhereUniqueInput[] | null;
  create?: MetadataShortUniqueIdWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataShortuniqueidUpdateInput {
  connect?: MetadataShortUniqueIdWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataShortuniqueidUpdateInput[] | null;
  create?: MetadataShortUniqueIdWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataShortuniqueidUpdateInput[] | null;
  delete?: MetadataShortUniqueIdWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataSourceCreateInput {
  connect?: MetadataSourceWhereUniqueInput[] | null;
  create?: MetadataSourceWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataSourceUpdateInput {
  connect?: MetadataSourceWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataSourceUpdateInput[] | null;
  create?: MetadataSourceWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataSourceUpdateInput[] | null;
  delete?: MetadataSourceWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataStateCreateInput {
  connect?: MetadataStateWhereUniqueInput[] | null;
  create?: MetadataStateWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataStateUpdateInput {
  connect?: MetadataStateWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataStateUpdateInput[] | null;
  create?: MetadataStateWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataStateUpdateInput[] | null;
  delete?: MetadataStateWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataStudydatetimeCreateInput {
  connect?: MetadataStudyDateTimeWhereUniqueInput[] | null;
  create?: MetadataStudyDateTimeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataStudydatetimeUpdateInput {
  connect?: MetadataStudyDateTimeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataStudydatetimeUpdateInput[] | null;
  create?: MetadataStudyDateTimeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataStudydatetimeUpdateInput[] | null;
  delete?: MetadataStudyDateTimeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataStudydescriptionCreateInput {
  connect?: MetadataStudyDescriptionWhereUniqueInput[] | null;
  create?: MetadataStudyDescriptionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataStudydescriptionUpdateInput {
  connect?: MetadataStudyDescriptionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataStudydescriptionUpdateInput[] | null;
  create?: MetadataStudyDescriptionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataStudydescriptionUpdateInput[] | null;
  delete?: MetadataStudyDescriptionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataStudyidCreateInput {
  connect?: MetadataStudyIdWhereUniqueInput[] | null;
  create?: MetadataStudyIdWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataStudyidUpdateInput {
  connect?: MetadataStudyIdWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataStudyidUpdateInput[] | null;
  create?: MetadataStudyIdWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataStudyidUpdateInput[] | null;
  delete?: MetadataStudyIdWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataStudyphysicianCreateInput {
  connect?: MetadataStudyPhysicianWhereUniqueInput[] | null;
  create?: MetadataStudyPhysicianWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataStudyphysicianUpdateInput {
  connect?: MetadataStudyPhysicianWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataStudyphysicianUpdateInput[] | null;
  create?: MetadataStudyPhysicianWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataStudyphysicianUpdateInput[] | null;
  delete?: MetadataStudyPhysicianWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataSubjectCreateInput {
  connect?: MetadataSubjectWhereUniqueInput[] | null;
  create?: MetadataSubjectWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataSubjectUpdateInput {
  connect?: MetadataSubjectWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataSubjectUpdateInput[] | null;
  create?: MetadataSubjectWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataSubjectUpdateInput[] | null;
  delete?: MetadataSubjectWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataSubjectcodeCreateInput {
  connect?: MetadataSubjectCodeWhereUniqueInput[] | null;
  create?: MetadataSubjectCodeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataSubjectcodeUpdateInput {
  connect?: MetadataSubjectCodeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataSubjectcodeUpdateInput[] | null;
  create?: MetadataSubjectCodeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataSubjectcodeUpdateInput[] | null;
  delete?: MetadataSubjectCodeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataSubjectprismaCreateInput {
  connect?: MetadataSubjectPrismaWhereUniqueInput[] | null;
  create?: MetadataSubjectPrismaWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataSubjectprismaUpdateInput {
  connect?: MetadataSubjectPrismaWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataSubjectprismaUpdateInput[] | null;
  create?: MetadataSubjectPrismaWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataSubjectprismaUpdateInput[] | null;
  delete?: MetadataSubjectPrismaWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataSupplementalcategoriesCreateInput {
  connect?: MetadataSupplementalCategoriesWhereUniqueInput[] | null;
  create?: MetadataSupplementalCategoriesWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataSupplementalcategoriesUpdateInput {
  connect?: MetadataSupplementalCategoriesWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataSupplementalcategoriesUpdateInput[] | null;
  create?: MetadataSupplementalCategoriesWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataSupplementalcategoriesUpdateInput[] | null;
  delete?: MetadataSupplementalCategoriesWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataTargetversionCreateInput {
  connect?: MetadataTargetVersionWhereUniqueInput[] | null;
  create?: MetadataTargetVersionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataTargetversionUpdateInput {
  connect?: MetadataTargetVersionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataTargetversionUpdateInput[] | null;
  create?: MetadataTargetVersionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataTargetversionUpdateInput[] | null;
  delete?: MetadataTargetVersionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataTitleCreateInput {
  connect?: MetadataTitleWhereUniqueInput[] | null;
  create?: MetadataTitleWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataTitleUpdateInput {
  connect?: MetadataTitleWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataTitleUpdateInput[] | null;
  create?: MetadataTitleWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataTitleUpdateInput[] | null;
  delete?: MetadataTitleWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataTonecurveCreateInput {
  connect?: MetadataToneCurveWhereUniqueInput[] | null;
  create?: MetadataToneCurveWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataTonecurveUpdateInput {
  connect?: MetadataToneCurveWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataTonecurveUpdateInput[] | null;
  create?: MetadataToneCurveWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataTonecurveUpdateInput[] | null;
  delete?: MetadataToneCurveWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataTransferredbyCreateInput {
  connect?: MetadataTransferredByWhereUniqueInput[] | null;
  create?: MetadataTransferredByWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataTransferredbyUpdateInput {
  connect?: MetadataTransferredByWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataTransferredbyUpdateInput[] | null;
  create?: MetadataTransferredByWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataTransferredbyUpdateInput[] | null;
  delete?: MetadataTransferredByWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataTransferredbyemailCreateInput {
  connect?: MetadataTransferredByEmailWhereUniqueInput[] | null;
  create?: MetadataTransferredByEmailWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataTransferredbyemailUpdateInput {
  connect?: MetadataTransferredByEmailWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataTransferredbyemailUpdateInput[] | null;
  create?: MetadataTransferredByEmailWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataTransferredbyemailUpdateInput[] | null;
  delete?: MetadataTransferredByEmailWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataTransferredbyfullnameCreateInput {
  connect?: MetadataTransferredByFullNameWhereUniqueInput[] | null;
  create?: MetadataTransferredByFullNameWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataTransferredbyfullnameUpdateInput {
  connect?: MetadataTransferredByFullNameWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataTransferredbyfullnameUpdateInput[] | null;
  create?: MetadataTransferredByFullNameWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataTransferredbyfullnameUpdateInput[] | null;
  delete?: MetadataTransferredByFullNameWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataTransmissionreferenceCreateInput {
  connect?: MetadataTransmissionReferenceWhereUniqueInput[] | null;
  create?: MetadataTransmissionReferenceWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataTransmissionreferenceUpdateInput {
  connect?: MetadataTransmissionReferenceWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataTransmissionreferenceUpdateInput[] | null;
  create?: MetadataTransmissionReferenceWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataTransmissionreferenceUpdateInput[] | null;
  delete?: MetadataTransmissionReferenceWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataTypeCreateInput {
  connect?: MetadataTypeWhereUniqueInput[] | null;
  create?: MetadataTypeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataTypeUpdateInput {
  connect?: MetadataTypeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataTypeUpdateInput[] | null;
  create?: MetadataTypeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataTypeUpdateInput[] | null;
  delete?: MetadataTypeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUndersubjectCreateInput {
  connect?: MetadataUndersubjectWhereUniqueInput[] | null;
  create?: MetadataUndersubjectWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUndersubjectUpdateInput {
  connect?: MetadataUndersubjectWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUndersubjectUpdateInput[] | null;
  create?: MetadataUndersubjectWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUndersubjectUpdateInput[] | null;
  delete?: MetadataUndersubjectWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUnderundersubjectCreateInput {
  connect?: MetadataUnderUnderSubjectWhereUniqueInput[] | null;
  create?: MetadataUnderUnderSubjectWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUnderundersubjectUpdateInput {
  connect?: MetadataUnderUnderSubjectWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUnderundersubjectUpdateInput[] | null;
  create?: MetadataUnderUnderSubjectWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUnderundersubjectUpdateInput[] | null;
  delete?: MetadataUnderUnderSubjectWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUniqueidCreateInput {
  connect?: MetadataUniqueIdWhereUniqueInput[] | null;
  create?: MetadataUniqueIdWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUniqueidUpdateInput {
  connect?: MetadataUniqueIdWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUniqueidUpdateInput[] | null;
  create?: MetadataUniqueIdWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUniqueidUpdateInput[] | null;
  delete?: MetadataUniqueIdWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUnitCreateInput {
  connect?: MetadataUnitWhereUniqueInput[] | null;
  create?: MetadataUnitWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUnitUpdateInput {
  connect?: MetadataUnitWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUnitUpdateInput[] | null;
  create?: MetadataUnitWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUnitUpdateInput[] | null;
  delete?: MetadataUnitWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUnitshortnameCreateInput {
  connect?: MetadataUnitShortNameWhereUniqueInput[] | null;
  create?: MetadataUnitShortNameWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUnitshortnameUpdateInput {
  connect?: MetadataUnitShortNameWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUnitshortnameUpdateInput[] | null;
  create?: MetadataUnitShortNameWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUnitshortnameUpdateInput[] | null;
  delete?: MetadataUnitShortNameWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUploadedbyCreateInput {
  connect?: MetadataUploadedByWhereUniqueInput[] | null;
  create?: MetadataUploadedByWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUploadedbyUpdateInput {
  connect?: MetadataUploadedByWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUploadedbyUpdateInput[] | null;
  create?: MetadataUploadedByWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUploadedbyUpdateInput[] | null;
  delete?: MetadataUploadedByWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUploadedbyfullnameCreateInput {
  connect?: MetadataUploadedByFullNameWhereUniqueInput[] | null;
  create?: MetadataUploadedByFullNameWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUploadedbyfullnameUpdateInput {
  connect?: MetadataUploadedByFullNameWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUploadedbyfullnameUpdateInput[] | null;
  create?: MetadataUploadedByFullNameWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUploadedbyfullnameUpdateInput[] | null;
  delete?: MetadataUploadedByFullNameWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUploadtimeCreateInput {
  connect?: MetadataUploadTimeWhereUniqueInput[] | null;
  create?: MetadataUploadTimeWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUploadtimeUpdateInput {
  connect?: MetadataUploadTimeWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUploadtimeUpdateInput[] | null;
  create?: MetadataUploadTimeWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUploadtimeUpdateInput[] | null;
  delete?: MetadataUploadTimeWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUrgencyCreateInput {
  connect?: MetadataUrgencyWhereUniqueInput[] | null;
  create?: MetadataUrgencyWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUrgencyUpdateInput {
  connect?: MetadataUrgencyWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUrgencyUpdateInput[] | null;
  create?: MetadataUrgencyWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUrgencyUpdateInput[] | null;
  delete?: MetadataUrgencyWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUsagetermsCreateInput {
  connect?: MetadataUsageTermsWhereUniqueInput[] | null;
  create?: MetadataUsageTermsWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUsagetermsUpdateInput {
  connect?: MetadataUsageTermsWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUsagetermsUpdateInput[] | null;
  create?: MetadataUsageTermsWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUsagetermsUpdateInput[] | null;
  delete?: MetadataUsageTermsWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUsercommentCreateInput {
  connect?: MetadataUserCommentWhereUniqueInput[] | null;
  create?: MetadataUserCommentWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUsercommentUpdateInput {
  connect?: MetadataUserCommentWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUsercommentUpdateInput[] | null;
  create?: MetadataUserCommentWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUsercommentUpdateInput[] | null;
  delete?: MetadataUserCommentWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUserdefined195CreateInput {
  connect?: MetadataUserDefined195WhereUniqueInput[] | null;
  create?: MetadataUserDefined195WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUserdefined195UpdateInput {
  connect?: MetadataUserDefined195WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUserdefined195UpdateInput[] | null;
  create?: MetadataUserDefined195WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUserdefined195UpdateInput[] | null;
  delete?: MetadataUserDefined195WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUserdefined237CreateInput {
  connect?: MetadataUserDefined237WhereUniqueInput[] | null;
  create?: MetadataUserDefined237WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUserdefined237UpdateInput {
  connect?: MetadataUserDefined237WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUserdefined237UpdateInput[] | null;
  create?: MetadataUserDefined237WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUserdefined237UpdateInput[] | null;
  delete?: MetadataUserDefined237WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUserdefined238CreateInput {
  connect?: MetadataUserDefined238WhereUniqueInput[] | null;
  create?: MetadataUserDefined238WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUserdefined238UpdateInput {
  connect?: MetadataUserDefined238WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUserdefined238UpdateInput[] | null;
  create?: MetadataUserDefined238WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUserdefined238UpdateInput[] | null;
  delete?: MetadataUserDefined238WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUserdefined239CreateInput {
  connect?: MetadataUserDefined239WhereUniqueInput[] | null;
  create?: MetadataUserDefined239WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUserdefined239UpdateInput {
  connect?: MetadataUserDefined239WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUserdefined239UpdateInput[] | null;
  create?: MetadataUserDefined239WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUserdefined239UpdateInput[] | null;
  delete?: MetadataUserDefined239WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUserdefined242CreateInput {
  connect?: MetadataUserDefined242WhereUniqueInput[] | null;
  create?: MetadataUserDefined242WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUserdefined242UpdateInput {
  connect?: MetadataUserDefined242WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUserdefined242UpdateInput[] | null;
  create?: MetadataUserDefined242WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUserdefined242UpdateInput[] | null;
  delete?: MetadataUserDefined242WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataUserdefined62CreateInput {
  connect?: MetadataUserDefined62WhereUniqueInput[] | null;
  create?: MetadataUserDefined62WithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataUserdefined62UpdateInput {
  connect?: MetadataUserDefined62WhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataUserdefined62UpdateInput[] | null;
  create?: MetadataUserDefined62WithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataUserdefined62UpdateInput[] | null;
  delete?: MetadataUserDefined62WhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataValidCreateInput {
  connect?: MetadataValidWhereUniqueInput[] | null;
  create?: MetadataValidWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataValidUpdateInput {
  connect?: MetadataValidWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataValidUpdateInput[] | null;
  create?: MetadataValidWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataValidUpdateInput[] | null;
  delete?: MetadataValidWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataVersionCreateInput {
  connect?: MetadataVersionWhereUniqueInput[] | null;
  create?: MetadataVersionWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataVersionUpdateInput {
  connect?: MetadataVersionWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataVersionUpdateInput[] | null;
  create?: MetadataVersionWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataVersionUpdateInput[] | null;
  delete?: MetadataVersionWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataWebstatementCreateInput {
  connect?: MetadataWebStatementWhereUniqueInput[] | null;
  create?: MetadataWebStatementWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataWebstatementUpdateInput {
  connect?: MetadataWebStatementWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataWebstatementUpdateInput[] | null;
  create?: MetadataWebStatementWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataWebstatementUpdateInput[] | null;
  delete?: MetadataWebStatementWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataWorkflowkindCreateInput {
  connect?: MetadataWorkflowKindWhereUniqueInput[] | null;
  create?: MetadataWorkflowKindWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataWorkflowkindUpdateInput {
  connect?: MetadataWorkflowKindWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataWorkflowkindUpdateInput[] | null;
  create?: MetadataWorkflowKindWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataWorkflowkindUpdateInput[] | null;
  delete?: MetadataWorkflowKindWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataXmpfilestampsCreateInput {
  connect?: MetadataXmpFileStampsWhereUniqueInput[] | null;
  create?: MetadataXmpFileStampsWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataXmpfilestampsUpdateInput {
  connect?: MetadataXmpFileStampsWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataXmpfilestampsUpdateInput[] | null;
  create?: MetadataXmpFileStampsWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataXmpfilestampsUpdateInput[] | null;
  delete?: MetadataXmpFileStampsWhereUniqueInput[] | null;
}

export interface PhotoNestedMetadataXmphistoryCreateInput {
  connect?: MetadataXmpHistoryWhereUniqueInput[] | null;
  create?: MetadataXmpHistoryWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedMetadataXmphistoryUpdateInput {
  connect?: MetadataXmpHistoryWhereUniqueInput[] | null;
  update?: PhotoNestedUpdateMetadataXmphistoryUpdateInput[] | null;
  create?: MetadataXmpHistoryWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertMetadataXmphistoryUpdateInput[] | null;
  delete?: MetadataXmpHistoryWhereUniqueInput[] | null;
}

export interface PhotoNestedPhotoMetadatasCreateInput {
  create?: PhotoMetadataWithForcedPhotoCreateInput[] | null;
}

export interface PhotoNestedPhotoMetadatasUpdateInput {
  update?: PhotoNestedUpdatePhotoMetadatasUpdateInput[] | null;
  create?: PhotoMetadataWithForcedPhotoCreateInput[] | null;
  upsert?: PhotoNestedUpsertPhotoMetadatasUpdateInput[] | null;
  delete?: PhotoMetadataWhereUniqueInput[] | null;
}

export interface PhotoNestedUpdateAgencyCreateInput {
  where: AgencyWhereUniqueInput;
  data: AgencyUpdateInput;
}

export interface PhotoNestedUpdateAgencyUpdateInput {
  where: AgencyWhereUniqueInput;
  data: AgencyUpdateInput;
}

export interface PhotoNestedUpdateArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface PhotoNestedUpdateArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  data: ArchiveUpdateInput;
}

export interface PhotoNestedUpdateCategoryCreateInput {
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateInput;
}

export interface PhotoNestedUpdateCategoryUpdateInput {
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateInput;
}

export interface PhotoNestedUpdateDeliverySubjectCreateInput {
  where: DeliverySubjectWhereUniqueInput;
  data: DeliverySubjectUpdateInput;
}

export interface PhotoNestedUpdateDeliverySubjectUpdateInput {
  where: DeliverySubjectWhereUniqueInput;
  data: DeliverySubjectUpdateInput;
}

export interface PhotoNestedUpdateImportQueueUpdateInput {
  where: ImportQueueWithOptionalPhotoWhereUniqueInput;
  data: ImportQueueWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataAccessrightsUpdateInput {
  where: MetadataAccessRightsWhereUniqueInput;
  data: MetadataAccessRightsWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataAddlmodelinfoUpdateInput {
  where: MetadataAddlModelInfoWhereUniqueInput;
  data: MetadataAddlModelInfoWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataArchiveChildUpdateInput {
  where: MetadataArchiveChildWhereUniqueInput;
  data: MetadataArchiveChildWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataArchiveParentUpdateInput {
  where: MetadataArchiveParentWhereUniqueInput;
  data: MetadataArchiveParentWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataAssetstateUpdateInput {
  where: MetadataAssetStateWhereUniqueInput;
  data: MetadataAssetStateWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataAttributionnameUpdateInput {
  where: MetadataAttributionNameWhereUniqueInput;
  data: MetadataAttributionNameWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataAttributionurlUpdateInput {
  where: MetadataAttributionUrlWhereUniqueInput;
  data: MetadataAttributionUrlWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataAudienceUpdateInput {
  where: MetadataAudienceWhereUniqueInput;
  data: MetadataAudienceWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataAuthorspositionUpdateInput {
  where: MetadataAuthorsPositionWhereUniqueInput;
  data: MetadataAuthorsPositionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataBackupnameUpdateInput {
  where: MetadataBackupNameWhereUniqueInput;
  data: MetadataBackupNameWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataBitmapgrayscalepictureUpdateInput {
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
  data: MetadataBitmapGrayscalePictureWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataBrandUpdateInput {
  where: MetadataBrandWhereUniqueInput;
  data: MetadataBrandWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataBrandprismaUpdateInput {
  where: MetadataBrandPrismaWhereUniqueInput;
  data: MetadataBrandPrismaWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataBrandshortnameUpdateInput {
  where: MetadataBrandShortNameWhereUniqueInput;
  data: MetadataBrandShortNameWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCaptionwriterUpdateInput {
  where: MetadataCaptionWriterWhereUniqueInput;
  data: MetadataCaptionWriterWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCategoryUpdateInput {
  where: MetadataCategoryWhereUniqueInput;
  data: MetadataCategoryWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCategoryprismaUpdateInput {
  where: MetadataCategoryPrismaWhereUniqueInput;
  data: MetadataCategoryPrismaWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCertificateUpdateInput {
  where: MetadataCertificateWhereUniqueInput;
  data: MetadataCertificateWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCiadrcityUpdateInput {
  where: MetadataCiAdrCityWhereUniqueInput;
  data: MetadataCiAdrCityWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCiadrctryUpdateInput {
  where: MetadataCiAdrCtryWhereUniqueInput;
  data: MetadataCiAdrCtryWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCiadrextadrUpdateInput {
  where: MetadataCiAdrExtadrWhereUniqueInput;
  data: MetadataCiAdrExtadrWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCiadrpcodeUpdateInput {
  where: MetadataCiAdrPcodeWhereUniqueInput;
  data: MetadataCiAdrPcodeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCiadrregionUpdateInput {
  where: MetadataCiAdrRegionWhereUniqueInput;
  data: MetadataCiAdrRegionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCiemailworkUpdateInput {
  where: MetadataCiEmailWorkWhereUniqueInput;
  data: MetadataCiEmailWorkWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCitelworkUpdateInput {
  where: MetadataCiTelWorkWhereUniqueInput;
  data: MetadataCiTelWorkWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCityUpdateInput {
  where: MetadataCityWhereUniqueInput;
  data: MetadataCityWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCiurlworkUpdateInput {
  where: MetadataCiUrlWorkWhereUniqueInput;
  data: MetadataCiUrlWorkWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataClassifyUpdateInput {
  where: MetadataClassifyWhereUniqueInput;
  data: MetadataClassifyWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCompanyUpdateInput {
  where: MetadataCompanyWhereUniqueInput;
  data: MetadataCompanyWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCompanyshortnameUpdateInput {
  where: MetadataCompanyShortNameWhereUniqueInput;
  data: MetadataCompanyShortNameWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataConfidentialityUpdateInput {
  where: MetadataConfidentialityWhereUniqueInput;
  data: MetadataConfidentialityWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataContainedinUpdateInput {
  where: MetadataContainedInWhereUniqueInput;
  data: MetadataContainedInWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataContentmediakindUpdateInput {
  where: MetadataContentMediaKindWhereUniqueInput;
  data: MetadataContentMediaKindWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataContentvalueUpdateInput {
  where: MetadataContentValueWhereUniqueInput;
  data: MetadataContentValueWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCopyrightlayerUpdateInput {
  where: MetadataCopyrightLayerWhereUniqueInput;
  data: MetadataCopyrightLayerWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCountryUpdateInput {
  where: MetadataCountryWhereUniqueInput;
  data: MetadataCountryWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCountrycodeUpdateInput {
  where: MetadataCountryCodeWhereUniqueInput;
  data: MetadataCountryCodeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCreatedtimeUpdateInput {
  where: MetadataCreatedTimeWhereUniqueInput;
  data: MetadataCreatedTimeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCreatorUpdateInput {
  where: MetadataCreatorWhereUniqueInput;
  data: MetadataCreatorWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCreatortoolUpdateInput {
  where: MetadataCreatorToolWhereUniqueInput;
  data: MetadataCreatorToolWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCreditUpdateInput {
  where: MetadataCreditWhereUniqueInput;
  data: MetadataCreditWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCustomfield14UpdateInput {
  where: MetadataCustomField14WhereUniqueInput;
  data: MetadataCustomField14WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCustomfield15UpdateInput {
  where: MetadataCustomField15WhereUniqueInput;
  data: MetadataCustomField15WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCustomfield16UpdateInput {
  where: MetadataCustomField16WhereUniqueInput;
  data: MetadataCustomField16WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCustomfield17UpdateInput {
  where: MetadataCustomField17WhereUniqueInput;
  data: MetadataCustomField17WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCustomfield20UpdateInput {
  where: MetadataCustomField20WhereUniqueInput;
  data: MetadataCustomField20WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCustomfield5UpdateInput {
  where: MetadataCustomField5WhereUniqueInput;
  data: MetadataCustomField5WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCustomfield6UpdateInput {
  where: MetadataCustomField6WhereUniqueInput;
  data: MetadataCustomField6WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCustomfield7UpdateInput {
  where: MetadataCustomField7WhereUniqueInput;
  data: MetadataCustomField7WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataCustomfield9UpdateInput {
  where: MetadataCustomField9WhereUniqueInput;
  data: MetadataCustomField9WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDatecreatedUpdateInput {
  where: MetadataDateCreatedWhereUniqueInput;
  data: MetadataDateCreatedWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDatetimedigitizedUpdateInput {
  where: MetadataDateTimeDigitizedWhereUniqueInput;
  data: MetadataDateTimeDigitizedWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDatetimeoriginalUpdateInput {
  where: MetadataDateTimeOriginalWhereUniqueInput;
  data: MetadataDateTimeOriginalWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliveryaccountUpdateInput {
  where: MetadataDeliveryAccountWhereUniqueInput;
  data: MetadataDeliveryAccountWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliverycompanyUpdateInput {
  where: MetadataDeliveryCompanyWhereUniqueInput;
  data: MetadataDeliveryCompanyWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliverycopyrightUpdateInput {
  where: MetadataDeliveryCopyrightWhereUniqueInput;
  data: MetadataDeliveryCopyrightWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliverydatefolderUpdateInput {
  where: MetadataDeliveryDateFolderWhereUniqueInput;
  data: MetadataDeliveryDateFolderWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliverydatetimeUpdateInput {
  where: MetadataDeliveryDateTimeWhereUniqueInput;
  data: MetadataDeliveryDateTimeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliverydccreatorUpdateInput {
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
  data: MetadataDeliveryDcCreatorWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliverydcrightsUpdateInput {
  where: MetadataDeliveryDcRightsWhereUniqueInput;
  data: MetadataDeliveryDcRightsWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliveryfiletypeUpdateInput {
  where: MetadataDeliveryFileTypeWhereUniqueInput;
  data: MetadataDeliveryFileTypeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliveryfolderUpdateInput {
  where: MetadataDeliveryFolderWhereUniqueInput;
  data: MetadataDeliveryFolderWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliverykindUpdateInput {
  where: MetadataDeliveryKindWhereUniqueInput;
  data: MetadataDeliveryKindWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliverypathUpdateInput {
  where: MetadataDeliveryPathWhereUniqueInput;
  data: MetadataDeliveryPathWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliverypersonshownintheimageUpdateInput {
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
  data: MetadataDeliveryPersonShownintheImageWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliveryphotoshopcreditUpdateInput {
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
  data: MetadataDeliveryPhotoshopCreditWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliveryphotoshopsourceUpdateInput {
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
  data: MetadataDeliveryPhotoshopSourceWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliveryserviceUpdateInput {
  where: MetadataDeliveryServiceWhereUniqueInput;
  data: MetadataDeliveryServiceWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliverysubjectUpdateInput {
  where: MetadataDeliverySubjectWhereUniqueInput;
  data: MetadataDeliverySubjectWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDeliveryundersubjectUpdateInput {
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
  data: MetadataDeliveryUnderSubjectWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDepartmentUpdateInput {
  where: MetadataDepartmentWhereUniqueInput;
  data: MetadataDepartmentWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDescriptionUpdateInput {
  where: MetadataDescriptionWhereUniqueInput;
  data: MetadataDescriptionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDigitalasseturlUpdateInput {
  where: MetadataDigitalAssetUrlWhereUniqueInput;
  data: MetadataDigitalAssetUrlWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDivisionUpdateInput {
  where: MetadataDivisionWhereUniqueInput;
  data: MetadataDivisionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataDocumenttextUpdateInput {
  where: MetadataDocumentTextWhereUniqueInput;
  data: MetadataDocumentTextWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataEditionUpdateInput {
  where: MetadataEditionWhereUniqueInput;
  data: MetadataEditionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataEditorialversionUpdateInput {
  where: MetadataEditorialVersionWhereUniqueInput;
  data: MetadataEditorialVersionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataEditstatusUpdateInput {
  where: MetadataEditStatusWhereUniqueInput;
  data: MetadataEditStatusWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataEnvironnementphotoUpdateInput {
  where: MetadataEnvironnementPhotoWhereUniqueInput;
  data: MetadataEnvironnementPhotoWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataEquipmentinstitutionUpdateInput {
  where: MetadataEquipmentInstitutionWhereUniqueInput;
  data: MetadataEquipmentInstitutionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataEquipmentmanufacturerUpdateInput {
  where: MetadataEquipmentManufacturerWhereUniqueInput;
  data: MetadataEquipmentManufacturerWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataEventUpdateInput {
  where: MetadataEventWhereUniqueInput;
  data: MetadataEventWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataExifUpdateInput {
  where: MetadataExifWhereUniqueInput;
  data: MetadataExifWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataFabstorageUpdateInput {
  where: MetadataFabStorageWhereUniqueInput;
  data: MetadataFabStorageWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataFilecheckforUpdateInput {
  where: MetadataFileCheckForWhereUniqueInput;
  data: MetadataFileCheckForWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataFileinfobitsperpixelUpdateInput {
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
  data: MetadataFileInfoBitsPerPixelWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataFileinfoframesUpdateInput {
  where: MetadataFileInfoFramesWhereUniqueInput;
  data: MetadataFileInfoFramesWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataFileinfophotometricinterpretationUpdateInput {
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
  data: MetadataFileInfoPhotometricInterpretationWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataFileinfotransfersyntaxUpdateInput {
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
  data: MetadataFileInfoTransferSyntaxWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataFilestageUpdateInput {
  where: MetadataFileStageWhereUniqueInput;
  data: MetadataFileStageWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataFilestatusUpdateInput {
  where: MetadataFileStatusWhereUniqueInput;
  data: MetadataFileStatusWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataFiletypeUpdateInput {
  where: MetadataFileTypeWhereUniqueInput;
  data: MetadataFileTypeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataFixtureidentifierUpdateInput {
  where: MetadataFixtureIdentifierWhereUniqueInput;
  data: MetadataFixtureIdentifierWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataFormatUpdateInput {
  where: MetadataFormatWhereUniqueInput;
  data: MetadataFormatWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataGpslatitudeUpdateInput {
  where: MetadataGpsLatitudeWhereUniqueInput;
  data: MetadataGpsLatitudeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataGpslongitudeUpdateInput {
  where: MetadataGpsLongitudeWhereUniqueInput;
  data: MetadataGpsLongitudeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataGpsprocessingmethodUpdateInput {
  where: MetadataGpsProcessingMethodWhereUniqueInput;
  data: MetadataGpsProcessingMethodWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataHeaderUpdateInput {
  where: MetadataHeaderWhereUniqueInput;
  data: MetadataHeaderWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataHeaderprismaUpdateInput {
  where: MetadataHeaderPrismaWhereUniqueInput;
  data: MetadataHeaderPrismaWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataHeadlineUpdateInput {
  where: MetadataHeadlineWhereUniqueInput;
  data: MetadataHeadlineWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataHistoryUpdateInput {
  where: MetadataHistoryWhereUniqueInput;
  data: MetadataHistoryWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataIccprofileUpdateInput {
  where: MetadataIccProfileWhereUniqueInput;
  data: MetadataIccProfileWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataIdSubjectUpdateInput {
  where: MetadataIdSubjectWhereUniqueInput;
  data: MetadataIdSubjectWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataIdassignmentUpdateInput {
  where: MetadataIdAssignmentWhereUniqueInput;
  data: MetadataIdAssignmentWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataIdmediacontentUpdateInput {
  where: MetadataIdMediaContentWhereUniqueInput;
  data: MetadataIdMediaContentWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataIdphotoprismaUpdateInput {
  where: MetadataIdPhotoPrismaWhereUniqueInput;
  data: MetadataIdPhotoPrismaWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataImagenotesUpdateInput {
  where: MetadataImageNotesWhereUniqueInput;
  data: MetadataImageNotesWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataImageuniqueidUpdateInput {
  where: MetadataImageUniqueIdWhereUniqueInput;
  data: MetadataImageUniqueIdWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataInstructionsUpdateInput {
  where: MetadataInstructionsWhereUniqueInput;
  data: MetadataInstructionsWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataIntellectualgenreUpdateInput {
  where: MetadataIntellectualGenreWhereUniqueInput;
  data: MetadataIntellectualGenreWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataIsospeedratingsUpdateInput {
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
  data: MetadataIsoSpeedRatingsWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataIssueUpdateInput {
  where: MetadataIssueWhereUniqueInput;
  data: MetadataIssueWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataJobidUpdateInput {
  where: MetadataJobIdWhereUniqueInput;
  data: MetadataJobIdWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataJobprocessingUpdateInput {
  where: MetadataJobProcessingWhereUniqueInput;
  data: MetadataJobProcessingWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataLayoutpictureeditingUpdateInput {
  where: MetadataLayoutPictureEditingWhereUniqueInput;
  data: MetadataLayoutPictureEditingWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataLayoutstorageUpdateInput {
  where: MetadataLayoutStorageWhereUniqueInput;
  data: MetadataLayoutStorageWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataLicenseUpdateInput {
  where: MetadataLicenseWhereUniqueInput;
  data: MetadataLicenseWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataLinksUpdateInput {
  where: MetadataLinksWhereUniqueInput;
  data: MetadataLinksWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataLocalcaptionUpdateInput {
  where: MetadataLocalCaptionWhereUniqueInput;
  data: MetadataLocalCaptionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataLocationUpdateInput {
  where: MetadataLocationWhereUniqueInput;
  data: MetadataLocationWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataMakeUpdateInput {
  where: MetadataMakeWhereUniqueInput;
  data: MetadataMakeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataManifestUpdateInput {
  where: MetadataManifestWhereUniqueInput;
  data: MetadataManifestWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataMarkedUpdateInput {
  where: MetadataMarkedWhereUniqueInput;
  data: MetadataMarkedWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataMasterdocumentidUpdateInput {
  where: MetadataMasterDocumentIdWhereUniqueInput;
  data: MetadataMasterDocumentIdWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataMaxavailheightUpdateInput {
  where: MetadataMaxAvailHeightWhereUniqueInput;
  data: MetadataMaxAvailHeightWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataMaxavailwidthUpdateInput {
  where: MetadataMaxAvailWidthWhereUniqueInput;
  data: MetadataMaxAvailWidthWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataMinormodelagedisclosureUpdateInput {
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
  data: MetadataMinorModelAgeDisclosureWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataModelUpdateInput {
  where: MetadataModelWhereUniqueInput;
  data: MetadataModelWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataModelageUpdateInput {
  where: MetadataModelAgeWhereUniqueInput;
  data: MetadataModelAgeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataModelreleaseidUpdateInput {
  where: MetadataModelReleaseIdWhereUniqueInput;
  data: MetadataModelReleaseIdWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataModelreleasestatusUpdateInput {
  where: MetadataModelReleaseStatusWhereUniqueInput;
  data: MetadataModelReleaseStatusWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataMorepermissionsUpdateInput {
  where: MetadataMorePermissionsWhereUniqueInput;
  data: MetadataMorePermissionsWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataObjectcycleUpdateInput {
  where: MetadataObjectCycleWhereUniqueInput;
  data: MetadataObjectCycleWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataOrganisationinimagenameUpdateInput {
  where: MetadataOrganisationInImageNameWhereUniqueInput;
  data: MetadataOrganisationInImageNameWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataOriginalcolorUpdateInput {
  where: MetadataOriginalColorWhereUniqueInput;
  data: MetadataOriginalColorWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataOriginaliccprofileUpdateInput {
  where: MetadataOriginalIccProfileWhereUniqueInput;
  data: MetadataOriginalIccProfileWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataOriginalnameUpdateInput {
  where: MetadataOriginalNameWhereUniqueInput;
  data: MetadataOriginalNameWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataOriginalweigthUpdateInput {
  where: MetadataOriginalWeigthWhereUniqueInput;
  data: MetadataOriginalWeigthWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataOwnerUpdateInput {
  where: MetadataOwnerWhereUniqueInput;
  data: MetadataOwnerWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataOwneridUpdateInput {
  where: MetadataOwnerIdWhereUniqueInput;
  data: MetadataOwnerIdWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPageUpdateInput {
  where: MetadataPageWhereUniqueInput;
  data: MetadataPageWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPageprismaUpdateInput {
  where: MetadataPagePrismaWhereUniqueInput;
  data: MetadataPagePrismaWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPaintbasedcorrectionsUpdateInput {
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
  data: MetadataPaintBasedCorrectionsWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPatientdobUpdateInput {
  where: MetadataPatientDobWhereUniqueInput;
  data: MetadataPatientDobWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPatientidUpdateInput {
  where: MetadataPatientIdWhereUniqueInput;
  data: MetadataPatientIdWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPatientnameUpdateInput {
  where: MetadataPatientNameWhereUniqueInput;
  data: MetadataPatientNameWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPatientsexUpdateInput {
  where: MetadataPatientSexWhereUniqueInput;
  data: MetadataPatientSexWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPersoninimageUpdateInput {
  where: MetadataPersonInImageWhereUniqueInput;
  data: MetadataPersonInImageWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPhotostorageUpdateInput {
  where: MetadataPhotoStorageWhereUniqueInput;
  data: MetadataPhotoStorageWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPrepresspictureeditingUpdateInput {
  where: MetadataPrepressPictureEditingWhereUniqueInput;
  data: MetadataPrepressPictureEditingWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPricelevelUpdateInput {
  where: MetadataPriceLevelWhereUniqueInput;
  data: MetadataPriceLevelWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPrintingprofileUpdateInput {
  where: MetadataPrintingProfileWhereUniqueInput;
  data: MetadataPrintingProfileWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPrismaproductionUpdateInput {
  where: MetadataPrismaProductionWhereUniqueInput;
  data: MetadataPrismaProductionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataProcesshistoryUpdateInput {
  where: MetadataProcessHistoryWhereUniqueInput;
  data: MetadataProcessHistoryWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataProcessparameterUpdateInput {
  where: MetadataProcessParameterWhereUniqueInput;
  data: MetadataProcessParameterWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataProcessstatusUpdateInput {
  where: MetadataProcessStatusWhereUniqueInput;
  data: MetadataProcessStatusWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataProductUpdateInput {
  where: MetadataProductWhereUniqueInput;
  data: MetadataProductWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataProductsUpdateInput {
  where: MetadataProductsWhereUniqueInput;
  data: MetadataProductsWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataProductshortnameUpdateInput {
  where: MetadataProductShortNameWhereUniqueInput;
  data: MetadataProductShortNameWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataProduitsUpdateInput {
  where: MetadataProduitsWhereUniqueInput;
  data: MetadataProduitsWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataProgramversionUpdateInput {
  where: MetadataProgramVersionWhereUniqueInput;
  data: MetadataProgramVersionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPropertyreleaseidUpdateInput {
  where: MetadataPropertyReleaseIdWhereUniqueInput;
  data: MetadataPropertyReleaseIdWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPropertyreleasestatusUpdateInput {
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
  data: MetadataPropertyReleaseStatusWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPublisherUpdateInput {
  where: MetadataPublisherWhereUniqueInput;
  data: MetadataPublisherWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPublishingissueUpdateInput {
  where: MetadataPublishingIssueWhereUniqueInput;
  data: MetadataPublishingIssueWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataPublishingsubjectUpdateInput {
  where: MetadataPublishingSubjectWhereUniqueInput;
  data: MetadataPublishingSubjectWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataQualifieduseprismabyUpdateInput {
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
  data: MetadataQualifiedUsePrismaByWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataQualifieduseprismadateUpdateInput {
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
  data: MetadataQualifiedUsePrismaDateWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataQualifieduseprismadurationUpdateInput {
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
  data: MetadataQualifiedUsePrismaDurationWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataQualifieduseprismasupportUpdateInput {
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
  data: MetadataQualifiedUsePrismaSupportWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataRatingUpdateInput {
  where: MetadataRatingWhereUniqueInput;
  data: MetadataRatingWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataReferencedateUpdateInput {
  where: MetadataReferenceDateWhereUniqueInput;
  data: MetadataReferenceDateWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataReferencenumberUpdateInput {
  where: MetadataReferenceNumberWhereUniqueInput;
  data: MetadataReferenceNumberWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataReferenceserviceUpdateInput {
  where: MetadataReferenceServiceWhereUniqueInput;
  data: MetadataReferenceServiceWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataReleasedateUpdateInput {
  where: MetadataReleaseDateWhereUniqueInput;
  data: MetadataReleaseDateWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataReleasetimeUpdateInput {
  where: MetadataReleaseTimeWhereUniqueInput;
  data: MetadataReleaseTimeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataRequiredpermissionUpdateInput {
  where: MetadataRequiredPermissionWhereUniqueInput;
  data: MetadataRequiredPermissionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataResolutionkindUpdateInput {
  where: MetadataResolutionKindWhereUniqueInput;
  data: MetadataResolutionKindWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataRightsUpdateInput {
  where: MetadataRightsWhereUniqueInput;
  data: MetadataRightsWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataRoyaltyfreeUpdateInput {
  where: MetadataRoyaltyfreeWhereUniqueInput;
  data: MetadataRoyaltyfreeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataSceneUpdateInput {
  where: MetadataSceneWhereUniqueInput;
  data: MetadataSceneWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataSectionUpdateInput {
  where: MetadataSectionWhereUniqueInput;
  data: MetadataSectionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataSenttoUpdateInput {
  where: MetadataSentToWhereUniqueInput;
  data: MetadataSentToWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataSerialnumberUpdateInput {
  where: MetadataSerialNumberWhereUniqueInput;
  data: MetadataSerialNumberWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataSeriesdatetimeUpdateInput {
  where: MetadataSeriesDateTimeWhereUniqueInput;
  data: MetadataSeriesDateTimeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataSeriesdescriptionUpdateInput {
  where: MetadataSeriesDescriptionWhereUniqueInput;
  data: MetadataSeriesDescriptionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataSeriesmodalityUpdateInput {
  where: MetadataSeriesModalityWhereUniqueInput;
  data: MetadataSeriesModalityWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataSeriesnumberUpdateInput {
  where: MetadataSeriesNumberWhereUniqueInput;
  data: MetadataSeriesNumberWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataShortuniqueidUpdateInput {
  where: MetadataShortUniqueIdWhereUniqueInput;
  data: MetadataShortUniqueIdWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataSourceUpdateInput {
  where: MetadataSourceWhereUniqueInput;
  data: MetadataSourceWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataStateUpdateInput {
  where: MetadataStateWhereUniqueInput;
  data: MetadataStateWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataStudydatetimeUpdateInput {
  where: MetadataStudyDateTimeWhereUniqueInput;
  data: MetadataStudyDateTimeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataStudydescriptionUpdateInput {
  where: MetadataStudyDescriptionWhereUniqueInput;
  data: MetadataStudyDescriptionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataStudyidUpdateInput {
  where: MetadataStudyIdWhereUniqueInput;
  data: MetadataStudyIdWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataStudyphysicianUpdateInput {
  where: MetadataStudyPhysicianWhereUniqueInput;
  data: MetadataStudyPhysicianWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataSubjectUpdateInput {
  where: MetadataSubjectWhereUniqueInput;
  data: MetadataSubjectWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataSubjectcodeUpdateInput {
  where: MetadataSubjectCodeWhereUniqueInput;
  data: MetadataSubjectCodeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataSubjectprismaUpdateInput {
  where: MetadataSubjectPrismaWhereUniqueInput;
  data: MetadataSubjectPrismaWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataSupplementalcategoriesUpdateInput {
  where: MetadataSupplementalCategoriesWhereUniqueInput;
  data: MetadataSupplementalCategoriesWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataTargetversionUpdateInput {
  where: MetadataTargetVersionWhereUniqueInput;
  data: MetadataTargetVersionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataTitleUpdateInput {
  where: MetadataTitleWhereUniqueInput;
  data: MetadataTitleWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataTonecurveUpdateInput {
  where: MetadataToneCurveWhereUniqueInput;
  data: MetadataToneCurveWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataTransferredbyUpdateInput {
  where: MetadataTransferredByWhereUniqueInput;
  data: MetadataTransferredByWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataTransferredbyemailUpdateInput {
  where: MetadataTransferredByEmailWhereUniqueInput;
  data: MetadataTransferredByEmailWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataTransferredbyfullnameUpdateInput {
  where: MetadataTransferredByFullNameWhereUniqueInput;
  data: MetadataTransferredByFullNameWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataTransmissionreferenceUpdateInput {
  where: MetadataTransmissionReferenceWhereUniqueInput;
  data: MetadataTransmissionReferenceWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataTypeUpdateInput {
  where: MetadataTypeWhereUniqueInput;
  data: MetadataTypeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUndersubjectUpdateInput {
  where: MetadataUndersubjectWhereUniqueInput;
  data: MetadataUndersubjectWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUnderundersubjectUpdateInput {
  where: MetadataUnderUnderSubjectWhereUniqueInput;
  data: MetadataUnderUnderSubjectWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUniqueidUpdateInput {
  where: MetadataUniqueIdWhereUniqueInput;
  data: MetadataUniqueIdWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUnitUpdateInput {
  where: MetadataUnitWhereUniqueInput;
  data: MetadataUnitWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUnitshortnameUpdateInput {
  where: MetadataUnitShortNameWhereUniqueInput;
  data: MetadataUnitShortNameWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUploadedbyUpdateInput {
  where: MetadataUploadedByWhereUniqueInput;
  data: MetadataUploadedByWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUploadedbyfullnameUpdateInput {
  where: MetadataUploadedByFullNameWhereUniqueInput;
  data: MetadataUploadedByFullNameWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUploadtimeUpdateInput {
  where: MetadataUploadTimeWhereUniqueInput;
  data: MetadataUploadTimeWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUrgencyUpdateInput {
  where: MetadataUrgencyWhereUniqueInput;
  data: MetadataUrgencyWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUsagetermsUpdateInput {
  where: MetadataUsageTermsWhereUniqueInput;
  data: MetadataUsageTermsWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUsercommentUpdateInput {
  where: MetadataUserCommentWhereUniqueInput;
  data: MetadataUserCommentWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUserdefined195UpdateInput {
  where: MetadataUserDefined195WhereUniqueInput;
  data: MetadataUserDefined195WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUserdefined237UpdateInput {
  where: MetadataUserDefined237WhereUniqueInput;
  data: MetadataUserDefined237WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUserdefined238UpdateInput {
  where: MetadataUserDefined238WhereUniqueInput;
  data: MetadataUserDefined238WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUserdefined239UpdateInput {
  where: MetadataUserDefined239WhereUniqueInput;
  data: MetadataUserDefined239WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUserdefined242UpdateInput {
  where: MetadataUserDefined242WhereUniqueInput;
  data: MetadataUserDefined242WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataUserdefined62UpdateInput {
  where: MetadataUserDefined62WhereUniqueInput;
  data: MetadataUserDefined62WithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataValidUpdateInput {
  where: MetadataValidWhereUniqueInput;
  data: MetadataValidWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataVersionUpdateInput {
  where: MetadataVersionWhereUniqueInput;
  data: MetadataVersionWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataWebstatementUpdateInput {
  where: MetadataWebStatementWhereUniqueInput;
  data: MetadataWebStatementWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataWorkflowkindUpdateInput {
  where: MetadataWorkflowKindWhereUniqueInput;
  data: MetadataWorkflowKindWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataXmpfilestampsUpdateInput {
  where: MetadataXmpFileStampsWhereUniqueInput;
  data: MetadataXmpFileStampsWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdateMetadataXmphistoryUpdateInput {
  where: MetadataXmpHistoryWhereUniqueInput;
  data: MetadataXmpHistoryWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpdatePhotoMetadatasUpdateInput {
  where: PhotoMetadataWhereUniqueInput;
  data: PhotoMetadataWithForcedPhotoUpdateInput;
}

export interface PhotoNestedUpsertAgencyCreateInput {
  where: AgencyWhereUniqueInput;
  update: AgencyUpdateInput;
  create: AgencyCreateInput;
}

export interface PhotoNestedUpsertAgencyUpdateInput {
  where: AgencyWhereUniqueInput;
  update: AgencyUpdateInput;
  create: AgencyCreateInput;
}

export interface PhotoNestedUpsertArchiveCreateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface PhotoNestedUpsertArchiveUpdateInput {
  where: ArchiveWhereUniqueInput;
  update: ArchiveUpdateInput;
  create: ArchiveCreateInput;
}

export interface PhotoNestedUpsertCategoryCreateInput {
  where: CategoryWhereUniqueInput;
  update: CategoryUpdateInput;
  create: CategoryCreateInput;
}

export interface PhotoNestedUpsertCategoryUpdateInput {
  where: CategoryWhereUniqueInput;
  update: CategoryUpdateInput;
  create: CategoryCreateInput;
}

export interface PhotoNestedUpsertDeliverySubjectCreateInput {
  where: DeliverySubjectWhereUniqueInput;
  update: DeliverySubjectUpdateInput;
  create: DeliverySubjectCreateInput;
}

export interface PhotoNestedUpsertDeliverySubjectUpdateInput {
  where: DeliverySubjectWhereUniqueInput;
  update: DeliverySubjectUpdateInput;
  create: DeliverySubjectCreateInput;
}

export interface PhotoNestedUpsertImportQueueUpdateInput {
  where: ImportQueueWithOptionalPhotoWhereUniqueInput;
  update: ImportQueueWithForcedPhotoUpdateInput;
  create: ImportQueueWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataAccessrightsUpdateInput {
  where: MetadataAccessRightsWhereUniqueInput;
  update: MetadataAccessRightsWithForcedPhotoUpdateInput;
  create: MetadataAccessRightsWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataAddlmodelinfoUpdateInput {
  where: MetadataAddlModelInfoWhereUniqueInput;
  update: MetadataAddlModelInfoWithForcedPhotoUpdateInput;
  create: MetadataAddlModelInfoWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataArchiveChildUpdateInput {
  where: MetadataArchiveChildWhereUniqueInput;
  update: MetadataArchiveChildWithForcedPhotoUpdateInput;
  create: MetadataArchiveChildWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataArchiveParentUpdateInput {
  where: MetadataArchiveParentWhereUniqueInput;
  update: MetadataArchiveParentWithForcedPhotoUpdateInput;
  create: MetadataArchiveParentWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataAssetstateUpdateInput {
  where: MetadataAssetStateWhereUniqueInput;
  update: MetadataAssetStateWithForcedPhotoUpdateInput;
  create: MetadataAssetStateWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataAttributionnameUpdateInput {
  where: MetadataAttributionNameWhereUniqueInput;
  update: MetadataAttributionNameWithForcedPhotoUpdateInput;
  create: MetadataAttributionNameWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataAttributionurlUpdateInput {
  where: MetadataAttributionUrlWhereUniqueInput;
  update: MetadataAttributionUrlWithForcedPhotoUpdateInput;
  create: MetadataAttributionUrlWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataAudienceUpdateInput {
  where: MetadataAudienceWhereUniqueInput;
  update: MetadataAudienceWithForcedPhotoUpdateInput;
  create: MetadataAudienceWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataAuthorspositionUpdateInput {
  where: MetadataAuthorsPositionWhereUniqueInput;
  update: MetadataAuthorsPositionWithForcedPhotoUpdateInput;
  create: MetadataAuthorsPositionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataBackupnameUpdateInput {
  where: MetadataBackupNameWhereUniqueInput;
  update: MetadataBackupNameWithForcedPhotoUpdateInput;
  create: MetadataBackupNameWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataBitmapgrayscalepictureUpdateInput {
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
  update: MetadataBitmapGrayscalePictureWithForcedPhotoUpdateInput;
  create: MetadataBitmapGrayscalePictureWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataBrandUpdateInput {
  where: MetadataBrandWhereUniqueInput;
  update: MetadataBrandWithForcedPhotoUpdateInput;
  create: MetadataBrandWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataBrandprismaUpdateInput {
  where: MetadataBrandPrismaWhereUniqueInput;
  update: MetadataBrandPrismaWithForcedPhotoUpdateInput;
  create: MetadataBrandPrismaWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataBrandshortnameUpdateInput {
  where: MetadataBrandShortNameWhereUniqueInput;
  update: MetadataBrandShortNameWithForcedPhotoUpdateInput;
  create: MetadataBrandShortNameWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCaptionwriterUpdateInput {
  where: MetadataCaptionWriterWhereUniqueInput;
  update: MetadataCaptionWriterWithForcedPhotoUpdateInput;
  create: MetadataCaptionWriterWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCategoryUpdateInput {
  where: MetadataCategoryWhereUniqueInput;
  update: MetadataCategoryWithForcedPhotoUpdateInput;
  create: MetadataCategoryWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCategoryprismaUpdateInput {
  where: MetadataCategoryPrismaWhereUniqueInput;
  update: MetadataCategoryPrismaWithForcedPhotoUpdateInput;
  create: MetadataCategoryPrismaWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCertificateUpdateInput {
  where: MetadataCertificateWhereUniqueInput;
  update: MetadataCertificateWithForcedPhotoUpdateInput;
  create: MetadataCertificateWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCiadrcityUpdateInput {
  where: MetadataCiAdrCityWhereUniqueInput;
  update: MetadataCiAdrCityWithForcedPhotoUpdateInput;
  create: MetadataCiAdrCityWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCiadrctryUpdateInput {
  where: MetadataCiAdrCtryWhereUniqueInput;
  update: MetadataCiAdrCtryWithForcedPhotoUpdateInput;
  create: MetadataCiAdrCtryWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCiadrextadrUpdateInput {
  where: MetadataCiAdrExtadrWhereUniqueInput;
  update: MetadataCiAdrExtadrWithForcedPhotoUpdateInput;
  create: MetadataCiAdrExtadrWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCiadrpcodeUpdateInput {
  where: MetadataCiAdrPcodeWhereUniqueInput;
  update: MetadataCiAdrPcodeWithForcedPhotoUpdateInput;
  create: MetadataCiAdrPcodeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCiadrregionUpdateInput {
  where: MetadataCiAdrRegionWhereUniqueInput;
  update: MetadataCiAdrRegionWithForcedPhotoUpdateInput;
  create: MetadataCiAdrRegionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCiemailworkUpdateInput {
  where: MetadataCiEmailWorkWhereUniqueInput;
  update: MetadataCiEmailWorkWithForcedPhotoUpdateInput;
  create: MetadataCiEmailWorkWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCitelworkUpdateInput {
  where: MetadataCiTelWorkWhereUniqueInput;
  update: MetadataCiTelWorkWithForcedPhotoUpdateInput;
  create: MetadataCiTelWorkWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCityUpdateInput {
  where: MetadataCityWhereUniqueInput;
  update: MetadataCityWithForcedPhotoUpdateInput;
  create: MetadataCityWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCiurlworkUpdateInput {
  where: MetadataCiUrlWorkWhereUniqueInput;
  update: MetadataCiUrlWorkWithForcedPhotoUpdateInput;
  create: MetadataCiUrlWorkWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataClassifyUpdateInput {
  where: MetadataClassifyWhereUniqueInput;
  update: MetadataClassifyWithForcedPhotoUpdateInput;
  create: MetadataClassifyWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCompanyUpdateInput {
  where: MetadataCompanyWhereUniqueInput;
  update: MetadataCompanyWithForcedPhotoUpdateInput;
  create: MetadataCompanyWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCompanyshortnameUpdateInput {
  where: MetadataCompanyShortNameWhereUniqueInput;
  update: MetadataCompanyShortNameWithForcedPhotoUpdateInput;
  create: MetadataCompanyShortNameWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataConfidentialityUpdateInput {
  where: MetadataConfidentialityWhereUniqueInput;
  update: MetadataConfidentialityWithForcedPhotoUpdateInput;
  create: MetadataConfidentialityWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataContainedinUpdateInput {
  where: MetadataContainedInWhereUniqueInput;
  update: MetadataContainedInWithForcedPhotoUpdateInput;
  create: MetadataContainedInWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataContentmediakindUpdateInput {
  where: MetadataContentMediaKindWhereUniqueInput;
  update: MetadataContentMediaKindWithForcedPhotoUpdateInput;
  create: MetadataContentMediaKindWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataContentvalueUpdateInput {
  where: MetadataContentValueWhereUniqueInput;
  update: MetadataContentValueWithForcedPhotoUpdateInput;
  create: MetadataContentValueWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCopyrightlayerUpdateInput {
  where: MetadataCopyrightLayerWhereUniqueInput;
  update: MetadataCopyrightLayerWithForcedPhotoUpdateInput;
  create: MetadataCopyrightLayerWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCountryUpdateInput {
  where: MetadataCountryWhereUniqueInput;
  update: MetadataCountryWithForcedPhotoUpdateInput;
  create: MetadataCountryWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCountrycodeUpdateInput {
  where: MetadataCountryCodeWhereUniqueInput;
  update: MetadataCountryCodeWithForcedPhotoUpdateInput;
  create: MetadataCountryCodeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCreatedtimeUpdateInput {
  where: MetadataCreatedTimeWhereUniqueInput;
  update: MetadataCreatedTimeWithForcedPhotoUpdateInput;
  create: MetadataCreatedTimeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCreatorUpdateInput {
  where: MetadataCreatorWhereUniqueInput;
  update: MetadataCreatorWithForcedPhotoUpdateInput;
  create: MetadataCreatorWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCreatortoolUpdateInput {
  where: MetadataCreatorToolWhereUniqueInput;
  update: MetadataCreatorToolWithForcedPhotoUpdateInput;
  create: MetadataCreatorToolWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCreditUpdateInput {
  where: MetadataCreditWhereUniqueInput;
  update: MetadataCreditWithForcedPhotoUpdateInput;
  create: MetadataCreditWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCustomfield14UpdateInput {
  where: MetadataCustomField14WhereUniqueInput;
  update: MetadataCustomField14WithForcedPhotoUpdateInput;
  create: MetadataCustomField14WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCustomfield15UpdateInput {
  where: MetadataCustomField15WhereUniqueInput;
  update: MetadataCustomField15WithForcedPhotoUpdateInput;
  create: MetadataCustomField15WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCustomfield16UpdateInput {
  where: MetadataCustomField16WhereUniqueInput;
  update: MetadataCustomField16WithForcedPhotoUpdateInput;
  create: MetadataCustomField16WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCustomfield17UpdateInput {
  where: MetadataCustomField17WhereUniqueInput;
  update: MetadataCustomField17WithForcedPhotoUpdateInput;
  create: MetadataCustomField17WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCustomfield20UpdateInput {
  where: MetadataCustomField20WhereUniqueInput;
  update: MetadataCustomField20WithForcedPhotoUpdateInput;
  create: MetadataCustomField20WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCustomfield5UpdateInput {
  where: MetadataCustomField5WhereUniqueInput;
  update: MetadataCustomField5WithForcedPhotoUpdateInput;
  create: MetadataCustomField5WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCustomfield6UpdateInput {
  where: MetadataCustomField6WhereUniqueInput;
  update: MetadataCustomField6WithForcedPhotoUpdateInput;
  create: MetadataCustomField6WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCustomfield7UpdateInput {
  where: MetadataCustomField7WhereUniqueInput;
  update: MetadataCustomField7WithForcedPhotoUpdateInput;
  create: MetadataCustomField7WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataCustomfield9UpdateInput {
  where: MetadataCustomField9WhereUniqueInput;
  update: MetadataCustomField9WithForcedPhotoUpdateInput;
  create: MetadataCustomField9WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDatecreatedUpdateInput {
  where: MetadataDateCreatedWhereUniqueInput;
  update: MetadataDateCreatedWithForcedPhotoUpdateInput;
  create: MetadataDateCreatedWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDatetimedigitizedUpdateInput {
  where: MetadataDateTimeDigitizedWhereUniqueInput;
  update: MetadataDateTimeDigitizedWithForcedPhotoUpdateInput;
  create: MetadataDateTimeDigitizedWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDatetimeoriginalUpdateInput {
  where: MetadataDateTimeOriginalWhereUniqueInput;
  update: MetadataDateTimeOriginalWithForcedPhotoUpdateInput;
  create: MetadataDateTimeOriginalWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliveryaccountUpdateInput {
  where: MetadataDeliveryAccountWhereUniqueInput;
  update: MetadataDeliveryAccountWithForcedPhotoUpdateInput;
  create: MetadataDeliveryAccountWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliverycompanyUpdateInput {
  where: MetadataDeliveryCompanyWhereUniqueInput;
  update: MetadataDeliveryCompanyWithForcedPhotoUpdateInput;
  create: MetadataDeliveryCompanyWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliverycopyrightUpdateInput {
  where: MetadataDeliveryCopyrightWhereUniqueInput;
  update: MetadataDeliveryCopyrightWithForcedPhotoUpdateInput;
  create: MetadataDeliveryCopyrightWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliverydatefolderUpdateInput {
  where: MetadataDeliveryDateFolderWhereUniqueInput;
  update: MetadataDeliveryDateFolderWithForcedPhotoUpdateInput;
  create: MetadataDeliveryDateFolderWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliverydatetimeUpdateInput {
  where: MetadataDeliveryDateTimeWhereUniqueInput;
  update: MetadataDeliveryDateTimeWithForcedPhotoUpdateInput;
  create: MetadataDeliveryDateTimeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliverydccreatorUpdateInput {
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
  update: MetadataDeliveryDcCreatorWithForcedPhotoUpdateInput;
  create: MetadataDeliveryDcCreatorWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliverydcrightsUpdateInput {
  where: MetadataDeliveryDcRightsWhereUniqueInput;
  update: MetadataDeliveryDcRightsWithForcedPhotoUpdateInput;
  create: MetadataDeliveryDcRightsWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliveryfiletypeUpdateInput {
  where: MetadataDeliveryFileTypeWhereUniqueInput;
  update: MetadataDeliveryFileTypeWithForcedPhotoUpdateInput;
  create: MetadataDeliveryFileTypeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliveryfolderUpdateInput {
  where: MetadataDeliveryFolderWhereUniqueInput;
  update: MetadataDeliveryFolderWithForcedPhotoUpdateInput;
  create: MetadataDeliveryFolderWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliverykindUpdateInput {
  where: MetadataDeliveryKindWhereUniqueInput;
  update: MetadataDeliveryKindWithForcedPhotoUpdateInput;
  create: MetadataDeliveryKindWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliverypathUpdateInput {
  where: MetadataDeliveryPathWhereUniqueInput;
  update: MetadataDeliveryPathWithForcedPhotoUpdateInput;
  create: MetadataDeliveryPathWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliverypersonshownintheimageUpdateInput {
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
  update: MetadataDeliveryPersonShownintheImageWithForcedPhotoUpdateInput;
  create: MetadataDeliveryPersonShownintheImageWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliveryphotoshopcreditUpdateInput {
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
  update: MetadataDeliveryPhotoshopCreditWithForcedPhotoUpdateInput;
  create: MetadataDeliveryPhotoshopCreditWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliveryphotoshopsourceUpdateInput {
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
  update: MetadataDeliveryPhotoshopSourceWithForcedPhotoUpdateInput;
  create: MetadataDeliveryPhotoshopSourceWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliveryserviceUpdateInput {
  where: MetadataDeliveryServiceWhereUniqueInput;
  update: MetadataDeliveryServiceWithForcedPhotoUpdateInput;
  create: MetadataDeliveryServiceWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliverysubjectUpdateInput {
  where: MetadataDeliverySubjectWhereUniqueInput;
  update: MetadataDeliverySubjectWithForcedPhotoUpdateInput;
  create: MetadataDeliverySubjectWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDeliveryundersubjectUpdateInput {
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
  update: MetadataDeliveryUnderSubjectWithForcedPhotoUpdateInput;
  create: MetadataDeliveryUnderSubjectWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDepartmentUpdateInput {
  where: MetadataDepartmentWhereUniqueInput;
  update: MetadataDepartmentWithForcedPhotoUpdateInput;
  create: MetadataDepartmentWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDescriptionUpdateInput {
  where: MetadataDescriptionWhereUniqueInput;
  update: MetadataDescriptionWithForcedPhotoUpdateInput;
  create: MetadataDescriptionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDigitalasseturlUpdateInput {
  where: MetadataDigitalAssetUrlWhereUniqueInput;
  update: MetadataDigitalAssetUrlWithForcedPhotoUpdateInput;
  create: MetadataDigitalAssetUrlWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDivisionUpdateInput {
  where: MetadataDivisionWhereUniqueInput;
  update: MetadataDivisionWithForcedPhotoUpdateInput;
  create: MetadataDivisionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataDocumenttextUpdateInput {
  where: MetadataDocumentTextWhereUniqueInput;
  update: MetadataDocumentTextWithForcedPhotoUpdateInput;
  create: MetadataDocumentTextWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataEditionUpdateInput {
  where: MetadataEditionWhereUniqueInput;
  update: MetadataEditionWithForcedPhotoUpdateInput;
  create: MetadataEditionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataEditorialversionUpdateInput {
  where: MetadataEditorialVersionWhereUniqueInput;
  update: MetadataEditorialVersionWithForcedPhotoUpdateInput;
  create: MetadataEditorialVersionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataEditstatusUpdateInput {
  where: MetadataEditStatusWhereUniqueInput;
  update: MetadataEditStatusWithForcedPhotoUpdateInput;
  create: MetadataEditStatusWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataEnvironnementphotoUpdateInput {
  where: MetadataEnvironnementPhotoWhereUniqueInput;
  update: MetadataEnvironnementPhotoWithForcedPhotoUpdateInput;
  create: MetadataEnvironnementPhotoWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataEquipmentinstitutionUpdateInput {
  where: MetadataEquipmentInstitutionWhereUniqueInput;
  update: MetadataEquipmentInstitutionWithForcedPhotoUpdateInput;
  create: MetadataEquipmentInstitutionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataEquipmentmanufacturerUpdateInput {
  where: MetadataEquipmentManufacturerWhereUniqueInput;
  update: MetadataEquipmentManufacturerWithForcedPhotoUpdateInput;
  create: MetadataEquipmentManufacturerWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataEventUpdateInput {
  where: MetadataEventWhereUniqueInput;
  update: MetadataEventWithForcedPhotoUpdateInput;
  create: MetadataEventWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataExifUpdateInput {
  where: MetadataExifWhereUniqueInput;
  update: MetadataExifWithForcedPhotoUpdateInput;
  create: MetadataExifWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataFabstorageUpdateInput {
  where: MetadataFabStorageWhereUniqueInput;
  update: MetadataFabStorageWithForcedPhotoUpdateInput;
  create: MetadataFabStorageWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataFilecheckforUpdateInput {
  where: MetadataFileCheckForWhereUniqueInput;
  update: MetadataFileCheckForWithForcedPhotoUpdateInput;
  create: MetadataFileCheckForWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataFileinfobitsperpixelUpdateInput {
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
  update: MetadataFileInfoBitsPerPixelWithForcedPhotoUpdateInput;
  create: MetadataFileInfoBitsPerPixelWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataFileinfoframesUpdateInput {
  where: MetadataFileInfoFramesWhereUniqueInput;
  update: MetadataFileInfoFramesWithForcedPhotoUpdateInput;
  create: MetadataFileInfoFramesWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataFileinfophotometricinterpretationUpdateInput {
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
  update: MetadataFileInfoPhotometricInterpretationWithForcedPhotoUpdateInput;
  create: MetadataFileInfoPhotometricInterpretationWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataFileinfotransfersyntaxUpdateInput {
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
  update: MetadataFileInfoTransferSyntaxWithForcedPhotoUpdateInput;
  create: MetadataFileInfoTransferSyntaxWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataFilestageUpdateInput {
  where: MetadataFileStageWhereUniqueInput;
  update: MetadataFileStageWithForcedPhotoUpdateInput;
  create: MetadataFileStageWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataFilestatusUpdateInput {
  where: MetadataFileStatusWhereUniqueInput;
  update: MetadataFileStatusWithForcedPhotoUpdateInput;
  create: MetadataFileStatusWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataFiletypeUpdateInput {
  where: MetadataFileTypeWhereUniqueInput;
  update: MetadataFileTypeWithForcedPhotoUpdateInput;
  create: MetadataFileTypeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataFixtureidentifierUpdateInput {
  where: MetadataFixtureIdentifierWhereUniqueInput;
  update: MetadataFixtureIdentifierWithForcedPhotoUpdateInput;
  create: MetadataFixtureIdentifierWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataFormatUpdateInput {
  where: MetadataFormatWhereUniqueInput;
  update: MetadataFormatWithForcedPhotoUpdateInput;
  create: MetadataFormatWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataGpslatitudeUpdateInput {
  where: MetadataGpsLatitudeWhereUniqueInput;
  update: MetadataGpsLatitudeWithForcedPhotoUpdateInput;
  create: MetadataGpsLatitudeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataGpslongitudeUpdateInput {
  where: MetadataGpsLongitudeWhereUniqueInput;
  update: MetadataGpsLongitudeWithForcedPhotoUpdateInput;
  create: MetadataGpsLongitudeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataGpsprocessingmethodUpdateInput {
  where: MetadataGpsProcessingMethodWhereUniqueInput;
  update: MetadataGpsProcessingMethodWithForcedPhotoUpdateInput;
  create: MetadataGpsProcessingMethodWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataHeaderUpdateInput {
  where: MetadataHeaderWhereUniqueInput;
  update: MetadataHeaderWithForcedPhotoUpdateInput;
  create: MetadataHeaderWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataHeaderprismaUpdateInput {
  where: MetadataHeaderPrismaWhereUniqueInput;
  update: MetadataHeaderPrismaWithForcedPhotoUpdateInput;
  create: MetadataHeaderPrismaWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataHeadlineUpdateInput {
  where: MetadataHeadlineWhereUniqueInput;
  update: MetadataHeadlineWithForcedPhotoUpdateInput;
  create: MetadataHeadlineWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataHistoryUpdateInput {
  where: MetadataHistoryWhereUniqueInput;
  update: MetadataHistoryWithForcedPhotoUpdateInput;
  create: MetadataHistoryWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataIccprofileUpdateInput {
  where: MetadataIccProfileWhereUniqueInput;
  update: MetadataIccProfileWithForcedPhotoUpdateInput;
  create: MetadataIccProfileWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataIdSubjectUpdateInput {
  where: MetadataIdSubjectWhereUniqueInput;
  update: MetadataIdSubjectWithForcedPhotoUpdateInput;
  create: MetadataIdSubjectWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataIdassignmentUpdateInput {
  where: MetadataIdAssignmentWhereUniqueInput;
  update: MetadataIdAssignmentWithForcedPhotoUpdateInput;
  create: MetadataIdAssignmentWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataIdmediacontentUpdateInput {
  where: MetadataIdMediaContentWhereUniqueInput;
  update: MetadataIdMediaContentWithForcedPhotoUpdateInput;
  create: MetadataIdMediaContentWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataIdphotoprismaUpdateInput {
  where: MetadataIdPhotoPrismaWhereUniqueInput;
  update: MetadataIdPhotoPrismaWithForcedPhotoUpdateInput;
  create: MetadataIdPhotoPrismaWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataImagenotesUpdateInput {
  where: MetadataImageNotesWhereUniqueInput;
  update: MetadataImageNotesWithForcedPhotoUpdateInput;
  create: MetadataImageNotesWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataImageuniqueidUpdateInput {
  where: MetadataImageUniqueIdWhereUniqueInput;
  update: MetadataImageUniqueIdWithForcedPhotoUpdateInput;
  create: MetadataImageUniqueIdWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataInstructionsUpdateInput {
  where: MetadataInstructionsWhereUniqueInput;
  update: MetadataInstructionsWithForcedPhotoUpdateInput;
  create: MetadataInstructionsWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataIntellectualgenreUpdateInput {
  where: MetadataIntellectualGenreWhereUniqueInput;
  update: MetadataIntellectualGenreWithForcedPhotoUpdateInput;
  create: MetadataIntellectualGenreWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataIsospeedratingsUpdateInput {
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
  update: MetadataIsoSpeedRatingsWithForcedPhotoUpdateInput;
  create: MetadataIsoSpeedRatingsWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataIssueUpdateInput {
  where: MetadataIssueWhereUniqueInput;
  update: MetadataIssueWithForcedPhotoUpdateInput;
  create: MetadataIssueWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataJobidUpdateInput {
  where: MetadataJobIdWhereUniqueInput;
  update: MetadataJobIdWithForcedPhotoUpdateInput;
  create: MetadataJobIdWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataJobprocessingUpdateInput {
  where: MetadataJobProcessingWhereUniqueInput;
  update: MetadataJobProcessingWithForcedPhotoUpdateInput;
  create: MetadataJobProcessingWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataLayoutpictureeditingUpdateInput {
  where: MetadataLayoutPictureEditingWhereUniqueInput;
  update: MetadataLayoutPictureEditingWithForcedPhotoUpdateInput;
  create: MetadataLayoutPictureEditingWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataLayoutstorageUpdateInput {
  where: MetadataLayoutStorageWhereUniqueInput;
  update: MetadataLayoutStorageWithForcedPhotoUpdateInput;
  create: MetadataLayoutStorageWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataLicenseUpdateInput {
  where: MetadataLicenseWhereUniqueInput;
  update: MetadataLicenseWithForcedPhotoUpdateInput;
  create: MetadataLicenseWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataLinksUpdateInput {
  where: MetadataLinksWhereUniqueInput;
  update: MetadataLinksWithForcedPhotoUpdateInput;
  create: MetadataLinksWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataLocalcaptionUpdateInput {
  where: MetadataLocalCaptionWhereUniqueInput;
  update: MetadataLocalCaptionWithForcedPhotoUpdateInput;
  create: MetadataLocalCaptionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataLocationUpdateInput {
  where: MetadataLocationWhereUniqueInput;
  update: MetadataLocationWithForcedPhotoUpdateInput;
  create: MetadataLocationWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataMakeUpdateInput {
  where: MetadataMakeWhereUniqueInput;
  update: MetadataMakeWithForcedPhotoUpdateInput;
  create: MetadataMakeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataManifestUpdateInput {
  where: MetadataManifestWhereUniqueInput;
  update: MetadataManifestWithForcedPhotoUpdateInput;
  create: MetadataManifestWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataMarkedUpdateInput {
  where: MetadataMarkedWhereUniqueInput;
  update: MetadataMarkedWithForcedPhotoUpdateInput;
  create: MetadataMarkedWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataMasterdocumentidUpdateInput {
  where: MetadataMasterDocumentIdWhereUniqueInput;
  update: MetadataMasterDocumentIdWithForcedPhotoUpdateInput;
  create: MetadataMasterDocumentIdWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataMaxavailheightUpdateInput {
  where: MetadataMaxAvailHeightWhereUniqueInput;
  update: MetadataMaxAvailHeightWithForcedPhotoUpdateInput;
  create: MetadataMaxAvailHeightWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataMaxavailwidthUpdateInput {
  where: MetadataMaxAvailWidthWhereUniqueInput;
  update: MetadataMaxAvailWidthWithForcedPhotoUpdateInput;
  create: MetadataMaxAvailWidthWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataMinormodelagedisclosureUpdateInput {
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
  update: MetadataMinorModelAgeDisclosureWithForcedPhotoUpdateInput;
  create: MetadataMinorModelAgeDisclosureWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataModelUpdateInput {
  where: MetadataModelWhereUniqueInput;
  update: MetadataModelWithForcedPhotoUpdateInput;
  create: MetadataModelWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataModelageUpdateInput {
  where: MetadataModelAgeWhereUniqueInput;
  update: MetadataModelAgeWithForcedPhotoUpdateInput;
  create: MetadataModelAgeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataModelreleaseidUpdateInput {
  where: MetadataModelReleaseIdWhereUniqueInput;
  update: MetadataModelReleaseIdWithForcedPhotoUpdateInput;
  create: MetadataModelReleaseIdWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataModelreleasestatusUpdateInput {
  where: MetadataModelReleaseStatusWhereUniqueInput;
  update: MetadataModelReleaseStatusWithForcedPhotoUpdateInput;
  create: MetadataModelReleaseStatusWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataMorepermissionsUpdateInput {
  where: MetadataMorePermissionsWhereUniqueInput;
  update: MetadataMorePermissionsWithForcedPhotoUpdateInput;
  create: MetadataMorePermissionsWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataObjectcycleUpdateInput {
  where: MetadataObjectCycleWhereUniqueInput;
  update: MetadataObjectCycleWithForcedPhotoUpdateInput;
  create: MetadataObjectCycleWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataOrganisationinimagenameUpdateInput {
  where: MetadataOrganisationInImageNameWhereUniqueInput;
  update: MetadataOrganisationInImageNameWithForcedPhotoUpdateInput;
  create: MetadataOrganisationInImageNameWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataOriginalcolorUpdateInput {
  where: MetadataOriginalColorWhereUniqueInput;
  update: MetadataOriginalColorWithForcedPhotoUpdateInput;
  create: MetadataOriginalColorWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataOriginaliccprofileUpdateInput {
  where: MetadataOriginalIccProfileWhereUniqueInput;
  update: MetadataOriginalIccProfileWithForcedPhotoUpdateInput;
  create: MetadataOriginalIccProfileWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataOriginalnameUpdateInput {
  where: MetadataOriginalNameWhereUniqueInput;
  update: MetadataOriginalNameWithForcedPhotoUpdateInput;
  create: MetadataOriginalNameWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataOriginalweigthUpdateInput {
  where: MetadataOriginalWeigthWhereUniqueInput;
  update: MetadataOriginalWeigthWithForcedPhotoUpdateInput;
  create: MetadataOriginalWeigthWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataOwnerUpdateInput {
  where: MetadataOwnerWhereUniqueInput;
  update: MetadataOwnerWithForcedPhotoUpdateInput;
  create: MetadataOwnerWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataOwneridUpdateInput {
  where: MetadataOwnerIdWhereUniqueInput;
  update: MetadataOwnerIdWithForcedPhotoUpdateInput;
  create: MetadataOwnerIdWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPageUpdateInput {
  where: MetadataPageWhereUniqueInput;
  update: MetadataPageWithForcedPhotoUpdateInput;
  create: MetadataPageWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPageprismaUpdateInput {
  where: MetadataPagePrismaWhereUniqueInput;
  update: MetadataPagePrismaWithForcedPhotoUpdateInput;
  create: MetadataPagePrismaWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPaintbasedcorrectionsUpdateInput {
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
  update: MetadataPaintBasedCorrectionsWithForcedPhotoUpdateInput;
  create: MetadataPaintBasedCorrectionsWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPatientdobUpdateInput {
  where: MetadataPatientDobWhereUniqueInput;
  update: MetadataPatientDobWithForcedPhotoUpdateInput;
  create: MetadataPatientDobWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPatientidUpdateInput {
  where: MetadataPatientIdWhereUniqueInput;
  update: MetadataPatientIdWithForcedPhotoUpdateInput;
  create: MetadataPatientIdWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPatientnameUpdateInput {
  where: MetadataPatientNameWhereUniqueInput;
  update: MetadataPatientNameWithForcedPhotoUpdateInput;
  create: MetadataPatientNameWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPatientsexUpdateInput {
  where: MetadataPatientSexWhereUniqueInput;
  update: MetadataPatientSexWithForcedPhotoUpdateInput;
  create: MetadataPatientSexWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPersoninimageUpdateInput {
  where: MetadataPersonInImageWhereUniqueInput;
  update: MetadataPersonInImageWithForcedPhotoUpdateInput;
  create: MetadataPersonInImageWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPhotostorageUpdateInput {
  where: MetadataPhotoStorageWhereUniqueInput;
  update: MetadataPhotoStorageWithForcedPhotoUpdateInput;
  create: MetadataPhotoStorageWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPrepresspictureeditingUpdateInput {
  where: MetadataPrepressPictureEditingWhereUniqueInput;
  update: MetadataPrepressPictureEditingWithForcedPhotoUpdateInput;
  create: MetadataPrepressPictureEditingWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPricelevelUpdateInput {
  where: MetadataPriceLevelWhereUniqueInput;
  update: MetadataPriceLevelWithForcedPhotoUpdateInput;
  create: MetadataPriceLevelWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPrintingprofileUpdateInput {
  where: MetadataPrintingProfileWhereUniqueInput;
  update: MetadataPrintingProfileWithForcedPhotoUpdateInput;
  create: MetadataPrintingProfileWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPrismaproductionUpdateInput {
  where: MetadataPrismaProductionWhereUniqueInput;
  update: MetadataPrismaProductionWithForcedPhotoUpdateInput;
  create: MetadataPrismaProductionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataProcesshistoryUpdateInput {
  where: MetadataProcessHistoryWhereUniqueInput;
  update: MetadataProcessHistoryWithForcedPhotoUpdateInput;
  create: MetadataProcessHistoryWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataProcessparameterUpdateInput {
  where: MetadataProcessParameterWhereUniqueInput;
  update: MetadataProcessParameterWithForcedPhotoUpdateInput;
  create: MetadataProcessParameterWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataProcessstatusUpdateInput {
  where: MetadataProcessStatusWhereUniqueInput;
  update: MetadataProcessStatusWithForcedPhotoUpdateInput;
  create: MetadataProcessStatusWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataProductUpdateInput {
  where: MetadataProductWhereUniqueInput;
  update: MetadataProductWithForcedPhotoUpdateInput;
  create: MetadataProductWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataProductsUpdateInput {
  where: MetadataProductsWhereUniqueInput;
  update: MetadataProductsWithForcedPhotoUpdateInput;
  create: MetadataProductsWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataProductshortnameUpdateInput {
  where: MetadataProductShortNameWhereUniqueInput;
  update: MetadataProductShortNameWithForcedPhotoUpdateInput;
  create: MetadataProductShortNameWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataProduitsUpdateInput {
  where: MetadataProduitsWhereUniqueInput;
  update: MetadataProduitsWithForcedPhotoUpdateInput;
  create: MetadataProduitsWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataProgramversionUpdateInput {
  where: MetadataProgramVersionWhereUniqueInput;
  update: MetadataProgramVersionWithForcedPhotoUpdateInput;
  create: MetadataProgramVersionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPropertyreleaseidUpdateInput {
  where: MetadataPropertyReleaseIdWhereUniqueInput;
  update: MetadataPropertyReleaseIdWithForcedPhotoUpdateInput;
  create: MetadataPropertyReleaseIdWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPropertyreleasestatusUpdateInput {
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
  update: MetadataPropertyReleaseStatusWithForcedPhotoUpdateInput;
  create: MetadataPropertyReleaseStatusWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPublisherUpdateInput {
  where: MetadataPublisherWhereUniqueInput;
  update: MetadataPublisherWithForcedPhotoUpdateInput;
  create: MetadataPublisherWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPublishingissueUpdateInput {
  where: MetadataPublishingIssueWhereUniqueInput;
  update: MetadataPublishingIssueWithForcedPhotoUpdateInput;
  create: MetadataPublishingIssueWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataPublishingsubjectUpdateInput {
  where: MetadataPublishingSubjectWhereUniqueInput;
  update: MetadataPublishingSubjectWithForcedPhotoUpdateInput;
  create: MetadataPublishingSubjectWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataQualifieduseprismabyUpdateInput {
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
  update: MetadataQualifiedUsePrismaByWithForcedPhotoUpdateInput;
  create: MetadataQualifiedUsePrismaByWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataQualifieduseprismadateUpdateInput {
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
  update: MetadataQualifiedUsePrismaDateWithForcedPhotoUpdateInput;
  create: MetadataQualifiedUsePrismaDateWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataQualifieduseprismadurationUpdateInput {
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
  update: MetadataQualifiedUsePrismaDurationWithForcedPhotoUpdateInput;
  create: MetadataQualifiedUsePrismaDurationWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataQualifieduseprismasupportUpdateInput {
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
  update: MetadataQualifiedUsePrismaSupportWithForcedPhotoUpdateInput;
  create: MetadataQualifiedUsePrismaSupportWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataRatingUpdateInput {
  where: MetadataRatingWhereUniqueInput;
  update: MetadataRatingWithForcedPhotoUpdateInput;
  create: MetadataRatingWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataReferencedateUpdateInput {
  where: MetadataReferenceDateWhereUniqueInput;
  update: MetadataReferenceDateWithForcedPhotoUpdateInput;
  create: MetadataReferenceDateWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataReferencenumberUpdateInput {
  where: MetadataReferenceNumberWhereUniqueInput;
  update: MetadataReferenceNumberWithForcedPhotoUpdateInput;
  create: MetadataReferenceNumberWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataReferenceserviceUpdateInput {
  where: MetadataReferenceServiceWhereUniqueInput;
  update: MetadataReferenceServiceWithForcedPhotoUpdateInput;
  create: MetadataReferenceServiceWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataReleasedateUpdateInput {
  where: MetadataReleaseDateWhereUniqueInput;
  update: MetadataReleaseDateWithForcedPhotoUpdateInput;
  create: MetadataReleaseDateWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataReleasetimeUpdateInput {
  where: MetadataReleaseTimeWhereUniqueInput;
  update: MetadataReleaseTimeWithForcedPhotoUpdateInput;
  create: MetadataReleaseTimeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataRequiredpermissionUpdateInput {
  where: MetadataRequiredPermissionWhereUniqueInput;
  update: MetadataRequiredPermissionWithForcedPhotoUpdateInput;
  create: MetadataRequiredPermissionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataResolutionkindUpdateInput {
  where: MetadataResolutionKindWhereUniqueInput;
  update: MetadataResolutionKindWithForcedPhotoUpdateInput;
  create: MetadataResolutionKindWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataRightsUpdateInput {
  where: MetadataRightsWhereUniqueInput;
  update: MetadataRightsWithForcedPhotoUpdateInput;
  create: MetadataRightsWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataRoyaltyfreeUpdateInput {
  where: MetadataRoyaltyfreeWhereUniqueInput;
  update: MetadataRoyaltyfreeWithForcedPhotoUpdateInput;
  create: MetadataRoyaltyfreeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataSceneUpdateInput {
  where: MetadataSceneWhereUniqueInput;
  update: MetadataSceneWithForcedPhotoUpdateInput;
  create: MetadataSceneWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataSectionUpdateInput {
  where: MetadataSectionWhereUniqueInput;
  update: MetadataSectionWithForcedPhotoUpdateInput;
  create: MetadataSectionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataSenttoUpdateInput {
  where: MetadataSentToWhereUniqueInput;
  update: MetadataSentToWithForcedPhotoUpdateInput;
  create: MetadataSentToWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataSerialnumberUpdateInput {
  where: MetadataSerialNumberWhereUniqueInput;
  update: MetadataSerialNumberWithForcedPhotoUpdateInput;
  create: MetadataSerialNumberWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataSeriesdatetimeUpdateInput {
  where: MetadataSeriesDateTimeWhereUniqueInput;
  update: MetadataSeriesDateTimeWithForcedPhotoUpdateInput;
  create: MetadataSeriesDateTimeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataSeriesdescriptionUpdateInput {
  where: MetadataSeriesDescriptionWhereUniqueInput;
  update: MetadataSeriesDescriptionWithForcedPhotoUpdateInput;
  create: MetadataSeriesDescriptionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataSeriesmodalityUpdateInput {
  where: MetadataSeriesModalityWhereUniqueInput;
  update: MetadataSeriesModalityWithForcedPhotoUpdateInput;
  create: MetadataSeriesModalityWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataSeriesnumberUpdateInput {
  where: MetadataSeriesNumberWhereUniqueInput;
  update: MetadataSeriesNumberWithForcedPhotoUpdateInput;
  create: MetadataSeriesNumberWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataShortuniqueidUpdateInput {
  where: MetadataShortUniqueIdWhereUniqueInput;
  update: MetadataShortUniqueIdWithForcedPhotoUpdateInput;
  create: MetadataShortUniqueIdWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataSourceUpdateInput {
  where: MetadataSourceWhereUniqueInput;
  update: MetadataSourceWithForcedPhotoUpdateInput;
  create: MetadataSourceWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataStateUpdateInput {
  where: MetadataStateWhereUniqueInput;
  update: MetadataStateWithForcedPhotoUpdateInput;
  create: MetadataStateWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataStudydatetimeUpdateInput {
  where: MetadataStudyDateTimeWhereUniqueInput;
  update: MetadataStudyDateTimeWithForcedPhotoUpdateInput;
  create: MetadataStudyDateTimeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataStudydescriptionUpdateInput {
  where: MetadataStudyDescriptionWhereUniqueInput;
  update: MetadataStudyDescriptionWithForcedPhotoUpdateInput;
  create: MetadataStudyDescriptionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataStudyidUpdateInput {
  where: MetadataStudyIdWhereUniqueInput;
  update: MetadataStudyIdWithForcedPhotoUpdateInput;
  create: MetadataStudyIdWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataStudyphysicianUpdateInput {
  where: MetadataStudyPhysicianWhereUniqueInput;
  update: MetadataStudyPhysicianWithForcedPhotoUpdateInput;
  create: MetadataStudyPhysicianWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataSubjectUpdateInput {
  where: MetadataSubjectWhereUniqueInput;
  update: MetadataSubjectWithForcedPhotoUpdateInput;
  create: MetadataSubjectWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataSubjectcodeUpdateInput {
  where: MetadataSubjectCodeWhereUniqueInput;
  update: MetadataSubjectCodeWithForcedPhotoUpdateInput;
  create: MetadataSubjectCodeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataSubjectprismaUpdateInput {
  where: MetadataSubjectPrismaWhereUniqueInput;
  update: MetadataSubjectPrismaWithForcedPhotoUpdateInput;
  create: MetadataSubjectPrismaWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataSupplementalcategoriesUpdateInput {
  where: MetadataSupplementalCategoriesWhereUniqueInput;
  update: MetadataSupplementalCategoriesWithForcedPhotoUpdateInput;
  create: MetadataSupplementalCategoriesWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataTargetversionUpdateInput {
  where: MetadataTargetVersionWhereUniqueInput;
  update: MetadataTargetVersionWithForcedPhotoUpdateInput;
  create: MetadataTargetVersionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataTitleUpdateInput {
  where: MetadataTitleWhereUniqueInput;
  update: MetadataTitleWithForcedPhotoUpdateInput;
  create: MetadataTitleWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataTonecurveUpdateInput {
  where: MetadataToneCurveWhereUniqueInput;
  update: MetadataToneCurveWithForcedPhotoUpdateInput;
  create: MetadataToneCurveWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataTransferredbyUpdateInput {
  where: MetadataTransferredByWhereUniqueInput;
  update: MetadataTransferredByWithForcedPhotoUpdateInput;
  create: MetadataTransferredByWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataTransferredbyemailUpdateInput {
  where: MetadataTransferredByEmailWhereUniqueInput;
  update: MetadataTransferredByEmailWithForcedPhotoUpdateInput;
  create: MetadataTransferredByEmailWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataTransferredbyfullnameUpdateInput {
  where: MetadataTransferredByFullNameWhereUniqueInput;
  update: MetadataTransferredByFullNameWithForcedPhotoUpdateInput;
  create: MetadataTransferredByFullNameWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataTransmissionreferenceUpdateInput {
  where: MetadataTransmissionReferenceWhereUniqueInput;
  update: MetadataTransmissionReferenceWithForcedPhotoUpdateInput;
  create: MetadataTransmissionReferenceWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataTypeUpdateInput {
  where: MetadataTypeWhereUniqueInput;
  update: MetadataTypeWithForcedPhotoUpdateInput;
  create: MetadataTypeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUndersubjectUpdateInput {
  where: MetadataUndersubjectWhereUniqueInput;
  update: MetadataUndersubjectWithForcedPhotoUpdateInput;
  create: MetadataUndersubjectWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUnderundersubjectUpdateInput {
  where: MetadataUnderUnderSubjectWhereUniqueInput;
  update: MetadataUnderUnderSubjectWithForcedPhotoUpdateInput;
  create: MetadataUnderUnderSubjectWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUniqueidUpdateInput {
  where: MetadataUniqueIdWhereUniqueInput;
  update: MetadataUniqueIdWithForcedPhotoUpdateInput;
  create: MetadataUniqueIdWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUnitUpdateInput {
  where: MetadataUnitWhereUniqueInput;
  update: MetadataUnitWithForcedPhotoUpdateInput;
  create: MetadataUnitWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUnitshortnameUpdateInput {
  where: MetadataUnitShortNameWhereUniqueInput;
  update: MetadataUnitShortNameWithForcedPhotoUpdateInput;
  create: MetadataUnitShortNameWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUploadedbyUpdateInput {
  where: MetadataUploadedByWhereUniqueInput;
  update: MetadataUploadedByWithForcedPhotoUpdateInput;
  create: MetadataUploadedByWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUploadedbyfullnameUpdateInput {
  where: MetadataUploadedByFullNameWhereUniqueInput;
  update: MetadataUploadedByFullNameWithForcedPhotoUpdateInput;
  create: MetadataUploadedByFullNameWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUploadtimeUpdateInput {
  where: MetadataUploadTimeWhereUniqueInput;
  update: MetadataUploadTimeWithForcedPhotoUpdateInput;
  create: MetadataUploadTimeWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUrgencyUpdateInput {
  where: MetadataUrgencyWhereUniqueInput;
  update: MetadataUrgencyWithForcedPhotoUpdateInput;
  create: MetadataUrgencyWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUsagetermsUpdateInput {
  where: MetadataUsageTermsWhereUniqueInput;
  update: MetadataUsageTermsWithForcedPhotoUpdateInput;
  create: MetadataUsageTermsWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUsercommentUpdateInput {
  where: MetadataUserCommentWhereUniqueInput;
  update: MetadataUserCommentWithForcedPhotoUpdateInput;
  create: MetadataUserCommentWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUserdefined195UpdateInput {
  where: MetadataUserDefined195WhereUniqueInput;
  update: MetadataUserDefined195WithForcedPhotoUpdateInput;
  create: MetadataUserDefined195WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUserdefined237UpdateInput {
  where: MetadataUserDefined237WhereUniqueInput;
  update: MetadataUserDefined237WithForcedPhotoUpdateInput;
  create: MetadataUserDefined237WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUserdefined238UpdateInput {
  where: MetadataUserDefined238WhereUniqueInput;
  update: MetadataUserDefined238WithForcedPhotoUpdateInput;
  create: MetadataUserDefined238WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUserdefined239UpdateInput {
  where: MetadataUserDefined239WhereUniqueInput;
  update: MetadataUserDefined239WithForcedPhotoUpdateInput;
  create: MetadataUserDefined239WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUserdefined242UpdateInput {
  where: MetadataUserDefined242WhereUniqueInput;
  update: MetadataUserDefined242WithForcedPhotoUpdateInput;
  create: MetadataUserDefined242WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataUserdefined62UpdateInput {
  where: MetadataUserDefined62WhereUniqueInput;
  update: MetadataUserDefined62WithForcedPhotoUpdateInput;
  create: MetadataUserDefined62WithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataValidUpdateInput {
  where: MetadataValidWhereUniqueInput;
  update: MetadataValidWithForcedPhotoUpdateInput;
  create: MetadataValidWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataVersionUpdateInput {
  where: MetadataVersionWhereUniqueInput;
  update: MetadataVersionWithForcedPhotoUpdateInput;
  create: MetadataVersionWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataWebstatementUpdateInput {
  where: MetadataWebStatementWhereUniqueInput;
  update: MetadataWebStatementWithForcedPhotoUpdateInput;
  create: MetadataWebStatementWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataWorkflowkindUpdateInput {
  where: MetadataWorkflowKindWhereUniqueInput;
  update: MetadataWorkflowKindWithForcedPhotoUpdateInput;
  create: MetadataWorkflowKindWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataXmpfilestampsUpdateInput {
  where: MetadataXmpFileStampsWhereUniqueInput;
  update: MetadataXmpFileStampsWithForcedPhotoUpdateInput;
  create: MetadataXmpFileStampsWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertMetadataXmphistoryUpdateInput {
  where: MetadataXmpHistoryWhereUniqueInput;
  update: MetadataXmpHistoryWithForcedPhotoUpdateInput;
  create: MetadataXmpHistoryWithForcedPhotoCreateInput;
}

export interface PhotoNestedUpsertPhotoMetadatasUpdateInput {
  where: PhotoMetadataWhereUniqueInput;
  update: PhotoMetadataWithForcedPhotoUpdateInput;
  create: PhotoMetadataWithForcedPhotoCreateInput;
}

export interface PhotoUpdateInput {
  originalName?: string | null;
  filesize?: number | null;
  height?: number | null;
  width?: number | null;
  contentType?: string | null;
  isUploaded?: boolean | null;
  isLegacy?: boolean | null;
  archive?: PhotoNestedArchiveUpdateInput | null;
  category?: PhotoNestedCategoryUpdateInput | null;
  agency?: PhotoNestedAgencyUpdateInput | null;
  deliverySubject?: PhotoNestedDeliverySubjectUpdateInput | null;
  importQueue?: PhotoNestedImportQueueUpdateInput | null;
  photoMetadatas?: PhotoNestedPhotoMetadatasUpdateInput | null;
  Metadata_SerialNumber?: PhotoNestedMetadataSerialnumberUpdateInput | null;
  Metadata_attributionName?: PhotoNestedMetadataAttributionnameUpdateInput | null;
  Metadata_attributionURL?: PhotoNestedMetadataAttributionurlUpdateInput | null;
  Metadata_license?: PhotoNestedMetadataLicenseUpdateInput | null;
  Metadata_morePermissions?: PhotoNestedMetadataMorepermissionsUpdateInput | null;
  Metadata_PaintBasedCorrections?: PhotoNestedMetadataPaintbasedcorrectionsUpdateInput | null;
  Metadata_ToneCurve?: PhotoNestedMetadataTonecurveUpdateInput | null;
  Metadata_creator?: PhotoNestedMetadataCreatorUpdateInput | null;
  Metadata_description?: PhotoNestedMetadataDescriptionUpdateInput | null;
  Metadata_Format?: PhotoNestedMetadataFormatUpdateInput | null;
  Metadata_Page?: PhotoNestedMetadataPageUpdateInput | null;
  Metadata_Publisher?: PhotoNestedMetadataPublisherUpdateInput | null;
  Metadata_rights?: PhotoNestedMetadataRightsUpdateInput | null;
  Metadata_subject?: PhotoNestedMetadataSubjectUpdateInput | null;
  Metadata_title?: PhotoNestedMetadataTitleUpdateInput | null;
  Metadata_Type?: PhotoNestedMetadataTypeUpdateInput | null;
  Metadata_AccessRights?: PhotoNestedMetadataAccessrightsUpdateInput | null;
  Metadata_Audience?: PhotoNestedMetadataAudienceUpdateInput | null;
  Metadata_Valid?: PhotoNestedMetadataValidUpdateInput | null;
  Metadata_EquipmentInstitution?: PhotoNestedMetadataEquipmentinstitutionUpdateInput | null;
  Metadata_EquipmentManufacturer?: PhotoNestedMetadataEquipmentmanufacturerUpdateInput | null;
  Metadata_FileInfoBitsPerPixel?: PhotoNestedMetadataFileinfobitsperpixelUpdateInput | null;
  Metadata_FileInfoFrames?: PhotoNestedMetadataFileinfoframesUpdateInput | null;
  Metadata_FileInfoPhotometricInterpretation?: PhotoNestedMetadataFileinfophotometricinterpretationUpdateInput | null;
  Metadata_FileInfoTransferSyntax?: PhotoNestedMetadataFileinfotransfersyntaxUpdateInput | null;
  Metadata_PatientDOB?: PhotoNestedMetadataPatientdobUpdateInput | null;
  Metadata_PatientID?: PhotoNestedMetadataPatientidUpdateInput | null;
  Metadata_PatientName?: PhotoNestedMetadataPatientnameUpdateInput | null;
  Metadata_PatientSex?: PhotoNestedMetadataPatientsexUpdateInput | null;
  Metadata_SeriesDateTime?: PhotoNestedMetadataSeriesdatetimeUpdateInput | null;
  Metadata_SeriesDescription?: PhotoNestedMetadataSeriesdescriptionUpdateInput | null;
  Metadata_SeriesModality?: PhotoNestedMetadataSeriesmodalityUpdateInput | null;
  Metadata_SeriesNumber?: PhotoNestedMetadataSeriesnumberUpdateInput | null;
  Metadata_StudyDateTime?: PhotoNestedMetadataStudydatetimeUpdateInput | null;
  Metadata_StudyDescription?: PhotoNestedMetadataStudydescriptionUpdateInput | null;
  Metadata_StudyID?: PhotoNestedMetadataStudyidUpdateInput | null;
  Metadata_StudyPhysician?: PhotoNestedMetadataStudyphysicianUpdateInput | null;
  Metadata_DateTimeDigitized?: PhotoNestedMetadataDatetimedigitizedUpdateInput | null;
  Metadata_DateTimeOriginal?: PhotoNestedMetadataDatetimeoriginalUpdateInput | null;
  Metadata_GPSLatitude?: PhotoNestedMetadataGpslatitudeUpdateInput | null;
  Metadata_GPSLongitude?: PhotoNestedMetadataGpslongitudeUpdateInput | null;
  Metadata_GPSProcessingMethod?: PhotoNestedMetadataGpsprocessingmethodUpdateInput | null;
  Metadata_ImageUniqueID?: PhotoNestedMetadataImageuniqueidUpdateInput | null;
  Metadata_ISOSpeedRatings?: PhotoNestedMetadataIsospeedratingsUpdateInput | null;
  Metadata_UserComment?: PhotoNestedMetadataUsercommentUpdateInput | null;
  Metadata_CustomField14?: PhotoNestedMetadataCustomfield14UpdateInput | null;
  Metadata_CustomField15?: PhotoNestedMetadataCustomfield15UpdateInput | null;
  Metadata_CustomField16?: PhotoNestedMetadataCustomfield16UpdateInput | null;
  Metadata_CustomField17?: PhotoNestedMetadataCustomfield17UpdateInput | null;
  Metadata_CustomField20?: PhotoNestedMetadataCustomfield20UpdateInput | null;
  Metadata_CustomField5?: PhotoNestedMetadataCustomfield5UpdateInput | null;
  Metadata_CustomField6?: PhotoNestedMetadataCustomfield6UpdateInput | null;
  Metadata_CustomField7?: PhotoNestedMetadataCustomfield7UpdateInput | null;
  Metadata_CustomField9?: PhotoNestedMetadataCustomfield9UpdateInput | null;
  Metadata_CreatedTime?: PhotoNestedMetadataCreatedtimeUpdateInput | null;
  Metadata_EditStatus?: PhotoNestedMetadataEditstatusUpdateInput | null;
  Metadata_FixtureIdentifier?: PhotoNestedMetadataFixtureidentifierUpdateInput | null;
  Metadata_LocalCaption?: PhotoNestedMetadataLocalcaptionUpdateInput | null;
  Metadata_ProgramVersion?: PhotoNestedMetadataProgramversionUpdateInput | null;
  Metadata_ReferenceDate?: PhotoNestedMetadataReferencedateUpdateInput | null;
  Metadata_ReferenceNumber?: PhotoNestedMetadataReferencenumberUpdateInput | null;
  Metadata_ReferenceService?: PhotoNestedMetadataReferenceserviceUpdateInput | null;
  Metadata_ReleaseDate?: PhotoNestedMetadataReleasedateUpdateInput | null;
  Metadata_ReleaseTime?: PhotoNestedMetadataReleasetimeUpdateInput | null;
  Metadata_AssetState?: PhotoNestedMetadataAssetstateUpdateInput | null;
  Metadata_Classify?: PhotoNestedMetadataClassifyUpdateInput | null;
  Metadata_ContainedIn?: PhotoNestedMetadataContainedinUpdateInput | null;
  Metadata_ContentValue?: PhotoNestedMetadataContentvalueUpdateInput | null;
  Metadata_DocumentText?: PhotoNestedMetadataDocumenttextUpdateInput | null;
  Metadata_Exif?: PhotoNestedMetadataExifUpdateInput | null;
  Metadata_History?: PhotoNestedMetadataHistoryUpdateInput | null;
  Metadata_ImageNotes?: PhotoNestedMetadataImagenotesUpdateInput | null;
  Metadata_JobId?: PhotoNestedMetadataJobidUpdateInput | null;
  Metadata_Links?: PhotoNestedMetadataLinksUpdateInput | null;
  Metadata_MasterDocumentId?: PhotoNestedMetadataMasterdocumentidUpdateInput | null;
  Metadata_ObjectCycle?: PhotoNestedMetadataObjectcycleUpdateInput | null;
  Metadata_OwnerId?: PhotoNestedMetadataOwneridUpdateInput | null;
  Metadata_ShortUniqueId?: PhotoNestedMetadataShortuniqueidUpdateInput | null;
  Metadata_TransferredBy?: PhotoNestedMetadataTransferredbyUpdateInput | null;
  Metadata_TransferredByEmail?: PhotoNestedMetadataTransferredbyemailUpdateInput | null;
  Metadata_TransferredByFullName?: PhotoNestedMetadataTransferredbyfullnameUpdateInput | null;
  Metadata_UniqueId?: PhotoNestedMetadataUniqueidUpdateInput | null;
  Metadata_UploadedBy?: PhotoNestedMetadataUploadedbyUpdateInput | null;
  Metadata_UploadedByFullName?: PhotoNestedMetadataUploadedbyfullnameUpdateInput | null;
  Metadata_UploadTime?: PhotoNestedMetadataUploadtimeUpdateInput | null;
  Metadata_UserDefined195?: PhotoNestedMetadataUserdefined195UpdateInput | null;
  Metadata_UserDefined237?: PhotoNestedMetadataUserdefined237UpdateInput | null;
  Metadata_UserDefined238?: PhotoNestedMetadataUserdefined238UpdateInput | null;
  Metadata_UserDefined239?: PhotoNestedMetadataUserdefined239UpdateInput | null;
  Metadata_UserDefined242?: PhotoNestedMetadataUserdefined242UpdateInput | null;
  Metadata_UserDefined62?: PhotoNestedMetadataUserdefined62UpdateInput | null;
  Metadata_CiAdrCity?: PhotoNestedMetadataCiadrcityUpdateInput | null;
  Metadata_CiAdrCtry?: PhotoNestedMetadataCiadrctryUpdateInput | null;
  Metadata_CiAdrExtadr?: PhotoNestedMetadataCiadrextadrUpdateInput | null;
  Metadata_CiAdrPcode?: PhotoNestedMetadataCiadrpcodeUpdateInput | null;
  Metadata_CiAdrRegion?: PhotoNestedMetadataCiadrregionUpdateInput | null;
  Metadata_CiEmailWork?: PhotoNestedMetadataCiemailworkUpdateInput | null;
  Metadata_CiTelWork?: PhotoNestedMetadataCitelworkUpdateInput | null;
  Metadata_CiUrlWork?: PhotoNestedMetadataCiurlworkUpdateInput | null;
  Metadata_CountryCode?: PhotoNestedMetadataCountrycodeUpdateInput | null;
  Metadata_IntellectualGenre?: PhotoNestedMetadataIntellectualgenreUpdateInput | null;
  Metadata_Location?: PhotoNestedMetadataLocationUpdateInput | null;
  Metadata_Scene?: PhotoNestedMetadataSceneUpdateInput | null;
  Metadata_SubjectCode?: PhotoNestedMetadataSubjectcodeUpdateInput | null;
  Metadata_AddlModelInfo?: PhotoNestedMetadataAddlmodelinfoUpdateInput | null;
  Metadata_Event?: PhotoNestedMetadataEventUpdateInput | null;
  Metadata_MaxAvailHeight?: PhotoNestedMetadataMaxavailheightUpdateInput | null;
  Metadata_MaxAvailWidth?: PhotoNestedMetadataMaxavailwidthUpdateInput | null;
  Metadata_ModelAge?: PhotoNestedMetadataModelageUpdateInput | null;
  Metadata_OrganisationInImageName?: PhotoNestedMetadataOrganisationinimagenameUpdateInput | null;
  Metadata_PersonInImage?: PhotoNestedMetadataPersoninimageUpdateInput | null;
  Metadata_AuthorsPosition?: PhotoNestedMetadataAuthorspositionUpdateInput | null;
  Metadata_CaptionWriter?: PhotoNestedMetadataCaptionwriterUpdateInput | null;
  Metadata_Category?: PhotoNestedMetadataCategoryUpdateInput | null;
  Metadata_City?: PhotoNestedMetadataCityUpdateInput | null;
  Metadata_Country?: PhotoNestedMetadataCountryUpdateInput | null;
  Metadata_Credit?: PhotoNestedMetadataCreditUpdateInput | null;
  Metadata_DateCreated?: PhotoNestedMetadataDatecreatedUpdateInput | null;
  Metadata_Headline?: PhotoNestedMetadataHeadlineUpdateInput | null;
  Metadata_ICCProfile?: PhotoNestedMetadataIccprofileUpdateInput | null;
  Metadata_Instructions?: PhotoNestedMetadataInstructionsUpdateInput | null;
  Metadata_Source?: PhotoNestedMetadataSourceUpdateInput | null;
  Metadata_State?: PhotoNestedMetadataStateUpdateInput | null;
  Metadata_SupplementalCategories?: PhotoNestedMetadataSupplementalcategoriesUpdateInput | null;
  Metadata_TransmissionReference?: PhotoNestedMetadataTransmissionreferenceUpdateInput | null;
  Metadata_Urgency?: PhotoNestedMetadataUrgencyUpdateInput | null;
  Metadata_MinorModelAgeDisclosure?: PhotoNestedMetadataMinormodelagedisclosureUpdateInput | null;
  Metadata_ModelReleaseID?: PhotoNestedMetadataModelreleaseidUpdateInput | null;
  Metadata_ModelReleaseStatus?: PhotoNestedMetadataModelreleasestatusUpdateInput | null;
  Metadata_PropertyReleaseID?: PhotoNestedMetadataPropertyreleaseidUpdateInput | null;
  Metadata_PropertyReleaseStatus?: PhotoNestedMetadataPropertyreleasestatusUpdateInput | null;
  Metadata_Version?: PhotoNestedMetadataVersionUpdateInput | null;
  Metadata_Brand?: PhotoNestedMetadataBrandUpdateInput | null;
  Metadata_Division?: PhotoNestedMetadataDivisionUpdateInput | null;
  Metadata_Header?: PhotoNestedMetadataHeaderUpdateInput | null;
  Metadata_Products?: PhotoNestedMetadataProductsUpdateInput | null;
  Metadata_Produits?: PhotoNestedMetadataProduitsUpdateInput | null;
  Metadata_publishingIssue?: PhotoNestedMetadataPublishingissueUpdateInput | null;
  Metadata_publishingSubject?: PhotoNestedMetadataPublishingsubjectUpdateInput | null;
  Metadata_Company?: PhotoNestedMetadataCompanyUpdateInput | null;
  Metadata_CompanyShortName?: PhotoNestedMetadataCompanyshortnameUpdateInput | null;
  Metadata_Confidentiality?: PhotoNestedMetadataConfidentialityUpdateInput | null;
  Metadata_EditorialVersion?: PhotoNestedMetadataEditorialversionUpdateInput | null;
  Metadata_FileCheckFor?: PhotoNestedMetadataFilecheckforUpdateInput | null;
  Metadata_FileStage?: PhotoNestedMetadataFilestageUpdateInput | null;
  Metadata_FileStatus?: PhotoNestedMetadataFilestatusUpdateInput | null;
  Metadata_SentTo?: PhotoNestedMetadataSenttoUpdateInput | null;
  Metadata_TargetVersion?: PhotoNestedMetadataTargetversionUpdateInput | null;
  Metadata_Unit?: PhotoNestedMetadataUnitUpdateInput | null;
  Metadata_Archive_child?: PhotoNestedMetadataArchiveChildUpdateInput | null;
  Metadata_Archive_parent?: PhotoNestedMetadataArchiveParentUpdateInput | null;
  Metadata_BackupName?: PhotoNestedMetadataBackupnameUpdateInput | null;
  Metadata_BitmapGrayscalePicture?: PhotoNestedMetadataBitmapgrayscalepictureUpdateInput | null;
  Metadata_BrandPrisma?: PhotoNestedMetadataBrandprismaUpdateInput | null;
  Metadata_BrandShortName?: PhotoNestedMetadataBrandshortnameUpdateInput | null;
  Metadata_CategoryPrisma?: PhotoNestedMetadataCategoryprismaUpdateInput | null;
  Metadata_ContentMediaKind?: PhotoNestedMetadataContentmediakindUpdateInput | null;
  Metadata_CopyrightLayer?: PhotoNestedMetadataCopyrightlayerUpdateInput | null;
  Metadata_DeliveryAccount?: PhotoNestedMetadataDeliveryaccountUpdateInput | null;
  Metadata_DeliveryCompany?: PhotoNestedMetadataDeliverycompanyUpdateInput | null;
  Metadata_DeliveryCopyright?: PhotoNestedMetadataDeliverycopyrightUpdateInput | null;
  Metadata_DeliveryDateFolder?: PhotoNestedMetadataDeliverydatefolderUpdateInput | null;
  Metadata_DeliveryDateTime?: PhotoNestedMetadataDeliverydatetimeUpdateInput | null;
  Metadata_DeliveryDcCreator?: PhotoNestedMetadataDeliverydccreatorUpdateInput | null;
  Metadata_DeliveryDcRights?: PhotoNestedMetadataDeliverydcrightsUpdateInput | null;
  Metadata_DeliveryFileType?: PhotoNestedMetadataDeliveryfiletypeUpdateInput | null;
  Metadata_DeliveryFolder?: PhotoNestedMetadataDeliveryfolderUpdateInput | null;
  Metadata_DeliveryKind?: PhotoNestedMetadataDeliverykindUpdateInput | null;
  Metadata_DeliveryPath?: PhotoNestedMetadataDeliverypathUpdateInput | null;
  Metadata_DeliveryPersonShownintheImage?: PhotoNestedMetadataDeliverypersonshownintheimageUpdateInput | null;
  Metadata_DeliveryPhotoshopCredit?: PhotoNestedMetadataDeliveryphotoshopcreditUpdateInput | null;
  Metadata_DeliveryPhotoshopSource?: PhotoNestedMetadataDeliveryphotoshopsourceUpdateInput | null;
  Metadata_DeliveryService?: PhotoNestedMetadataDeliveryserviceUpdateInput | null;
  Metadata_DeliverySubject?: PhotoNestedMetadataDeliverysubjectUpdateInput | null;
  Metadata_DeliveryUnderSubject?: PhotoNestedMetadataDeliveryundersubjectUpdateInput | null;
  Metadata_Department?: PhotoNestedMetadataDepartmentUpdateInput | null;
  Metadata_DigitalAssetURL?: PhotoNestedMetadataDigitalasseturlUpdateInput | null;
  Metadata_Edition?: PhotoNestedMetadataEditionUpdateInput | null;
  Metadata_EnvironnementPhoto?: PhotoNestedMetadataEnvironnementphotoUpdateInput | null;
  Metadata_FabStorage?: PhotoNestedMetadataFabstorageUpdateInput | null;
  Metadata_FileType?: PhotoNestedMetadataFiletypeUpdateInput | null;
  Metadata_HeaderPrisma?: PhotoNestedMetadataHeaderprismaUpdateInput | null;
  Metadata_ID_subject?: PhotoNestedMetadataIdSubjectUpdateInput | null;
  Metadata_IdAssignment?: PhotoNestedMetadataIdassignmentUpdateInput | null;
  Metadata_IdMediaContent?: PhotoNestedMetadataIdmediacontentUpdateInput | null;
  Metadata_IdPhotoPrisma?: PhotoNestedMetadataIdphotoprismaUpdateInput | null;
  Metadata_Issue?: PhotoNestedMetadataIssueUpdateInput | null;
  Metadata_JobProcessing?: PhotoNestedMetadataJobprocessingUpdateInput | null;
  Metadata_LayoutPictureEditing?: PhotoNestedMetadataLayoutpictureeditingUpdateInput | null;
  Metadata_LayoutStorage?: PhotoNestedMetadataLayoutstorageUpdateInput | null;
  Metadata_OriginalColor?: PhotoNestedMetadataOriginalcolorUpdateInput | null;
  Metadata_OriginalICCProfile?: PhotoNestedMetadataOriginaliccprofileUpdateInput | null;
  Metadata_OriginalName?: PhotoNestedMetadataOriginalnameUpdateInput | null;
  Metadata_OriginalWeigth?: PhotoNestedMetadataOriginalweigthUpdateInput | null;
  Metadata_PagePrisma?: PhotoNestedMetadataPageprismaUpdateInput | null;
  Metadata_PhotoStorage?: PhotoNestedMetadataPhotostorageUpdateInput | null;
  Metadata_PrepressPictureEditing?: PhotoNestedMetadataPrepresspictureeditingUpdateInput | null;
  Metadata_PriceLevel?: PhotoNestedMetadataPricelevelUpdateInput | null;
  Metadata_PrintingProfile?: PhotoNestedMetadataPrintingprofileUpdateInput | null;
  Metadata_PrismaProduction?: PhotoNestedMetadataPrismaproductionUpdateInput | null;
  Metadata_ProcessHistory?: PhotoNestedMetadataProcesshistoryUpdateInput | null;
  Metadata_ProcessParameter?: PhotoNestedMetadataProcessparameterUpdateInput | null;
  Metadata_ProcessStatus?: PhotoNestedMetadataProcessstatusUpdateInput | null;
  Metadata_Product?: PhotoNestedMetadataProductUpdateInput | null;
  Metadata_ProductShortName?: PhotoNestedMetadataProductshortnameUpdateInput | null;
  Metadata_QualifiedUsePrismaBy?: PhotoNestedMetadataQualifieduseprismabyUpdateInput | null;
  Metadata_QualifiedUsePrismaDate?: PhotoNestedMetadataQualifieduseprismadateUpdateInput | null;
  Metadata_QualifiedUsePrismaDuration?: PhotoNestedMetadataQualifieduseprismadurationUpdateInput | null;
  Metadata_QualifiedUsePrismaSupport?: PhotoNestedMetadataQualifieduseprismasupportUpdateInput | null;
  Metadata_RequiredPermission?: PhotoNestedMetadataRequiredpermissionUpdateInput | null;
  Metadata_ResolutionKind?: PhotoNestedMetadataResolutionkindUpdateInput | null;
  Metadata_Royaltyfree?: PhotoNestedMetadataRoyaltyfreeUpdateInput | null;
  Metadata_Section?: PhotoNestedMetadataSectionUpdateInput | null;
  Metadata_SubjectPrisma?: PhotoNestedMetadataSubjectprismaUpdateInput | null;
  Metadata_Undersubject?: PhotoNestedMetadataUndersubjectUpdateInput | null;
  Metadata_UnderUnderSubject?: PhotoNestedMetadataUnderundersubjectUpdateInput | null;
  Metadata_UnitShortName?: PhotoNestedMetadataUnitshortnameUpdateInput | null;
  Metadata_WorkflowKind?: PhotoNestedMetadataWorkflowkindUpdateInput | null;
  Metadata_Make?: PhotoNestedMetadataMakeUpdateInput | null;
  Metadata_Model?: PhotoNestedMetadataModelUpdateInput | null;
  Metadata_CreatorTool?: PhotoNestedMetadataCreatortoolUpdateInput | null;
  Metadata_Rating?: PhotoNestedMetadataRatingUpdateInput | null;
  Metadata_XMPFileStamps?: PhotoNestedMetadataXmpfilestampsUpdateInput | null;
  Metadata_Manifest?: PhotoNestedMetadataManifestUpdateInput | null;
  Metadata_XMPHistory?: PhotoNestedMetadataXmphistoryUpdateInput | null;
  Metadata_Certificate?: PhotoNestedMetadataCertificateUpdateInput | null;
  Metadata_Marked?: PhotoNestedMetadataMarkedUpdateInput | null;
  Metadata_Owner?: PhotoNestedMetadataOwnerUpdateInput | null;
  Metadata_UsageTerms?: PhotoNestedMetadataUsagetermsUpdateInput | null;
  Metadata_WebStatement?: PhotoNestedMetadataWebstatementUpdateInput | null;
}

/**
 * Filters the "Photo" nodes by specifying some conditions
 */
export interface PhotoWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  originalName?: string | null;
  originalName_not?: string | null;
  originalName_is_null?: boolean | null;
  originalName_in?: (string | null)[] | null;
  originalName_not_in?: (string | null)[] | null;
  originalName_contains?: string | null;
  originalName_not_contains?: string | null;
  originalName_starts_with?: string | null;
  originalName_not_starts_with?: string | null;
  originalName_ends_with?: string | null;
  originalName_not_ends_with?: string | null;
  originalName_lt?: string | null;
  originalName_lte?: string | null;
  originalName_gt?: string | null;
  originalName_gte?: string | null;
  filesize?: number | null;
  filesize_not?: number | null;
  filesize_is_null?: boolean | null;
  filesize_in?: (number | null)[] | null;
  filesize_not_in?: (number | null)[] | null;
  filesize_lt?: number | null;
  filesize_lte?: number | null;
  filesize_gt?: number | null;
  filesize_gte?: number | null;
  height?: number | null;
  height_not?: number | null;
  height_is_null?: boolean | null;
  height_in?: (number | null)[] | null;
  height_not_in?: (number | null)[] | null;
  height_lt?: number | null;
  height_lte?: number | null;
  height_gt?: number | null;
  height_gte?: number | null;
  width?: number | null;
  width_not?: number | null;
  width_is_null?: boolean | null;
  width_in?: (number | null)[] | null;
  width_not_in?: (number | null)[] | null;
  width_lt?: number | null;
  width_lte?: number | null;
  width_gt?: number | null;
  width_gte?: number | null;
  contentType?: string | null;
  contentType_not?: string | null;
  contentType_is_null?: boolean | null;
  contentType_in?: (string | null)[] | null;
  contentType_not_in?: (string | null)[] | null;
  contentType_contains?: string | null;
  contentType_not_contains?: string | null;
  contentType_starts_with?: string | null;
  contentType_not_starts_with?: string | null;
  contentType_ends_with?: string | null;
  contentType_not_ends_with?: string | null;
  contentType_lt?: string | null;
  contentType_lte?: string | null;
  contentType_gt?: string | null;
  contentType_gte?: string | null;
  isUploaded?: boolean | null;
  isUploaded_not?: boolean | null;
  isUploaded_is_null?: boolean | null;
  isUploaded_in?: (boolean | null)[] | null;
  isUploaded_not_in?: (boolean | null)[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  isLegacy?: boolean | null;
  isLegacy_not?: boolean | null;
  isLegacy_is_null?: boolean | null;
  isLegacy_in?: (boolean | null)[] | null;
  isLegacy_not_in?: (boolean | null)[] | null;
  archive?: ArchiveWhereInput | null;
  category?: CategoryWhereInput | null;
  category_is_null?: boolean | null;
  agency?: AgencyWhereInput | null;
  agency_is_null?: boolean | null;
  deliverySubject?: DeliverySubjectWhereInput | null;
  deliverySubject_is_null?: boolean | null;
  importQueue?: ImportQueueWhereInput | null;
  importQueue_is_null?: boolean | null;
  photoMetadatas_some?: PhotoMetadataWhereInput | null;
  Metadata_SerialNumber_some?: MetadataSerialNumberWhereInput | null;
  Metadata_attributionName_some?: MetadataAttributionNameWhereInput | null;
  Metadata_attributionURL_some?: MetadataAttributionUrlWhereInput | null;
  Metadata_license_some?: MetadataLicenseWhereInput | null;
  Metadata_morePermissions_some?: MetadataMorePermissionsWhereInput | null;
  Metadata_PaintBasedCorrections_some?: MetadataPaintBasedCorrectionsWhereInput | null;
  Metadata_ToneCurve_some?: MetadataToneCurveWhereInput | null;
  Metadata_creator_some?: MetadataCreatorWhereInput | null;
  Metadata_description_some?: MetadataDescriptionWhereInput | null;
  Metadata_Format_some?: MetadataFormatWhereInput | null;
  Metadata_Page_some?: MetadataPageWhereInput | null;
  Metadata_Publisher_some?: MetadataPublisherWhereInput | null;
  Metadata_rights_some?: MetadataRightsWhereInput | null;
  Metadata_subject_some?: MetadataSubjectWhereInput | null;
  Metadata_title_some?: MetadataTitleWhereInput | null;
  Metadata_Type_some?: MetadataTypeWhereInput | null;
  Metadata_AccessRights_some?: MetadataAccessRightsWhereInput | null;
  Metadata_Audience_some?: MetadataAudienceWhereInput | null;
  Metadata_Valid_some?: MetadataValidWhereInput | null;
  Metadata_EquipmentInstitution_some?: MetadataEquipmentInstitutionWhereInput | null;
  Metadata_EquipmentManufacturer_some?: MetadataEquipmentManufacturerWhereInput | null;
  Metadata_FileInfoBitsPerPixel_some?: MetadataFileInfoBitsPerPixelWhereInput | null;
  Metadata_FileInfoFrames_some?: MetadataFileInfoFramesWhereInput | null;
  Metadata_FileInfoPhotometricInterpretation_some?: MetadataFileInfoPhotometricInterpretationWhereInput | null;
  Metadata_FileInfoTransferSyntax_some?: MetadataFileInfoTransferSyntaxWhereInput | null;
  Metadata_PatientDOB_some?: MetadataPatientDobWhereInput | null;
  Metadata_PatientID_some?: MetadataPatientIdWhereInput | null;
  Metadata_PatientName_some?: MetadataPatientNameWhereInput | null;
  Metadata_PatientSex_some?: MetadataPatientSexWhereInput | null;
  Metadata_SeriesDateTime_some?: MetadataSeriesDateTimeWhereInput | null;
  Metadata_SeriesDescription_some?: MetadataSeriesDescriptionWhereInput | null;
  Metadata_SeriesModality_some?: MetadataSeriesModalityWhereInput | null;
  Metadata_SeriesNumber_some?: MetadataSeriesNumberWhereInput | null;
  Metadata_StudyDateTime_some?: MetadataStudyDateTimeWhereInput | null;
  Metadata_StudyDescription_some?: MetadataStudyDescriptionWhereInput | null;
  Metadata_StudyID_some?: MetadataStudyIdWhereInput | null;
  Metadata_StudyPhysician_some?: MetadataStudyPhysicianWhereInput | null;
  Metadata_DateTimeDigitized_some?: MetadataDateTimeDigitizedWhereInput | null;
  Metadata_DateTimeOriginal_some?: MetadataDateTimeOriginalWhereInput | null;
  Metadata_GPSLatitude_some?: MetadataGpsLatitudeWhereInput | null;
  Metadata_GPSLongitude_some?: MetadataGpsLongitudeWhereInput | null;
  Metadata_GPSProcessingMethod_some?: MetadataGpsProcessingMethodWhereInput | null;
  Metadata_ImageUniqueID_some?: MetadataImageUniqueIdWhereInput | null;
  Metadata_ISOSpeedRatings_some?: MetadataIsoSpeedRatingsWhereInput | null;
  Metadata_UserComment_some?: MetadataUserCommentWhereInput | null;
  Metadata_CustomField14_some?: MetadataCustomField14WhereInput | null;
  Metadata_CustomField15_some?: MetadataCustomField15WhereInput | null;
  Metadata_CustomField16_some?: MetadataCustomField16WhereInput | null;
  Metadata_CustomField17_some?: MetadataCustomField17WhereInput | null;
  Metadata_CustomField20_some?: MetadataCustomField20WhereInput | null;
  Metadata_CustomField5_some?: MetadataCustomField5WhereInput | null;
  Metadata_CustomField6_some?: MetadataCustomField6WhereInput | null;
  Metadata_CustomField7_some?: MetadataCustomField7WhereInput | null;
  Metadata_CustomField9_some?: MetadataCustomField9WhereInput | null;
  Metadata_CreatedTime_some?: MetadataCreatedTimeWhereInput | null;
  Metadata_EditStatus_some?: MetadataEditStatusWhereInput | null;
  Metadata_FixtureIdentifier_some?: MetadataFixtureIdentifierWhereInput | null;
  Metadata_LocalCaption_some?: MetadataLocalCaptionWhereInput | null;
  Metadata_ProgramVersion_some?: MetadataProgramVersionWhereInput | null;
  Metadata_ReferenceDate_some?: MetadataReferenceDateWhereInput | null;
  Metadata_ReferenceNumber_some?: MetadataReferenceNumberWhereInput | null;
  Metadata_ReferenceService_some?: MetadataReferenceServiceWhereInput | null;
  Metadata_ReleaseDate_some?: MetadataReleaseDateWhereInput | null;
  Metadata_ReleaseTime_some?: MetadataReleaseTimeWhereInput | null;
  Metadata_AssetState_some?: MetadataAssetStateWhereInput | null;
  Metadata_Classify_some?: MetadataClassifyWhereInput | null;
  Metadata_ContainedIn_some?: MetadataContainedInWhereInput | null;
  Metadata_ContentValue_some?: MetadataContentValueWhereInput | null;
  Metadata_DocumentText_some?: MetadataDocumentTextWhereInput | null;
  Metadata_Exif_some?: MetadataExifWhereInput | null;
  Metadata_History_some?: MetadataHistoryWhereInput | null;
  Metadata_ImageNotes_some?: MetadataImageNotesWhereInput | null;
  Metadata_JobId_some?: MetadataJobIdWhereInput | null;
  Metadata_Links_some?: MetadataLinksWhereInput | null;
  Metadata_MasterDocumentId_some?: MetadataMasterDocumentIdWhereInput | null;
  Metadata_ObjectCycle_some?: MetadataObjectCycleWhereInput | null;
  Metadata_OwnerId_some?: MetadataOwnerIdWhereInput | null;
  Metadata_ShortUniqueId_some?: MetadataShortUniqueIdWhereInput | null;
  Metadata_TransferredBy_some?: MetadataTransferredByWhereInput | null;
  Metadata_TransferredByEmail_some?: MetadataTransferredByEmailWhereInput | null;
  Metadata_TransferredByFullName_some?: MetadataTransferredByFullNameWhereInput | null;
  Metadata_UniqueId_some?: MetadataUniqueIdWhereInput | null;
  Metadata_UploadedBy_some?: MetadataUploadedByWhereInput | null;
  Metadata_UploadedByFullName_some?: MetadataUploadedByFullNameWhereInput | null;
  Metadata_UploadTime_some?: MetadataUploadTimeWhereInput | null;
  Metadata_UserDefined195_some?: MetadataUserDefined195WhereInput | null;
  Metadata_UserDefined237_some?: MetadataUserDefined237WhereInput | null;
  Metadata_UserDefined238_some?: MetadataUserDefined238WhereInput | null;
  Metadata_UserDefined239_some?: MetadataUserDefined239WhereInput | null;
  Metadata_UserDefined242_some?: MetadataUserDefined242WhereInput | null;
  Metadata_UserDefined62_some?: MetadataUserDefined62WhereInput | null;
  Metadata_CiAdrCity_some?: MetadataCiAdrCityWhereInput | null;
  Metadata_CiAdrCtry_some?: MetadataCiAdrCtryWhereInput | null;
  Metadata_CiAdrExtadr_some?: MetadataCiAdrExtadrWhereInput | null;
  Metadata_CiAdrPcode_some?: MetadataCiAdrPcodeWhereInput | null;
  Metadata_CiAdrRegion_some?: MetadataCiAdrRegionWhereInput | null;
  Metadata_CiEmailWork_some?: MetadataCiEmailWorkWhereInput | null;
  Metadata_CiTelWork_some?: MetadataCiTelWorkWhereInput | null;
  Metadata_CiUrlWork_some?: MetadataCiUrlWorkWhereInput | null;
  Metadata_CountryCode_some?: MetadataCountryCodeWhereInput | null;
  Metadata_IntellectualGenre_some?: MetadataIntellectualGenreWhereInput | null;
  Metadata_Location_some?: MetadataLocationWhereInput | null;
  Metadata_Scene_some?: MetadataSceneWhereInput | null;
  Metadata_SubjectCode_some?: MetadataSubjectCodeWhereInput | null;
  Metadata_AddlModelInfo_some?: MetadataAddlModelInfoWhereInput | null;
  Metadata_Event_some?: MetadataEventWhereInput | null;
  Metadata_MaxAvailHeight_some?: MetadataMaxAvailHeightWhereInput | null;
  Metadata_MaxAvailWidth_some?: MetadataMaxAvailWidthWhereInput | null;
  Metadata_ModelAge_some?: MetadataModelAgeWhereInput | null;
  Metadata_OrganisationInImageName_some?: MetadataOrganisationInImageNameWhereInput | null;
  Metadata_PersonInImage_some?: MetadataPersonInImageWhereInput | null;
  Metadata_AuthorsPosition_some?: MetadataAuthorsPositionWhereInput | null;
  Metadata_CaptionWriter_some?: MetadataCaptionWriterWhereInput | null;
  Metadata_Category_some?: MetadataCategoryWhereInput | null;
  Metadata_City_some?: MetadataCityWhereInput | null;
  Metadata_Country_some?: MetadataCountryWhereInput | null;
  Metadata_Credit_some?: MetadataCreditWhereInput | null;
  Metadata_DateCreated_some?: MetadataDateCreatedWhereInput | null;
  Metadata_Headline_some?: MetadataHeadlineWhereInput | null;
  Metadata_ICCProfile_some?: MetadataIccProfileWhereInput | null;
  Metadata_Instructions_some?: MetadataInstructionsWhereInput | null;
  Metadata_Source_some?: MetadataSourceWhereInput | null;
  Metadata_State_some?: MetadataStateWhereInput | null;
  Metadata_SupplementalCategories_some?: MetadataSupplementalCategoriesWhereInput | null;
  Metadata_TransmissionReference_some?: MetadataTransmissionReferenceWhereInput | null;
  Metadata_Urgency_some?: MetadataUrgencyWhereInput | null;
  Metadata_MinorModelAgeDisclosure_some?: MetadataMinorModelAgeDisclosureWhereInput | null;
  Metadata_ModelReleaseID_some?: MetadataModelReleaseIdWhereInput | null;
  Metadata_ModelReleaseStatus_some?: MetadataModelReleaseStatusWhereInput | null;
  Metadata_PropertyReleaseID_some?: MetadataPropertyReleaseIdWhereInput | null;
  Metadata_PropertyReleaseStatus_some?: MetadataPropertyReleaseStatusWhereInput | null;
  Metadata_Version_some?: MetadataVersionWhereInput | null;
  Metadata_Brand_some?: MetadataBrandWhereInput | null;
  Metadata_Division_some?: MetadataDivisionWhereInput | null;
  Metadata_Header_some?: MetadataHeaderWhereInput | null;
  Metadata_Products_some?: MetadataProductsWhereInput | null;
  Metadata_Produits_some?: MetadataProduitsWhereInput | null;
  Metadata_publishingIssue_some?: MetadataPublishingIssueWhereInput | null;
  Metadata_publishingSubject_some?: MetadataPublishingSubjectWhereInput | null;
  Metadata_Company_some?: MetadataCompanyWhereInput | null;
  Metadata_CompanyShortName_some?: MetadataCompanyShortNameWhereInput | null;
  Metadata_Confidentiality_some?: MetadataConfidentialityWhereInput | null;
  Metadata_EditorialVersion_some?: MetadataEditorialVersionWhereInput | null;
  Metadata_FileCheckFor_some?: MetadataFileCheckForWhereInput | null;
  Metadata_FileStage_some?: MetadataFileStageWhereInput | null;
  Metadata_FileStatus_some?: MetadataFileStatusWhereInput | null;
  Metadata_SentTo_some?: MetadataSentToWhereInput | null;
  Metadata_TargetVersion_some?: MetadataTargetVersionWhereInput | null;
  Metadata_Unit_some?: MetadataUnitWhereInput | null;
  Metadata_Archive_child_some?: MetadataArchiveChildWhereInput | null;
  Metadata_Archive_parent_some?: MetadataArchiveParentWhereInput | null;
  Metadata_BackupName_some?: MetadataBackupNameWhereInput | null;
  Metadata_BitmapGrayscalePicture_some?: MetadataBitmapGrayscalePictureWhereInput | null;
  Metadata_BrandPrisma_some?: MetadataBrandPrismaWhereInput | null;
  Metadata_BrandShortName_some?: MetadataBrandShortNameWhereInput | null;
  Metadata_CategoryPrisma_some?: MetadataCategoryPrismaWhereInput | null;
  Metadata_ContentMediaKind_some?: MetadataContentMediaKindWhereInput | null;
  Metadata_CopyrightLayer_some?: MetadataCopyrightLayerWhereInput | null;
  Metadata_DeliveryAccount_some?: MetadataDeliveryAccountWhereInput | null;
  Metadata_DeliveryCompany_some?: MetadataDeliveryCompanyWhereInput | null;
  Metadata_DeliveryCopyright_some?: MetadataDeliveryCopyrightWhereInput | null;
  Metadata_DeliveryDateFolder_some?: MetadataDeliveryDateFolderWhereInput | null;
  Metadata_DeliveryDateTime_some?: MetadataDeliveryDateTimeWhereInput | null;
  Metadata_DeliveryDcCreator_some?: MetadataDeliveryDcCreatorWhereInput | null;
  Metadata_DeliveryDcRights_some?: MetadataDeliveryDcRightsWhereInput | null;
  Metadata_DeliveryFileType_some?: MetadataDeliveryFileTypeWhereInput | null;
  Metadata_DeliveryFolder_some?: MetadataDeliveryFolderWhereInput | null;
  Metadata_DeliveryKind_some?: MetadataDeliveryKindWhereInput | null;
  Metadata_DeliveryPath_some?: MetadataDeliveryPathWhereInput | null;
  Metadata_DeliveryPersonShownintheImage_some?: MetadataDeliveryPersonShownintheImageWhereInput | null;
  Metadata_DeliveryPhotoshopCredit_some?: MetadataDeliveryPhotoshopCreditWhereInput | null;
  Metadata_DeliveryPhotoshopSource_some?: MetadataDeliveryPhotoshopSourceWhereInput | null;
  Metadata_DeliveryService_some?: MetadataDeliveryServiceWhereInput | null;
  Metadata_DeliverySubject_some?: MetadataDeliverySubjectWhereInput | null;
  Metadata_DeliveryUnderSubject_some?: MetadataDeliveryUnderSubjectWhereInput | null;
  Metadata_Department_some?: MetadataDepartmentWhereInput | null;
  Metadata_DigitalAssetURL_some?: MetadataDigitalAssetUrlWhereInput | null;
  Metadata_Edition_some?: MetadataEditionWhereInput | null;
  Metadata_EnvironnementPhoto_some?: MetadataEnvironnementPhotoWhereInput | null;
  Metadata_FabStorage_some?: MetadataFabStorageWhereInput | null;
  Metadata_FileType_some?: MetadataFileTypeWhereInput | null;
  Metadata_HeaderPrisma_some?: MetadataHeaderPrismaWhereInput | null;
  Metadata_ID_subject_some?: MetadataIdSubjectWhereInput | null;
  Metadata_IdAssignment_some?: MetadataIdAssignmentWhereInput | null;
  Metadata_IdMediaContent_some?: MetadataIdMediaContentWhereInput | null;
  Metadata_IdPhotoPrisma_some?: MetadataIdPhotoPrismaWhereInput | null;
  Metadata_Issue_some?: MetadataIssueWhereInput | null;
  Metadata_JobProcessing_some?: MetadataJobProcessingWhereInput | null;
  Metadata_LayoutPictureEditing_some?: MetadataLayoutPictureEditingWhereInput | null;
  Metadata_LayoutStorage_some?: MetadataLayoutStorageWhereInput | null;
  Metadata_OriginalColor_some?: MetadataOriginalColorWhereInput | null;
  Metadata_OriginalICCProfile_some?: MetadataOriginalIccProfileWhereInput | null;
  Metadata_OriginalName_some?: MetadataOriginalNameWhereInput | null;
  Metadata_OriginalWeigth_some?: MetadataOriginalWeigthWhereInput | null;
  Metadata_PagePrisma_some?: MetadataPagePrismaWhereInput | null;
  Metadata_PhotoStorage_some?: MetadataPhotoStorageWhereInput | null;
  Metadata_PrepressPictureEditing_some?: MetadataPrepressPictureEditingWhereInput | null;
  Metadata_PriceLevel_some?: MetadataPriceLevelWhereInput | null;
  Metadata_PrintingProfile_some?: MetadataPrintingProfileWhereInput | null;
  Metadata_PrismaProduction_some?: MetadataPrismaProductionWhereInput | null;
  Metadata_ProcessHistory_some?: MetadataProcessHistoryWhereInput | null;
  Metadata_ProcessParameter_some?: MetadataProcessParameterWhereInput | null;
  Metadata_ProcessStatus_some?: MetadataProcessStatusWhereInput | null;
  Metadata_Product_some?: MetadataProductWhereInput | null;
  Metadata_ProductShortName_some?: MetadataProductShortNameWhereInput | null;
  Metadata_QualifiedUsePrismaBy_some?: MetadataQualifiedUsePrismaByWhereInput | null;
  Metadata_QualifiedUsePrismaDate_some?: MetadataQualifiedUsePrismaDateWhereInput | null;
  Metadata_QualifiedUsePrismaDuration_some?: MetadataQualifiedUsePrismaDurationWhereInput | null;
  Metadata_QualifiedUsePrismaSupport_some?: MetadataQualifiedUsePrismaSupportWhereInput | null;
  Metadata_RequiredPermission_some?: MetadataRequiredPermissionWhereInput | null;
  Metadata_ResolutionKind_some?: MetadataResolutionKindWhereInput | null;
  Metadata_Royaltyfree_some?: MetadataRoyaltyfreeWhereInput | null;
  Metadata_Section_some?: MetadataSectionWhereInput | null;
  Metadata_SubjectPrisma_some?: MetadataSubjectPrismaWhereInput | null;
  Metadata_Undersubject_some?: MetadataUndersubjectWhereInput | null;
  Metadata_UnderUnderSubject_some?: MetadataUnderUnderSubjectWhereInput | null;
  Metadata_UnitShortName_some?: MetadataUnitShortNameWhereInput | null;
  Metadata_WorkflowKind_some?: MetadataWorkflowKindWhereInput | null;
  Metadata_Make_some?: MetadataMakeWhereInput | null;
  Metadata_Model_some?: MetadataModelWhereInput | null;
  Metadata_CreatorTool_some?: MetadataCreatorToolWhereInput | null;
  Metadata_Rating_some?: MetadataRatingWhereInput | null;
  Metadata_XMPFileStamps_some?: MetadataXmpFileStampsWhereInput | null;
  Metadata_Manifest_some?: MetadataManifestWhereInput | null;
  Metadata_XMPHistory_some?: MetadataXmpHistoryWhereInput | null;
  Metadata_Certificate_some?: MetadataCertificateWhereInput | null;
  Metadata_Marked_some?: MetadataMarkedWhereInput | null;
  Metadata_Owner_some?: MetadataOwnerWhereInput | null;
  Metadata_UsageTerms_some?: MetadataUsageTermsWhereInput | null;
  Metadata_WebStatement_some?: MetadataWebStatementWhereInput | null;
  AND?: PhotoWhereInput[] | null;
  OR?: PhotoWhereInput[] | null;
  NOT?: PhotoWhereInput | null;
}

/**
 * Identifies exactly one "Photo" node with one of these unique values:
 * - _id
 * - id
 */
export interface PhotoWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
}

export interface PhotoWithForcedAgencyCreateInput {
  id?: string | null;
  originalName?: string | null;
  filesize?: number | null;
  height?: number | null;
  width?: number | null;
  contentType?: string | null;
  isUploaded?: boolean | null;
  isLegacy?: boolean | null;
  archive: PhotoNestedArchiveCreateInput;
  category?: PhotoNestedCategoryCreateInput | null;
  deliverySubject?: PhotoNestedDeliverySubjectCreateInput | null;
  importQueue?: PhotoNestedImportQueueCreateInput | null;
  photoMetadatas?: PhotoNestedPhotoMetadatasCreateInput | null;
  Metadata_SerialNumber?: PhotoNestedMetadataSerialnumberCreateInput | null;
  Metadata_attributionName?: PhotoNestedMetadataAttributionnameCreateInput | null;
  Metadata_attributionURL?: PhotoNestedMetadataAttributionurlCreateInput | null;
  Metadata_license?: PhotoNestedMetadataLicenseCreateInput | null;
  Metadata_morePermissions?: PhotoNestedMetadataMorepermissionsCreateInput | null;
  Metadata_PaintBasedCorrections?: PhotoNestedMetadataPaintbasedcorrectionsCreateInput | null;
  Metadata_ToneCurve?: PhotoNestedMetadataTonecurveCreateInput | null;
  Metadata_creator?: PhotoNestedMetadataCreatorCreateInput | null;
  Metadata_description?: PhotoNestedMetadataDescriptionCreateInput | null;
  Metadata_Format?: PhotoNestedMetadataFormatCreateInput | null;
  Metadata_Page?: PhotoNestedMetadataPageCreateInput | null;
  Metadata_Publisher?: PhotoNestedMetadataPublisherCreateInput | null;
  Metadata_rights?: PhotoNestedMetadataRightsCreateInput | null;
  Metadata_subject?: PhotoNestedMetadataSubjectCreateInput | null;
  Metadata_title?: PhotoNestedMetadataTitleCreateInput | null;
  Metadata_Type?: PhotoNestedMetadataTypeCreateInput | null;
  Metadata_AccessRights?: PhotoNestedMetadataAccessrightsCreateInput | null;
  Metadata_Audience?: PhotoNestedMetadataAudienceCreateInput | null;
  Metadata_Valid?: PhotoNestedMetadataValidCreateInput | null;
  Metadata_EquipmentInstitution?: PhotoNestedMetadataEquipmentinstitutionCreateInput | null;
  Metadata_EquipmentManufacturer?: PhotoNestedMetadataEquipmentmanufacturerCreateInput | null;
  Metadata_FileInfoBitsPerPixel?: PhotoNestedMetadataFileinfobitsperpixelCreateInput | null;
  Metadata_FileInfoFrames?: PhotoNestedMetadataFileinfoframesCreateInput | null;
  Metadata_FileInfoPhotometricInterpretation?: PhotoNestedMetadataFileinfophotometricinterpretationCreateInput | null;
  Metadata_FileInfoTransferSyntax?: PhotoNestedMetadataFileinfotransfersyntaxCreateInput | null;
  Metadata_PatientDOB?: PhotoNestedMetadataPatientdobCreateInput | null;
  Metadata_PatientID?: PhotoNestedMetadataPatientidCreateInput | null;
  Metadata_PatientName?: PhotoNestedMetadataPatientnameCreateInput | null;
  Metadata_PatientSex?: PhotoNestedMetadataPatientsexCreateInput | null;
  Metadata_SeriesDateTime?: PhotoNestedMetadataSeriesdatetimeCreateInput | null;
  Metadata_SeriesDescription?: PhotoNestedMetadataSeriesdescriptionCreateInput | null;
  Metadata_SeriesModality?: PhotoNestedMetadataSeriesmodalityCreateInput | null;
  Metadata_SeriesNumber?: PhotoNestedMetadataSeriesnumberCreateInput | null;
  Metadata_StudyDateTime?: PhotoNestedMetadataStudydatetimeCreateInput | null;
  Metadata_StudyDescription?: PhotoNestedMetadataStudydescriptionCreateInput | null;
  Metadata_StudyID?: PhotoNestedMetadataStudyidCreateInput | null;
  Metadata_StudyPhysician?: PhotoNestedMetadataStudyphysicianCreateInput | null;
  Metadata_DateTimeDigitized?: PhotoNestedMetadataDatetimedigitizedCreateInput | null;
  Metadata_DateTimeOriginal?: PhotoNestedMetadataDatetimeoriginalCreateInput | null;
  Metadata_GPSLatitude?: PhotoNestedMetadataGpslatitudeCreateInput | null;
  Metadata_GPSLongitude?: PhotoNestedMetadataGpslongitudeCreateInput | null;
  Metadata_GPSProcessingMethod?: PhotoNestedMetadataGpsprocessingmethodCreateInput | null;
  Metadata_ImageUniqueID?: PhotoNestedMetadataImageuniqueidCreateInput | null;
  Metadata_ISOSpeedRatings?: PhotoNestedMetadataIsospeedratingsCreateInput | null;
  Metadata_UserComment?: PhotoNestedMetadataUsercommentCreateInput | null;
  Metadata_CustomField14?: PhotoNestedMetadataCustomfield14CreateInput | null;
  Metadata_CustomField15?: PhotoNestedMetadataCustomfield15CreateInput | null;
  Metadata_CustomField16?: PhotoNestedMetadataCustomfield16CreateInput | null;
  Metadata_CustomField17?: PhotoNestedMetadataCustomfield17CreateInput | null;
  Metadata_CustomField20?: PhotoNestedMetadataCustomfield20CreateInput | null;
  Metadata_CustomField5?: PhotoNestedMetadataCustomfield5CreateInput | null;
  Metadata_CustomField6?: PhotoNestedMetadataCustomfield6CreateInput | null;
  Metadata_CustomField7?: PhotoNestedMetadataCustomfield7CreateInput | null;
  Metadata_CustomField9?: PhotoNestedMetadataCustomfield9CreateInput | null;
  Metadata_CreatedTime?: PhotoNestedMetadataCreatedtimeCreateInput | null;
  Metadata_EditStatus?: PhotoNestedMetadataEditstatusCreateInput | null;
  Metadata_FixtureIdentifier?: PhotoNestedMetadataFixtureidentifierCreateInput | null;
  Metadata_LocalCaption?: PhotoNestedMetadataLocalcaptionCreateInput | null;
  Metadata_ProgramVersion?: PhotoNestedMetadataProgramversionCreateInput | null;
  Metadata_ReferenceDate?: PhotoNestedMetadataReferencedateCreateInput | null;
  Metadata_ReferenceNumber?: PhotoNestedMetadataReferencenumberCreateInput | null;
  Metadata_ReferenceService?: PhotoNestedMetadataReferenceserviceCreateInput | null;
  Metadata_ReleaseDate?: PhotoNestedMetadataReleasedateCreateInput | null;
  Metadata_ReleaseTime?: PhotoNestedMetadataReleasetimeCreateInput | null;
  Metadata_AssetState?: PhotoNestedMetadataAssetstateCreateInput | null;
  Metadata_Classify?: PhotoNestedMetadataClassifyCreateInput | null;
  Metadata_ContainedIn?: PhotoNestedMetadataContainedinCreateInput | null;
  Metadata_ContentValue?: PhotoNestedMetadataContentvalueCreateInput | null;
  Metadata_DocumentText?: PhotoNestedMetadataDocumenttextCreateInput | null;
  Metadata_Exif?: PhotoNestedMetadataExifCreateInput | null;
  Metadata_History?: PhotoNestedMetadataHistoryCreateInput | null;
  Metadata_ImageNotes?: PhotoNestedMetadataImagenotesCreateInput | null;
  Metadata_JobId?: PhotoNestedMetadataJobidCreateInput | null;
  Metadata_Links?: PhotoNestedMetadataLinksCreateInput | null;
  Metadata_MasterDocumentId?: PhotoNestedMetadataMasterdocumentidCreateInput | null;
  Metadata_ObjectCycle?: PhotoNestedMetadataObjectcycleCreateInput | null;
  Metadata_OwnerId?: PhotoNestedMetadataOwneridCreateInput | null;
  Metadata_ShortUniqueId?: PhotoNestedMetadataShortuniqueidCreateInput | null;
  Metadata_TransferredBy?: PhotoNestedMetadataTransferredbyCreateInput | null;
  Metadata_TransferredByEmail?: PhotoNestedMetadataTransferredbyemailCreateInput | null;
  Metadata_TransferredByFullName?: PhotoNestedMetadataTransferredbyfullnameCreateInput | null;
  Metadata_UniqueId?: PhotoNestedMetadataUniqueidCreateInput | null;
  Metadata_UploadedBy?: PhotoNestedMetadataUploadedbyCreateInput | null;
  Metadata_UploadedByFullName?: PhotoNestedMetadataUploadedbyfullnameCreateInput | null;
  Metadata_UploadTime?: PhotoNestedMetadataUploadtimeCreateInput | null;
  Metadata_UserDefined195?: PhotoNestedMetadataUserdefined195CreateInput | null;
  Metadata_UserDefined237?: PhotoNestedMetadataUserdefined237CreateInput | null;
  Metadata_UserDefined238?: PhotoNestedMetadataUserdefined238CreateInput | null;
  Metadata_UserDefined239?: PhotoNestedMetadataUserdefined239CreateInput | null;
  Metadata_UserDefined242?: PhotoNestedMetadataUserdefined242CreateInput | null;
  Metadata_UserDefined62?: PhotoNestedMetadataUserdefined62CreateInput | null;
  Metadata_CiAdrCity?: PhotoNestedMetadataCiadrcityCreateInput | null;
  Metadata_CiAdrCtry?: PhotoNestedMetadataCiadrctryCreateInput | null;
  Metadata_CiAdrExtadr?: PhotoNestedMetadataCiadrextadrCreateInput | null;
  Metadata_CiAdrPcode?: PhotoNestedMetadataCiadrpcodeCreateInput | null;
  Metadata_CiAdrRegion?: PhotoNestedMetadataCiadrregionCreateInput | null;
  Metadata_CiEmailWork?: PhotoNestedMetadataCiemailworkCreateInput | null;
  Metadata_CiTelWork?: PhotoNestedMetadataCitelworkCreateInput | null;
  Metadata_CiUrlWork?: PhotoNestedMetadataCiurlworkCreateInput | null;
  Metadata_CountryCode?: PhotoNestedMetadataCountrycodeCreateInput | null;
  Metadata_IntellectualGenre?: PhotoNestedMetadataIntellectualgenreCreateInput | null;
  Metadata_Location?: PhotoNestedMetadataLocationCreateInput | null;
  Metadata_Scene?: PhotoNestedMetadataSceneCreateInput | null;
  Metadata_SubjectCode?: PhotoNestedMetadataSubjectcodeCreateInput | null;
  Metadata_AddlModelInfo?: PhotoNestedMetadataAddlmodelinfoCreateInput | null;
  Metadata_Event?: PhotoNestedMetadataEventCreateInput | null;
  Metadata_MaxAvailHeight?: PhotoNestedMetadataMaxavailheightCreateInput | null;
  Metadata_MaxAvailWidth?: PhotoNestedMetadataMaxavailwidthCreateInput | null;
  Metadata_ModelAge?: PhotoNestedMetadataModelageCreateInput | null;
  Metadata_OrganisationInImageName?: PhotoNestedMetadataOrganisationinimagenameCreateInput | null;
  Metadata_PersonInImage?: PhotoNestedMetadataPersoninimageCreateInput | null;
  Metadata_AuthorsPosition?: PhotoNestedMetadataAuthorspositionCreateInput | null;
  Metadata_CaptionWriter?: PhotoNestedMetadataCaptionwriterCreateInput | null;
  Metadata_Category?: PhotoNestedMetadataCategoryCreateInput | null;
  Metadata_City?: PhotoNestedMetadataCityCreateInput | null;
  Metadata_Country?: PhotoNestedMetadataCountryCreateInput | null;
  Metadata_Credit?: PhotoNestedMetadataCreditCreateInput | null;
  Metadata_DateCreated?: PhotoNestedMetadataDatecreatedCreateInput | null;
  Metadata_Headline?: PhotoNestedMetadataHeadlineCreateInput | null;
  Metadata_ICCProfile?: PhotoNestedMetadataIccprofileCreateInput | null;
  Metadata_Instructions?: PhotoNestedMetadataInstructionsCreateInput | null;
  Metadata_Source?: PhotoNestedMetadataSourceCreateInput | null;
  Metadata_State?: PhotoNestedMetadataStateCreateInput | null;
  Metadata_SupplementalCategories?: PhotoNestedMetadataSupplementalcategoriesCreateInput | null;
  Metadata_TransmissionReference?: PhotoNestedMetadataTransmissionreferenceCreateInput | null;
  Metadata_Urgency?: PhotoNestedMetadataUrgencyCreateInput | null;
  Metadata_MinorModelAgeDisclosure?: PhotoNestedMetadataMinormodelagedisclosureCreateInput | null;
  Metadata_ModelReleaseID?: PhotoNestedMetadataModelreleaseidCreateInput | null;
  Metadata_ModelReleaseStatus?: PhotoNestedMetadataModelreleasestatusCreateInput | null;
  Metadata_PropertyReleaseID?: PhotoNestedMetadataPropertyreleaseidCreateInput | null;
  Metadata_PropertyReleaseStatus?: PhotoNestedMetadataPropertyreleasestatusCreateInput | null;
  Metadata_Version?: PhotoNestedMetadataVersionCreateInput | null;
  Metadata_Brand?: PhotoNestedMetadataBrandCreateInput | null;
  Metadata_Division?: PhotoNestedMetadataDivisionCreateInput | null;
  Metadata_Header?: PhotoNestedMetadataHeaderCreateInput | null;
  Metadata_Products?: PhotoNestedMetadataProductsCreateInput | null;
  Metadata_Produits?: PhotoNestedMetadataProduitsCreateInput | null;
  Metadata_publishingIssue?: PhotoNestedMetadataPublishingissueCreateInput | null;
  Metadata_publishingSubject?: PhotoNestedMetadataPublishingsubjectCreateInput | null;
  Metadata_Company?: PhotoNestedMetadataCompanyCreateInput | null;
  Metadata_CompanyShortName?: PhotoNestedMetadataCompanyshortnameCreateInput | null;
  Metadata_Confidentiality?: PhotoNestedMetadataConfidentialityCreateInput | null;
  Metadata_EditorialVersion?: PhotoNestedMetadataEditorialversionCreateInput | null;
  Metadata_FileCheckFor?: PhotoNestedMetadataFilecheckforCreateInput | null;
  Metadata_FileStage?: PhotoNestedMetadataFilestageCreateInput | null;
  Metadata_FileStatus?: PhotoNestedMetadataFilestatusCreateInput | null;
  Metadata_SentTo?: PhotoNestedMetadataSenttoCreateInput | null;
  Metadata_TargetVersion?: PhotoNestedMetadataTargetversionCreateInput | null;
  Metadata_Unit?: PhotoNestedMetadataUnitCreateInput | null;
  Metadata_Archive_child?: PhotoNestedMetadataArchiveChildCreateInput | null;
  Metadata_Archive_parent?: PhotoNestedMetadataArchiveParentCreateInput | null;
  Metadata_BackupName?: PhotoNestedMetadataBackupnameCreateInput | null;
  Metadata_BitmapGrayscalePicture?: PhotoNestedMetadataBitmapgrayscalepictureCreateInput | null;
  Metadata_BrandPrisma?: PhotoNestedMetadataBrandprismaCreateInput | null;
  Metadata_BrandShortName?: PhotoNestedMetadataBrandshortnameCreateInput | null;
  Metadata_CategoryPrisma?: PhotoNestedMetadataCategoryprismaCreateInput | null;
  Metadata_ContentMediaKind?: PhotoNestedMetadataContentmediakindCreateInput | null;
  Metadata_CopyrightLayer?: PhotoNestedMetadataCopyrightlayerCreateInput | null;
  Metadata_DeliveryAccount?: PhotoNestedMetadataDeliveryaccountCreateInput | null;
  Metadata_DeliveryCompany?: PhotoNestedMetadataDeliverycompanyCreateInput | null;
  Metadata_DeliveryCopyright?: PhotoNestedMetadataDeliverycopyrightCreateInput | null;
  Metadata_DeliveryDateFolder?: PhotoNestedMetadataDeliverydatefolderCreateInput | null;
  Metadata_DeliveryDateTime?: PhotoNestedMetadataDeliverydatetimeCreateInput | null;
  Metadata_DeliveryDcCreator?: PhotoNestedMetadataDeliverydccreatorCreateInput | null;
  Metadata_DeliveryDcRights?: PhotoNestedMetadataDeliverydcrightsCreateInput | null;
  Metadata_DeliveryFileType?: PhotoNestedMetadataDeliveryfiletypeCreateInput | null;
  Metadata_DeliveryFolder?: PhotoNestedMetadataDeliveryfolderCreateInput | null;
  Metadata_DeliveryKind?: PhotoNestedMetadataDeliverykindCreateInput | null;
  Metadata_DeliveryPath?: PhotoNestedMetadataDeliverypathCreateInput | null;
  Metadata_DeliveryPersonShownintheImage?: PhotoNestedMetadataDeliverypersonshownintheimageCreateInput | null;
  Metadata_DeliveryPhotoshopCredit?: PhotoNestedMetadataDeliveryphotoshopcreditCreateInput | null;
  Metadata_DeliveryPhotoshopSource?: PhotoNestedMetadataDeliveryphotoshopsourceCreateInput | null;
  Metadata_DeliveryService?: PhotoNestedMetadataDeliveryserviceCreateInput | null;
  Metadata_DeliverySubject?: PhotoNestedMetadataDeliverysubjectCreateInput | null;
  Metadata_DeliveryUnderSubject?: PhotoNestedMetadataDeliveryundersubjectCreateInput | null;
  Metadata_Department?: PhotoNestedMetadataDepartmentCreateInput | null;
  Metadata_DigitalAssetURL?: PhotoNestedMetadataDigitalasseturlCreateInput | null;
  Metadata_Edition?: PhotoNestedMetadataEditionCreateInput | null;
  Metadata_EnvironnementPhoto?: PhotoNestedMetadataEnvironnementphotoCreateInput | null;
  Metadata_FabStorage?: PhotoNestedMetadataFabstorageCreateInput | null;
  Metadata_FileType?: PhotoNestedMetadataFiletypeCreateInput | null;
  Metadata_HeaderPrisma?: PhotoNestedMetadataHeaderprismaCreateInput | null;
  Metadata_ID_subject?: PhotoNestedMetadataIdSubjectCreateInput | null;
  Metadata_IdAssignment?: PhotoNestedMetadataIdassignmentCreateInput | null;
  Metadata_IdMediaContent?: PhotoNestedMetadataIdmediacontentCreateInput | null;
  Metadata_IdPhotoPrisma?: PhotoNestedMetadataIdphotoprismaCreateInput | null;
  Metadata_Issue?: PhotoNestedMetadataIssueCreateInput | null;
  Metadata_JobProcessing?: PhotoNestedMetadataJobprocessingCreateInput | null;
  Metadata_LayoutPictureEditing?: PhotoNestedMetadataLayoutpictureeditingCreateInput | null;
  Metadata_LayoutStorage?: PhotoNestedMetadataLayoutstorageCreateInput | null;
  Metadata_OriginalColor?: PhotoNestedMetadataOriginalcolorCreateInput | null;
  Metadata_OriginalICCProfile?: PhotoNestedMetadataOriginaliccprofileCreateInput | null;
  Metadata_OriginalName?: PhotoNestedMetadataOriginalnameCreateInput | null;
  Metadata_OriginalWeigth?: PhotoNestedMetadataOriginalweigthCreateInput | null;
  Metadata_PagePrisma?: PhotoNestedMetadataPageprismaCreateInput | null;
  Metadata_PhotoStorage?: PhotoNestedMetadataPhotostorageCreateInput | null;
  Metadata_PrepressPictureEditing?: PhotoNestedMetadataPrepresspictureeditingCreateInput | null;
  Metadata_PriceLevel?: PhotoNestedMetadataPricelevelCreateInput | null;
  Metadata_PrintingProfile?: PhotoNestedMetadataPrintingprofileCreateInput | null;
  Metadata_PrismaProduction?: PhotoNestedMetadataPrismaproductionCreateInput | null;
  Metadata_ProcessHistory?: PhotoNestedMetadataProcesshistoryCreateInput | null;
  Metadata_ProcessParameter?: PhotoNestedMetadataProcessparameterCreateInput | null;
  Metadata_ProcessStatus?: PhotoNestedMetadataProcessstatusCreateInput | null;
  Metadata_Product?: PhotoNestedMetadataProductCreateInput | null;
  Metadata_ProductShortName?: PhotoNestedMetadataProductshortnameCreateInput | null;
  Metadata_QualifiedUsePrismaBy?: PhotoNestedMetadataQualifieduseprismabyCreateInput | null;
  Metadata_QualifiedUsePrismaDate?: PhotoNestedMetadataQualifieduseprismadateCreateInput | null;
  Metadata_QualifiedUsePrismaDuration?: PhotoNestedMetadataQualifieduseprismadurationCreateInput | null;
  Metadata_QualifiedUsePrismaSupport?: PhotoNestedMetadataQualifieduseprismasupportCreateInput | null;
  Metadata_RequiredPermission?: PhotoNestedMetadataRequiredpermissionCreateInput | null;
  Metadata_ResolutionKind?: PhotoNestedMetadataResolutionkindCreateInput | null;
  Metadata_Royaltyfree?: PhotoNestedMetadataRoyaltyfreeCreateInput | null;
  Metadata_Section?: PhotoNestedMetadataSectionCreateInput | null;
  Metadata_SubjectPrisma?: PhotoNestedMetadataSubjectprismaCreateInput | null;
  Metadata_Undersubject?: PhotoNestedMetadataUndersubjectCreateInput | null;
  Metadata_UnderUnderSubject?: PhotoNestedMetadataUnderundersubjectCreateInput | null;
  Metadata_UnitShortName?: PhotoNestedMetadataUnitshortnameCreateInput | null;
  Metadata_WorkflowKind?: PhotoNestedMetadataWorkflowkindCreateInput | null;
  Metadata_Make?: PhotoNestedMetadataMakeCreateInput | null;
  Metadata_Model?: PhotoNestedMetadataModelCreateInput | null;
  Metadata_CreatorTool?: PhotoNestedMetadataCreatortoolCreateInput | null;
  Metadata_Rating?: PhotoNestedMetadataRatingCreateInput | null;
  Metadata_XMPFileStamps?: PhotoNestedMetadataXmpfilestampsCreateInput | null;
  Metadata_Manifest?: PhotoNestedMetadataManifestCreateInput | null;
  Metadata_XMPHistory?: PhotoNestedMetadataXmphistoryCreateInput | null;
  Metadata_Certificate?: PhotoNestedMetadataCertificateCreateInput | null;
  Metadata_Marked?: PhotoNestedMetadataMarkedCreateInput | null;
  Metadata_Owner?: PhotoNestedMetadataOwnerCreateInput | null;
  Metadata_UsageTerms?: PhotoNestedMetadataUsagetermsCreateInput | null;
  Metadata_WebStatement?: PhotoNestedMetadataWebstatementCreateInput | null;
}

export interface PhotoWithForcedAgencyUpdateInput {
  originalName?: string | null;
  filesize?: number | null;
  height?: number | null;
  width?: number | null;
  contentType?: string | null;
  isUploaded?: boolean | null;
  isLegacy?: boolean | null;
  archive?: PhotoNestedArchiveUpdateInput | null;
  category?: PhotoNestedCategoryUpdateInput | null;
  deliverySubject?: PhotoNestedDeliverySubjectUpdateInput | null;
  importQueue?: PhotoNestedImportQueueUpdateInput | null;
  photoMetadatas?: PhotoNestedPhotoMetadatasUpdateInput | null;
  Metadata_SerialNumber?: PhotoNestedMetadataSerialnumberUpdateInput | null;
  Metadata_attributionName?: PhotoNestedMetadataAttributionnameUpdateInput | null;
  Metadata_attributionURL?: PhotoNestedMetadataAttributionurlUpdateInput | null;
  Metadata_license?: PhotoNestedMetadataLicenseUpdateInput | null;
  Metadata_morePermissions?: PhotoNestedMetadataMorepermissionsUpdateInput | null;
  Metadata_PaintBasedCorrections?: PhotoNestedMetadataPaintbasedcorrectionsUpdateInput | null;
  Metadata_ToneCurve?: PhotoNestedMetadataTonecurveUpdateInput | null;
  Metadata_creator?: PhotoNestedMetadataCreatorUpdateInput | null;
  Metadata_description?: PhotoNestedMetadataDescriptionUpdateInput | null;
  Metadata_Format?: PhotoNestedMetadataFormatUpdateInput | null;
  Metadata_Page?: PhotoNestedMetadataPageUpdateInput | null;
  Metadata_Publisher?: PhotoNestedMetadataPublisherUpdateInput | null;
  Metadata_rights?: PhotoNestedMetadataRightsUpdateInput | null;
  Metadata_subject?: PhotoNestedMetadataSubjectUpdateInput | null;
  Metadata_title?: PhotoNestedMetadataTitleUpdateInput | null;
  Metadata_Type?: PhotoNestedMetadataTypeUpdateInput | null;
  Metadata_AccessRights?: PhotoNestedMetadataAccessrightsUpdateInput | null;
  Metadata_Audience?: PhotoNestedMetadataAudienceUpdateInput | null;
  Metadata_Valid?: PhotoNestedMetadataValidUpdateInput | null;
  Metadata_EquipmentInstitution?: PhotoNestedMetadataEquipmentinstitutionUpdateInput | null;
  Metadata_EquipmentManufacturer?: PhotoNestedMetadataEquipmentmanufacturerUpdateInput | null;
  Metadata_FileInfoBitsPerPixel?: PhotoNestedMetadataFileinfobitsperpixelUpdateInput | null;
  Metadata_FileInfoFrames?: PhotoNestedMetadataFileinfoframesUpdateInput | null;
  Metadata_FileInfoPhotometricInterpretation?: PhotoNestedMetadataFileinfophotometricinterpretationUpdateInput | null;
  Metadata_FileInfoTransferSyntax?: PhotoNestedMetadataFileinfotransfersyntaxUpdateInput | null;
  Metadata_PatientDOB?: PhotoNestedMetadataPatientdobUpdateInput | null;
  Metadata_PatientID?: PhotoNestedMetadataPatientidUpdateInput | null;
  Metadata_PatientName?: PhotoNestedMetadataPatientnameUpdateInput | null;
  Metadata_PatientSex?: PhotoNestedMetadataPatientsexUpdateInput | null;
  Metadata_SeriesDateTime?: PhotoNestedMetadataSeriesdatetimeUpdateInput | null;
  Metadata_SeriesDescription?: PhotoNestedMetadataSeriesdescriptionUpdateInput | null;
  Metadata_SeriesModality?: PhotoNestedMetadataSeriesmodalityUpdateInput | null;
  Metadata_SeriesNumber?: PhotoNestedMetadataSeriesnumberUpdateInput | null;
  Metadata_StudyDateTime?: PhotoNestedMetadataStudydatetimeUpdateInput | null;
  Metadata_StudyDescription?: PhotoNestedMetadataStudydescriptionUpdateInput | null;
  Metadata_StudyID?: PhotoNestedMetadataStudyidUpdateInput | null;
  Metadata_StudyPhysician?: PhotoNestedMetadataStudyphysicianUpdateInput | null;
  Metadata_DateTimeDigitized?: PhotoNestedMetadataDatetimedigitizedUpdateInput | null;
  Metadata_DateTimeOriginal?: PhotoNestedMetadataDatetimeoriginalUpdateInput | null;
  Metadata_GPSLatitude?: PhotoNestedMetadataGpslatitudeUpdateInput | null;
  Metadata_GPSLongitude?: PhotoNestedMetadataGpslongitudeUpdateInput | null;
  Metadata_GPSProcessingMethod?: PhotoNestedMetadataGpsprocessingmethodUpdateInput | null;
  Metadata_ImageUniqueID?: PhotoNestedMetadataImageuniqueidUpdateInput | null;
  Metadata_ISOSpeedRatings?: PhotoNestedMetadataIsospeedratingsUpdateInput | null;
  Metadata_UserComment?: PhotoNestedMetadataUsercommentUpdateInput | null;
  Metadata_CustomField14?: PhotoNestedMetadataCustomfield14UpdateInput | null;
  Metadata_CustomField15?: PhotoNestedMetadataCustomfield15UpdateInput | null;
  Metadata_CustomField16?: PhotoNestedMetadataCustomfield16UpdateInput | null;
  Metadata_CustomField17?: PhotoNestedMetadataCustomfield17UpdateInput | null;
  Metadata_CustomField20?: PhotoNestedMetadataCustomfield20UpdateInput | null;
  Metadata_CustomField5?: PhotoNestedMetadataCustomfield5UpdateInput | null;
  Metadata_CustomField6?: PhotoNestedMetadataCustomfield6UpdateInput | null;
  Metadata_CustomField7?: PhotoNestedMetadataCustomfield7UpdateInput | null;
  Metadata_CustomField9?: PhotoNestedMetadataCustomfield9UpdateInput | null;
  Metadata_CreatedTime?: PhotoNestedMetadataCreatedtimeUpdateInput | null;
  Metadata_EditStatus?: PhotoNestedMetadataEditstatusUpdateInput | null;
  Metadata_FixtureIdentifier?: PhotoNestedMetadataFixtureidentifierUpdateInput | null;
  Metadata_LocalCaption?: PhotoNestedMetadataLocalcaptionUpdateInput | null;
  Metadata_ProgramVersion?: PhotoNestedMetadataProgramversionUpdateInput | null;
  Metadata_ReferenceDate?: PhotoNestedMetadataReferencedateUpdateInput | null;
  Metadata_ReferenceNumber?: PhotoNestedMetadataReferencenumberUpdateInput | null;
  Metadata_ReferenceService?: PhotoNestedMetadataReferenceserviceUpdateInput | null;
  Metadata_ReleaseDate?: PhotoNestedMetadataReleasedateUpdateInput | null;
  Metadata_ReleaseTime?: PhotoNestedMetadataReleasetimeUpdateInput | null;
  Metadata_AssetState?: PhotoNestedMetadataAssetstateUpdateInput | null;
  Metadata_Classify?: PhotoNestedMetadataClassifyUpdateInput | null;
  Metadata_ContainedIn?: PhotoNestedMetadataContainedinUpdateInput | null;
  Metadata_ContentValue?: PhotoNestedMetadataContentvalueUpdateInput | null;
  Metadata_DocumentText?: PhotoNestedMetadataDocumenttextUpdateInput | null;
  Metadata_Exif?: PhotoNestedMetadataExifUpdateInput | null;
  Metadata_History?: PhotoNestedMetadataHistoryUpdateInput | null;
  Metadata_ImageNotes?: PhotoNestedMetadataImagenotesUpdateInput | null;
  Metadata_JobId?: PhotoNestedMetadataJobidUpdateInput | null;
  Metadata_Links?: PhotoNestedMetadataLinksUpdateInput | null;
  Metadata_MasterDocumentId?: PhotoNestedMetadataMasterdocumentidUpdateInput | null;
  Metadata_ObjectCycle?: PhotoNestedMetadataObjectcycleUpdateInput | null;
  Metadata_OwnerId?: PhotoNestedMetadataOwneridUpdateInput | null;
  Metadata_ShortUniqueId?: PhotoNestedMetadataShortuniqueidUpdateInput | null;
  Metadata_TransferredBy?: PhotoNestedMetadataTransferredbyUpdateInput | null;
  Metadata_TransferredByEmail?: PhotoNestedMetadataTransferredbyemailUpdateInput | null;
  Metadata_TransferredByFullName?: PhotoNestedMetadataTransferredbyfullnameUpdateInput | null;
  Metadata_UniqueId?: PhotoNestedMetadataUniqueidUpdateInput | null;
  Metadata_UploadedBy?: PhotoNestedMetadataUploadedbyUpdateInput | null;
  Metadata_UploadedByFullName?: PhotoNestedMetadataUploadedbyfullnameUpdateInput | null;
  Metadata_UploadTime?: PhotoNestedMetadataUploadtimeUpdateInput | null;
  Metadata_UserDefined195?: PhotoNestedMetadataUserdefined195UpdateInput | null;
  Metadata_UserDefined237?: PhotoNestedMetadataUserdefined237UpdateInput | null;
  Metadata_UserDefined238?: PhotoNestedMetadataUserdefined238UpdateInput | null;
  Metadata_UserDefined239?: PhotoNestedMetadataUserdefined239UpdateInput | null;
  Metadata_UserDefined242?: PhotoNestedMetadataUserdefined242UpdateInput | null;
  Metadata_UserDefined62?: PhotoNestedMetadataUserdefined62UpdateInput | null;
  Metadata_CiAdrCity?: PhotoNestedMetadataCiadrcityUpdateInput | null;
  Metadata_CiAdrCtry?: PhotoNestedMetadataCiadrctryUpdateInput | null;
  Metadata_CiAdrExtadr?: PhotoNestedMetadataCiadrextadrUpdateInput | null;
  Metadata_CiAdrPcode?: PhotoNestedMetadataCiadrpcodeUpdateInput | null;
  Metadata_CiAdrRegion?: PhotoNestedMetadataCiadrregionUpdateInput | null;
  Metadata_CiEmailWork?: PhotoNestedMetadataCiemailworkUpdateInput | null;
  Metadata_CiTelWork?: PhotoNestedMetadataCitelworkUpdateInput | null;
  Metadata_CiUrlWork?: PhotoNestedMetadataCiurlworkUpdateInput | null;
  Metadata_CountryCode?: PhotoNestedMetadataCountrycodeUpdateInput | null;
  Metadata_IntellectualGenre?: PhotoNestedMetadataIntellectualgenreUpdateInput | null;
  Metadata_Location?: PhotoNestedMetadataLocationUpdateInput | null;
  Metadata_Scene?: PhotoNestedMetadataSceneUpdateInput | null;
  Metadata_SubjectCode?: PhotoNestedMetadataSubjectcodeUpdateInput | null;
  Metadata_AddlModelInfo?: PhotoNestedMetadataAddlmodelinfoUpdateInput | null;
  Metadata_Event?: PhotoNestedMetadataEventUpdateInput | null;
  Metadata_MaxAvailHeight?: PhotoNestedMetadataMaxavailheightUpdateInput | null;
  Metadata_MaxAvailWidth?: PhotoNestedMetadataMaxavailwidthUpdateInput | null;
  Metadata_ModelAge?: PhotoNestedMetadataModelageUpdateInput | null;
  Metadata_OrganisationInImageName?: PhotoNestedMetadataOrganisationinimagenameUpdateInput | null;
  Metadata_PersonInImage?: PhotoNestedMetadataPersoninimageUpdateInput | null;
  Metadata_AuthorsPosition?: PhotoNestedMetadataAuthorspositionUpdateInput | null;
  Metadata_CaptionWriter?: PhotoNestedMetadataCaptionwriterUpdateInput | null;
  Metadata_Category?: PhotoNestedMetadataCategoryUpdateInput | null;
  Metadata_City?: PhotoNestedMetadataCityUpdateInput | null;
  Metadata_Country?: PhotoNestedMetadataCountryUpdateInput | null;
  Metadata_Credit?: PhotoNestedMetadataCreditUpdateInput | null;
  Metadata_DateCreated?: PhotoNestedMetadataDatecreatedUpdateInput | null;
  Metadata_Headline?: PhotoNestedMetadataHeadlineUpdateInput | null;
  Metadata_ICCProfile?: PhotoNestedMetadataIccprofileUpdateInput | null;
  Metadata_Instructions?: PhotoNestedMetadataInstructionsUpdateInput | null;
  Metadata_Source?: PhotoNestedMetadataSourceUpdateInput | null;
  Metadata_State?: PhotoNestedMetadataStateUpdateInput | null;
  Metadata_SupplementalCategories?: PhotoNestedMetadataSupplementalcategoriesUpdateInput | null;
  Metadata_TransmissionReference?: PhotoNestedMetadataTransmissionreferenceUpdateInput | null;
  Metadata_Urgency?: PhotoNestedMetadataUrgencyUpdateInput | null;
  Metadata_MinorModelAgeDisclosure?: PhotoNestedMetadataMinormodelagedisclosureUpdateInput | null;
  Metadata_ModelReleaseID?: PhotoNestedMetadataModelreleaseidUpdateInput | null;
  Metadata_ModelReleaseStatus?: PhotoNestedMetadataModelreleasestatusUpdateInput | null;
  Metadata_PropertyReleaseID?: PhotoNestedMetadataPropertyreleaseidUpdateInput | null;
  Metadata_PropertyReleaseStatus?: PhotoNestedMetadataPropertyreleasestatusUpdateInput | null;
  Metadata_Version?: PhotoNestedMetadataVersionUpdateInput | null;
  Metadata_Brand?: PhotoNestedMetadataBrandUpdateInput | null;
  Metadata_Division?: PhotoNestedMetadataDivisionUpdateInput | null;
  Metadata_Header?: PhotoNestedMetadataHeaderUpdateInput | null;
  Metadata_Products?: PhotoNestedMetadataProductsUpdateInput | null;
  Metadata_Produits?: PhotoNestedMetadataProduitsUpdateInput | null;
  Metadata_publishingIssue?: PhotoNestedMetadataPublishingissueUpdateInput | null;
  Metadata_publishingSubject?: PhotoNestedMetadataPublishingsubjectUpdateInput | null;
  Metadata_Company?: PhotoNestedMetadataCompanyUpdateInput | null;
  Metadata_CompanyShortName?: PhotoNestedMetadataCompanyshortnameUpdateInput | null;
  Metadata_Confidentiality?: PhotoNestedMetadataConfidentialityUpdateInput | null;
  Metadata_EditorialVersion?: PhotoNestedMetadataEditorialversionUpdateInput | null;
  Metadata_FileCheckFor?: PhotoNestedMetadataFilecheckforUpdateInput | null;
  Metadata_FileStage?: PhotoNestedMetadataFilestageUpdateInput | null;
  Metadata_FileStatus?: PhotoNestedMetadataFilestatusUpdateInput | null;
  Metadata_SentTo?: PhotoNestedMetadataSenttoUpdateInput | null;
  Metadata_TargetVersion?: PhotoNestedMetadataTargetversionUpdateInput | null;
  Metadata_Unit?: PhotoNestedMetadataUnitUpdateInput | null;
  Metadata_Archive_child?: PhotoNestedMetadataArchiveChildUpdateInput | null;
  Metadata_Archive_parent?: PhotoNestedMetadataArchiveParentUpdateInput | null;
  Metadata_BackupName?: PhotoNestedMetadataBackupnameUpdateInput | null;
  Metadata_BitmapGrayscalePicture?: PhotoNestedMetadataBitmapgrayscalepictureUpdateInput | null;
  Metadata_BrandPrisma?: PhotoNestedMetadataBrandprismaUpdateInput | null;
  Metadata_BrandShortName?: PhotoNestedMetadataBrandshortnameUpdateInput | null;
  Metadata_CategoryPrisma?: PhotoNestedMetadataCategoryprismaUpdateInput | null;
  Metadata_ContentMediaKind?: PhotoNestedMetadataContentmediakindUpdateInput | null;
  Metadata_CopyrightLayer?: PhotoNestedMetadataCopyrightlayerUpdateInput | null;
  Metadata_DeliveryAccount?: PhotoNestedMetadataDeliveryaccountUpdateInput | null;
  Metadata_DeliveryCompany?: PhotoNestedMetadataDeliverycompanyUpdateInput | null;
  Metadata_DeliveryCopyright?: PhotoNestedMetadataDeliverycopyrightUpdateInput | null;
  Metadata_DeliveryDateFolder?: PhotoNestedMetadataDeliverydatefolderUpdateInput | null;
  Metadata_DeliveryDateTime?: PhotoNestedMetadataDeliverydatetimeUpdateInput | null;
  Metadata_DeliveryDcCreator?: PhotoNestedMetadataDeliverydccreatorUpdateInput | null;
  Metadata_DeliveryDcRights?: PhotoNestedMetadataDeliverydcrightsUpdateInput | null;
  Metadata_DeliveryFileType?: PhotoNestedMetadataDeliveryfiletypeUpdateInput | null;
  Metadata_DeliveryFolder?: PhotoNestedMetadataDeliveryfolderUpdateInput | null;
  Metadata_DeliveryKind?: PhotoNestedMetadataDeliverykindUpdateInput | null;
  Metadata_DeliveryPath?: PhotoNestedMetadataDeliverypathUpdateInput | null;
  Metadata_DeliveryPersonShownintheImage?: PhotoNestedMetadataDeliverypersonshownintheimageUpdateInput | null;
  Metadata_DeliveryPhotoshopCredit?: PhotoNestedMetadataDeliveryphotoshopcreditUpdateInput | null;
  Metadata_DeliveryPhotoshopSource?: PhotoNestedMetadataDeliveryphotoshopsourceUpdateInput | null;
  Metadata_DeliveryService?: PhotoNestedMetadataDeliveryserviceUpdateInput | null;
  Metadata_DeliverySubject?: PhotoNestedMetadataDeliverysubjectUpdateInput | null;
  Metadata_DeliveryUnderSubject?: PhotoNestedMetadataDeliveryundersubjectUpdateInput | null;
  Metadata_Department?: PhotoNestedMetadataDepartmentUpdateInput | null;
  Metadata_DigitalAssetURL?: PhotoNestedMetadataDigitalasseturlUpdateInput | null;
  Metadata_Edition?: PhotoNestedMetadataEditionUpdateInput | null;
  Metadata_EnvironnementPhoto?: PhotoNestedMetadataEnvironnementphotoUpdateInput | null;
  Metadata_FabStorage?: PhotoNestedMetadataFabstorageUpdateInput | null;
  Metadata_FileType?: PhotoNestedMetadataFiletypeUpdateInput | null;
  Metadata_HeaderPrisma?: PhotoNestedMetadataHeaderprismaUpdateInput | null;
  Metadata_ID_subject?: PhotoNestedMetadataIdSubjectUpdateInput | null;
  Metadata_IdAssignment?: PhotoNestedMetadataIdassignmentUpdateInput | null;
  Metadata_IdMediaContent?: PhotoNestedMetadataIdmediacontentUpdateInput | null;
  Metadata_IdPhotoPrisma?: PhotoNestedMetadataIdphotoprismaUpdateInput | null;
  Metadata_Issue?: PhotoNestedMetadataIssueUpdateInput | null;
  Metadata_JobProcessing?: PhotoNestedMetadataJobprocessingUpdateInput | null;
  Metadata_LayoutPictureEditing?: PhotoNestedMetadataLayoutpictureeditingUpdateInput | null;
  Metadata_LayoutStorage?: PhotoNestedMetadataLayoutstorageUpdateInput | null;
  Metadata_OriginalColor?: PhotoNestedMetadataOriginalcolorUpdateInput | null;
  Metadata_OriginalICCProfile?: PhotoNestedMetadataOriginaliccprofileUpdateInput | null;
  Metadata_OriginalName?: PhotoNestedMetadataOriginalnameUpdateInput | null;
  Metadata_OriginalWeigth?: PhotoNestedMetadataOriginalweigthUpdateInput | null;
  Metadata_PagePrisma?: PhotoNestedMetadataPageprismaUpdateInput | null;
  Metadata_PhotoStorage?: PhotoNestedMetadataPhotostorageUpdateInput | null;
  Metadata_PrepressPictureEditing?: PhotoNestedMetadataPrepresspictureeditingUpdateInput | null;
  Metadata_PriceLevel?: PhotoNestedMetadataPricelevelUpdateInput | null;
  Metadata_PrintingProfile?: PhotoNestedMetadataPrintingprofileUpdateInput | null;
  Metadata_PrismaProduction?: PhotoNestedMetadataPrismaproductionUpdateInput | null;
  Metadata_ProcessHistory?: PhotoNestedMetadataProcesshistoryUpdateInput | null;
  Metadata_ProcessParameter?: PhotoNestedMetadataProcessparameterUpdateInput | null;
  Metadata_ProcessStatus?: PhotoNestedMetadataProcessstatusUpdateInput | null;
  Metadata_Product?: PhotoNestedMetadataProductUpdateInput | null;
  Metadata_ProductShortName?: PhotoNestedMetadataProductshortnameUpdateInput | null;
  Metadata_QualifiedUsePrismaBy?: PhotoNestedMetadataQualifieduseprismabyUpdateInput | null;
  Metadata_QualifiedUsePrismaDate?: PhotoNestedMetadataQualifieduseprismadateUpdateInput | null;
  Metadata_QualifiedUsePrismaDuration?: PhotoNestedMetadataQualifieduseprismadurationUpdateInput | null;
  Metadata_QualifiedUsePrismaSupport?: PhotoNestedMetadataQualifieduseprismasupportUpdateInput | null;
  Metadata_RequiredPermission?: PhotoNestedMetadataRequiredpermissionUpdateInput | null;
  Metadata_ResolutionKind?: PhotoNestedMetadataResolutionkindUpdateInput | null;
  Metadata_Royaltyfree?: PhotoNestedMetadataRoyaltyfreeUpdateInput | null;
  Metadata_Section?: PhotoNestedMetadataSectionUpdateInput | null;
  Metadata_SubjectPrisma?: PhotoNestedMetadataSubjectprismaUpdateInput | null;
  Metadata_Undersubject?: PhotoNestedMetadataUndersubjectUpdateInput | null;
  Metadata_UnderUnderSubject?: PhotoNestedMetadataUnderundersubjectUpdateInput | null;
  Metadata_UnitShortName?: PhotoNestedMetadataUnitshortnameUpdateInput | null;
  Metadata_WorkflowKind?: PhotoNestedMetadataWorkflowkindUpdateInput | null;
  Metadata_Make?: PhotoNestedMetadataMakeUpdateInput | null;
  Metadata_Model?: PhotoNestedMetadataModelUpdateInput | null;
  Metadata_CreatorTool?: PhotoNestedMetadataCreatortoolUpdateInput | null;
  Metadata_Rating?: PhotoNestedMetadataRatingUpdateInput | null;
  Metadata_XMPFileStamps?: PhotoNestedMetadataXmpfilestampsUpdateInput | null;
  Metadata_Manifest?: PhotoNestedMetadataManifestUpdateInput | null;
  Metadata_XMPHistory?: PhotoNestedMetadataXmphistoryUpdateInput | null;
  Metadata_Certificate?: PhotoNestedMetadataCertificateUpdateInput | null;
  Metadata_Marked?: PhotoNestedMetadataMarkedUpdateInput | null;
  Metadata_Owner?: PhotoNestedMetadataOwnerUpdateInput | null;
  Metadata_UsageTerms?: PhotoNestedMetadataUsagetermsUpdateInput | null;
  Metadata_WebStatement?: PhotoNestedMetadataWebstatementUpdateInput | null;
}

export interface PhotoWithForcedArchiveCreateInput {
  id?: string | null;
  originalName?: string | null;
  filesize?: number | null;
  height?: number | null;
  width?: number | null;
  contentType?: string | null;
  isUploaded?: boolean | null;
  isLegacy?: boolean | null;
  category?: PhotoNestedCategoryCreateInput | null;
  agency?: PhotoNestedAgencyCreateInput | null;
  deliverySubject?: PhotoNestedDeliverySubjectCreateInput | null;
  importQueue?: PhotoNestedImportQueueCreateInput | null;
  photoMetadatas?: PhotoNestedPhotoMetadatasCreateInput | null;
  Metadata_SerialNumber?: PhotoNestedMetadataSerialnumberCreateInput | null;
  Metadata_attributionName?: PhotoNestedMetadataAttributionnameCreateInput | null;
  Metadata_attributionURL?: PhotoNestedMetadataAttributionurlCreateInput | null;
  Metadata_license?: PhotoNestedMetadataLicenseCreateInput | null;
  Metadata_morePermissions?: PhotoNestedMetadataMorepermissionsCreateInput | null;
  Metadata_PaintBasedCorrections?: PhotoNestedMetadataPaintbasedcorrectionsCreateInput | null;
  Metadata_ToneCurve?: PhotoNestedMetadataTonecurveCreateInput | null;
  Metadata_creator?: PhotoNestedMetadataCreatorCreateInput | null;
  Metadata_description?: PhotoNestedMetadataDescriptionCreateInput | null;
  Metadata_Format?: PhotoNestedMetadataFormatCreateInput | null;
  Metadata_Page?: PhotoNestedMetadataPageCreateInput | null;
  Metadata_Publisher?: PhotoNestedMetadataPublisherCreateInput | null;
  Metadata_rights?: PhotoNestedMetadataRightsCreateInput | null;
  Metadata_subject?: PhotoNestedMetadataSubjectCreateInput | null;
  Metadata_title?: PhotoNestedMetadataTitleCreateInput | null;
  Metadata_Type?: PhotoNestedMetadataTypeCreateInput | null;
  Metadata_AccessRights?: PhotoNestedMetadataAccessrightsCreateInput | null;
  Metadata_Audience?: PhotoNestedMetadataAudienceCreateInput | null;
  Metadata_Valid?: PhotoNestedMetadataValidCreateInput | null;
  Metadata_EquipmentInstitution?: PhotoNestedMetadataEquipmentinstitutionCreateInput | null;
  Metadata_EquipmentManufacturer?: PhotoNestedMetadataEquipmentmanufacturerCreateInput | null;
  Metadata_FileInfoBitsPerPixel?: PhotoNestedMetadataFileinfobitsperpixelCreateInput | null;
  Metadata_FileInfoFrames?: PhotoNestedMetadataFileinfoframesCreateInput | null;
  Metadata_FileInfoPhotometricInterpretation?: PhotoNestedMetadataFileinfophotometricinterpretationCreateInput | null;
  Metadata_FileInfoTransferSyntax?: PhotoNestedMetadataFileinfotransfersyntaxCreateInput | null;
  Metadata_PatientDOB?: PhotoNestedMetadataPatientdobCreateInput | null;
  Metadata_PatientID?: PhotoNestedMetadataPatientidCreateInput | null;
  Metadata_PatientName?: PhotoNestedMetadataPatientnameCreateInput | null;
  Metadata_PatientSex?: PhotoNestedMetadataPatientsexCreateInput | null;
  Metadata_SeriesDateTime?: PhotoNestedMetadataSeriesdatetimeCreateInput | null;
  Metadata_SeriesDescription?: PhotoNestedMetadataSeriesdescriptionCreateInput | null;
  Metadata_SeriesModality?: PhotoNestedMetadataSeriesmodalityCreateInput | null;
  Metadata_SeriesNumber?: PhotoNestedMetadataSeriesnumberCreateInput | null;
  Metadata_StudyDateTime?: PhotoNestedMetadataStudydatetimeCreateInput | null;
  Metadata_StudyDescription?: PhotoNestedMetadataStudydescriptionCreateInput | null;
  Metadata_StudyID?: PhotoNestedMetadataStudyidCreateInput | null;
  Metadata_StudyPhysician?: PhotoNestedMetadataStudyphysicianCreateInput | null;
  Metadata_DateTimeDigitized?: PhotoNestedMetadataDatetimedigitizedCreateInput | null;
  Metadata_DateTimeOriginal?: PhotoNestedMetadataDatetimeoriginalCreateInput | null;
  Metadata_GPSLatitude?: PhotoNestedMetadataGpslatitudeCreateInput | null;
  Metadata_GPSLongitude?: PhotoNestedMetadataGpslongitudeCreateInput | null;
  Metadata_GPSProcessingMethod?: PhotoNestedMetadataGpsprocessingmethodCreateInput | null;
  Metadata_ImageUniqueID?: PhotoNestedMetadataImageuniqueidCreateInput | null;
  Metadata_ISOSpeedRatings?: PhotoNestedMetadataIsospeedratingsCreateInput | null;
  Metadata_UserComment?: PhotoNestedMetadataUsercommentCreateInput | null;
  Metadata_CustomField14?: PhotoNestedMetadataCustomfield14CreateInput | null;
  Metadata_CustomField15?: PhotoNestedMetadataCustomfield15CreateInput | null;
  Metadata_CustomField16?: PhotoNestedMetadataCustomfield16CreateInput | null;
  Metadata_CustomField17?: PhotoNestedMetadataCustomfield17CreateInput | null;
  Metadata_CustomField20?: PhotoNestedMetadataCustomfield20CreateInput | null;
  Metadata_CustomField5?: PhotoNestedMetadataCustomfield5CreateInput | null;
  Metadata_CustomField6?: PhotoNestedMetadataCustomfield6CreateInput | null;
  Metadata_CustomField7?: PhotoNestedMetadataCustomfield7CreateInput | null;
  Metadata_CustomField9?: PhotoNestedMetadataCustomfield9CreateInput | null;
  Metadata_CreatedTime?: PhotoNestedMetadataCreatedtimeCreateInput | null;
  Metadata_EditStatus?: PhotoNestedMetadataEditstatusCreateInput | null;
  Metadata_FixtureIdentifier?: PhotoNestedMetadataFixtureidentifierCreateInput | null;
  Metadata_LocalCaption?: PhotoNestedMetadataLocalcaptionCreateInput | null;
  Metadata_ProgramVersion?: PhotoNestedMetadataProgramversionCreateInput | null;
  Metadata_ReferenceDate?: PhotoNestedMetadataReferencedateCreateInput | null;
  Metadata_ReferenceNumber?: PhotoNestedMetadataReferencenumberCreateInput | null;
  Metadata_ReferenceService?: PhotoNestedMetadataReferenceserviceCreateInput | null;
  Metadata_ReleaseDate?: PhotoNestedMetadataReleasedateCreateInput | null;
  Metadata_ReleaseTime?: PhotoNestedMetadataReleasetimeCreateInput | null;
  Metadata_AssetState?: PhotoNestedMetadataAssetstateCreateInput | null;
  Metadata_Classify?: PhotoNestedMetadataClassifyCreateInput | null;
  Metadata_ContainedIn?: PhotoNestedMetadataContainedinCreateInput | null;
  Metadata_ContentValue?: PhotoNestedMetadataContentvalueCreateInput | null;
  Metadata_DocumentText?: PhotoNestedMetadataDocumenttextCreateInput | null;
  Metadata_Exif?: PhotoNestedMetadataExifCreateInput | null;
  Metadata_History?: PhotoNestedMetadataHistoryCreateInput | null;
  Metadata_ImageNotes?: PhotoNestedMetadataImagenotesCreateInput | null;
  Metadata_JobId?: PhotoNestedMetadataJobidCreateInput | null;
  Metadata_Links?: PhotoNestedMetadataLinksCreateInput | null;
  Metadata_MasterDocumentId?: PhotoNestedMetadataMasterdocumentidCreateInput | null;
  Metadata_ObjectCycle?: PhotoNestedMetadataObjectcycleCreateInput | null;
  Metadata_OwnerId?: PhotoNestedMetadataOwneridCreateInput | null;
  Metadata_ShortUniqueId?: PhotoNestedMetadataShortuniqueidCreateInput | null;
  Metadata_TransferredBy?: PhotoNestedMetadataTransferredbyCreateInput | null;
  Metadata_TransferredByEmail?: PhotoNestedMetadataTransferredbyemailCreateInput | null;
  Metadata_TransferredByFullName?: PhotoNestedMetadataTransferredbyfullnameCreateInput | null;
  Metadata_UniqueId?: PhotoNestedMetadataUniqueidCreateInput | null;
  Metadata_UploadedBy?: PhotoNestedMetadataUploadedbyCreateInput | null;
  Metadata_UploadedByFullName?: PhotoNestedMetadataUploadedbyfullnameCreateInput | null;
  Metadata_UploadTime?: PhotoNestedMetadataUploadtimeCreateInput | null;
  Metadata_UserDefined195?: PhotoNestedMetadataUserdefined195CreateInput | null;
  Metadata_UserDefined237?: PhotoNestedMetadataUserdefined237CreateInput | null;
  Metadata_UserDefined238?: PhotoNestedMetadataUserdefined238CreateInput | null;
  Metadata_UserDefined239?: PhotoNestedMetadataUserdefined239CreateInput | null;
  Metadata_UserDefined242?: PhotoNestedMetadataUserdefined242CreateInput | null;
  Metadata_UserDefined62?: PhotoNestedMetadataUserdefined62CreateInput | null;
  Metadata_CiAdrCity?: PhotoNestedMetadataCiadrcityCreateInput | null;
  Metadata_CiAdrCtry?: PhotoNestedMetadataCiadrctryCreateInput | null;
  Metadata_CiAdrExtadr?: PhotoNestedMetadataCiadrextadrCreateInput | null;
  Metadata_CiAdrPcode?: PhotoNestedMetadataCiadrpcodeCreateInput | null;
  Metadata_CiAdrRegion?: PhotoNestedMetadataCiadrregionCreateInput | null;
  Metadata_CiEmailWork?: PhotoNestedMetadataCiemailworkCreateInput | null;
  Metadata_CiTelWork?: PhotoNestedMetadataCitelworkCreateInput | null;
  Metadata_CiUrlWork?: PhotoNestedMetadataCiurlworkCreateInput | null;
  Metadata_CountryCode?: PhotoNestedMetadataCountrycodeCreateInput | null;
  Metadata_IntellectualGenre?: PhotoNestedMetadataIntellectualgenreCreateInput | null;
  Metadata_Location?: PhotoNestedMetadataLocationCreateInput | null;
  Metadata_Scene?: PhotoNestedMetadataSceneCreateInput | null;
  Metadata_SubjectCode?: PhotoNestedMetadataSubjectcodeCreateInput | null;
  Metadata_AddlModelInfo?: PhotoNestedMetadataAddlmodelinfoCreateInput | null;
  Metadata_Event?: PhotoNestedMetadataEventCreateInput | null;
  Metadata_MaxAvailHeight?: PhotoNestedMetadataMaxavailheightCreateInput | null;
  Metadata_MaxAvailWidth?: PhotoNestedMetadataMaxavailwidthCreateInput | null;
  Metadata_ModelAge?: PhotoNestedMetadataModelageCreateInput | null;
  Metadata_OrganisationInImageName?: PhotoNestedMetadataOrganisationinimagenameCreateInput | null;
  Metadata_PersonInImage?: PhotoNestedMetadataPersoninimageCreateInput | null;
  Metadata_AuthorsPosition?: PhotoNestedMetadataAuthorspositionCreateInput | null;
  Metadata_CaptionWriter?: PhotoNestedMetadataCaptionwriterCreateInput | null;
  Metadata_Category?: PhotoNestedMetadataCategoryCreateInput | null;
  Metadata_City?: PhotoNestedMetadataCityCreateInput | null;
  Metadata_Country?: PhotoNestedMetadataCountryCreateInput | null;
  Metadata_Credit?: PhotoNestedMetadataCreditCreateInput | null;
  Metadata_DateCreated?: PhotoNestedMetadataDatecreatedCreateInput | null;
  Metadata_Headline?: PhotoNestedMetadataHeadlineCreateInput | null;
  Metadata_ICCProfile?: PhotoNestedMetadataIccprofileCreateInput | null;
  Metadata_Instructions?: PhotoNestedMetadataInstructionsCreateInput | null;
  Metadata_Source?: PhotoNestedMetadataSourceCreateInput | null;
  Metadata_State?: PhotoNestedMetadataStateCreateInput | null;
  Metadata_SupplementalCategories?: PhotoNestedMetadataSupplementalcategoriesCreateInput | null;
  Metadata_TransmissionReference?: PhotoNestedMetadataTransmissionreferenceCreateInput | null;
  Metadata_Urgency?: PhotoNestedMetadataUrgencyCreateInput | null;
  Metadata_MinorModelAgeDisclosure?: PhotoNestedMetadataMinormodelagedisclosureCreateInput | null;
  Metadata_ModelReleaseID?: PhotoNestedMetadataModelreleaseidCreateInput | null;
  Metadata_ModelReleaseStatus?: PhotoNestedMetadataModelreleasestatusCreateInput | null;
  Metadata_PropertyReleaseID?: PhotoNestedMetadataPropertyreleaseidCreateInput | null;
  Metadata_PropertyReleaseStatus?: PhotoNestedMetadataPropertyreleasestatusCreateInput | null;
  Metadata_Version?: PhotoNestedMetadataVersionCreateInput | null;
  Metadata_Brand?: PhotoNestedMetadataBrandCreateInput | null;
  Metadata_Division?: PhotoNestedMetadataDivisionCreateInput | null;
  Metadata_Header?: PhotoNestedMetadataHeaderCreateInput | null;
  Metadata_Products?: PhotoNestedMetadataProductsCreateInput | null;
  Metadata_Produits?: PhotoNestedMetadataProduitsCreateInput | null;
  Metadata_publishingIssue?: PhotoNestedMetadataPublishingissueCreateInput | null;
  Metadata_publishingSubject?: PhotoNestedMetadataPublishingsubjectCreateInput | null;
  Metadata_Company?: PhotoNestedMetadataCompanyCreateInput | null;
  Metadata_CompanyShortName?: PhotoNestedMetadataCompanyshortnameCreateInput | null;
  Metadata_Confidentiality?: PhotoNestedMetadataConfidentialityCreateInput | null;
  Metadata_EditorialVersion?: PhotoNestedMetadataEditorialversionCreateInput | null;
  Metadata_FileCheckFor?: PhotoNestedMetadataFilecheckforCreateInput | null;
  Metadata_FileStage?: PhotoNestedMetadataFilestageCreateInput | null;
  Metadata_FileStatus?: PhotoNestedMetadataFilestatusCreateInput | null;
  Metadata_SentTo?: PhotoNestedMetadataSenttoCreateInput | null;
  Metadata_TargetVersion?: PhotoNestedMetadataTargetversionCreateInput | null;
  Metadata_Unit?: PhotoNestedMetadataUnitCreateInput | null;
  Metadata_Archive_child?: PhotoNestedMetadataArchiveChildCreateInput | null;
  Metadata_Archive_parent?: PhotoNestedMetadataArchiveParentCreateInput | null;
  Metadata_BackupName?: PhotoNestedMetadataBackupnameCreateInput | null;
  Metadata_BitmapGrayscalePicture?: PhotoNestedMetadataBitmapgrayscalepictureCreateInput | null;
  Metadata_BrandPrisma?: PhotoNestedMetadataBrandprismaCreateInput | null;
  Metadata_BrandShortName?: PhotoNestedMetadataBrandshortnameCreateInput | null;
  Metadata_CategoryPrisma?: PhotoNestedMetadataCategoryprismaCreateInput | null;
  Metadata_ContentMediaKind?: PhotoNestedMetadataContentmediakindCreateInput | null;
  Metadata_CopyrightLayer?: PhotoNestedMetadataCopyrightlayerCreateInput | null;
  Metadata_DeliveryAccount?: PhotoNestedMetadataDeliveryaccountCreateInput | null;
  Metadata_DeliveryCompany?: PhotoNestedMetadataDeliverycompanyCreateInput | null;
  Metadata_DeliveryCopyright?: PhotoNestedMetadataDeliverycopyrightCreateInput | null;
  Metadata_DeliveryDateFolder?: PhotoNestedMetadataDeliverydatefolderCreateInput | null;
  Metadata_DeliveryDateTime?: PhotoNestedMetadataDeliverydatetimeCreateInput | null;
  Metadata_DeliveryDcCreator?: PhotoNestedMetadataDeliverydccreatorCreateInput | null;
  Metadata_DeliveryDcRights?: PhotoNestedMetadataDeliverydcrightsCreateInput | null;
  Metadata_DeliveryFileType?: PhotoNestedMetadataDeliveryfiletypeCreateInput | null;
  Metadata_DeliveryFolder?: PhotoNestedMetadataDeliveryfolderCreateInput | null;
  Metadata_DeliveryKind?: PhotoNestedMetadataDeliverykindCreateInput | null;
  Metadata_DeliveryPath?: PhotoNestedMetadataDeliverypathCreateInput | null;
  Metadata_DeliveryPersonShownintheImage?: PhotoNestedMetadataDeliverypersonshownintheimageCreateInput | null;
  Metadata_DeliveryPhotoshopCredit?: PhotoNestedMetadataDeliveryphotoshopcreditCreateInput | null;
  Metadata_DeliveryPhotoshopSource?: PhotoNestedMetadataDeliveryphotoshopsourceCreateInput | null;
  Metadata_DeliveryService?: PhotoNestedMetadataDeliveryserviceCreateInput | null;
  Metadata_DeliverySubject?: PhotoNestedMetadataDeliverysubjectCreateInput | null;
  Metadata_DeliveryUnderSubject?: PhotoNestedMetadataDeliveryundersubjectCreateInput | null;
  Metadata_Department?: PhotoNestedMetadataDepartmentCreateInput | null;
  Metadata_DigitalAssetURL?: PhotoNestedMetadataDigitalasseturlCreateInput | null;
  Metadata_Edition?: PhotoNestedMetadataEditionCreateInput | null;
  Metadata_EnvironnementPhoto?: PhotoNestedMetadataEnvironnementphotoCreateInput | null;
  Metadata_FabStorage?: PhotoNestedMetadataFabstorageCreateInput | null;
  Metadata_FileType?: PhotoNestedMetadataFiletypeCreateInput | null;
  Metadata_HeaderPrisma?: PhotoNestedMetadataHeaderprismaCreateInput | null;
  Metadata_ID_subject?: PhotoNestedMetadataIdSubjectCreateInput | null;
  Metadata_IdAssignment?: PhotoNestedMetadataIdassignmentCreateInput | null;
  Metadata_IdMediaContent?: PhotoNestedMetadataIdmediacontentCreateInput | null;
  Metadata_IdPhotoPrisma?: PhotoNestedMetadataIdphotoprismaCreateInput | null;
  Metadata_Issue?: PhotoNestedMetadataIssueCreateInput | null;
  Metadata_JobProcessing?: PhotoNestedMetadataJobprocessingCreateInput | null;
  Metadata_LayoutPictureEditing?: PhotoNestedMetadataLayoutpictureeditingCreateInput | null;
  Metadata_LayoutStorage?: PhotoNestedMetadataLayoutstorageCreateInput | null;
  Metadata_OriginalColor?: PhotoNestedMetadataOriginalcolorCreateInput | null;
  Metadata_OriginalICCProfile?: PhotoNestedMetadataOriginaliccprofileCreateInput | null;
  Metadata_OriginalName?: PhotoNestedMetadataOriginalnameCreateInput | null;
  Metadata_OriginalWeigth?: PhotoNestedMetadataOriginalweigthCreateInput | null;
  Metadata_PagePrisma?: PhotoNestedMetadataPageprismaCreateInput | null;
  Metadata_PhotoStorage?: PhotoNestedMetadataPhotostorageCreateInput | null;
  Metadata_PrepressPictureEditing?: PhotoNestedMetadataPrepresspictureeditingCreateInput | null;
  Metadata_PriceLevel?: PhotoNestedMetadataPricelevelCreateInput | null;
  Metadata_PrintingProfile?: PhotoNestedMetadataPrintingprofileCreateInput | null;
  Metadata_PrismaProduction?: PhotoNestedMetadataPrismaproductionCreateInput | null;
  Metadata_ProcessHistory?: PhotoNestedMetadataProcesshistoryCreateInput | null;
  Metadata_ProcessParameter?: PhotoNestedMetadataProcessparameterCreateInput | null;
  Metadata_ProcessStatus?: PhotoNestedMetadataProcessstatusCreateInput | null;
  Metadata_Product?: PhotoNestedMetadataProductCreateInput | null;
  Metadata_ProductShortName?: PhotoNestedMetadataProductshortnameCreateInput | null;
  Metadata_QualifiedUsePrismaBy?: PhotoNestedMetadataQualifieduseprismabyCreateInput | null;
  Metadata_QualifiedUsePrismaDate?: PhotoNestedMetadataQualifieduseprismadateCreateInput | null;
  Metadata_QualifiedUsePrismaDuration?: PhotoNestedMetadataQualifieduseprismadurationCreateInput | null;
  Metadata_QualifiedUsePrismaSupport?: PhotoNestedMetadataQualifieduseprismasupportCreateInput | null;
  Metadata_RequiredPermission?: PhotoNestedMetadataRequiredpermissionCreateInput | null;
  Metadata_ResolutionKind?: PhotoNestedMetadataResolutionkindCreateInput | null;
  Metadata_Royaltyfree?: PhotoNestedMetadataRoyaltyfreeCreateInput | null;
  Metadata_Section?: PhotoNestedMetadataSectionCreateInput | null;
  Metadata_SubjectPrisma?: PhotoNestedMetadataSubjectprismaCreateInput | null;
  Metadata_Undersubject?: PhotoNestedMetadataUndersubjectCreateInput | null;
  Metadata_UnderUnderSubject?: PhotoNestedMetadataUnderundersubjectCreateInput | null;
  Metadata_UnitShortName?: PhotoNestedMetadataUnitshortnameCreateInput | null;
  Metadata_WorkflowKind?: PhotoNestedMetadataWorkflowkindCreateInput | null;
  Metadata_Make?: PhotoNestedMetadataMakeCreateInput | null;
  Metadata_Model?: PhotoNestedMetadataModelCreateInput | null;
  Metadata_CreatorTool?: PhotoNestedMetadataCreatortoolCreateInput | null;
  Metadata_Rating?: PhotoNestedMetadataRatingCreateInput | null;
  Metadata_XMPFileStamps?: PhotoNestedMetadataXmpfilestampsCreateInput | null;
  Metadata_Manifest?: PhotoNestedMetadataManifestCreateInput | null;
  Metadata_XMPHistory?: PhotoNestedMetadataXmphistoryCreateInput | null;
  Metadata_Certificate?: PhotoNestedMetadataCertificateCreateInput | null;
  Metadata_Marked?: PhotoNestedMetadataMarkedCreateInput | null;
  Metadata_Owner?: PhotoNestedMetadataOwnerCreateInput | null;
  Metadata_UsageTerms?: PhotoNestedMetadataUsagetermsCreateInput | null;
  Metadata_WebStatement?: PhotoNestedMetadataWebstatementCreateInput | null;
}

export interface PhotoWithForcedArchiveUpdateInput {
  originalName?: string | null;
  filesize?: number | null;
  height?: number | null;
  width?: number | null;
  contentType?: string | null;
  isUploaded?: boolean | null;
  isLegacy?: boolean | null;
  category?: PhotoNestedCategoryUpdateInput | null;
  agency?: PhotoNestedAgencyUpdateInput | null;
  deliverySubject?: PhotoNestedDeliverySubjectUpdateInput | null;
  importQueue?: PhotoNestedImportQueueUpdateInput | null;
  photoMetadatas?: PhotoNestedPhotoMetadatasUpdateInput | null;
  Metadata_SerialNumber?: PhotoNestedMetadataSerialnumberUpdateInput | null;
  Metadata_attributionName?: PhotoNestedMetadataAttributionnameUpdateInput | null;
  Metadata_attributionURL?: PhotoNestedMetadataAttributionurlUpdateInput | null;
  Metadata_license?: PhotoNestedMetadataLicenseUpdateInput | null;
  Metadata_morePermissions?: PhotoNestedMetadataMorepermissionsUpdateInput | null;
  Metadata_PaintBasedCorrections?: PhotoNestedMetadataPaintbasedcorrectionsUpdateInput | null;
  Metadata_ToneCurve?: PhotoNestedMetadataTonecurveUpdateInput | null;
  Metadata_creator?: PhotoNestedMetadataCreatorUpdateInput | null;
  Metadata_description?: PhotoNestedMetadataDescriptionUpdateInput | null;
  Metadata_Format?: PhotoNestedMetadataFormatUpdateInput | null;
  Metadata_Page?: PhotoNestedMetadataPageUpdateInput | null;
  Metadata_Publisher?: PhotoNestedMetadataPublisherUpdateInput | null;
  Metadata_rights?: PhotoNestedMetadataRightsUpdateInput | null;
  Metadata_subject?: PhotoNestedMetadataSubjectUpdateInput | null;
  Metadata_title?: PhotoNestedMetadataTitleUpdateInput | null;
  Metadata_Type?: PhotoNestedMetadataTypeUpdateInput | null;
  Metadata_AccessRights?: PhotoNestedMetadataAccessrightsUpdateInput | null;
  Metadata_Audience?: PhotoNestedMetadataAudienceUpdateInput | null;
  Metadata_Valid?: PhotoNestedMetadataValidUpdateInput | null;
  Metadata_EquipmentInstitution?: PhotoNestedMetadataEquipmentinstitutionUpdateInput | null;
  Metadata_EquipmentManufacturer?: PhotoNestedMetadataEquipmentmanufacturerUpdateInput | null;
  Metadata_FileInfoBitsPerPixel?: PhotoNestedMetadataFileinfobitsperpixelUpdateInput | null;
  Metadata_FileInfoFrames?: PhotoNestedMetadataFileinfoframesUpdateInput | null;
  Metadata_FileInfoPhotometricInterpretation?: PhotoNestedMetadataFileinfophotometricinterpretationUpdateInput | null;
  Metadata_FileInfoTransferSyntax?: PhotoNestedMetadataFileinfotransfersyntaxUpdateInput | null;
  Metadata_PatientDOB?: PhotoNestedMetadataPatientdobUpdateInput | null;
  Metadata_PatientID?: PhotoNestedMetadataPatientidUpdateInput | null;
  Metadata_PatientName?: PhotoNestedMetadataPatientnameUpdateInput | null;
  Metadata_PatientSex?: PhotoNestedMetadataPatientsexUpdateInput | null;
  Metadata_SeriesDateTime?: PhotoNestedMetadataSeriesdatetimeUpdateInput | null;
  Metadata_SeriesDescription?: PhotoNestedMetadataSeriesdescriptionUpdateInput | null;
  Metadata_SeriesModality?: PhotoNestedMetadataSeriesmodalityUpdateInput | null;
  Metadata_SeriesNumber?: PhotoNestedMetadataSeriesnumberUpdateInput | null;
  Metadata_StudyDateTime?: PhotoNestedMetadataStudydatetimeUpdateInput | null;
  Metadata_StudyDescription?: PhotoNestedMetadataStudydescriptionUpdateInput | null;
  Metadata_StudyID?: PhotoNestedMetadataStudyidUpdateInput | null;
  Metadata_StudyPhysician?: PhotoNestedMetadataStudyphysicianUpdateInput | null;
  Metadata_DateTimeDigitized?: PhotoNestedMetadataDatetimedigitizedUpdateInput | null;
  Metadata_DateTimeOriginal?: PhotoNestedMetadataDatetimeoriginalUpdateInput | null;
  Metadata_GPSLatitude?: PhotoNestedMetadataGpslatitudeUpdateInput | null;
  Metadata_GPSLongitude?: PhotoNestedMetadataGpslongitudeUpdateInput | null;
  Metadata_GPSProcessingMethod?: PhotoNestedMetadataGpsprocessingmethodUpdateInput | null;
  Metadata_ImageUniqueID?: PhotoNestedMetadataImageuniqueidUpdateInput | null;
  Metadata_ISOSpeedRatings?: PhotoNestedMetadataIsospeedratingsUpdateInput | null;
  Metadata_UserComment?: PhotoNestedMetadataUsercommentUpdateInput | null;
  Metadata_CustomField14?: PhotoNestedMetadataCustomfield14UpdateInput | null;
  Metadata_CustomField15?: PhotoNestedMetadataCustomfield15UpdateInput | null;
  Metadata_CustomField16?: PhotoNestedMetadataCustomfield16UpdateInput | null;
  Metadata_CustomField17?: PhotoNestedMetadataCustomfield17UpdateInput | null;
  Metadata_CustomField20?: PhotoNestedMetadataCustomfield20UpdateInput | null;
  Metadata_CustomField5?: PhotoNestedMetadataCustomfield5UpdateInput | null;
  Metadata_CustomField6?: PhotoNestedMetadataCustomfield6UpdateInput | null;
  Metadata_CustomField7?: PhotoNestedMetadataCustomfield7UpdateInput | null;
  Metadata_CustomField9?: PhotoNestedMetadataCustomfield9UpdateInput | null;
  Metadata_CreatedTime?: PhotoNestedMetadataCreatedtimeUpdateInput | null;
  Metadata_EditStatus?: PhotoNestedMetadataEditstatusUpdateInput | null;
  Metadata_FixtureIdentifier?: PhotoNestedMetadataFixtureidentifierUpdateInput | null;
  Metadata_LocalCaption?: PhotoNestedMetadataLocalcaptionUpdateInput | null;
  Metadata_ProgramVersion?: PhotoNestedMetadataProgramversionUpdateInput | null;
  Metadata_ReferenceDate?: PhotoNestedMetadataReferencedateUpdateInput | null;
  Metadata_ReferenceNumber?: PhotoNestedMetadataReferencenumberUpdateInput | null;
  Metadata_ReferenceService?: PhotoNestedMetadataReferenceserviceUpdateInput | null;
  Metadata_ReleaseDate?: PhotoNestedMetadataReleasedateUpdateInput | null;
  Metadata_ReleaseTime?: PhotoNestedMetadataReleasetimeUpdateInput | null;
  Metadata_AssetState?: PhotoNestedMetadataAssetstateUpdateInput | null;
  Metadata_Classify?: PhotoNestedMetadataClassifyUpdateInput | null;
  Metadata_ContainedIn?: PhotoNestedMetadataContainedinUpdateInput | null;
  Metadata_ContentValue?: PhotoNestedMetadataContentvalueUpdateInput | null;
  Metadata_DocumentText?: PhotoNestedMetadataDocumenttextUpdateInput | null;
  Metadata_Exif?: PhotoNestedMetadataExifUpdateInput | null;
  Metadata_History?: PhotoNestedMetadataHistoryUpdateInput | null;
  Metadata_ImageNotes?: PhotoNestedMetadataImagenotesUpdateInput | null;
  Metadata_JobId?: PhotoNestedMetadataJobidUpdateInput | null;
  Metadata_Links?: PhotoNestedMetadataLinksUpdateInput | null;
  Metadata_MasterDocumentId?: PhotoNestedMetadataMasterdocumentidUpdateInput | null;
  Metadata_ObjectCycle?: PhotoNestedMetadataObjectcycleUpdateInput | null;
  Metadata_OwnerId?: PhotoNestedMetadataOwneridUpdateInput | null;
  Metadata_ShortUniqueId?: PhotoNestedMetadataShortuniqueidUpdateInput | null;
  Metadata_TransferredBy?: PhotoNestedMetadataTransferredbyUpdateInput | null;
  Metadata_TransferredByEmail?: PhotoNestedMetadataTransferredbyemailUpdateInput | null;
  Metadata_TransferredByFullName?: PhotoNestedMetadataTransferredbyfullnameUpdateInput | null;
  Metadata_UniqueId?: PhotoNestedMetadataUniqueidUpdateInput | null;
  Metadata_UploadedBy?: PhotoNestedMetadataUploadedbyUpdateInput | null;
  Metadata_UploadedByFullName?: PhotoNestedMetadataUploadedbyfullnameUpdateInput | null;
  Metadata_UploadTime?: PhotoNestedMetadataUploadtimeUpdateInput | null;
  Metadata_UserDefined195?: PhotoNestedMetadataUserdefined195UpdateInput | null;
  Metadata_UserDefined237?: PhotoNestedMetadataUserdefined237UpdateInput | null;
  Metadata_UserDefined238?: PhotoNestedMetadataUserdefined238UpdateInput | null;
  Metadata_UserDefined239?: PhotoNestedMetadataUserdefined239UpdateInput | null;
  Metadata_UserDefined242?: PhotoNestedMetadataUserdefined242UpdateInput | null;
  Metadata_UserDefined62?: PhotoNestedMetadataUserdefined62UpdateInput | null;
  Metadata_CiAdrCity?: PhotoNestedMetadataCiadrcityUpdateInput | null;
  Metadata_CiAdrCtry?: PhotoNestedMetadataCiadrctryUpdateInput | null;
  Metadata_CiAdrExtadr?: PhotoNestedMetadataCiadrextadrUpdateInput | null;
  Metadata_CiAdrPcode?: PhotoNestedMetadataCiadrpcodeUpdateInput | null;
  Metadata_CiAdrRegion?: PhotoNestedMetadataCiadrregionUpdateInput | null;
  Metadata_CiEmailWork?: PhotoNestedMetadataCiemailworkUpdateInput | null;
  Metadata_CiTelWork?: PhotoNestedMetadataCitelworkUpdateInput | null;
  Metadata_CiUrlWork?: PhotoNestedMetadataCiurlworkUpdateInput | null;
  Metadata_CountryCode?: PhotoNestedMetadataCountrycodeUpdateInput | null;
  Metadata_IntellectualGenre?: PhotoNestedMetadataIntellectualgenreUpdateInput | null;
  Metadata_Location?: PhotoNestedMetadataLocationUpdateInput | null;
  Metadata_Scene?: PhotoNestedMetadataSceneUpdateInput | null;
  Metadata_SubjectCode?: PhotoNestedMetadataSubjectcodeUpdateInput | null;
  Metadata_AddlModelInfo?: PhotoNestedMetadataAddlmodelinfoUpdateInput | null;
  Metadata_Event?: PhotoNestedMetadataEventUpdateInput | null;
  Metadata_MaxAvailHeight?: PhotoNestedMetadataMaxavailheightUpdateInput | null;
  Metadata_MaxAvailWidth?: PhotoNestedMetadataMaxavailwidthUpdateInput | null;
  Metadata_ModelAge?: PhotoNestedMetadataModelageUpdateInput | null;
  Metadata_OrganisationInImageName?: PhotoNestedMetadataOrganisationinimagenameUpdateInput | null;
  Metadata_PersonInImage?: PhotoNestedMetadataPersoninimageUpdateInput | null;
  Metadata_AuthorsPosition?: PhotoNestedMetadataAuthorspositionUpdateInput | null;
  Metadata_CaptionWriter?: PhotoNestedMetadataCaptionwriterUpdateInput | null;
  Metadata_Category?: PhotoNestedMetadataCategoryUpdateInput | null;
  Metadata_City?: PhotoNestedMetadataCityUpdateInput | null;
  Metadata_Country?: PhotoNestedMetadataCountryUpdateInput | null;
  Metadata_Credit?: PhotoNestedMetadataCreditUpdateInput | null;
  Metadata_DateCreated?: PhotoNestedMetadataDatecreatedUpdateInput | null;
  Metadata_Headline?: PhotoNestedMetadataHeadlineUpdateInput | null;
  Metadata_ICCProfile?: PhotoNestedMetadataIccprofileUpdateInput | null;
  Metadata_Instructions?: PhotoNestedMetadataInstructionsUpdateInput | null;
  Metadata_Source?: PhotoNestedMetadataSourceUpdateInput | null;
  Metadata_State?: PhotoNestedMetadataStateUpdateInput | null;
  Metadata_SupplementalCategories?: PhotoNestedMetadataSupplementalcategoriesUpdateInput | null;
  Metadata_TransmissionReference?: PhotoNestedMetadataTransmissionreferenceUpdateInput | null;
  Metadata_Urgency?: PhotoNestedMetadataUrgencyUpdateInput | null;
  Metadata_MinorModelAgeDisclosure?: PhotoNestedMetadataMinormodelagedisclosureUpdateInput | null;
  Metadata_ModelReleaseID?: PhotoNestedMetadataModelreleaseidUpdateInput | null;
  Metadata_ModelReleaseStatus?: PhotoNestedMetadataModelreleasestatusUpdateInput | null;
  Metadata_PropertyReleaseID?: PhotoNestedMetadataPropertyreleaseidUpdateInput | null;
  Metadata_PropertyReleaseStatus?: PhotoNestedMetadataPropertyreleasestatusUpdateInput | null;
  Metadata_Version?: PhotoNestedMetadataVersionUpdateInput | null;
  Metadata_Brand?: PhotoNestedMetadataBrandUpdateInput | null;
  Metadata_Division?: PhotoNestedMetadataDivisionUpdateInput | null;
  Metadata_Header?: PhotoNestedMetadataHeaderUpdateInput | null;
  Metadata_Products?: PhotoNestedMetadataProductsUpdateInput | null;
  Metadata_Produits?: PhotoNestedMetadataProduitsUpdateInput | null;
  Metadata_publishingIssue?: PhotoNestedMetadataPublishingissueUpdateInput | null;
  Metadata_publishingSubject?: PhotoNestedMetadataPublishingsubjectUpdateInput | null;
  Metadata_Company?: PhotoNestedMetadataCompanyUpdateInput | null;
  Metadata_CompanyShortName?: PhotoNestedMetadataCompanyshortnameUpdateInput | null;
  Metadata_Confidentiality?: PhotoNestedMetadataConfidentialityUpdateInput | null;
  Metadata_EditorialVersion?: PhotoNestedMetadataEditorialversionUpdateInput | null;
  Metadata_FileCheckFor?: PhotoNestedMetadataFilecheckforUpdateInput | null;
  Metadata_FileStage?: PhotoNestedMetadataFilestageUpdateInput | null;
  Metadata_FileStatus?: PhotoNestedMetadataFilestatusUpdateInput | null;
  Metadata_SentTo?: PhotoNestedMetadataSenttoUpdateInput | null;
  Metadata_TargetVersion?: PhotoNestedMetadataTargetversionUpdateInput | null;
  Metadata_Unit?: PhotoNestedMetadataUnitUpdateInput | null;
  Metadata_Archive_child?: PhotoNestedMetadataArchiveChildUpdateInput | null;
  Metadata_Archive_parent?: PhotoNestedMetadataArchiveParentUpdateInput | null;
  Metadata_BackupName?: PhotoNestedMetadataBackupnameUpdateInput | null;
  Metadata_BitmapGrayscalePicture?: PhotoNestedMetadataBitmapgrayscalepictureUpdateInput | null;
  Metadata_BrandPrisma?: PhotoNestedMetadataBrandprismaUpdateInput | null;
  Metadata_BrandShortName?: PhotoNestedMetadataBrandshortnameUpdateInput | null;
  Metadata_CategoryPrisma?: PhotoNestedMetadataCategoryprismaUpdateInput | null;
  Metadata_ContentMediaKind?: PhotoNestedMetadataContentmediakindUpdateInput | null;
  Metadata_CopyrightLayer?: PhotoNestedMetadataCopyrightlayerUpdateInput | null;
  Metadata_DeliveryAccount?: PhotoNestedMetadataDeliveryaccountUpdateInput | null;
  Metadata_DeliveryCompany?: PhotoNestedMetadataDeliverycompanyUpdateInput | null;
  Metadata_DeliveryCopyright?: PhotoNestedMetadataDeliverycopyrightUpdateInput | null;
  Metadata_DeliveryDateFolder?: PhotoNestedMetadataDeliverydatefolderUpdateInput | null;
  Metadata_DeliveryDateTime?: PhotoNestedMetadataDeliverydatetimeUpdateInput | null;
  Metadata_DeliveryDcCreator?: PhotoNestedMetadataDeliverydccreatorUpdateInput | null;
  Metadata_DeliveryDcRights?: PhotoNestedMetadataDeliverydcrightsUpdateInput | null;
  Metadata_DeliveryFileType?: PhotoNestedMetadataDeliveryfiletypeUpdateInput | null;
  Metadata_DeliveryFolder?: PhotoNestedMetadataDeliveryfolderUpdateInput | null;
  Metadata_DeliveryKind?: PhotoNestedMetadataDeliverykindUpdateInput | null;
  Metadata_DeliveryPath?: PhotoNestedMetadataDeliverypathUpdateInput | null;
  Metadata_DeliveryPersonShownintheImage?: PhotoNestedMetadataDeliverypersonshownintheimageUpdateInput | null;
  Metadata_DeliveryPhotoshopCredit?: PhotoNestedMetadataDeliveryphotoshopcreditUpdateInput | null;
  Metadata_DeliveryPhotoshopSource?: PhotoNestedMetadataDeliveryphotoshopsourceUpdateInput | null;
  Metadata_DeliveryService?: PhotoNestedMetadataDeliveryserviceUpdateInput | null;
  Metadata_DeliverySubject?: PhotoNestedMetadataDeliverysubjectUpdateInput | null;
  Metadata_DeliveryUnderSubject?: PhotoNestedMetadataDeliveryundersubjectUpdateInput | null;
  Metadata_Department?: PhotoNestedMetadataDepartmentUpdateInput | null;
  Metadata_DigitalAssetURL?: PhotoNestedMetadataDigitalasseturlUpdateInput | null;
  Metadata_Edition?: PhotoNestedMetadataEditionUpdateInput | null;
  Metadata_EnvironnementPhoto?: PhotoNestedMetadataEnvironnementphotoUpdateInput | null;
  Metadata_FabStorage?: PhotoNestedMetadataFabstorageUpdateInput | null;
  Metadata_FileType?: PhotoNestedMetadataFiletypeUpdateInput | null;
  Metadata_HeaderPrisma?: PhotoNestedMetadataHeaderprismaUpdateInput | null;
  Metadata_ID_subject?: PhotoNestedMetadataIdSubjectUpdateInput | null;
  Metadata_IdAssignment?: PhotoNestedMetadataIdassignmentUpdateInput | null;
  Metadata_IdMediaContent?: PhotoNestedMetadataIdmediacontentUpdateInput | null;
  Metadata_IdPhotoPrisma?: PhotoNestedMetadataIdphotoprismaUpdateInput | null;
  Metadata_Issue?: PhotoNestedMetadataIssueUpdateInput | null;
  Metadata_JobProcessing?: PhotoNestedMetadataJobprocessingUpdateInput | null;
  Metadata_LayoutPictureEditing?: PhotoNestedMetadataLayoutpictureeditingUpdateInput | null;
  Metadata_LayoutStorage?: PhotoNestedMetadataLayoutstorageUpdateInput | null;
  Metadata_OriginalColor?: PhotoNestedMetadataOriginalcolorUpdateInput | null;
  Metadata_OriginalICCProfile?: PhotoNestedMetadataOriginaliccprofileUpdateInput | null;
  Metadata_OriginalName?: PhotoNestedMetadataOriginalnameUpdateInput | null;
  Metadata_OriginalWeigth?: PhotoNestedMetadataOriginalweigthUpdateInput | null;
  Metadata_PagePrisma?: PhotoNestedMetadataPageprismaUpdateInput | null;
  Metadata_PhotoStorage?: PhotoNestedMetadataPhotostorageUpdateInput | null;
  Metadata_PrepressPictureEditing?: PhotoNestedMetadataPrepresspictureeditingUpdateInput | null;
  Metadata_PriceLevel?: PhotoNestedMetadataPricelevelUpdateInput | null;
  Metadata_PrintingProfile?: PhotoNestedMetadataPrintingprofileUpdateInput | null;
  Metadata_PrismaProduction?: PhotoNestedMetadataPrismaproductionUpdateInput | null;
  Metadata_ProcessHistory?: PhotoNestedMetadataProcesshistoryUpdateInput | null;
  Metadata_ProcessParameter?: PhotoNestedMetadataProcessparameterUpdateInput | null;
  Metadata_ProcessStatus?: PhotoNestedMetadataProcessstatusUpdateInput | null;
  Metadata_Product?: PhotoNestedMetadataProductUpdateInput | null;
  Metadata_ProductShortName?: PhotoNestedMetadataProductshortnameUpdateInput | null;
  Metadata_QualifiedUsePrismaBy?: PhotoNestedMetadataQualifieduseprismabyUpdateInput | null;
  Metadata_QualifiedUsePrismaDate?: PhotoNestedMetadataQualifieduseprismadateUpdateInput | null;
  Metadata_QualifiedUsePrismaDuration?: PhotoNestedMetadataQualifieduseprismadurationUpdateInput | null;
  Metadata_QualifiedUsePrismaSupport?: PhotoNestedMetadataQualifieduseprismasupportUpdateInput | null;
  Metadata_RequiredPermission?: PhotoNestedMetadataRequiredpermissionUpdateInput | null;
  Metadata_ResolutionKind?: PhotoNestedMetadataResolutionkindUpdateInput | null;
  Metadata_Royaltyfree?: PhotoNestedMetadataRoyaltyfreeUpdateInput | null;
  Metadata_Section?: PhotoNestedMetadataSectionUpdateInput | null;
  Metadata_SubjectPrisma?: PhotoNestedMetadataSubjectprismaUpdateInput | null;
  Metadata_Undersubject?: PhotoNestedMetadataUndersubjectUpdateInput | null;
  Metadata_UnderUnderSubject?: PhotoNestedMetadataUnderundersubjectUpdateInput | null;
  Metadata_UnitShortName?: PhotoNestedMetadataUnitshortnameUpdateInput | null;
  Metadata_WorkflowKind?: PhotoNestedMetadataWorkflowkindUpdateInput | null;
  Metadata_Make?: PhotoNestedMetadataMakeUpdateInput | null;
  Metadata_Model?: PhotoNestedMetadataModelUpdateInput | null;
  Metadata_CreatorTool?: PhotoNestedMetadataCreatortoolUpdateInput | null;
  Metadata_Rating?: PhotoNestedMetadataRatingUpdateInput | null;
  Metadata_XMPFileStamps?: PhotoNestedMetadataXmpfilestampsUpdateInput | null;
  Metadata_Manifest?: PhotoNestedMetadataManifestUpdateInput | null;
  Metadata_XMPHistory?: PhotoNestedMetadataXmphistoryUpdateInput | null;
  Metadata_Certificate?: PhotoNestedMetadataCertificateUpdateInput | null;
  Metadata_Marked?: PhotoNestedMetadataMarkedUpdateInput | null;
  Metadata_Owner?: PhotoNestedMetadataOwnerUpdateInput | null;
  Metadata_UsageTerms?: PhotoNestedMetadataUsagetermsUpdateInput | null;
  Metadata_WebStatement?: PhotoNestedMetadataWebstatementUpdateInput | null;
}

export interface PhotoWithForcedCategoryCreateInput {
  id?: string | null;
  originalName?: string | null;
  filesize?: number | null;
  height?: number | null;
  width?: number | null;
  contentType?: string | null;
  isUploaded?: boolean | null;
  isLegacy?: boolean | null;
  archive: PhotoNestedArchiveCreateInput;
  agency?: PhotoNestedAgencyCreateInput | null;
  deliverySubject?: PhotoNestedDeliverySubjectCreateInput | null;
  importQueue?: PhotoNestedImportQueueCreateInput | null;
  photoMetadatas?: PhotoNestedPhotoMetadatasCreateInput | null;
  Metadata_SerialNumber?: PhotoNestedMetadataSerialnumberCreateInput | null;
  Metadata_attributionName?: PhotoNestedMetadataAttributionnameCreateInput | null;
  Metadata_attributionURL?: PhotoNestedMetadataAttributionurlCreateInput | null;
  Metadata_license?: PhotoNestedMetadataLicenseCreateInput | null;
  Metadata_morePermissions?: PhotoNestedMetadataMorepermissionsCreateInput | null;
  Metadata_PaintBasedCorrections?: PhotoNestedMetadataPaintbasedcorrectionsCreateInput | null;
  Metadata_ToneCurve?: PhotoNestedMetadataTonecurveCreateInput | null;
  Metadata_creator?: PhotoNestedMetadataCreatorCreateInput | null;
  Metadata_description?: PhotoNestedMetadataDescriptionCreateInput | null;
  Metadata_Format?: PhotoNestedMetadataFormatCreateInput | null;
  Metadata_Page?: PhotoNestedMetadataPageCreateInput | null;
  Metadata_Publisher?: PhotoNestedMetadataPublisherCreateInput | null;
  Metadata_rights?: PhotoNestedMetadataRightsCreateInput | null;
  Metadata_subject?: PhotoNestedMetadataSubjectCreateInput | null;
  Metadata_title?: PhotoNestedMetadataTitleCreateInput | null;
  Metadata_Type?: PhotoNestedMetadataTypeCreateInput | null;
  Metadata_AccessRights?: PhotoNestedMetadataAccessrightsCreateInput | null;
  Metadata_Audience?: PhotoNestedMetadataAudienceCreateInput | null;
  Metadata_Valid?: PhotoNestedMetadataValidCreateInput | null;
  Metadata_EquipmentInstitution?: PhotoNestedMetadataEquipmentinstitutionCreateInput | null;
  Metadata_EquipmentManufacturer?: PhotoNestedMetadataEquipmentmanufacturerCreateInput | null;
  Metadata_FileInfoBitsPerPixel?: PhotoNestedMetadataFileinfobitsperpixelCreateInput | null;
  Metadata_FileInfoFrames?: PhotoNestedMetadataFileinfoframesCreateInput | null;
  Metadata_FileInfoPhotometricInterpretation?: PhotoNestedMetadataFileinfophotometricinterpretationCreateInput | null;
  Metadata_FileInfoTransferSyntax?: PhotoNestedMetadataFileinfotransfersyntaxCreateInput | null;
  Metadata_PatientDOB?: PhotoNestedMetadataPatientdobCreateInput | null;
  Metadata_PatientID?: PhotoNestedMetadataPatientidCreateInput | null;
  Metadata_PatientName?: PhotoNestedMetadataPatientnameCreateInput | null;
  Metadata_PatientSex?: PhotoNestedMetadataPatientsexCreateInput | null;
  Metadata_SeriesDateTime?: PhotoNestedMetadataSeriesdatetimeCreateInput | null;
  Metadata_SeriesDescription?: PhotoNestedMetadataSeriesdescriptionCreateInput | null;
  Metadata_SeriesModality?: PhotoNestedMetadataSeriesmodalityCreateInput | null;
  Metadata_SeriesNumber?: PhotoNestedMetadataSeriesnumberCreateInput | null;
  Metadata_StudyDateTime?: PhotoNestedMetadataStudydatetimeCreateInput | null;
  Metadata_StudyDescription?: PhotoNestedMetadataStudydescriptionCreateInput | null;
  Metadata_StudyID?: PhotoNestedMetadataStudyidCreateInput | null;
  Metadata_StudyPhysician?: PhotoNestedMetadataStudyphysicianCreateInput | null;
  Metadata_DateTimeDigitized?: PhotoNestedMetadataDatetimedigitizedCreateInput | null;
  Metadata_DateTimeOriginal?: PhotoNestedMetadataDatetimeoriginalCreateInput | null;
  Metadata_GPSLatitude?: PhotoNestedMetadataGpslatitudeCreateInput | null;
  Metadata_GPSLongitude?: PhotoNestedMetadataGpslongitudeCreateInput | null;
  Metadata_GPSProcessingMethod?: PhotoNestedMetadataGpsprocessingmethodCreateInput | null;
  Metadata_ImageUniqueID?: PhotoNestedMetadataImageuniqueidCreateInput | null;
  Metadata_ISOSpeedRatings?: PhotoNestedMetadataIsospeedratingsCreateInput | null;
  Metadata_UserComment?: PhotoNestedMetadataUsercommentCreateInput | null;
  Metadata_CustomField14?: PhotoNestedMetadataCustomfield14CreateInput | null;
  Metadata_CustomField15?: PhotoNestedMetadataCustomfield15CreateInput | null;
  Metadata_CustomField16?: PhotoNestedMetadataCustomfield16CreateInput | null;
  Metadata_CustomField17?: PhotoNestedMetadataCustomfield17CreateInput | null;
  Metadata_CustomField20?: PhotoNestedMetadataCustomfield20CreateInput | null;
  Metadata_CustomField5?: PhotoNestedMetadataCustomfield5CreateInput | null;
  Metadata_CustomField6?: PhotoNestedMetadataCustomfield6CreateInput | null;
  Metadata_CustomField7?: PhotoNestedMetadataCustomfield7CreateInput | null;
  Metadata_CustomField9?: PhotoNestedMetadataCustomfield9CreateInput | null;
  Metadata_CreatedTime?: PhotoNestedMetadataCreatedtimeCreateInput | null;
  Metadata_EditStatus?: PhotoNestedMetadataEditstatusCreateInput | null;
  Metadata_FixtureIdentifier?: PhotoNestedMetadataFixtureidentifierCreateInput | null;
  Metadata_LocalCaption?: PhotoNestedMetadataLocalcaptionCreateInput | null;
  Metadata_ProgramVersion?: PhotoNestedMetadataProgramversionCreateInput | null;
  Metadata_ReferenceDate?: PhotoNestedMetadataReferencedateCreateInput | null;
  Metadata_ReferenceNumber?: PhotoNestedMetadataReferencenumberCreateInput | null;
  Metadata_ReferenceService?: PhotoNestedMetadataReferenceserviceCreateInput | null;
  Metadata_ReleaseDate?: PhotoNestedMetadataReleasedateCreateInput | null;
  Metadata_ReleaseTime?: PhotoNestedMetadataReleasetimeCreateInput | null;
  Metadata_AssetState?: PhotoNestedMetadataAssetstateCreateInput | null;
  Metadata_Classify?: PhotoNestedMetadataClassifyCreateInput | null;
  Metadata_ContainedIn?: PhotoNestedMetadataContainedinCreateInput | null;
  Metadata_ContentValue?: PhotoNestedMetadataContentvalueCreateInput | null;
  Metadata_DocumentText?: PhotoNestedMetadataDocumenttextCreateInput | null;
  Metadata_Exif?: PhotoNestedMetadataExifCreateInput | null;
  Metadata_History?: PhotoNestedMetadataHistoryCreateInput | null;
  Metadata_ImageNotes?: PhotoNestedMetadataImagenotesCreateInput | null;
  Metadata_JobId?: PhotoNestedMetadataJobidCreateInput | null;
  Metadata_Links?: PhotoNestedMetadataLinksCreateInput | null;
  Metadata_MasterDocumentId?: PhotoNestedMetadataMasterdocumentidCreateInput | null;
  Metadata_ObjectCycle?: PhotoNestedMetadataObjectcycleCreateInput | null;
  Metadata_OwnerId?: PhotoNestedMetadataOwneridCreateInput | null;
  Metadata_ShortUniqueId?: PhotoNestedMetadataShortuniqueidCreateInput | null;
  Metadata_TransferredBy?: PhotoNestedMetadataTransferredbyCreateInput | null;
  Metadata_TransferredByEmail?: PhotoNestedMetadataTransferredbyemailCreateInput | null;
  Metadata_TransferredByFullName?: PhotoNestedMetadataTransferredbyfullnameCreateInput | null;
  Metadata_UniqueId?: PhotoNestedMetadataUniqueidCreateInput | null;
  Metadata_UploadedBy?: PhotoNestedMetadataUploadedbyCreateInput | null;
  Metadata_UploadedByFullName?: PhotoNestedMetadataUploadedbyfullnameCreateInput | null;
  Metadata_UploadTime?: PhotoNestedMetadataUploadtimeCreateInput | null;
  Metadata_UserDefined195?: PhotoNestedMetadataUserdefined195CreateInput | null;
  Metadata_UserDefined237?: PhotoNestedMetadataUserdefined237CreateInput | null;
  Metadata_UserDefined238?: PhotoNestedMetadataUserdefined238CreateInput | null;
  Metadata_UserDefined239?: PhotoNestedMetadataUserdefined239CreateInput | null;
  Metadata_UserDefined242?: PhotoNestedMetadataUserdefined242CreateInput | null;
  Metadata_UserDefined62?: PhotoNestedMetadataUserdefined62CreateInput | null;
  Metadata_CiAdrCity?: PhotoNestedMetadataCiadrcityCreateInput | null;
  Metadata_CiAdrCtry?: PhotoNestedMetadataCiadrctryCreateInput | null;
  Metadata_CiAdrExtadr?: PhotoNestedMetadataCiadrextadrCreateInput | null;
  Metadata_CiAdrPcode?: PhotoNestedMetadataCiadrpcodeCreateInput | null;
  Metadata_CiAdrRegion?: PhotoNestedMetadataCiadrregionCreateInput | null;
  Metadata_CiEmailWork?: PhotoNestedMetadataCiemailworkCreateInput | null;
  Metadata_CiTelWork?: PhotoNestedMetadataCitelworkCreateInput | null;
  Metadata_CiUrlWork?: PhotoNestedMetadataCiurlworkCreateInput | null;
  Metadata_CountryCode?: PhotoNestedMetadataCountrycodeCreateInput | null;
  Metadata_IntellectualGenre?: PhotoNestedMetadataIntellectualgenreCreateInput | null;
  Metadata_Location?: PhotoNestedMetadataLocationCreateInput | null;
  Metadata_Scene?: PhotoNestedMetadataSceneCreateInput | null;
  Metadata_SubjectCode?: PhotoNestedMetadataSubjectcodeCreateInput | null;
  Metadata_AddlModelInfo?: PhotoNestedMetadataAddlmodelinfoCreateInput | null;
  Metadata_Event?: PhotoNestedMetadataEventCreateInput | null;
  Metadata_MaxAvailHeight?: PhotoNestedMetadataMaxavailheightCreateInput | null;
  Metadata_MaxAvailWidth?: PhotoNestedMetadataMaxavailwidthCreateInput | null;
  Metadata_ModelAge?: PhotoNestedMetadataModelageCreateInput | null;
  Metadata_OrganisationInImageName?: PhotoNestedMetadataOrganisationinimagenameCreateInput | null;
  Metadata_PersonInImage?: PhotoNestedMetadataPersoninimageCreateInput | null;
  Metadata_AuthorsPosition?: PhotoNestedMetadataAuthorspositionCreateInput | null;
  Metadata_CaptionWriter?: PhotoNestedMetadataCaptionwriterCreateInput | null;
  Metadata_Category?: PhotoNestedMetadataCategoryCreateInput | null;
  Metadata_City?: PhotoNestedMetadataCityCreateInput | null;
  Metadata_Country?: PhotoNestedMetadataCountryCreateInput | null;
  Metadata_Credit?: PhotoNestedMetadataCreditCreateInput | null;
  Metadata_DateCreated?: PhotoNestedMetadataDatecreatedCreateInput | null;
  Metadata_Headline?: PhotoNestedMetadataHeadlineCreateInput | null;
  Metadata_ICCProfile?: PhotoNestedMetadataIccprofileCreateInput | null;
  Metadata_Instructions?: PhotoNestedMetadataInstructionsCreateInput | null;
  Metadata_Source?: PhotoNestedMetadataSourceCreateInput | null;
  Metadata_State?: PhotoNestedMetadataStateCreateInput | null;
  Metadata_SupplementalCategories?: PhotoNestedMetadataSupplementalcategoriesCreateInput | null;
  Metadata_TransmissionReference?: PhotoNestedMetadataTransmissionreferenceCreateInput | null;
  Metadata_Urgency?: PhotoNestedMetadataUrgencyCreateInput | null;
  Metadata_MinorModelAgeDisclosure?: PhotoNestedMetadataMinormodelagedisclosureCreateInput | null;
  Metadata_ModelReleaseID?: PhotoNestedMetadataModelreleaseidCreateInput | null;
  Metadata_ModelReleaseStatus?: PhotoNestedMetadataModelreleasestatusCreateInput | null;
  Metadata_PropertyReleaseID?: PhotoNestedMetadataPropertyreleaseidCreateInput | null;
  Metadata_PropertyReleaseStatus?: PhotoNestedMetadataPropertyreleasestatusCreateInput | null;
  Metadata_Version?: PhotoNestedMetadataVersionCreateInput | null;
  Metadata_Brand?: PhotoNestedMetadataBrandCreateInput | null;
  Metadata_Division?: PhotoNestedMetadataDivisionCreateInput | null;
  Metadata_Header?: PhotoNestedMetadataHeaderCreateInput | null;
  Metadata_Products?: PhotoNestedMetadataProductsCreateInput | null;
  Metadata_Produits?: PhotoNestedMetadataProduitsCreateInput | null;
  Metadata_publishingIssue?: PhotoNestedMetadataPublishingissueCreateInput | null;
  Metadata_publishingSubject?: PhotoNestedMetadataPublishingsubjectCreateInput | null;
  Metadata_Company?: PhotoNestedMetadataCompanyCreateInput | null;
  Metadata_CompanyShortName?: PhotoNestedMetadataCompanyshortnameCreateInput | null;
  Metadata_Confidentiality?: PhotoNestedMetadataConfidentialityCreateInput | null;
  Metadata_EditorialVersion?: PhotoNestedMetadataEditorialversionCreateInput | null;
  Metadata_FileCheckFor?: PhotoNestedMetadataFilecheckforCreateInput | null;
  Metadata_FileStage?: PhotoNestedMetadataFilestageCreateInput | null;
  Metadata_FileStatus?: PhotoNestedMetadataFilestatusCreateInput | null;
  Metadata_SentTo?: PhotoNestedMetadataSenttoCreateInput | null;
  Metadata_TargetVersion?: PhotoNestedMetadataTargetversionCreateInput | null;
  Metadata_Unit?: PhotoNestedMetadataUnitCreateInput | null;
  Metadata_Archive_child?: PhotoNestedMetadataArchiveChildCreateInput | null;
  Metadata_Archive_parent?: PhotoNestedMetadataArchiveParentCreateInput | null;
  Metadata_BackupName?: PhotoNestedMetadataBackupnameCreateInput | null;
  Metadata_BitmapGrayscalePicture?: PhotoNestedMetadataBitmapgrayscalepictureCreateInput | null;
  Metadata_BrandPrisma?: PhotoNestedMetadataBrandprismaCreateInput | null;
  Metadata_BrandShortName?: PhotoNestedMetadataBrandshortnameCreateInput | null;
  Metadata_CategoryPrisma?: PhotoNestedMetadataCategoryprismaCreateInput | null;
  Metadata_ContentMediaKind?: PhotoNestedMetadataContentmediakindCreateInput | null;
  Metadata_CopyrightLayer?: PhotoNestedMetadataCopyrightlayerCreateInput | null;
  Metadata_DeliveryAccount?: PhotoNestedMetadataDeliveryaccountCreateInput | null;
  Metadata_DeliveryCompany?: PhotoNestedMetadataDeliverycompanyCreateInput | null;
  Metadata_DeliveryCopyright?: PhotoNestedMetadataDeliverycopyrightCreateInput | null;
  Metadata_DeliveryDateFolder?: PhotoNestedMetadataDeliverydatefolderCreateInput | null;
  Metadata_DeliveryDateTime?: PhotoNestedMetadataDeliverydatetimeCreateInput | null;
  Metadata_DeliveryDcCreator?: PhotoNestedMetadataDeliverydccreatorCreateInput | null;
  Metadata_DeliveryDcRights?: PhotoNestedMetadataDeliverydcrightsCreateInput | null;
  Metadata_DeliveryFileType?: PhotoNestedMetadataDeliveryfiletypeCreateInput | null;
  Metadata_DeliveryFolder?: PhotoNestedMetadataDeliveryfolderCreateInput | null;
  Metadata_DeliveryKind?: PhotoNestedMetadataDeliverykindCreateInput | null;
  Metadata_DeliveryPath?: PhotoNestedMetadataDeliverypathCreateInput | null;
  Metadata_DeliveryPersonShownintheImage?: PhotoNestedMetadataDeliverypersonshownintheimageCreateInput | null;
  Metadata_DeliveryPhotoshopCredit?: PhotoNestedMetadataDeliveryphotoshopcreditCreateInput | null;
  Metadata_DeliveryPhotoshopSource?: PhotoNestedMetadataDeliveryphotoshopsourceCreateInput | null;
  Metadata_DeliveryService?: PhotoNestedMetadataDeliveryserviceCreateInput | null;
  Metadata_DeliverySubject?: PhotoNestedMetadataDeliverysubjectCreateInput | null;
  Metadata_DeliveryUnderSubject?: PhotoNestedMetadataDeliveryundersubjectCreateInput | null;
  Metadata_Department?: PhotoNestedMetadataDepartmentCreateInput | null;
  Metadata_DigitalAssetURL?: PhotoNestedMetadataDigitalasseturlCreateInput | null;
  Metadata_Edition?: PhotoNestedMetadataEditionCreateInput | null;
  Metadata_EnvironnementPhoto?: PhotoNestedMetadataEnvironnementphotoCreateInput | null;
  Metadata_FabStorage?: PhotoNestedMetadataFabstorageCreateInput | null;
  Metadata_FileType?: PhotoNestedMetadataFiletypeCreateInput | null;
  Metadata_HeaderPrisma?: PhotoNestedMetadataHeaderprismaCreateInput | null;
  Metadata_ID_subject?: PhotoNestedMetadataIdSubjectCreateInput | null;
  Metadata_IdAssignment?: PhotoNestedMetadataIdassignmentCreateInput | null;
  Metadata_IdMediaContent?: PhotoNestedMetadataIdmediacontentCreateInput | null;
  Metadata_IdPhotoPrisma?: PhotoNestedMetadataIdphotoprismaCreateInput | null;
  Metadata_Issue?: PhotoNestedMetadataIssueCreateInput | null;
  Metadata_JobProcessing?: PhotoNestedMetadataJobprocessingCreateInput | null;
  Metadata_LayoutPictureEditing?: PhotoNestedMetadataLayoutpictureeditingCreateInput | null;
  Metadata_LayoutStorage?: PhotoNestedMetadataLayoutstorageCreateInput | null;
  Metadata_OriginalColor?: PhotoNestedMetadataOriginalcolorCreateInput | null;
  Metadata_OriginalICCProfile?: PhotoNestedMetadataOriginaliccprofileCreateInput | null;
  Metadata_OriginalName?: PhotoNestedMetadataOriginalnameCreateInput | null;
  Metadata_OriginalWeigth?: PhotoNestedMetadataOriginalweigthCreateInput | null;
  Metadata_PagePrisma?: PhotoNestedMetadataPageprismaCreateInput | null;
  Metadata_PhotoStorage?: PhotoNestedMetadataPhotostorageCreateInput | null;
  Metadata_PrepressPictureEditing?: PhotoNestedMetadataPrepresspictureeditingCreateInput | null;
  Metadata_PriceLevel?: PhotoNestedMetadataPricelevelCreateInput | null;
  Metadata_PrintingProfile?: PhotoNestedMetadataPrintingprofileCreateInput | null;
  Metadata_PrismaProduction?: PhotoNestedMetadataPrismaproductionCreateInput | null;
  Metadata_ProcessHistory?: PhotoNestedMetadataProcesshistoryCreateInput | null;
  Metadata_ProcessParameter?: PhotoNestedMetadataProcessparameterCreateInput | null;
  Metadata_ProcessStatus?: PhotoNestedMetadataProcessstatusCreateInput | null;
  Metadata_Product?: PhotoNestedMetadataProductCreateInput | null;
  Metadata_ProductShortName?: PhotoNestedMetadataProductshortnameCreateInput | null;
  Metadata_QualifiedUsePrismaBy?: PhotoNestedMetadataQualifieduseprismabyCreateInput | null;
  Metadata_QualifiedUsePrismaDate?: PhotoNestedMetadataQualifieduseprismadateCreateInput | null;
  Metadata_QualifiedUsePrismaDuration?: PhotoNestedMetadataQualifieduseprismadurationCreateInput | null;
  Metadata_QualifiedUsePrismaSupport?: PhotoNestedMetadataQualifieduseprismasupportCreateInput | null;
  Metadata_RequiredPermission?: PhotoNestedMetadataRequiredpermissionCreateInput | null;
  Metadata_ResolutionKind?: PhotoNestedMetadataResolutionkindCreateInput | null;
  Metadata_Royaltyfree?: PhotoNestedMetadataRoyaltyfreeCreateInput | null;
  Metadata_Section?: PhotoNestedMetadataSectionCreateInput | null;
  Metadata_SubjectPrisma?: PhotoNestedMetadataSubjectprismaCreateInput | null;
  Metadata_Undersubject?: PhotoNestedMetadataUndersubjectCreateInput | null;
  Metadata_UnderUnderSubject?: PhotoNestedMetadataUnderundersubjectCreateInput | null;
  Metadata_UnitShortName?: PhotoNestedMetadataUnitshortnameCreateInput | null;
  Metadata_WorkflowKind?: PhotoNestedMetadataWorkflowkindCreateInput | null;
  Metadata_Make?: PhotoNestedMetadataMakeCreateInput | null;
  Metadata_Model?: PhotoNestedMetadataModelCreateInput | null;
  Metadata_CreatorTool?: PhotoNestedMetadataCreatortoolCreateInput | null;
  Metadata_Rating?: PhotoNestedMetadataRatingCreateInput | null;
  Metadata_XMPFileStamps?: PhotoNestedMetadataXmpfilestampsCreateInput | null;
  Metadata_Manifest?: PhotoNestedMetadataManifestCreateInput | null;
  Metadata_XMPHistory?: PhotoNestedMetadataXmphistoryCreateInput | null;
  Metadata_Certificate?: PhotoNestedMetadataCertificateCreateInput | null;
  Metadata_Marked?: PhotoNestedMetadataMarkedCreateInput | null;
  Metadata_Owner?: PhotoNestedMetadataOwnerCreateInput | null;
  Metadata_UsageTerms?: PhotoNestedMetadataUsagetermsCreateInput | null;
  Metadata_WebStatement?: PhotoNestedMetadataWebstatementCreateInput | null;
}

export interface PhotoWithForcedCategoryUpdateInput {
  originalName?: string | null;
  filesize?: number | null;
  height?: number | null;
  width?: number | null;
  contentType?: string | null;
  isUploaded?: boolean | null;
  isLegacy?: boolean | null;
  archive?: PhotoNestedArchiveUpdateInput | null;
  agency?: PhotoNestedAgencyUpdateInput | null;
  deliverySubject?: PhotoNestedDeliverySubjectUpdateInput | null;
  importQueue?: PhotoNestedImportQueueUpdateInput | null;
  photoMetadatas?: PhotoNestedPhotoMetadatasUpdateInput | null;
  Metadata_SerialNumber?: PhotoNestedMetadataSerialnumberUpdateInput | null;
  Metadata_attributionName?: PhotoNestedMetadataAttributionnameUpdateInput | null;
  Metadata_attributionURL?: PhotoNestedMetadataAttributionurlUpdateInput | null;
  Metadata_license?: PhotoNestedMetadataLicenseUpdateInput | null;
  Metadata_morePermissions?: PhotoNestedMetadataMorepermissionsUpdateInput | null;
  Metadata_PaintBasedCorrections?: PhotoNestedMetadataPaintbasedcorrectionsUpdateInput | null;
  Metadata_ToneCurve?: PhotoNestedMetadataTonecurveUpdateInput | null;
  Metadata_creator?: PhotoNestedMetadataCreatorUpdateInput | null;
  Metadata_description?: PhotoNestedMetadataDescriptionUpdateInput | null;
  Metadata_Format?: PhotoNestedMetadataFormatUpdateInput | null;
  Metadata_Page?: PhotoNestedMetadataPageUpdateInput | null;
  Metadata_Publisher?: PhotoNestedMetadataPublisherUpdateInput | null;
  Metadata_rights?: PhotoNestedMetadataRightsUpdateInput | null;
  Metadata_subject?: PhotoNestedMetadataSubjectUpdateInput | null;
  Metadata_title?: PhotoNestedMetadataTitleUpdateInput | null;
  Metadata_Type?: PhotoNestedMetadataTypeUpdateInput | null;
  Metadata_AccessRights?: PhotoNestedMetadataAccessrightsUpdateInput | null;
  Metadata_Audience?: PhotoNestedMetadataAudienceUpdateInput | null;
  Metadata_Valid?: PhotoNestedMetadataValidUpdateInput | null;
  Metadata_EquipmentInstitution?: PhotoNestedMetadataEquipmentinstitutionUpdateInput | null;
  Metadata_EquipmentManufacturer?: PhotoNestedMetadataEquipmentmanufacturerUpdateInput | null;
  Metadata_FileInfoBitsPerPixel?: PhotoNestedMetadataFileinfobitsperpixelUpdateInput | null;
  Metadata_FileInfoFrames?: PhotoNestedMetadataFileinfoframesUpdateInput | null;
  Metadata_FileInfoPhotometricInterpretation?: PhotoNestedMetadataFileinfophotometricinterpretationUpdateInput | null;
  Metadata_FileInfoTransferSyntax?: PhotoNestedMetadataFileinfotransfersyntaxUpdateInput | null;
  Metadata_PatientDOB?: PhotoNestedMetadataPatientdobUpdateInput | null;
  Metadata_PatientID?: PhotoNestedMetadataPatientidUpdateInput | null;
  Metadata_PatientName?: PhotoNestedMetadataPatientnameUpdateInput | null;
  Metadata_PatientSex?: PhotoNestedMetadataPatientsexUpdateInput | null;
  Metadata_SeriesDateTime?: PhotoNestedMetadataSeriesdatetimeUpdateInput | null;
  Metadata_SeriesDescription?: PhotoNestedMetadataSeriesdescriptionUpdateInput | null;
  Metadata_SeriesModality?: PhotoNestedMetadataSeriesmodalityUpdateInput | null;
  Metadata_SeriesNumber?: PhotoNestedMetadataSeriesnumberUpdateInput | null;
  Metadata_StudyDateTime?: PhotoNestedMetadataStudydatetimeUpdateInput | null;
  Metadata_StudyDescription?: PhotoNestedMetadataStudydescriptionUpdateInput | null;
  Metadata_StudyID?: PhotoNestedMetadataStudyidUpdateInput | null;
  Metadata_StudyPhysician?: PhotoNestedMetadataStudyphysicianUpdateInput | null;
  Metadata_DateTimeDigitized?: PhotoNestedMetadataDatetimedigitizedUpdateInput | null;
  Metadata_DateTimeOriginal?: PhotoNestedMetadataDatetimeoriginalUpdateInput | null;
  Metadata_GPSLatitude?: PhotoNestedMetadataGpslatitudeUpdateInput | null;
  Metadata_GPSLongitude?: PhotoNestedMetadataGpslongitudeUpdateInput | null;
  Metadata_GPSProcessingMethod?: PhotoNestedMetadataGpsprocessingmethodUpdateInput | null;
  Metadata_ImageUniqueID?: PhotoNestedMetadataImageuniqueidUpdateInput | null;
  Metadata_ISOSpeedRatings?: PhotoNestedMetadataIsospeedratingsUpdateInput | null;
  Metadata_UserComment?: PhotoNestedMetadataUsercommentUpdateInput | null;
  Metadata_CustomField14?: PhotoNestedMetadataCustomfield14UpdateInput | null;
  Metadata_CustomField15?: PhotoNestedMetadataCustomfield15UpdateInput | null;
  Metadata_CustomField16?: PhotoNestedMetadataCustomfield16UpdateInput | null;
  Metadata_CustomField17?: PhotoNestedMetadataCustomfield17UpdateInput | null;
  Metadata_CustomField20?: PhotoNestedMetadataCustomfield20UpdateInput | null;
  Metadata_CustomField5?: PhotoNestedMetadataCustomfield5UpdateInput | null;
  Metadata_CustomField6?: PhotoNestedMetadataCustomfield6UpdateInput | null;
  Metadata_CustomField7?: PhotoNestedMetadataCustomfield7UpdateInput | null;
  Metadata_CustomField9?: PhotoNestedMetadataCustomfield9UpdateInput | null;
  Metadata_CreatedTime?: PhotoNestedMetadataCreatedtimeUpdateInput | null;
  Metadata_EditStatus?: PhotoNestedMetadataEditstatusUpdateInput | null;
  Metadata_FixtureIdentifier?: PhotoNestedMetadataFixtureidentifierUpdateInput | null;
  Metadata_LocalCaption?: PhotoNestedMetadataLocalcaptionUpdateInput | null;
  Metadata_ProgramVersion?: PhotoNestedMetadataProgramversionUpdateInput | null;
  Metadata_ReferenceDate?: PhotoNestedMetadataReferencedateUpdateInput | null;
  Metadata_ReferenceNumber?: PhotoNestedMetadataReferencenumberUpdateInput | null;
  Metadata_ReferenceService?: PhotoNestedMetadataReferenceserviceUpdateInput | null;
  Metadata_ReleaseDate?: PhotoNestedMetadataReleasedateUpdateInput | null;
  Metadata_ReleaseTime?: PhotoNestedMetadataReleasetimeUpdateInput | null;
  Metadata_AssetState?: PhotoNestedMetadataAssetstateUpdateInput | null;
  Metadata_Classify?: PhotoNestedMetadataClassifyUpdateInput | null;
  Metadata_ContainedIn?: PhotoNestedMetadataContainedinUpdateInput | null;
  Metadata_ContentValue?: PhotoNestedMetadataContentvalueUpdateInput | null;
  Metadata_DocumentText?: PhotoNestedMetadataDocumenttextUpdateInput | null;
  Metadata_Exif?: PhotoNestedMetadataExifUpdateInput | null;
  Metadata_History?: PhotoNestedMetadataHistoryUpdateInput | null;
  Metadata_ImageNotes?: PhotoNestedMetadataImagenotesUpdateInput | null;
  Metadata_JobId?: PhotoNestedMetadataJobidUpdateInput | null;
  Metadata_Links?: PhotoNestedMetadataLinksUpdateInput | null;
  Metadata_MasterDocumentId?: PhotoNestedMetadataMasterdocumentidUpdateInput | null;
  Metadata_ObjectCycle?: PhotoNestedMetadataObjectcycleUpdateInput | null;
  Metadata_OwnerId?: PhotoNestedMetadataOwneridUpdateInput | null;
  Metadata_ShortUniqueId?: PhotoNestedMetadataShortuniqueidUpdateInput | null;
  Metadata_TransferredBy?: PhotoNestedMetadataTransferredbyUpdateInput | null;
  Metadata_TransferredByEmail?: PhotoNestedMetadataTransferredbyemailUpdateInput | null;
  Metadata_TransferredByFullName?: PhotoNestedMetadataTransferredbyfullnameUpdateInput | null;
  Metadata_UniqueId?: PhotoNestedMetadataUniqueidUpdateInput | null;
  Metadata_UploadedBy?: PhotoNestedMetadataUploadedbyUpdateInput | null;
  Metadata_UploadedByFullName?: PhotoNestedMetadataUploadedbyfullnameUpdateInput | null;
  Metadata_UploadTime?: PhotoNestedMetadataUploadtimeUpdateInput | null;
  Metadata_UserDefined195?: PhotoNestedMetadataUserdefined195UpdateInput | null;
  Metadata_UserDefined237?: PhotoNestedMetadataUserdefined237UpdateInput | null;
  Metadata_UserDefined238?: PhotoNestedMetadataUserdefined238UpdateInput | null;
  Metadata_UserDefined239?: PhotoNestedMetadataUserdefined239UpdateInput | null;
  Metadata_UserDefined242?: PhotoNestedMetadataUserdefined242UpdateInput | null;
  Metadata_UserDefined62?: PhotoNestedMetadataUserdefined62UpdateInput | null;
  Metadata_CiAdrCity?: PhotoNestedMetadataCiadrcityUpdateInput | null;
  Metadata_CiAdrCtry?: PhotoNestedMetadataCiadrctryUpdateInput | null;
  Metadata_CiAdrExtadr?: PhotoNestedMetadataCiadrextadrUpdateInput | null;
  Metadata_CiAdrPcode?: PhotoNestedMetadataCiadrpcodeUpdateInput | null;
  Metadata_CiAdrRegion?: PhotoNestedMetadataCiadrregionUpdateInput | null;
  Metadata_CiEmailWork?: PhotoNestedMetadataCiemailworkUpdateInput | null;
  Metadata_CiTelWork?: PhotoNestedMetadataCitelworkUpdateInput | null;
  Metadata_CiUrlWork?: PhotoNestedMetadataCiurlworkUpdateInput | null;
  Metadata_CountryCode?: PhotoNestedMetadataCountrycodeUpdateInput | null;
  Metadata_IntellectualGenre?: PhotoNestedMetadataIntellectualgenreUpdateInput | null;
  Metadata_Location?: PhotoNestedMetadataLocationUpdateInput | null;
  Metadata_Scene?: PhotoNestedMetadataSceneUpdateInput | null;
  Metadata_SubjectCode?: PhotoNestedMetadataSubjectcodeUpdateInput | null;
  Metadata_AddlModelInfo?: PhotoNestedMetadataAddlmodelinfoUpdateInput | null;
  Metadata_Event?: PhotoNestedMetadataEventUpdateInput | null;
  Metadata_MaxAvailHeight?: PhotoNestedMetadataMaxavailheightUpdateInput | null;
  Metadata_MaxAvailWidth?: PhotoNestedMetadataMaxavailwidthUpdateInput | null;
  Metadata_ModelAge?: PhotoNestedMetadataModelageUpdateInput | null;
  Metadata_OrganisationInImageName?: PhotoNestedMetadataOrganisationinimagenameUpdateInput | null;
  Metadata_PersonInImage?: PhotoNestedMetadataPersoninimageUpdateInput | null;
  Metadata_AuthorsPosition?: PhotoNestedMetadataAuthorspositionUpdateInput | null;
  Metadata_CaptionWriter?: PhotoNestedMetadataCaptionwriterUpdateInput | null;
  Metadata_Category?: PhotoNestedMetadataCategoryUpdateInput | null;
  Metadata_City?: PhotoNestedMetadataCityUpdateInput | null;
  Metadata_Country?: PhotoNestedMetadataCountryUpdateInput | null;
  Metadata_Credit?: PhotoNestedMetadataCreditUpdateInput | null;
  Metadata_DateCreated?: PhotoNestedMetadataDatecreatedUpdateInput | null;
  Metadata_Headline?: PhotoNestedMetadataHeadlineUpdateInput | null;
  Metadata_ICCProfile?: PhotoNestedMetadataIccprofileUpdateInput | null;
  Metadata_Instructions?: PhotoNestedMetadataInstructionsUpdateInput | null;
  Metadata_Source?: PhotoNestedMetadataSourceUpdateInput | null;
  Metadata_State?: PhotoNestedMetadataStateUpdateInput | null;
  Metadata_SupplementalCategories?: PhotoNestedMetadataSupplementalcategoriesUpdateInput | null;
  Metadata_TransmissionReference?: PhotoNestedMetadataTransmissionreferenceUpdateInput | null;
  Metadata_Urgency?: PhotoNestedMetadataUrgencyUpdateInput | null;
  Metadata_MinorModelAgeDisclosure?: PhotoNestedMetadataMinormodelagedisclosureUpdateInput | null;
  Metadata_ModelReleaseID?: PhotoNestedMetadataModelreleaseidUpdateInput | null;
  Metadata_ModelReleaseStatus?: PhotoNestedMetadataModelreleasestatusUpdateInput | null;
  Metadata_PropertyReleaseID?: PhotoNestedMetadataPropertyreleaseidUpdateInput | null;
  Metadata_PropertyReleaseStatus?: PhotoNestedMetadataPropertyreleasestatusUpdateInput | null;
  Metadata_Version?: PhotoNestedMetadataVersionUpdateInput | null;
  Metadata_Brand?: PhotoNestedMetadataBrandUpdateInput | null;
  Metadata_Division?: PhotoNestedMetadataDivisionUpdateInput | null;
  Metadata_Header?: PhotoNestedMetadataHeaderUpdateInput | null;
  Metadata_Products?: PhotoNestedMetadataProductsUpdateInput | null;
  Metadata_Produits?: PhotoNestedMetadataProduitsUpdateInput | null;
  Metadata_publishingIssue?: PhotoNestedMetadataPublishingissueUpdateInput | null;
  Metadata_publishingSubject?: PhotoNestedMetadataPublishingsubjectUpdateInput | null;
  Metadata_Company?: PhotoNestedMetadataCompanyUpdateInput | null;
  Metadata_CompanyShortName?: PhotoNestedMetadataCompanyshortnameUpdateInput | null;
  Metadata_Confidentiality?: PhotoNestedMetadataConfidentialityUpdateInput | null;
  Metadata_EditorialVersion?: PhotoNestedMetadataEditorialversionUpdateInput | null;
  Metadata_FileCheckFor?: PhotoNestedMetadataFilecheckforUpdateInput | null;
  Metadata_FileStage?: PhotoNestedMetadataFilestageUpdateInput | null;
  Metadata_FileStatus?: PhotoNestedMetadataFilestatusUpdateInput | null;
  Metadata_SentTo?: PhotoNestedMetadataSenttoUpdateInput | null;
  Metadata_TargetVersion?: PhotoNestedMetadataTargetversionUpdateInput | null;
  Metadata_Unit?: PhotoNestedMetadataUnitUpdateInput | null;
  Metadata_Archive_child?: PhotoNestedMetadataArchiveChildUpdateInput | null;
  Metadata_Archive_parent?: PhotoNestedMetadataArchiveParentUpdateInput | null;
  Metadata_BackupName?: PhotoNestedMetadataBackupnameUpdateInput | null;
  Metadata_BitmapGrayscalePicture?: PhotoNestedMetadataBitmapgrayscalepictureUpdateInput | null;
  Metadata_BrandPrisma?: PhotoNestedMetadataBrandprismaUpdateInput | null;
  Metadata_BrandShortName?: PhotoNestedMetadataBrandshortnameUpdateInput | null;
  Metadata_CategoryPrisma?: PhotoNestedMetadataCategoryprismaUpdateInput | null;
  Metadata_ContentMediaKind?: PhotoNestedMetadataContentmediakindUpdateInput | null;
  Metadata_CopyrightLayer?: PhotoNestedMetadataCopyrightlayerUpdateInput | null;
  Metadata_DeliveryAccount?: PhotoNestedMetadataDeliveryaccountUpdateInput | null;
  Metadata_DeliveryCompany?: PhotoNestedMetadataDeliverycompanyUpdateInput | null;
  Metadata_DeliveryCopyright?: PhotoNestedMetadataDeliverycopyrightUpdateInput | null;
  Metadata_DeliveryDateFolder?: PhotoNestedMetadataDeliverydatefolderUpdateInput | null;
  Metadata_DeliveryDateTime?: PhotoNestedMetadataDeliverydatetimeUpdateInput | null;
  Metadata_DeliveryDcCreator?: PhotoNestedMetadataDeliverydccreatorUpdateInput | null;
  Metadata_DeliveryDcRights?: PhotoNestedMetadataDeliverydcrightsUpdateInput | null;
  Metadata_DeliveryFileType?: PhotoNestedMetadataDeliveryfiletypeUpdateInput | null;
  Metadata_DeliveryFolder?: PhotoNestedMetadataDeliveryfolderUpdateInput | null;
  Metadata_DeliveryKind?: PhotoNestedMetadataDeliverykindUpdateInput | null;
  Metadata_DeliveryPath?: PhotoNestedMetadataDeliverypathUpdateInput | null;
  Metadata_DeliveryPersonShownintheImage?: PhotoNestedMetadataDeliverypersonshownintheimageUpdateInput | null;
  Metadata_DeliveryPhotoshopCredit?: PhotoNestedMetadataDeliveryphotoshopcreditUpdateInput | null;
  Metadata_DeliveryPhotoshopSource?: PhotoNestedMetadataDeliveryphotoshopsourceUpdateInput | null;
  Metadata_DeliveryService?: PhotoNestedMetadataDeliveryserviceUpdateInput | null;
  Metadata_DeliverySubject?: PhotoNestedMetadataDeliverysubjectUpdateInput | null;
  Metadata_DeliveryUnderSubject?: PhotoNestedMetadataDeliveryundersubjectUpdateInput | null;
  Metadata_Department?: PhotoNestedMetadataDepartmentUpdateInput | null;
  Metadata_DigitalAssetURL?: PhotoNestedMetadataDigitalasseturlUpdateInput | null;
  Metadata_Edition?: PhotoNestedMetadataEditionUpdateInput | null;
  Metadata_EnvironnementPhoto?: PhotoNestedMetadataEnvironnementphotoUpdateInput | null;
  Metadata_FabStorage?: PhotoNestedMetadataFabstorageUpdateInput | null;
  Metadata_FileType?: PhotoNestedMetadataFiletypeUpdateInput | null;
  Metadata_HeaderPrisma?: PhotoNestedMetadataHeaderprismaUpdateInput | null;
  Metadata_ID_subject?: PhotoNestedMetadataIdSubjectUpdateInput | null;
  Metadata_IdAssignment?: PhotoNestedMetadataIdassignmentUpdateInput | null;
  Metadata_IdMediaContent?: PhotoNestedMetadataIdmediacontentUpdateInput | null;
  Metadata_IdPhotoPrisma?: PhotoNestedMetadataIdphotoprismaUpdateInput | null;
  Metadata_Issue?: PhotoNestedMetadataIssueUpdateInput | null;
  Metadata_JobProcessing?: PhotoNestedMetadataJobprocessingUpdateInput | null;
  Metadata_LayoutPictureEditing?: PhotoNestedMetadataLayoutpictureeditingUpdateInput | null;
  Metadata_LayoutStorage?: PhotoNestedMetadataLayoutstorageUpdateInput | null;
  Metadata_OriginalColor?: PhotoNestedMetadataOriginalcolorUpdateInput | null;
  Metadata_OriginalICCProfile?: PhotoNestedMetadataOriginaliccprofileUpdateInput | null;
  Metadata_OriginalName?: PhotoNestedMetadataOriginalnameUpdateInput | null;
  Metadata_OriginalWeigth?: PhotoNestedMetadataOriginalweigthUpdateInput | null;
  Metadata_PagePrisma?: PhotoNestedMetadataPageprismaUpdateInput | null;
  Metadata_PhotoStorage?: PhotoNestedMetadataPhotostorageUpdateInput | null;
  Metadata_PrepressPictureEditing?: PhotoNestedMetadataPrepresspictureeditingUpdateInput | null;
  Metadata_PriceLevel?: PhotoNestedMetadataPricelevelUpdateInput | null;
  Metadata_PrintingProfile?: PhotoNestedMetadataPrintingprofileUpdateInput | null;
  Metadata_PrismaProduction?: PhotoNestedMetadataPrismaproductionUpdateInput | null;
  Metadata_ProcessHistory?: PhotoNestedMetadataProcesshistoryUpdateInput | null;
  Metadata_ProcessParameter?: PhotoNestedMetadataProcessparameterUpdateInput | null;
  Metadata_ProcessStatus?: PhotoNestedMetadataProcessstatusUpdateInput | null;
  Metadata_Product?: PhotoNestedMetadataProductUpdateInput | null;
  Metadata_ProductShortName?: PhotoNestedMetadataProductshortnameUpdateInput | null;
  Metadata_QualifiedUsePrismaBy?: PhotoNestedMetadataQualifieduseprismabyUpdateInput | null;
  Metadata_QualifiedUsePrismaDate?: PhotoNestedMetadataQualifieduseprismadateUpdateInput | null;
  Metadata_QualifiedUsePrismaDuration?: PhotoNestedMetadataQualifieduseprismadurationUpdateInput | null;
  Metadata_QualifiedUsePrismaSupport?: PhotoNestedMetadataQualifieduseprismasupportUpdateInput | null;
  Metadata_RequiredPermission?: PhotoNestedMetadataRequiredpermissionUpdateInput | null;
  Metadata_ResolutionKind?: PhotoNestedMetadataResolutionkindUpdateInput | null;
  Metadata_Royaltyfree?: PhotoNestedMetadataRoyaltyfreeUpdateInput | null;
  Metadata_Section?: PhotoNestedMetadataSectionUpdateInput | null;
  Metadata_SubjectPrisma?: PhotoNestedMetadataSubjectprismaUpdateInput | null;
  Metadata_Undersubject?: PhotoNestedMetadataUndersubjectUpdateInput | null;
  Metadata_UnderUnderSubject?: PhotoNestedMetadataUnderundersubjectUpdateInput | null;
  Metadata_UnitShortName?: PhotoNestedMetadataUnitshortnameUpdateInput | null;
  Metadata_WorkflowKind?: PhotoNestedMetadataWorkflowkindUpdateInput | null;
  Metadata_Make?: PhotoNestedMetadataMakeUpdateInput | null;
  Metadata_Model?: PhotoNestedMetadataModelUpdateInput | null;
  Metadata_CreatorTool?: PhotoNestedMetadataCreatortoolUpdateInput | null;
  Metadata_Rating?: PhotoNestedMetadataRatingUpdateInput | null;
  Metadata_XMPFileStamps?: PhotoNestedMetadataXmpfilestampsUpdateInput | null;
  Metadata_Manifest?: PhotoNestedMetadataManifestUpdateInput | null;
  Metadata_XMPHistory?: PhotoNestedMetadataXmphistoryUpdateInput | null;
  Metadata_Certificate?: PhotoNestedMetadataCertificateUpdateInput | null;
  Metadata_Marked?: PhotoNestedMetadataMarkedUpdateInput | null;
  Metadata_Owner?: PhotoNestedMetadataOwnerUpdateInput | null;
  Metadata_UsageTerms?: PhotoNestedMetadataUsagetermsUpdateInput | null;
  Metadata_WebStatement?: PhotoNestedMetadataWebstatementUpdateInput | null;
}

export interface PhotoWithForcedDeliverySubjectCreateInput {
  id?: string | null;
  originalName?: string | null;
  filesize?: number | null;
  height?: number | null;
  width?: number | null;
  contentType?: string | null;
  isUploaded?: boolean | null;
  isLegacy?: boolean | null;
  archive: PhotoNestedArchiveCreateInput;
  category?: PhotoNestedCategoryCreateInput | null;
  agency?: PhotoNestedAgencyCreateInput | null;
  importQueue?: PhotoNestedImportQueueCreateInput | null;
  photoMetadatas?: PhotoNestedPhotoMetadatasCreateInput | null;
  Metadata_SerialNumber?: PhotoNestedMetadataSerialnumberCreateInput | null;
  Metadata_attributionName?: PhotoNestedMetadataAttributionnameCreateInput | null;
  Metadata_attributionURL?: PhotoNestedMetadataAttributionurlCreateInput | null;
  Metadata_license?: PhotoNestedMetadataLicenseCreateInput | null;
  Metadata_morePermissions?: PhotoNestedMetadataMorepermissionsCreateInput | null;
  Metadata_PaintBasedCorrections?: PhotoNestedMetadataPaintbasedcorrectionsCreateInput | null;
  Metadata_ToneCurve?: PhotoNestedMetadataTonecurveCreateInput | null;
  Metadata_creator?: PhotoNestedMetadataCreatorCreateInput | null;
  Metadata_description?: PhotoNestedMetadataDescriptionCreateInput | null;
  Metadata_Format?: PhotoNestedMetadataFormatCreateInput | null;
  Metadata_Page?: PhotoNestedMetadataPageCreateInput | null;
  Metadata_Publisher?: PhotoNestedMetadataPublisherCreateInput | null;
  Metadata_rights?: PhotoNestedMetadataRightsCreateInput | null;
  Metadata_subject?: PhotoNestedMetadataSubjectCreateInput | null;
  Metadata_title?: PhotoNestedMetadataTitleCreateInput | null;
  Metadata_Type?: PhotoNestedMetadataTypeCreateInput | null;
  Metadata_AccessRights?: PhotoNestedMetadataAccessrightsCreateInput | null;
  Metadata_Audience?: PhotoNestedMetadataAudienceCreateInput | null;
  Metadata_Valid?: PhotoNestedMetadataValidCreateInput | null;
  Metadata_EquipmentInstitution?: PhotoNestedMetadataEquipmentinstitutionCreateInput | null;
  Metadata_EquipmentManufacturer?: PhotoNestedMetadataEquipmentmanufacturerCreateInput | null;
  Metadata_FileInfoBitsPerPixel?: PhotoNestedMetadataFileinfobitsperpixelCreateInput | null;
  Metadata_FileInfoFrames?: PhotoNestedMetadataFileinfoframesCreateInput | null;
  Metadata_FileInfoPhotometricInterpretation?: PhotoNestedMetadataFileinfophotometricinterpretationCreateInput | null;
  Metadata_FileInfoTransferSyntax?: PhotoNestedMetadataFileinfotransfersyntaxCreateInput | null;
  Metadata_PatientDOB?: PhotoNestedMetadataPatientdobCreateInput | null;
  Metadata_PatientID?: PhotoNestedMetadataPatientidCreateInput | null;
  Metadata_PatientName?: PhotoNestedMetadataPatientnameCreateInput | null;
  Metadata_PatientSex?: PhotoNestedMetadataPatientsexCreateInput | null;
  Metadata_SeriesDateTime?: PhotoNestedMetadataSeriesdatetimeCreateInput | null;
  Metadata_SeriesDescription?: PhotoNestedMetadataSeriesdescriptionCreateInput | null;
  Metadata_SeriesModality?: PhotoNestedMetadataSeriesmodalityCreateInput | null;
  Metadata_SeriesNumber?: PhotoNestedMetadataSeriesnumberCreateInput | null;
  Metadata_StudyDateTime?: PhotoNestedMetadataStudydatetimeCreateInput | null;
  Metadata_StudyDescription?: PhotoNestedMetadataStudydescriptionCreateInput | null;
  Metadata_StudyID?: PhotoNestedMetadataStudyidCreateInput | null;
  Metadata_StudyPhysician?: PhotoNestedMetadataStudyphysicianCreateInput | null;
  Metadata_DateTimeDigitized?: PhotoNestedMetadataDatetimedigitizedCreateInput | null;
  Metadata_DateTimeOriginal?: PhotoNestedMetadataDatetimeoriginalCreateInput | null;
  Metadata_GPSLatitude?: PhotoNestedMetadataGpslatitudeCreateInput | null;
  Metadata_GPSLongitude?: PhotoNestedMetadataGpslongitudeCreateInput | null;
  Metadata_GPSProcessingMethod?: PhotoNestedMetadataGpsprocessingmethodCreateInput | null;
  Metadata_ImageUniqueID?: PhotoNestedMetadataImageuniqueidCreateInput | null;
  Metadata_ISOSpeedRatings?: PhotoNestedMetadataIsospeedratingsCreateInput | null;
  Metadata_UserComment?: PhotoNestedMetadataUsercommentCreateInput | null;
  Metadata_CustomField14?: PhotoNestedMetadataCustomfield14CreateInput | null;
  Metadata_CustomField15?: PhotoNestedMetadataCustomfield15CreateInput | null;
  Metadata_CustomField16?: PhotoNestedMetadataCustomfield16CreateInput | null;
  Metadata_CustomField17?: PhotoNestedMetadataCustomfield17CreateInput | null;
  Metadata_CustomField20?: PhotoNestedMetadataCustomfield20CreateInput | null;
  Metadata_CustomField5?: PhotoNestedMetadataCustomfield5CreateInput | null;
  Metadata_CustomField6?: PhotoNestedMetadataCustomfield6CreateInput | null;
  Metadata_CustomField7?: PhotoNestedMetadataCustomfield7CreateInput | null;
  Metadata_CustomField9?: PhotoNestedMetadataCustomfield9CreateInput | null;
  Metadata_CreatedTime?: PhotoNestedMetadataCreatedtimeCreateInput | null;
  Metadata_EditStatus?: PhotoNestedMetadataEditstatusCreateInput | null;
  Metadata_FixtureIdentifier?: PhotoNestedMetadataFixtureidentifierCreateInput | null;
  Metadata_LocalCaption?: PhotoNestedMetadataLocalcaptionCreateInput | null;
  Metadata_ProgramVersion?: PhotoNestedMetadataProgramversionCreateInput | null;
  Metadata_ReferenceDate?: PhotoNestedMetadataReferencedateCreateInput | null;
  Metadata_ReferenceNumber?: PhotoNestedMetadataReferencenumberCreateInput | null;
  Metadata_ReferenceService?: PhotoNestedMetadataReferenceserviceCreateInput | null;
  Metadata_ReleaseDate?: PhotoNestedMetadataReleasedateCreateInput | null;
  Metadata_ReleaseTime?: PhotoNestedMetadataReleasetimeCreateInput | null;
  Metadata_AssetState?: PhotoNestedMetadataAssetstateCreateInput | null;
  Metadata_Classify?: PhotoNestedMetadataClassifyCreateInput | null;
  Metadata_ContainedIn?: PhotoNestedMetadataContainedinCreateInput | null;
  Metadata_ContentValue?: PhotoNestedMetadataContentvalueCreateInput | null;
  Metadata_DocumentText?: PhotoNestedMetadataDocumenttextCreateInput | null;
  Metadata_Exif?: PhotoNestedMetadataExifCreateInput | null;
  Metadata_History?: PhotoNestedMetadataHistoryCreateInput | null;
  Metadata_ImageNotes?: PhotoNestedMetadataImagenotesCreateInput | null;
  Metadata_JobId?: PhotoNestedMetadataJobidCreateInput | null;
  Metadata_Links?: PhotoNestedMetadataLinksCreateInput | null;
  Metadata_MasterDocumentId?: PhotoNestedMetadataMasterdocumentidCreateInput | null;
  Metadata_ObjectCycle?: PhotoNestedMetadataObjectcycleCreateInput | null;
  Metadata_OwnerId?: PhotoNestedMetadataOwneridCreateInput | null;
  Metadata_ShortUniqueId?: PhotoNestedMetadataShortuniqueidCreateInput | null;
  Metadata_TransferredBy?: PhotoNestedMetadataTransferredbyCreateInput | null;
  Metadata_TransferredByEmail?: PhotoNestedMetadataTransferredbyemailCreateInput | null;
  Metadata_TransferredByFullName?: PhotoNestedMetadataTransferredbyfullnameCreateInput | null;
  Metadata_UniqueId?: PhotoNestedMetadataUniqueidCreateInput | null;
  Metadata_UploadedBy?: PhotoNestedMetadataUploadedbyCreateInput | null;
  Metadata_UploadedByFullName?: PhotoNestedMetadataUploadedbyfullnameCreateInput | null;
  Metadata_UploadTime?: PhotoNestedMetadataUploadtimeCreateInput | null;
  Metadata_UserDefined195?: PhotoNestedMetadataUserdefined195CreateInput | null;
  Metadata_UserDefined237?: PhotoNestedMetadataUserdefined237CreateInput | null;
  Metadata_UserDefined238?: PhotoNestedMetadataUserdefined238CreateInput | null;
  Metadata_UserDefined239?: PhotoNestedMetadataUserdefined239CreateInput | null;
  Metadata_UserDefined242?: PhotoNestedMetadataUserdefined242CreateInput | null;
  Metadata_UserDefined62?: PhotoNestedMetadataUserdefined62CreateInput | null;
  Metadata_CiAdrCity?: PhotoNestedMetadataCiadrcityCreateInput | null;
  Metadata_CiAdrCtry?: PhotoNestedMetadataCiadrctryCreateInput | null;
  Metadata_CiAdrExtadr?: PhotoNestedMetadataCiadrextadrCreateInput | null;
  Metadata_CiAdrPcode?: PhotoNestedMetadataCiadrpcodeCreateInput | null;
  Metadata_CiAdrRegion?: PhotoNestedMetadataCiadrregionCreateInput | null;
  Metadata_CiEmailWork?: PhotoNestedMetadataCiemailworkCreateInput | null;
  Metadata_CiTelWork?: PhotoNestedMetadataCitelworkCreateInput | null;
  Metadata_CiUrlWork?: PhotoNestedMetadataCiurlworkCreateInput | null;
  Metadata_CountryCode?: PhotoNestedMetadataCountrycodeCreateInput | null;
  Metadata_IntellectualGenre?: PhotoNestedMetadataIntellectualgenreCreateInput | null;
  Metadata_Location?: PhotoNestedMetadataLocationCreateInput | null;
  Metadata_Scene?: PhotoNestedMetadataSceneCreateInput | null;
  Metadata_SubjectCode?: PhotoNestedMetadataSubjectcodeCreateInput | null;
  Metadata_AddlModelInfo?: PhotoNestedMetadataAddlmodelinfoCreateInput | null;
  Metadata_Event?: PhotoNestedMetadataEventCreateInput | null;
  Metadata_MaxAvailHeight?: PhotoNestedMetadataMaxavailheightCreateInput | null;
  Metadata_MaxAvailWidth?: PhotoNestedMetadataMaxavailwidthCreateInput | null;
  Metadata_ModelAge?: PhotoNestedMetadataModelageCreateInput | null;
  Metadata_OrganisationInImageName?: PhotoNestedMetadataOrganisationinimagenameCreateInput | null;
  Metadata_PersonInImage?: PhotoNestedMetadataPersoninimageCreateInput | null;
  Metadata_AuthorsPosition?: PhotoNestedMetadataAuthorspositionCreateInput | null;
  Metadata_CaptionWriter?: PhotoNestedMetadataCaptionwriterCreateInput | null;
  Metadata_Category?: PhotoNestedMetadataCategoryCreateInput | null;
  Metadata_City?: PhotoNestedMetadataCityCreateInput | null;
  Metadata_Country?: PhotoNestedMetadataCountryCreateInput | null;
  Metadata_Credit?: PhotoNestedMetadataCreditCreateInput | null;
  Metadata_DateCreated?: PhotoNestedMetadataDatecreatedCreateInput | null;
  Metadata_Headline?: PhotoNestedMetadataHeadlineCreateInput | null;
  Metadata_ICCProfile?: PhotoNestedMetadataIccprofileCreateInput | null;
  Metadata_Instructions?: PhotoNestedMetadataInstructionsCreateInput | null;
  Metadata_Source?: PhotoNestedMetadataSourceCreateInput | null;
  Metadata_State?: PhotoNestedMetadataStateCreateInput | null;
  Metadata_SupplementalCategories?: PhotoNestedMetadataSupplementalcategoriesCreateInput | null;
  Metadata_TransmissionReference?: PhotoNestedMetadataTransmissionreferenceCreateInput | null;
  Metadata_Urgency?: PhotoNestedMetadataUrgencyCreateInput | null;
  Metadata_MinorModelAgeDisclosure?: PhotoNestedMetadataMinormodelagedisclosureCreateInput | null;
  Metadata_ModelReleaseID?: PhotoNestedMetadataModelreleaseidCreateInput | null;
  Metadata_ModelReleaseStatus?: PhotoNestedMetadataModelreleasestatusCreateInput | null;
  Metadata_PropertyReleaseID?: PhotoNestedMetadataPropertyreleaseidCreateInput | null;
  Metadata_PropertyReleaseStatus?: PhotoNestedMetadataPropertyreleasestatusCreateInput | null;
  Metadata_Version?: PhotoNestedMetadataVersionCreateInput | null;
  Metadata_Brand?: PhotoNestedMetadataBrandCreateInput | null;
  Metadata_Division?: PhotoNestedMetadataDivisionCreateInput | null;
  Metadata_Header?: PhotoNestedMetadataHeaderCreateInput | null;
  Metadata_Products?: PhotoNestedMetadataProductsCreateInput | null;
  Metadata_Produits?: PhotoNestedMetadataProduitsCreateInput | null;
  Metadata_publishingIssue?: PhotoNestedMetadataPublishingissueCreateInput | null;
  Metadata_publishingSubject?: PhotoNestedMetadataPublishingsubjectCreateInput | null;
  Metadata_Company?: PhotoNestedMetadataCompanyCreateInput | null;
  Metadata_CompanyShortName?: PhotoNestedMetadataCompanyshortnameCreateInput | null;
  Metadata_Confidentiality?: PhotoNestedMetadataConfidentialityCreateInput | null;
  Metadata_EditorialVersion?: PhotoNestedMetadataEditorialversionCreateInput | null;
  Metadata_FileCheckFor?: PhotoNestedMetadataFilecheckforCreateInput | null;
  Metadata_FileStage?: PhotoNestedMetadataFilestageCreateInput | null;
  Metadata_FileStatus?: PhotoNestedMetadataFilestatusCreateInput | null;
  Metadata_SentTo?: PhotoNestedMetadataSenttoCreateInput | null;
  Metadata_TargetVersion?: PhotoNestedMetadataTargetversionCreateInput | null;
  Metadata_Unit?: PhotoNestedMetadataUnitCreateInput | null;
  Metadata_Archive_child?: PhotoNestedMetadataArchiveChildCreateInput | null;
  Metadata_Archive_parent?: PhotoNestedMetadataArchiveParentCreateInput | null;
  Metadata_BackupName?: PhotoNestedMetadataBackupnameCreateInput | null;
  Metadata_BitmapGrayscalePicture?: PhotoNestedMetadataBitmapgrayscalepictureCreateInput | null;
  Metadata_BrandPrisma?: PhotoNestedMetadataBrandprismaCreateInput | null;
  Metadata_BrandShortName?: PhotoNestedMetadataBrandshortnameCreateInput | null;
  Metadata_CategoryPrisma?: PhotoNestedMetadataCategoryprismaCreateInput | null;
  Metadata_ContentMediaKind?: PhotoNestedMetadataContentmediakindCreateInput | null;
  Metadata_CopyrightLayer?: PhotoNestedMetadataCopyrightlayerCreateInput | null;
  Metadata_DeliveryAccount?: PhotoNestedMetadataDeliveryaccountCreateInput | null;
  Metadata_DeliveryCompany?: PhotoNestedMetadataDeliverycompanyCreateInput | null;
  Metadata_DeliveryCopyright?: PhotoNestedMetadataDeliverycopyrightCreateInput | null;
  Metadata_DeliveryDateFolder?: PhotoNestedMetadataDeliverydatefolderCreateInput | null;
  Metadata_DeliveryDateTime?: PhotoNestedMetadataDeliverydatetimeCreateInput | null;
  Metadata_DeliveryDcCreator?: PhotoNestedMetadataDeliverydccreatorCreateInput | null;
  Metadata_DeliveryDcRights?: PhotoNestedMetadataDeliverydcrightsCreateInput | null;
  Metadata_DeliveryFileType?: PhotoNestedMetadataDeliveryfiletypeCreateInput | null;
  Metadata_DeliveryFolder?: PhotoNestedMetadataDeliveryfolderCreateInput | null;
  Metadata_DeliveryKind?: PhotoNestedMetadataDeliverykindCreateInput | null;
  Metadata_DeliveryPath?: PhotoNestedMetadataDeliverypathCreateInput | null;
  Metadata_DeliveryPersonShownintheImage?: PhotoNestedMetadataDeliverypersonshownintheimageCreateInput | null;
  Metadata_DeliveryPhotoshopCredit?: PhotoNestedMetadataDeliveryphotoshopcreditCreateInput | null;
  Metadata_DeliveryPhotoshopSource?: PhotoNestedMetadataDeliveryphotoshopsourceCreateInput | null;
  Metadata_DeliveryService?: PhotoNestedMetadataDeliveryserviceCreateInput | null;
  Metadata_DeliverySubject?: PhotoNestedMetadataDeliverysubjectCreateInput | null;
  Metadata_DeliveryUnderSubject?: PhotoNestedMetadataDeliveryundersubjectCreateInput | null;
  Metadata_Department?: PhotoNestedMetadataDepartmentCreateInput | null;
  Metadata_DigitalAssetURL?: PhotoNestedMetadataDigitalasseturlCreateInput | null;
  Metadata_Edition?: PhotoNestedMetadataEditionCreateInput | null;
  Metadata_EnvironnementPhoto?: PhotoNestedMetadataEnvironnementphotoCreateInput | null;
  Metadata_FabStorage?: PhotoNestedMetadataFabstorageCreateInput | null;
  Metadata_FileType?: PhotoNestedMetadataFiletypeCreateInput | null;
  Metadata_HeaderPrisma?: PhotoNestedMetadataHeaderprismaCreateInput | null;
  Metadata_ID_subject?: PhotoNestedMetadataIdSubjectCreateInput | null;
  Metadata_IdAssignment?: PhotoNestedMetadataIdassignmentCreateInput | null;
  Metadata_IdMediaContent?: PhotoNestedMetadataIdmediacontentCreateInput | null;
  Metadata_IdPhotoPrisma?: PhotoNestedMetadataIdphotoprismaCreateInput | null;
  Metadata_Issue?: PhotoNestedMetadataIssueCreateInput | null;
  Metadata_JobProcessing?: PhotoNestedMetadataJobprocessingCreateInput | null;
  Metadata_LayoutPictureEditing?: PhotoNestedMetadataLayoutpictureeditingCreateInput | null;
  Metadata_LayoutStorage?: PhotoNestedMetadataLayoutstorageCreateInput | null;
  Metadata_OriginalColor?: PhotoNestedMetadataOriginalcolorCreateInput | null;
  Metadata_OriginalICCProfile?: PhotoNestedMetadataOriginaliccprofileCreateInput | null;
  Metadata_OriginalName?: PhotoNestedMetadataOriginalnameCreateInput | null;
  Metadata_OriginalWeigth?: PhotoNestedMetadataOriginalweigthCreateInput | null;
  Metadata_PagePrisma?: PhotoNestedMetadataPageprismaCreateInput | null;
  Metadata_PhotoStorage?: PhotoNestedMetadataPhotostorageCreateInput | null;
  Metadata_PrepressPictureEditing?: PhotoNestedMetadataPrepresspictureeditingCreateInput | null;
  Metadata_PriceLevel?: PhotoNestedMetadataPricelevelCreateInput | null;
  Metadata_PrintingProfile?: PhotoNestedMetadataPrintingprofileCreateInput | null;
  Metadata_PrismaProduction?: PhotoNestedMetadataPrismaproductionCreateInput | null;
  Metadata_ProcessHistory?: PhotoNestedMetadataProcesshistoryCreateInput | null;
  Metadata_ProcessParameter?: PhotoNestedMetadataProcessparameterCreateInput | null;
  Metadata_ProcessStatus?: PhotoNestedMetadataProcessstatusCreateInput | null;
  Metadata_Product?: PhotoNestedMetadataProductCreateInput | null;
  Metadata_ProductShortName?: PhotoNestedMetadataProductshortnameCreateInput | null;
  Metadata_QualifiedUsePrismaBy?: PhotoNestedMetadataQualifieduseprismabyCreateInput | null;
  Metadata_QualifiedUsePrismaDate?: PhotoNestedMetadataQualifieduseprismadateCreateInput | null;
  Metadata_QualifiedUsePrismaDuration?: PhotoNestedMetadataQualifieduseprismadurationCreateInput | null;
  Metadata_QualifiedUsePrismaSupport?: PhotoNestedMetadataQualifieduseprismasupportCreateInput | null;
  Metadata_RequiredPermission?: PhotoNestedMetadataRequiredpermissionCreateInput | null;
  Metadata_ResolutionKind?: PhotoNestedMetadataResolutionkindCreateInput | null;
  Metadata_Royaltyfree?: PhotoNestedMetadataRoyaltyfreeCreateInput | null;
  Metadata_Section?: PhotoNestedMetadataSectionCreateInput | null;
  Metadata_SubjectPrisma?: PhotoNestedMetadataSubjectprismaCreateInput | null;
  Metadata_Undersubject?: PhotoNestedMetadataUndersubjectCreateInput | null;
  Metadata_UnderUnderSubject?: PhotoNestedMetadataUnderundersubjectCreateInput | null;
  Metadata_UnitShortName?: PhotoNestedMetadataUnitshortnameCreateInput | null;
  Metadata_WorkflowKind?: PhotoNestedMetadataWorkflowkindCreateInput | null;
  Metadata_Make?: PhotoNestedMetadataMakeCreateInput | null;
  Metadata_Model?: PhotoNestedMetadataModelCreateInput | null;
  Metadata_CreatorTool?: PhotoNestedMetadataCreatortoolCreateInput | null;
  Metadata_Rating?: PhotoNestedMetadataRatingCreateInput | null;
  Metadata_XMPFileStamps?: PhotoNestedMetadataXmpfilestampsCreateInput | null;
  Metadata_Manifest?: PhotoNestedMetadataManifestCreateInput | null;
  Metadata_XMPHistory?: PhotoNestedMetadataXmphistoryCreateInput | null;
  Metadata_Certificate?: PhotoNestedMetadataCertificateCreateInput | null;
  Metadata_Marked?: PhotoNestedMetadataMarkedCreateInput | null;
  Metadata_Owner?: PhotoNestedMetadataOwnerCreateInput | null;
  Metadata_UsageTerms?: PhotoNestedMetadataUsagetermsCreateInput | null;
  Metadata_WebStatement?: PhotoNestedMetadataWebstatementCreateInput | null;
}

export interface PhotoWithForcedDeliverySubjectUpdateInput {
  originalName?: string | null;
  filesize?: number | null;
  height?: number | null;
  width?: number | null;
  contentType?: string | null;
  isUploaded?: boolean | null;
  isLegacy?: boolean | null;
  archive?: PhotoNestedArchiveUpdateInput | null;
  category?: PhotoNestedCategoryUpdateInput | null;
  agency?: PhotoNestedAgencyUpdateInput | null;
  importQueue?: PhotoNestedImportQueueUpdateInput | null;
  photoMetadatas?: PhotoNestedPhotoMetadatasUpdateInput | null;
  Metadata_SerialNumber?: PhotoNestedMetadataSerialnumberUpdateInput | null;
  Metadata_attributionName?: PhotoNestedMetadataAttributionnameUpdateInput | null;
  Metadata_attributionURL?: PhotoNestedMetadataAttributionurlUpdateInput | null;
  Metadata_license?: PhotoNestedMetadataLicenseUpdateInput | null;
  Metadata_morePermissions?: PhotoNestedMetadataMorepermissionsUpdateInput | null;
  Metadata_PaintBasedCorrections?: PhotoNestedMetadataPaintbasedcorrectionsUpdateInput | null;
  Metadata_ToneCurve?: PhotoNestedMetadataTonecurveUpdateInput | null;
  Metadata_creator?: PhotoNestedMetadataCreatorUpdateInput | null;
  Metadata_description?: PhotoNestedMetadataDescriptionUpdateInput | null;
  Metadata_Format?: PhotoNestedMetadataFormatUpdateInput | null;
  Metadata_Page?: PhotoNestedMetadataPageUpdateInput | null;
  Metadata_Publisher?: PhotoNestedMetadataPublisherUpdateInput | null;
  Metadata_rights?: PhotoNestedMetadataRightsUpdateInput | null;
  Metadata_subject?: PhotoNestedMetadataSubjectUpdateInput | null;
  Metadata_title?: PhotoNestedMetadataTitleUpdateInput | null;
  Metadata_Type?: PhotoNestedMetadataTypeUpdateInput | null;
  Metadata_AccessRights?: PhotoNestedMetadataAccessrightsUpdateInput | null;
  Metadata_Audience?: PhotoNestedMetadataAudienceUpdateInput | null;
  Metadata_Valid?: PhotoNestedMetadataValidUpdateInput | null;
  Metadata_EquipmentInstitution?: PhotoNestedMetadataEquipmentinstitutionUpdateInput | null;
  Metadata_EquipmentManufacturer?: PhotoNestedMetadataEquipmentmanufacturerUpdateInput | null;
  Metadata_FileInfoBitsPerPixel?: PhotoNestedMetadataFileinfobitsperpixelUpdateInput | null;
  Metadata_FileInfoFrames?: PhotoNestedMetadataFileinfoframesUpdateInput | null;
  Metadata_FileInfoPhotometricInterpretation?: PhotoNestedMetadataFileinfophotometricinterpretationUpdateInput | null;
  Metadata_FileInfoTransferSyntax?: PhotoNestedMetadataFileinfotransfersyntaxUpdateInput | null;
  Metadata_PatientDOB?: PhotoNestedMetadataPatientdobUpdateInput | null;
  Metadata_PatientID?: PhotoNestedMetadataPatientidUpdateInput | null;
  Metadata_PatientName?: PhotoNestedMetadataPatientnameUpdateInput | null;
  Metadata_PatientSex?: PhotoNestedMetadataPatientsexUpdateInput | null;
  Metadata_SeriesDateTime?: PhotoNestedMetadataSeriesdatetimeUpdateInput | null;
  Metadata_SeriesDescription?: PhotoNestedMetadataSeriesdescriptionUpdateInput | null;
  Metadata_SeriesModality?: PhotoNestedMetadataSeriesmodalityUpdateInput | null;
  Metadata_SeriesNumber?: PhotoNestedMetadataSeriesnumberUpdateInput | null;
  Metadata_StudyDateTime?: PhotoNestedMetadataStudydatetimeUpdateInput | null;
  Metadata_StudyDescription?: PhotoNestedMetadataStudydescriptionUpdateInput | null;
  Metadata_StudyID?: PhotoNestedMetadataStudyidUpdateInput | null;
  Metadata_StudyPhysician?: PhotoNestedMetadataStudyphysicianUpdateInput | null;
  Metadata_DateTimeDigitized?: PhotoNestedMetadataDatetimedigitizedUpdateInput | null;
  Metadata_DateTimeOriginal?: PhotoNestedMetadataDatetimeoriginalUpdateInput | null;
  Metadata_GPSLatitude?: PhotoNestedMetadataGpslatitudeUpdateInput | null;
  Metadata_GPSLongitude?: PhotoNestedMetadataGpslongitudeUpdateInput | null;
  Metadata_GPSProcessingMethod?: PhotoNestedMetadataGpsprocessingmethodUpdateInput | null;
  Metadata_ImageUniqueID?: PhotoNestedMetadataImageuniqueidUpdateInput | null;
  Metadata_ISOSpeedRatings?: PhotoNestedMetadataIsospeedratingsUpdateInput | null;
  Metadata_UserComment?: PhotoNestedMetadataUsercommentUpdateInput | null;
  Metadata_CustomField14?: PhotoNestedMetadataCustomfield14UpdateInput | null;
  Metadata_CustomField15?: PhotoNestedMetadataCustomfield15UpdateInput | null;
  Metadata_CustomField16?: PhotoNestedMetadataCustomfield16UpdateInput | null;
  Metadata_CustomField17?: PhotoNestedMetadataCustomfield17UpdateInput | null;
  Metadata_CustomField20?: PhotoNestedMetadataCustomfield20UpdateInput | null;
  Metadata_CustomField5?: PhotoNestedMetadataCustomfield5UpdateInput | null;
  Metadata_CustomField6?: PhotoNestedMetadataCustomfield6UpdateInput | null;
  Metadata_CustomField7?: PhotoNestedMetadataCustomfield7UpdateInput | null;
  Metadata_CustomField9?: PhotoNestedMetadataCustomfield9UpdateInput | null;
  Metadata_CreatedTime?: PhotoNestedMetadataCreatedtimeUpdateInput | null;
  Metadata_EditStatus?: PhotoNestedMetadataEditstatusUpdateInput | null;
  Metadata_FixtureIdentifier?: PhotoNestedMetadataFixtureidentifierUpdateInput | null;
  Metadata_LocalCaption?: PhotoNestedMetadataLocalcaptionUpdateInput | null;
  Metadata_ProgramVersion?: PhotoNestedMetadataProgramversionUpdateInput | null;
  Metadata_ReferenceDate?: PhotoNestedMetadataReferencedateUpdateInput | null;
  Metadata_ReferenceNumber?: PhotoNestedMetadataReferencenumberUpdateInput | null;
  Metadata_ReferenceService?: PhotoNestedMetadataReferenceserviceUpdateInput | null;
  Metadata_ReleaseDate?: PhotoNestedMetadataReleasedateUpdateInput | null;
  Metadata_ReleaseTime?: PhotoNestedMetadataReleasetimeUpdateInput | null;
  Metadata_AssetState?: PhotoNestedMetadataAssetstateUpdateInput | null;
  Metadata_Classify?: PhotoNestedMetadataClassifyUpdateInput | null;
  Metadata_ContainedIn?: PhotoNestedMetadataContainedinUpdateInput | null;
  Metadata_ContentValue?: PhotoNestedMetadataContentvalueUpdateInput | null;
  Metadata_DocumentText?: PhotoNestedMetadataDocumenttextUpdateInput | null;
  Metadata_Exif?: PhotoNestedMetadataExifUpdateInput | null;
  Metadata_History?: PhotoNestedMetadataHistoryUpdateInput | null;
  Metadata_ImageNotes?: PhotoNestedMetadataImagenotesUpdateInput | null;
  Metadata_JobId?: PhotoNestedMetadataJobidUpdateInput | null;
  Metadata_Links?: PhotoNestedMetadataLinksUpdateInput | null;
  Metadata_MasterDocumentId?: PhotoNestedMetadataMasterdocumentidUpdateInput | null;
  Metadata_ObjectCycle?: PhotoNestedMetadataObjectcycleUpdateInput | null;
  Metadata_OwnerId?: PhotoNestedMetadataOwneridUpdateInput | null;
  Metadata_ShortUniqueId?: PhotoNestedMetadataShortuniqueidUpdateInput | null;
  Metadata_TransferredBy?: PhotoNestedMetadataTransferredbyUpdateInput | null;
  Metadata_TransferredByEmail?: PhotoNestedMetadataTransferredbyemailUpdateInput | null;
  Metadata_TransferredByFullName?: PhotoNestedMetadataTransferredbyfullnameUpdateInput | null;
  Metadata_UniqueId?: PhotoNestedMetadataUniqueidUpdateInput | null;
  Metadata_UploadedBy?: PhotoNestedMetadataUploadedbyUpdateInput | null;
  Metadata_UploadedByFullName?: PhotoNestedMetadataUploadedbyfullnameUpdateInput | null;
  Metadata_UploadTime?: PhotoNestedMetadataUploadtimeUpdateInput | null;
  Metadata_UserDefined195?: PhotoNestedMetadataUserdefined195UpdateInput | null;
  Metadata_UserDefined237?: PhotoNestedMetadataUserdefined237UpdateInput | null;
  Metadata_UserDefined238?: PhotoNestedMetadataUserdefined238UpdateInput | null;
  Metadata_UserDefined239?: PhotoNestedMetadataUserdefined239UpdateInput | null;
  Metadata_UserDefined242?: PhotoNestedMetadataUserdefined242UpdateInput | null;
  Metadata_UserDefined62?: PhotoNestedMetadataUserdefined62UpdateInput | null;
  Metadata_CiAdrCity?: PhotoNestedMetadataCiadrcityUpdateInput | null;
  Metadata_CiAdrCtry?: PhotoNestedMetadataCiadrctryUpdateInput | null;
  Metadata_CiAdrExtadr?: PhotoNestedMetadataCiadrextadrUpdateInput | null;
  Metadata_CiAdrPcode?: PhotoNestedMetadataCiadrpcodeUpdateInput | null;
  Metadata_CiAdrRegion?: PhotoNestedMetadataCiadrregionUpdateInput | null;
  Metadata_CiEmailWork?: PhotoNestedMetadataCiemailworkUpdateInput | null;
  Metadata_CiTelWork?: PhotoNestedMetadataCitelworkUpdateInput | null;
  Metadata_CiUrlWork?: PhotoNestedMetadataCiurlworkUpdateInput | null;
  Metadata_CountryCode?: PhotoNestedMetadataCountrycodeUpdateInput | null;
  Metadata_IntellectualGenre?: PhotoNestedMetadataIntellectualgenreUpdateInput | null;
  Metadata_Location?: PhotoNestedMetadataLocationUpdateInput | null;
  Metadata_Scene?: PhotoNestedMetadataSceneUpdateInput | null;
  Metadata_SubjectCode?: PhotoNestedMetadataSubjectcodeUpdateInput | null;
  Metadata_AddlModelInfo?: PhotoNestedMetadataAddlmodelinfoUpdateInput | null;
  Metadata_Event?: PhotoNestedMetadataEventUpdateInput | null;
  Metadata_MaxAvailHeight?: PhotoNestedMetadataMaxavailheightUpdateInput | null;
  Metadata_MaxAvailWidth?: PhotoNestedMetadataMaxavailwidthUpdateInput | null;
  Metadata_ModelAge?: PhotoNestedMetadataModelageUpdateInput | null;
  Metadata_OrganisationInImageName?: PhotoNestedMetadataOrganisationinimagenameUpdateInput | null;
  Metadata_PersonInImage?: PhotoNestedMetadataPersoninimageUpdateInput | null;
  Metadata_AuthorsPosition?: PhotoNestedMetadataAuthorspositionUpdateInput | null;
  Metadata_CaptionWriter?: PhotoNestedMetadataCaptionwriterUpdateInput | null;
  Metadata_Category?: PhotoNestedMetadataCategoryUpdateInput | null;
  Metadata_City?: PhotoNestedMetadataCityUpdateInput | null;
  Metadata_Country?: PhotoNestedMetadataCountryUpdateInput | null;
  Metadata_Credit?: PhotoNestedMetadataCreditUpdateInput | null;
  Metadata_DateCreated?: PhotoNestedMetadataDatecreatedUpdateInput | null;
  Metadata_Headline?: PhotoNestedMetadataHeadlineUpdateInput | null;
  Metadata_ICCProfile?: PhotoNestedMetadataIccprofileUpdateInput | null;
  Metadata_Instructions?: PhotoNestedMetadataInstructionsUpdateInput | null;
  Metadata_Source?: PhotoNestedMetadataSourceUpdateInput | null;
  Metadata_State?: PhotoNestedMetadataStateUpdateInput | null;
  Metadata_SupplementalCategories?: PhotoNestedMetadataSupplementalcategoriesUpdateInput | null;
  Metadata_TransmissionReference?: PhotoNestedMetadataTransmissionreferenceUpdateInput | null;
  Metadata_Urgency?: PhotoNestedMetadataUrgencyUpdateInput | null;
  Metadata_MinorModelAgeDisclosure?: PhotoNestedMetadataMinormodelagedisclosureUpdateInput | null;
  Metadata_ModelReleaseID?: PhotoNestedMetadataModelreleaseidUpdateInput | null;
  Metadata_ModelReleaseStatus?: PhotoNestedMetadataModelreleasestatusUpdateInput | null;
  Metadata_PropertyReleaseID?: PhotoNestedMetadataPropertyreleaseidUpdateInput | null;
  Metadata_PropertyReleaseStatus?: PhotoNestedMetadataPropertyreleasestatusUpdateInput | null;
  Metadata_Version?: PhotoNestedMetadataVersionUpdateInput | null;
  Metadata_Brand?: PhotoNestedMetadataBrandUpdateInput | null;
  Metadata_Division?: PhotoNestedMetadataDivisionUpdateInput | null;
  Metadata_Header?: PhotoNestedMetadataHeaderUpdateInput | null;
  Metadata_Products?: PhotoNestedMetadataProductsUpdateInput | null;
  Metadata_Produits?: PhotoNestedMetadataProduitsUpdateInput | null;
  Metadata_publishingIssue?: PhotoNestedMetadataPublishingissueUpdateInput | null;
  Metadata_publishingSubject?: PhotoNestedMetadataPublishingsubjectUpdateInput | null;
  Metadata_Company?: PhotoNestedMetadataCompanyUpdateInput | null;
  Metadata_CompanyShortName?: PhotoNestedMetadataCompanyshortnameUpdateInput | null;
  Metadata_Confidentiality?: PhotoNestedMetadataConfidentialityUpdateInput | null;
  Metadata_EditorialVersion?: PhotoNestedMetadataEditorialversionUpdateInput | null;
  Metadata_FileCheckFor?: PhotoNestedMetadataFilecheckforUpdateInput | null;
  Metadata_FileStage?: PhotoNestedMetadataFilestageUpdateInput | null;
  Metadata_FileStatus?: PhotoNestedMetadataFilestatusUpdateInput | null;
  Metadata_SentTo?: PhotoNestedMetadataSenttoUpdateInput | null;
  Metadata_TargetVersion?: PhotoNestedMetadataTargetversionUpdateInput | null;
  Metadata_Unit?: PhotoNestedMetadataUnitUpdateInput | null;
  Metadata_Archive_child?: PhotoNestedMetadataArchiveChildUpdateInput | null;
  Metadata_Archive_parent?: PhotoNestedMetadataArchiveParentUpdateInput | null;
  Metadata_BackupName?: PhotoNestedMetadataBackupnameUpdateInput | null;
  Metadata_BitmapGrayscalePicture?: PhotoNestedMetadataBitmapgrayscalepictureUpdateInput | null;
  Metadata_BrandPrisma?: PhotoNestedMetadataBrandprismaUpdateInput | null;
  Metadata_BrandShortName?: PhotoNestedMetadataBrandshortnameUpdateInput | null;
  Metadata_CategoryPrisma?: PhotoNestedMetadataCategoryprismaUpdateInput | null;
  Metadata_ContentMediaKind?: PhotoNestedMetadataContentmediakindUpdateInput | null;
  Metadata_CopyrightLayer?: PhotoNestedMetadataCopyrightlayerUpdateInput | null;
  Metadata_DeliveryAccount?: PhotoNestedMetadataDeliveryaccountUpdateInput | null;
  Metadata_DeliveryCompany?: PhotoNestedMetadataDeliverycompanyUpdateInput | null;
  Metadata_DeliveryCopyright?: PhotoNestedMetadataDeliverycopyrightUpdateInput | null;
  Metadata_DeliveryDateFolder?: PhotoNestedMetadataDeliverydatefolderUpdateInput | null;
  Metadata_DeliveryDateTime?: PhotoNestedMetadataDeliverydatetimeUpdateInput | null;
  Metadata_DeliveryDcCreator?: PhotoNestedMetadataDeliverydccreatorUpdateInput | null;
  Metadata_DeliveryDcRights?: PhotoNestedMetadataDeliverydcrightsUpdateInput | null;
  Metadata_DeliveryFileType?: PhotoNestedMetadataDeliveryfiletypeUpdateInput | null;
  Metadata_DeliveryFolder?: PhotoNestedMetadataDeliveryfolderUpdateInput | null;
  Metadata_DeliveryKind?: PhotoNestedMetadataDeliverykindUpdateInput | null;
  Metadata_DeliveryPath?: PhotoNestedMetadataDeliverypathUpdateInput | null;
  Metadata_DeliveryPersonShownintheImage?: PhotoNestedMetadataDeliverypersonshownintheimageUpdateInput | null;
  Metadata_DeliveryPhotoshopCredit?: PhotoNestedMetadataDeliveryphotoshopcreditUpdateInput | null;
  Metadata_DeliveryPhotoshopSource?: PhotoNestedMetadataDeliveryphotoshopsourceUpdateInput | null;
  Metadata_DeliveryService?: PhotoNestedMetadataDeliveryserviceUpdateInput | null;
  Metadata_DeliverySubject?: PhotoNestedMetadataDeliverysubjectUpdateInput | null;
  Metadata_DeliveryUnderSubject?: PhotoNestedMetadataDeliveryundersubjectUpdateInput | null;
  Metadata_Department?: PhotoNestedMetadataDepartmentUpdateInput | null;
  Metadata_DigitalAssetURL?: PhotoNestedMetadataDigitalasseturlUpdateInput | null;
  Metadata_Edition?: PhotoNestedMetadataEditionUpdateInput | null;
  Metadata_EnvironnementPhoto?: PhotoNestedMetadataEnvironnementphotoUpdateInput | null;
  Metadata_FabStorage?: PhotoNestedMetadataFabstorageUpdateInput | null;
  Metadata_FileType?: PhotoNestedMetadataFiletypeUpdateInput | null;
  Metadata_HeaderPrisma?: PhotoNestedMetadataHeaderprismaUpdateInput | null;
  Metadata_ID_subject?: PhotoNestedMetadataIdSubjectUpdateInput | null;
  Metadata_IdAssignment?: PhotoNestedMetadataIdassignmentUpdateInput | null;
  Metadata_IdMediaContent?: PhotoNestedMetadataIdmediacontentUpdateInput | null;
  Metadata_IdPhotoPrisma?: PhotoNestedMetadataIdphotoprismaUpdateInput | null;
  Metadata_Issue?: PhotoNestedMetadataIssueUpdateInput | null;
  Metadata_JobProcessing?: PhotoNestedMetadataJobprocessingUpdateInput | null;
  Metadata_LayoutPictureEditing?: PhotoNestedMetadataLayoutpictureeditingUpdateInput | null;
  Metadata_LayoutStorage?: PhotoNestedMetadataLayoutstorageUpdateInput | null;
  Metadata_OriginalColor?: PhotoNestedMetadataOriginalcolorUpdateInput | null;
  Metadata_OriginalICCProfile?: PhotoNestedMetadataOriginaliccprofileUpdateInput | null;
  Metadata_OriginalName?: PhotoNestedMetadataOriginalnameUpdateInput | null;
  Metadata_OriginalWeigth?: PhotoNestedMetadataOriginalweigthUpdateInput | null;
  Metadata_PagePrisma?: PhotoNestedMetadataPageprismaUpdateInput | null;
  Metadata_PhotoStorage?: PhotoNestedMetadataPhotostorageUpdateInput | null;
  Metadata_PrepressPictureEditing?: PhotoNestedMetadataPrepresspictureeditingUpdateInput | null;
  Metadata_PriceLevel?: PhotoNestedMetadataPricelevelUpdateInput | null;
  Metadata_PrintingProfile?: PhotoNestedMetadataPrintingprofileUpdateInput | null;
  Metadata_PrismaProduction?: PhotoNestedMetadataPrismaproductionUpdateInput | null;
  Metadata_ProcessHistory?: PhotoNestedMetadataProcesshistoryUpdateInput | null;
  Metadata_ProcessParameter?: PhotoNestedMetadataProcessparameterUpdateInput | null;
  Metadata_ProcessStatus?: PhotoNestedMetadataProcessstatusUpdateInput | null;
  Metadata_Product?: PhotoNestedMetadataProductUpdateInput | null;
  Metadata_ProductShortName?: PhotoNestedMetadataProductshortnameUpdateInput | null;
  Metadata_QualifiedUsePrismaBy?: PhotoNestedMetadataQualifieduseprismabyUpdateInput | null;
  Metadata_QualifiedUsePrismaDate?: PhotoNestedMetadataQualifieduseprismadateUpdateInput | null;
  Metadata_QualifiedUsePrismaDuration?: PhotoNestedMetadataQualifieduseprismadurationUpdateInput | null;
  Metadata_QualifiedUsePrismaSupport?: PhotoNestedMetadataQualifieduseprismasupportUpdateInput | null;
  Metadata_RequiredPermission?: PhotoNestedMetadataRequiredpermissionUpdateInput | null;
  Metadata_ResolutionKind?: PhotoNestedMetadataResolutionkindUpdateInput | null;
  Metadata_Royaltyfree?: PhotoNestedMetadataRoyaltyfreeUpdateInput | null;
  Metadata_Section?: PhotoNestedMetadataSectionUpdateInput | null;
  Metadata_SubjectPrisma?: PhotoNestedMetadataSubjectprismaUpdateInput | null;
  Metadata_Undersubject?: PhotoNestedMetadataUndersubjectUpdateInput | null;
  Metadata_UnderUnderSubject?: PhotoNestedMetadataUnderundersubjectUpdateInput | null;
  Metadata_UnitShortName?: PhotoNestedMetadataUnitshortnameUpdateInput | null;
  Metadata_WorkflowKind?: PhotoNestedMetadataWorkflowkindUpdateInput | null;
  Metadata_Make?: PhotoNestedMetadataMakeUpdateInput | null;
  Metadata_Model?: PhotoNestedMetadataModelUpdateInput | null;
  Metadata_CreatorTool?: PhotoNestedMetadataCreatortoolUpdateInput | null;
  Metadata_Rating?: PhotoNestedMetadataRatingUpdateInput | null;
  Metadata_XMPFileStamps?: PhotoNestedMetadataXmpfilestampsUpdateInput | null;
  Metadata_Manifest?: PhotoNestedMetadataManifestUpdateInput | null;
  Metadata_XMPHistory?: PhotoNestedMetadataXmphistoryUpdateInput | null;
  Metadata_Certificate?: PhotoNestedMetadataCertificateUpdateInput | null;
  Metadata_Marked?: PhotoNestedMetadataMarkedUpdateInput | null;
  Metadata_Owner?: PhotoNestedMetadataOwnerUpdateInput | null;
  Metadata_UsageTerms?: PhotoNestedMetadataUsagetermsUpdateInput | null;
  Metadata_WebStatement?: PhotoNestedMetadataWebstatementUpdateInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
