import { PersonAdd } from '@mui/icons-material';
import { IconButton, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useDialog } from '@prismamedia/one-components';
import React from 'react';
import { GetSubject_subject_subjectUsers } from '../../../__generated__/queries-topic';
import { Avatar } from '../../../components/Avatar';
import { ShareSubjectDialog } from '../../../components/ShareDialog/ShareSubjectDialog';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      '& > :first-child': {
        marginRight: 10,
      },
      '& > :not(:first-child):not(:last-child)': {
        transition: 'all 0.25s ease-out',
        marginRight: -25,
      },
      '&:hover > :not(:first-child):not(:last-child)': {
        marginRight: 5,
      },
    },
    addUser: {
      backgroundColor: theme.palette.common.white,
      width: 40,
      height: 40,
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
      boxShadow: `1px 2px 7px ${theme.palette.common.black}`,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    icon: {
      marginTop: '-4px',
    },
  });

type UsersListProps = {
  subjectId: string;
  confidentialUsersList: GetSubject_subject_subjectUsers[];
} & WithStyles<typeof styles>;

const UsersListWithoutStyle: React.FC<UsersListProps> = ({
  classes,
  subjectId,
  confidentialUsersList,
}) => {
  const { openDialog, closeDialog } = useDialog();

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.addUser}
        onClick={() => {
          openDialog(
            <ShareSubjectDialog
              subjectId={subjectId}
              closeDialog={closeDialog}
            />,
          );
        }}
        size="large"
      >
        <PersonAdd color="primary" className={classes.icon} />
      </IconButton>
      {confidentialUsersList.map(
        (user: GetSubject_subject_subjectUsers, i: number) => (
          <Avatar
            avatarUrl={user?.user?.avatarUrl || ''}
            tooltip={user?.user?.name}
            key={i}
          />
        ),
      )}
    </div>
  );
};

export const UsersList = withStyles(styles)(UsersListWithoutStyle);
