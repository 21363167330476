import CheckIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import DownloadIcon from '@mui/icons-material/CloudDownloadTwoTone';
import DeleteIcon from '@mui/icons-material/DeleteForeverTwoTone';
import PreviewIcon from '@mui/icons-material/ImageSearchTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { GetPrintHeading_printHeading_printTemplates } from '../../../__generated__/queries-topic';
import {
  useActivatePrintTemplate,
  useGetDownloadUrl,
} from '../../../apollo/queries/printTemplate.topic.graphql';
import IDSNIcon from '../../../assets/svg/idsn.svg';
import {
  CircularLoader,
  LoaderSizes,
} from '../../../components/Loader/Circular';
import { getSize } from '../../../utils/getSize';
import { useCommonStyles } from '../common.styles';
import { DetachPrintTemplateDialog } from '../DetachDialog';
import { DetailsPrintTemplateDialog } from '../DetailsDialog';
import { EditPrintTemplateDialog } from '../EditDialog';
import { PreviewPagesDialog } from '../PreviewDialog';
import { ViewVariant } from '../utils';
import { useStyles } from './style';

interface TemplateItemProps {
  printHeadingPrintTemplate: GetPrintHeading_printHeading_printTemplates;
  viewVariant: ViewVariant;
}

type DialogTypes = 'delete' | 'details' | 'edit' | 'preview';

export const TemplateItem: React.FC<TemplateItemProps> = ({
  printHeadingPrintTemplate: { id, active, printTemplate },
  viewVariant,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [menuRef, setMenuRef] = useState<any>(undefined);
  const { pushNotification } = useNotification();
  const [isDownloading, setIsDownloading] = useState(false);
  const [openedDialog, openDialog] = useState<DialogTypes | null>(null);
  const getDownloadUrl = useGetDownloadUrl(printTemplate.id);
  const activatePrintTemplate = useActivatePrintTemplate();

  const handleDownload = async () => {
    setIsDownloading(true);
    const { data, errors } = await getDownloadUrl();

    if (errors || !data) {
      pushNotification({
        message:
          'Un problème est survenu lors de la génération URL du téléchargement.',
        type: NotificationTypeEnum.error,
      });
      return;
    }

    const anchor = document.createElement('a');
    // eslint-disable-next-line immutable/no-mutation
    anchor.href = data.printTemplateDownload.url;
    // eslint-disable-next-line immutable/no-mutation
    anchor.download = printTemplate.filename || printTemplate.label;
    document.body.appendChild(anchor);

    anchor.click();

    setTimeout(() => anchor.remove());
    setIsDownloading(false);
  };

  const handleMenuAction = useCallback(
    (action?: 'activate' | 'edit' | 'details' | 'delete') => async () => {
      try {
        switch (action) {
          case 'activate': {
            const { errors } = await activatePrintTemplate(id);
            if (errors?.length) {
              throw Error(
                `Un problème est survenu lors de l'activation du gabarit ${printTemplate.label}`,
              );
            }

            pushNotification({
              message: `Le gabarit ${printTemplate.label} a été activé avec succès.`,
              type: NotificationTypeEnum.success,
            });
            break;
          }
          case 'edit':
          case 'details':
          case 'delete': {
            openDialog(action);
            break;
          }
        }
      } catch (err) {
        pushNotification({
          message: (err as { message: string }).message,
          type: NotificationTypeEnum.error,
        });
      }
      setMenuRef(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [printTemplate],
  );

  const handleMenuToggle = (evt?: any) =>
    setMenuRef((ref: any) => (ref ? undefined : evt.currentTarget));
  const handleDialogOnClose = () => openDialog(null);
  const handleDialogOpen = (dialog: DialogTypes) => () => openDialog(dialog);
  const autoFieldsCount = printTemplate.fields.length;
  const { size, unit } = getSize(printTemplate.filesize || 0);
  const isListView = viewVariant === 'list';

  const renderActiveStatus = () =>
    active && (
      <div className={classes.statusLabel}>
        <CheckIcon />
        <Typography variant="body2" component="p">
          Activé
        </Typography>
      </div>
    );

  const renderDownloadButton = () => (
    <IconButton
      aria-label="download"
      onClick={handleDownload}
      disabled={isDownloading}
      size="large"
    >
      {isDownloading ? (
        <CircularLoader size={LoaderSizes.xsmall} />
      ) : (
        <DownloadIcon />
      )}
    </IconButton>
  );

  const renderDeleteButton = () => (
    <IconButton
      aria-label="delete"
      disabled={!!active}
      onClick={handleDialogOpen('delete')}
      size="large"
    >
      <DeleteIcon />
    </IconButton>
  );

  const renderPreviewButton = () => (
    <IconButton
      aria-label="preview"
      disabled={!printTemplate.pageCount}
      onClick={handleDialogOpen('preview')}
      size="large"
    >
      <PreviewIcon />
    </IconButton>
  );

  return (
    <>
      <Card className={clsx(classes.container, isListView && classes.list)}>
        <CardHeader
          className={classes.header}
          classes={{ action: classes.headerAction }}
          avatar={
            <Avatar
              src={IDSNIcon}
              className={clsx(active && classes.templateItemActive)}
            />
          }
          action={
            <>
              {isListView && renderActiveStatus()}
              {isListView && renderDeleteButton()}
              {isListView && renderPreviewButton()}
              {isListView && renderDownloadButton()}
              <IconButton
                aria-label="settings"
                onClick={handleMenuToggle}
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
            </>
          }
          title={printTemplate.label}
          subheader={`${autoFieldsCount} champ(s) automatisé(s)`}
        />
        <CardContent className={classes.contentContainer}>
          <Table size="small" className={commonClasses.tableInfo}>
            <TableBody>
              <TableRow>
                <TableCell>Nom fichier</TableCell>
                <TableCell align="right">{printTemplate.filename}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell align="right">{printTemplate.type}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Taille</TableCell>
                <TableCell align="right">
                  {size}
                  {unit}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Créateur</TableCell>
                <TableCell align="right">
                  {(printTemplate.createdBy && printTemplate.createdBy.name) ||
                    'N/A'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date création</TableCell>
                <TableCell align="right">
                  {new Date(printTemplate.createdAt).toLocaleString('fr')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date m-à-j</TableCell>
                <TableCell align="right">
                  {new Date(printTemplate.updatedAt).toLocaleString('fr')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
        <CardActions disableSpacing className={classes.actionsContainer}>
          {renderDownloadButton()}
          {renderPreviewButton()}
          {renderDeleteButton()}
          {renderActiveStatus()}
        </CardActions>
      </Card>
      <DetachPrintTemplateDialog
        open={openedDialog === 'delete'}
        printHeadingPrintTemplateId={id}
        printTemplate={printTemplate}
        onClose={handleDialogOnClose}
      />
      <EditPrintTemplateDialog
        open={openedDialog === 'edit'}
        printTemplate={printTemplate}
        onClose={handleDialogOnClose}
      />
      <DetailsPrintTemplateDialog
        open={openedDialog === 'details'}
        printTemplate={printTemplate}
        active={!!active}
        onClose={handleDialogOnClose}
      />
      {openedDialog === 'preview' && (
        <PreviewPagesDialog
          open={openedDialog === 'preview'}
          printTemplate={printTemplate}
          onClose={handleDialogOnClose}
        />
      )}
      <Menu
        id="options-menu"
        anchorEl={menuRef}
        keepMounted
        open={!!menuRef}
        onClose={handleMenuToggle}
      >
        <MenuItem
          onClick={handleMenuAction('edit')}
          className={classes.optionsMenuItem}
        >
          Editer Label
        </MenuItem>
        <MenuItem
          onClick={handleMenuAction('activate')}
          className={classes.optionsMenuItem}
          disabled={!!active}
        >
          Activer
        </MenuItem>
        <MenuItem
          onClick={handleMenuAction('details')}
          className={classes.optionsMenuItem}
        >
          Détails
        </MenuItem>
      </Menu>
    </>
  );
};
