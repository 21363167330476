import { ApolloProvider } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from '@mui/styles';
import {
  DialogDisplay,
  DialogProvider,
  GoogleAuthProvider,
  MenuDisplay,
  MenuProvider,
  NotificationDisplay,
  NotificationProvider,
  UpdateNotification,
  useOnMount,
} from '@prismamedia/one-components';
import { Head } from 'components/Head';
import frLocale from 'date-fns/locale/fr';
import moment from 'moment';
import 'moment/locale/fr';
import { FC } from 'react';
import { initialize } from 'react-ga';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { QueryParamProvider } from 'use-query-params';
import { getRemoteVersion } from 'utils/version';
import { client } from './apollo';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Routing } from './routing';
import { useStyles } from './styles';
import { theme } from './theme';

// globaly configure locale for momentjs
moment.locale('fr');

initialize(config.GA_ID, {
  testMode: process.env.NODE_ENV === 'test',
});

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useDialogStyles = makeStyles({
  paper: {
    width: '100%',
  },
});

const dialogProps = {
  maxWidth: 'sm' as const,
};

export const App: FC = () => {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();

  useOnMount(() => {
    if (process.env.NODE_ENV !== 'development') {
      (async () => {
        const remoteVersion = await getRemoteVersion();

        datadogRum.init({
          applicationId: '3c911508-c03a-4837-a6d2-bf2d8fb854ee',
          clientToken: 'pubc80719df30e119fb5995056a66dd7055',
          defaultPrivacyLevel: 'allow',
          env: config.ENV,
          sampleRate: 100,
          service: 'one-story',
          site: 'datadoghq.eu',
          trackInteractions: true,
          trackViewsManually: true,
          version: remoteVersion,
        });
      })();
    }
  });

  return (
    <GoogleAuthProvider>
      <RecoilRoot>
        <div className={`${classes.printPortalWrapper} print-portal-wrapper`} />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline>
              <div className={classes.appContainer}>
                <ErrorBoundary>
                  <ApolloProvider client={client}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={frLocale}
                    >
                      <HelmetProvider>
                        <BrowserRouter>
                          <QueryParamProvider ReactRouterRoute={Route}>
                            <Head />
                            <NotificationProvider>
                              <MenuProvider>
                                <DialogProvider
                                  {...dialogProps}
                                  classes={dialogClasses}
                                >
                                  <Routing />
                                  <DialogDisplay />
                                  <MenuDisplay />
                                  <NotificationDisplay />
                                  <UpdateNotification />
                                </DialogProvider>
                              </MenuProvider>
                              <div id="modal-root" />
                            </NotificationProvider>
                          </QueryParamProvider>
                        </BrowserRouter>
                      </HelmetProvider>
                    </LocalizationProvider>
                  </ApolloProvider>
                </ErrorBoundary>
              </div>
            </CssBaseline>
          </ThemeProvider>
        </StyledEngineProvider>
      </RecoilRoot>
    </GoogleAuthProvider>
  );
};
