/* eslint-disable fp/no-this */
import React from 'react';
import { SubjectListPage_GetCategoryTree } from '../../../../__generated__/queries-topic';
import {
  checkboxStatus,
  mapFromTree,
  NodeMap,
  TreeList,
  TreeNodeRef,
} from '../../../../components/TreeList';

interface CategoryTreeProps {
  timeout: 0 | 'auto';
  treeList: SubjectListPage_GetCategoryTree;
  selected: string[];
  onChange: (ids: string[]) => void;
}

interface CategoryTreeState {
  locationNodeMap: NodeMap;
}

// eslint-disable-next-line fp/no-class
export class CategoryTree extends React.Component<
  CategoryTreeProps,
  CategoryTreeState
> {
  state = {
    locationNodeMap: mapFromTree(),
  };

  private timeout = 0;

  componentDidMount() {
    if (this.props.treeList && this.props.treeList.subjectCategories) {
      this.updateCategoryTreeState();
    }
  }

  componentDidUpdate(prevProps: CategoryTreeProps) {
    if (
      (prevProps.treeList &&
        prevProps.treeList.subjectCategories !==
          this.props.treeList.subjectCategories) ||
      (prevProps.selected && prevProps.selected !== this.props.selected)
    ) {
      this.updateCategoryTreeState();
    }
  }

  updateCategoryTreeState() {
    const getCheckedStatus = (id: string) => {
      return this.props.selected.includes(id)
        ? checkboxStatus.checked
        : checkboxStatus.unchecked;
    };

    const treeList = this.props.treeList.subjectCategories
      .filter((category) => category.children.length > 0)
      .map((category) => ({
        id: category.id,
        title: category.title,
        checked: getCheckedStatus(category.id),
        children: category.children.map((child) => ({
          id: child.id,
          title: child.title,
          checked: getCheckedStatus(child.id),
        })),
      }));

    this.setState({
      locationNodeMap: mapFromTree(treeList, true),
    });
  }

  onTreeChange = (nodeMap: NodeMap) => {
    this.setState({ locationNodeMap: nodeMap });
  };

  render() {
    const { timeout, onChange } = this.props;

    return (
      <TreeList
        timeout={timeout}
        nodeMap={this.state.locationNodeMap}
        onChange={this.onTreeChange}
        onNodeCheck={async (checkedNode: TreeNodeRef, nodeMap: NodeMap) => {
          const checkedIds = Object.values(nodeMap)
            .filter((node) => node.checked === checkboxStatus.checked)
            .map((node) => node.id);
          // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/21310#issuecomment-367919251
          window.clearTimeout(this.timeout);
          // eslint-disable-next-line immutable/no-mutation
          this.timeout = window.setTimeout(async () => {
            onChange(checkedIds);
          }, 1000);
        }}
      />
    );
  }
}
