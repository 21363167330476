import {
  brown,
  cyan,
  deepPurple,
  green,
  orange,
  red,
} from '@mui/material/colors';
import { reverse } from 'ramda';
import { RawArticleStatus } from '../../__generated__/queries-topic';
import { auth } from '../auth';

export const getOrderedStatusList = (): RawArticleStatus[] => [
  RawArticleStatus.Editing,
  RawArticleStatus.Validation,
  RawArticleStatus.Validating,
  RawArticleStatus.Correction,
  RawArticleStatus.Correcting,
  RawArticleStatus.Mockup,
];

export const getStatusName = (status: RawArticleStatus): string => {
  switch (status) {
    case RawArticleStatus.Editing:
      return 'EN RÉDACTION';
    case RawArticleStatus.Validation:
      return 'À VALIDER';
    case RawArticleStatus.Validating:
      return 'EN VALIDATION';
    case RawArticleStatus.Correction:
      return 'À CORRIGER';
    case RawArticleStatus.Correcting:
      return 'EN CORRECTION';
    case RawArticleStatus.Mockup:
      return 'À MAQUETTER';
    default:
      return '';
  }
};

export const getNextStatus = (
  status: RawArticleStatus,
): RawArticleStatus | boolean => {
  switch (status) {
    case RawArticleStatus.Editing:
      return RawArticleStatus.Validation;
    case RawArticleStatus.Validation:
      return RawArticleStatus.Validating;
    case RawArticleStatus.Validating:
      return RawArticleStatus.Correction;
    case RawArticleStatus.Correction:
      return RawArticleStatus.Correcting;
    case RawArticleStatus.Correcting:
      return RawArticleStatus.Mockup;
    case RawArticleStatus.Mockup:
    default:
      return false;
  }
};
export const getPrevStatus = (
  status: RawArticleStatus,
): RawArticleStatus | boolean => {
  switch (status) {
    case RawArticleStatus.Editing:
      return false;
    case RawArticleStatus.Validation:
      return RawArticleStatus.Editing;
    case RawArticleStatus.Validating:
      return RawArticleStatus.Validation;
    case RawArticleStatus.Correction:
      return RawArticleStatus.Validating;
    case RawArticleStatus.Correcting:
      return RawArticleStatus.Correction;
    case RawArticleStatus.Mockup:
      return RawArticleStatus.Correcting;
    default:
      return false;
  }
};

export const checkIfStatusIsDisable = (
  status?: RawArticleStatus,
  allowedStatuses?: RawArticleStatus[],
  lastEditorId?: string,
  currentStatus?: RawArticleStatus,
) => {
  if (currentStatus === RawArticleStatus.Mockup) {
    return true;
  }
  if (status === RawArticleStatus.Mockup) {
    if (auth.user?.id === lastEditorId) {
      return false;
    }
  }
  if (
    allowedStatuses &&
    !allowedStatuses.find((allowedStatus) => allowedStatus === status)
  ) {
    return true;
  }
  return false;
};

export const statusColors: { [key: string]: string } = {
  [RawArticleStatus.Editing]: cyan[500],
  [RawArticleStatus.Validation]: green[500],
  [RawArticleStatus.Validating]: brown[500],
  [RawArticleStatus.Correction]: red[500],
  [RawArticleStatus.Correcting]: deepPurple[500],
  [RawArticleStatus.Mockup]: orange[500],
};
export const getColorByStatus = (status?: RawArticleStatus): string => {
  return status ? statusColors[status] : cyan[500];
};

export interface Version {
  id: string;
  status: RawArticleStatus;
  editorId: string;
  editor: {
    id: string;
    name: string;
    avatarUrl: string | null;
  } | null;
}

export const getLatestVersionByStatus = (
  versions: Version[],
  currentStatus: RawArticleStatus,
): Record<RawArticleStatus, Version> => {
  const orderedStatuses = getOrderedStatusList();
  const currentStatusIdx = orderedStatuses.indexOf(currentStatus);
  const statusesToCheck = orderedStatuses.slice(0, currentStatusIdx + 1);

  return reverse(statusesToCheck)
    .map((status) => {
      const version = versions.find((v) => v.status === status);
      return { status, version };
    })
    .filter((obj) => obj.version !== undefined)
    .reduce((acc, current) => {
      // eslint-disable-next-line immutable/no-mutation
      acc[current.status] = current.version as Version;
      return acc;
    }, {} as Record<RawArticleStatus, Version>);
};

export const isValidStatus = (
  status: RawArticleStatus | boolean,
  allowedStatuses: RawArticleStatus[] = [],
): boolean =>
  typeof status === 'string' ? allowedStatuses.includes(status) : false;
