import { MoreVert, Spellcheck } from '@mui/icons-material';
import {
  Box,
  IconButton,
  MenuItem,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { common } from '@mui/material/colors';
import {
  NotificationTypeEnum,
  OneMedia,
  useMenu,
  useNotification,
} from '@prismamedia/one-components';
import { useAssignmentUpdate } from 'apollo/mutations/upsertAssignment.topic.graphql';
import { Head } from 'components/Head';
import { TopicLink } from 'components/Link/TopicLink';
import { EditorState } from 'draft-js';
import { equals } from 'ramda';
import React, {
  FC,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import {
  ArticleForm_RawArticle_assignment,
  GetAssignment_assignment,
  GetRawArticle_rawArticle_assignment,
  GetRawArticle_rawArticle_assignment_articleTemplate,
  GetRawArticle_rawArticle_assignment_printTemplate,
  PrintTemplateDraftFieldsDataType,
  RawArticleStatus,
  TemplateType,
  UpdateAssignment,
} from '../../__generated__/queries-topic';
import { useAssigmentGetter } from '../../apollo/queries/assignment.topic.graphql';
import { useRawArticleGetter } from '../../apollo/queries/rawArticle.topic.graphql';
import { AppToolbar } from '../../components/AppToolbar';
import { AssignmentTitle } from '../../components/AssignmentTitle';
import { SaveButton } from '../../components/Button/SaveButton';
import { CircularLoader } from '../../components/Loader/Circular';
import { TopicNavigationPrompt } from '../../components/NavigationPrompt';
import { InputText, ProlexisDialog } from '../../components/ProlexisDialog';
import { paths, replaceParams } from '../../routing';
import { gaSendEvent } from '../../utils/googleAnalytics';
import { getColorByStatus } from '../../utils/rawArticleStatus';
import { WorkflowConfirmStatusChangeModal } from '../ArticleForm/WorkflowConfirmStatusChangeModal';
import { WorkflowDrawer } from '../ArticleForm/WorkflowDrawer';
import { WorkflowStepper } from '../ArticleForm/WorkflowStepper';
import { ArticleHeader } from './ArticleHeader';
import { ArticleAutoForm } from './Form';
import { applyParagraphStyle } from './Form/components/FormInputDraft';
import { LeftOverDrawer } from './LeftOverDrawer';
import {
  ArticleFormModel,
  apiToForm,
  formToApi,
  getEmptyArticleForm,
  postSave,
  serializeFormValues,
} from './dataTransformer';
import { useStyles } from './styles';

export interface ApiBodyData {
  idPrintTemplate: string;
  fields: PrintTemplateDraftFieldsDataType[];
}

interface BaseFormField {
  id: number;
  name: string;
  page: number;
}

export type ImageFormField = BaseFormField & {
  type: 'IMAGE';
  value: OneMedia[];
  refresh?: React.Dispatch<React.SetStateAction<OneMedia[]>>;
  ratio?: string;
};

export type DraftFormField = BaseFormField & {
  type: 'DRAFT' | 'TEXT';
  value: EditorState;
  refresh?: React.Dispatch<React.SetStateAction<EditorState>>;
  maxLength: number;
};

export type FormField = DraftFormField | ImageFormField;

interface ContextProps {
  showPreview: boolean;
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
  isLeftOverOpen: boolean;
  setIsLeftOverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  printTemplates: ArticleForm_RawArticle_assignment['printTemplate'][];
  setPrintTemplates: React.Dispatch<
    React.SetStateAction<ArticleForm_RawArticle_assignment['printTemplate'][]>
  >;
  articleTemplate:
    | GetRawArticle_rawArticle_assignment_articleTemplate
    | null
    | undefined;
  setArticleTemplate: React.Dispatch<
    React.SetStateAction<
      GetRawArticle_rawArticle_assignment_articleTemplate | null | undefined
    >
  >;
  selectedPrintTemplateId: string;
  setSelectedPrintTemplateId: React.Dispatch<React.SetStateAction<string>>;
  selectedPrintTemplate?:
    | ArticleForm_RawArticle_assignment['printTemplate']
    | GetRawArticle_rawArticle_assignment_printTemplate
    | undefined;
  setSelectedPrintTemplate: React.Dispatch<
    React.SetStateAction<
      | ArticleForm_RawArticle_assignment['printTemplate']
      | GetRawArticle_rawArticle_assignment_printTemplate
      | undefined
    >
  >;
  assignmentData:
    | GetRawArticle_rawArticle_assignment
    | GetAssignment_assignment
    | null;
  setAssignmentData: React.Dispatch<
    React.SetStateAction<
      GetRawArticle_rawArticle_assignment | GetAssignment_assignment | null
    >
  >;
  rawArticleData: ArticleFormModel;
  setRawArticleData: React.Dispatch<React.SetStateAction<ArticleFormModel>>;
  rawArticleBody: ApiBodyData | null;
  setRawArticleBody: React.Dispatch<React.SetStateAction<ApiBodyData | null>>;
  lastSavedFormFieldValues: ApiBodyData | null;
  setLastSavedFormFieldValues: React.Dispatch<
    React.SetStateAction<ApiBodyData | null>
  >;
  formValues: FormField[];
  lastModifiedField: FormField | null;
  setFormValues: React.Dispatch<React.SetStateAction<FormField[]>>;
  formStructure: FormField[] | null;
  setFormStructure: React.Dispatch<React.SetStateAction<FormField[] | null>>;
  togglePreview: () => void;
  updateFormValues: (id: number, value: OneMedia[] | EditorState) => void;
  setPreviewResponseTime: React.Dispatch<React.SetStateAction<string>>;
  previewResponseTime: string;
  isPreviewLoading: boolean;
  setIsPreviewLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedField: number;
  setSelectedField: React.Dispatch<React.SetStateAction<number>>;
}

export const ArticleAutoContext = createContext({} as ContextProps);

interface MatchParams {
  assignmentId?: string;
  id?: string;
}

// TODO: better manage state of article editor (e.g: use different pattern like reducer or state manager library)
export const ArticleAutoWrapper: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<MatchParams>();
  const getAssigment = useAssigmentGetter();
  const getRawArticle = useRawArticleGetter();

  const { openMenu, closeMenu } = useMenu();

  // for the context
  const localShowPreview = localStorage.getItem('showAutoPreview');
  const [showPreview, setShowPreview] = useState(
    !(localShowPreview && localShowPreview === 'false'),
  );
  const [lastModifiedField, setLastModifiedField] = useState<FormField | null>(
    null,
  );
  const [printTemplates, setPrintTemplates] = useState<
    ArticleForm_RawArticle_assignment['printTemplate'][]
  >([]);
  const [articleTemplate, setArticleTemplate] = useState<
    GetRawArticle_rawArticle_assignment_articleTemplate | null | undefined
  >(null);
  const [selectedPrintTemplateId, setSelectedPrintTemplateId] = useState('');
  const [selectedPrintTemplate, setSelectedPrintTemplate] = useState<
    | ArticleForm_RawArticle_assignment['printTemplate']
    | GetRawArticle_rawArticle_assignment_printTemplate
    | undefined
  >(null);
  const [assignmentData, setAssignmentData] = useState<
    GetRawArticle_rawArticle_assignment | GetAssignment_assignment | null
  >(null);
  const [rawArticleData, setRawArticleData] = useState<ArticleFormModel>(
    getEmptyArticleForm(),
  );
  const [rawArticleBody, setRawArticleBody] = useState<ApiBodyData | null>(
    null,
  );
  const [
    lastSavedFormFieldValues,
    setLastSavedFormFieldValues,
  ] = useState<ApiBodyData | null>(null);
  const [formValues, setFormValues] = useState<FormField[]>([]);
  const [formStructure, setFormStructure] = useState<FormField[] | null>(null);
  const { pushNotification } = useNotification();
  const [previewResponseTime, setPreviewResponseTime] = useState('0');

  // for the current component
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nextStatus, setNextStatus] = useState<RawArticleStatus>(
    RawArticleStatus.Editing,
  );
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [isWorkflowDrawerOpen, setIsWorkflowDrawerOpen] = useState<boolean>(
    false,
  );
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [isProlexisOpen, setIsProlexisOpen] = useState(false);
  const [isLeftOverOpen, setIsLeftOverOpen] = useState(false);
  const [selectedField, setSelectedField] = useState(0);

  const subjectId = assignmentData ? assignmentData.subject.id : '';
  const subjectTitle = assignmentData ? assignmentData.subject.title : '';
  const rawArticleId = rawArticleData.id;
  const rawArticleStatus = rawArticleData.status;
  const allowedStatuses = rawArticleData.allowedStatuses || [];
  const versions = rawArticleData.versions || [];
  const editedAt = rawArticleData.createdAt;

  const updateFormValues = useCallback(
    (id: number, value: OneMedia[] | EditorState) => {
      setFormValues((oldValues) => {
        const fieldIndex = oldValues.findIndex(
          (formField) => formField.id === id,
        );

        if (fieldIndex === -1) {
          return oldValues;
        }

        const newFields = [...oldValues];
        // eslint-disable-next-line immutable/no-mutation
        newFields[fieldIndex].value = value;

        // update last modified field accordingly in deferred state
        setTimeout(() => setLastModifiedField(newFields[fieldIndex]));
        return newFields;
      });
    },
    [],
  );

  const params: ContextProps = {
    showPreview,
    lastModifiedField,
    setShowPreview,
    printTemplates,
    setPrintTemplates,
    articleTemplate,
    setArticleTemplate,
    selectedPrintTemplateId,
    setSelectedPrintTemplateId,
    selectedPrintTemplate,
    setSelectedPrintTemplate,
    rawArticleData,
    setRawArticleData,
    assignmentData,
    setAssignmentData,
    rawArticleBody,
    setRawArticleBody,
    formValues,
    setFormValues,
    formStructure,
    setFormStructure,
    togglePreview: () => {
      setShowPreview(!showPreview);
      localStorage.setItem('showAutoPreview', String(!showPreview));
    },
    updateFormValues,
    lastSavedFormFieldValues,
    setLastSavedFormFieldValues,
    previewResponseTime,
    setPreviewResponseTime,
    isPreviewLoading,
    setIsPreviewLoading,
    isLeftOverOpen,
    setIsLeftOverOpen,
    selectedField,
    setSelectedField,
  };

  const [updateAssignment] = useAssignmentUpdate({
    onCompleted: async (result: UpdateAssignment) => {
      setRawArticleData(postSave(rawArticleData, result));
      const normalizedFields = serializeFormValues(
        selectedPrintTemplateId,
        formValues,
      );
      setLastSavedFormFieldValues(normalizedFields);
      pushNotification({
        message: `L'article a bien été sauvegardé.`,
        type: NotificationTypeEnum.success,
      });
      return history.push({
        pathname: replaceParams(paths.ARTICLE_AUTO_EDIT, {
          id: result?.updateAssignment?.rawArticle?.id,
        }),
        search: location.search,
      });
    },
    onError: () => {
      pushNotification({
        message: "Erreur lors de l'enregistrement de l'article",
        type: NotificationTypeEnum.error,
      });
    },
  });

  const save = useCallback(
    (status = RawArticleStatus.Editing) => {
      const normalizedFields = serializeFormValues(
        selectedPrintTemplateId,
        formValues,
      );
      const upsertRawArticleInput = formToApi({
        id: rawArticleData?.id,
        status,
        body: JSON.stringify(normalizedFields),
        textIsReadOnly: rawArticleData.textIsReadOnly,
        printTemplateId: selectedPrintTemplateId,
        lastVersionId: rawArticleData.lastVersionId,
      });

      updateAssignment({
        variables: {
          data: {
            rawArticle: upsertRawArticleInput,
            printTemplate: { connect: { id: selectedPrintTemplateId } },
          },
          where: { id: assignmentData?.id },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPrintTemplateId, formValues, rawArticleData, assignmentData],
  );

  useEffect(() => {
    const onCmdS = (e: KeyboardEvent) => {
      if (e.key === 's' && e.metaKey) {
        e.preventDefault();
        save(rawArticleData.status);
      }
    };
    window.addEventListener('keydown', onCmdS);
    return () => window.removeEventListener('keydown', onCmdS);
  }, [rawArticleData, save]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { assignmentId, id } = match.params!;
    if (match.path === paths.ARTICLE_AUTO_ADD && assignmentId) {
      setIsLoading(true);
      getAssigment({ assignmentId })
        .then(({ assignment }) => {
          const { printTemplates: PrintTemplate = [], autoPrototype } =
            assignment?.printHeading || {};
          if (!autoPrototype && !assignment?.printTemplate?.id) {
            return history.push({
              pathname: replaceParams(paths.ARTICLE_ADD, {
                assignmentId,
              }),
              search: location.search,
            });
          }
          setAssignmentData(assignment);
          setPrintTemplates(PrintTemplate.map((item) => item.printTemplate));

          const activeTemplate = PrintTemplate.find(({ active }) => active);

          if (activeTemplate) {
            setSelectedPrintTemplateId(activeTemplate.printTemplate.id);
            setSelectedPrintTemplate(activeTemplate.printTemplate);
          }

          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
        });
    } else if (match.path === paths.ARTICLE_AUTO_EDIT && id) {
      setIsLoading(true);
      getRawArticle({ id }, { fetchPolicy: 'network-only' })
        .then(({ rawArticle }) => {
          if (
            rawArticle &&
            !rawArticle.assignment?.printHeading?.autoPrototype &&
            !rawArticle.assignment?.printTemplate?.id
          ) {
            return history.push({
              pathname: replaceParams(paths.ARTICLE_EDIT, {
                id: rawArticle.id,
              }),
              search: location.search,
            });
          }
          const rawArticleResponse = apiToForm(rawArticle);
          setArticleTemplate(rawArticleResponse.articleTemplate);
          setAssignmentData(rawArticle?.assignment || null);
          setPrintTemplates(rawArticleResponse.printTemplates || []);
          setRawArticleData(rawArticleResponse);
          setSelectedPrintTemplate(
            rawArticleResponse.articleTemplate?.printTemplate ||
              (rawArticle?.assignment?.printTemplate as any) ||
              null,
          );
          setSelectedPrintTemplateId(
            rawArticleResponse.articleTemplate?.printTemplate?.id ||
              rawArticle?.assignment?.printTemplate?.id ||
              '',
          );
          try {
            const parsedBody: ApiBodyData = JSON.parse(rawArticleResponse.body);
            setRawArticleBody(parsedBody);
          } catch (error) {
            console.error('Error on existing rawArticle');
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!match) {
    return null;
  }

  const selectNextStatus = (status: RawArticleStatus) => {
    setNextStatus(status);
    setIsStatusModalOpen(true);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: getColorByStatus(rawArticleStatus),
        contrastText: common.white,
      },
    },
  });

  const closeProlexis = (texts: InputText[] | false) => {
    if (texts) {
      const newFormValues = [...formValues];
      newFormValues.forEach((field) => {
        const correctedField = texts.find((text) => text.name === field.name);
        if (correctedField && field.type === 'DRAFT') {
          const correctedValue = correctedField.value as EditorState;
          // eslint-disable-next-line immutable/no-mutation
          field.value = correctedValue;
          field.refresh && field.refresh(correctedValue);
        }
      });
      setFormValues(newFormValues);
    }
    setIsProlexisOpen(false);
  };

  const getPreviousRoute = () => {
    if (
      articleTemplate?.printTemplate?.type ===
      TemplateType.MULTIPLE_ARTICLE_TEMPLATE
    )
      return {
        pathname: replaceParams(paths.MULTI_SUBJECT_AUTO_EDIT, {
          printHeadingId: assignmentData?.printHeading?.id,
          originId: subjectId,
        }),
      };
    return subjectId
      ? {
          pathname: replaceParams(paths.SUBJECT_EDIT, {
            id: subjectId,
          }),
        }
      : { pathname: paths.SUBJECT_LIST };
  };

  const handleCloseLeftOverDialog = () => {
    setIsLeftOverOpen(false);
    setSelectedField(0);
  };

  const handleSelectLeftOver = (value: EditorState) => {
    const field = formStructure?.find((item) => item.id === selectedField);
    const defaultStyle = selectedPrintTemplate?.fields.find(
      (f) => f?.id === selectedField,
    )?.paragraphStyles[0].id;
    const styledValue = defaultStyle
      ? applyParagraphStyle(value, defaultStyle)
      : value;
    updateFormValues(selectedField, styledValue);
    if (field?.refresh && field.type === 'DRAFT') {
      field.refresh(styledValue);
    }
    handleCloseLeftOverDialog();
  };

  return isLoading ? (
    <CircularLoader />
  ) : (
    <>
      <Head title={subjectTitle} />
      <ThemeProvider theme={theme}>
        <ArticleAutoContext.Provider value={{ ...params }}>
          <AppToolbar
            childrenJustifyContent="flex-end"
            className={classes.appToolbarChildren}
            title={`Sujet: ${subjectTitle}`}
            editedAt={editedAt}
            previousRoute={getPreviousRoute()}
            assignmentTitle={
              rawArticleId && (
                <AssignmentTitle
                  assignment={
                    assignmentData as GetRawArticle_rawArticle_assignment
                  }
                  withLinkOnPrintIssue
                />
              )
            }
            actionButton={
              <>
                <IconButton
                  color="inherit"
                  className={classes.prolexisButton}
                  onClick={() => {
                    gaSendEvent('ArticleAuto', 'click', 'Prolexis');
                    setIsProlexisOpen(true);
                  }}
                  disabled={rawArticleData.textIsReadOnly}
                  size="large"
                >
                  <Spellcheck />
                </IconButton>
                <SaveButton
                  onClick={() => save(rawArticleData.status)}
                  className={classes.saveButton}
                />
                {rawArticleId && (
                  <IconButton
                    sx={{ ml: 2 }}
                    onClick={(e) =>
                      openMenu(
                        e.currentTarget,
                        <Box>
                          <MenuItem>
                            <TopicLink
                              to={paths.ARTICLE_AUTO_HISTORY}
                              params={{ idRawArticle: rawArticleId }}
                              onClick={() => closeMenu()}
                            >
                              Historique
                            </TopicLink>
                          </MenuItem>
                        </Box>,
                      )
                    }
                  >
                    <MoreVert />
                  </IconButton>
                )}
              </>
            }
          >
            {rawArticleId && rawArticleData.editor && (
              <WorkflowStepper
                userName={rawArticleData.editor.name}
                avatarUrl={rawArticleData.editor.avatarUrl}
                status={rawArticleStatus}
                updateStatus={selectNextStatus}
                allowedStatuses={allowedStatuses}
                onStatusClick={() => setIsWorkflowDrawerOpen(true)}
              />
            )}
          </AppToolbar>
          <ArticleHeader />
          <div className={classes.main}>
            <ArticleAutoForm />
          </div>
          {rawArticleId && (
            <>
              <WorkflowConfirmStatusChangeModal
                open={isStatusModalOpen}
                createNewVersion={() => save(nextStatus)}
                toggleConfirmModal={() => setIsStatusModalOpen(false)}
                currentStatus={rawArticleStatus}
                nextStatus={nextStatus}
              />
              <WorkflowDrawer
                rawArticleId={rawArticleId}
                open={isWorkflowDrawerOpen}
                onClose={() => setIsWorkflowDrawerOpen(false)}
                currentStatus={rawArticleStatus}
                allowedStatuses={allowedStatuses}
                versions={versions}
                updateStatus={selectNextStatus}
              />
            </>
          )}
          <TopicNavigationPrompt
            when={() =>
              !equals(
                lastSavedFormFieldValues,
                serializeFormValues(selectedPrintTemplateId, formValues),
              )
            }
            title="Êtes-vous sur de vouloir quitter la page ?"
            content="Vous avez des modifications non sauvegardées"
            confirmLabel="Oui"
            cancelLabel="Non"
          />
          {isProlexisOpen && (
            <ProlexisDialog
              unitTitle={assignmentData?.subject.unit.title}
              texts={formValues
                .filter((field) => field.type === 'DRAFT')
                .map(
                  ({ name, type, value }) =>
                    ({ label: name, name, type, value } as InputText),
                )}
              handleClose={closeProlexis}
            />
          )}
          <LeftOverDrawer
            leftOver={rawArticleData.leftOver || []}
            open={isLeftOverOpen}
            onClose={handleCloseLeftOverDialog}
            handleSelectLeftOver={handleSelectLeftOver}
          ></LeftOverDrawer>
        </ArticleAutoContext.Provider>
      </ThemeProvider>
    </>
  );
};
