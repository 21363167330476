import { ApolloQueryResult } from '@apollo/client';
import { Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Infinite from 'react-infinite';
import {
  GetSubjects,
  GetSubjectsVariables,
  RawArticleStatus,
} from '../../../__generated__/queries-topic';
import { ContentNotFound } from '../../../components/ContentNotFound';
import { SubjectItem } from '../../../components/ListItem/SubjectItem';
import { CircularLoader } from '../../../components/Loader/Circular';
import { isUserAdmin } from '../../../utils/auth';
import {
  getWhereInputCategoryFilter,
  getWhereInputLocationFilter,
  getWhereInputSearchFilter,
  getWhereInputTextStatusFilter,
  getWhereInputUnitFilter,
  useGetSubjects,
} from './getFilteredSubjectList.topic.graphql';
import { NewSubject } from './NewSubject';
import { useStyles } from './styles';

const stringifyFilters = (
  locations: string[],
  categories: string[],
  searchString?: string,
) =>
  JSON.stringify({
    // eslint-disable-next-line fp/no-mutating-methods
    locations: locations.sort(),
    // eslint-disable-next-line fp/no-mutating-methods
    categories: categories.sort(),
    searchString,
  });

interface SubjectListProps {
  unit?: string;
  locations: string[];
  categories: string[];
  searchString?: string;
  textStatusIds: RawArticleStatus[];
}

const buildVariables = ({
  locations,
  categories,
  searchString,
  unit,
  textStatusIds,
}: SubjectListProps) => {
  const variables: GetSubjectsVariables = {
    where: {},
    skip: 0,
  };
  const hasUnitFilter = unit?.length;
  const hasLocationFilter = locations.length;
  const hasCategoryFilter = categories.length;
  const hasSearchString = searchString && searchString.length;
  const hasTextStatusFilter = textStatusIds.length;

  if (
    hasCategoryFilter ||
    hasLocationFilter ||
    hasSearchString ||
    hasUnitFilter ||
    hasTextStatusFilter
  ) {
    // eslint-disable-next-line immutable/no-mutation
    variables.where.AND = [];
    if (hasUnitFilter) {
      // eslint-disable-next-line fp/no-mutating-methods, @typescript-eslint/no-non-null-assertion
      variables.where.AND.push(getWhereInputUnitFilter(unit!));
    }
    if (hasLocationFilter) {
      const locationFilter = getWhereInputLocationFilter(locations);
      // eslint-disable-next-line fp/no-mutating-methods
      variables.where.AND.push(locationFilter);
    }
    if (hasCategoryFilter) {
      // eslint-disable-next-line fp/no-mutating-methods
      variables.where.AND.push(getWhereInputCategoryFilter(categories));
    }
    if (hasTextStatusFilter) {
      // eslint-disable-next-line fp/no-mutating-methods
      variables.where.AND.push(getWhereInputTextStatusFilter(textStatusIds));
    }
    if (searchString && searchString.length > 0) {
      // eslint-disable-next-line fp/no-mutating-methods
      variables.where.AND.push(getWhereInputSearchFilter(searchString));
    }
  }
  return variables;
};

const LIMIT = 10;

export const SubjectList: FC<SubjectListProps> = (props) => {
  const { locations, categories, searchString, textStatusIds } = props;
  const classes = useStyles();
  const [noMore, setNoMore] = useState(false);
  const [filters, setFilters] = useState('');

  useEffect(() => {
    const newFilters = stringifyFilters(locations, categories, searchString);
    if (filters !== newFilters) {
      setNoMore(false);
      setFilters(newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, locations, searchString]);

  const isAdmin = isUserAdmin();
  const variables = buildVariables(props);
  const {
    data: subjectData,
    loading: subjectLoading,
    fetchMore,
    refetch,
  } = useGetSubjects(variables);

  if (subjectLoading) {
    return <CircularLoader />;
  }
  if (!subjectData) {
    return <div>ERROR subjectList</div>;
  }
  const { subjects } = subjectData;

  return (
    <div className={classes.list}>
      {/* Temporary Disable */}
      {false && (
        <NewSubject
          refetch={refetch}
          variables={variables}
          firstSubjectId={subjects.length > 0 ? subjects[0].id : null}
        />
      )}

      {!subjects.length ? (
        <ContentNotFound title="Aucun sujet ne correspond à ces filtres." />
      ) : (
        <Infinite
          useWindowAsScrollContainer
          className={classes.infinite}
          elementHeight={subjects.reduce((acc, subject) => {
            const baseHeight = 72;
            const articleHeight = 40;
            const height =
              baseHeight + (subject.assignments.length || 1) * articleHeight;
            return [...acc, height];
          }, [] as number[])}
          infiniteLoadBeginEdgeOffset={60}
          onInfiniteLoad={async () => {
            if (noMore) {
              return;
            }
            if (subjects.length < LIMIT) {
              setNoMore(true);
              return;
            }
            const { data: moreData } = await fetchMore({
              variables: { skip: subjects.length },
            });
            if (moreData.subjects.length < LIMIT) {
              setNoMore(true);
            }
          }}
          loadingSpinnerDelegate={
            noMore ? (
              <Typography>Fin de la liste</Typography>
            ) : (
              <CircularLoader />
            )
          }
        >
          {subjects.map((subject) => (
            <SubjectItem
              key={subject.id}
              subject={subject}
              textStatusIds={textStatusIds}
              refetch={
                refetch as (
                  variables?: GetSubjectsVariables,
                ) => Promise<ApolloQueryResult<GetSubjects>>
              }
              isUserAdmin={isAdmin}
            />
          ))}
        </Infinite>
      )}
    </div>
  );
};
