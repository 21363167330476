import { EuroSymbol } from '@mui/icons-material';
import { Box, SortDirection, SxProps } from '@mui/material';
import { amber, green, orange, red } from '@mui/material/colors';
import {
  SearchParams,
  notificationQueue,
  useNotification,
} from '@prismamedia/one-components';
import {
  MetadataNameEnumType,
  PhotoMetadataWhereInput,
  PhotoOrderByInput,
  PhotoWhereInput,
  SearchPhotos_searchPhotos,
} from '__generated__/queries-photo';
import { GetSubject_subject_assignments } from '__generated__/queries-topic';
import { useUpdatePhotoFile } from 'apollo/mutations/photos.photo.graphql';
import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';
import { ReactNode } from 'react';
import { downloadImage } from 'utils/downloadImage';
import { Metadata, getMetadata } from 'utils/getMetadata';
import { PreviewSize, getPreview } from 'utils/getPreview';
import { isUUID } from 'utils/is-uuid';

export enum EDIT_STATUS {
  PUBLISHED = 'OK_PROD',
  PENDING = 'EN_COURS',
  DELETE = 'DELETE',
}

export enum EXPORT_TYPE {
  PRINT = 'PRINT',
  WEB = 'WEB',
}

export enum PROCESS_STATUS {
  ATTENTE = 'EN_ATTENTE',
  TRAITE = 'TRAITE',
}

export enum AccessName {
  READ = 'READ',
  SEND_AND_DOWNLOAD = 'SEND_AND_DOWNLOAD',
  ARCHIVE_FROM = 'ARCHIVE_FROM',
  WRITE_AND_IMPORT_TO = 'WRITE_AND_IMPORT_TO',
  DELETE = 'DELETE',
}

export enum PriceLevel {
  FREE = 'GRATUIT',
  PAID = 'PAYANT',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

enum SortMethodId {
  MODIFICATION_DATE = 'MODIFICATION_DATE',
  CREATION_DATE = 'CREATION_DATE',
}

interface SortMethod {
  id: SortMethodId;
  title: string;
}

export interface Sort {
  direction: SortDirection;
  method: SortMethod;
}

export const sortMethods: SortMethod[] = [
  {
    id: SortMethodId.CREATION_DATE,
    title: 'Date de création',
  },
  {
    id: SortMethodId.MODIFICATION_DATE,
    title: 'Date de modification',
  },
];

export const getOrderBy = (sort: Sort): PhotoOrderByInput => {
  if (sort.method.id === SortMethodId.MODIFICATION_DATE) {
    if (sort.direction === 'desc') {
      return PhotoOrderByInput.updatedAt_DESC;
    }
    return PhotoOrderByInput.updatedAt_ASC;
  }
  if (sort.direction === 'desc') {
    return PhotoOrderByInput.createdAt_DESC;
  }
  return PhotoOrderByInput.createdAt_ASC;
};

export const priceLevels: Record<
  PriceLevel,
  { label: string; renderIcon: (sx?: SxProps) => ReactNode }
> = {
  [PriceLevel.FREE]: {
    label: 'Gratuit',
    renderIcon: (sx) => <EuroSymbol sx={{ color: green[500], ...sx }} />,
  },
  [PriceLevel.PAID]: {
    label: 'Payant',
    renderIcon: (sx) => <EuroSymbol sx={{ color: red[500], ...sx }} />,
  },
  [PriceLevel.LOW]: {
    label: 'Prix bas',
    renderIcon: (sx) => <EuroSymbol sx={{ color: amber[500], ...sx }} />,
  },
  [PriceLevel.MEDIUM]: {
    label: 'Prix moyen',
    renderIcon: (sx) => <Box sx={{ color: orange[500], ...sx }}>€€</Box>,
  },
  [PriceLevel.HIGH]: {
    label: 'Prix élevé',
    renderIcon: (sx) => <Box sx={{ color: red[500], ...sx }}>€€€</Box>,
  },
};

export enum Resolution {
  HD = 'HD',
  BD = 'BD',
  R = 'R',
}

export enum EditStatus {
  SENT_PRINT = 'Envoyé au print',
  SENT_WEB = 'Envoyé au web',
  PENDING = 'En cours',
}

export const NO_COLOR = 'NO_COLOR';

export const colors = [
  { color: 'pink', label: 'Rose', value: '0' },
  { color: 'red', label: 'Rouge', value: '1' },
  { color: 'orange', label: 'Orange', value: '2' },
  { color: 'yellow', label: 'Jaune', value: '3' },
  { color: 'green', label: 'Vert', value: '4' },
  { color: 'cyan', label: 'Cyan', value: '5' },
  { color: 'blue', label: 'Bleu', value: '6' },
];

export const getColorClass = (
  photo: Pick<SearchPhotosPhoto, 'metadataByName'>,
) => {
  const urgency = getMetadata(MetadataNameEnumType.Urgency, photo);

  if (!urgency) {
    return undefined;
  }

  const index = parseInt(urgency, 10);
  return colors[index]?.color || undefined;
};

export interface Filters {
  priceLevels?: string[];
  resolutions?: Resolution[];
  editStatus?: EditStatus[];
  colors?: string[];
  underSubjects?: string[];
}

const isQuotedToken = (token: string) => {
  return token.startsWith('"') && token.endsWith('"');
};

const tokenizeSearchPhrase = (phrase: string) => {
  // special chars (!!) enables advanced mode
  if (phrase.startsWith('!!')) {
    return [phrase.substring(2)];
  }

  return (
    phrase
      .split(/("[^"]*")/gi)
      .flatMap((token) => {
        return (
          (isQuotedToken(token)
            ? token
            : token.split(/\s+/g).map((t) => {
                return t.match(
                  /([^0-9a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]+)/gi,
                )
                  ? `"${t}"`
                  : t;
              })) || ''
        );
      })
      // only include tokens with more than 3 chars
      .filter((tq) => {
        return tq.length - (isQuotedToken(tq) ? 2 : 0) > 3;
      })
      // add `*` wildcard if none present (expands search result)
      .map((token) => {
        return isQuotedToken(token) ? token : `${token}*`;
      })
  );
};

const expandMetadataFilters = (...queries: PhotoMetadataWhereInput[]) => {
  return queries
    .reduce((q, query) => {
      if (query.value_contains) {
        return q.concat(
          tokenizeSearchPhrase(query.value_contains).map((token) => ({
            ...query,
            value_contains: token,
          })),
        );
      }

      if (query.value) {
        return q.concat({
          ...query,
          value: query.value.trim(),
        });
      }

      return q.concat(query);
    }, [] as PhotoMetadataWhereInput[])
    .map((filter) => ({
      photoMetadatas_some: filter,
    }));
};

export const getWhere = (
  subjectId: string,
  searchParams: SearchParams,
  filters: Filters,
  archiveId?: number,
): PhotoWhereInput => {
  const searchTerm = searchParams.search?.trim();
  const searchIsUuid = searchTerm && isUUID(searchTerm);
  const tokenizedSearch = searchIsUuid
    ? undefined
    : tokenizeSearchPhrase(searchParams.search || '');
  const metadataFilters: PhotoMetadataWhereInput[] = [];

  // eslint-disable-next-line fp/no-mutating-methods
  metadataFilters.push({
    value: subjectId,
    metadata: { name: Metadata.ID_subject },
  });

  if (filters.underSubjects?.length) {
    // eslint-disable-next-line fp/no-mutating-methods
    metadataFilters.push({
      value_in: filters.underSubjects,
      metadata: { name: Metadata.Undersubject },
    });
  }

  if (filters.priceLevels?.length) {
    // eslint-disable-next-line fp/no-mutating-methods
    metadataFilters.push({
      value_in: filters.priceLevels,
      metadata: { name: Metadata.PriceLevel },
    });
  }

  if (filters.resolutions?.length) {
    // eslint-disable-next-line fp/no-mutating-methods
    metadataFilters.push({
      value_in: filters.resolutions,
      metadata: { name: Metadata.ResolutionKind },
    });
  }

  if (filters.editStatus?.length) {
    filters.editStatus.map((status) => {
      // eslint-disable-next-line fp/no-mutating-methods
      metadataFilters.push(
        {
          [EditStatus.SENT_PRINT]: {
            value: EXPORT_TYPE.PRINT,
            metadata: { name: Metadata.ExportTypePrint },
          },
          [EditStatus.SENT_WEB]: {
            value: EXPORT_TYPE.WEB,
            metadata: { name: Metadata.ExportTypeWeb },
          },
          [EditStatus.PENDING]: {
            value: EDIT_STATUS.PENDING,
            metadata: { name: Metadata.EditStatus },
          },
        }[status],
      );
    });
  }

  const photoMetadataFilters = expandMetadataFilters(...metadataFilters);

  if (tokenizedSearch) {
    // eslint-disable-next-line fp/no-mutating-methods
    photoMetadataFilters.push(
      ...tokenizedSearch.map((token) => ({
        photoMetadatas_some: {
          value_contains: token,
          metadata: {
            name_in: [
              MetadataNameEnumType.description,
              MetadataNameEnumType.Credit,
              MetadataNameEnumType.Headline,
              MetadataNameEnumType.Source,
              MetadataNameEnumType.UploadedBy,
              MetadataNameEnumType.creator,
              MetadataNameEnumType.DeliveryCopyright,
              MetadataNameEnumType.subject,
              MetadataNameEnumType.ImageNotes,
              MetadataNameEnumType.Instructions,
              MetadataNameEnumType.title,
              MetadataNameEnumType.OriginalName,
              MetadataNameEnumType.BackupName,
              MetadataNameEnumType.IdPhotoPrisma,
            ],
          },
        },
      })),
    );
  }

  const where: PhotoWhereInput = {
    isUploaded: true,
    AND: photoMetadataFilters,
  };

  if (archiveId) {
    // eslint-disable-next-line immutable/no-mutation
    where.archive = { archiveId };
  }

  if (filters.colors?.length) {
    const trueColors = filters.colors?.filter((value) => value !== NO_COLOR);

    // eslint-disable-next-line fp/no-mutating-methods
    where.AND?.push({
      OR: [
        {
          photoMetadatas_some: {
            metadata: {
              name: Metadata.Urgency,
            },
            value_in: trueColors,
          },
        },
        ...(filters.colors.includes(NO_COLOR)
          ? [
              {
                // "NOT" is bugged in current state of graphql-platform
                NOT: {
                  photoMetadatas_some: {
                    metadata: {
                      name: Metadata.Urgency,
                    },
                  },
                },
              },
              // Above code is equivalent to the following which is not yet implemented
              // in the version of graphql-platform currently installed on the backend
              // Need to update to last version and replace by the following code
              // {
              //   photoMetadatas_none: {
              //     metadata: {
              //       name: Metadata.Urgency,
              //     },
              //   },
              // },
            ]
          : []),
      ],
    });
  }

  if (searchIsUuid) {
    // eslint-disable-next-line fp/no-mutating-methods
    where.AND?.push({
      id: searchTerm,
    });
  }

  return where;
};

export const useDownloadPhotos = () => {
  const updatePhotoFile = useUpdatePhotoFile();
  const { pushNotification, removeNotification } = useNotification();

  return (photos: SearchPhotosPhoto[]) => {
    const queue = photos.map((photo) => async () => {
      const { data } = await updatePhotoFile({ photoId: photo.id });
      if (!data?.updatePhotoFile?.downloadUrl) {
        throw new Error(`No download url for photo of id: ${photo.id}`);
      }
      return downloadImage(
        data.updatePhotoFile.downloadUrl,
        getMetadata(MetadataNameEnumType.IdPhotoPrisma, photo),
      );
    });

    return notificationQueue(
      queue,
      pushNotification,
      removeNotification,
      'Téléchargement',
    );
  };
};

export const convertPhotosToGallery = (
  photos: SearchPhotos_searchPhotos[] | undefined,
  previewSize?: PreviewSize,
) =>
  (photos || []).map((photo) => ({
    ...photo,
    src: getPreview(photo, previewSize),
    fallbacks: [{ src: photo.downloadUrl }],
  }));

export const getAssignmentData = (
  assignment: GetSubject_subject_assignments,
) => {
  const { printHeading, printIssue, printPublication } = assignment;

  let assignmentData = printPublication ? printPublication.title : '';
  if (printIssue) {
    assignmentData = assignmentData.concat(` - ${printIssue.title}`);
  }
  if (printHeading) {
    assignmentData = assignmentData.concat(
      `- ${assignment.printHeading?.title}`,
    );
  }
  return assignmentData;
};
