import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { ContentState, convertToRaw } from 'draft-js';
import React, { FC, useContext, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DragUpdate,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import SplitPane, { Pane } from 'react-split-pane';
import { MultiSubjectAutoContext } from '..';
import {
  GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates,
  GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_assignments,
  RawArticleStatus,
} from '../../../__generated__/queries-topic';
import { useAssignmentUpdate } from '../../../apollo/mutations/upsertAssignment.topic.graphql';
import { useActivatePrintTemplate } from '../../../apollo/queries/printTemplate.topic.graphql';
import { ConfirmDialog } from '../../../components/Dialog/Confirm';
import { generateTextLeftOver } from '../../../utils/rawArticle';
import { MultiSubjectPreview } from '../Preview';
import { SubjectAutoItem } from '../SubjectAutoItem';
import './index.css';
import { useStyles } from './styles';

const AWAITING_SUBJECTS = 'awaitingSubjects';

export const MultiSubjectAutoForm: FC = () => {
  const {
    showPreview,
    assignments,
    vacantAssignments,
    printTemplates,
    selectedPrintTemplateId,
    setSelectedPrintTemplateId,
    articleTemplates,
    hoveredArticleTopic,
    setHoveredArticleTopic,
    getAssignment,
    getArticleTemplate,
  } = useContext(MultiSubjectAutoContext);
  const classes = useStyles();
  const { pushNotification } = useNotification();
  const [updateAssignment] = useAssignmentUpdate({
    refetchQueries: ['GetPrintHeadingById'],
  });
  const [blockDropTarget, setBlockDropTarget] = useState('');
  const [mainPanelSize, setMainPanelSize] = useState(() => {
    const storedSize = parseInt(localStorage.getItem('splitPos') || '400', 10);
    return storedSize < window.innerWidth ? storedSize : window.innerWidth / 2;
  });
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [confirmationProcess, setConfirmationProcess] = useState<
    () => Promise<void>
  >(async () => {});
  const activatePrintTemplate = useActivatePrintTemplate();

  const handleSelect = async (event: SelectChangeEvent<string>) => {
    try {
      const newPrintTemplateId = event.target.value as string;
      await activatePrintTemplate(newPrintTemplateId);
      setSelectedPrintTemplateId(newPrintTemplateId);
    } catch (error) {
      pushNotification({
        message: `Un problème est survenu lors du changement de template`,
        type: NotificationTypeEnum.error,
      });
    }
  };

  const cancelAction = () => {
    setIsConfirmDialogOpen(false);
    setConfirmationProcess(async () => {});
    setConfirmationMsg('');
  };

  const confirmAction = async (
    Message: string,
    process: () => Promise<void>,
  ) => {
    setConfirmationMsg(Message);
    setIsConfirmDialogOpen(true);
    setConfirmationProcess(() => async () => {
      try {
        await process();
      } catch (error) {
        pushNotification({
          message: 'Une erreur technique est survenu.',
          type: NotificationTypeEnum.error,
        });
      }
      setIsConfirmDialogOpen(false);
    });
  };

  const connectAssignmentToArticleTemplate = async (
    assignmentId: string,
    articleTemplateId: string,
  ) => {
    const rawArticle = assignments.find(
      (assignment) => assignment.id === assignmentId,
    )?.rawArticle;
    const lastVersion = rawArticle?.lastVersion;
    let newLeftOver = '[]';
    if (lastVersion) {
      const { title, lead, body, signature, leftOver } = lastVersion;
      newLeftOver = generateTextLeftOver(
        title,
        lead,
        body,
        signature,
        leftOver,
      );
    }
    const data = {
      status: RawArticleStatus.Editing,
      leftOver: newLeftOver,
      title: '',
      signature: '',
      lead: JSON.stringify(convertToRaw(ContentState.createFromText(''))),
      body: JSON.stringify(convertToRaw(ContentState.createFromText(''))),
    };

    await updateAssignment({
      variables: {
        where: { id: assignmentId },
        data: {
          articleTemplate: { connect: { id: articleTemplateId } },
          rawArticle:
            rawArticle && lastVersion
              ? {
                  update: {
                    versions: {
                      create: [
                        {
                          ...data,
                          previousVersion: {
                            connect: { id: lastVersion.id },
                          },
                          forkOf: lastVersion.textIsReadOnly
                            ? {
                                connect: {
                                  id: lastVersion.id,
                                },
                              }
                            : undefined,
                        },
                      ],
                    },
                  },
                }
              : {
                  create: {
                    versions: {
                      create: [{ ...data, previousVersion: null }],
                    },
                  },
                },
        },
      },
    });
  };

  const disconnectAssignmentFromArticleTemplate = async (
    assignmentId: string,
  ) => {
    await updateAssignment({
      variables: {
        where: { id: assignmentId },
        data: { articleTemplate: { disconnect: true } },
      },
    });
  };

  const replaceAssignmentToArticleTemplate = async (
    assignmentId: string,
    articleTemplateId: string,
  ) => {
    const foundAssignmentId = articleTemplates.find(
      (articleTemplate) => articleTemplate.id === articleTemplateId,
    )?.assignments[0]?.id;
    if (foundAssignmentId)
      await disconnectAssignmentFromArticleTemplate(foundAssignmentId);
    await connectAssignmentToArticleTemplate(assignmentId, articleTemplateId);
  };

  const switchAssignmentsForArticleTemplates = async (
    assignmentId: string,
    articleTemplateId: string,
    targetArticleTemplateId: string,
  ) => {
    const targetAssignmentId = articleTemplates.find(
      (item) => item.id === targetArticleTemplateId,
    )?.assignments[0]?.id;

    await disconnectAssignmentFromArticleTemplate(assignmentId);
    if (targetAssignmentId)
      await disconnectAssignmentFromArticleTemplate(targetAssignmentId);
    await connectAssignmentToArticleTemplate(
      assignmentId,
      targetArticleTemplateId,
    );
    if (targetAssignmentId)
      await connectAssignmentToArticleTemplate(
        targetAssignmentId,
        articleTemplateId,
      );
  };

  const assignedAssignments = articleTemplates.reduce(
    (
      acc: GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_assignments[],
      item: GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates,
    ) => {
      return [...acc, ...item.assignments];
    },
    [] as GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_assignments[],
  );

  return (
    <div className={classes.main}>
      <SplitPane
        split="vertical"
        minSize={400}
        size={showPreview ? mainPanelSize : window.innerWidth}
        onChange={(size) => {
          setMainPanelSize(size);
          localStorage.setItem('splitPos', String(size));
        }}
      >
        <Pane className={classes.panelForm}>
          <Grid container justifyContent="center">
            <Grid item xs={10}>
              <FormControl
                className={`${classes.selectControl} ${classes.formTextItem}`}
                variant="standard"
              >
                <InputLabel>Gabarits</InputLabel>
                <Select
                  value={selectedPrintTemplateId}
                  onChange={handleSelect}
                  displayEmpty
                  disabled={!!assignedAssignments.length}
                  variant="standard"
                >
                  {printTemplates?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.printTemplate?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10}>
              <DragDropContext
                data-testId="drop-test"
                onDragEnd={({
                  draggableId: itemId,
                  source,
                  destination,
                }: DropResult) => {
                  setBlockDropTarget('');
                  if (!destination) return;
                  if (destination.droppableId === source.droppableId) return;
                  const destinationId = destination.droppableId;
                  const sourceId = source.droppableId;
                  const sourceArticleTemplateLabel = getArticleTemplate(
                    sourceId,
                  )?.label;
                  const TargetArticleTemplateLabel = getArticleTemplate(
                    destinationId,
                  )?.label;
                  const assignmentLabel = getAssignment(itemId)?.subject.title;
                  if (sourceId === AWAITING_SUBJECTS) {
                    confirmAction(
                      `Vous allez affecter «${assignmentLabel}» au sujet «${TargetArticleTemplateLabel}» du Gabarit.`,
                      () =>
                        replaceAssignmentToArticleTemplate(
                          itemId,
                          destinationId,
                        ),
                    );
                  } else if (destinationId === AWAITING_SUBJECTS) {
                    confirmAction(
                      `Vous allez placer «${assignmentLabel}» dans «Sujets à mettre en place».`,
                      () => disconnectAssignmentFromArticleTemplate(itemId),
                    );
                  } else {
                    confirmAction(
                      `Vous allez déplacer «${assignmentLabel}» du sujet «${sourceArticleTemplateLabel}» vers le sujet «${TargetArticleTemplateLabel}» du Gabarit.`,
                      () =>
                        switchAssignmentsForArticleTemplates(
                          itemId,
                          sourceId,
                          destinationId,
                        ),
                    );
                  }
                }}
                onDragUpdate={({ destination }: DragUpdate) => {
                  if (!destination) setBlockDropTarget('');
                  if (destination?.droppableId) {
                    setBlockDropTarget(destination.droppableId);
                  }
                }}
              >
                <div>
                  <h2>Sujets mis en page</h2>
                  {articleTemplates.map((articleTopic, index) => (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={`${classes.subjectBlock} ${
                        articleTopic.id === blockDropTarget ||
                        articleTopic.id === hoveredArticleTopic
                          ? classes.highlightBlock
                          : classes.defaultColorBlock
                      }`}
                      key={index}
                      onMouseEnter={() => {
                        setHoveredArticleTopic(articleTopic.id);
                      }}
                      onMouseLeave={() => setHoveredArticleTopic('')}
                    >
                      <Grid item xs={4} className={classes.subjectLabel}>
                        {articleTopic.label === 'undefined'
                          ? 'Sujet par défaut'
                          : articleTopic.label}
                      </Grid>
                      <Grid item xs={8} className={classes.subjectAssignment}>
                        <Droppable
                          droppableId={`${articleTopic.id}`}
                          ignoreContainerClipping
                          isCombineEnabled
                        >
                          {(droppableProvided) => (
                            <div ref={droppableProvided.innerRef}>
                              {articleTopic.assignments[0] ? (
                                <Draggable
                                  draggableId={articleTopic.assignments[0]?.id}
                                  index={0}
                                >
                                  {(draggableProvided) => (
                                    <div
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      {...draggableProvided.dragHandleProps}
                                      style={
                                        {
                                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                          ...draggableProvided.draggableProps
                                            .style!,
                                        } as React.CSSProperties
                                      }
                                    >
                                      <SubjectAutoItem
                                        assignment={articleTopic.assignments[0]}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ) : (
                                <div className={classes.emptySubject}>
                                  Aucun sujet affecté.
                                </div>
                              )}
                              {droppableProvided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </Grid>
                    </Grid>
                  ))}
                </div>
                <div
                  className={
                    blockDropTarget === AWAITING_SUBJECTS
                      ? classes.highlightBlock
                      : ''
                  }
                >
                  <h2>Sujets à mettre en place</h2>
                  <Droppable droppableId={AWAITING_SUBJECTS}>
                    {(droppableProvided) => (
                      <div ref={droppableProvided.innerRef}>
                        {vacantAssignments.length === 0
                          ? "Aucun sujet n'est en attente."
                          : vacantAssignments.map((assignment, index) => (
                              <Draggable
                                key={assignment.id}
                                draggableId={assignment.id}
                                index={index}
                              >
                                {(draggableProvided) => (
                                  <div
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                    style={
                                      {
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        ...draggableProvided.draggableProps
                                          .style!,
                                      } as React.CSSProperties
                                    }
                                  >
                                    <SubjectAutoItem
                                      assignment={assignment}
                                      isEmpty={true}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </Grid>
          </Grid>
        </Pane>
        <Pane
          className={classes.panelPreview}
          style={{ width: window.innerWidth - mainPanelSize - 10 }}
        >
          <MultiSubjectPreview />
        </Pane>
      </SplitPane>
      <ConfirmDialog
        title="Demande de confirmation"
        content={confirmationMsg}
        onClose={cancelAction}
        open={isConfirmDialogOpen}
        onConfirm={() => confirmationProcess()}
        onCancel={cancelAction}
        cancelLabel="Annuler"
        confirmLabel="Confirmer"
      />
    </div>
  );
};
