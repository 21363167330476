import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getOperationName } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { auth } from '../utils/auth';
import { CREATE_CATEGORY } from './mutations/categories.photo.graphql';
import {
  CREATE_PHOTO,
  DELETE_PHOTO,
  UPDATE_PHOTO,
  UPDATE_PHOTO_FILE,
  CLONE_PHOTO,
} from './mutations/photos.photo.graphql';
import { GET_ARCHIVES } from './queries/archives.photo.graphql';
import { GET_CATEGORIES } from './queries/categories.photo.graphql';
import { GET_UNDER_SUBJECTS } from './queries/photoMetadatas.photo.graphql';
import {
  GET_PHOTOS_COUNT,
  SEARCH_PHOTOS,
} from './queries/photos.photo.graphql';
import { GET_USERS } from './queries/users.auth.graphql';

const isFile = (value: File | Blob | FileList) => {
  return (
    (typeof File !== 'undefined' && value instanceof File) ||
    (typeof FileList !== 'undefined' && value instanceof FileList) ||
    (typeof Blob !== 'undefined' && value instanceof Blob)
  );
};

const isUpload = ({ variables }: Operation): boolean =>
  Object.values(variables).some(isFile);

const httpLink = new HttpLink({
  uri: config.API_ONE_TOPIC,
});

const uploadLink = createUploadLink({
  uri: config.API_ONE_TOPIC,
});

const terminalLink = split(
  (data: Operation) => isUpload(data),
  uploadLink,
  httpLink as any,
);

const identifyRequestLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => {
    let baseUri;
    switch (operation.operationName) {
      case getOperationName(GET_USERS):
        baseUri = config.API_ONE_AUTH;
        break;
      case getOperationName(SEARCH_PHOTOS):
      case getOperationName(GET_PHOTOS_COUNT):
      case getOperationName(UPDATE_PHOTO):
      case getOperationName(CREATE_PHOTO):
      case getOperationName(DELETE_PHOTO):
      case getOperationName(GET_UNDER_SUBJECTS):
      case getOperationName(UPDATE_PHOTO_FILE):
      case getOperationName(CREATE_CATEGORY):
      case getOperationName(GET_ARCHIVES):
      case getOperationName(GET_CATEGORIES):
      case getOperationName(CLONE_PHOTO):
        baseUri = config.API_ONE_PHOTO;
        break;
      default:
        baseUri = config.API_ONE_TOPIC;
        break;
    }

    return { uri: `${baseUri}?${operation.operationName}` };
  });
  if (forward) {
    return forward(operation);
  }

  return null;
});

const authLink = setContext(async (_, context) => {
  const jwt = await auth.getJwt();
  if (!jwt) {
    return context;
  }

  return {
    ...context,
    headers: {
      ...context.headers,
      Authorization: `Bearer ${jwt}`,
      'x-client-name': `${config.ENV}-BONE-TOPIC-REACT`,
    },
  };
});

const paginatedFields = ['searchPhotos'];

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case 'SubjectUser':
        return object.userId as string;
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
  typePolicies: {
    Query: {
      fields: {
        ...paginatedFields.reduce(
          (prev, fieldName) => ({
            ...(prev as any),
            [fieldName]: {
              keyArgs: ['where', 'first', 'orderBy'],
              merge: (existing = [], incoming: any, { args }: any) =>
                !args?.skip ? incoming : [...existing, ...incoming],
            },
          }),
          {} as any,
        ),
        subjects: {
          keyArgs: ['where', 'first', 'orderBy', 'assignments_some'],
          merge: (existing = [], incoming: any, { args }: any) =>
            !args?.skip ? incoming : [...existing, ...incoming],
        },
      },
    },
    Photo: {
      fields: {
        metadataByame: {
          merge: false,
        },
      },
    },
  },
});

export const client = new ApolloClient<NormalizedCacheObject>({
  connectToDevTools: true,
  link: ApolloLink.from([identifyRequestLink, authLink, terminalLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
  },
});
