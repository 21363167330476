import { Grid, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { LocationPage_GetPrintHeadingByPrintIssue_printHeadings } from '../../../__generated__/queries-topic';
import { LocationItem } from '../../../components/ListItem/LocationItem';
import { CircularLoader } from '../../../components/Loader/Circular';
import { SortableList } from '../SortableList';
import { useStyles } from '../styles';
import { AutoPrototypePrintTemplateDialog } from './AutoPrototypeDialog';
import { AutoSubjectDialogForm } from './AutoSubjectDialogForm';
import { DeletePrintHeadingDialog } from './DeletePrintHeadingDialog';
import { useGetPrintHeadingByPrintIssue } from './getPrintHeadingsByPrintIssue.topic.graphql';
import { PrintHeadingDialogForm } from './PrintHeadingDialogForm';

interface PrintHeadingListProps {
  printIssueId: string;
  unitId: string;
}

interface PrintHeadingListState {
  printHeadingFormOpen: boolean;
  editedPrintHeading?: LocationPage_GetPrintHeadingByPrintIssue_printHeadings;
  printHeadingDeleteFormOpen: boolean;
  deletedPrintHeading?: LocationPage_GetPrintHeadingByPrintIssue_printHeadings;
  autoSubjectFormOpen: boolean;
  autoProtoPrintHeading?: LocationPage_GetPrintHeadingByPrintIssue_printHeadings;
  autoPrototypeDialogOpen: boolean;
}

export const PrintHeadingList: FC<PrintHeadingListProps> = ({
  printIssueId,
  unitId,
}) => {
  const classes = useStyles();
  const [
    {
      printHeadingFormOpen,
      editedPrintHeading,
      printHeadingDeleteFormOpen,
      deletedPrintHeading,
      autoSubjectFormOpen,
      autoProtoPrintHeading,
      autoPrototypeDialogOpen,
    },
    setPrintHeadingState,
  ] = useState<PrintHeadingListState>({
    printHeadingFormOpen: false,
    editedPrintHeading: undefined,
    autoProtoPrintHeading: undefined,
    printHeadingDeleteFormOpen: false,
    deletedPrintHeading: undefined,
    autoSubjectFormOpen: false,
    autoPrototypeDialogOpen: false,
  });
  const {
    data: printHeadingsData,
    loading: printHeadingsLoading,
  } = useGetPrintHeadingByPrintIssue({ printIssueId });

  return (
    <Grid item xs className={classes.column} data-cy="location-grid-heading">
      {!printIssueId ? (
        <div className={classes.placeholder}>
          <Typography variant="h5">Choisissez une parution.</Typography>
        </div>
      ) : printHeadingsLoading ? (
        <CircularLoader />
      ) : !printHeadingsData || !printHeadingsData.printHeadings ? (
        <div className={classes.placeholder}>
          <Typography>Choisissez une parution.</Typography>
        </div>
      ) : (
        <>
          <PrintHeadingDialogForm
            open={printHeadingFormOpen}
            handleClose={() =>
              setPrintHeadingState((prevState) => ({
                ...prevState,
                printHeadingFormOpen: false,
                editedPrintHeading: undefined,
              }))
            }
            headingsLength={printHeadingsData.printHeadings.length}
            printIssueId={printIssueId}
            printHeading={editedPrintHeading}
          />
          <DeletePrintHeadingDialog
            open={printHeadingDeleteFormOpen}
            printHeading={deletedPrintHeading}
            handleClose={() =>
              setPrintHeadingState((prevState) => ({
                ...prevState,
                deletedPrintHeading: undefined,
                printHeadingDeleteFormOpen: false,
              }))
            }
            printIssueId={printIssueId}
          />
          {autoProtoPrintHeading && (
            <AutoPrototypePrintTemplateDialog
              open={autoPrototypeDialogOpen}
              printHeading={autoProtoPrintHeading}
              handleClose={() =>
                setPrintHeadingState((prevState) => ({
                  ...prevState,
                  autoProtoPrintHeading: undefined,
                  printHeadingDeleteFormOpen: false,
                }))
              }
            />
          )}
          {editedPrintHeading && (
            <AutoSubjectDialogForm
              open={autoSubjectFormOpen}
              printHeading={editedPrintHeading}
              printIssueTitle={
                printHeadingsData.printIssue
                  ? printHeadingsData.printIssue.title
                  : ''
              }
              printIssueId={printHeadingsData.printIssue?.id}
              printPublicationTrigramme={
                printHeadingsData.printIssue?.printPublication.key
              }
              handleClose={() =>
                setPrintHeadingState((prevState) => ({
                  ...prevState,
                  editedPrintHeading: undefined,
                  autoSubjectFormOpen: false,
                }))
              }
              unitId={unitId}
            />
          )}
          <LocationItem
            data-cy="location-create-item-heading"
            sticky
            text="Créer une nouvelle têtière."
            onClick={() =>
              setPrintHeadingState((prevState) => ({
                ...prevState,
                printHeadingFormOpen: true,
              }))
            }
            header
          />
          <SortableList
            printIssueId={printIssueId}
            items={printHeadingsData.printHeadings}
            render={(
              item: LocationPage_GetPrintHeadingByPrintIssue_printHeadings,
            ) => (
              <LocationItem
                data-cy="location-item-heading"
                text={item.title}
                key={item.id}
                paperStyle={{ cursor: 'move' }}
                hasAutomaticSubject={item.hasAutomaticSubject}
                autoPrototype={item.autoPrototype}
                menuActions={[
                  {
                    label: !item.hasAutomaticSubject
                      ? 'Activer sujet auto'
                      : 'Désactiver sujet auto',
                    onClick: () => {
                      setPrintHeadingState((prevState) => ({
                        ...prevState,
                        editedPrintHeading: item,
                        autoSubjectFormOpen: true,
                      }));
                    },
                  },
                  {
                    label: 'Modifier',
                    disabled: item.assignmentCount > 0,
                    onClick: () => {
                      setPrintHeadingState((prevState) => ({
                        ...prevState,
                        editedPrintHeading: item,
                        printHeadingFormOpen: true,
                      }));
                    },
                  },
                  {
                    label: 'Supprimer',
                    onClick: () => {
                      setPrintHeadingState((prevState) => ({
                        ...prevState,
                        deletedPrintHeading: item,
                        printHeadingDeleteFormOpen: true,
                      }));
                    },
                  },
                  {
                    label: item.autoPrototype
                      ? 'Désactiver pré-maquette'
                      : 'Activer pré-maquette',
                    onClick: () => {
                      setPrintHeadingState((prevState) => ({
                        ...prevState,
                        autoProtoPrintHeading: item,
                        autoPrototypeDialogOpen: true,
                      }));
                    },
                  },
                  {
                    label: 'Gérer les gabarits',
                    onClick: () => {
                      // eslint-disable-next-line immutable/no-mutation
                      window.location.href = `/location/${item.id}`;
                    },
                  },
                ]}
              />
            )}
          />
        </>
      )}
    </Grid>
  );
};
