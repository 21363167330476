import { ObservableQueryFields } from '@apollo/client';
import { Divider, Typography } from '@mui/material';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import {
  GetSubjects,
  GetSubjectsVariables,
} from '../../../../__generated__/queries-topic';
import { useSubjectsGetter } from '../getFilteredSubjectList.topic.graphql';
import { useStyles } from './styles';

interface NewSubjectProps {
  firstSubjectId: string | null;
  refetch: ObservableQueryFields<GetSubjects, GetSubjectsVariables>['refetch'];
  variables: GetSubjectsVariables;
}

let intervalId: number | null = null;
const POLL_TIME = 5000;

export const NewSubject: FC<NewSubjectProps> = ({
  firstSubjectId,
  refetch,
  variables,
}) => {
  const classes = useStyles();
  const [hasNewSubjects, setHasNewSubjects] = useState(false);
  const getSubjects = useSubjectsGetter();

  useEffect(() => {
    const checkIfNewSubject = async () => {
      const { subjects } = await getSubjects(
        {
          ...variables,
          skip: 0,
          first: 1,
        },
        { fetchPolicy: 'network-only' },
      );
      if (subjects?.[0]?.id !== firstSubjectId) {
        intervalId && window.clearInterval(intervalId);
        setHasNewSubjects(true);
      }
    };
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (intervalId) {
          window.clearInterval(intervalId);
        }
        intervalId = window.setInterval(checkIfNewSubject, POLL_TIME);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        window.clearInterval(intervalId!);
      }
    };
    window.addEventListener('visibilitychange', handleVisibilityChange, false);
    intervalId = window.setInterval(checkIfNewSubject, POLL_TIME);
    return () => {
      window.removeEventListener(
        'visibilitychange',
        handleVisibilityChange,
        false,
      );
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.clearInterval(intervalId!);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateData = async () => {
    await refetch();
    setHasNewSubjects(false);
  };

  return (
    <div
      className={classNames(
        classes.wrapper,
        !hasNewSubjects && classes.hideWrapper,
      )}
      onClick={updateData}
    >
      <Divider className={classes.divider} />
      <Typography className={classes.text}>Nouveau(x) sujet(s)</Typography>
      <Divider className={classes.divider} />
    </div>
  );
};
