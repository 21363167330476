import { ApolloQueryResult } from '@apollo/client';
import { Badge } from '@mui/material';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import clx from 'classnames';
import debounce from 'lodash.debounce';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { MultiSubjectAutoContext } from '..';
import {
  GetPrintTemplateDraft,
  PrintTemplateDraftFormatType,
} from '../../../__generated__/queries-topic';
import { useGetTemplateDraft } from '../../ArticleAuto/genTemplateDraft.topic.graphql';
import { useStyles } from './styles';

export const MultiSubjectPreview: React.FC = () => {
  const classes = useStyles();
  const { pushNotification } = useNotification();
  const match = useRouteMatch<{ printHeadingId: string }>();
  const { printHeadingId } = match.params;
  const [previewResults, setPreviewResult] = useState<
    ApolloQueryResult<GetPrintTemplateDraft>
  >();
  const [artifactUrls, setArtifactUrls] = useState<{ [key: string]: string }>(
    {},
  );
  const genTemplateDraft = useGetTemplateDraft();

  const {
    showPreview,
    isPreviewLoading,
    printTemplates,
    selectedPrintTemplateId,
    setIsPreviewLoading,
    setPreviewResponseTime,
  } = useContext(MultiSubjectAutoContext);

  // react-hooks/exhaustive-deps does not support nested functions inference
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updatePreview = useCallback(
    debounce(
      async (printTemplate: string, pages: number[]) => {
        setIsPreviewLoading(true);

        try {
          const startTime = performance.now();

          const result = await genTemplateDraft(
            {
              printHeadingId,
              format: PrintTemplateDraftFormatType.PNG,
              pages,
              fieldsData: [],
            },
            { fetchPolicy: 'network-only' },
          );

          setPreviewResponseTime(
            ((performance.now() - startTime) / 1000).toFixed(3),
          );

          if (result.data) {
            setArtifactUrls((peviousArtifacts) => ({
              ...peviousArtifacts,
              ...result.data?.printTemplateDrafts.artifacts.reduce(
                (urls, artifact) => {
                  return { ...urls, [artifact.page.toString()]: artifact.url };
                },
                {},
              ),
            }));
          }

          if (result.error) {
            throw result.error;
          }

          setPreviewResult(result);
        } catch (err) {
          pushNotification({
            message: (err as { message: string }).message,
            type: NotificationTypeEnum.error,
          });
        }

        setIsPreviewLoading(false);
      },
      1000,
      {
        maxWait: 5000,
      },
    ),
    [pushNotification, setIsPreviewLoading, genTemplateDraft, printHeadingId],
  );

  useEffect(() => {
    if (showPreview && selectedPrintTemplateId) {
      updatePreview(
        printTemplates.find(
          (template) => template.id === selectedPrintTemplateId,
        )?.printTemplate.id || '',
        [],
      );
    }
  }, [showPreview, selectedPrintTemplateId, updatePreview, printTemplates]);

  if (!previewResults) {
    return null;
  }

  if (previewResults.error) {
    return <div>{JSON.stringify(previewResults.error)}</div>;
  }

  return (
    <TransformWrapper
      doubleClick={{ step: 30 }}
      pinch={{ disabled: true }}
      pan={{ disabled: false }}
      wheel={{ disabled: false, step: Object.keys(artifactUrls).length / 2 }}
      options={{
        centerContent: false,
        minScale: 0.5,
        limitToBounds: false,
        ...{ wrapperClass: classes.fullWidth },
      }}
      defaultScale={0.9}
    >
      <TransformComponent>
        <div className={classes.draftPreviewContainer}>
          {Object.keys(artifactUrls).map((page, index) => (
            <div className={classes.draftImgContainer} key={index}>
              <Badge
                key={page}
                badgeContent={index + 1}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                classes={{
                  root: classes.draftPreviewBadge,
                }}
              />
              <img
                alt=""
                src={artifactUrls[page]}
                className={clx(
                  classes.draftPreviewImg,
                  isPreviewLoading && classes.draftPreviewImgLoading,
                )}
              />
            </div>
          ))}
        </div>
      </TransformComponent>
    </TransformWrapper>
  );
};
